import { api } from 'api';
import { ConfirmModal } from 'components/ModalWindows/ConfirmModal/ConfirmModal';

import {
  createEditErrorResult,
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import { useApiQuery } from 'hooks/useApiQuery';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { success } from 'utils/alert';
import {
  apiSettlementUser,
  SettlementUserEditable,
  SettlementUserInfo,
} from './api/apiSettlementUser';

type ConfirmDialogProps = Parameters<typeof ConfirmModal>[0];

export const useSettlementsUserManagement = () => {
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>();
  const users = useApiQuery({
    queryFn: apiSettlementUser.getAll,
  });

  const usersData = useMemo(() => users.data ?? [], [users.data]);

  const setPasswordUpdateLoading = useCallback(
    (userId: string, loading: boolean) => {
      users.setData((items) =>
        items?.map((r) => {
          if (r.userId !== userId) return r;
          return { ...r, resetPassword: loading };
        }),
      );
    },
    [users],
  );

  const add: OnAddSave<SettlementUserInfo> = useCallback(
    async (formValues: Record<string, string | string[]>) => {
      const newAddedUser = await apiSettlementUser.postNew(formValues);
      if (_.isNil(newAddedUser)) return createEditErrorResult('New user failed');

      users.setData([...usersData, newAddedUser]);
      return { type: FetchStatus.Done, item: newAddedUser };
    },
    [users, usersData],
  );

  const edit: OnEditSave<SettlementUserInfo> = useCallback(
    async (
      formValues: Record<string, string | string[]>,
      item: SettlementUserInfo,
    ) => {
      const { userRole } = formValues as SettlementUserEditable;
      if (_.isEmpty(userRole))
        return createEditErrorResult('Please select user role');

      const newItem = { ...item, userRole };
      const result = await apiSettlementUser.updateUserRole(newItem);
      if (_.isNil(result)) return createEditErrorResult('Update failed');

      return { type: FetchStatus.Done, item: newItem };
    },
    [],
  );

  const executePasswordReset = useCallback(
    async (item: SettlementUserInfo | null) => {
      if (_.isNil(item)) return;

      setPasswordUpdateLoading(item.userId, true);
      const result = await api.settlementUsers.resetUserPassword(item.id);
      setPasswordUpdateLoading(item.userId, false);

      if (result) success('Password reset');
    },
    [setPasswordUpdateLoading],
  );

  const passwordReset = useCallback(
    async (item: SettlementUserInfo | null) => {
      setConfirmDialog({
        title: 'Reset password',
        body: `Are you sure you want to reset password for ${item?.name}?`,
        handleConfirm: (confirm) => {
          setConfirmDialog(undefined);
          if (confirm) executePasswordReset(item);
        },
      });
    },
    [executePasswordReset],
  );

  return {
    data: usersData,
    loading: users.loading,
    confirmDialog,
    edit,
    add,
    refresh: users.fetch,
    passwordReset,
  };
};

import { SlipSheet } from 'api/endpoints';
import { useMemo } from 'react';
import './SlipSheetEntityInfo.css';

interface Props {
  item: SlipSheet;
}

export const SlipSheetEntityInfo = ({ item }: Props) => {
  const { names, parts } = useMemo(
    () => ({
      names: item.legalEntities.map((x) => `${x.entityName}`),
      parts: item.parts.map((x) => x.partDescription),
    }),
    [item],
  );

  return (
    <span className="preview-enumerator">
      <span className="preview-enumerator__info">
        {firstOrCount(names, 'Entities')}
      </span>
      <span className="action-list-item__actions__divider" />
      <span className="preview-enumerator__info">
        {firstOrCount(parts, 'Parts')}
      </span>
    </span>
  );
};

function firstOrCount(labels: string[], countOf: string): string {
  const len = labels.length;
  if (len === 0) return '';
  if (len === 1) return labels[0];
  return `${len} ${countOf}`;
}

import { PageButtonsGroup, PageTabButton } from 'components/PageButtonsGroup';
import { useSettlementGridContext } from 'pages/ClaimsSettlementsPage/SettlementGridContextProvider';
import { useMemo, useState } from 'react';

const buttons: PageTabButton[] = [{ id: 'main', label: 'Settlements' }];

type PageButtonsGroupProps = React.ComponentProps<typeof PageButtonsGroup>;
type Props = Omit<PageButtonsGroupProps, 'buttons' | 'selected'> & {};

export const SettlementButtonsGroup = ({ onButtonClick, ...props }: Props) => {
  const { rowTotalCount } = useSettlementGridContext();

  const buttonsWithNumbers = useMemo(
    () =>
      buttons.map((r) => {
        if (r.id === 'main' && rowTotalCount)
          return { ...r, label: `${r.label} (${rowTotalCount})` };
        return r;
      }),
    [rowTotalCount],
  );

  const [selectedId, setSelectedId] = useState(buttonsWithNumbers[0].id);

  const handlePageTabClick = (tab: PageTabButton) => {
    setSelectedId(tab.id);
    onButtonClick?.(tab);
  };

  return (
    <PageButtonsGroup
      selectedId={selectedId}
      buttons={buttonsWithNumbers}
      onButtonClick={handlePageTabClick}
      {...props}
    />
  );
};

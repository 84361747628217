import { ApModal } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { isArray } from 'lodash';
import React from 'react';
import './ErrorModal.css';

type ErrorModalProps = {
  isOpen?: boolean;
  header: React.ReactNode;
  content: string[] | string;
  modalClassName?: string;
  renderFooter?: (props: any) => React.ReactNode;
  onClosed?: () => void;
};

export const ErrorModal = ({
  isOpen,
  header,
  content,
  modalClassName,
  renderFooter,
  onClosed,
}: ErrorModalProps) => {
  const renderContent = (content: string[]) => {
    return content.map((c, idx) => <p key={`${c}_${idx}`}>{c}</p>);
  };

  return (
    <ApModal
      header={header}
      isDismissible={true}
      footer={renderFooter}
      isOpen={isOpen}
      className={classNames('upload-error-modal', modalClassName)}
      hasClosed={onClosed}
    >
      <div className="content">
        {isArray(content) ? renderContent(content) : content}
      </div>
    </ApModal>
  );
};

import { ActionName } from 'api/endpoints/action-names';
import Excel from 'exceljs';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { error } from 'utils/alert';
import { downloadExcelFile } from 'utils/file';
import { ExhibitTemplates, getTemplateName } from './exibitType';
import { templateAllowedClaims } from './templateAllowedClaims';
import { templateAlterClaimAmount } from './templateAlterClaimAmount';
import { templateAlterClaimAmountProposeDebtor } from './templateAlterClaimAmountProposeDebtor';
import { templateAlterDebtor } from './templateAlterDebtor';
import { templateExpungeNoSurviving } from './templateExpungeNoSurviving';
import { templateExpungeWithSurviving } from './templateExpungeWithSurviving';

export interface IExcelCreator {
  fileName: string;
  getWorkbook: (
    data: IExhibitState,
    action: ActionName,
  ) => Promise<Excel.Workbook | null>;
}

const ExcelCreatorTemplate: { [id in ExhibitTemplates]: IExcelCreator } = {
  [ExhibitTemplates.AllowedClaims]: templateAllowedClaims,
  [ExhibitTemplates.AlterClaimAmount]: templateAlterClaimAmount,
  [ExhibitTemplates.AlterClaimAmountProposeDebtor]:
    templateAlterClaimAmountProposeDebtor,
  [ExhibitTemplates.AlterDebtor]: templateAlterDebtor,
  [ExhibitTemplates.ExpungeNoSurviving]: templateExpungeNoSurviving,
  [ExhibitTemplates.ExpungeWithSurviving]: templateExpungeWithSurviving,
};

export const exportExhibitExcelFile = async (
  data: IExhibitState,
  action: ActionName | undefined,
) => {
  if (!action) {
    error(`Unknown resolution type`);
    return;
  }

  const tempateName = getTemplateName(action.substatusId);
  const template = ExcelCreatorTemplate[tempateName!];
  if (!template) {
    error(`Can't create template for this resolution type: ${action.name}`);
    return;
  }

  downloadExhibitTemplate(data, action, template);
};

const downloadExhibitTemplate = async (
  data: IExhibitState,
  action: ActionName,
  tempate: IExcelCreator,
) => {
  const workbook = await tempate.getWorkbook(data, action);
  if (!workbook) {
    error('XSLX file is empty');
    return;
  }

  await downloadExcelFile(workbook, tempate.fileName);
};

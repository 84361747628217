import { SettingsTitlePanel } from '../../components/SettingsTitlePanel';
import { SettlementAgreemantGetTemplate } from './components/SettlementAgreemantGetTemplate';
import { SettlementAgreemantHelp } from './components/SettlementAgreemantHelp';
import { SettlementAgreemantPreview } from './components/SettlementAgreemantPreview';
import { SettlementAgreemantUpload } from './components/SettlementAgreemantUpload';
import { SettlementsAgreementSection } from './components/SettlementsAgreementSection';

import './SettlementsAgreementTemplates.css';
import { useSettlementsAgreementTemplates } from './useSettlementsAgreementTemplates';

interface Props {
  children?: React.ReactNode;
}

export const SettlementsAgreementTemplates = (props: Props) => {
  const {
    item,
    previewWindow,
    loading,
    uploadProgress,
    upload,
    download,
    deleteFile,
    previewOpen,
    previewClose,
  } = useSettlementsAgreementTemplates();

  return (
    <div className="settlements-agreement-templates">
      <SettingsTitlePanel
        className="settlements-agreement-templates__body"
        title="Agreement templates"
      >
        <SettlementsAgreementSection title="Upload agreement template">
          <SettlementAgreemantUpload
            item={item}
            loading={loading}
            uploadProgress={uploadProgress}
            upload={upload}
            download={download}
            deleteFile={deleteFile}
            preview={previewOpen}
          />
        </SettlementsAgreementSection>
        <SettlementsAgreementSection title="What is an agreement template">
          <SettlementAgreemantGetTemplate />
        </SettlementsAgreementSection>
      </SettingsTitlePanel>

      <SettingsTitlePanel
        className="settlements-agreement-templates__help"
        title="How to create a new agreement template"
      >
        <SettlementAgreemantHelp />
      </SettingsTitlePanel>

      {previewWindow && (
        <SettlementAgreemantPreview
          agreemant={previewWindow}
          onClose={previewClose}
        />
      )}
    </div>
  );
};

import classNames from 'classnames';
import './SettingsTitlePanel.css';

type Props = React.PropsWithChildren<{
  className?: string;
  title: string;
}>;

export const SettingsTitlePanel = ({ title, className, children }: Props) => {
  return (
    <div className={classNames('settings-title-panel', className)}>
      <h3 className="settings-title-panel__title">{title}</h3>
      <div className="settings-title-panel__body">{children}</div>
    </div>
  );
};

import { GridInitialState } from 'components/AgGridExtended';
import _ from 'lodash';
import {
  createContext,
  PropsWithChildren,
  Ref,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataTableGridType } from './components/DataTableGrid';

interface SettlementGridData {
  gridRef: Ref<DataTableGridType>;
  gridState: GridInitialState;
  rowTotalCount: number | undefined;
  isFilterApplied: boolean;
  setRowTotalCount: (value: number) => void;
  updateGridState: (state: GridInitialState) => void;
  gridShowColumns: () => void;
  gridResetFilters: () => void;
}

const SettlementGridContext = createContext<SettlementGridData>(
  {} as SettlementGridData,
);

export const SettlementGridContextProvider = (props: PropsWithChildren<{}>) => {
  const gridRef = useRef<DataTableGridType>(null);
  const [gridState, setGridState] = useState<GridInitialState>({});
  const [isOpenColumnPanel, setIsOpenColumnPanel] = useState(false);
  const [rowTotalCount, setRowTotalCount] = useState<number>();

  const updateGridState = (newState: GridInitialState) => {
    setGridState((prevState) => ({ ...prevState, ...newState }));
  };

  const gridResetFilters = () => {
    gridRef.current?.resetFilters();
  };
  const gridShowColumns = () => {
    const newState = !isOpenColumnPanel;
    gridRef.current?.showColumnsPanel(newState);
    setIsOpenColumnPanel(newState);
  };

  const isFilterApplied = useMemo(
    () => !_.isEmpty(gridState.filter),
    [gridState.filter],
  );

  return (
    <SettlementGridContext.Provider
      value={{
        gridRef,
        gridState,
        rowTotalCount,
        isFilterApplied,
        setRowTotalCount,
        updateGridState,
        gridShowColumns,
        gridResetFilters,
      }}
    >
      {props.children}
    </SettlementGridContext.Provider>
  );
};

export const useSettlementGridContext = () => useContext(SettlementGridContext);

import { ApMenu } from '@alixpartners/ui-components';
import { isNil } from 'lodash';
import styled from 'styled-components';

/**
 * We are using BEM notation in other places in the project but since @alixpartners/ui-components is using styled-components
 * we are styled-components here as this is the simplest way to implement this.
 */

export const ApMenuStyled = styled(ApMenu)<{ width?: number }>`
  width: ${({ width }) => (!isNil(width) ? `${width}px` : '100%')};
`;

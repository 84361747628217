import { colProp } from '../contractsBatchEdit.const';
import { IBatchItem } from '../contractsBatchEdit.type';
import {
  ContractActionSubstatus,
  ContractActionType,
} from 'api/endpoints/actions-select-options';

type BaseProps = {
  items: IBatchItem[];
};
type SetActionSubstatusProps = BaseProps & {
  actionTypes?: ContractActionType[];
  actionTypeId?: number;
  allActionSubstatuses: ContractActionSubstatus[];
};

export const setSelectActionSubStatus = ({
  items,
  actionTypes,
  actionTypeId,
  allActionSubstatuses,
}: SetActionSubstatusProps) => {
  // we show all action substatuses if there's no action type selected
  const contractActionSubStatuses =
    actionTypeId !== undefined
      ? actionTypes?.find((type: ContractActionType) => type.id === actionTypeId)
          ?.contractActionSubStatuses
      : allActionSubstatuses;
  const allowedValues = contractActionSubStatuses?.map(
    (c: ContractActionSubstatus) => ({
      label: c.contractActionSubStatusName,
      value: c.id,
    }),
  );

  items
    .filter((item) => item.propName === colProp.contractActionSubStatusId)
    .forEach((item: IBatchItem) => {
      item.allowedValues = allowedValues;
    });
};

import { ApButtonMain, ApModal } from '@alixpartners/ui-components';
import classnames from 'classnames';
import './SofaModal.css';

type ApModalProps = React.ComponentProps<typeof ApModal>;
interface Props extends ApModalProps {
  btnConfirm?: {
    name?: string;
    iconName?: string;
    disabled?: boolean;
    onClick?: () => void;
  };
}

export const SofaModal = ({ className, btnConfirm, ...props }: Props) => {
  return (
    <ApModal
      className={classnames('sofa-modal', className)}
      isOpen
      isDismissible
      footer={
        btnConfirm
          ? () => (
              <ApButtonMain
                className="sofa-modal__action"
                type="submit"
                disabled={btnConfirm?.disabled}
                iconName={btnConfirm.iconName}
                onClick={btnConfirm?.onClick}
              >
                {btnConfirm?.name}
              </ApButtonMain>
            )
          : undefined
      }
      {...props}
    />
  );
};

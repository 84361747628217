import classNames from 'classnames';
import './LabeledMultiSelectExtendable.css';
import { IMultiSelectProps, MultiSelectExtendable } from './MultiSelectExtendable';

export type LabeledMultiSelectExtendableProps = IMultiSelectProps & {
  selectCn?: string;
  topLabel: string;
};

/** Simply puts a given label _above_ the multi select component. */
export function LabeledMultiSelectExtendable(
  props: LabeledMultiSelectExtendableProps,
) {
  const { className, selectCn, topLabel, ...msProps } = props;
  return (
    <div className={classNames('labeled-multiselect', className)}>
      <div className="labeled-multiselect__label">{topLabel}</div>
      <MultiSelectExtendable
        className={classNames('labeled-multiselect__select', selectCn, {
          'multiselect--empty': (msProps.selected ?? []).length === 0,
        })}
        {...msProps}
      />
    </div>
  );
}

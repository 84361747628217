import { MatchingContent } from './MatchingContent';
import { MatchingStart } from './MatchingStart';
import { MatchingToggle } from './MatchingToggle';
import { MatchingSection } from '../../components/MatchingSection';
import { useMatchingContext } from '../../MatchingContextProvider';
import { MatchStatus } from 'api/endpoints';

export const MatchingProcess = () => {
  const {
    downloading,
    matchingStatus,
    onDownload,
    onMatchingTrigger,
    inProgressStatus,
    fileErrors,
  } = useMatchingContext();

  return (
    <MatchingSection>
      <MatchingStart
        onMatchingTrigger={onMatchingTrigger}
        inProgressStatus={inProgressStatus}
      />
      <MatchingContent
        status={fileErrors ? MatchStatus.PublishFailed : matchingStatus}
        onDownload={onDownload}
        downloading={downloading}
      />
      <MatchingToggle />
    </MatchingSection>
  );
};

import { ApButtonSecondary } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './ClaimButton.css';

type ClaimButtonProps = React.ComponentProps<typeof ApButtonSecondary>;

export const ClaimButton = ({ className, ...props }: ClaimButtonProps) => (
  <ApButtonSecondary
    className={classNames('exhibit-claim-button', className)}
    {...props}
  />
);

import { error } from 'utils/alert';
import { downloadExcelFile, filenameSanitizer } from 'utils/file';
import { getIsoDate } from 'utils/formatDate';
import {
  ContractExhibitOptions,
  ContractExhibitType,
} from '../exhibityContractType';
import { templateNonRealEstateAssumption } from './templateNonRealEstateAssumption';
import { templateNonRealEstateRejection } from './templateNonRealEstateRejection';
import { templateRealEstateAssumption } from './templateRealEstateAssumption';
import { templateRealEstateRejection } from './templateRealEstateRejection';
import { ContractExhibitExport, IExhibitExcelCreator } from './templatesType';

export const exportExhibitExcelFile = async (
  exhibit: ContractExhibitExport,
  exhibitOptions?: ContractExhibitOptions,
) => {
  const { fileName } = exhibit;
  const { includeRejDate } = exhibitOptions || {};

  const creator = getExhibitExcelCreator(exhibit.typeId!);
  if (!creator) {
    console.error();
    error('Unknown Actual Substatus value');
    return;
  }

  const workbook = creator.getWorkbook({ ...exhibit, includeRejDate });
  if (!workbook) {
    console.error('XSLX data file is empty');
    return;
  }

  const fileFullName = createExibitExcelFileName(fileName);
  await downloadExcelFile(workbook, fileFullName);
};

const getExhibitExcelCreator = (
  typeId: number,
): IExhibitExcelCreator | undefined => {
  switch (typeId) {
    case ContractExhibitType.NonRealEstateAssumption:
      return templateNonRealEstateAssumption;
    case ContractExhibitType.RealEstateAssumption:
      return templateRealEstateAssumption;
    case ContractExhibitType.NonRealEstateRejection:
      return templateNonRealEstateRejection;
    case ContractExhibitType.RealEstateRejection:
      return templateRealEstateRejection;
  }

  return undefined;
};

function createExibitExcelFileName(actionName: string) {
  const date = getIsoDate(new Date());
  const fileName = `${actionName}_${date}.xlsx`;

  return filenameSanitizer(fileName);
}

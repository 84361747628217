import { PageHeaderTitle } from 'components/PageHeaderTitle';
import { PanelBoxShadowed } from '../../components/PanelBoxShadowed/PanelBoxShadowed';
import './ContactUsPage.css';
import { Layout } from 'components/Layout';

export const ContactUsPage = () => {
  return (
    <Layout className="contact-us-page">
      <PageHeaderTitle title="Contact Us" />
      <div className="contact-us-page__body">
        <PanelBoxShadowed
          className="contact-us-page__message-box"
          title="Send us a message"
        >
          <div className="contact-us-page__message">
            <div>
              If you would like to provide some feedback on using the platform or
              report an issue you are encountering, please contact the Platform Team
              and TRS working group at:
            </div>
            <div>
              <a
                className="contact-us-page__message_link"
                href="mailto:ch11-support@alixpartners.com"
              >
                ch11-support@alixpartners.com
              </a>
            </div>
          </div>

          <div className="contact-us-page__message">
            <div>
              If you want to add additional AlixPartners users to the Platform please
              email:
            </div>
            <div>
              <a
                className="contact-us-page__message_link"
                href="mailto:ETS-Support@alixpartners.com"
              >
                ETS-Support@alixpartners.com
              </a>
            </div>
          </div>

          <div className="contact-us-page__message">
            <div>If you want to add client users to the Platform please email:</div>
            <div>
              <a
                className="contact-us-page__message_link"
                href="mailto:it-ccsi-request@alixpartners.com"
              >
                it-ccsi-request@alixpartners.com
              </a>
            </div>
          </div>
        </PanelBoxShadowed>
      </div>
    </Layout>
  );
};

import { createContext, useContext } from 'react';
import { useMatchingExport } from './hooks/useMatchingExport';
import { useMatchingUpload } from './hooks/useMatchingUpload';

type ContextData = ReturnType<typeof useMatchingExport> &
  ReturnType<typeof useMatchingUpload>;

const MatchingContext = createContext<ContextData>({} as ContextData);

export const MatchingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { onUpload, prevUploaded, onDeleteFile, fetchingFiles } =
    useMatchingUpload();
  const {
    downloading,
    matchingStatus,
    onDownload,
    onMatchingTrigger,
    inProgressStatus,
    fileErrors,
    setFileErrors,
  } = useMatchingExport();
  return (
    <MatchingContext.Provider
      value={{
        onDeleteFile,
        prevUploaded,
        fetchingFiles,
        onUpload,
        downloading,
        matchingStatus,
        onDownload,
        onMatchingTrigger,
        inProgressStatus,
        fileErrors,
        setFileErrors,
      }}
    >
      {children}
    </MatchingContext.Provider>
  );
};

export const useMatchingContext = () => useContext(MatchingContext);

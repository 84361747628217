import { CounterpartySummary } from 'api/endpoints';

import { HeaderItem } from 'components/SingleEntityHeader/components/HeaderItem';
import './CounterpartyHeader.css';

export interface SingleEntityHeaderProps {
  summary: CounterpartySummary;
}

export const CounterpartyHeader = ({ summary }: SingleEntityHeaderProps) => {
  const counterpartyName = summary.counterPartyName;
  const matchCode = summary.matchCode;

  return (
    <div className="counterparty-header">
      <div className="counterparty-header__left-pane">
        <div className="counterparty-header-subtitle">
          <h4>Counterparty summary for:</h4>
        </div>

        <div className="counterparty-header__title">{counterpartyName}</div>
      </div>

      <div className="counterparty-header__right-pane">
        <div className="counterparty-header__right-pane__row">
          <HeaderItem id="matchcode" label="Matchcode:" value={matchCode} />
        </div>
      </div>
    </div>
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { ContractRow, Counterparty } from 'api/endpoints';
import { SubsectionType } from '../types';
import { CellTextInput } from './CellTextInput';
import './CounterpartyNameCell/CounterpartyNameCell.css';

export interface CounterpartyNameCellProps
  extends ICellRendererParams<Counterparty | ContractRow, any> {
  subsection: SubsectionType;
  onUpdateMatchCode: (
    id: number,
    matchCode: number,
    contract: boolean,
    cellProps: ICellRendererParams<any>,
  ) => unknown;
  onFetchNextMatchCode: () => Promise<string>;
  onLostFocus: () => unknown;
}

export const MatchCodeNameCell = ({
  onUpdateMatchCode,
  onFetchNextMatchCode,
  onLostFocus,
  ...props
}: CounterpartyNameCellProps) => {
  const contract: ContractRow = props.data as ContractRow;

  const multipleMatchCodes =
    contract?.sourceType === 'Contract' && contract?.hasMultipleMatchCodes;

  return (
    <div
      className={
        multipleMatchCodes ? 'counterparty-name-cell__with-icon' : 'match-code-cell'
      }
    >
      <CellTextInput
        placeholder="Match code"
        value={`${contract?.matchCode ?? ''}`}
        length={9}
        onChange={(v) =>
          contract?.id && onUpdateMatchCode(contract?.id, Number(v), true, props)
        }
        onLostFocus={onLostFocus}
        getNextValue={onFetchNextMatchCode}
        editDisabled
      />

      {multipleMatchCodes && <div className="cell-icon">+</div>}
    </div>
  );
};

import { IOption } from '@alixpartners/ui-components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { FetchStatus } from 'types/fetch-status.types';
import { FormValidState } from 'utils/form/form-utils';
import { iconImage } from '../SvgIcon/iconImageList';
import { SortState } from './useTableSorting';

export enum EntityRowState {
  Initial = 1,
  Expading,
  Saving,
  Collapsing,
}

export type IdEntity = { id: string | number };

export enum AddOrEditStatus {
  DoneButNoReturn = 'DoneButNoReturn',
}

export type EditOrAddResponse<T extends IdEntity> =
  | {
      type: AddOrEditStatus.DoneButNoReturn;
    }
  | {
      type: FetchStatus.Done;
      item: T;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
    };

export const createEditErrorResult = (message: string) =>
  ({
    type: FetchStatus.Error,
    error: { message },
  } as const);

export type OnEditSave<T extends IdEntity> = (
  formValues: Record<string, string | string[]>,
  item: T,
) => Promise<EditOrAddResponse<T>>;

export type OnAddSave<T extends IdEntity> = (
  formValues: Record<string, string | string[]>,
) => Promise<EditOrAddResponse<T>>;

export type OnDelete = (userGroupId: number) => Promise<any>;

export type EntityEditRowProps<T extends IdEntity> = {
  item: T;
  add?: boolean;
  hasDeleteRow?: boolean;
};

export type EntityAddRowProps = {};

export type OnFilter = (filterValues: Record<string, string>) => Promise<void>;

export type EntityFilterRowProps = {};

export type InputComponentProps<T extends IdEntity> = {
  item: T | null;
  columnKey: keyof T;
  fromTable: {
    operation: 'new' | 'edit';
    disabled: boolean;
    readonly?: boolean;
    className: string;
    getValue: (name: string) => any;
    setValue: (name: string, value: any) => void;
    setValidity: (name: string, value: FormValidState) => void;
    showHoverData?: boolean;
    formSubmitted?: boolean;
    options?: IOption[];
  };
  handleToggleChange?: () => void;
};

export type FilterComponentProps<T extends IdEntity> = {
  filterKey: keyof T;
  fromTable: { disabled: boolean; className: string };
};

export type InputComponent<T extends IdEntity> = (
  props: InputComponentProps<T>,
) => React.ReactElement<any, any> | null;

export type FilterComponent<T extends IdEntity> = (
  props: FilterComponentProps<T>,
) => React.ReactElement<any, any> | null;

export type EntityInfoBase<T extends IdEntity> = {
  items: T[];
  editColumnKeys: (keyof T)[];
  inputComponent: InputComponent<T>;
  onEditSave: OnEditSave<T>;
};

type AdditionalEntityProps<T> = {
  onDelete?: OnDelete;
  section?: SectionType;
  hasDeleteRow?: boolean;
  options?: IOption[];
  toggleAll?: boolean;
  handleToggleAll?: () => void;
  handleExportItems?: () => Promise<void>;
  sortState?: SortState<T>;
  handleSort?: (field: keyof T) => void;
  sortIcon?: keyof typeof iconImage;
};

export type EntityInfo<T extends IdEntity> = EntityInfoBase<T> &
  AdditionalEntityProps<T> & {
    status: FetchStatus;
    onAddSave?: OnAddSave<T>;
    addColumnKeys: (keyof T)[];
    onFilter: OnFilter;
    filterColumnKeys: (keyof T)[];
    filterComponent: FilterComponent<T>;
  };

export type EntityTableProps<T extends IdEntity> = EntityInfoBase<T> &
  AdditionalEntityProps<T> & {
    children?: React.ReactNode;
    className?: string;
    status?: FetchStatus;
    onAddSave?: OnAddSave<T>;
    addColumnKeys?: (keyof T)[];
    onFilter?: OnFilter;
    filterColumnKeys?: (keyof T)[];
    filterComponent?: FilterComponent<T>;
  };

export type PanelProps = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

export type HeadersBaseProps = {
  headers: string[];
};

export type EditListProps<T> = HeadersBaseProps & {
  singleItem?: T;
  add?: boolean;
  warningMessage?: string;
  toggleAll?: boolean;
  pagination?: boolean;
  handleToggleAll?: () => void;
};

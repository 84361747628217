import { SimpleAccordion } from 'components/SimpleAccordion/SimpleAccordion';
import { useClaimContractChangesCommentsLogs } from 'pages/ClaimsContractsToolPage/hooks/useClaimContractChangesCommentsLogs';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useClaimContractLogPagination } from 'pages/SingleClaimPage/hooks/useClaimContractLogPagination';
import { formatDate } from 'utils/formatDate';
import { LogEntry } from 'api/endpoints/claimLogs';

export const LatestComment = ({
  id,
  section,
}: {
  id: number;
  section: SectionType;
}) => {
  const { page, sort } = useClaimContractLogPagination();
  const { latestComment } = useClaimContractChangesCommentsLogs(
    {
      id,
      currentPage: page,
      currentSort: sort,
    },
    section,
  );

  if (!latestComment?.comment) return null;

  return (
    <SimpleAccordion
      summary={{
        openedTitle: 'Hide latest comment',
        closedTitle: 'Show latest comment',
        rightPart: <CommentDetails comment={latestComment} />,
      }}
      content={latestComment?.comment}
      withIcon
      defaultOpened
    />
  );
};

const CommentDetails = ({ comment }: { comment: LogEntry }) => (
  <span>
    <b>by {comment.user}</b>
    <span style={{ color: '#737373' }}> on {formatDate(comment.logTime)}</span>
  </span>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DynamicField } from 'api/endpoints/dynamic-fields';
import { FetchStatus } from 'types/fetch-status.types';
import { FetchDynamicFieldsInput } from './thunks/dynamicFields-thunks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export type DynamicFieldsSlice =
  | {
      type: FetchStatus.Idle;
      claimsData: null;
      contractsData: null;
      error: null;
      input: FetchDynamicFieldsInput;
      allClaimsData: DynamicField[];
      allContractsData: DynamicField[];
    }
  | {
      type: FetchStatus.Done;
      claimsData: DynamicField[];
      contractsData: DynamicField[];
      input: FetchDynamicFieldsInput;
      error: null;
      allClaimsData: DynamicField[];
      allContractsData: DynamicField[];
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      input: FetchDynamicFieldsInput;
      claimsData: DynamicField[];
      contractsData: DynamicField[];
      allClaimsData: DynamicField[];
      allContractsData: DynamicField[];
    }
  | {
      type: FetchStatus.Fetching;
      claimsData: DynamicField[];
      contractsData: DynamicField[];
      input: FetchDynamicFieldsInput;
      error: null;
      allClaimsData: DynamicField[];
      allContractsData: DynamicField[];
    };

export const DEFUALT_INPUT: FetchDynamicFieldsInput = { filter: { type: 'all' } };

const sortDynamicFiels = (a: DynamicField, b: DynamicField): number => {
  if (a.name && b.name) {
    return 0;
  }

  if (!a.name && !b.name) {
    return a.type.localeCompare(b.type);
  }

  return a.name ? -1 : 1;
};

export const initialState: DynamicFieldsSlice = {
  type: FetchStatus.Idle,
  error: null,
  claimsData: null,
  contractsData: null,
  input: DEFUALT_INPUT,
} as DynamicFieldsSlice;

const SLICE_NAME = 'dynamic-fields';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    idle(state: DynamicFieldsSlice) {
      state.type = FetchStatus.Idle;
      state.error = null;
      state.claimsData = null;
      state.contractsData = null;
      state.input = DEFUALT_INPUT;
    },
    fetching(
      state: DynamicFieldsSlice,
      action: PayloadAction<FetchDynamicFieldsInput>,
    ) {
      state.type = FetchStatus.Fetching;
      state.input = action.payload;
    },
    done(
      state: DynamicFieldsSlice,
      action: PayloadAction<{
        items: DynamicField[];
        input: FetchDynamicFieldsInput;
        section: SectionType;
      }>,
    ) {
      const { section, items, input } = action.payload;
      if (section === SectionType.Claims) {
        state.claimsData = [...items].sort(sortDynamicFiels);
      } else if (section === SectionType.Contracts) {
        state.contractsData = [...items].sort(sortDynamicFiels);
      }
      state.type = FetchStatus.Done;
      state.input = input;
    },
    error(state: DynamicFieldsSlice, action: PayloadAction<{ message: string }>) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
    setAll(
      state: DynamicFieldsSlice,
      action: PayloadAction<{ items: DynamicField[]; section: SectionType }>,
    ) {
      const { section, items } = action.payload;
      if (section === SectionType.Claims) {
        state.allClaimsData = items;
      } else if (section === SectionType.Contracts) {
        state.allContractsData = items;
      }
    },
    editDone: (
      state: DynamicFieldsSlice,
      action: PayloadAction<{ edited: DynamicField; section: SectionType }>,
    ) => {
      const { section, edited } = action.payload;
      if (section === SectionType.Claims) {
        const allClaims = [...state.allClaimsData];
        const claimsData = [...(state.claimsData ?? [])];
        const idx = allClaims.findIndex((e) => e.id === edited.id);
        if (idx !== -1) {
          allClaims[idx] = { ...edited };
          state.allClaimsData = allClaims;
        }
        if (claimsData) {
          const idx = claimsData.findIndex((e) => e.id === edited.id);
          if (idx !== -1) {
            claimsData[idx] = edited;
            state.claimsData = claimsData;
          }
        }
      } else if (section === SectionType.Contracts) {
        const allContracts = [...state.allContractsData];
        const contractsData = [...(state.contractsData ?? [])];
        const idx = allContracts.findIndex((e) => e.id === edited.id);
        if (idx !== -1) {
          allContracts[idx] = { ...edited };
          state.allContractsData = allContracts;
        }
        if (contractsData) {
          const idx = contractsData.findIndex((e) => e.id === edited.id);
          if (idx !== -1) {
            contractsData[idx] = edited;
            state.contractsData = contractsData;
          }
        }
      }
    },
  },
});

export const dynamicFieldsActions = slice.actions;
export default slice.reducer;

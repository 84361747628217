import { useState } from 'react';
import './VendorGraph.css';
import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';

enum TabType {
  PrePetition = 'PrePetition',
  FirstDayMotion = 'FirstDayMotion',
}

type DashboardTab = {
  type: TabType;
  label: string;
};

const tabs: DashboardTab[] = [
  {
    type: TabType.PrePetition,
    label: 'Pre petition',
  },
  { type: TabType.FirstDayMotion, label: '1st day motions' },
];

const DEFAULT_TAB = TabType.PrePetition;

export const VendorGraph = () => {
  const [tab, setTab] = useState<TabType>(DEFAULT_TAB);

  return (
    <div className="vendor-tool__graph">
      <ApButtonGroup>
        {tabs.map((t) => (
          <ApButtonTab
            key={t.type}
            autoFocus={false}
            isSelected={tab === t.type}
            onClick={() => setTab(t.type)}
            theme="light"
          >
            {t.label}
          </ApButtonTab>
        ))}
      </ApButtonGroup>
    </div>
  );
};

import { SlipSheet, slipsheets } from 'api/endpoints';
import classNames from 'classnames';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useState } from 'react';
import {
  slipSheetsDeleteThunk,
  slipSheetsPutThunk,
} from 'reducer/thunks/slipSheets-thunk';
import { FetchStatus } from 'types/fetch-status.types';
import { ActionList } from '../../Components/ActionList';
import { AddButton } from '../../Components/AddButton';
import { SlipSheetDeleteModal } from './SlipSheetDeleteModal';
import { SlipSheetListItem } from './SlipSheetListItem';
import { OkParam, SlipSheetModal } from './SlipSheetModal';
import './SlipSheets.css';
import { useSlipSheetsList } from './useSlipSheets';
import { useSlipsheetUpload } from './useSlipSheetsUpload';

type NewItemWindow = { item: SlipSheet | undefined };
type DeleteWindow = { item: SlipSheet };

export function SlipSheets() {
  const { data, type: status, deleteState } = useSlipSheetsList();

  const [showNewItemModal, setShowNewItemModal] = useState<NewItemWindow>();
  const [showDeleteModal, setShowDeleteModal] = useState<DeleteWindow>();

  const { uploadAndCreateSlipSheet, uploadStatus } = useSlipsheetUpload();
  const dispatch = useAppDispatch();

  const deleteLoading = deleteState.status === FetchStatus.Fetching;
  const listLoading = status === FetchStatus.Fetching;
  const showLoading = listLoading || deleteLoading || uploadStatus.progress! >= 0;

  const handleOk = useCallback(
    (arg: OkParam) => {
      if (arg.type === 'edit') {
        dispatch(slipSheetsPutThunk(arg.params));
      } else {
        uploadAndCreateSlipSheet({
          entries: [
            {
              file: arg.file,
              input: arg.params,
            },
          ],
        });
      }
      setShowNewItemModal(undefined);
    },
    [dispatch, uploadAndCreateSlipSheet],
  );

  const handleModalClose = useCallback(() => setShowNewItemModal(undefined), []);

  const handleAddNew = useCallback(
    () => setShowNewItemModal({ item: undefined }),
    [],
  );

  const handleDeleteClick = useCallback(
    (item: SlipSheet) => setShowDeleteModal({ item }),
    [],
  );

  const handleDeleteModalConfirm = useCallback(
    (confirm: boolean) => {
      const item = showDeleteModal?.item;
      setShowDeleteModal(undefined);
      if (confirm && item) {
        dispatch(slipSheetsDeleteThunk(item));
      }
    },
    [dispatch, showDeleteModal?.item],
  );

  const handleDownload = useCallback(
    (item: SlipSheet) => slipsheets.download(item),
    [],
  );

  const handleEdit = useCallback(
    (item: SlipSheet) => setShowNewItemModal({ item }),
    [],
  );

  return (
    <div
      className={classNames(
        'sofas-schedules-panel global-notes-selection-panel sofas-schedules__content',
      )}
    >
      <h4>Slip Sheets</h4>
      <DivWithLoader
        className={classNames(
          'sofas-schedules-panel__inner-panel global-notes-selection-panel__inner-panel',
        )}
        loading={showLoading}
      >
        <div>
          <AddButton onClick={handleAddNew} disabled={listLoading} />
        </div>

        <ActionList uploadProgress={uploadStatus.progress} hideOnEmptyList>
          {data?.map((item) => {
            return (
              <SlipSheetListItem
                key={item.id}
                item={item}
                onDeleteClick={handleDeleteClick}
                onDownloadClick={handleDownload}
                onEditClick={handleEdit}
              />
            );
          })}
        </ActionList>
      </DivWithLoader>
      {showNewItemModal && (
        <SlipSheetModal
          item={showNewItemModal.item}
          onOk={handleOk}
          onClosed={handleModalClose}
        />
      )}
      {showDeleteModal && (
        <SlipSheetDeleteModal
          item={showDeleteModal.item}
          onClose={handleDeleteModalConfirm}
        />
      )}
    </div>
  );
}

import { GlobalNote } from 'api/endpoints';
import { useMemo } from 'react';
import './GlobalNoteItemInfo.css';

interface Props {
  item: GlobalNote;
}

export const GlobalNoteItemInfo = ({ item }: Props) => {
  const names = useMemo(
    () => item.legalEntities.map((x) => `${x.legalEntityName}`),

    [item],
  );

  return (
    <span className="preview-enumerator">
      <span className="preview-enumerator__info">
        {entityListToString(names, 'Entities')}
      </span>
    </span>
  );
};

function entityListToString(items: string[], label: string): string {
  const count = items.length;
  switch (count) {
    case 0:
      return '';
    case 1:
      return items[0];
    default:
      return `${count} ${label}`;
  }
}

import { PropsWithChildren } from 'react';
import './ClaimsReportsCard.css';

export const ClaimsReportsCard = ({
  title,
  description,
  children,
}: PropsWithChildren<{ title: string; description: string }>) => {
  return (
    <div className="claims-reports__card">
      <div className="claims-reports__card__title">{title}</div>
      <div className="claims-reports__card__description">{description}</div>
      <div className="claims-reports__card__body">{children}</div>
    </div>
  );
};

import {
  ActionStatusDynamic,
  LegalEntity,
  MajorCategories,
  MinorCategories,
  UserInfo,
} from 'api/endpoints';
import { AssignedGroup } from 'api/endpoints/assigned-group-users';
import classNames from 'classnames';
import { ApNiceSelect } from 'components/ApNiceSelect/ApNiceSelect';
import { ReviewersEdit } from 'components/ReviewersEdit/ReviewersEdit';
import { ReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import {
  IOption,
  MultiSelectExtendable,
} from 'components/UIComponents/MultiSelectExtendable';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import isEqual from 'lodash/isEqual';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback } from 'react';
import { ClaimReviewerData, setClaimReviewers } from 'reducer/claimReviewerReducer';
import { FetchStatus } from 'types/fetch-status.types';
import { formatLegalEntity } from 'utils/format';
import { dateISOtoISO8601 } from 'utils/formatDate';
import { EditFieldFunc } from '../../hook/useEditClaim';
import { CategorySelectDef } from '../../types';
import { SurvivingClaimNoSelect } from '../SurvivingClaimNoSelect/SurvivingClaimNoSelect';
import './CategorySelect.css';
import { MajorMinorCategoryOption } from './MajorMinorCategoryOption';

export enum CategorySelectType {
  Category = 'Category',
  LegalEntities = 'LegalEntities',
  ClaimReviewer = 'ClaimReviewer',
  SurvivingClaimNo = 'SurvivingClaimNo',
  ActionType = 'ActionType',
  ActionSubstatus = 'ActionSubstatus',
  ActionName = 'ActionName',
  ReviewLevel = 'ReviewLevel',
  ActionStatus = 'ActionStatus',
}

export interface CategorySelectProps extends CategorySelectDef {
  editField: EditFieldFunc;
  disabled?: boolean;
}

export interface CategorySelectMap {
  [k: string]:
    | 'categoriesMajorCategoriesOptions2'
    | 'legalEntities'
    | 'actionStatuses';
}

const CATEGORY_SELECT_MAP: CategorySelectMap = {
  [CategorySelectType.Category]: 'categoriesMajorCategoriesOptions2',
  [CategorySelectType.LegalEntities]: 'legalEntities',
  [CategorySelectType.ActionStatus]: 'actionStatuses',
};

const ReviewLevelOptions = [
  { label: 'None', value: 0 },
  { label: 'High', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Low', value: 3 },
];

export const CategorySelect = ({
  type,
  isMajor,
  name,
  claim,
  disabled,
  editField,
}: CategorySelectProps) => {
  const dispatch = useAppDispatch();
  const selectionState = useAppSelector((s) => s.claimActionNameSelection);
  const activeReviewers = useAppSelector((s) => s.claimReviewer);
  const allUsers = useAppSelector((s) => s.allUsers);
  const allGroups = useAppSelector((s) => s.assignedGroupUsers);

  const getActiveUserReviewers = (activeUserIds: number[], users: UserInfo[]) =>
    users.map((u) => ({
      ...u,
      checked: activeUserIds.includes(u.id) && !u.hasOwnProperty('userGroupName'),
    }));

  const getActiveGroupReviewers = (
    activeUserGroupIds: number[],
    groups: AssignedGroup[],
  ) =>
    groups.map((g) => ({
      ...g,
      checked:
        activeUserGroupIds.includes(Number(g.id)) &&
        g.hasOwnProperty('userGroupName'),
    }));

  const isClaimReviewersChanged = (
    e: ReviewersBatchItem,
    data: ClaimReviewerData,
  ) => {
    return (
      !isEqual(e.claimReviewerUserNames, data?.userReviewers) ||
      !isEqual(e.claimReviewerUserGroupIds, data?.userGroupReviewers)
    );
  };

  const isClaimReviewersLoading = () => {
    return (
      allGroups.status !== FetchStatus.Done || allUsers.status !== FetchStatus.Done
    );
  };

  const { selectable } = useAppSelector((state) => state);
  const isCategorySelectable = useCallback((option) => {
    const item = option.data;
    if (!item) return false;
    if ((item as MajorCategories).majorCategory) return false;
    return true;
  }, []);
  if (!selectable.data) return null;
  const optionKey = CATEGORY_SELECT_MAP[type];
  const optionsBeforeType = selectable.data[optionKey];
  const value = claim ? (claim as any)[name] : undefined;

  if (type === CategorySelectType.Category && !isMajor) {
    const options = optionsBeforeType as IOption[];
    return (
      <MultiSelectExtendable
        className={classNames('category-select', {
          disabled,
        })}
        menuClassName="category-select__multiselect-menu"
        options={options}
        isSelectable={isCategorySelectable}
        itemRenderer={MajorMinorCategoryOption}
        searchEnabled
        multiple={false}
        disabled={disabled}
        hideFooter
        renderLabel={getMajMinLabelByMinorId(
          selectable.data.categoriesMajorCategories,
          value,
        )}
        searchFilter={categorySearchFilter}
        onChange={(n) => {
          if (!n.length) return;
          const item = n[0];
          editField(name, (item.data as MinorCategories).id);
        }}
      />
    );
  }

  if (type === CategorySelectType.LegalEntities) {
    const options = optionsBeforeType as LegalEntity[];
    return (
      <ApNiceSelect
        showValueAsTooltip
        emptyDefaultOption
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => {
          if (disabled) return;
          editField(name, e.target.value);
        }}
        value={value}
        name={name}
        options={options.map((c) => ({
          label: formatLegalEntity(c),
          value: `${c.id}`,
        }))}
      />
    );
  }
  const {
    actionTypesByStatus,
    substatusOptions,
    actionNameOptions,
    claimActionInfo,
  } = selectionState;
  if (type === CategorySelectType.ActionStatus) {
    const options = optionsBeforeType as ActionStatusDynamic[];
    return (
      <ApNiceSelect
        showValueAsTooltip
        emptyDefaultOption
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => {
          if (disabled) return;
          editField(name, e.target.value);
        }}
        value={claimActionInfo.actionStatus ?? ''}
        name={name}
        options={options?.map((c) => ({
          label: c.actionStatusDisplayName,
          value: c.actionStatus,
        }))}
      />
    );
  }

  const { actionTypeId, actionSubstatusId, actionNameId } = claimActionInfo;
  if (type === CategorySelectType.ActionType) {
    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => editField(name, e.target.value)}
        value={actionTypeId ?? 0}
        name={name}
        options={actionTypesByStatus.map((o) => ({
          label: `${o.actionExhibitTypeName}`,
          value: `${o.id}`,
        }))}
      />
    );
  }

  if (type === CategorySelectType.ActionSubstatus) {
    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => editField(name, e.target.value)}
        value={actionSubstatusId ?? 0}
        name={name}
        options={substatusOptions.map((o) => ({
          label: `${o.actionSubStatusName}`,
          value: `${o.id}`,
        }))}
      />
    );
  }

  if (type === CategorySelectType.ActionName) {
    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => editField(name, e.target.value)}
        value={actionNameId ?? 0}
        name={name}
        options={actionNameOptions.map((o) => ({
          label: `${o.name} ${
            o.hearingDate ? `[${dateISOtoISO8601(o.hearingDate)}]` : ''
          }`,
          value: `${o.id}`,
        }))}
      />
    );
  }

  if (type === CategorySelectType.ClaimReviewer) {
    const { data } = activeReviewers;
    return (
      <ReviewersEdit
        users={getActiveUserReviewers(data.userReviewers, allUsers.data || [])}
        groups={getActiveGroupReviewers(
          data.userGroupReviewers,
          allGroups.data || [],
        )}
        disabled={disabled}
        loading={isClaimReviewersLoading()}
        onChange={(e) => {
          if (isClaimReviewersChanged(e, data)) {
            dispatch(setClaimReviewers(e));
            editField(name, e);
          }
        }}
        section={SectionType.Claims}
      />
    );
  }

  if (type === CategorySelectType.SurvivingClaimNo) {
    return (
      <SurvivingClaimNoSelect
        value={claim?.survivingClaimNumber}
        disabled={disabled}
        onChange={(value) => editField(name, value)}
      />
    );
  }

  if (type === CategorySelectType.ReviewLevel) {
    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => editField(name, e.target.value)}
        value={value}
        name={name}
        options={ReviewLevelOptions}
      />
    );
  }

  return <div />;
};

const getMajMinLabelByMinorId = (categories: MajorCategories[], id: number) => {
  for (const maj of categories) {
    for (const min of maj.minorCategories) {
      if (min.id === id) return `${maj.majorCategory} / ${min.minorCategory}`;
    }
  }
  return '';
};

const categorySearchFilter = (o: IOption, text: string) => {
  if (o.label.toLowerCase().includes(text)) return true;
  const minorCats = (o.data as MajorCategories).minorCategories;
  if (minorCats) {
    return !!minorCats.find((c) => c.minorCategory.toLowerCase().includes(text));
  }
  return false;
};

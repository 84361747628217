import { ContractEditValues } from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEditActionField } from 'hooks/useEditActionField';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  useActionNames,
  useActionsSelectOptions,
} from 'pages/SettingsPage/sections/ActionNames/useActionNames';
import { useEffect } from 'react';
import { contractSet } from 'reducer/contractActionNameReducer';

function useContractEditActionStatus(contract?: ContractEditValues) {
  const contractSelectionState = useAppSelector(
    (s) => s.contractActionNameSelection,
  );
  const { contractId } = contractSelectionState;

  // trigger data loading
  useActionNames({ section: SectionType.Contracts });
  useActionsSelectOptions(SectionType.Contracts);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contract && contractId !== contract.id) {
      dispatch(
        contractSet({
          contractActionInfo: {
            id: contract.id,
            contractActionNameId: contract.contractActionNameId,
            contractActionSubStatusId: contract.contractActionSubStatusId,
            contractActionTypeId: contract.contractActionTypeId,
          },
          contractExhibitTypeId: contract.contractExhibitTypeId ?? 1,
          contractStatusId: contract.contractStatusId ?? 1,
        }),
      );
    }
  }, [dispatch, contract, contractId]);

  const { editActionField } = useEditActionField(SectionType.Contracts);

  return {
    editActionField,
  };
}

export default useContractEditActionStatus;

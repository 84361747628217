import { ReactChild, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchAllMarkdownFiles } from './api';
import { HelpSearchBox } from './components/HelpContentsBox';

import './HelpSearchResults.css';
import { helpStructure } from './helpStructure';
import { HelpSearchResult } from './types';
import { getHelpStructureAsList } from './utils/getHelpStructureAsList';
import { searchEngine } from './utils/searchEngine';

interface Props {
  searchText: string;
  onSearch: (searchText: string) => void;
  onRedirect: (keys: string[]) => void;
}

export const HelpSearch = ({ searchText, onSearch, onRedirect }: Props) => {
  const [isSearching, setIsSearching] = useState(false);
  const [list, setList] = useState<HelpSearchResult[]>([]);

  const searchForText = useCallback(
    async (abortSignal: AbortSignal) => {
      setIsSearching(true);

      const fileList = getHelpStructureAsList(helpStructure);
      const contentList = await fetchAllMarkdownFiles(fileList, abortSignal);
      const searchedItems = searchEngine(searchText, contentList);
      if (abortSignal.aborted) return;

      setList(searchedItems);
      setIsSearching(false);
    },
    [searchText],
  );

  useEffect(() => {
    const abort = new AbortController();
    searchForText(abort.signal);

    return () => abort.abort();
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const { title, subTitle } = useMemo(() => {
    if (isSearching)
      return {
        title: `Searching ...`,
        subTitle: `Searching "${searchText}"`,
      };

    if (list.length <= 0)
      return {
        title: 'Sorry. No result found',
        subTitle: `No results for "${searchText}"`,
      };

    return {
      title: `Search Results (${list.length})`,
      subTitle: `${list.length} results for "${searchText}"`,
    };
  }, [isSearching, list.length, searchText]);

  return (
    <div className="help-search-results">
      <HelpSearchBox
        className="help-search-results__results"
        title={title}
        onSearch={onSearch}
      >
        <div className="help-search-results__body">
          <header>{subTitle}</header>
          <div className="help-search-results__list">
            {list.map((item, id) => (
              <SearchItem
                key={`search-item-${id}`}
                keys={item.keys}
                snippet={item.snippet}
                onClick={onRedirect}
              />
            ))}
          </div>
        </div>
      </HelpSearchBox>
    </div>
  );
};

const SearchItem = (props: {
  keys: string[];
  snippet: ReactChild | undefined;
  onClick: (keys: string[]) => void;
}) => {
  const keys = [...props.keys];
  const header = keys.pop();
  const footer = keys.join(' -> ');

  const handleClick = () => props.onClick(props.keys);

  return (
    <div className="help-search-results__list-item" onClick={handleClick}>
      <header>{header}</header>
      <div>{props.snippet}</div>
      <footer>{footer}</footer>
    </div>
  );
};

import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { useEffect, useState } from 'react';
import { createApolloClient, GraphQlApolloClient } from './graphql';

const emptyApollo = new ApolloClient<NormalizedCacheObject>({
  cache: new InMemoryCache(),
});

const useGraphql = () => {
  const [authorized, setAuthorized] = useState(false);
  const [client, setClient] = useState<GraphQlApolloClient>(emptyApollo);

  useEffect(() => {
    async function load() {
      const apolloClient = await createApolloClient();
      setClient(apolloClient);
      setAuthorized(true);
    }
    load();
  }, []);
  return [client, authorized] as const;
};

export default useGraphql;

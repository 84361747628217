import { useEffect } from 'react';
import { fetchAssignedGroupUserThunk } from 'reducer/thunks/assignedGroupUserThunk';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppDispatch, useAppSelector } from './reducerHooks';

//!!! Deprecated !!! use "userGroup.ts => claimUserGroup" instead
export function useAssignedGroupUsers() {
  const groupUsers = useAppSelector((s) => s.assignedGroupUsers);
  const { status } = groupUsers;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === FetchStatus.Idle) {
      dispatch(fetchAssignedGroupUserThunk());
    }
  }, [dispatch, status]);

  return groupUsers;
}

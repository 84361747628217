import { api } from 'api';
import {
  BackgroundjobStatus,
  BACKGROUND_IN_PROGRESS,
} from 'api/endpoints/backgroundJob';
import { SettlementBackgroundStatus } from 'api/endpoints/settlementUsers';
import * as ls from 'local-storage';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntervalTimer } from 'utils/hooks/useIntervalTimer';
import { iif } from 'utils/object';

const MONITOR_TIME_INTERVAL = 15 * 1000; //milliseconds
const MAX_HTTP_ERRORS = 10;

export const useSettlementsUserBackgroundJob = () => {
  const jobIdRef = useRef<number>();
  const fileNameRef = useRef<string>();

  const errorsCount = useRef(0);
  const [result, setResult] = useState<SettlementBackgroundStatus>();
  const timer = useIntervalTimer();

  const stop = useCallback(() => {
    localStorageBackgroundJob.cleanup();
    timer.stop();
  }, [timer]);

  const checkJobStatus = useCallback(async () => {
    if (!jobIdRef.current) {
      stop();
      return;
    }

    const { data } = await api.settlementUsers.getBatchUploadJob(jobIdRef.current);

    if (!data) {
      console.error('DataPipeline/status request - returns an empty data');
      if (errorsCount.current < MAX_HTTP_ERRORS) {
        errorsCount.current += 1;
        return;
      }

      console.error('DataPipeline/status request Termintated - too many errors');
      stop();
    }

    errorsCount.current = 0;
    setResult(data);

    const isInProgress = BACKGROUND_IN_PROGRESS.includes(data?.status!);
    if (!isInProgress) {
      stop();
    }
  }, [errorsCount, jobIdRef, stop]);

  const start = useCallback(
    async (jobId: number | undefined, additional?: { fileName?: string }) => {
      if (!jobId) return;

      setResult(undefined);

      jobIdRef.current = jobId;
      fileNameRef.current = additional?.fileName;
      timer.start(() => checkJobStatus(), MONITOR_TIME_INTERVAL);

      localStorageBackgroundJob.set({ jobId, ...additional });
    },
    [timer, checkJobStatus],
  );

  useEffect(() => {
    const { jobId, fileName } = localStorageBackgroundJob.get();
    start(jobId, { fileName });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const status: BackgroundjobStatus | undefined = useMemo(
    () => result?.status ?? iif(timer.active, 'InProgress'),
    [result?.status, timer.active],
  );

  return {
    jobId: jobIdRef.current,
    running: timer.active,
    status,
    result,
    fileName: fileNameRef.current,
    start,
    stop,
  };
};

//--------------------------------------------------------------------------------

interface StorageValue {
  jobId: number;
  fileName?: string;
}

const localStorageName = 'settlementBackgroundJob';

const localStorageBackgroundJob = {
  set: (value: StorageValue) => ls.set(localStorageName, value),
  get: () => ls.get<StorageValue>(localStorageName) ?? {},
  cleanup: () => ls.remove(localStorageName),
};

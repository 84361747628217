import { ApButtonMain, ApSelect } from '@alixpartners/ui-components';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { useMemo } from 'react';

import {
  listPartsSchedules,
  listPartsSOFA,
  listSchedules,
} from './SofasPartsTables/Columns/types';
import { ISofaTableProps } from './tables.types';

import './TableSubHeader.css';

interface ITableSubHeader {
  sofaTable: ISofaTableProps;
  onChangeTab: (part: string) => void;
  onChangeSchedule: (schedule: string) => void;
  onResetFilters: () => void;
  onAdd: () => void;
  onDelete: () => void;
  deleteDisabled: boolean
}

export const TableSubHeader = ({
  sofaTable,
  onChangeTab,
  onChangeSchedule,
  onAdd,
  onDelete,
  deleteDisabled,
  onResetFilters
}: ITableSubHeader) => {
  const { type, part, schedule } = sofaTable;

  const { placeholder, partsOptions, scheduleOptions } = useMemo(() => {
    const isScedulesSection = type === 'schedule';

    const schedules = isScedulesSection ? listSchedules : undefined;
    const parts = isScedulesSection ? listPartsSchedules[schedule!] : listPartsSOFA;

    return {
      placeholder: part ? `Part ${part}` : 'Select Part',
      partsOptions: parts.map((value) => ({ label: `Part ${value}`, value })),
      scheduleOptions: schedules?.map((value) => ({ label: value, value })),
    };
  }, [part, schedule, type]);

  return (
    <div className="table-sub-header">
      <div className="table-sub-header__buttons">
        <ApButtonMain iconName="add" onClick={onAdd}>
          Add New Row
        </ApButtonMain>
        <ApButtonMain iconName="delete_outline" onClick={onDelete} disabled={deleteDisabled}>
          Delete
        </ApButtonMain>
      </div>
      {scheduleOptions && (
        <div className="table-sub-header__schedule-select">
          <div className="table-sub-header-text">Select Schedule: </div>
          <ApSelect
            name="select-schedule"
            value={sofaTable.schedule}
            options={scheduleOptions}
            onChange={onChangeSchedule}
          />
        </div>
      )}
      <div className="table-sub-header-text">Select Part: </div>
      <ApSelect
        name="select-part"
        value={sofaTable.part}
        options={partsOptions}
        placeholder={placeholder}
        onChange={onChangeTab}
      />

      <ApButtonCasual className="reset-filters-button" iconName="backup_restore" onClick={onResetFilters} >
        Reset filters
      </ApButtonCasual>
    </div>
  );
};

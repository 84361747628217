import { dataPipeline, FileComparisonResponse, fileInfo } from 'api/endpoints';
import { useCallback, useMemo, useState } from 'react';
import {
  FileStatus,
  FileType,
  IUploadItem,
  IUploadSection,
  ModuleToDelete,
  PublishSection,
} from 'types/dataUploaderTypes';

import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import {
  azureStorageUpload,
  bulkCompareFiles,
  getUploadedFiles,
} from '../api/apiDataUploader';

import { error, success } from 'utils/alert';
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect';
import { setSavedGridState } from 'utils/local-storage';
import { deleteAllFiles, deleteFileInfo } from '../api/apiDataDownload';
import { usePipeLineMonitoring } from './usePipelineStateMonitoring';

const FILE_TYPES_ALLOWED_FOR_COMPARE = [
  FileType.Sofa,
  FileType.SofaAppend,
  FileType.Schedule,
  FileType.ScheduleAppend,
];

export const useDataUploader = () => {
  const [selectedSection, setSelectedSection] = useState<IUploadSection>();
  const [filesToUpload, setFilesToUpload] = useState<IUploadItem[]>([]);
  const [serverFiles, setServerFiles] = useState<IUploadItem[]>([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const files = useMemo(
    () => [...filesToUpload, ...serverFiles],
    [filesToUpload, serverFiles],
  );

  const appendFileType = useMemo(() => {
    if (!selectedSection) {
      return null;
    }

    switch (selectedSection.type) {
      case FileType.Sofa:
        return FileType.SofaAppend;
      case FileType.Schedule:
        return FileType.ScheduleAppend;
      default:
        return null;
    }
  }, [selectedSection]);

  const selectedSectionUnpublishedFiles = selectedSection
    ? serverFiles.filter(
        (file) =>
          [selectedSection.type]
            .concat(appendFileType ? [appendFileType] : [])
            .includes(file.type) && file.status === FileStatus.Unpublished,
      )
    : [];

  const {
    pipeline,
    monitoring: publishingStatus,
    initPipeline,
    startPipelineMonitoring,
    resetPipelineProcessingStatus,
  } = usePipeLineMonitoring();

  const initFileInfo = useCallback(async () => {
    setFetchingFiles(true);
    const uploadedFiles = await getUploadedFiles();
    setServerFiles(uploadedFiles);
    setFetchingFiles(false);

    return uploadedFiles;
  }, []);

  useUpdateEffect(() => {
    if (!publishingStatus.active) initFileInfo();
  }, [publishingStatus.active]);

  const init = useCallback(() => {
    initFileInfo();
    initPipeline();
  }, [initFileInfo, initPipeline]);

  const addFiles = useCallback(
    async (items: IUploadItem[]) => {
      const itemsToUpload = items.map((r) => ({
        ...r,
        status: FileStatus.Uploading,
      }));
      setFilesToUpload(itemsToUpload);
      await azureStorageUpload(itemsToUpload, onProgress);
      setFilesToUpload([]);

      init();
    },
    [init],
  );

  const deleteFile = useCallback(
    async (item: IUploadItem) => {
      resetPipelineProcessingStatus();
      setFilesToUpload((ui) => ui.filter((uitem) => uitem !== item));
      setServerFiles((ui) => ui.filter((uitem) => uitem !== item));

      const result = await deleteFileInfo(item);
      initFileInfo();

      if (result) success(`${item.displayName} deleted`);
    },
    [initFileInfo, resetPipelineProcessingStatus],
  );

  const deleteAll = useCallback(async () => {
    const result = await deleteAllFiles();
    if (result.type !== ResponseType.OK) {
      error('Deletion of all files encountered an unexpected error.');
    } else {
      success(`Deletion of all files is in progress`);
    }
    setServerFiles([]);
    startPipelineMonitoring();
  }, [startPipelineMonitoring]);

  const getFilesComparisonResponse = async (): Promise<
    FileComparisonResponse | 'not_applicable'
  > => {
    if (
      !selectedSection ||
      !FILE_TYPES_ALLOWED_FOR_COMPARE.includes(selectedSection.type)
    ) {
      return 'not_applicable';
    }

    return bulkCompareFiles(selectedSectionUnpublishedFiles);
  };

  const updateSelectedUnpublishedFilesTypeToAppend = async () => {
    if (appendFileType === null) {
      return 'not_applicable';
    }

    await Promise.all(
      selectedSectionUnpublishedFiles.map(({ id: fileId }) =>
        fileInfo.deleteFile(appendFileType, fileId),
      ),
    );

    await Promise.all(
      selectedSectionUnpublishedFiles.map(
        ({ name, displayName, schedulePart, errorCount }) =>
          fileInfo.postFileInfo({
            schedulePart,
            name,
            displayName,
            errorCount: errorCount ?? null,
            isOverride: false,
            fileType: appendFileType,
            fileState: FileStatus.Unpublished,
          }),
      ),
    );
  };

  const getAppendFilesComparisonResult = async (
    fileInfo: IUploadItem[],
  ): Promise<'not_applicable' | FileComparisonResponse> => {
    if (appendFileType === null) {
      return Promise.resolve('not_applicable');
    }

    const unpublishedAppendFiles = fileInfo.filter(
      (file) =>
        file.type === appendFileType && file.status === FileStatus.Unpublished,
    );

    return bulkCompareFiles(unpublishedAppendFiles);
  };

  const publish = useCallback(
    async (module: PublishSection) => {
      resetPipelineProcessingStatus();
      await dataPipeline.postTrigger(module);
      setSavedGridState(undefined);
      startPipelineMonitoring();
    },
    [startPipelineMonitoring, resetPipelineProcessingStatus],
  );

  const onProgress = (item: IUploadItem, loaded: number, size: number) => {
    item.uploadProgress = loaded / size;
    setFilesToUpload((r) => [...r]);
  };

  const deleteAllByModule = useCallback(
    async (moduleToDelete: ModuleToDelete, caption: string) => {
      if (!moduleToDelete) return;
      success(`Deletion of all files for ${caption} is in progress`);
      const result = await dataPipeline.postTriggerResetModule(moduleToDelete);
      if (result.error) {
        error(`Error deleting the data for ${caption}. Please try again`);
        return;
      }
      startPipelineMonitoring();
    },
    [startPipelineMonitoring],
  );

  return {
    selectedSection,
    files,
    pipeline,
    publishingStatus,
    setSelectedSection,
    init,
    publish,
    getFilesComparisonResponse,
    updateSelectedUnpublishedFilesTypeToAppend,
    getAppendFilesComparisonResult,
    addFiles,
    deleteFile,
    deleteAll,
    deleteAllByModule,
    initFileInfo,
    fetchingFiles,
  };
};

import { ApButtonIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { SwitchButton } from 'components/UIComponents/SwitchButton';
import { isUndefined } from 'lodash';
import './DocumentTableActions.css';

export const DocumentTableActions = ({
  primary,
  loading,
  primaryChange,
  deleteAttachment,
  deletingDoc,
  downloadClick,
}: {
  primary?: boolean;
  loading?: boolean;
  primaryChange?: (value: boolean) => void;
  deleteAttachment: () => void;
  downloadClick: () => void;
  deletingDoc: boolean;
}) => {
  return (
    <span className="document-item__actions">
      {!isUndefined(primary) && (
        <>
          <SwitchButton
            className="document-item__primary-switch"
            label="Primary"
            value={!!primary}
            disabled={loading}
            onChange={primaryChange}
          />
          <span className="document-item__divider"></span>
        </>
      )}

      <span className="document-item__divider"></span>
      <ButtonIcon
        className="document-item__download-button"
        type="button"
        icon="download_file"
        iconColor="var(--ap-color-grey8)"
        iconSize={20}
        onClick={(e) => {
          e.preventDefault();
          downloadClick();
        }}
      />
      <span className="document-item__divider"></span>
      {deletingDoc ? (
        <ApLoaderDefault className="document-item__delete-loader" loaderSize="sm" />
      ) : (
        <ApButtonIcon
          iconName="delete"
          className="document-item__delete-button"
          onClick={deleteAttachment}
          iconProps={{ iconSize: 20, iconColor: 'var(--ap-color-grey8)' }}
        />
      )}
    </span>
  );
};

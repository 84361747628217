import Select from 'components/UIComponents/Select';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { selectableUpdated } from 'reducer/selectableDataReducer';
import { IBatchItem } from '../../contractsBatchEdit.type';
import { colProp } from '../../contractsBatchEdit.const';

export const ContractCategoryType = ({
  onChange,
  addCascadingItem,
}: {
  addCascadingItem: (item: IBatchItem) => void;
  onChange: (val: number) => void;
}) => {
  const { contractCategories, contractTypesByCategory } = useAppSelector(
    (s) => s.selectable.data,
  );
  const dispatch = useAppDispatch();

  return (
    <div className="action-name-edit">
      <div className="action-name-edit__selector">
        <label>Contract Category</label>
        <Select
          name={'Contract category'}
          options={contractCategories.map((c) => ({
            label: c.category,
            value: c.id,
          }))}
          onChange={(e) => {
            dispatch(selectableUpdated({ contractCategoryId: +e.target.value }));
            addCascadingItem({
              id: '',
              propName: colProp.contractCategoryId,
              value: +e.target.value,
            });
          }}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Contract Type</label>
        <Select
          name={'Contract Type'}
          options={[{ contractType: '', id: '' }, ...contractTypesByCategory].map(
            (c) => ({
              label: c.contractType,
              value: c.id,
            }),
          )}
          onChange={(e) => onChange(+e.target.value)}
        />
      </div>
    </div>
  );
};

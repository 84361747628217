import classNames from 'classnames';
import './PageHelpSection.css';

export type Props = {
  title: string;
  steps: {
    title: string;
    content?: string | JSX.Element;
  }[];
  className?: string;
  subTitle?: string;
};

export const PageHelpSection = ({ title, steps, className, subTitle }: Props) => {
  return (
    <div className={classNames('page-help-section', className)}>
      <h3>{title}</h3>
      {subTitle && <h6>{subTitle}</h6>}
      <ul>
        {steps.map((step, idx) => {
          return (
            <li key={idx}>
              <h5>
                <p className={classNames({ withContent: !!step.content })}>
                  <span>{idx + 1}.</span> <span>{step.title}</span>
                </p>
              </h5>
              {step.content && <p>{step.content}</p>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

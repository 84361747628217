import { jsonFetchGet } from 'api/jsonFetch/authJsonFetch';
import { mapOk } from 'api/jsonFetch/jsonFetch';
import groupBy from 'lodash/groupBy';

/**
 * This data is hardcoded in the backend, so we can just hardcode it here too
 */
export enum ActionExhibitTypeId {
  None = 0,
  Expunge = 1,
  Allow = 2,
  AlterSettlement = 3,
  AlterObjection = 4,
  ExpungeSettlement = 5,
  ExpungeOther = 6,
}

export enum ContractActionTypeId {
  None = 0,
  AwaitingReview = 1,
  UnderReview = 2,
  AssumeCure = 3,
  AssumeAssign = 4,
  AssumeAmended = 5,
  Reject = 6,
  Terminate = 7,
  NonExecutory = 8,
}

export type ActionType = {
  id: ActionExhibitTypeId;
  actionTypeName: string;
};

export type ContractActionType = {
  id: number;
  contractActionTypeName: string;
  contractActionSubStatuses: ContractActionSubstatus[];
  hearingDateRequired: boolean;
  contractStatusId: number;
};

export type ActionSubstatus = {
  id: number;
  actionSubStatusName: string;
  typeId: number;
};

export type ContractActionSubstatus = {
  id: number;
  contractActionSubStatusName: string;
  typeId: number;
};

export type ActionSelectOptionsData = {
  actionTypes: ActionType[];
  actionSubstatusesByActionType: Record<number, ActionSubstatus[]>;
  hearingDateRequiredByActionType: Record<number, boolean>;
};

export type ContractActionSelectOptionsData = {
  contractActionTypes: ContractActionType[];
  actionSubstatusesByActionType: Record<number, ContractActionSubstatus[]>;
  hearingDateRequiredByActionType: Record<number, boolean>;
};

export type ServerSubstatus = {
  id: number;
  actionSubStatusName: string;
};

export type ServerExhibitType = {
  id: ActionExhibitTypeId;
  actionExhibitTypeName: string;
  hearingDateRequired: boolean;
  actionSubStatuses: ServerSubstatus[];
};

export function serverToAppResponse(
  serverResponse: ServerExhibitType[],
): ActionSelectOptionsData {
  const allSubstatuses: ActionSubstatus[] = serverResponse.reduce((acc, crt) => {
    const subs: ActionSubstatus[] = crt.actionSubStatuses.map((sb) => ({
      id: sb.id,
      typeId: crt.id,
      actionSubStatusName: sb.actionSubStatusName,
    }));
    return [...acc, ...subs];
  }, [] as ActionSubstatus[]);

  const actionSubstatusesByActionType = groupBy(allSubstatuses, (sb) => sb.typeId);
  const hearingDateRequiredByActionType = serverResponse.reduce((acc, crt) => {
    return {
      ...acc,
      [crt.id]: crt.hearingDateRequired,
    };
  }, {} as Record<number, boolean>);

  return {
    actionTypes: serverResponse.map((sr) => {
      return {
        id: sr.id,
        actionTypeName: sr.actionExhibitTypeName,
      };
    }),
    hearingDateRequiredByActionType,
    actionSubstatusesByActionType,
  };
}

export function serverToAppContractResponse(
  serverResponse: ContractActionType[],
): ContractActionSelectOptionsData {
  const allSubstatuses: ContractActionSubstatus[] = serverResponse.reduce(
    (acc, crt) => {
      const subs: ContractActionSubstatus[] = crt.contractActionSubStatuses.map(
        (sb) => ({
          id: sb.id,
          typeId: crt.id,
          contractActionSubStatusName: sb.contractActionSubStatusName,
        }),
      );
      return [...acc, ...subs];
    },
    [] as ContractActionSubstatus[],
  );
  const actionSubstatusesByActionType = groupBy(allSubstatuses, (sb) => sb.typeId);
  const hearingDateRequiredByActionType = serverResponse.reduce((acc, crt) => {
    return {
      ...acc,
      [crt.id]: crt.hearingDateRequired,
    };
  }, {} as Record<number, boolean>);

  return {
    contractActionTypes: serverResponse.map((sr) => {
      return {
        id: sr.id,
        contractActionTypeName: sr.contractActionTypeName,
        contractActionSubStatuses: sr.contractActionSubStatuses,
        contractStatusId: sr.contractStatusId,
        hearingDateRequired: sr.hearingDateRequired,
      };
    }),
    hearingDateRequiredByActionType,
    actionSubstatusesByActionType,
  };
}

export const actionSelectOptions = {
  getOptions: async () => {
    const serverResponse = await jsonFetchGet<ServerExhibitType[]>(
      '/v1/ActionExhibitTypes',
    );
    const response = mapOk(serverResponse, serverToAppResponse);
    return response;
  },
  getContractOptions: async () => {
    const serverResponse = await jsonFetchGet<ContractActionType[]>(
      '/v1/ContractActionTypes',
    );
    const response = mapOk(serverResponse, serverToAppContractResponse);
    return response;
  },
};

import { claims, contracts, fileInfo } from 'api/endpoints';
import { BatchEditUploadDialog } from 'components/BatchEditor/BatchEditUploadDialog';
import { BatchEditUploadForm } from 'components/BatchEditor/BatchEditUploadForm';
import { isNil } from 'lodash';
import { useBatchEditContextData } from 'pages/ClaimsContractsToolPage/sections/ClaimsTable/ClaimsBatchEdit/BatchEditContextProvider';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

// const downloadTemplateName = 'ContractBatchEdit.xlsx';

export type BatchUpdateResponse = {
  rowsUpdated?: number;
  errorId?: number;
};

interface Props {
  sectionType: SectionType;
  templateFileName: string;
}

export const BatchEditUploadPanel = ({ sectionType, templateFileName }: Props) => {
  const {
    setShowErrorModal,
    errorLogId,
    setShowUploadModal,
    showErrorModal,
    showUploadModal,
    onSubmit,
    isUploading,
    fileName,
  } = useBatchEditContextData();
  const hasErrors = !isNil(errorLogId);

  const handleDownloadErrorClick = async () => {
    if (isNil(errorLogId)) return;
    if (sectionType === SectionType.Contracts) {
      await contracts.downloadErrorLogs(errorLogId);
    } else if (sectionType === SectionType.Claims) {
      await claims.downloadErrorLogs(errorLogId);
    }
    setShowErrorModal(false);
  };

  const handleDownloadTemplate = () => fileInfo.downloadTemplate(templateFileName);

  return (
    <>
      <BatchEditUploadForm
        title={`Do you have a file with updated content for specific ${sectionType} ?`}
        hasError={hasErrors}
        onDownloadErrorClick={handleDownloadErrorClick}
        onDownloadTemplateClick={handleDownloadTemplate}
        setShowUploadModal={setShowUploadModal}
        isUploading={isUploading}
        fileName={fileName}
      />

      <BatchEditUploadDialog
        uploadLabel={`You can upload a file with the updated data/configuration for the selected ${sectionType}`}
        onDownloadError={handleDownloadErrorClick}
        onSubmit={onSubmit}
        setShowUploadModal={setShowUploadModal}
        showErrorModal={showErrorModal}
        showUploadModal={showUploadModal}
        setShowErrorModal={setShowErrorModal}
        isUploading={isUploading}
      />
    </>
  );
};

import { FileType, IUploadItem } from 'types/dataUploaderTypes';

export const mapFileTypeForDeleteSection = (file: IUploadItem) => {
  // we need to group multiple types into a single type for display and deletion

  switch (file.type) {
    case FileType.Schedule:
    case FileType.ScheduleAppend:
      return FileType.Schedule;
    case FileType.Sofa:
    case FileType.SofaAppend:
      return FileType.Sofa;
    case FileType.FiledClaims:
    case FileType.ScheduledClaims:
    case FileType.Category:
      return FileType.Claims;
    case FileType.Contract:
    case FileType.ContractCategory:
      return FileType.Contracts;
    default:
      return file.type;
  }
};

import { IMajorMinorValue, MajorCategories } from 'api/endpoints';
import { IOption } from 'components/UIComponents/MultiSelect';

export const mapStringToMajMinorValue = (value: string): IMajorMinorValue => {
  const [value1, value2] = value.split(',');

  return {
    majorCategoryId: value1 ? +value1 : null,
    minorCategoryId: value2 ? +value2 : null,
  };
};

export const mapMajMinorToString = (value: IMajorMinorValue) =>
  `${value.majorCategoryId ?? ''},${value.minorCategoryId ?? ''}`;

export const mapMajorCategoriesToOption = (major: MajorCategories): IOption => ({
  label: major.majorCategory,
  value: `${major.id},`,
  data: major,
  children: major.minorCategories?.map((minor) => ({
    data: minor,
    label: minor.minorCategory,
    value: `${major.id},${minor.id}`,
  })),
});

export const mapMajorCategories = (categories?: MajorCategories[]) =>
  categories?.map(mapMajorCategoriesToOption) ?? [];

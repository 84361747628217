export const PageNotFoundPage = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '30vh',
    }}
  >
    <h3>Page not found</h3>
  </div>
);

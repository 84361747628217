import { ContractRow } from 'api/endpoints';
import Excel, { Column } from 'exceljs';
import { createExcelWorkbook, getSortedSheetItems } from './excelUtils';
import {
  columnDefaults,
  IExhibitExcelCreator,
  ContractExhibitExport,
} from './templatesType';
import { formatDateAs } from 'utils/formatDate';

const SORT_KEY: keyof ContractRow = 'counterpartyName';

const COLUMN_LIST: Partial<Column>[] = [
  { header: 'Contract Number', width: 15, ...columnDefaults },
  { header: 'Counterparty name', width: 30, ...columnDefaults },
  { header: 'Address 1', width: 30, ...columnDefaults },
  { header: 'Address 2', width: 30, ...columnDefaults },
  { header: 'Address 3', width: 30, ...columnDefaults },
  { header: 'City', width: 30, ...columnDefaults },
  { header: 'State', width: 30, ...columnDefaults },
  { header: 'Zip', width: 30, ...columnDefaults },
  { header: 'Country', width: 30, ...columnDefaults },
  { header: 'Debtor', width: 40, ...columnDefaults },
  { header: 'Contract Type', width: 30, ...columnDefaults },
  { header: 'Description', width: 30, ...columnDefaults },
  { header: 'Cure Amount', width: 20, ...columnDefaults },
];

export const templateNonRealEstateRejection: IExhibitExcelCreator = {
  getWorkbook: (exhibit: ContractExhibitExport): Excel.Workbook | null => {
    const { includeRejDate } = exhibit;

    const workbook = createExcelWorkbook(
      'Non-Real Estate Rejection',
      includeRejDate
        ? [
            ...COLUMN_LIST,
            { header: 'Rejection Date', width: 30, ...columnDefaults },
          ]
        : [...COLUMN_LIST],
    );
    const firstSheet = workbook.getWorksheet(1);

    const sheetItems = getSortedSheetItems(exhibit.contracts, SORT_KEY).map((r) =>
      mapContractToExcelRow(r, includeRejDate),
    );

    sheetItems.forEach((item) => firstSheet.addRow(item));

    return workbook;
  },
};

const mapContractToExcelRow = (row: ContractRow, includeRejDate?: boolean) => {
  return [
    row.referenceNumber,
    row.counterpartyName,
    row.address1,
    row.address2,
    row.address3,
    row.city,
    row.state,
    row.zip,
    row.country,
    row.primaryDebtor,
    row.contractType,
    row.description,
    row.negotiatedCureAmount,
    includeRejDate ? formatDateAs(row.rejectionDate, 'MM-DD-YYYY') : undefined,
  ];
};

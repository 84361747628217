import { useEffect, useState } from 'react';

interface IProps<T> {
  queryFn: () => Promise<T>;
  skipAutofetch?: boolean;
  initialValue?: T;
}

//todo: delete this hook and use @tanstack/react-query library instead
export const useApiQuery = <T>({
  skipAutofetch,
  initialValue,
  queryFn,
}: IProps<T>) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(initialValue);

  useEffect(() => {
    if (skipAutofetch) return;

    const abort = new AbortController();
    fetch(abort.signal);
    return () => abort.abort();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = async (abortSignal?: AbortSignal) => {
    try {
      setLoading(true);
      const result = await queryFn();
      if (abortSignal?.aborted) return;

      setData(result);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, setData, fetch };
};

import { SofaModal } from 'pages/SOFAsAndSchedules/Components';

interface Props {
  onClose: () => void;
}

export const ModalFormatDetails = ({ onClose }: Props) => {
  return (
    <SofaModal header="What format should my data be in?" hasClosed={onClose}>
      <div>Your data file should be uploaded in .xlsx format.</div>
      <div>
        To pass validation your file must be in a specific column structure. Please
        download the appropriate template
      </div>
      <p />
    </SofaModal>
  );
};

import keyBy from 'lodash/keyBy';
import { ClaimRow, CounterpartyRowWithPathId } from 'api/endpoints';

function calculateClaims(
  claims: ClaimRow[],
  counterparties: CounterpartyRowWithPathId[],
): ClaimRow[] {
  const counterpartiesObj = keyBy(counterparties, 'claimId');
  return claims.map((c) => ({
    ...c,
    hasOneHundredPercentMatch:
      counterpartiesObj[c.id]?.hasOneHundredPercentMatch || false,
    path: [c.id],
  }));
}

export default calculateClaims;

import {
  IMultiSelectProps,
  IOption,
  LabeledMultiSelectExtendable,
} from 'components/UIComponents/MultiSelectExtendable';

type Props = Pick<IMultiSelectProps, 'onChange' | 'selected'> & {};

export function TemplateExportMultiselect(props: Props) {
  const { selected, onChange } = props;

  const options: IOption[] = [
    { label: 'SOFA', value: 'sofa' },
    { label: 'Schedule AB', value: 'scheduleAB' },
    { label: 'Schedule D', value: 'scheduleD' },
    { label: 'Schedule EF', value: 'scheduleEF' },
    { label: 'Schedule G', value: 'scheduleG' },
    { label: 'Schedule H', value: 'scheduleH' },
  ];

  return (
    <LabeledMultiSelectExtendable
      topLabel="Search for template"
      options={options}
      multiple={false}
      selected={selected}
      onChange={onChange}
    />
  );
}

import { DiscloseLinkMenu } from 'components/DisclosureLinkMenu/DiscloseLinkMenu';
import { MenuItem } from 'components/DisclosureLinkMenu/DisclosureLinkMenu.types';

export type Props = {
  items: MenuItem[];
};

export function SOFAsAndSchedulesMenu({ items }: Props) {
  return (
    <aside className="sofas-schedules__menu thin-scrollbar">
      {items.map((item, index) => {
        return <DiscloseLinkMenu key={`${item.baseLink}-${index}`} item={item} />;
      })}
    </aside>
  );
}

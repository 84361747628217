import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import './UploadProgress.css';

const labelOffset = 20; //percent

export type ProgressProps = {
  value?: number;
};

export const UploadProgress = ({ value }: ProgressProps) => {
  const indicatorRef = useRef<HTMLElement>(null);

  const percent = useMemo(() => {
    if (_.isNil(value) || value < 0) return undefined;
    const result = Math.ceil(value * 100);
    return result <= 100 ? result : 100;
  }, [value]);

  useEffect(() => {
    const percentFraction = (percent ?? 0) / 100;
    const progresPercent = labelOffset + (100 - labelOffset) * percentFraction;
    indicatorRef.current?.style.setProperty(
      '--progress-percent',
      `${progresPercent}%`,
    );
  }, [percent]);

  return (
    <>
      <div
        className={classNames('upload-progress__container', {
          'upload-progress__hide': !!percent,
        })}
      >
        <span className="upload-progress__body">
          <span ref={indicatorRef} className="upload-progress__progress-bar" />
          File(s) uploading{'  '}
          {percent}%
        </span>
      </div>
    </>
  );
};

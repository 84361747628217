import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ContractsBaseDetailedData } from 'pages/Dashboard/types';
import { DetailedItemContent } from './DetailedItemContent';

export const DetailedSubItems = ({
  subItems,
}: {
  subItems: ContractsBaseDetailedData[];
}) => {
  return (
    <div className="detailed-item__secondary-subitems">
      {subItems.map((item, idx) => (
        <DetailedItemContent
          key={`${item.label}_${idx}`}
          data={item}
          // only Contracts have subitems
          sectionType={SectionType.Contracts}
        />
      ))}
    </div>
  );
};

import calculateCounterparties from 'pages/ClaimsContractsToolPage/util/calculateCounterparties';
// import { CounterpartyMatchStatus } from 'api/endpoints';

import {
  ContractsDataState,
  SectionType,
} from 'pages/ClaimsContractsToolPage/types';
import { GridColumn, CounterpartyRow } from 'api/endpoints';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processGridColumns } from 'utils/processGridColumns';

const CLAIMS_DATA_DEFAULT_STATE = {
  data: [],
  counterparties: [],
  section: undefined,
};
export const initialState: ContractReducerState = {
  loading: true,
  data: CLAIMS_DATA_DEFAULT_STATE,
  section: undefined,
};

export interface ContractReducerState {
  data: ContractsDataState;
  error?: string;
  loading: boolean;
  section?: SectionType;
}

export interface ContractsDataStateWithEmpty
  extends Omit<ContractsDataState, 'gridColumns' | 'counterparties'> {
  gridColumns?: GridColumn[];
  counterparties?: CounterpartyRow[];
}

const contractsSlice = createSlice({
  name: 'contracts',
  initialState: initialState,
  reducers: {
    contractsFetch(state, action) {
      state.loading = true;
    },
    contractsLoaded(state, action: PayloadAction<ContractsDataStateWithEmpty>) {
      state.loading = false;
      state.data = {
        ...action.payload,
        gridColumns: processGridColumns(action.payload.gridColumns),
        counterparties: action.payload.counterparties
          ? calculateCounterparties(action.payload.counterparties)
          : [],
      };
    },
    setSection: (state, action: PayloadAction<SectionType | undefined>) => {
      state.section = action.payload;
    },
    setRelatedContractsNumber(state, action: PayloadAction<number>) {
      state.data.relatedContracts = action.payload;
    },
  },
});

export const {
  contractsFetch,
  contractsLoaded,
  setSection,
  setRelatedContractsNumber,
} = contractsSlice.actions;
export default contractsSlice.reducer;

import { snack } from '@alixpartners/ui-components';

export const warning = (message: string) =>
  snack.warning({
    message,
    showIcon: true,
  });

export const info = (message: string) =>
  snack.info({
    message,
    showIcon: true,
  });

export const success = (message: string) =>
  snack.success({
    message,
    showIcon: true,
  });

export const error = (message: string) =>
  snack.error({
    message,
    showIcon: true,
  });

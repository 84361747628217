import { DynamicField, DynamicFieldFilter } from 'api/endpoints/dynamic-fields';
import { OnEditSave } from 'components/UIComponents/EntityTable/EntityTable.types';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useCallback, useEffect, useMemo } from 'react';
import { DEFUALT_INPUT } from 'reducer/dynamicFields';
import {
  editDynamicFieldsThunk,
  FetchDynamicFieldsInput,
  fetchDynamicFieldsThunk,
  filterDynamicFields,
} from 'reducer/thunks/dynamicFields-thunks';
import { FetchStatus } from 'types/fetch-status.types';
import { SectionType } from '../../../ClaimsContractsToolPage/types';
import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';

export type UseDynamicFieldsReturn = {
  data: DynamicField[];
  status: FetchStatus;
};

export function useEditAndFilterDynamicFields(section: SectionType) {
  const dispatch = useAppDispatch();
  const { slice, filter } = useDynamicFields(DEFUALT_INPUT, section);

  const onEditSave: OnEditSave<DynamicField> = useCallback(
    async (formValues, item: DynamicField) => {
      return dispatch(
        editDynamicFieldsThunk(
          {
            id: item.id,
            enabled: formValues.enabled === 'on',
            name: (formValues.name ?? item.name) as string,
            displayIn: (formValues.displayIn ?? item.displayIn) as PageSections,
            type: item.type,
          },
          section,
        ),
      );
    },
    [dispatch, section],
  );

  const onFilter = useCallback(
    async (filterValues) => {
      const filterType = filterValues.type as DynamicFieldFilter;
      filter({ filter: { type: filterType } });
    },
    [filter],
  );

  const formatDynamicField = useCallback(
    (field: DynamicField) => ({
      label: field.name,
      field: field.propertyName,
      dateField: field.propertyName.includes('DateTime'),
      amountField: field.propertyName.includes('Decimal'),
      toggle: field.propertyName.includes('Boolean'),
      hyperlinkField: field.propertyName.includes('customUrl'),
    }),
    [],
  );

  return {
    onEditSave,
    onFilter,
    status: slice.type,
    claimsData: slice.claimsData,
    contractsData: slice.contractsData,
    fetching: slice.type === FetchStatus.Fetching,
    formatDynamicField,
  };
}

export function useDynamicFields(
  input: FetchDynamicFieldsInput = DEFUALT_INPUT,
  section: SectionType,
) {
  const slice = useAppSelector((s) => s.dynamicFields);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (slice.type === FetchStatus.Idle) {
      dispatch(fetchDynamicFieldsThunk(input, section));
    }
  }, [dispatch, input, slice.type, section, slice]);

  return useMemo(() => {
    return {
      slice,
      filter: (fetchInput: FetchDynamicFieldsInput) => {
        dispatch(filterDynamicFields(fetchInput, section));
      },
    };
  }, [dispatch, section, slice]);
}

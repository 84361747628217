import _ from 'lodash';
import { IBatchEditableColumns } from './claimsBatchEdit.type';

//constants for propeties is used in the code to find proper item
export enum colProp {
  majorCategory = 'categoryId',
  actionStatus = 'actionStatus',
  survivingClaimNumber = 'survivingClaimNumber',
}

export const DEFAULT_ADDRESS_FIELDS: IBatchEditableColumns[] = [
  {
    propertyName: 'address1',
    displayName: 'Address 1',
    dataType: 'string',
  },
  {
    propertyName: 'address2',
    displayName: 'Address 2',
    dataType: 'string',
  },
  {
    propertyName: 'address3',
    displayName: 'Address 3',
    dataType: 'string',
  },
  {
    propertyName: 'address4',
    displayName: 'Address 4',
    dataType: 'string',
  },
  {
    propertyName: 'city',
    displayName: 'City',
    dataType: 'string',
  },
  {
    propertyName: 'state',
    displayName: 'State',
    dataType: 'string',
  },
  {
    propertyName: 'country',
    displayName: 'Country',
    dataType: 'string',
  },
  {
    propertyName: 'zip',
    displayName: 'ZIP',
    dataType: 'string',
  },
];

const EDITABLE_COLUMNS: IBatchEditableColumns[] = [
  {
    propertyName: colProp.majorCategory,
    displayName: 'Major/minor categories',
    dataType: 'maj-min',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: 'actionStatus',
    displayName: 'Workflow Status',
    dataType: 'enum',
    // allowedValues: allowedValuesActionStatus,
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: 'actionNameId',
    displayName: 'Resolution Name',
    dataType: 'action-name-id',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: 'claimImageUrl',
    displayName: 'Claim image',
    dataType: 'string',
  },
  {
    // claims reviewer moved under action status as per: https://alixpartners-dev.visualstudio.com/AP.Chapter11Platform/_workitems/edit/101806
    propertyName: 'claimsReviewer',
    displayName: 'Claim reviewer',
    dataType: 'group-multi-select',
  },
  {
    propertyName: 'survivingClaimNumber',
    displayName: 'Surviving Claim Number',
    dataType: 'string',
  },
  // == alixPartners assessed ==
  {
    propertyName: 'alixPartnersSecured',
    displayName: 'AlixPartners Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'alixPartnersAdministrative',
    displayName: 'AlixPartners Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'alixPartnersPriority',
    displayName: 'AlixPartners Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'alixPartnersUnsecured',
    displayName: 'AlixPartners Unsecured',
    dataType: 'decimal',
  },
  // == Client assessed ==
  {
    propertyName: 'clientSecured',
    displayName: 'Client Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'clientAdministrative',
    displayName: 'Client Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'clientPriority',
    displayName: 'Client Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'clientUnsecured',
    displayName: 'Client Unsecured',
    dataType: 'decimal',
  },
  // == Counsel assessed ==
  {
    propertyName: 'counselSecured',
    displayName: 'Counsel Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'counselAdministrative',
    displayName: 'Counsel Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'counselPriority',
    displayName: 'Counsel Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'counselUnsecured',
    displayName: 'Counsel Unsecured',
    dataType: 'decimal',
  },
  {
    propertyName: 'counterpartyName',
    displayName: 'Counterparty Name',
    dataType: 'string',
  },
  // == Proposed ==
  {
    propertyName: 'proposedSecured',
    displayName: 'Proposed Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedAdministrative',
    displayName: 'Proposed Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedPriority',
    displayName: 'Proposed Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedUnsecured',
    displayName: 'Proposed Unsecured',
    dataType: 'decimal',
  },
  {
    propertyName: 'comment',
    displayName: 'Comment',
    dataType: 'string',
  },
  {
    propertyName: 'notes',
    displayName: 'Notes',
    dataType: 'string',
  },
  {
    propertyName: 'settlementNumber',
    displayName: 'Settlement Number',
    dataType: 'string',
  },
  ...DEFAULT_ADDRESS_FIELDS,
];

export const EDITABLE_COLUMNS_SORTED = _.sortBy(
  EDITABLE_COLUMNS,
  (r) => r.displayName,
);

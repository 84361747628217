import { GlobalNote } from 'api/endpoints';
import classNames from 'classnames';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { useCallback, useState } from 'react';

import { ActionListItem, FileDisplay } from '../../../Components/ActionList';
import { GlobalNoteItemDetails } from './GlobalNoteItemDetails';
import { GlobalNoteItemInfo } from './GlobalNoteItemInfo';
import './NoteListItem.css';

interface Props {
  item: GlobalNote;
  onEditClick: (item: GlobalNote) => void;
  onDownloadClick: (item: GlobalNote) => void;
  onDeleteClick: (item: GlobalNote) => void;
}

export function NoteListItem({
  item,
  onEditClick,
  onDownloadClick,
  onDeleteClick,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleEditClick = useCallback(() => onEditClick(item), [item, onEditClick]);
  const handleDownloadClick = useCallback(
    () => onDownloadClick(item),
    [item, onDownloadClick],
  );
  const handleDeleteClick = useCallback(
    () => onDeleteClick(item),
    [item, onDeleteClick],
  );

  return (
    <div data-expanded={expanded} className={classNames('slip-sheet-list-item')}>
      <ActionListItem
        key={item.id}
        id={item.id}
        displayName={item.displayName}
        newlyAdded={false}
        onEditClick={handleEditClick}
        onDownloadClick={handleDownloadClick}
        onDeleteClick={handleDeleteClick}
      >
        <span className="action-list-item__actions ">
          <ButtonIcon
            onClick={() => setExpanded((ep) => !ep)}
            className="globalnote-listitem__expand_btn"
            type="button"
            icon="chevron_left"
            iconColor="var(--ap-color-ui-grey-light)"
          />
          <span className="action-list-item__actions__divider" />
        </span>
        <FileDisplay displayName={item.displayName} />
        <GlobalNoteItemInfo item={item} />
      </ActionListItem>
      {expanded ? <GlobalNoteItemDetails item={item} /> : null}
    </div>
  );
}

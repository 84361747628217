import Excel, { Column } from 'exceljs';
import { ContractExhibit } from '../exhibityContractType';
import { createExcelWorkbook, getSortedSheetItems } from './excelUtils';
import { columnDefaults, IExhibitExcelCreator } from './templatesType';
import { ContractRow } from 'api/endpoints';

const SORT_KEY: keyof ContractRow = 'counterpartyName';

const COLUMN_LIST: Partial<Column>[] = [
  { header: 'Contract Number', width: 15, ...columnDefaults },
  { header: 'Counterparty', width: 30, ...columnDefaults },
  { header: 'Description', width: 30, ...columnDefaults },
  { header: 'Address 1', width: 30, ...columnDefaults },
  { header: 'Address 2', width: 30, ...columnDefaults },
  { header: 'Address 3', width: 30, ...columnDefaults },
  { header: 'City', width: 30, ...columnDefaults },
  { header: 'State', width: 30, ...columnDefaults },
  { header: 'Zip', width: 30, ...columnDefaults },
  { header: 'Country', width: 30, ...columnDefaults },
  { header: 'Debtor', width: 40, ...columnDefaults },
  { header: 'Cure Amount', width: 20, ...columnDefaults },
];

export const templateNonRealEstateAssumption: IExhibitExcelCreator = {
  getWorkbook: (exhibit: ContractExhibit): Excel.Workbook | null => {
    const workbook = createExcelWorkbook('Non Real Estate Assumption', COLUMN_LIST);
    const firstSheet = workbook.getWorksheet(1);

    const sheetItems = getSortedSheetItems(exhibit.contracts, SORT_KEY).map((r) =>
      mapContractToExcelRow(r),
    );
    sheetItems.forEach((r) => firstSheet.addRow(r));

    return workbook;
  },
};

const mapContractToExcelRow = (row: ContractRow) => {
  return [
    row.referenceNumber,
    row.counterpartyName,
    row.description,
    row.address1,
    row.address2,
    row.address3,
    row.city,
    row.state,
    row.zip,
    row.country,
    row.primaryDebtor,
    row.negotiatedCureAmount,
  ];
};

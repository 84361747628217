import { ApLoaderDefault } from '@alixpartners/ui-components';
import './LoadingMenuItem.css';

interface Props {
  text: string;
}

export const LoadingMenuItem = ({ text }: Props) => {
  return (
    <div className="loading-menu-item">
      <ApLoaderDefault loaderSize="sm" className="loading-menu-item__animation" />
      <div>Generating Excel file</div>
    </div>
  );
};

import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './WarningText.css';

interface Props {
  message: string;
  className?: string;
  iconSize?: number;
}

/**
 * Displays a warning text with a yellow error icon.
 * Can be used "inline" with other text.
 */

export const WarningText = ({ message, className, iconSize = 18 }: Props) => {
  return (
    <span className={classNames('warning-text', className)}>
      <ApIcon iconName="error" iconColor="#D97706" iconSize={iconSize} />
      <span>{message}</span>
    </span>
  );
};

import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import {
  ContractCategoryItemBase,
  EditContractCategoryRequest,
  contractCategories,
  contracts,
} from 'api/endpoints';
import { isEmpty } from 'lodash';
import {
  editCategoryDone,
  fetchDropdownCategoriesDone,
  fetchingAllCategories,
  fetchAllCategoriesDone,
} from 'reducer/contractCategoriesReducer';
import { selectableLoaded } from 'reducer/selectableDataReducer';

import { AppDispatch, GetState } from 'reducer/store';

export const getContractsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    const [contractCategories, contractTypes] = await Promise.all([
      contracts.getContractsCategories(),
      contracts.getContractTypes(),
    ]);
    if (contractCategories?.length && contractTypes?.length) {
      dispatch(
        selectableLoaded({
          contractCategories,
          contractTypes,
        }),
      );
    }
  };

export const getDropdownContractsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractCategories.getDropdownCategories();
    if (response?.length) {
      dispatch(fetchDropdownCategoriesDone(response));
    }
  };

export const saveContractsCategoryThunk =
  (newItem: ContractCategoryItemBase) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractCategories.addNewCategory(newItem);
    if (response?.data) {
      dispatch(getAllContractsCategoriesThunk());
      dispatch(getDropdownContractsCategoriesThunk());
    } else if (response.error) {
      showHttpSingleError(response.error);
    }
  };

export const getAllContractsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(fetchingAllCategories(true));
    const response = await contractCategories.getAllCategories();
    if (response?.length) {
      dispatch(
        fetchAllCategoriesDone(
          response.map((r) => ({ ...r, id: r.contractTypeId })),
        ),
      );
    }
    dispatch(fetchingAllCategories(false));
  };

export const editContractCategoryThunk =
  (values: EditContractCategoryRequest) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractCategories.editCategory(values);
    if (response?.data) {
      dispatch(editCategoryDone(values));
    } else if (response.error) {
      showHttpSingleError(response.error);
    }
  };

export const setToggleAllThunk =
  (toggle: boolean) => async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractCategories.setToggleAll(toggle);
    if (isEmpty(response)) {
      dispatch(getAllContractsCategoriesThunk());
    }
  };

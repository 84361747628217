import Excel, { Column } from 'exceljs';
import { ContractExhibit } from '../exhibityContractType';
import { createExcelWorkbook, getSortedSheetItems } from './excelUtils';
import { columnDefaults, IExhibitExcelCreator } from './templatesType';
import { ContractRow } from 'api/endpoints';

const COLUMN_LIST: Partial<Column>[] = [
  { header: 'Contract Number', width: 15, ...columnDefaults },
  { header: 'Counterparty Name', width: 30, ...columnDefaults },
  { header: 'Address1', width: 30, ...columnDefaults },
  { header: 'Address2', width: 30, ...columnDefaults },
  { header: 'Address3', width: 30, ...columnDefaults },
  { header: 'City', width: 30, ...columnDefaults },
  { header: 'State', width: 30, ...columnDefaults },
  { header: 'Zip', width: 30, ...columnDefaults },
  { header: 'Country', width: 30, ...columnDefaults },
  { header: 'Leased Property Address1', width: 30, ...columnDefaults },
  { header: 'Leased Property Address2', width: 30, ...columnDefaults },
  { header: 'Leased Property Address3', width: 30, ...columnDefaults },
  { header: 'Leased Property City', width: 30, ...columnDefaults },
  { header: 'Leased Property State', width: 30, ...columnDefaults },
  { header: 'Leased Property Zip', width: 30, ...columnDefaults },
  { header: 'Leased Property Country', width: 30, ...columnDefaults },
  { header: 'Debtor', width: 30, ...columnDefaults },
  { header: 'Cure Amount', width: 30, ...columnDefaults },
];

const SORT_KEY: keyof ContractRow = 'counterpartyName';

export const templateRealEstateAssumption: IExhibitExcelCreator = {
  getWorkbook: (exhibit: ContractExhibit): Excel.Workbook | null => {
    const workbook = createExcelWorkbook('Real Estate Assumption', COLUMN_LIST);
    const firstSheet = workbook.getWorksheet(1);

    const sheetItems = getSortedSheetItems(exhibit.contracts, SORT_KEY).map((r) =>
      mapContractToExcelRow(r),
    );

    sheetItems.forEach((item) => firstSheet.addRow(item));

    return workbook;
  },
};

const mapContractToExcelRow = (row: ContractRow) => {
  return [
    row.referenceNumber, // -- Contract Ref Number
    row.counterpartyName, // -- Lessor
    row.address1, // -- Address1
    row.address2, // -- Address2
    row.address3, // -- Address3
    row.city, // -- City
    row.state, // -- State
    row.zip, // -- Zip
    row.country, // -- Country
    row.leasedPropertyAddress1, // -- Leased Property Address1
    row.leasedPropertyAddress2, // -- Leased Property Address2
    row.leasedPropertyAddress3, // -- Leased Property Address3
    row.leasedPropertyCity, // -- Leased Property City
    row.leasedPropertyState, // -- Leased Property State
    row.leasedPropertyZip, // -- Leased Property Zip
    row.leasedPropertyCountry, // -- Leased Property Country
    row.primaryDebtor, // -- Debtor
    row.negotiatedCureAmount, // -- Cure Amount
  ];
};

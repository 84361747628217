import {
  ApButtonDanger,
  ApButtonGroup,
  ApButtonMain,
  ApIcon,
  ApModal,
} from '@alixpartners/ui-components';

interface Props {
  title: string;
  message: string;
  disabled: boolean;
  mainBtnText: string;
  dangerBtnText: string;
  onConfirm: (confirmed: boolean) => void;
}

export const WarningModal = ({
  title,
  message,
  disabled,
  mainBtnText,
  dangerBtnText,
  onConfirm,
}: Props) => {
  const handleConfirmClick = () => onConfirm(true);
  const handleCancelClick = () => onConfirm(false);

  return (
    <ApModal
      isDismissible={false}
      isFluid={false}
      isOpen={true}
      zIndex={998}
      hasClosed={handleCancelClick}
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonMain onClick={handleCancelClick} disabled={disabled}>
            {mainBtnText}
          </ApButtonMain>
          <ApButtonDanger onClick={handleConfirmClick} disabled={disabled}>
            {dangerBtnText}
          </ApButtonDanger>
        </ApButtonGroup>
      )}
    >
      <h3 style={customHeaderStyle}>
        <ApIcon iconName="outline_warning" iconColor="orange" />
        {title}
      </h3>
      <div>{message}</div>
    </ApModal>
  );
};

const customHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  textIndent: '8px',
};

import {
  ApButtonGroup,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';
import './DetailsModal.css';

type ApModalProps = React.ComponentProps<typeof ApModal>;

interface IProps extends ApModalProps {
  title: string;
  closeBtn?: string;
  onClose: () => void;
}

export const DetailsModal = ({
  title,
  children,
  closeBtn = 'Cancel',
  onClose,
  ...rest
}: IProps) => {
  return (
    <ApModal
      zIndex={998}
      isFluid
      {...rest}
      footer={({ handleClose }) => (
        <ApButtonGroup className="ch11-detailsmodal__footer">
          <ApButtonSecondary onClick={onClose}>{closeBtn}</ApButtonSecondary>
        </ApButtonGroup>
      )}
    >
      <div className="ch11-detailsmodal">
        <h4>{title}</h4>
        {children}
      </div>
    </ApModal>
  );
};

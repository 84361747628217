import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { REFERENCE_NUMBER_COLUMN } from '../../const';
import { CellClickedEvent } from 'ag-grid-community';
import { ContractRow } from 'api/endpoints';
import { SectionType, SubsectionType } from '../../types';
import { buildSingleContractPath } from 'utils/hooks/useContractToolQueryParams';

function useRefClick(section: SectionType, subsection: SubsectionType) {
  const navigate = useNavigate();

  const onRefCellClickedInjector = useCallback(
    (event: CellClickedEvent<ContractRow>) => {
      const { column, data } = event;
      if (column.getColDef().field !== REFERENCE_NUMBER_COLUMN) return;
      const contractId = data?.id;
      if (!contractId) return;
      navigate(buildSingleContractPath(data?.id, section, subsection));
    },
    [navigate, section, subsection],
  );
  return {
    onRefCellClickedInjector,
  };
}

export default useRefClick;

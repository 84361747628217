import Excel from 'exceljs';
import _ from 'lodash';
import { createArrayRange } from 'utils/array';
import { textTrimEndingBy } from 'utils/stringUtils';
import { dividerStyle } from './excelConst';
import { ExcelDocumentHeader } from './excelCreateWorkbook';
import { titleStyle } from './excelSheetStyle';
import { IHeaderFooter, IHeaderFooterField, IRowValues, TRowStyle } from './type';

export const excelAddTableHeaderRow = (
  sheet: Excel.Worksheet,
  columns: ExcelDocumentHeader[],
  style = titleStyle,
) => {
  const values = columns.map((r) => r.header);
  const headerRow = sheet.addRow(values);
  headerRow.height = 35;
  headerRow.eachCell((cell) => (cell.style = style));

  sheet.pageSetup.printTitlesRow = `1:${sheet.rowCount}`;
};

export function excelAddRow(
  sheet: Excel.Worksheet,
  row: IRowValues,
  style: TRowStyle,
) {
  if (!row) return;

  addSpaceRow(sheet); //space before
  const dataRow = sheet.addRow(row.values);
  applyCellsStyle(dataRow, style);

  row.additional?.forEach((additionalRows) => {
    addSpaceRow(sheet);
    const dataRow = sheet.addRow(additionalRows);
    applyCellsStyle(dataRow, style);
  });

  const spaceAfter = addSpaceRow(sheet);
  applyDividerStyle(spaceAfter, row.values.length);
}

export function excelAddTotalRow(
  sheet: Excel.Worksheet,
  row: IRowValues | undefined,
  style: TRowStyle,
) {
  if (!row) return;

  addSpaceRow(sheet);
  const dataRow = sheet.addRow(row.values);
  applyCellsStyle(dataRow, style);
}

const hintText =
  '(1) (S) Secured; (A) Administrative; (P) Priority; (U) Unsecured; (T) Total';

export function excelAddPageFooter(
  sheet: Excel.Worksheet,
  headerFooter: IHeaderFooter,
) {
  const bottom = trimFooterToProperLength(headerFooter.bottom);
  const bottomFirst = trimFooterToProperLength(headerFooter.bottomFirst);

  const oddFooter =
    `&L&8&"Times New Roman"${bottom.left}\n${hintText}` +
    `&C&8&"Times New Roman"${bottom.middle}` +
    `&R&8${bottom.right}`;

  const firstFooter = bottomFirst
    ? `&L&8&"Times New Roman"${bottomFirst.left}\n${hintText}` +
      `&C&8&"Times New Roman"${bottomFirst.middle}` +
      `&R&8${bottomFirst.right}`
    : undefined;

  sheet.headerFooter = {
    differentFirst: true,
    oddFooter,
    firstFooter: firstFooter ?? oddFooter,
  };
}

export function deleteObjectionCommentaryColumn(
  sheet: Excel.Worksheet,
  displayObjection: boolean | undefined,
  objectionColId: number,
) {
  if (displayObjection) return;

  const objectionColumn = sheet.getColumn(objectionColId);
  const objectionWidth = objectionColumn.width ?? 0;

  // !!! WARNING !!! If coulumn is deleted - it could break the page header. (exceljs framework limitation)
  // So let's hide it instead.
  objectionColumn.width = 0;
  objectionColumn.values = [];

  // let's skip the first ID colum
  adjustExcelColumnsToFitPageWidth(sheet, 2, objectionColId - 1, objectionWidth);
}

function adjustExcelColumnsToFitPageWidth(
  sheet: Excel.Worksheet,
  startColId: number,
  endColId: number,
  widthToReduce: number,
) {
  if (widthToReduce < 1) return;

  const columns = createArrayRange(startColId, endColId).map((id) =>
    sheet.getColumn(id),
  );
  const visibleColWidth = _.sum(columns.map((col) => col.width));
  const increaseMultiplier = visibleColWidth / (visibleColWidth - widthToReduce);

  if (increaseMultiplier <= 1) return;

  columns.forEach((col) => {
    col.width = Math.trunc(col.width! * increaseMultiplier);
  });
}

function addSpaceRow(sheet: Excel.Worksheet) {
  const row = sheet.addRow([]);
  row.height = 5;
  return row;
}

function applyDividerStyle(row: Excel.Row, length: number) {
  for (let id = 1; id <= length; id++) {
    row.getCell(id).style = dividerStyle;
  }
}

const applyCellsStyle = (row: Excel.Row, rowStyle: TRowStyle) =>
  row.eachCell((cell, id) => (cell.style = rowStyle[id - 1]));

// !!!Excel has limitation!!! - footer length cant be longer than 190 characters.
const FOOTER_MAX_LENGTH = 190;
//amount of caracters availiable for user text
const FOOTER_USER_TEXT_LENGTH = FOOTER_MAX_LENGTH - hintText.length;

function trimFooterToProperLength<T extends IHeaderFooterField | undefined>(
  footerFiled: T,
): T {
  if (!footerFiled) return footerFiled;

  // footer filed in the excel file can't be longer than specific count of characters
  const { left, middle } = footerFiled;
  const totalLength = left.length + middle.length;
  const caractersToTrim = totalLength - FOOTER_USER_TEXT_LENGTH;
  if (caractersToTrim <= 0) return footerFiled;

  if (left.length > middle.length) {
    return {
      ...footerFiled,
      left: textTrimEndingBy(left, caractersToTrim),
    };
  } else {
    return {
      ...footerFiled,
      middle: textTrimEndingBy(middle, caractersToTrim),
    };
  }
}

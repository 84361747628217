import 'components/UIComponents/css-animations.css';
import { EntityContextProvider, useGridTemplateColumns } from './EntityContext';
import './EntityTable.css';
import { EntityTableProps, IdEntity } from './EntityTable.types';

export const EntityTable = <T extends IdEntity>(props: EntityTableProps<T>) => {
  const { className, children, ...rest } = props;
  const maxColumnSize = Math.max(
    rest?.addColumnKeys?.length ?? 0,
    rest?.filterColumnKeys?.length ?? 0,
    rest?.editColumnKeys?.length ?? 0,
  );
  const styles = useGridTemplateColumns(maxColumnSize);

  return (
    <EntityContextProvider {...rest}>
      <div style={styles} className={className}>
        {children}
      </div>
    </EntityContextProvider>
  );
};

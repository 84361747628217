import { ICellRendererParams } from 'ag-grid-community';
import { SingleSettlementResponse } from 'api/endpoints/settlements';
import { URL_PATH } from 'appUrls';
import { Link } from 'react-router-dom';
import './SettlementLinkCell.css';

type Props = ICellRendererParams<SingleSettlementResponse, string>;

export const SettlementLinkCell = ({ value, data }: Props) => {
  const id = data?.id;
  return (
    <Link
      to={`${URL_PATH.CLAIMS_SETTLEMENT}/view/${id}`}
      className="settlement-link-cell"
    >
      <span>{value}</span>
    </Link>
  );
};

import { InputText } from 'components/UIComponents/InputText/InputText';
import Select from 'components/UIComponents/Select';

const LABEL_TEXT = 'Value';
type TOnChange = (val: string) => void;

interface IInputProps {
  value: string | undefined;
  onChange: TOnChange;
}

export const ItemEditNumber = ({ value, onChange }: IInputProps) => {
  const handleChange = (r: React.ChangeEvent<HTMLInputElement>) => {
    r.preventDefault();
    const num = Number.parseInt(r?.target?.value);
    const intNum = Math.trunc(num);
    onChange(intNum.toString());
  };

  return (
    <InputText
      value={value}
      label={LABEL_TEXT}
      type={'number'}
      onChange={handleChange}
    />
  );
};

export const ItemEditDecimal = ({ value, onChange }: IInputProps) => (
  <InputText
    value={value}
    label={LABEL_TEXT}
    step="1"
    type={'number'}
    onChange={handleInputChange(onChange)}
  />
);

export const ItemEditDate = ({ value, onChange }: IInputProps) => (
  <InputText
    value={value}
    label={LABEL_TEXT}
    type={'date'}
    onChange={handleInputChange(onChange)}
  />
);

export const ItemEditDateTime = ({ value, onChange }: IInputProps) => (
  <InputText
    value={value}
    label={LABEL_TEXT}
    type={'datetime-local'}
    onChange={handleInputChange(onChange)}
  />
);

export const ItemEditText = ({ value, onChange }: IInputProps) => (
  <InputText
    value={value}
    label={LABEL_TEXT}
    type={'text'}
    onChange={handleInputChange(onChange)}
  />
);

export const ItemEditBoolean = ({ value, onChange }: IInputProps) => {
  return (
    <Select
      value={value}
      label={LABEL_TEXT}
      options={boolOptionsList}
      onChange={handleSelectChange(onChange)}
    />
  );
};

export const ItemEditDisabled = () => (
  <InputText label={LABEL_TEXT} type={'text'} disabled />
);

export const ItemEditUnknown = () => (
  <InputText label={LABEL_TEXT} placeholder="Unknown type" type={'text'} disabled />
);

// ============  internal utils  ==============
const boolOptionsList = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const handleInputChange =
  (onChange: TOnChange) => (r: React.ChangeEvent<HTMLInputElement>) => {
    r.preventDefault();
    onChange(r?.target?.value);
  };

const handleSelectChange =
  (onChange: TOnChange) => (r: React.ChangeEvent<HTMLSelectElement>) => {
    r.preventDefault();
    onChange(r?.target?.value);
  };

import './UserLogs.css';
import { useEffect } from 'react';
import { SettingsContentPanel } from '../components/SettingsContentPanel';
import { useUserLogsData } from './hooks/useUserLogsData';
import { UserLogsSection } from './components/UserLogsSection';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { FetchStatus } from 'types/fetch-status.types';
import { fetchUserLogsThunk } from 'reducer/thunks/userLogsThunks';

export const UserLogs = () => {
  const { apUsersCount, clientUsersCount, downloadReport } = useUserLogsData();
  const dispatch = useAppDispatch();
  const { status, users } = useAppSelector((s) => s.userLogs);

  useEffect(() => {
    if (status === FetchStatus.Idle) {
      dispatch(fetchUserLogsThunk());
    }
  }, [dispatch, status]);

  return (
    <SettingsContentPanel className="user-logs">
      <section className="anim-appear">
        <h3 className="user-logs__title">
          User Accounts ({users?.length})
          <ApButtonCasual iconName="save_alt" onClick={downloadReport}>
            Export last month's report
          </ApButtonCasual>
        </h3>

        <div className="users-content">
          <UserLogsSection title="Alixpartners users" count={apUsersCount} id="AP" />
          <UserLogsSection
            title="Client Users"
            count={clientUsersCount}
            id="Clients"
          />
        </div>
      </section>
    </SettingsContentPanel>
  );
};

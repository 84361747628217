import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SlipSheet } from 'api/endpoints';
import { BasicFetchState, FetchStatus } from 'types/fetch-status.types';

export type SlipSheetsSlice = {
  deleteState: BasicFetchState;
} & (
  | {
      type: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      type: FetchStatus.Done;
      data: SlipSheet[];
      error: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: SlipSheet[];
    }
  | {
      type: FetchStatus.Fetching;
      data: SlipSheet[];

      error: null;
    }
);

export const initialState: SlipSheetsSlice = {
  type: FetchStatus.Idle,
  error: null,
  data: null,
  deleteState: {
    status: FetchStatus.Idle,
  },
} as SlipSheetsSlice;

const SLICE_NAME = 'slipSheets';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetching(state: SlipSheetsSlice) {
      state.type = FetchStatus.Fetching;
    },
    done(
      state: SlipSheetsSlice,
      action: PayloadAction<{
        items: SlipSheet[];
      }>,
    ) {
      state.type = FetchStatus.Done;
      state.data = action.payload.items;
      state.error = null;
    },
    postDone(
      state: SlipSheetsSlice,
      action: PayloadAction<{
        items: SlipSheet[];
      }>,
    ) {
      if (state.data) state.data = [...action.payload.items, ...state.data];
      state.error = null;
    },
    putDone(
      state: SlipSheetsSlice,
      action: PayloadAction<{
        item: SlipSheet;
      }>,
    ) {
      if (state.data) {
        const index = state.data.findIndex((x) => x.id === action.payload.item.id);
        if (index !== -1) {
          state.data[index] = action.payload.item;
        }
      }
      state.error = null;
    },
    deleteFetching(state: SlipSheetsSlice) {
      state.deleteState.status = FetchStatus.Fetching;
    },
    deleteDone(
      state: SlipSheetsSlice,
      action: PayloadAction<{
        id: number;
      }>,
    ) {
      if (state.data) {
        state.data = state.data.filter((x) => x.id !== action.payload.id);
      }
      state.deleteState.status = FetchStatus.Done;
      state.error = null;
    },
    error(state: SlipSheetsSlice, action: PayloadAction<{ message: string }>) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const slipSheetsActions = slice.actions;
export default slice.reducer;

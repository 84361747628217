import {
  ExportPdfResponse,
  ExportPdfStatus,
  exportPdf_canceled,
  exportPdf_finished,
  exportPdf_notCompleted
} from 'api/endpoints/exportPdf';
import { Cell, DocumentsTable, Row } from 'components/DocumentsTable';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { IconLoading } from 'components/UIComponents/IconLoading';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import prettyBytes from 'pretty-bytes';

import { ComponentProps, useMemo, useState } from 'react';
import { isNotNil } from 'utils/lodashEx';
import { useSofasExportContext } from '../../../../Domain/SofasExportContextProvider';
import './SofaExportList.css';

const GRID_HEADER_CAPTION = ['Files', 'Exported by', 'Entities', 'Size', 'Actions'];

export const SofaExportList = () => {
  const { completed, downloadFile, retryExport } = useSofasExportContext();

  return (
    <div className="sofas-export-list">
      <h4> Previous Exports </h4>
      <div>
        <DocumentsTable
          className="sofas-export-list-table"
          headerItems={GRID_HEADER_CAPTION}
        >
          {completed?.map((r, id) => {
            return (
              <ExportItem
                key={`${id}`}
                item={r}
                onDownload={downloadFile}
                onRetryExport={retryExport}
              />
            );
          })}
        </DocumentsTable>
      </div>
    </div>
  );
};

const ExportItem = (props: {
  item: ExportPdfResponse;
  onDownload: (filePath: string) => Promise<void>;
  onRetryExport: (item: ExportPdfResponse) => Promise<void>;
}) => {
  const { item, onDownload, onRetryExport } = props;
  const {
    fileName,
    processedFiles,
    totalFiles,
    exportStatus,
    filePath,
    fileSize,
    createdBy,
  } = item;
  const [isDownload, setIsDownload] = useState(false);
  const [isExportRetried, setExportRetried] = useState(false);

  const handleDownload = async () => {
    setIsDownload(true);
    await onDownload(filePath);
    setIsDownload(false);
  };

  const handleExportRetry = async () => {
    setExportRetried(true);
    await onRetryExport(item);
    setExportRetried(false);
  };

  const additionalInfo = useMemo(() => {
    if (!isNotNil(filePath)) return;
    if (exportPdf_canceled.includes(exportStatus) && !!filePath) {
      return getAdditionalInfoText(processedFiles, totalFiles);
    }

    return undefined;
  }, [exportStatus, filePath, processedFiles, totalFiles]);

  const { iconName, color } = getColorByStatus(exportStatus);
  const canDownloadFile =
    exportPdf_finished.includes(exportStatus) && isNotNil(filePath);
  const canExportRetry = exportPdf_notCompleted.includes(exportStatus);

  if (!isNotNil(fileName)) return null;

  return (
    <Row className="sofas-export-listitem">
      <Cell className="sofas-export-listitem__files">
        <SvgIcon iconName={iconName} fill={color} width={20} height={20} />
        <span className="sofas-export-listitem__files-body">
          <span className="sofas-export-listitem__filename" style={{ color }}>
            {fileName}
          </span>
          {additionalInfo && (
            <span className="sofas-export-listitem__additional">
              {additionalInfo}
            </span>
          )}
        </span>
      </Cell>
      <Cell>{createdBy}</Cell>
      <Cell>{totalFiles}</Cell>
      <Cell>{fileSize ? prettyBytes(fileSize, { binary: true }) : '-'}</Cell>
      <Cell className="sofas-export-listitem__actions">
        {canExportRetry && (
          <ButtonIcon
            icon="reset"
            hint="Retry export"
            width={24}
            height={24}
            iconSize={18}
            iconColor={'#5CB335'}
            disabled={isExportRetried}
            onClick={handleExportRetry}
          />
        )}
        {isDownload ? (
          <IconLoading />
        ) : (
          <ButtonIcon
            icon="download_file"
            hint="Download file"
            width={24}
            height={24}
            iconSize={20}
            iconColor={'#5CB335'}
            disabled={!canDownloadFile}
            onClick={handleDownload}
          />
        )}
      </Cell>
    </Row>
  );
};

type IconName = ComponentProps<typeof SvgIcon>['iconName'];
type IconColor = { iconName: IconName; color: string };

const getColorByStatus = (status: ExportPdfStatus): IconColor => {
  switch (status) {
    case 'Completed':
      return { iconName: 'check', color: '#5CB335' };
    case 'Failed':
      return { iconName: 'stop', color: '#B91C1C' };
    case 'InComplete':
    case 'Canceled':
      return { iconName: 'terminate', color: '#D97706' };
    default:
      return { iconName: '' as IconName, color: '#B91C1C' };
  }
};
const getAdditionalInfoText = (processedFiles: number, totalFiles: number) => {
  if (isNil(processedFiles)) return undefined;

  const entityPlural = pluralize('entity', totalFiles);
  return `${processedFiles} of ${totalFiles} ${entityPlural} exported`;
};

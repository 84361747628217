import {
  jsonFetchGet,
  jsonFetchPost,
  jsonFetchPut,
} from 'api/jsonFetch/authJsonFetch';
import { del, downloadFileGet, put } from '../apiRequest';

export enum ContractAttachmentDocumentType {
  ContractImage = 1,
  CourtExhibit = 2,
  VendorAgreement = 3,
  SettlementAgreement = 4,
  Communications = 5,
  Reconciliations = 6,
  ScheduleDetail = 7,
  FinancingAgreement = 8,
  Email = 9,
}

export interface ContractAttachment {
  id: number;
  contractId: number;
  referenceNumber: string;
  documentName: string;
  displayName: string;
  contractImageUrl: string;
  comment: string;
  contractDocumentTypeId: ContractAttachmentDocumentType;
  isPrimaryDocument: boolean;
}

export interface CreateContractAttachmentParams {
  contractId: number;
  contractUploadDocuments: Pick<
    ContractAttachment,
    | 'referenceNumber'
    | 'documentName'
    | 'displayName'
    | 'contractDocumentTypeId'
    | 'comment'
  >[];
}

type NotFoundError = {
  status: 404;
  error: {
    statusCode: number;
  };
};

export type PostError =
  | NotFoundError
  | {
      status: 400;
      error: CreateContractAttachmentError[];
    };

export type CreateContractAttachmentError = Pick<
  ContractAttachment,
  'documentName' | 'displayName' | 'contractDocumentTypeId'
> & {
  error: string;
};

export type ContractAttachmentPrimaryParam = Pick<
  ContractAttachment,
  'id' | 'isPrimaryDocument'
>;

export const contractAttachments = {
  getDocuments(contractId: number) {
    return jsonFetchGet<ContractAttachment[], NotFoundError>(
      `v1/ContractDocuments/${contractId}`,
    );
  },
  deleteDocument: (id: number) => del(`v1/ContractDocuments/${id}`),
  postDocument(params: CreateContractAttachmentParams) {
    return jsonFetchPost<ContractAttachment[], PostError>(
      `v1/ContractDocuments/createContractDocument`,
      params,
    );
  },
  downloadDoc(docId: number) {
    return downloadFileGet(`v1/ContractDocuments/download/${docId}`);
  },
  setPrimaryDocument(param: ContractAttachmentPrimaryParam) {
    return jsonFetchPut('v1/ContractDocuments', param);
  },

  saveComment(documentId: number, comment: string) {
    return put(`v1/ContractDocuments/${documentId}?comment=${comment}`, {});
  },
};

export enum FetchStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Done = 'done',
  Error = 'error',
  Stale = 'stale',
}

export type BasicFetchState =
  | {
      status: FetchStatus.Idle | FetchStatus.Fetching | FetchStatus.Done;
    }
  | {
      status: FetchStatus.Error;
      error: { message: string };
    };

// remove the end of the text
// example to use
//     textTrimEndingBy('Lorem ipsum consectetur', 6) => 'Lorem ipsum co...'
export const textTrimEndingBy = (text: string, caractersToTrim: number) => {
  if (!text) return text;

  const ends = '...';
  const numToKeep = text.length - caractersToTrim - ends.length;
  if (numToKeep <= 0) return '...';

  const result = text.slice(0, numToKeep);

  return `${result}${ends}`;
};

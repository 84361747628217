import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogEntry } from 'api/endpoints/claimLogs';
import { ContractLogEntryResult } from 'api/endpoints/contractLogs';
import { FetchStatus } from 'types/fetch-status.types';
import { PaginationInput } from 'reducer/claimLogsDataReducer';
import { paginate } from 'utils/array';

export type PaginationParams = Pick<
  ContractLogEntryResult,
  'currentPage' | 'currentSort' | 'entriesPerPage'
>;

export interface ContractLogsDataSliceState {
  entries_dsc: LogEntry[] | null;
  entries_asc: LogEntry[] | null;
  pageData: ContractLogEntryResult | null;
  fetchStatus: FetchStatus;
  error: { message: string } | null;
  latestComment?: LogEntry | null;
}

export const initialState: ContractLogsDataSliceState = {
  error: null,
  fetchStatus: FetchStatus.Idle,
  entries_dsc: null,
  entries_asc: null,
  pageData: null,
  latestComment: null,
};

const PAGE_SIZE = 8;

const contractsLogSlice = createSlice({
  name: 'contractLogs',
  initialState: initialState,
  reducers: {
    setContractLogsIdle: (state) => {
      state.fetchStatus = FetchStatus.Idle;
    },
    contractLogsFetching(state, _action: PayloadAction<PaginationInput>) {
      state.entries_dsc = null;
      state.entries_asc = null;
      state.error = null;
      state.fetchStatus = FetchStatus.Fetching;
    },

    paginationChanged(state, action: PayloadAction<PaginationInput>) {
      const input = action.payload;
      const { entries_asc, entries_dsc } = state;

      const entries =
        input.currentSort === 'created-date-desc' ? entries_dsc : entries_asc;

      if (!entries)
        throw new Error('Please make sure you have fetched the data first.');
      const paginated = paginate(entries, input.currentPage, PAGE_SIZE);

      state.pageData = {
        ...paginated,
        currentSort: input.currentSort,
        contractId: input.id,
      };

      state.fetchStatus = FetchStatus.Done;
    },
    contractLogsDone(state, action: PayloadAction<ContractLogEntryResult>) {
      const { entries: serverEntries } = action.payload;

      // an older fetch request took longer and the app is already displaying a new one, ignore the result
      const tincoming = action.payload._requestTime;
      const tstate = state.pageData?._requestTime;
      if (
        tincoming &&
        tstate &&
        new Date(tincoming).getTime() < new Date(tstate).getTime()
      )
        return;

      // sort by most recent date should be the default from server
      const entries_dsc = serverEntries;
      const entries_asc = [...serverEntries].sort(
        (a, b) => new Date(a.logTime).getTime() - new Date(b.logTime).getTime(),
      );

      state.entries_asc = entries_asc;
      state.entries_dsc = entries_dsc;
      state.latestComment = entries_dsc[0];

      state.fetchStatus = FetchStatus.Done;
    },
    contractLogsError(state, action: PayloadAction<{ message: string }>) {
      state.fetchStatus = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const {
  contractLogsFetching,
  contractLogsDone,
  contractLogsError,
  paginationChanged,
  setContractLogsIdle,
} = contractsLogSlice.actions;
export default contractsLogSlice.reducer;

import isNil from 'lodash/isNil';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionName } from 'api/endpoints/action-names';
import {
  ActionExhibitTypeId,
  ServerExhibitType,
  ServerSubstatus,
} from 'api/endpoints/actions-select-options';
import { ActionStatus, ActionStatusDynamic } from 'api/endpoints';
import { SelectableDataEmptyAllowed } from 'types/selectableDataTypes';

export type ClaimActionInfo = {
  id: number; // claim.id
  actionTypeId?: ActionExhibitTypeId;
  actionSubstatusId?: number;
  actionNameId?: number;
  _fetchTimestamp?: string;
  actionStatus?: ActionStatus | undefined;
};

export enum CANSSliceType {
  Intitial = 1,
  Populated,
  Touched,
}

export type Base = {
  type: CANSSliceType;
  claimId: number;
  claimActionInfo: Omit<ClaimActionInfo, 'id'>;
  toSend: Omit<ClaimActionInfo, 'id'>;
  actionStatuses: ActionStatusDynamic[];
  actionTypesByStatus: ServerExhibitType[];
  substatusOptions: ServerSubstatus[];
  actionNameOptions: ActionName[];
  _allActionNames: ActionName[];
  _fetchTimestamp?: string;
  actionStatus?: ActionStatus;
};

export type ClaimActionNameSelectionSlice = Base;

export const initialState: ClaimActionNameSelectionSlice = {
  type: CANSSliceType.Intitial,
  _allActionNames: [],
  actionStatuses: [],
  actionTypesByStatus: [],
  substatusOptions: [],
  actionNameOptions: [],
  claimId: -1,
  claimActionInfo: {
    actionNameId: undefined,
    actionSubstatusId: undefined,
    actionTypeId: undefined,
    actionStatus: undefined,
  },
  toSend: {
    actionNameId: undefined,
    actionSubstatusId: undefined,
    actionTypeId: undefined,
  },
  actionStatus: undefined,
} as ClaimActionNameSelectionSlice;

const SLICE_NAME = 'claim-actionname-selection';
const CLAIM_SET_ACTION_TYPE = `${SLICE_NAME}/claimSet`;
const ACTION_TYPE_ID_SET_ACTION_TYPE = `${SLICE_NAME}/typeIdSet`;
const ACTION_NAME_ID_SET_ACTION_TYPE = `${SLICE_NAME}/nameIdSet`;
const ACTION_SUBVSTATUS_ID_SET_ACTION_TYPE = `${SLICE_NAME}/substatusIdSet`;
const ACTION_STATUS_TYPE_SET = `${SLICE_NAME}/actionStatusSet`;
const ACTION_CLEAR_TO_SEND = `${SLICE_NAME}/clear-toSend`;

export function claimSet(payload: { claimActionInfo: ClaimActionInfo }) {
  return { type: CLAIM_SET_ACTION_TYPE, payload };
}
export function actionTypeIdSet(payload: { value: number | undefined }) {
  return { type: ACTION_TYPE_ID_SET_ACTION_TYPE, payload };
}

export function actionSubstatusIdSet(payload: { value: number | undefined }) {
  return { type: ACTION_SUBVSTATUS_ID_SET_ACTION_TYPE, payload };
}

export function actionNameIdSet(payload: { value: number | undefined }) {
  return { type: ACTION_NAME_ID_SET_ACTION_TYPE, payload };
}

export function actionStatusSet(payload: string | undefined) {
  return { type: ACTION_STATUS_TYPE_SET, payload };
}

export function clearToSend(payload: {}) {
  return { type: ACTION_CLEAR_TO_SEND, payload };
}
/**
 * Node (a): The reducers {} field uses a proxies the state and prevents setting it in helper function, thus this switches
 *       the more 'classic' extraReducers approach.
 * Node (b): We use the action-* action types to hook up to the data loading flow
 */
const claimActionNameSelectionSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  extraReducers: {
    'action-names/actionNamesDone': (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<ActionName[]>,
    ) => {
      state._allActionNames = action.payload;

      setSelectionOptionsOnState(state, state.claimActionInfo);
    },
    'selectable/selectableLoaded': (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<SelectableDataEmptyAllowed>,
    ) => {
      if (action.payload.actionStatuses) {
        state.actionStatuses = action.payload.actionStatuses;
        setSelectionOptionsOnState(state, state.claimActionInfo);
      }
    },
    [CLAIM_SET_ACTION_TYPE]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<{
        claimActionInfo: ClaimActionInfo;
      }>,
    ) => {
      state.claimId = action.payload.claimActionInfo.id;
      state._fetchTimestamp = action.payload.claimActionInfo._fetchTimestamp;
      state.type = CANSSliceType.Populated;

      const claimActionInfo = cascadeInputResets(action.payload.claimActionInfo);
      state.claimActionInfo = claimActionInfo;

      setSelectionOptionsOnState(state, state.claimActionInfo);
      state.actionStatus = action.payload.claimActionInfo.actionStatus;
    },
    [ACTION_TYPE_ID_SET_ACTION_TYPE]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<{ value: number }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      const claimActionInfo = cascadeInputResets({
        actionNameId: 0,
        actionSubstatusId: 0,
        actionTypeId: value,
        actionStatus: state.claimActionInfo.actionStatus,
      });

      state.claimActionInfo = { ...claimActionInfo };
      state.toSend = { ...claimActionInfo };

      setSelectionOptionsOnState(state, state.claimActionInfo);
    },
    [ACTION_SUBVSTATUS_ID_SET_ACTION_TYPE]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<{ value: number }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      state.toSend.actionSubstatusId = value;
      const { actionNameId, actionTypeId } = state.claimActionInfo;

      const claimActionInfo = cascadeInputResets({
        actionNameId,
        actionTypeId,
        actionSubstatusId: value,
        actionStatus: state.claimActionInfo.actionStatus,
      });
      state.claimActionInfo = claimActionInfo;

      state.toSend = { ...claimActionInfo };
      state.toSend.actionNameId = 0;

      setSelectionOptionsOnState(state, state.claimActionInfo);
    },
    [ACTION_NAME_ID_SET_ACTION_TYPE]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<{ value: number | undefined }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      const { actionSubstatusId, actionTypeId } = state.claimActionInfo;

      const claimActionInfo = cascadeInputResets({
        actionTypeId,
        actionSubstatusId,
        actionNameId: value,
        actionStatus: state.claimActionInfo.actionStatus,
      });

      state.claimActionInfo = claimActionInfo;

      state.toSend = { ...claimActionInfo };

      setSelectionOptionsOnState(state, state.claimActionInfo);
    },

    [ACTION_STATUS_TYPE_SET]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<ActionStatus>,
    ) => {
      // when changing actionStatus we need to check if current selections are included in the new actionStatus data
      // if yes, we keep the values, otherwise we reset to 0 'None'
      const currentActionTypesByStatus =
        state.actionStatuses.find((status) => status.actionStatus === action.payload)
          ?.actionExhibitTypes ?? [];

      const { actionTypeId, actionSubstatusId, actionNameId } =
        state.claimActionInfo;

      const selectedActionType = currentActionTypesByStatus.find(
        (at) => at.id === actionTypeId,
      );
      const currentActionTypeId = selectedActionType?.id;
      const currentSubstatusId = selectedActionType?.actionSubStatuses.find(
        (substatus) => substatus.id === actionSubstatusId,
      )?.id;

      const claimActionInfo = cascadeInputResets({
        actionNameId,
        actionSubstatusId: currentSubstatusId ?? 0,
        actionTypeId: currentActionTypeId ?? 0,
        actionStatus: action.payload,
      });

      state.claimActionInfo = { ...claimActionInfo };
      state.toSend = { ...claimActionInfo };
      setSelectionOptionsOnState(state, state.claimActionInfo);
      state.actionStatus = action.payload;
    },

    [ACTION_CLEAR_TO_SEND]: (
      state: ClaimActionNameSelectionSlice,
      action: PayloadAction<{}>,
    ) => {
      state.toSend = action.payload;
    },
  },
  reducers: {},
});

export default claimActionNameSelectionSlice.reducer;

function addEmptyOptionToSubstatusOptions(
  options: ServerSubstatus[],
): ServerSubstatus[] {
  return [
    {
      id: 0,
      actionSubStatusName: 'None',
    },
    ...options.filter((o) => o.actionSubStatusName !== 'None'),
  ];
}

function addEmptyOptionToActionNameOptions(
  options: ActionName[],
  typeId: number,
  substatusId: number,
): ActionName[] {
  return [
    {
      id: 0,
      name: 'None',
      typeId,
      substatusId,
    },
    ...options.filter((o) => o.name !== 'None'),
  ];
}

export function computeSelectionOptions(
  state: Base,
  claimActionInfo: Omit<ClaimActionInfo, 'id'>,
) {
  const actionNameOptions = isNil(claimActionInfo?.actionTypeId)
    ? []
    : addEmptyOptionToActionNameOptions(
        state._allActionNames.filter(
          (a) => a.substatusId === claimActionInfo?.actionSubstatusId,
        ),
        claimActionInfo?.actionTypeId || 0,
        claimActionInfo?.actionSubstatusId || 0,
      );

  const actionTypesByStatus =
    state.actionStatuses?.find(
      (item) => item.actionStatus === claimActionInfo.actionStatus,
    )?.actionExhibitTypes ?? [];
  const substatusOptions = addEmptyOptionToSubstatusOptions(
    actionTypesByStatus.find((action) => action.id === claimActionInfo.actionTypeId)
      ?.actionSubStatuses ?? [],
  );

  return {
    actionNameOptions,
    substatusOptions,
    actionTypesByStatus,
  };
}
function cascadeInputResets(
  original: Omit<ClaimActionInfo, 'id'>,
): Omit<ClaimActionInfo, 'id'> {
  const { actionTypeId, actionNameId, actionSubstatusId, actionStatus } = original;
  const curated = { actionTypeId, actionNameId, actionSubstatusId, actionStatus };
  // cascade resets
  if (!original.actionTypeId) {
    curated.actionSubstatusId = 0;
    curated.actionNameId = 0;
  }

  if (!original.actionSubstatusId) {
    curated.actionNameId = 0;
  }

  return curated;
}
function setSelectionOptionsOnState(
  state: Base,
  claimActionInfo: Omit<ClaimActionInfo, 'id'>,
) {
  const { actionNameOptions, substatusOptions, actionTypesByStatus } =
    computeSelectionOptions(state, claimActionInfo);
  state.actionTypesByStatus = actionTypesByStatus;
  state.actionNameOptions = actionNameOptions;
  state.substatusOptions = substatusOptions;
  return state;
}

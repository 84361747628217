import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

type FileState = {
  fileUploading: boolean;
  fileName: string;
};

type State = {
  claims: FileState;
  contracts: FileState;
};

const initialState: State = {
  claims: {
    fileUploading: false,
    fileName: '',
  },
  contracts: {
    fileUploading: false,
    fileName: '',
  },
};

const batchEdit = createSlice({
  name: 'batchEdit',
  initialState: initialState,
  reducers: {
    setBatchEditDetails: (
      state,
      action: PayloadAction<{
        sectionType: SectionType;
        fileUploading?: boolean;
        fileName?: string;
      }>,
    ) => {
      if (action.payload.sectionType === SectionType.Claims) {
        state.claims.fileUploading =
          action.payload.fileUploading ?? state.claims.fileUploading;
        state.claims.fileName = action.payload.fileName ?? state.claims.fileName;
      } else if (action.payload.sectionType === SectionType.Contracts) {
        state.contracts.fileUploading =
          action.payload.fileUploading ?? state.contracts.fileUploading;
        state.contracts.fileName =
          action.payload.fileName ?? state.contracts.fileName;
      }
    },
  },
});
export const { setBatchEditDetails } = batchEdit.actions;
export default batchEdit.reducer;

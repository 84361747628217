import { api } from 'api';
import _ from 'lodash';

export const apiSettlementsAgreementTemplates = {
  getTemplate: async () => {
    const items = await api.settlementAgreementTemplates.get();

    const itemsSorted = _.orderBy(items, 'id', ['desc']);
    const itemLast = itemsSorted[0];

    return itemLast;
  },
  downloadPreview: async (id: number) => {
    const result = await api.settlementAgreementTemplates.getPreviewBlob(id);

    return result.type === 'ok' ? result.data : undefined;
  },
};

import { ClaimRow, SourceType } from 'api/endpoints';

interface ObjectWithId {
  id: string | number;
}

export const mapOptions = (
  values: ObjectWithId[] | undefined,
  fieldName: string,
) => {
  if (!values) return [];

  return values.map((action: any) => ({
    label: action[fieldName],
    value: action.id,
  }));
};

export const getClaimNumber = (row: ClaimRow | undefined) => {
  if (!row) return '';

  return row.sourceType === SourceType.ScheduledClaim
    ? row.claimScheduleNumber
    : row.referenceNumber;
};

import { GlobalNote } from 'api/endpoints';
import { useMemo } from 'react';
import './GlobalNoteItemDetails.css';

interface Props {
  item: GlobalNote;
}

export const GlobalNoteItemDetails = ({ item }: Props) => {
  const names = useMemo(
    () => item.legalEntities.map((x) => `${x.legalEntityName}`),

    [item],
  );

  return (
    <div className={'globalnote-listitem__details'}>
      <Enumerator title={entitiesLabel(names?.length)} items={names} countItems />
    </div>
  );
};

function entitiesLabel(count: number) {
  switch (count) {
    case 0:
      return `No Entities.`;
    case 1:
      return 'One Entity:';
    default:
      return `${count} Entities:`;
  }
}

function Enumerator(props: {
  title: string;
  items: string[];
  countItems?: boolean;
}) {
  const { title, items, countItems } = props;
  return (
    <div className="enumerator">
      <div className="enumerator__text enumerator__title">{title}</div>
      <ul className="enumerator__list">
        {items.map((item, i) => {
          const content = countItems ? `${i + 1} - ${item}` : item;
          return (
            <li key={item} className="enumerator__text">
              {content}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

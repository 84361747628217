import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import './Preview.css';
import { PreviewHeader } from './PreviewHeader';
import { usePreview } from './usePreview';
import DomPurify from 'dompurify';

export const Preview = () => {
  const { type } = useParams();
  const { url, loading, legalEntityId, setLegalEntityId, part, setPart } =
    usePreview({ type });
  const [frameLoading, setFrameLoading] = useState(true);

  useEffect(() => setFrameLoading(true), [url]);
  const handleLoadComplete = useCallback(() => setFrameLoading(false), []);

  return (
    <div className="sofa-preview__container">
      <PreviewHeader
        legalEntityId={legalEntityId}
        onChangeLegalEntity={setLegalEntityId}
        onChangePart={setPart}
        part={part}
        type={type}
      />
      <DivWithLoader
        className="sofa-preview__content"
        loading={frameLoading || loading}
      >
        <iframe
          title="preview"
          className="sofa-preview-frame"
          src={url ? DomPurify.sanitize(url) : url}
          onLoad={handleLoadComplete}
        />
      </DivWithLoader>
    </div>
  );
};

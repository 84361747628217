import { useCallback, useEffect, useState } from 'react';
import { counterpartiesAndDebtors, CounterpartiesOrDebtors } from 'api/endpoints';
import { Debtor } from '../../SingleContractCounterpartiesAndDebtors/SingleContractCounterpartiesAndDebtorsTable';

export const useCounterpartiesAndDebtors = (contractId: number, debtors: Debtor) => {
  const [items, setItems] = useState<CounterpartiesOrDebtors[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const type = debtors === 'counterparty' ? 'counterparties' : 'debtors';

  const loadList = useCallback(async () => {
    const data = await counterpartiesAndDebtors.getData(contractId, type);
    setItems(data);
    setLoading(false);
  }, [contractId, type]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handlePrimaryToggle = useCallback(
    async (id: number) => {
      const resp = await counterpartiesAndDebtors.setPrimary(id, debtors);
      if (!resp) return;
      loadList();
    },
    [debtors, loadList],
  );

  const handleDelete = useCallback(
    async (id: number) => {
      const resp = await counterpartiesAndDebtors.delete(id, debtors);
      if (!resp) return;
      loadList();
    },
    [debtors, loadList],
  );

  return {
    items,
    loading,
    handlePrimaryToggle,
    handleDelete,
  };
};

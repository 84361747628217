import { jsonFetchGet } from 'api/jsonFetch/authJsonFetch';
import { ClaimsDetailedData, ContractsDetailedData } from 'pages/Dashboard/types';
import { get } from '../apiRequest';

export type ApDashboardTasksInfo = ContractsTasks & {
  allClaims: number;
  resolved: number;
  resolutionProccessInitiated: number;
  resolutionProcessPending: number;
  unassignedClaims: number;
  // estimates data
  estimates: number;
  estimatesCure: number;
  rejectionDamage: number;
  estimatesRejectionDamageLow: number;
  estimatesRejectionDamageHigh: number;
  // separator
  separator: any;
};

type ContractsTasks = {
  contractsTotalPercentage: number;
  reviewSubheader: number;
  assessedSubheader: number;
  allContracts: number;
  contractsAwaitingReview: number;
  contractsUnderReview: number;
  contractsAssume: number;
  contractsReject: number;
  contractsTerminate: number;
  contractsNonExecutory: number;
};

export type ClientDashboardTasksInfo = ContractsTasks & {
  assigned: number;
  applicationUserId: number;
  assessed: number;
  allClaims: number;
};

export const userDashboardTasks = {
  getApplicationUserId: () =>
    get<{ applicationUserId: number }>(`v1/UserGroup/getApplicationUser`),
  getApContractsItems: (showMine?: boolean) =>
    jsonFetchGet<ApDashboardTasksInfo>(
      `v1/contracts/dashboard?onlyMy=${!!showMine}`,
    ),
  getClientTasks: () =>
    jsonFetchGet<ClientDashboardTasksInfo>(`/v1/ClaimReviewers/getClientUserBoard`),
  getClientsContractItems: () =>
    jsonFetchGet<ClientDashboardTasksInfo>(`v1/contracts/dashboard?onlyMy=true`),
  getClientAppUserId: () =>
    get<ClientDashboardTasksInfo>(`/v1/ClaimReviewers/getClientUserBoard`),
  getDetailedContractData: (showMine?: boolean) =>
    jsonFetchGet<ContractsDetailedData[]>(
      `v1/contracts/dashboard/detailed?onlyMy=${!!showMine}`,
    ),
  getApClaimsItems: (showMine?: boolean) =>
    jsonFetchGet<ClaimsDetailedData[]>(`v1/Claims/dashboard?onlyMy=${!!showMine}`),
};

import { ProcessingStatus } from 'api/endpoints';
import { memo } from 'react';
import { pluralize } from 'utils/pluralize';
import { PublishingStatus, TRequiredFiles } from '../types/uploadItemsTypes';
import './NotificationText.css';

export const NotificationText = memo(
  (props: {
    status: ProcessingStatus | undefined;
    publishingCount: number;
    requiredFiles: TRequiredFiles;
    publishingStatus: PublishingStatus;
  }) => {
    const { status, publishingStatus, publishingCount, requiredFiles } = props;

    if (publishingStatus.active && publishingStatus.errorMessage)
      return (
        <span className="notification-text-error">
          <strong>{publishingStatus.errorMessage}</strong>
        </span>
      );

    switch (status) {
      case ProcessingStatus.Unpublished:
        if (publishingStatus.active) return <>Publishing started</>;
        if (requiredFiles.upload < requiredFiles.total) {
          return (
            <>
              <strong>{requiredFiles.upload}</strong> of{' '}
              <strong>{requiredFiles.total}</strong> required data files have been
              uploaded
            </>
          );
        }
        if (publishingCount > 0) {
          return (
            <>
              <b>{publishingCount}</b> {pluralize('file', publishingCount)} ready for
              publishing
            </>
          );
        }
        return null;

      case ProcessingStatus.InProgress:
        return (
          <>
            You have <strong>{publishingCount}</strong>{' '}
            {pluralize('file', publishingCount)} currently publishing
          </>
        );

      case ProcessingStatus.Completed:
        return <>All uploaded files have been published</>;

      case ProcessingStatus.ApplicationException:
      case ProcessingStatus.Failed:
        return <>Error publishing files</>;

      default:
        return null;
    }
  },
);

export const SOFA_1_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['1', '2'],
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'enum',
      displayName: 'Year Category',
      propertyName: 'yearCategory',
      allowedValues: [0, 1, 2],
    },
    {
      dataType: 'string',
      displayName: 'Begin Date',
      propertyName: 'beginDate',
    },
    {
      dataType: 'string',
      displayName: 'End Date',
      propertyName: 'endDate',
    },
    {
      dataType: 'bool',
      displayName: 'Revenue from Operating a Business (Yes / No)',
      propertyName: 'revenueFromOp',
    },
    {
      dataType: 'string',
      displayName: 'Other Revenue Sources',
      propertyName: 'otherRevDesc',
    },
    {
      dataType: 'decimal',
      displayName: 'Gross Revenue',
      propertyName: 'revenue',
    },

    // {
    //   dataType: 'bool',
    //   displayName: 'Is Filling Date',
    //   propertyName: 'isFilingDate',
    // },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

import _, { isString } from 'lodash';

export const isInputMoneyValid = (value: string | number) => {
  if (value === '-') return true; // to allow user enter negative number
  if (value === ' ') return false;

  const numberValue = +value;

  return (
    _.isFinite(numberValue) &&
    _.inRange(numberValue, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)
  );
};

export const isInputIntegerValid = (value: string | number) => {
  if (value === '-') return true; // to allow user enter negative number
  if (value === ' ') return false;

  //  decimal separators is not allowed
  if (isString(value) && value.slice(-1) === '.') return false;

  const numberValue = +value;

  return (
    _.isInteger(numberValue) &&
    _.inRange(numberValue, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)
  );
};

export const inputStringToNumber = (value: string | undefined) => {
  const result = Number(value);
  return isNaN(result) ? 0 : result;
};

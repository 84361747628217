import { FieldChange, LogEntry } from 'api/endpoints/claimLogs';
import { formatDateTime } from 'utils/formatDate';
import { formatMoney } from 'utils/formatNumber';

export type LabeledFieldChange = FieldChange & { label: string };

export function labelFieldChange(change: FieldChange): LabeledFieldChange | null {
  let formattedFrom = formatFieldFromOrTo(change, 'from');
  let formattedTo = formatFieldFromOrTo(change, 'to');

  return {
    ...change,
    label: `${formattedFrom} to ${formattedTo}`,
  };
}

export type LabelledLogEntry = LogEntry & {
  labelledLogTime: string;
  labelledChanges?: LabeledFieldChange[];
  labelledComment?: string;
};

export const commentToLabel = (comment?: string | null) => (comment ? comment : '-');

export function labelEntries(entries?: LogEntry[]): LabelledLogEntry[] | undefined {
  return entries?.map((et) => {
    if (et.type === 'field-changes') {
      const labelledChanges: LabeledFieldChange[] = et.fieldChanges
        .map(labelFieldChange)
        // TS doesn't take filter in consideration, still thinks type can be `null` without
        .filter((lfc) => !!lfc) as LabeledFieldChange[];
      return {
        ...et,
        labelledLogTime: formatDateTime(et.logTime),
        labelledChanges,
        labelledComment: commentToLabel(et.comment),
      };
    }
    return {
      ...et,
      labelledLogTime: formatDateTime(et.logTime),
    };
  });
}

const skeletonEntry: LogEntry = {
  id: 2,
  type: 'field-changes',
  logTime: '2019-04-15T13:54:00.488Z',
  user: '-',
  comment: '-',
  fieldChanges: [
    {
      id: 1,
      dataType: 'currency',
      displayName: '',
      propertyName: '',
      from: 0,
      to: 0,
    },
  ],
};

export const skeletonEntries: LogEntry[] = Array.from({ length: 4 }).map((_, i) => ({
  ...skeletonEntry,
  id: i,
}));

function formatFieldFromOrTo(
  change: FieldChange,
  fromOrTo: keyof FieldChange & ('from' | 'to'),
): string | null {
  // TS does not ineffer there is a value if the null check happens here
  switch (change.dataType) {
    case 'currency':
      const val = change[fromOrTo];
      if (val === null) return null;
      return formatMoney(val);
    case 'decimal':
    case 'int':
      const valNumber = change[fromOrTo];
      if (valNumber === null) return null;
      // let's not use formatNumberByType here, it's not needed
      // as formatting should not be applied in an audit log
      // as it is king of a system dialogue
      return valNumber.toString();
    case 'date':
    case 'datetime':
      const vdate = change[fromOrTo];
      if (vdate === null) return null;
      return formatDateTime(vdate);
    default:
      // Display remaining as string until business gives new rules, then extend this
      return `${change[fromOrTo]}`;
  }
}

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol (optional)
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR ip (v4) address
    '\\[[0-9a-fA-F:]+\\])' + // OR ipv6 address
    '(\\:\\d+)?' + // port (optional)
    "(\\/[\\w\\-.~:@!$&'()*+,;=/%]*)*" + // path (optional)
    "(\\?[;&\\w\\-.~:@!$&'()*+,;=/%]*)?" + // query string (optional)
    '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator (optional)
  'i', // case insensitive
);

/**
 *
 * @param url
 * @returns undefined if url is invalid or formatted url with 'https://' prepended to it if it didn't already have it
 */
export const validateAndFormatURL = (url: string) => {
  if (!url || !urlPattern.test(url.trim())) return;
  // check if the URL doesn't start with 'http', 'https', or 'www'
  if (!/^https?:\/\//i.test(url.trim())) {
    // Prepend 'https://' if the URL starts with 'www.'
    if (/^www\./i.test(url.trim())) {
      return `https://${url.trim()}`;
    }
    // If URL doesn't start with 'www.' or 'http', assume it's a domain and prepend 'https://'
    return `https://${url.trim()}`;
  }
  // If URL already has 'http' or 'https', return as is
  return url.trim();
};

import { createArray } from 'utils/array';
import { TCellStyle, TRowStyle } from './type';

export const defCellStyle: TCellStyle = {
  font: { size: 8, color: { theme: 1 }, name: 'Times New Roman', family: 1 },
  alignment: {
    horizontal: 'left',
    vertical: 'top',
    wrapText: true,
    indent: 1,
  },
};

export const defPositionNumberStyle: TCellStyle = {
  ...defCellStyle,
  alignment: { ...defCellStyle.alignment, indent: 0 },
};

export const defTotalStyle: TCellStyle = {
  font: {
    ...defCellStyle.font,
    bold: true,
  },
  alignment: { horizontal: 'right', vertical: 'middle' },
};

export const dividerStyle: TCellStyle = {
  border: {
    bottom: { style: 'thick' },
  },
};

const first4TotalsCellStyles: TRowStyle = [
  { ...defTotalStyle },
  {
    ...defTotalStyle,
    alignment: { ...defTotalStyle.alignment, horizontal: 'left' },
  },
  { ...defTotalStyle },
  {
    ...defTotalStyle,
    alignment: { ...defTotalStyle.alignment, horizontal: 'left' },
  },
];

export function createTotalRowStyle(rowCount: number): TRowStyle {
  const restCellCount = rowCount - first4TotalsCellStyles.length;

  return [...first4TotalsCellStyles, ...createArray(restCellCount, defTotalStyle)];
}

import classNames from 'classnames';
import 'components/UIComponents/css-animations.css';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import _ from 'lodash';
import './DocumentsTable.css';
import { DocUploadProgress } from './DocUploadProgress';

type Components<T> = React.ReactElement<T> | React.ReactElement<T>[];

type Props = {
  className?: string;
  headerItems: string[];
  progressValue?: number;
  progressText?: string;
  showLoader?: boolean;
  emptyListMessage?: React.ReactNode;
  children: Components<typeof Row> | undefined;
};

export const DocumentsTable = ({
  className,
  headerItems,
  progressValue,
  progressText,
  showLoader,
  emptyListMessage,
  children,
}: Props) => {
  return (
    <DivWithLoader
      loading={showLoader}
      className={classNames('document-table', className)}
    >
      <table>
        <thead>
          <tr className="document-table-header-row">
            {headerItems.map((header, id) => (
              <th key={`header ${id}`}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <ProgresRow text={progressText} value={progressValue} />
          <TableBody
            rows={children}
            showLoader={showLoader}
            emptyListMessage={emptyListMessage}
          />
        </tbody>
      </table>
    </DivWithLoader>
  );
};

const TableBody = (props: {
  showLoader: boolean | undefined;
  emptyListMessage: React.ReactNode | undefined;
  rows: Components<typeof Row> | undefined;
}) => {
  const { showLoader, emptyListMessage, rows } = props;

  if (showLoader) return <></>;

  if (_.isEmpty(rows) && emptyListMessage)
    return <EmptyListMessage value={emptyListMessage} />;

  return <>{rows}</>;
};

type RowProps = React.ComponentProps<'tr'> & { markAsNew?: boolean };
export const Row = ({ className, markAsNew, ...props }: RowProps) => {
  return (
    <tr
      key={props.key}
      className={classNames('document-table-item-row', className, {
        'document-table-item-row__new-row': markAsNew,
      })}
      {...props}
    />
  );
};

export const Cell = (props: React.ComponentProps<'td'>) => {
  return <td {...props} />;
};

export const RowAdditionalInfo = (props: { children: React.ReactNode }) => {
  return (
    <Row className="document-table-item__additional-row-info">
      <Cell colSpan={Number.MAX_SAFE_INTEGER}>{props.children}</Cell>
    </Row>
  );
};

const ProgresRow = (props: { text?: string; value: number | undefined }) => {
  const { text, value = -1 } = props;

  if (value < 0) return null;

  return (
    <tr className="document-table-progress-row">
      <Cell colSpan={Number.MAX_SAFE_INTEGER}>
        <DocUploadProgress text={text} value={value} />
      </Cell>
    </tr>
  );
};

const EmptyListMessage = (props: { value: React.ReactNode }) => {
  return (
    <Row>
      <Cell colSpan={Number.MAX_SAFE_INTEGER}>
        <div className="document-table-item__empty-list-message">
          {' '}
          {props.value}{' '}
        </div>
      </Cell>
    </Row>
  );
};

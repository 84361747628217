import classNames from 'classnames';
import './ButtonNav.css';

interface IProps {
  text: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonNav = ({
  text,
  className,
  active,
  onClick,
  disabled,
}: IProps) => {
  return (
    <button
      className={classNames('button-nav', active && 'button-nav-active', className)}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="button-nav--text">{text}</span>
      <IconArrowSmall />
    </button>
  );
};

const IconArrowSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 14">
    <path d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"></path>
  </svg>
);

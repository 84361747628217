import { get } from '../apiRequest';

export interface BlobStorageCredentials {
  host: string;
  containerName: string;
  sasToken: string;
}

export const storage = {
  getLogoContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetLogoContainerSasToken'),
  getExcelContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetExcelContainerSasToken'),
  getClaimDocumentsContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetClaimDocumentsContainerSasToken'),
  getContractDocumentsContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetContractDocumentsContainerSasToken'),
  getSettlementDocumentsContainerSasToken: () =>
    get<BlobStorageCredentials>(
      'v1/Storage/GetSettlementDocumentsContainerSasToken',
    ),
  getSettlementAgreementTemplatesContainerSasToken: () =>
    get<BlobStorageCredentials>(
      'v1/Storage/GetSettlementAgreementTemplatesContainerSasToken',
    ),
  getContractImagesContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetContractImagesContainerSasToken'),
  getExportedPdfContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetExportedPdfContainerSasTokenAsync'),
  getContractImagesZipContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetContractImagesZipContainerSasToken'),
  getClaimImagesZipContainerSasToken: () =>
    get<BlobStorageCredentials>('v1/Storage/GetClaimImagesZipContainerSasToken'),
};

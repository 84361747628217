import { api } from 'api';
import { SettlementAgreementId } from 'api/endpoints/settlementAgreementTemplates';
import { useApiQuery } from 'hooks/useApiQuery';
import { useCallback, useMemo, useState } from 'react';
import { azureFileUploader } from 'utils/azureUploader/azureFileUploader';
import { apiSettlementsAgreementTemplates } from './api/apiSettlementsAgreementTemplates';

export interface SettlementsAgreementPreview {
  id: SettlementAgreementId;
}

export const useSettlementsAgreementTemplates = () => {
  const [templateUploadProgress, setTemplateUploadProgress] = useState<number>();
  const [uploadFileName, setUploadFileName] = useState<string>();
  const [previewWindow, setPreviewWindow] = useState<SettlementsAgreementPreview>();

  const template = useApiQuery({
    queryFn: () => apiSettlementsAgreementTemplates.getTemplate(),
  });

  const item = template.data;

  const upload = useCallback(
    async (file: File) => {
      setUploadFileName(file.name);

      const uploadResult = await azureFileUploader({
        file,
        showErrorMessage: true,
        getCredentials: api.storage.getSettlementAgreementTemplatesContainerSasToken,
        onProgress: setTemplateUploadProgress,
      });

      if (uploadResult.type !== 'ok') {
        setUploadFileName(undefined);
        return false;
      }

      const result = await api.settlementAgreementTemplates.post({
        name: 'Settlement Agreement',
        displayName: uploadResult.displayName,
        fileName: uploadResult.fileName!,
      });
      await template.fetch();

      setTemplateUploadProgress(undefined);
      setUploadFileName(undefined);

      return !!result;
    },
    [template],
  );

  const download = useCallback(async () => {
    if (!item) return;
    await api.settlementAgreementTemplates.download(item.id, item.displayName);
  }, [item]);

  const deleteFile = useCallback(async () => {
    if (!item) return;
    await api.settlementAgreementTemplates.delete(item.id);
    await template.fetch();
  }, [item, template]);

  const previewOpen = useCallback(() => {
    setPreviewWindow({ id: item!.id });
  }, [item]);

  const previewClose = useCallback(() => {
    setPreviewWindow(undefined);
  }, []);

  const uploadProgress = useMemo(() => {
    if (!uploadFileName) return undefined;

    return {
      fileName: uploadFileName,
      progress: templateUploadProgress ?? 0,
    };
  }, [uploadFileName, templateUploadProgress]);

  return {
    item,
    uploadProgress,
    loading: template.loading,
    previewWindow,
    upload,
    download,
    deleteFile,
    previewOpen,
    previewClose,
  };
};

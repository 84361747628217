import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { ExtendedGridOptions } from '../../types';

import { ApLoaderDefault } from '@alixpartners/ui-components';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';
import {
  DEFAULT_COL_DEF,
  GRID_FLOATING_FILTER_HEIGHT,
  GRID_HEADER_HEIGHT,
  SIDE_BAR_CONFIG,
} from '../../const';
import '../ag-theme-alpine.css';
import './ClaimsGrid.css';
import { getGridRowId } from './ClaimsGrid.utils';

export interface IProps {
  isColumnSelectionOpen: boolean;
  gridOptions: ExtendedGridOptions;
  dataSourceLoading?: boolean;
}

export const ClaimsGrid = ({
  isColumnSelectionOpen,
  gridOptions,
  dataSourceLoading,
}: IProps) => {
  const { ref } = gridOptions;
  const gridApi = ref?.current?.api;

  useEffect(() => {
    isColumnSelectionOpen
      ? gridApi?.openToolPanel('columns')
      : gridApi?.closeToolPanel();
  }, [gridApi, isColumnSelectionOpen]);

  useEffect(() => {
    if (!gridApi) return;
     if (!dataSourceLoading && !gridApi?.getRenderedNodes().length) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [gridApi, dataSourceLoading, gridOptions]);

  return (
    <div
      id="id-claim-grid"
      className={classNames('claims-tool-grid', 'ag-theme-alpine')}
    >
      <AgGridReact
        suppressDragLeaveHidesColumns
        ref={ref}
        animateRows
        rowSelection="multiple"
        rowClass="claims-tool-grid__row"
        rowModelType="serverSide"
        // isServerSideGroupOpenByDefault,
        serverSideInfiniteScroll
        serverSideSortOnServer
        suppressRowClickSelection
        enableBrowserTooltips // Added this option, because default AgGrid tooltip can't show new lines '\n' (need for ClaimReviewer column)
        loadingOverlayComponent={ApLoaderDefault}
        overlayNoRowsTemplate='No results matching the search'
        defaultColDef={{
          ...DEFAULT_COL_DEF,
          // this will disable column checkboxes while running graphQL query
          lockVisible: dataSourceLoading,
        }}
        sideBar={SIDE_BAR_CONFIG}
        headerHeight={GRID_HEADER_HEIGHT}
        floatingFiltersHeight={GRID_FLOATING_FILTER_HEIGHT}
        getRowId={getGridRowId} // we have to set getRowId it to enable rows animations
        {...gridOptions}
      />
    </div>
  );
};

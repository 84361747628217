import { reportGenerator } from 'api/endpoints/reportGenerator';
import DOMPurify from 'dompurify';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const usePreview = (props: { type: string | undefined }) => {
  const { type } = props;
  const [reportUrl, setReportUrl] = useState<{ url: string; token: string }>();
  const [legalEntityId, setLegalEntityId] = useState<string>();
  const [part, setPart] = useState<string>('All');

  const fetchReportUrl = useCallback(async () => {
    const [token, url] = await Promise.all([
      reportGenerator.getReportGeneratorAppAuthToken(),
      reportGenerator.getReportGeneratorUrl(),
    ]);
    setReportUrl({
      token: token ?? '',
      url: url ?? '',
    });
  }, []);

  useEffect(() => {
    fetchReportUrl();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPart('All');
  }, [type]);

  const url = useMemo(() => {
    if (!reportUrl) return undefined;

    const { url, token } = reportUrl;
    const docType = type === 'schedules' ? 'schedule' : 'sofa';
    const partParam = part === 'All' ? '' : `&parts=${part}`;

    return DOMPurify.sanitize(
      `${url}${docType}?token=${token}&lid=${legalEntityId}&preview=true${partParam}`,
    );
  }, [reportUrl, type, legalEntityId, part]);

  return {
    url,
    loading: !reportUrl,
    legalEntityId,
    setLegalEntityId,
    part,
    setPart,
  };
};

import classnames from 'classnames';
import React, { forwardRef, useMemo } from 'react';

import { ApCheckbox, ApIcon, IIconProps } from '@alixpartners/ui-components';
import { IOption } from './MultiSelect';
import { ApMenuItemStyled, CheckIcon } from './MultiSelect.styles';

const DEFAULT_ICON_SIZE = '18px';

export interface IMultiSelectLabelProps {
  isOpen?: boolean;
  disabled?: boolean;
  iconName?: string;
  iconProps?: IIconProps;
  text: string;
  className?: string;
}

export const MultiSelectLabel = forwardRef<
  HTMLButtonElement,
  IMultiSelectLabelProps
>((props, ref) => {
  const {
    isOpen,
    iconProps,
    iconName,
    disabled,
    text = '',
    className,
    ...rest
  } = props;
  const classMultiselectDisabled = disabled ? 'multiselect--disabled' : undefined;
  return (
    <button
      className={classnames(
        'multiselect--button',
        className,
        classMultiselectDisabled,
      )}
      {...rest}
      type="button"
      ref={ref}
      disabled={disabled}
    >
      {iconName && (
        <ApIcon
          className={classMultiselectDisabled}
          iconName={iconName}
          iconSize={DEFAULT_ICON_SIZE}
          {...iconProps}
        />
      )}
      <div className={classnames('multiselect--text', classMultiselectDisabled)}>
        {text}
      </div>
      <ApIcon
        className={classnames(classMultiselectDisabled, {
          'multiselect--icon-rotate': isOpen,
        })}
        iconName="caret"
      />
    </button>
  );
});

export const OptionsList = (props: {
  options: IOption[];
  multiple?: boolean;
  selected?: IOption[];
  maxItemsExceeded?: boolean;
  level?: number;
  onChange: (option: IOption) => void;
}) => {
  const {
    options,
    multiple,
    selected,
    maxItemsExceeded,
    level = 0,
    onChange,
  } = props;

  return (
    <>
      {options.map((o, id) => (
        <React.Fragment key={`${id}`}>
          <ApMenuItemStyled
            className="multiselect--menu-item"
            disableCloseOnClick={multiple}
            onClick={() => onChange(o)}
            disabled={o.disabled}
            level={level}
            icon={
              <CheckboxIcon
                option={o}
                selected={selected}
                multiple={multiple}
                maxItemsExceeded={maxItemsExceeded}
              />
            }
          >
            {o.labelNode || <div>{o.label}</div>}
          </ApMenuItemStyled>
          {o.children && (
            <OptionsList
              options={o.children}
              multiple={multiple}
              selected={selected}
              maxItemsExceeded={maxItemsExceeded}
              level={level + 1}
              onChange={onChange}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const CheckboxIcon = (props: {
  option: IOption;
  selected?: IOption[];
  multiple?: boolean;
  maxItemsExceeded?: boolean;
}) => {
  const { selected, multiple, option, maxItemsExceeded } = props;

  const checked = useMemo(
    () => !!selected?.find((o: IOption) => o.value === option.value),
    [selected, option],
  );

  if (option.iconName)
    return (
      <ApIcon iconName={option.iconName} iconSize="18px" {...option.iconProps} />
    );

  if (multiple)
    return (
      <ApCheckbox
        autoFocus={false}
        disabled={!checked && maxItemsExceeded}
        id="multiselect-checkbox"
        theme="light"
        type="checkbox"
        checked={checked}
        readOnly
      />
    );

  return <CheckIcon iconName="outline_done" visible={checked} iconSize="18px" />;
};

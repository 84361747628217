import { ClaimsGrid } from 'pages/ClaimsContractsToolPage/grid/ClaimsGrid/ClaimsGrid';
import { TSummaryMatchDialog } from 'pages/ClaimsContractsToolPage/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ClaimsData } from '../../types';
import { ClaimsAllSubheader } from './ClaimsAllSubheader';
import { ClaimsBatchEdit } from './ClaimsBatchEdit';
import { ClaimsMatchingSummaryModal } from './ClaimsMatchingSummaryModal';
import './ClaimsTable.css';
import { useAppDispatch } from 'hooks/reducerHooks';
import { getClaimsCategoriesThunk } from 'reducer/thunks/claimsCategoriesThunks';

export interface IClaimsToolContentProps {
  claimsData: ClaimsData;
  claimsCount: number | undefined;
  isColumnSelectionOpen: boolean;
  summaryMatchDialog: TSummaryMatchDialog;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
  setColumnSelection: Dispatch<SetStateAction<boolean>>;
}

export const ClaimsTable = ({
  claimsData,
  claimsCount,
  isColumnSelectionOpen,
  summaryMatchDialog,
  disableResetFilters,
  onResetFilters,
  setColumnSelection,
}: IClaimsToolContentProps) => {
  const [showBatch, setShowBatch] = useState(false);
  const dispatch = useAppDispatch();

  const handleBatchComplete = () => {
    setShowBatch(false);
    claimsData.refresh();
  };

  const handleCancelClick = () => {
    setShowBatch(false);
    claimsData?.onResetFilters?.();
  };

  useEffect(() => {
    dispatch(getClaimsCategoriesThunk());
  }, [dispatch]);

  return (
    <>
      <ClaimsAllSubheader
        searchText={claimsData.searchText}
        claimsCount={claimsCount}
        showMineClaims={claimsData.showMineClaims}
        disableResetFilters={disableResetFilters}
        isColumnSelectionOpen={isColumnSelectionOpen}
        onResetFilters={onResetFilters}
        setColumnSelection={setColumnSelection}
        onShowMineClaimsChange={claimsData.onShowMineClaimsChange}
        onBatchClick={() => setShowBatch((r) => !r)}
        onExportClaims={claimsData.onExport}
        onSearchChanged={claimsData.onSearchChanged}
      />
      <div className="claims-all--body">
        <ClaimsGrid
          isColumnSelectionOpen={isColumnSelectionOpen}
          gridOptions={claimsData.grid}
          dataSourceLoading={claimsData.dataSourceLoading}
        />

        {showBatch && (
          <ClaimsBatchEdit
            claimsData={claimsData}
            onClose={handleBatchComplete}
            cancelBatch={handleCancelClick}
          />
        )}
      </div>

      {summaryMatchDialog.show && claimsData.gridColumns && (
        <ClaimsMatchingSummaryModal
          claim={summaryMatchDialog.data}
          onClose={summaryMatchDialog.close}
        />
      )}
    </>
  );
};

import { ReportLegalEntity, ReportPart } from 'api/endpoints';
import { IOption } from 'components/UIComponents/MultiSelectExtendable';

export function reportPartToOptionPrefixed(part: ReportPart): IOption {
  const opt: IOption = {
    label: `${part.parentType} ${part.partDescription}`,
    value: part.partName,
    data: part,
  };
  return opt;
}

export function reportPartToOption(part: ReportPart): IOption {
  const opt: IOption = {
    label: part.partDescription,
    value: part.partName,
    data: part,
  };
  return opt;
}

export function mapLegalEntityToOption(le: ReportLegalEntity): IOption {
  const opt: IOption = {
    label: le.entityName,
    value: `${le.legalEntityId}`,
    data: le,
  };
  return opt;
}

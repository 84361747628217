export enum ResponseType {
  OK = 1,
  BAD_REQ,
  SEVER_ERR,
  FETCH_ERR,
  PARSE_ERR,
}

export type OkResponse<T> = {
  type: ResponseType.OK;
  data: T;
};

export type ErrorBody = { status: number; error: any };

export type BadRequest<E extends ErrorBody> = {
  type: ResponseType.BAD_REQ;
  data: E;
};

export type ServerError = {
  type: ResponseType.SEVER_ERR;
  status: number;
};

export type FetchError = {
  type: ResponseType.FETCH_ERR;
};

export type JsonParseError = {
  type: ResponseType.PARSE_ERR;
};

export type ErrorResponse<E extends ErrorBody> =
  | FetchError
  | ServerError
  | JsonParseError
  | BadRequest<E>;

export type JsonFetchResponse<T, E extends ErrorBody> =
  | OkResponse<T>
  | ErrorResponse<E>;

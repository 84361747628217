import { IOption } from '@alixpartners/ui-components';

export interface SelectedItems {
  useAll: boolean;
  items: IOption[];
}

export interface SofasSelected {
  entities: SelectedItems;
  parts: SelectedItems;
}

export interface ExportDropdownItems {
  entities: IOption[];
  parts: IOption[];
}

export interface ExportSofasItems {
  sofa: ExportDropdownItems;
  schedule: ExportDropdownItems;
}

export const emptyDropdownItems: ExportDropdownItems = {
  entities: [],
  parts: [],
};

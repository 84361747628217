import {
  ColDef,
  GridApi,
  RowEditingStoppedEvent,
  RowValueChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import { GraphQlApolloClient } from 'api/graphql';
import _ from 'lodash';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import { ISofaTableProps } from '../tables.types';
import {
  createAddQuery,
  createDeleteQuery,
  createUpdateQuery,
} from './graphQlUtils/mutationQuery';

export const useTableBody = (
  gridRef: React.RefObject<AgGridReact>,
  client: GraphQlApolloClient,
  sofaTable: ISofaTableProps,
) => {
  const [processing, setProccessing] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<any[]>();

  const onRowAdd = useCallback(async () => {
    if (!gridRef.current?.api) return;
    gridShowNewValueRow(gridRef.current?.api);
  }, [gridRef]);

  const onRowEditingStopped = useCallback(
    (event: RowEditingStoppedEvent) => {
      if (!gridRef.current?.api) return;
      if (event.rowPinned === 'top') {
        gridHideNewValueRow(gridRef.current?.api);
      }
    },
    [gridRef],
  );

  const onRowValueChanged = useCallback(
    async (event: RowValueChangedEvent) => {
      if (!gridRef.current?.api) return;
      const { data } = event;

      const isNewItem = !data.id; //"id" is undefined for new item
      if (isNewItem && !data.legalEntityId) return;

      setProccessing(true);
      if (isNewItem) {
        await queryAddItem(data, sofaTable, client);
      } else {
        await queryEditItem(data, sofaTable, client);
      }
      setProccessing(false);

      gridRef.current?.api.refreshServerSide();
    },
    [gridRef, client, sofaTable],
  );

  const onRowDelete = useCallback(async () => {
    if (!gridRef.current?.api) return;

    const selectedRows = gridRef.current?.api?.getSelectedNodes();
    if (!selectedRows?.length) {
      console.warn('[Example] No row selected.');
      return;
    }
    const dataList = selectedRows.map((r) => r.data);

    setDataToDelete(dataList);
  }, [gridRef]);

  const confirmDialog = useMemo(() => {
    if (!dataToDelete) return undefined;

    const itemsText = pluralize('item', dataToDelete.length);

    return {
      title: 'Confirm',
      body: `Are you sure you want to delete ${itemsText}?`,
      handleConfirm: async (confirm: boolean) => {
        const items = dataToDelete;
        setDataToDelete(undefined);

        if (!confirm) return;
        setProccessing(true);
        await queryDeleteItems(items, sofaTable, client);
        setProccessing(false);

        gridRef.current?.api.refreshServerSide();
      },
    };
  }, [dataToDelete, gridRef, sofaTable, client]);

  return {
    processing,
    confirmDialog,
    onRowAdd,
    onRowDelete,
    onRowValueChanged,
    onRowEditingStopped,
  };
};

// ---- private methods -----

const gridHideNewValueRow = (api: GridApi) => {
  api.setPinnedTopRowData();
};

const gridShowNewValueRow = (api: GridApi) => {
  const firstColKey = (api.getColumnDefs()?.[0] as ColDef)?.field ?? '';
  const newData = {}; //createNewRowData();
  api.setPinnedTopRowData([newData]);

  //we need to wait when pinned row apper on the screen, to be able make it editable
  setTimeout(() => {
    api!.setFocusedCell(0, firstColKey, 'top');
    api!.startEditingCell({
      rowIndex: 0,
      colKey: firstColKey,
      rowPinned: 'top',
    });
  }, 5);
};

const queryAddItem = async (
  data: any,
  sofaTable: ISofaTableProps,
  client: GraphQlApolloClient,
) => {
  //at this moment 'legalEntity' column is not editable. We should use 'legalEntityId' column instead
  const value = _.omit(data, ['legalEntity']);

  try {
    const mutation = createAddQuery(sofaTable, value);
    await client.mutate({ mutation });
  } catch (e: any) {
    console.error(e.message);
    showHttpSingleError({ title: e.message });
  }
};
const queryEditItem = async (
  data: any,
  sofaTable: ISofaTableProps,
  client: GraphQlApolloClient,
) => {
  //at this moment 'legalEntity' column is not editable. We should use 'legalEntityId' column instead
  const value = _.omit(data, ['legalEntity']);

  try {
    const mutation = createUpdateQuery(sofaTable, value);
    await client.mutate({ mutation });
  } catch (e: any) {
    console.error(e.message);
    showHttpSingleError({ title: e.message });
  }
};

const queryDeleteItems = async (
  items: any[],
  sofaTable: ISofaTableProps,
  client: GraphQlApolloClient,
) => {
  for (const row of items) {
    await queryDeleteItem(row, sofaTable, client);
  }
};

const queryDeleteItem = async (
  data: any,
  sofaTable: ISofaTableProps,
  client: GraphQlApolloClient,
) => {
  //at this moment 'legalEntity' column is not editable. We should use 'legalEntityId' column instead
  const value = _.omit(data, ['legalEntity']);

  try {
    const mutation = createDeleteQuery(sofaTable, value);
    await client.mutate({ mutation });
  } catch (e: any) {
    console.error(e.message);
    showHttpSingleError({ title: e.message });
  }
};

import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { eUploadStatus } from '../utils/dataUploadMenuUtils';

interface Props {
  label: string;
  active?: boolean;
  status: eUploadStatus;
  onClick?: () => void;
}

export const DataUploadMenuItem = ({ label, active, status, onClick }: Props) => {
  const isError = status === eUploadStatus.Error;
  const iconColor = active ? '#fff' : '#999999';

  return (
    <div
      className={classNames('dataupload-menu-section_item', {
        'dataupload-menu-section_item-selected': active,
      })}
      onClick={onClick}
    >
      <span>{label}</span>
      <MenuItemIcon status={status} color={isError ? '#f00' : iconColor} />
    </div>
  );
};

const MenuItemIcon = (props: {
  status: eUploadStatus;
  color: string;
}): JSX.Element => {
  const { status, color } = props;
  switch (status) {
    case eUploadStatus.Unpublished:
      return <SvgIcon iconName={'dynamicFeed'} fill={color} />;
    case eUploadStatus.UploadedAll:
      return <ApIcon iconName="outline_check_circle_outline" iconColor={color} />;
    case eUploadStatus.Error:
      return <ApIcon iconName="outline_warning" iconColor={color} />;
    default:
      return <SvgIcon iconName={'arrow_right'} fill={color} />;
  }
};

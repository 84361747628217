import {
  ActionName,
  actionNames,
  ContractActionName,
  ServerActionName,
  ServerContractActionName,
} from 'api/endpoints/action-names';
import { actionSelectOptions } from 'api/endpoints/actions-select-options';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { FetchStatus } from 'types/fetch-status.types';
import {
  actionNameCreated,
  actionNameEdit,
  actionNamesDone,
  actionNamesError,
  actionNamesFetching,
  contractActionNameCreated,
  contractActionNameEdit,
  contractActionNamesDone,
  contractActionNamesFetching,
} from '../actionNamesReducer';
import {
  actionsSelectOptionsDone,
  actionsSelectOptionsError,
  actionsSelectOptionsFetching,
} from '../actionsSelectOptions';
import {
  contractActionSelectOptionsDone,
  contractActionSelectOptionsError,
  contractActionSelectOptionsFetching,
} from '../contractActionsSelectOptions';
import { AppDispatch, GetState } from '../store';

export const fetchActionsSelectOptions =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(actionsSelectOptionsFetching());
    const result = await actionSelectOptions.getOptions();
    if (result.type === ResponseType.OK) {
      return dispatch(actionsSelectOptionsDone(result.data));
    }
    dispatch(
      actionsSelectOptionsError({ message: 'Could not fetch action options.' }),
    );
  };

export const fetchContractActionsSelectOptions =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(contractActionSelectOptionsFetching());
    const result = await actionSelectOptions.getContractOptions();
    if (result.type === ResponseType.OK) {
      return dispatch(contractActionSelectOptionsDone(result.data));
    }
    dispatch(
      contractActionSelectOptionsError({
        message: 'Could not fetch action options.',
      }),
    );
  };

export function serverActionNameToActionName(sa: ServerActionName): ActionName {
  return {
    id: sa.id,
    hearingDate: sa.hearingDate,
    name: sa.actionName,
    typeId: sa.actionExhibitTypeId,
    substatusId: sa.actionSubStatusId,
  };
}

export function serverContractActionNameToActionName(
  sa: ServerContractActionName,
): ContractActionName {
  return {
    id: sa.id,
    hearingDate: sa.hearingDate,
    contractActionName: sa.contractActionName,
    contractActionNameId: sa.contractActionNameId,
    contractExhibitTypeId: sa.contractExhibitTypeId,
  };
}

export const fetchActionNamesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(actionNamesFetching());
    const result = await actionNames.get();
    if (result.type === ResponseType.OK) {
      dispatch(actionNamesDone(result.data.map(serverActionNameToActionName)));
    } else {
      dispatch(actionNamesError({ message: 'Could not fetch resolution names.' }));
    }

    return result;
  };

export const fetchContractActionNamesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(contractActionNamesFetching());
    const result = await actionNames.getContractActionNames();
    if (result.type === ResponseType.OK) {
      dispatch(
        contractActionNamesDone(
          result.data.map(serverContractActionNameToActionName),
        ),
      );
    } else {
      dispatch(actionNamesError({ message: 'Could not fetch resolution names.' }));
    }

    return result;
  };

export type CreateParams = {
  name: string;
  typeId: number;
  hearingDate?: string;
};

export type EditParams = CreateParams & { id: number };

export type ActionEditOrAddResponse =
  | {
      type: FetchStatus.Done;
      item: ActionName;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
    };

export type ContractActionEditOrAddResponse =
  | {
      type: FetchStatus.Done;
      item: ContractActionName;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
    };

export const creatActionNameThunk =
  (newActionName: Omit<ActionName, 'id' | 'typeId'>) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<ActionEditOrAddResponse> => {
    const result = await actionNames.post({
      actionName: newActionName.name,
      actionSubStatusId: newActionName.substatusId,
      hearingDate: newActionName.hearingDate,
    });
    if (result.type === ResponseType.OK) {
      const action = serverActionNameToActionName(result.data);
      dispatch(actionNameCreated(action));
      return { type: FetchStatus.Done, item: action };
    } else if (result.type === ResponseType.BAD_REQ) {
      const message = result.data.error[0].errorMessage;
      return { type: FetchStatus.Error, error: { message } };
    }
    return {
      type: FetchStatus.Error,
      error: {
        message: 'An unexpected error occured when creating the resolution name.',
      },
    };
  };

export const createContractActionNameThunk =
  (
    newActionName: Omit<
      ContractActionName,
      | 'contractActionSubStatusId'
      | 'id'
      | 'contractActionTypeId'
      | 'contractActionNameId'
    >,
  ) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<ContractActionEditOrAddResponse> => {
    const result = await actionNames.postContractActionNames({
      contractExhibitTypeId: newActionName.contractExhibitTypeId,
      contractActionName: newActionName.contractActionName,
      hearingDate: newActionName.hearingDate,
    });
    if (result.type === ResponseType.OK) {
      const action = serverContractActionNameToActionName(result.data);
      dispatch(contractActionNameCreated(action));
      return { type: FetchStatus.Done, item: action };
    } else if (result.type === ResponseType.BAD_REQ) {
      const message = result.data.error[0].errorMessage;
      return { type: FetchStatus.Error, error: { message } };
    }
    return {
      type: FetchStatus.Error,
      error: {
        message: 'An unexpected error occured when creating the resolution name.',
      },
    };
  };

export const editActionNameThunk =
  (editedAction: Omit<ActionName, 'typeId'>) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<ActionEditOrAddResponse> => {
    const result = await actionNames.put({
      id: editedAction.id,
      actionName: editedAction.name,
      actionSubStatusId: editedAction.substatusId,
      hearingDate: editedAction.hearingDate,
    });
    if (result.type === ResponseType.OK) {
      const action = serverActionNameToActionName(result.data);
      dispatch(actionNameEdit(action));
      return { type: FetchStatus.Done, item: action };
    } else if (result.type === ResponseType.BAD_REQ) {
      // pick the first message
      const message = result.data.error[0].errorMessage;
      return { type: FetchStatus.Error, error: { message } };
    }
    return {
      type: FetchStatus.Error,
      error: {
        message: 'An unexpected error occured when editing the resolution name.',
      },
    };
  };

export const editAContractsctionNameThunk =
  (
    editedAction: Omit<
      ContractActionName,
      'contractActionSubStatusId' | 'contractActionTypeId' | 'contractActionNameId'
    >,
  ) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<ContractActionEditOrAddResponse> => {
    const result = await actionNames.putContractActionNames({
      contractExhibitTypeId: editedAction.contractExhibitTypeId,
      contractActionName: editedAction.contractActionName,
      hearingDate: editedAction.hearingDate,
      id: editedAction.id,
    });
    if (result.type === ResponseType.OK) {
      const action = serverContractActionNameToActionName(result.data);
      dispatch(contractActionNameEdit(action));
      return { type: FetchStatus.Done, item: action };
    } else if (result.type === ResponseType.BAD_REQ) {
      // pick the first message
      const message = result.data.error[0].errorMessage;
      return { type: FetchStatus.Error, error: { message } };
    }
    return {
      type: FetchStatus.Error,
      error: {
        message: 'An unexpected error occured when editing the resolution name.',
      },
    };
  };

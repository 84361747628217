import { post } from '../apiRequest';

export type ExportResponse = {
  jobId: number;
  statusEndpoint: string;
};

export const claimsExcelExport = {
  getFiltered: (params: { query: string; variables: any }) => {
    return post<ExportResponse>(`v1/Claims/filteredClaimsExcelExport`, params);
  },
};

import {
  dataPipeline,
  FileComparisonResponse,
  fileInfo,
  FileInfoRequest,
} from 'api/endpoints';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import { FileStatus, FileType, IUploadItem } from 'types/dataUploaderTypes';
import {
  uploadClaimImage,
  uploadClaimImageZipMapping,
  uploadClaimZipImage,
  uploadContractImage,
  uploadContractImageZipMapping,
  uploadContractZipImage,
  uploadExcel,
} from 'utils/uploader';
import { uploadSections } from '../utils/dataUploader.config';
import { claimsDocumentsApi, contractDocumentsApi } from 'api/endpoints/documents';

export const azureStorageUpload = async (
  items: IUploadItem[],
  onProgress?: (item: IUploadItem, loaded: number, size: number) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size);
    const { file, type } = item;
    const uploadFile = await uploadExcel(file!, type, progressFn);

    await postFileInfo(item, uploadFile?.name, {
      isOverride: true,
      fileState: FileStatus.Unpublished,
    });
  }
};

export const azureStorageUploadClaimImage = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const itemIndex = items.indexOf(item) + 1;
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size, itemIndex);
    const { file } = item;
    const uploadFile = await uploadClaimImage(file!, abortSignal, progressFn);

    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });
  }
};

export const azureStorageUploadClaimZipImage = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size);
    const { file } = item;
    const uploadFile = await uploadClaimZipImage(file!, abortSignal, progressFn);
    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });

    await claimsDocumentsApi.triggerUnzip?.(uploadFile?.name!);
  }
};

export const azureStorageUploadContractImage = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;
  for (let item of items) {
    const itemIndex = items.indexOf(item) + 1;
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size, itemIndex);
    const { file } = item;
    const uploadFile = await uploadContractImage(file!, abortSignal, progressFn);

    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });
  }
};

export const azureStorageUploadContractZipImage = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size);
    const { file } = item;
    const uploadFile = await uploadContractZipImage(file!, abortSignal, progressFn);
    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });
    await contractDocumentsApi.triggerUnzip?.(uploadFile?.name!);
  }
};

export const azureStorageUploadContractZipImageMapping = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size);
    const { file } = item;
    const uploadFile = await uploadContractImageZipMapping(
      file!,
      abortSignal,
      progressFn,
    );

    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });
    await contractDocumentsApi.triggerMapping(uploadFile?.name!);
  }
};

export const azureStorageUploadClaimImageMapping = async (
  items: IUploadItem[],
  abortSignal: AbortSignal,
  onProgress?: (
    item: IUploadItem,
    loaded: number,
    size: number,
    itemIndex?: number,
  ) => void,
) => {
  if (!items.length) return;

  for (let item of items) {
    const progressFn = (loaded: number, size: number) =>
      onProgress?.(item, loaded, size);
    const { file } = item;
    const uploadFile = await uploadClaimImageZipMapping(
      file!,
      abortSignal,
      progressFn,
    );

    await postFileInfo(item, uploadFile?.name, {
      isOverride: false,
      fileState: FileStatus.Published,
    });
    await claimsDocumentsApi.triggerMapping(uploadFile?.name!);
  }
};

export const azureStorageUploadClaimsContractsBatchEdit = async (
  item: IUploadItem,
  onProgress?: (item: IUploadItem, loaded: number, size: number) => void,
) => {
  if (!item) return;

  const progressFn = (loaded: number, size: number) =>
    onProgress?.(item, loaded, size);
  const { file, type } = item;
  const resp = await uploadExcel(file!, type, progressFn);
  return {
    name: resp?.name,
  };
};

export const postFileInfo = async (
  item: IUploadItem,
  name: string | undefined,
  extendedOptions: { isOverride: boolean; fileState: FileStatus },
) => {
  if (!name) return;
  const { isOverride, fileState } = extendedOptions;

  const fileInfoRequest: FileInfoRequest = {
    fileType: item.type,
    fileState,
    errorCount: 0,
    name,
    displayName: item.file!.name,
    isOverride,
    schedulePart: item?.file?.schedulePart,
  };

  await fileInfo.postFileInfo(fileInfoRequest);
};

export const getUploadedFiles = async () => {
  const [fileInfoResult, errorDict] = await Promise.all([
    fileInfo.getFileInfo(),
    getAllSectionsError(),
  ]);

  const filesWithErrors = fileInfoResult?.map((f) => {
    const name = f.name ?? '';

    const item: IUploadItem = {
      id: f.id,
      name: f.name,
      displayName: f.displayName,
      status: f.fileState,
      type: f.fileType,
      uploadedFile: f,
      errors: errorDict[name]?.errorMessages,
      errorCount: errorDict[name]?.errorCount,
    };
    return item;
  });

  return filesWithErrors ?? [];
};

/** @todo: we should really talk to BE and fetch all errors as one endpoint.. */
export const getAllSectionsError = async () => {
  const errorFetcherPromise = uploadSections?.map((s) =>
    fileInfo.getErrorsSummary(s.type),
  );
  const errors = await Promise.all(errorFetcherPromise);

  const errorsList = errors.flat().filter((r) => r?.hasError);
  const errorsData = compact(errorsList);
  const result = keyBy(errorsData, (r) => r?.fileName);
  return result;
};

export const bulkCompareFiles = async (files: IUploadItem[]) => {
  const comparisonResults = await Promise.all(files.map(compareFile));

  return comparisonResults
    .filter((result): result is FileComparisonResponse => result !== undefined)
    .reduce(
      (acc, curr) => {
        return {
          duplicateCount: acc.duplicateCount + curr.duplicateCount,
          add: acc.add + curr.add,
          delete: acc.delete + curr.delete,
          duplicates: [...acc.duplicates, ...curr.duplicates],
        };
      },
      {
        duplicateCount: 0,
        add: 0,
        delete: 0,
        duplicates: [],
      },
    );
};

export const compareFile = async (file: IUploadItem) => {
  switch (file.type) {
    case FileType.Sofa:
    case FileType.SofaAppend:
      return dataPipeline.compareSofaFile(file.id);
    case FileType.Schedule:
    case FileType.ScheduleAppend:
      return dataPipeline.compareScheduleFile(file.id);
    default:
      // @todo - implement for more file types
      throw new Error('not implemented');
  }
};

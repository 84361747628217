import {
  GlobalNote,
  GlobalNotePut,
  globalNotes,
  SofaOrScheduleFileType,
} from 'api/endpoints';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { getActions } from '../globalNotesReducer';

import { AppDispatch, GetState } from '../store';

export const fetchAllGlobalNotesThunks =
  (fileType: SofaOrScheduleFileType) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const { done, error, fetching } = getActions(fileType);
    dispatch(fetching());

    const response = await globalNotes.getList(fileType);
    if (response.type === ResponseType.OK) {
      dispatch(done({ items: response.data }));
      return;
    }

    dispatch(error({ message: 'Could not fetch global notes.' }));
  };

export const deleteGlobalNoteThunk =
  (fileType: SofaOrScheduleFileType, id: number) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const { deleteFetching, deleteDone, deleteError } = getActions(fileType);
    dispatch(deleteFetching());
    const response = await globalNotes.delete({ id });

    if (response.type === ResponseType.OK) {
      dispatch(deleteDone({ id }));
    } else {
      dispatch(deleteError({ message: 'Could not delete global note.' }));
    }
  };

export const updateGlobalNoteThunk =
  (fileType: SofaOrScheduleFileType, item: GlobalNotePut) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const { updateBegin, updateDone, updateError } = getActions(fileType);
    dispatch(updateBegin());
    const response = await globalNotes.put(item);

    if (response.type === ResponseType.OK) {
      const updatedItem = response.data as GlobalNote;
      dispatch(updateDone({ item: updatedItem }));
    } else {
      dispatch(updateError({ message: 'Item update error' }));
    }
  };

export const SCHEDULE_AB_11_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: [
        'Notes',
        'TaxRefunds',
        'InsuranceInterest',
        'ActionAgainst3rdParty',
        'OtherContingent',
        'PropertyInterest',
        'OtherProperty',
      ],
      dataType: 'enum',
      displayName: 'Type of Contingent Asset',
      propertyName: 'typeOfContingentAsset',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Current Value',
      propertyName: 'currentValue',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Appraised in the Last 12 Months',
      propertyName: 'appraisedInLastYear',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

import { ColDef, GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cs from 'classnames';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useCallback, useMemo } from 'react';
import './ExhibitTable.css';

const GRID_ROW_HEIGHT = 40;
const GRID_HEADER_HEIGHT = 30;

interface Props<T> {
  rows: T[];
  loading?: boolean;
  title?: string;
  warningMessage?: React.ReactChild;
  columnDefs: ColDef[];
  gridOptions?: GridOptions<T>;
  onSelectedChange: (items: T[]) => any;
}

export const ExhibitTable = <T extends {}>({
  rows,
  loading,
  title,
  warningMessage,
  columnDefs,
  gridOptions,
  onSelectedChange,
}: Props<T>) => {
  const height = useMemo(() => {
    let value = rows.length * GRID_ROW_HEIGHT;
    if (value < 200) value = 200;
    if (value > 400) value = 400;
    return value;
  }, [rows.length]);

  const handleSelectedChanged = useCallback(
    (e: SelectionChangedEvent<T>) => {
      const selectedRows = e.api.getSelectedRows();
      onSelectedChange(selectedRows);
    },
    [onSelectedChange],
  );

  return (
    <div className={cs('exhibit-table', 'ag-theme-alpine')} style={{ height }}>
      <header>
        <div>{title}</div>
        {warningMessage}
      </header>
      <DivWithLoader loading={loading} className="exhibit-table__body">
        <AgGridReact
          defaultColDef={{
            resizable: true,
            suppressMenu: true,
          }}
          rowData={rows}
          suppressMovableColumns
          animateRows
          rowSelection="multiple"
          rowClass="exhibit-table__row"
          suppressRowClickSelection
          suppressContextMenu
          headerHeight={GRID_HEADER_HEIGHT}
          columnDefs={columnDefs}
          onSelectionChanged={handleSelectedChanged}
          gridOptions={gridOptions}
        />
      </DivWithLoader>
    </div>
  );
};

import classNames from 'classnames';
import { ApProgressBar } from 'components/ApProgressBar';
import { useDropzone } from 'react-dropzone';
import './FileUploadSection.css';

export type Extension =
  //documents
  | '.xlsx'
  | '.xls'
  | '.pdf'
  | '.docx'
  | '.doc'
  //image
  | '.png'
  | '.gif'
  | '.jpg'
  | '.jpeg'
  | '.jfif'
  | '.jif'
  | '.heic' // Image used on the Mac/IPhone
  | '.webp'
  // emails
  | '.eml'
  | '.msg'
  | '.pst'
  | '.edb'
  | '.ost'
  | '.mbox'
  // zip for contract images
  | '.zip';

export type UploadProgress = {
  fileName?: string;
  progress: number;
};

type Props = React.PropsWithChildren<{
  className?: string;
  maxFiles?: number;
  multiple?: boolean;
  accept?: Extension[] | Extension;
  uploadProgress?: UploadProgress;
  onUpload: (files: File[]) => Promise<boolean>;
}>;

export const FileUploadSection = ({
  className,
  maxFiles,
  multiple,
  accept,
  children,
  uploadProgress,
  onUpload,
}: Props) => {
  const handleUpload = (files: File[]) => {
    onUpload(files);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles,
    accept,
    multiple,
    onDrop: handleUpload,
  });

  return (
    <div
      className={classNames('fileupload-section', className, {
        'fileupload-section__dragging': isDragActive,
      })}
    >
      {uploadProgress ? (
        <FileUploadProgress
          fileName={uploadProgress.fileName}
          progress={uploadProgress.progress}
        />
      ) : (
        <div className="fileupload-section__dragdrop" {...getRootProps()}>
          <input {...getInputProps()} />
          {children ?? <BodyMessage />}
        </div>
      )}
    </div>
  );
};

const FileUploadProgress = (props: {
  fileName?: string | undefined;
  progress: number;
}) => {
  const progress = props.progress > 1 ? 1 : props.progress;
  const titleText = props.fileName ? `Uploading: ${props.fileName}` : 'Uploading';

  return (
    <div className="fileupload-section__uploading">
      <div>{titleText}</div>
      <ApProgressBar progress={progress} />
    </div>
  );
};

const BodyMessage = () => (
  <div className="fileupload-section__caption">
    Drag your file here or browse files to upload
  </div>
);

import { GlobalNote, globalNotes as globalNotesAPI } from 'api/endpoints';
import classNames from 'classnames';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { deleteGlobalNoteThunk } from 'reducer/thunks/globalNotes-thunks';
import { FileType } from 'types/dataUploaderTypes';
import { FetchStatus } from 'types/fetch-status.types';
import { ActionList } from '../../Components/ActionList';
import { AddButton } from '../../Components/AddButton';
import { NoteListItem } from './Components/NoteListItem';
import './GlobalNotes.css';
import { GlobalNotesErrorModal } from './GlobalNotesErrorModal';
import { GlobalNotesUploadModal } from './GlobalNotesUploadModal';
import { useGlobalNotes } from './useGlobalNotes';
import { useUploadGlobalNote } from './useGlobalNotesUpload';

import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { GlobalNotesDeleteModal } from './GlobalNotesDeleteModal';

type NewItemWindow = { item: GlobalNote | undefined };
type DeleteWindow = { item: GlobalNote };

export const GlobalNotes = () => {
  const [itemModalWinow, setItemModalWindow] = useState<NewItemWindow>();
  const [showDeleteModal, setShowDeleteModal] = useState<DeleteWindow>();

  const dispatch = useAppDispatch();
  const urlParams = useParams();

  const type = useMemo(
    () => (urlParams.type === 'schedules' ? FileType.Schedule : FileType.Sofa),
    [urlParams.type],
  );

  const { data, fetchState, updateState, deleteState } = useGlobalNotes(type);
  const { uploadStatus, resetError, createGlobalNote, updateGlobalNote } =
    useUploadGlobalNote(type);

  const showLoading =
    fetchState.status === FetchStatus.Fetching ||
    updateState.status === FetchStatus.Fetching ||
    deleteState.status === FetchStatus.Fetching ||
    !!uploadStatus.progress;

  const handleModalOpen = useCallback(
    () => setItemModalWindow({ item: undefined }),
    [],
  );
  const handleModalClose = useCallback(() => setItemModalWindow(undefined), []);

  const handleModalSave = useCallback(
    (files: File[], entityIds: number[]) => {
      const item = itemModalWinow?.item;
      const legalEntities = entityIds.map((legalEntityId) => ({ legalEntityId }));

      handleModalClose();
      if (!item) {
        createGlobalNote({ files, legalEntities });
        return;
      }

      const newItem = { ...item, legalEntities };
      updateGlobalNote(newItem);
    },
    [itemModalWinow, createGlobalNote, handleModalClose, updateGlobalNote],
  );

  const handleDeleteClick = useCallback(
    (item: GlobalNote) => setShowDeleteModal({ item }),
    [],
  );

  const handleEdit = useCallback(
    (item: GlobalNote) => setItemModalWindow({ item }),
    [],
  );

  const handleDelete = useCallback(
    (confirm: boolean) => {
      const item = showDeleteModal?.item;
      setShowDeleteModal(undefined);
      if (confirm && item) {
        dispatch(deleteGlobalNoteThunk(type, item.id));
      }
    },
    [dispatch, showDeleteModal?.item, type],
  );

  const handleDownload = useCallback(
    (item: GlobalNote) => globalNotesAPI.download(item),
    [],
  );

  return (
    <div
      className={classNames(
        'sofas-schedules-panel global-notes-selection-panel sofas-schedules__content',
      )}
    >
      <h4>Global Notes</h4>
      <DivWithLoader
        className={classNames(
          'sofas-schedules-panel__inner-panel global-notes-selection-panel__inner-panel',
        )}
        loading={showLoading}
      >
        <div className="global-notes-add-strip">
          <AddButton onClick={handleModalOpen} disabled={showLoading} />
        </div>

        <ActionList uploadProgress={uploadStatus.progress ?? -1} hideOnEmptyList>
          {data?.map((item) => (
            <NoteListItem
              key={item.id}
              item={item}
              onDeleteClick={handleDeleteClick}
              onDownloadClick={handleDownload}
              onEditClick={handleEdit}
            />
          ))}
        </ActionList>
      </DivWithLoader>
      {itemModalWinow && (
        <GlobalNotesUploadModal
          item={itemModalWinow.item}
          onClosed={handleModalClose}
          onSave={handleModalSave}
        />
      )}
      {uploadStatus.error && (
        <GlobalNotesErrorModal errorList={uploadStatus.error} onClose={resetError} />
      )}
      {showDeleteModal && (
        <GlobalNotesDeleteModal item={showDeleteModal.item} onClose={handleDelete} />
      )}
    </div>
  );
};

import classnames from 'classnames';
import { Footer } from 'components/Footer';
import { NavBarContainer } from 'components/NavBar';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { ComponentProps } from 'react';
import './PageMainPanel.css';

interface Props extends ComponentProps<'div'> {
  hideFooter?: boolean;
  loading?: boolean;
}

export const PageMainPanel = ({
  hideFooter,
  className,
  children,
  loading,
  ...props
}: Props) => (
  <div className={classnames('page-main-container', className)} {...props}>
    <NavBarContainer />
    <DivWithLoader className="page-main-container__body" loading={loading}>
      {children}
    </DivWithLoader>
    {!hideFooter && <Footer />}
  </div>
);

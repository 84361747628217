import { GetRowIdParams, ValueFormatterFunc } from 'ag-grid-community';
import { ContractRow } from 'api/endpoints';

import { fromCamelCase } from 'utils/format';
import { formatMoney } from 'utils/formatNumber';
import {
  ACTION_REVIEW_COLUMN,
  MONEY_FIELDS,
  PRIMARY_COLUMN,
  SOURCE_TYPE_COLUMN,
} from '../../const';

export const getGridRowId = (params: GetRowIdParams) => params.data[PRIMARY_COLUMN];

type TContractsFormatter = ValueFormatterFunc<ContractRow>;

export const getValueFormatterFn = (
  propertyName: string,
  dataType: string,
): TContractsFormatter | undefined => {
  if (PRIMARY_COLUMN === propertyName) return formatterPrimaryCol;
  if (MONEY_FIELDS.includes(propertyName) || dataType === 'decimal')
    return formatterCurrency;
  if ([ACTION_REVIEW_COLUMN, SOURCE_TYPE_COLUMN].includes(propertyName))
    return formatterCamelCase;

  return undefined;
};

const formatterPrimaryCol: TContractsFormatter = ({ value }) =>
  value ? `#${value}` : '';

const formatterCurrency: TContractsFormatter = ({ value }) => formatMoney(value);

const formatterCamelCase: TContractsFormatter = ({ value }) => fromCamelCase(value);

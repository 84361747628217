import { del, get, post } from '../apiRequest';
import { ClaimRow } from './claims';

export interface Option {
  id: number;
  name: string;
}

export interface StructureHeaderOption {
  id: number;
  name: string;
  headerOptionId: number;
}

export interface StructureFooterOption {
  id: number;
  name: string;
  footerOptionId: number;
}

export interface Claim {
  id: number;
  exporterId: number;
  claimId: number;
  claimReferenceNumber: string;
  counterPartyName: string;
  proposedDebtor: string;
  currentTotal: number;
}

export interface ClaimGlobalFootnote {
  id: number;
  footnote: string;
  footnoteAppearOptionId: number;
}

export interface ClaimReferencingFootnote {
  id: number;
  claimReferenceNumber: string;
  footnote: string;
  footnoteAppearOptionId: number;
}

export interface Exhibit {
  exhibitNumber: string;
  objectionBasis: string;
  actionNameId: number;
  exportDocument: string;
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string | null;
  updatedDate: string | null;
  actionName: {
    id: number;
    actionName: string;
    actionExhibitTypeId: number;
    actionExhibitTypeName: string;
    actionSubStatusId: number;
    actionSubStatusName: string;
    hearingDate: string;
  };
  claims: Claim[];
  structureHeaders: StructureHeaderOption[];
  structureFooters: StructureFooterOption[];
  globalFootnotes: ClaimGlobalFootnote[];
  referencingClaimFootnotes: ClaimReferencingFootnote[];
}

export const exhibits = {
  get: (id: number) => get<Exhibit>(`v1/ExhibitExporter/getExhibitExporter/${id}`),
  delete: (id: number) => del(`v1/ExhibitExporter/deleteExhibitExporter/${id}`),
  create: (exhibitData: {}) =>
    post('v1/ExhibitExporter/createExhibitExporter', exhibitData),

  getAll: () => get<Array<Exhibit>>('v1/ExhibitExporter/getAllExhibitExporters'),
  getChildClaims: (id: number) =>
    get<ClaimRow[]>(`v1/ExhibitExporter/getChildClaims/${id}`),

  // *** redundunt APIs ********
  // getHeaderOptions: (): Promise<Option[] | undefined> =>
  //   get<Option[]>('v1/ExhibitExporter/getExhibitExporterHeaderOptions'),
  // getFooterOptions: (): Promise<Option[] | undefined> =>
  //   get<Option[]>('v1/ExhibitExporter/getExhibitExporterFooterOptions'),
  // getFootnoteAppearOptions: (): Promise<Option[] | undefined> =>
  //   get<Option[]>('v1/ExhibitExporter/getExhibitExporterFootnoteAppearOptions'),
};

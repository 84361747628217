import React, { useContext, useMemo } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { EntityInfo, EntityTableProps, IdEntity } from './EntityTable.types';

const EntityContext = React.createContext<any | null>(null);

const blankOnFilter = async () => {};
const BlankFilterComponent = () => {
  return null;
};

export const EntityContextProvider = <T extends IdEntity>(
  props: Omit<EntityTableProps<T>, 'children' | 'className'> & {
    children?: React.ReactNode;
  },
) => {
  const { children, ...rest } = props;

  const contextValue: EntityInfo<T> = {
    ...rest,
    status: rest.status ?? FetchStatus.Idle,
    onFilter: rest.onFilter ?? blankOnFilter,
    addColumnKeys: rest.addColumnKeys ?? [],
    filterColumnKeys: rest.filterColumnKeys ?? [],
    filterComponent: rest.filterComponent ?? BlankFilterComponent,
  };

  return (
    <EntityContext.Provider value={contextValue}>
      {contextValue && children}
    </EntityContext.Provider>
  );
};

export const useEntityContext = <T extends IdEntity>(): EntityInfo<T> => {
  const value: EntityInfo<T> = useContext(EntityContext);
  return value;
};

export const useGridTemplateColumns = (size: number) => {
  const styles: React.CSSProperties = useMemo(() => {
    if (!size) {
      return {};
    }
    return {
      '--default-edit-table-grid-template-columns': `${Array.from({ length: size })
        .map((_) => `1fr`)
        .join(' ')} minmax(8rem, auto)`,
    } as React.CSSProperties;
  }, [size]);

  return styles;
};

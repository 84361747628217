import _ from 'lodash';
import isNil from 'lodash/isNil';

// example:
//  formatCurrency(5)    // =>  $5.00
//  formatCurrency(8.01) // =>  $8.01
export const formatMoney = (value?: number): string => {
  return formatMoneyUSD(value);
};

// example:
//  formatCurrency(5)    // =>  $5
//  formatCurrency(8.01) // =>  $8
export const formatMoneyShort = (value?: number): string => {
  return formatMoneyUSD(value, { hideDecimals: true });
};

export const asNumber = (value?: number | string) => {
  if (_.isNil(value)) return undefined;

  const result = +value;
  return isFinite(result) ? result : undefined;
};

// ------------- internal functions --------------------------------------------------

const formatterUSCurrency_0 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatterUSCurrency_2 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const formatMoneyUSD = (
  value?: number,
  options?: { hideDecimals?: boolean },
): string => {
  if (isNil(value) || !isFinite(value)) return '';

  //fix negative zero
  const fixedValue = Object.is(value, -0) ? 0 : value;

  const formatter = options?.hideDecimals
    ? formatterUSCurrency_0
    : formatterUSCurrency_2;

  return formatter.format(fixedValue);
};

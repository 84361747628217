import { AgGridReact } from 'ag-grid-react';

export const getGridState = (grid: AgGridReact<any>) => {
  const colState = grid.columnApi?.getColumnState();
  const groupState = grid.columnApi?.getColumnGroupState();

  // get scroll position in ag-grid
  const firstDisplayedRow = grid.api?.getFirstDisplayedRow();
  const filterState = grid.api?.getFilterModel();
  return {
    colState,
    groupState,
    filterState,
    firstDisplayedRow,
  };
};

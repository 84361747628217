import { api } from 'api';
import {
  SettlementDataPut,
  SettlementId,
  settlements,
  SingleSettlementResponse,
} from 'api/endpoints/settlements';
import {
  SettlementAssignedUser,
  SettlementUser,
  SettlementUserRole,
  settlementUsers,
} from 'api/endpoints/settlementUsers';
import { OptionItem } from 'components/ApNiceSelect';
import _, { toInteger } from 'lodash';
import { downloadBlob } from 'utils/file';
import { isNotNil } from 'utils/lodashEx';

export type UserList = {
  counterparty: OptionItem[];
  client: OptionItem[];
};

export const emptyUserList: UserList = {
  counterparty: [],
  client: [],
};

export type SingleSettlement = SingleSettlementResponse & {
  regenerate: boolean;
  saveComment: string;
  counterpartyId: string | undefined;
  clientId: string | undefined;
};

export const apiSettlementSingle = {
  getUsersList: async () => {
    const allUsers = (await settlementUsers.getAll()) ?? [];
    return {
      counterparty: mapUserToOptions(allUsers, 'Counterparty'),
      client: mapUserToOptions(allUsers, 'Client'),
    };
  },

  getData: async (id: SettlementId) => {
    const [item, users] = await Promise.all([
      settlements.get(id),
      settlementUsers.getAssigned(id),
    ]);

    const result = mapResponseToSingleSettlement(item, users);
    return result;
  },

  update: async (data: SingleSettlement | undefined) => {
    if (!data) return false;

    const postData = mapSingleSettlementToPut(data);
    const result = await settlements.put(data.id, postData, data.regenerate);
    const isSuccess = isNotNil(result);
    return isSuccess;
  },

  agreemantDownload: async (id: SettlementId | undefined) => {
    if (!id) return undefined;

    const result = await api.settlements.agreemantDownload(id);
    return result.type === 'ok' ? result.data : undefined;
  },

  agreemantDownloadFile: async (
    id: SettlementId | undefined,
    fileName: string | null | undefined,
  ) => {
    const blob = await apiSettlementSingle.agreemantDownload(id);
    if (!blob) return;

    const agrreemantFileName = fileName ?? 'agreement.pdf';
    downloadBlob(blob, agrreemantFileName);
  },
};

// --------------------------- mappers -----------------------------------
const mapUserToOptions = (
  users: SettlementUser[],
  userRole: SettlementUserRole,
): OptionItem[] => {
  const filteredUsers = users.filter((u) => u.userRole === userRole);
  const result: OptionItem[] = filteredUsers.map((r) => ({
    label: r.name,
    value: r.id.toString(),
  }));
  return result;
};

const mapSingleSettlementToPut = (data: SingleSettlement) => {
  const settlemntData = _.pick(data, [
    'id',
    'counterpartyName',
    'counterpartyEmail',
    'counterpartyPhone',
    'counterpartyCountry',
    'counterpartyState',
    'counterpartyCity',
    'counterpartyZip',
    'counterpartyAddress',
    'status',
    'notes',
  ]);

  const settlementUsersAssignment = [data.counterpartyId, data.clientId]
    .filter((r) => r)
    .map(toInteger);

  const result: SettlementDataPut = {
    ...settlemntData,
    settlementUsersAssignment,
  };

  return result;
};
const mapResponseToSingleSettlement = (
  item: SingleSettlementResponse | undefined,
  users: SettlementAssignedUser[] | undefined,
) => {
  if (!item) {
    return undefined;
  }

  const result: SingleSettlement = {
    ...item,
    regenerate: false,
    saveComment: '',
    clientId: findUserId(users, 'Client'),
    counterpartyId: findUserId(users, 'Counterparty'),
  };

  return result;
};

const findUserId = (
  users: SettlementAssignedUser[] | undefined,
  userRole: SettlementUserRole,
) => {
  const result = users?.find((u) => u.role === userRole);
  return result ? `${result.id}` : undefined;
};

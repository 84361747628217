import {
  ActionStatus,
  ClaimEditValues,
  LabeledClaimEditChangeOptionsMarkFlag,
} from 'api/endpoints';
import { ApplicationUser, USER_ROLE } from 'utils/AppInitializer/application-user';
import { ActionStatusLabel } from '../../api/endpoints/actionStatus';

type ComputeParams = {
  claim: Pick<ClaimEditValues, 'actionStatus'> | undefined;
  applicationUser: ApplicationUser | null;
  labels: ActionStatusLabel[];
  actionTypeId?: number;
};

/**
 * Returns Save checkboxes depending on the claim state and BE labels
 * @param claim
 * @param applicationUser
 * @param labels
 * @returns
 */

export function computeChangeOptionsMarkFlags({
  claim,
  applicationUser,
  labels,
  actionTypeId,
}: ComputeParams): LabeledClaimEditChangeOptionsMarkFlag[] {
  const isClient =
    applicationUser?.roles.includes(USER_ROLE.ClientApprover) ||
    applicationUser?.roles.includes(USER_ROLE.ClientProposer);
  if (!claim || !applicationUser) return [];

  const flags: LabeledClaimEditChangeOptionsMarkFlag[] = [];
  const label = labels.find((l) => l.actionStatus === claim.actionStatus);
  if (!label) return [];
  if (isClient && claim.actionStatus === ActionStatus.Assigned && !!actionTypeId) {
    return [
      {
        label: label.transactionApproverLabel,
        changeOptionsMarkFlag: label.transactionApproverFlag,
      },
    ];
  }

  if (applicationUser.roles.includes(USER_ROLE.TransactionProposer)) {
    if (label.transactionProposerFlag && label.transactionProposerLabel) {
      flags.push({
        label: label.transactionProposerLabel,
        changeOptionsMarkFlag: label.transactionProposerFlag,
      });
    }
  }

  if (applicationUser.roles.includes(USER_ROLE.TransactionApprover)) {
    if (label.transactionApproverLabel && label.transactionApproverFlag) {
      flags.push({
        label: label.transactionApproverLabel,
        changeOptionsMarkFlag: label.transactionApproverFlag,
      });
    }
  }

  return flags;
}

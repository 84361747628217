import { ApButtonSecondary } from '@alixpartners/ui-components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useDashboardContextData } from 'pages/Dashboard/context/DashboardContextProvider';
import {
  CLAIMS_DETAILED_VIEW_NAV_BTNS,
  CONTRACTS_DETAILED_VIEW_NAV_BTNS,
} from 'pages/Dashboard/utils/dashboardTasksConfig';

export const DetailedViewNavButtons = ({
  sectionType,
}: {
  sectionType: SectionType;
}) => {
  const { contractActiveSection, handleTaskClick, claimsActiveSection } =
    useDashboardContextData();

  const buttons =
    sectionType === SectionType.Contracts
      ? [...CONTRACTS_DETAILED_VIEW_NAV_BTNS]
      : [...CLAIMS_DETAILED_VIEW_NAV_BTNS];
  return (
    <div className="dashboard-detailed__nav-buttons">
      <ApButtonSecondary
        onClick={() =>
          handleTaskClick?.({ section: undefined, isBackToTop: true, sectionType })
        }
      >
        Back to top
      </ApButtonSecondary>
      <ul>
        {buttons.map((button, idx) => (
          <li key={button.value}>
            <ApButtonSecondary
              onClick={() =>
                handleTaskClick?.({ section: button.value, sectionType })
              }
              disabled={
                button.value === contractActiveSection ||
                button.value === claimsActiveSection
              }
            >
              {button.label}
            </ApButtonSecondary>
            {idx + 1 < buttons.length && <span className="separator"></span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

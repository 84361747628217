import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { acquireUserThunk } from 'reducer/thunks/applicationUserThunks';
import { FetchStatus } from 'types/fetch-status.types';

export function useApplicationUser() {
  const userDataState = useAppSelector((s) => s.applicationUser.slice);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userDataState.status === FetchStatus.Idle) {
      dispatch(acquireUserThunk());
    }
  }, [dispatch, userDataState.status]);

  return userDataState;
}

import _ from 'lodash';
import {
  APROVAL_STATUSES_APUSER,
  APROVAL_STATUSES_CLIENT,
} from 'pages/SingleContractPage/sections/SingleContractOverview/components/CategorySelect/config';
import { IBatchEditableColumns } from './contractsBatchEdit.type';
import { DEFAULT_ADDRESS_FIELDS } from '../../ClaimsTable/ClaimsBatchEdit/claimsBatchEdit.const';

//constants for propeties is used in the code to find proper item
export enum colProp {
  contractCategoryId = 'contractCategoryId',
  contractTypeId = 'contractTypeId',
  contractStatusId = 'contractStatusId',
  contractActionNameId = 'contractActionNameId',
  contractExhibitTypeId = 'contractExhibitTypeId',
  contractActionTypeId = 'contractActionTypeId',
  contractActionSubStatusId = 'contractActionSubStatusId',
  paymentPositionId = 'paymentPositionId',
  contractReviewer = 'contractReviewer',
  vendorNumber = 'vendorNumber',
  debtorId = 'proposedDebtorId',
  clinetApprovalStatus = 'clientApprovalStatusId',
  clinetApprovalStatusDate = 'clientApprovalStatusDate',
  apuserApprovalStatus = 'aPApprovalStatusId',
  apuserApprovalStatusDate = 'aPApprovalDate',
  matchCode = 'matchCode',
  counterpartyName = 'counterpartyName',
}

const EDITABLE_COLUMNS: IBatchEditableColumns[] = [
  {
    propertyName: colProp.contractTypeId,
    displayName: 'Contract Category / Type',
    dataType: 'contractCategoryType',
    allowedValues: undefined, //will be filled with data from the API
  },
  /* 
  we use the same dataType for these 3 fields: WorkflowStatus, Res. Type & Res. Subtype
  all 3 will use <ContractResolutionType /> component
   */
  {
    propertyName: colProp.contractActionSubStatusId,
    displayName: 'Resolution Subtype',
    dataType: 'contractResolutionItems',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.contractActionTypeId,
    displayName: 'Resolution Type',
    dataType: 'contractResolutionItems',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.contractStatusId,
    displayName: 'Workflow Status',
    dataType: 'contractResolutionItems',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.contractActionNameId,
    displayName: 'Resolution Name',
    dataType: 'contractResolutionName',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.counterpartyName,
    displayName: 'Counterparty Name',
    dataType: 'string',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.paymentPositionId,
    displayName: 'Payment Position',
    dataType: 'enum',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: colProp.vendorNumber,
    displayName: 'Vendor number',
    dataType: 'string',
  },
  {
    propertyName: colProp.contractReviewer,
    displayName: 'Contract reviewer',
    dataType: 'group-multi-select',
  },
  {
    propertyName: 'companyCode',
    displayName: 'Company Code',
    dataType: 'int',
  },
  {
    propertyName: colProp.debtorId,
    displayName: 'Debtor',
    dataType: 'enum',
    allowedValues: undefined, //will be filled with data from the API
  },
  {
    propertyName: 'comment',
    displayName: 'Comment',
    dataType: 'string',
  },
  {
    propertyName: 'notes',
    displayName: 'Notes',
    dataType: 'string',
  },
  {
    propertyName: colProp.clinetApprovalStatus,
    displayName: 'Client Approval Status',
    dataType: 'enum',
    allowedValues: APROVAL_STATUSES_CLIENT,
  },
  {
    propertyName: colProp.clinetApprovalStatusDate,
    displayName: 'Client Approval Date',
    dataType: 'date',
  },
  {
    propertyName: colProp.apuserApprovalStatus,
    displayName: 'AlixPartners Approval Status',
    dataType: 'enum',
    allowedValues: APROVAL_STATUSES_APUSER,
  },
  {
    propertyName: colProp.apuserApprovalStatusDate,
    displayName: 'AlixPartners Approval Date',
    dataType: 'date',
  },
  ...(DEFAULT_ADDRESS_FIELDS as IBatchEditableColumns[]),
];

export const EDITABLE_COLUMNS_SORTED = _.sortBy(
  EDITABLE_COLUMNS,
  (r) => r.displayName,
);

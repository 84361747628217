import { projectDetailsApi, ProjectDetails } from 'api/endpoints/projectDetails';

import { selectableUpdated } from '../selectableDataReducer';
import { AppDispatch, GetState } from '../store';

export const saveProjectDetailsThunk =
  (editedDetails: ProjectDetails) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const result = await projectDetailsApi.saveProjectDetails({
      projectName: editedDetails.projectName,
      primaryCaseNumber: editedDetails.primaryCaseNumber,
    });
    dispatch(selectableUpdated({ projectDetails: { ...editedDetails } }));

    return result;
  };

export const saveMatchingToggle =
  (toggle: boolean) => async (dispatch: AppDispatch, _getState: GetState) => {
    await projectDetailsApi.setMatchingToggle(toggle);
    dispatch(selectableUpdated({ useMatching: toggle }));
  };

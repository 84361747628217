import { ApCheckbox } from '@alixpartners/ui-components';
import { fileInfo } from 'api/endpoints';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { SofaModal } from 'pages/SOFAsAndSchedules/Components';
import { useCallback, useState } from 'react';
import { error } from 'utils/alert';
import './ModalDownloadTemplate.css';

const templatesList = [
  'Sofa.xlsx',
  'Sofa-v2.xlsx',
  'Schedules-AB.xlsx',
  'Schedules-D.xlsx',
  'Schedules-EF.xlsx',
  'Schedules-G.xlsx',
  'Schedules-H.xlsx',
];

interface Props {
  onClose: () => void;
}

export const ModalDownloadTemplate = ({ onClose }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    await downloadTemplates(selected);
    setLoading(false);
    onClose();
  }, [onClose, selected]);

  const handleCheckboxChange = useCallback((itemId) => {
    setSelected((prev) =>
      prev.includes(itemId) ? prev.filter((r) => r !== itemId) : [...prev, itemId],
    );
  }, []);

  return (
    <SofaModal
      header="Choose the data templates you want to download"
      hasClosed={onClose}
      btnConfirm={{
        name: 'Download templates',
        iconName: 'outline_save_alt',
        disabled: loading,
        onClick: handleConfirm,
      }}
    >
      <DivWithLoader className="sofa-modal-download-template" loading={loading}>
        {templatesList.map((template) => (
          <div key={template} className="sofa-modal-download-template__checkbox">
            <ApCheckbox
              id={`sofa-download-template_${template}`}
              type="checkbox"
              checked={selected.includes(template)}
              onChange={() => handleCheckboxChange(template)}
            >
              {template}
            </ApCheckbox>
          </div>
        ))}
      </DivWithLoader>
    </SofaModal>
  );
};

async function downloadTemplates(files: string[]) {
  try {
    const promises = files.map((file) => fileInfo.downloadTemplate(file));
    await Promise.all(promises);
  } catch (e: any) {
    error('Error Downloading Files');
    console.error(e);
  }
}

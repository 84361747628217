import { ColDef } from 'ag-grid-community';
import get from 'lodash/get';
import { PLACEHOLDERS, GENERIC_PLACEHOLDERS } from '../const';

function setPlaceholders(columnDefs: ColDef[]) {
  Array.from(document.getElementsByClassName('ag-text-field-input'))?.forEach(
    (obj) => {
      if (obj.getAttribute('disabled')) return;
      // AG Grid doesn't have a specific way of settings a placeholder
      // so we need to figure out the aria-colindex of parent element
      // then find corresponding placeholder
      const element = get(
        obj,
        'parentNode.parentNode.parentNode.parentNode.parentNode',
      );
      const columnIndex =
        Number((element as Element).getAttribute('aria-colindex')) - 1;
      const columnDef = columnDefs[columnIndex];
      const fieldName = (columnDef && columnDef.field) || '';

      if (PLACEHOLDERS[fieldName])
        obj.setAttribute('placeholder', PLACEHOLDERS[fieldName]);
      else if (
        columnDef &&
        columnDef.filter &&
        GENERIC_PLACEHOLDERS[columnDef.filter]
      ) {
        obj.setAttribute('placeholder', GENERIC_PLACEHOLDERS[columnDef.filter]);
      }
    },
  );
}

export default setPlaceholders;

import './Dashboard.css';
import classNames from 'classnames';
import { useUserDashboardTasks } from 'hooks/useUserDashboardTasks';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppInitialDataContext } from 'utils/AppInitializer';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { DashboardApUsers } from './sections/DashboardApUsers/DasboardApUsers';
import { UserInfoPanel } from './UserInfoPanel';
import { DashboardClients } from './sections/DashboardClientUsers/DashboardClients';

export type DashboardProps = {
  className?: string;
};

export function Dashboard(props: DashboardProps) {
  const { className } = props;
  const user = useApplicationUser();
  const initial = useAppInitialDataContext();
  const dashboardTasks = useUserDashboardTasks(user?.data);
  const { isClient, isApUser } = useAppUserType();

  return (
    <>
      <section
        className={classNames(className, 'dashboard', {
          'dashboard-tasks-done': dashboardTasks.status === FetchStatus.Done,
          'dashboard-user-done': user.status === FetchStatus.Done,
        })}
      >
        <div className={classNames('dashboard__column', { apDashboard: isApUser })}>
          <UserInfoPanel
            className="dashboard__userinfo"
            userInfo={user.data}
            clientInfo={initial.data?.basicInfo?.clientInfo}
            isClient={isClient}
          />

          {isClient ? <DashboardClients /> : <DashboardApUsers />}
        </div>
      </section>
    </>
  );
}

import { delQuiet, get, postQuiet, putQuiet } from 'api/apiRequest';
import { BookmarkCardData } from 'pages/BookmarksPage/types';

type BookmarkDataEdit = {
  title: string;
  id: number;
};

type PostBookmarkCardData = Omit<
  BookmarkCardData,
  'id' | 'createdBy' | 'createdDate' | 'createdByEmail'
>;

export const bookmarksApi = {
  getBookmarks: () => get<BookmarkCardData[]>('v1/Bookmarks'),
  saveBookmark: (data: PostBookmarkCardData) => postQuiet('v1/Bookmarks', data),
  editBookmark: (data: BookmarkDataEdit) => putQuiet('v1/Bookmarks', data),
  deleteBookmark: (id: number) => delQuiet(`v1/Bookmarks/${id}`),
};

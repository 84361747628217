import Excel from 'exceljs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  reports,
  ClaimsResolutionByCategories,
  ClaimResolutionCategory,
  ClaimResolutionSection,
} from 'api/endpoints';
import { loadTemplate } from 'pages/ExhibitExporterPage/templates/utils/exporterUtils';
import isEmpty from 'lodash/isEmpty';

// needed to show 3rd, 1st August
dayjs.extend(advancedFormat);

export interface ClaimsByCategoryParams {
  legalEntityId?: number;
  legalEntityName?: string;
}
export interface ClaimsByCategoryExcelData {
  headerFooter: never[];
  reportData?: {
    majorClaimCategories: string[];
    sectionHeaders: string[];
    data: ClaimsResolutionByCategories;
  };
}

const subheaderCells: {
  key: keyof ClaimResolutionCategory;
  value: string;
}[] = [
  {
    key: 'claimCount',
    value: 'Count',
  },
  {
    key: 'assertedTotal',
    value: 'Asserted Total',
  },
  {
    key: 'currentTotal',
    value: 'Current Total',
  },
  {
    key: 'proposedTotal',
    value: 'Proposed Total',
  },
];

export const ClaimsByCategory = {
  fileName: 'ClaimsByCategory',
  getWorkbook: async (params: ClaimsByCategoryParams) => {
    const excelData = await getDataToShow(params);
    return await createWorkbook(excelData, params);
  },
};

async function createWorkbook(
  excelData: ClaimsByCategoryExcelData,
  params: ClaimsByCategoryParams,
): Promise<Excel.Workbook | undefined> {
  const templateData = await loadTemplate('ClaimsByCategory.xlsx', 'reports');

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(templateData);

  if (!excelData.reportData) {
    return;
  }
  const firstSheet = workbook.worksheets[0];

  renderHeader(firstSheet, params);

  // render all major categories labels
  // render total label
  renderCategories(firstSheet, excelData.reportData.majorClaimCategories);

  // render headers
  renderHeaders(
    firstSheet,
    excelData.reportData.sectionHeaders,
    excelData.reportData.majorClaimCategories.length,
  );

  // render section by section
  renderSections(firstSheet, excelData.reportData.data);

  return workbook;
}

async function renderHeader(sheet: Excel.Worksheet, params: ClaimsByCategoryParams) {
  const headerStart = [1, 1];

  sheet.getCell(
    headerStart[0],
    headerStart[1],
  ).value = `Debtor [${params.legalEntityName}]`;
  sheet.getCell(
    headerStart[0] + 2,
    headerStart[1],
  ).value = `Date [As of ${dayjs().format('MMMM Do, YYYY')}]`;
}

async function renderHeaders(
  sheet: Excel.Worksheet,
  sectionHeaders: string[],
  additionalCategoriesLength: number,
) {
  const headerStart = [7, 3];
  const subheaderStart = [8, 3];

  const subheaderCellObjects = subheaderCells.map((c, index) => ({
    ...c,
    cell: sheet.getCell(subheaderStart[0], subheaderStart[1] + index),
    amountCell: sheet.getCell(subheaderStart[0] + 1, subheaderStart[1] + index),
    totalCell: sheet.getCell(
      subheaderStart[0] + additionalCategoriesLength + 2,
      subheaderStart[1] + index,
    ),
  }));

  // set section header values and merge cells
  // (each section header has 4 columns)
  sheet.getCell(headerStart[0], headerStart[1]).value = sectionHeaders[0];
  for (let index = 1; index < sectionHeaders.length; index += 1) {
    const header = sectionHeaders[index];
    const newPosition = headerStart[1] + index * 4;

    // first we set value, then we set style and only after
    // that we merge cells. If we change order, the style will be partially lost
    // because the library is not doing the style application correctly
    sheet.getCell(headerStart[0], newPosition).value = header;
    sheet.getCell(headerStart[0], newPosition).style = sheet.getCell(
      headerStart[0],
      headerStart[1],
    ).style;
    sheet.mergeCells(headerStart[0], newPosition, headerStart[0], newPosition + 3);

    subheaderCellObjects.forEach((subheaderCell, subheaderIndex) => {
      // copy subheader cell value
      sheet.getCell(subheaderStart[0], newPosition + subheaderIndex).value =
        subheaderCell.cell.value;

      // copy subheader cell style
      sheet.getCell(subheaderStart[0], newPosition + subheaderIndex).style =
        subheaderCell.cell.style;

      // copy amount cell style
      sheet.getCell(subheaderStart[0] + 1, newPosition + subheaderIndex).style =
        subheaderCell.amountCell.style;

      for (let i = 0; i < additionalCategoriesLength; i++) {
        sheet.getCell(
          subheaderStart[0] + 2 + i,
          newPosition + subheaderIndex,
        ).style = subheaderCell.amountCell.style;
      }

      // copy total cell style
      sheet.getCell(
        subheaderStart[0] + additionalCategoriesLength + 2,
        newPosition + subheaderIndex,
      ).style = subheaderCell.totalCell.style;
    });
  }
}

async function renderCategories(
  sheet: Excel.Worksheet,
  majorClaimCategories: string[],
) {
  // start category rendering at row 9 column 2
  // workbook
  const categoryStart = [9, 2];

  sheet.getCell(categoryStart[0], categoryStart[1]).value = majorClaimCategories[0];

  for (let i = 1; i < majorClaimCategories.length; i++) {
    const category = majorClaimCategories[i];
    sheet.insertRow(categoryStart[0] + i, ['', category]);
    sheet.getCell(categoryStart[0] + i, categoryStart[1]).style = sheet.getCell(
      categoryStart[0],
      categoryStart[1],
    ).style;

    subheaderCells.forEach((_, index) => {
      const cell = sheet.getCell(categoryStart[0] + i, categoryStart[1] + index + 1);
      const originalCell = sheet.getCell(
        categoryStart[0],
        categoryStart[1] + index + 1,
      );
      cell.style = originalCell.style;

      cell.value = originalCell.value;
    });
  }
}

async function renderSections(
  sheet: Excel.Worksheet,
  data: ClaimsResolutionByCategories,
) {
  const dataStart = [9, 3];

  const hasAtLeastOneVisibleSection = data.some((section) => section.defaultVisible);

  data.forEach((section, index) => {
    const newPosition = [dataStart[0], dataStart[1] + index * subheaderCells.length];

    if (hasAtLeastOneVisibleSection) {
      const columnStart = newPosition[1];
      const columnEnd = columnStart + subheaderCells.length - 1;

      for (let columnIndex = columnStart; columnIndex <= columnEnd; columnIndex++) {
        setColumnHidden(sheet, columnIndex, section.defaultVisible);
      }
    }

    renderSection(sheet, section, newPosition);
  });
}

function setColumnHidden(
  sheet: Excel.Worksheet,
  cellCol: number,
  defaultVisible: boolean,
) {
  const column = sheet.getColumn(cellCol);
  if (!isEmpty(column)) {
    column.hidden = !defaultVisible;
  }
}

async function renderSection(
  sheet: Excel.Worksheet,
  section: ClaimResolutionSection,
  position: number[],
) {
  subheaderCells.forEach((subheaderCell, index) => {
    section.values.forEach((value, valueIndex) => {
      const rowIndex = position[0] + valueIndex;
      const columnIndex = position[1] + index;

      const cell = sheet.getCell(rowIndex, columnIndex);
      cell.value = value[subheaderCell.key];
    });
  });
}

async function getDataToShow(params: ClaimsByCategoryParams) {
  return {
    headerFooter: [],
    reportData: await getPreparedCategoryReportData(params.legalEntityId),
  };
}

const getPreparedCategoryReportData = async (legalEntityId?: number) => {
  const response = await reports.getClaimsResolutionByCategory(legalEntityId);

  if (!response || !response.length) return undefined;

  const majorClaimCategories = response[0].values
    .filter((item) => !item.isTotalRow)
    .map((item) => item.majorCategoryName);
  const sectionHeaders = response.map((item) => item.sectionName);

  return {
    majorClaimCategories,
    sectionHeaders,
    data: response,
  };
};

import { showHttpError } from 'api/apiRequest/httpErrorAlert';
import { BlobStorageCredentials } from 'api/endpoints';
import _ from 'lodash';
import { createArray } from 'utils/array';
import AzureStorageClient from 'utils/azureStorageClient';
import { azureStorageUpload, AzureUploadResult } from './azureStorageUpload';

export interface FileEntity {
  file: File;
}

export type UploadResult<T> = AzureUploadResult & {
  entity: T;
};

const MIN_UPLOAD_PROGRESS = 0.05;
const MAX_UPLOAD_PROGRESS = 0.9;

export async function azureFileBatchUploader<T extends FileEntity>(
  fileList: T[],
  options: {
    folder: string;
    showErrorMessage?: boolean;
    getCredentials: () => Promise<BlobStorageCredentials | any>;
    uploaderProgress?: (value: number) => void;
  },
) {
  const { folder, showErrorMessage, getCredentials, uploaderProgress } = options;

  uploaderProgress?.(MIN_UPLOAD_PROGRESS);

  let totalProgress = createArray(fileList.length, 0);
  const azureClient = new AzureStorageClient({ getCredentials });

  const uploadPromises = fileList.map(async (entity, id) => {
    const file = entity.file;

    const result = await azureStorageUpload(azureClient, file, folder, (loaded) => {
      totalProgress = [...totalProgress];
      totalProgress[id] = loaded;
      uploaderProgress?.(calcAvgProgress(totalProgress));
    });

    return { ...result, entity } as UploadResult<T>;
  });

  const results = await Promise.all(uploadPromises);

  uploaderProgress?.(MAX_UPLOAD_PROGRESS);

  const succes = results.filter((u) => u.type === 'ok');
  const failed = results.filter((u) => u.type === 'fail');

  if (failed.length > 0 && showErrorMessage) {
    const messages = failed.map((r) => `File upload fail: ${r.entity.file.name}`);
    showHttpError({ title: 'Upload failed', messages });
  }

  return { succes, failed };
}

const calcAvgProgress = (items: number[]) => {
  const avg = _.mean(items);
  return avg > MAX_UPLOAD_PROGRESS ? MAX_UPLOAD_PROGRESS : avg;
};

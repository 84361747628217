import { ApButtonMain, ApButtonSecondary } from '@alixpartners/ui-components';
import cx from 'classnames';
import inputTextClasses from 'components/UIComponents/InputText/InputText.module.css';
import selectInputClasses from 'components/UIComponents/Select/Select.module.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, {
  ActionMeta,
  MultiValue,
  SelectInstance,
  SingleValue,
} from 'react-select';

import { customComponents, MainLabel } from './GroupMultiSelect.components';
import './GroupMultiSelect.css';
import { Dropdown } from './GroupMultiSelect.icons';
import {
  GroupMultiSelectProps,
  InternalGroup,
  InternalOption,
  Option,
} from './GroupMultiSelect.types';

// ------  attention ------------------------------------------
// !!! this component is BUGGY. Try to use differrent one.
// ------------------------------------------------------------

/**
 * Usage with custom `MainLabelComponent`:
 ```
 const CustomMainLabel: MainLabelType = ({ placeholder, selected, className }) => {
      let content = placeholder;
      if (selected.length > 3) {
        content = `${selected.length} Selections`;
      } else if (selected.length > 0) {
        content = selected.map((o) => o.label).join(' | ');
      }
      return <div className={className}>{content}</div>;
    };
 ...
 <GroupMultiSelect
 placeholder="Select..."
 MainLabelComponent={CustomMainLabel}
 {...}
 />
 ```

 */
export const GroupMultiSelect = ({
  label,
  groups,
  onSubmit,
  disabled,
  className,
  placeholder,
  loading,
  mainLabelComponent: MainLabelComponent = MainLabel,
  hasSearch = false,
  hasActions = false,
  submitLabel = 'submit',
  cancelLabel = 'cancel',
  searchPlaceholder = 'search',
}: GroupMultiSelectProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // refs used for focus and scroll
  const scrolalbleAreaRef = useRef<HTMLDivElement | null>(null);
  const reactSelectRef =
    useRef<SelectInstance<InternalOption, boolean, InternalGroup>>(null);
  const toggleRef = useRef<HTMLElement>(null);

  // close dropdown menu with save button click
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleRef]);

  useEffect(() => {
    if (isDropdownOpen && scrolalbleAreaRef?.current)
      scrolalbleAreaRef.current.scrollIntoView({
        block: 'center',
      });
  }, [isDropdownOpen, scrolalbleAreaRef]);

  const internalGroups: InternalGroup[] = useMemo(() => {
    return groups?.map((g) => {
      return {
        label: g.label,
        value: g.value,
        options: g.options.map((o: Option) => ({
          ...o,
          groupInfo: {
            label: g.label,
            value: g.value,
          },
        })),
      };
    });
  }, [groups]);

  const defaultSelected = useMemo(
    () => internalGroups.flatMap((g) => g.options).filter((o) => o.checked),
    [internalGroups],
  );

  const [selected, setSelected] = useState<InternalOption[]>([]);
  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const submitBase = useCallback(
    (selectedOption = null) => {
      const selectedOptions = selectedOption || selected;

      const checkedRecord = selectedOptions.reduce(
        (
          acc: { [x: string]: { [x: string]: any } },
          current: { groupInfo: { value: any }; value: any; checked: any },
        ) => {
          const groupKey = current.groupInfo.value;
          const optionKey = current.value;
          if (!acc[groupKey]) {
            acc[groupKey] = {};
          }
          acc[groupKey][optionKey] = current.checked;

          return acc;
        },
        {} as Record<string, Record<string, boolean | undefined>>,
      );

      const newGroups: InternalGroup[] = internalGroups?.map((g) => ({
        ...g,
        options: g?.options.map((o) => ({
          ...o,
          checked: checkedRecord[g?.value]?.[o.value] ?? false,
        })),
      }));

      onSubmit(selectedOptions, newGroups);
    },
    [selected, internalGroups, onSubmit],
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setSelected(defaultSelected);
    },
    [defaultSelected],
  );

  const handleChange = (
    _: SingleValue<InternalOption> | MultiValue<InternalOption>,
    actionMeta: ActionMeta<InternalOption>,
  ) => {
    const { action, option } = actionMeta;

    let selectedHelper = [];

    if (action === 'select-option') {
      selectedHelper = [...selected, option as InternalOption];
      submitBase(selectedHelper);
      setSelected(selectedHelper);
    } else if (action === 'deselect-option') {
      selectedHelper = selected.filter((o) => o.value !== option?.value);
      submitBase(selectedHelper);
      setSelected(selectedHelper);
    }

    // we don't care about any other actions
    return;
  };

  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      submitBase();
    },
    [submitBase],
  );

  const handleMainLabelClick: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (isDropdownOpen) {
        // transitioning from `true` to `false`
        submitBase();
        return;
      }
      setIsDropdownOpen((previous) => !previous);
      // React Select jumps to the text search input on when selecting for the first time, focus the input beforehand.
      // At the moment of writing, the react-select seems to not expose an API to customize this behaviour.
      setTimeout(() => {
        scrolalbleAreaRef.current?.scroll({ top: 0 });
        reactSelectRef.current?.focus();
      }, 1);
    },
    [isDropdownOpen, submitBase],
  );

  const handleBackdropClick: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      submitBase();
      setIsDropdownOpen(false);
    },
    [setIsDropdownOpen, submitBase],
  );

  return (
    <div className={cx('gms-root', { 'gms-root-disabled': disabled }, className)}>
      {label && <div className={inputTextClasses.label}>{label}</div>}
      <details ref={toggleRef} className="gms-toggle" open={isDropdownOpen}>
        <summary
          className={cx(selectInputClasses.select, 'gms-main-label-wrapper')}
          onClick={handleMainLabelClick}
        >
          <MainLabelComponent
            loading={loading}
            className="gms-main-label"
            selected={selected}
            placeholder={placeholder}
          />
          <Dropdown className="gms-select-icon " />
        </summary>
        <div
          className="gms-options-area"
          style={{ width: toggleRef.current?.clientWidth }}
        >
          <div className="gms-scrollable-area" tabIndex={-1} ref={scrolalbleAreaRef}>
            <ReactSelect<InternalOption, boolean, InternalGroup>
              ref={reactSelectRef}
              tabIndex={1}
              onChange={handleChange}
              classNamePrefix="gms"
              className={cx('gms-react-select', { 'gms-hide-search': !hasSearch })}
              options={internalGroups}
              components={customComponents}
              value={selected}
              placeholder={searchPlaceholder}
              isMulti={true}
              menuIsOpen={true}
              isClearable={false}
              isSearchable={hasSearch}
              openMenuOnFocus={false}
              tabSelectsValue={false}
              captureMenuScroll={false}
              closeMenuOnScroll={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              menuShouldBlockScroll={false}
              controlShouldRenderValue={false}
            />
          </div>
          <div className={cx('gms-btns', { none: !hasActions })}>
            <ApButtonMain
              className="gms-btn gms-submit"
              type="button"
              onClick={handleSubmit}
            >
              {submitLabel}
            </ApButtonMain>
            <ApButtonSecondary
              className="gms-btn gms-cancel"
              type="button"
              onClick={handleCancel}
            >
              {cancelLabel}
            </ApButtonSecondary>
          </div>
        </div>
      </details>
      <div onClick={handleBackdropClick} className="gms-backdrop" />
    </div>
  );
};

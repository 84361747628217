import { get, post, postQuiet } from 'api/apiRequest';

export interface CategoryItem {
  id: number;
  majorCategory: string;
  minorCategory: string;
}

export interface MajorCategories {
  id: number;
  majorCategory: string;
  minorCategories: MinorCategories[];
}

export interface MinorCategories {
  id: number;
  minorCategory: string;
}

export type DropdownCategories = {
  id: number;
  categoryName: string;
};

export type ClaimCategoryItemBase = {
  id: number;
  majorCategoryId: number | null;
  majorCategoryName: string | null;
  minorCategoryName: string;
  minorCategoryId: number;
};

export type AllClaimsCategories = ClaimCategoryItemBase & {
  categoryType: string;
  enabled: boolean;
  usedInClaims: boolean;
};

export type EditClaimCategoryRequest = {
  minorCategoryId: number;
  minorCategoryName: string | null;
  enabled: boolean;
};

export enum CategoryType {
  Baseline = 'Baseline',
  Custom = 'Custom',
}

export type ContractCategoryItemBase = {
  id: number;
  contractCategoryId: number | null;
  categoryName: string | null;
  contractTypeName: string;
  contractTypeId: number;
};

export type AllContractsCategories = ContractCategoryItemBase & {
  categoryType: string;
  enabled: boolean;
  usedInContracts: boolean;
};

export type EditContractCategoryRequest = {
  contractTypeId: number;
  contractTypeName: string | null;
  enabled: boolean;
};

export const claimCategories = {
  get: () => get<CategoryItem[]>('v1/Categories'),
  majorCategories: () => get<MajorCategories[]>('v1/Categories/majorCategories'),
  getDropdownCategories: () =>
    get<DropdownCategories[]>('v1/Categories/dropdownCategories'),
  getAllCategories: () => get<AllClaimsCategories[]>('v1/Categories/allCategories'),
  addNewCategory: (newCategory: ClaimCategoryItemBase) =>
    postQuiet('v1/Categories/add', newCategory),
  editCategory: (category: EditClaimCategoryRequest) =>
    postQuiet('v1/Categories/edit', category),
  setToggleAll: (toggle: boolean) => post(`v1/Categories/toggle/${toggle}`, {}),
};

export const contractCategories = {
  getDropdownCategories: () =>
    get<DropdownCategories[]>('v1/ContractCategories/dropdownCategories'),
  getAllCategories: () =>
    get<AllContractsCategories[]>('v1/ContractCategories/allCategories'),
  addNewCategory: (newCategory: ContractCategoryItemBase) =>
    postQuiet('v1/ContractCategories/add', newCategory),
  editCategory: (category: EditContractCategoryRequest) =>
    postQuiet('v1/ContractCategories/edit', category),
  setToggleAll: (toggle: boolean) =>
    post(`v1/ContractCategories/toggle/${toggle}`, {}),
};

import { api } from 'api';
import { downloadBlobStorageFile } from 'api/apiRequest';
import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import { ExportPdfResponse, exportPdf_InProgress } from 'api/endpoints/exportPdf';
import { partition } from 'lodash';
import { urlExtractFileName } from 'utils/file';

export const apiSofaExport = {
  downloadExportedPdf: async (
    filePath: ExportPdfResponse['filePath'] | undefined,
  ) => {
    if (!filePath) {
      showHttpSingleError({ title: 'System Error: File name is empty' });
      return;
    }

    const token = await api.storage.getExportedPdfContainerSasToken();
    const urlWithToken = `${filePath}${token?.sasToken}`;
    const filename = urlExtractFileName(filePath) || 'file.pdf';
    await downloadBlobStorageFile(urlWithToken, filename);
  },

  getHistoryList: async () => {
    const items = await api.exportPdf.getQuiet();

    const exportItems = items?.data ?? [];
    const [inProgress, completed] = partition(exportItems, (r) =>
      exportPdf_InProgress.includes(r.exportStatus),
    );

    return {
      progress: inProgress.filter((r) => r.exportStatus === 'InProgress'),
      queued: inProgress.filter((r) => r.exportStatus === 'InQueue'),
      completed,
    };
  },

  cancelExport: async (id: number | undefined) => {
    if (!id) return;
    await api.exportPdf.cancel(id);
  },
};

import {
  ErrorBody,
  FetchError,
  JsonFetchResponse,
  JsonParseError,
  OkResponse,
  ResponseType,
  ServerError,
} from './jsonFetch.types';

export async function jsonFetch<T, E extends ErrorBody>(
  input: RequestInfo | URL,
  reqBody?: object,
  init?: RequestInit,
  skipBodyParse?: boolean,
): Promise<JsonFetchResponse<T, E>> {
  try {
    let reqInit: RequestInit = init ?? {};
    if (reqBody) {
      reqInit = {
        ...reqInit,
        body: JSON.stringify(reqBody),
      };
    }
    const response = await fetch(input, reqInit);
    const { status, ok } = response;

    if (status < 100 || status >= 500) {
      return {
        type: ResponseType.SEVER_ERR,
      } as ServerError;
    }

    let body: any;
    try {
      const skipParse = ok ? skipBodyParse : false;
      if (!skipParse) {
        body = await response.json();
      }
    } catch (_e) {
      return {
        type: ResponseType.PARSE_ERR,
      } as JsonParseError;
    }

    if (ok) {
      return {
        type: ResponseType.OK,
        data: body as T,
      } as OkResponse<T>;
    }

    return {
      type: ResponseType.BAD_REQ,
      data: {
        status,
        error: body,
      } as E,
    };
  } catch (_fe) {
    return {
      type: ResponseType.FETCH_ERR,
    } as FetchError;
  }
}

/**
 * Maps the data content of a given server response using the provided function.
 */
export function mapOk<TServer, TApp, E extends ErrorBody = any>(
  response: JsonFetchResponse<TServer, E>,
  mapper: (severData: TServer) => TApp,
): JsonFetchResponse<TApp, E> {
  if (response.type === ResponseType.OK) {
    return {
      type: ResponseType.OK,
      data: mapper(response.data),
    };
  }

  return response;
}

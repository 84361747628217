import { useCallback } from 'react';
import { ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { MatchStatus } from 'api/endpoints';
import classNames from 'classnames';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';

export const MatchingContent = ({
  status,
  onDownload,
  downloading,
}: {
  onDownload: () => Promise<void>;
  status?: MatchStatus;
  downloading?: boolean;
}) => {
  const renderContentByStatus = useCallback(() => {
    switch (status) {
      case MatchStatus.PublishFailed:
        return (
          <div
            className="matching-status publish-failed flex-align-center"
            data-testid="publishFailed"
          >
            <ApIcon iconName="outline_warning" iconColor="#EE3A36" />
            <div className="matching-status__header">
              Errors have been detected in the matching file - see below
              <p>Please fix these errors and re-upload to match the records</p>
            </div>
          </div>
        );
      case MatchStatus.Pending:
      case MatchStatus.Running:
        return (
          <div className="matching-status matching" data-testid="running">
            <div className="matching-status__header flex-align-center">
              <div className="matching-status__spinner">
                <img alt="processing" src="img/datauploader/uploading.gif" />
              </div>
              <div className="matching-status__content ">
                <p>Matching in progress...</p>
                <p>
                  Matching algorithm currently running. The matching suggestion file
                  will be ready for download once this is completed.
                </p>
                <p>
                  In the meantime you can use the previous matching suggestion file
                </p>
              </div>
            </div>
            <div className="matching-status__buttons">
              <ApButtonCasual
                className="matching-status__download"
                onClick={onDownload}
                disabled={downloading}
              >
                {downloading ? (
                  <>
                    <ApLoaderDefault
                      loaderSize="sm"
                      className="matching-status__download-icon"
                    />
                    Downloading match suggestions file
                  </>
                ) : (
                  <>
                    <ApIcon iconName="save_alt" />
                    Download match suggestions file
                  </>
                )}
              </ApButtonCasual>
            </div>
          </div>
        );
      case MatchStatus.Failed:
        return (
          <div className="matching-status matching-error" data-testid="failed">
            <div
              className={classNames(
                'matching-status__header',
                'matching-status__error',
                'flex-align-center',
              )}
            >
              <ApIcon iconName="error" iconColor="#D97706" iconSize={40} />
              <p>
                Matching process has failed, please contact your administrator.
                <span>
                  You can still download the previous matching suggestions file
                </span>
              </p>
            </div>

            <div className="matching-status__buttons">
              <ApButtonCasual
                className="matching-status__download"
                onClick={onDownload}
                disabled={downloading}
              >
                {downloading ? (
                  <>
                    <ApLoaderDefault
                      loaderSize="sm"
                      className="matching-status__download-icon"
                    />
                    Downloading match suggestions file
                  </>
                ) : (
                  <>
                    <ApIcon iconName="save_alt" />
                    Download match suggestions file
                  </>
                )}
              </ApButtonCasual>
            </div>
          </div>
        );
      default:
        return (
          <div className="matching-status" data-testid="completed">
            <div className="matching-status__header">
              Match suggestions are now available
            </div>
            <div className="matching-status__content">
              <p>
                All uploaded items have been published and the matching algorithm
                process is now completed. To review all matching suggestions please
                download the match data Excel file. Once your review and edits are
                complete please use the "Upload data for matching" feature below to
                upload your revised file.
              </p>
            </div>
            <div className="matching-status__buttons">
              <ApButtonCasual
                className="matching-status__download"
                onClick={onDownload}
                disabled={downloading}
              >
                {downloading ? (
                  <>
                    <ApLoaderDefault
                      loaderSize="sm"
                      className="matching-status__download-icon"
                    />
                    Downloading match suggestions file
                  </>
                ) : (
                  <>
                    <ApIcon iconName="save_alt" />
                    Download match suggestions file
                  </>
                )}
              </ApButtonCasual>
            </div>
          </div>
        );
    }
  }, [downloading, onDownload, status]);

  return <>{renderContentByStatus()}</>;
};

import { VendorGraph } from 'pages/VendorsPage/components/VendorGraph/VendorGraph';
import './VendorsDashboard.css';
import { ApplicationUserSlice } from 'reducer/applicationUserReducer';
import { useMemo } from 'react';

export const VendorsDashboard = ({ user }: { user: ApplicationUserSlice }) => {
  const name = useMemo(
    () =>
      user.data?.given_name || user.data?.family_name
        ? `, ${user.data?.given_name} ${user.data?.family_name}`
        : '',
    [user],
  );

  return (
    <div className="vendors-tool__dashboard">
      <h5 className="dashboard__userinfo__title flex-align-center">
        <b>Hello{name}</b>
        <span>Welcome to the Vendor management module</span>
      </h5>
      <p>This is a nice subheader text to be shown on the Vendors Dashboard page</p>
      <VendorGraph />
    </div>
  );
};

import { GridColumnsSectionType } from 'pages/ClaimsContractsToolPage/types';
import { ColumnBase } from 'utils/agGrid/agGridColumn';
import { get } from '../apiRequest';
import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';

export interface GridColumn extends ColumnBase {
  id: number;
  formatting?: 'currency';
  subProperty?: string;
  enabled: boolean;
  customField?: boolean;
  displayIn?: PageSections;
}

export const configuration = {
  getGridColumns: (type: GridColumnsSectionType) =>
    get<GridColumn[]>(`v1/Configuration/grid-columns/${type}`),
};

export type FormatType = GridColumn['dataType'] | 'currency';

export function gridFieldToFormatType(gridField: {
  propertyName: string;
  dataType: GridColumn['dataType'];
}): FormatType {
  const { propertyName, dataType } = gridField;

  switch (propertyName) {
    // Everything that should be currency
    // This will be extended as we go
    case 'secured':
    case 'administrative':
    case 'priority':
    case 'unsecured':
    case 'total':
    case 'currentTotal':
      return 'currency';

    // The others are the same
    default:
      return dataType;
  }
}

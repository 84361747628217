import { ContractRow } from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { graphqlGetContracts } from 'utils/agGridHotchocolate/graphqlGetContracts';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';

const DEBOUNCED_DELEY = 500;

export const useContractSearchByRef = (searchText: string) => {
  const [client] = useGraphql();
  const [data, setData] = useState<ContractRow[]>([]);
  const [loading, setLoading] = useState(false);

  const searchContractsByRefNum = useCallback(
    async (text: string) => {
      if (_.isEmpty(text)) {
        setData([]);
        return;
      }

      setLoading(true);

      const filterModel: AgGridFilterModel = {
        referenceNumber: {
          filterType: 'text',
          type: 'contains',
          filter: text,
        },
      };

      const result = await graphqlGetContracts({ filterModel }, client);

      setData(result);
      setLoading(false);
    },
    [client],
  );

  const loadDataDebounced = useMemo(
    () => _.debounce(searchContractsByRefNum, DEBOUNCED_DELEY),
    [searchContractsByRefNum],
  );

  useEffect(() => {
    loadDataDebounced(searchText);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, loading };
};

import classNames from 'classnames';
import './UploadHelpPanel.css';

type Props = React.PropsWithChildren<{
  className?: string;
  title: string;
}>;

export const UploadHelpPanel = ({ title, className, children }: Props) => {
  return (
    <div className={classNames('upload-help-panel', className)}>
      <div className="upload-help-panel__title">{title}</div>
      <div className="upload-help-panel__body">{children}</div>
    </div>
  );
};

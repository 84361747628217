import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContractActionSelectOptionsData,
  ContractActionSubstatus,
} from 'api/endpoints/actions-select-options';
import { FetchStatus } from 'types/fetch-status.types';

export type ContractActionSelectOptionsReduxData =
  ContractActionSelectOptionsData & {
    contractActionSubStatuses: ContractActionSubstatus[];
  };

export type ContractActionsSelectOptionsState =
  | {
      type: FetchStatus.Idle;
      data: ContractActionSelectOptionsReduxData;
      error: null;
    }
  | {
      type: FetchStatus.Done;
      data: ContractActionSelectOptionsReduxData;
      error: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: ContractActionSelectOptionsReduxData;
    }
  | {
      type: FetchStatus.Fetching;
      data: ContractActionSelectOptionsReduxData;
      error: null;
    };

export const initialState: ContractActionsSelectOptionsState = {
  type: FetchStatus.Idle,
  error: null,
  data: {
    contractActionTypes: [],
    contractActionSubStatuses: [],
    actionSubstatusesByActionType: {},
    hearingDateRequiredByActionType: {},
  },
};

const contractActionNameOptionsSlice = createSlice({
  name: 'actions-select-options',
  initialState: initialState,
  reducers: {
    contractActionSelectOptionsFetching(state: ContractActionsSelectOptionsState) {
      state.type = FetchStatus.Fetching;
    },

    contractActionSelectOptionsDone(
      state: ContractActionsSelectOptionsState,
      action: PayloadAction<ContractActionSelectOptionsData>,
    ) {
      state.type = FetchStatus.Done;
      state.data = {
        ...action.payload,
        contractActionSubStatuses: Object.values(
          action.payload.actionSubstatusesByActionType,
        ).flatMap((bt) => bt),
      };
    },

    contractActionSelectOptionsError(
      state: ContractActionsSelectOptionsState,
      action: PayloadAction<{ message: string }>,
    ) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const {
  contractActionSelectOptionsFetching,
  contractActionSelectOptionsDone,
  contractActionSelectOptionsError,
} = contractActionNameOptionsSlice.actions;
export default contractActionNameOptionsSlice.reducer;

import { ApButtonIcon, ApModal } from '@alixpartners/ui-components';

import { BasicClientInfo } from 'api/endpoints';
import { NavBar } from 'components/NavBar';
import './PreviewModal.css';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  previewData: {
    newCompanyLogo: ArrayBuffer | undefined;
    companyName: string | undefined;
  };
}

export const PreviewModal = ({ isOpen, onClose, previewData }: Props) => {
  const clientInfo: BasicClientInfo = {
    clientCompanyName: previewData.companyName ?? '',
    logoContent: previewData.newCompanyLogo,
    internalProjectName: '',
    logoFileName: '',
    primaryBrandColor: '',
    secondaryBrandColor: '',
  };

  return (
    <ApModal
      isDismissible={true}
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
      hasClosed={onClose}
      className="preview-modal__container"
    >
      <ApButtonIcon
        autoFocus={false}
        disabled={false}
        hoverColor="#5CB335"
        iconName="close"
        onClick={onClose}
        className="button-close"
        theme="dark"
      />

      <div className="preview-modal__wrapper">
        <div className="preview-modal__nav-bar-container">
          <NavBar clientInfo={clientInfo} hideHeaderMenu />
        </div>
        <div className="preview-modal__white-space">
          <div className="preview-modal__black-object" />
        </div>
      </div>
    </ApModal>
  );
};

import { ApIcon } from '@alixpartners/ui-components';

export const WizardErrorInformation = (props: { text: string }) => {
  return (
    <div className="setup-wizard-field__error">
      <ApIcon iconName="outline_error" iconColor="#ff600f" iconSize={15} />
      <div>{props.text}</div>
    </div>
  );
};

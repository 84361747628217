import classNames from 'classnames';
import './ApProgressBar.css';

interface ApProgressBarProps {
  className?: string;
  progress: number;
}

export const ApProgressBar = ({ className, progress }: ApProgressBarProps) => (
  <div className={classNames('progress-bar', className)}>
    <div className="progress-bar__container">
      <div
        className="progress-bar__bar"
        style={{ width: `${Math.floor(progress * 100)}%` }}
      />
    </div>
  </div>
);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  DropdownCategories,
  ClaimCategoryItemBase,
  EditClaimCategoryRequest,
  AllClaimsCategories,
  CategoryType,
} from 'api/endpoints';
import {
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import {
  editClaimsCategoryThunk,
  getAllClaimsCategoriesThunk,
  getDropdownClaimsCategoriesThunk,
  saveClaimsCategoryThunk,
  setToggleAllThunk,
} from 'reducer/thunks/claimsCategoriesThunks';
import { uniqBy } from 'lodash';
import {
  SORT_VALUES,
  useEntityTableSorting,
} from 'components/UIComponents/EntityTable/useTableSorting';
import { exportCategoriesItems } from 'pages/SettingsPage/utils/exportCategoriesItems';

export const useClaimCategories = () => {
  const [toggleAll, setToggleAll] = useState(false);

  const initialSortState = useMemo(
    () => ({
      field: 'majorCategoryName' as keyof AllClaimsCategories,
      direction: SORT_VALUES.ASC,
    }),
    [],
  );

  const { sortState, handleSort, sortIcon } =
    useEntityTableSorting<AllClaimsCategories>(initialSortState);

  const {
    dropdownCategories,
    allCategories: categories,
    fetching,
  } = useAppSelector((s) => s.claimsCategoriesSettings);
  const dispatch = useAppDispatch();

  const sortedItems = useMemo(
    () =>
      [...categories].sort((a: AllClaimsCategories, b: AllClaimsCategories) => {
        const { field, direction } = sortState;
        return direction === SORT_VALUES.ASC
          ? String(a[field])?.localeCompare(String(b[field]))
          : String(b[field])?.localeCompare(String(a[field]));
      }),
    [categories, sortState],
  );

  const options = useMemo(
    () => uniqBy(mapCategoryByName(dropdownCategories), (c) => c.label),
    [dropdownCategories],
  );

  useEffect(() => {
    dispatch(getDropdownClaimsCategoriesThunk());
    dispatch(getAllClaimsCategoriesThunk());
  }, [dispatch]);

  const onAddSave: OnAddSave<ClaimCategoryItemBase> | any = useCallback(
    async (formValues: ClaimCategoryItemBase) => {
      const existing = categories.find(
        (c) => c.majorCategoryName === formValues.majorCategoryName,
      );
      if (existing) {
        return dispatch(
          saveClaimsCategoryThunk({
            ...formValues,
            majorCategoryId: existing.majorCategoryId,
            minorCategoryName: formValues.minorCategoryName,
            majorCategoryName: null,
          }),
        );
      }
      return dispatch(saveClaimsCategoryThunk(formValues));
    },
    [dispatch, categories],
  );

  const onEditSave: OnEditSave<AllClaimsCategories> | any = useCallback(
    async (formValues: EditClaimCategoryRequest, item: AllClaimsCategories) => {
      const editValues: EditClaimCategoryRequest = {
        minorCategoryId: item.minorCategoryId,
        enabled: item.enabled,
        minorCategoryName:
          formValues.minorCategoryName !== item.minorCategoryName
            ? formValues.minorCategoryName
            : null,
      };
      return dispatch(editClaimsCategoryThunk(editValues));
    },
    [dispatch],
  );

  const warningMessage = useMemo(
    () =>
      `${
        categories.filter((categ) => categ.usedInClaims).length
      } categories in use. Please make sure the categories you disable are not assigned to any claims`,
    [categories],
  );

  const allCategories = useMemo(
    () =>
      sortedItems.map((category) => ({
        ...category,
        hideEdit: category.categoryType === CategoryType.Baseline,
      })),
    [sortedItems],
  );

  const handleToggleAll = useCallback(async () => {
    setToggleAll((prev) => !prev);
    return dispatch(setToggleAllThunk(!toggleAll));
  }, [dispatch, toggleAll]);

  const exportEnabledCategories = async () => {
    await exportCategoriesItems(
      categories
        .filter((category) => category.enabled)
        .map((item) => [item.majorCategoryName ?? '', item.minorCategoryName]),
      {
        fileName: 'ClaimsCategories.xlsx',
        columns: [
          { header: 'MajorCategory', width: 30 },
          { header: 'MinorCategory', width: 70 },
        ],
        templateName: 'ContractCategory.xlsx',
      },
    );
  };

  return {
    options,
    onAddSave,
    allCategories,
    warningMessage,
    onEditSave,
    toggleAll,
    handleToggleAll,
    fetching,
    sortState,
    handleSort,
    sortIcon,
    exportEnabledCategories,
  };
};

const mapCategoryByName = (categories: DropdownCategories[]) => {
  return categories?.map((category) => ({
    value: String(category.id),
    label: category.categoryName,
  }));
};

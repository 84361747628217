import { contractLogs } from 'api/endpoints/contractLogs';
import {
  contractLogsFetching,
  contractLogsDone,
  contractLogsError,
  paginationChanged,
} from '../contractLogsDataReducer';
import { PaginationInput } from 'reducer/claimLogsDataReducer';

import { AppDispatch, GetState } from '../store';

export const fetchContractsLogsThunk =
  (input: PaginationInput) => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(contractLogsFetching(input));

    const response = await contractLogs.get(input.id || 0);
    if (!response) {
      // errors are shown by the popup already but let the reducer know
      dispatch(contractLogsError({ message: 'Could not fetch contracts log.' }));
      return;
    }
    dispatch(
      contractLogsDone({
        ...response,
        ...input,
      }),
    );

    dispatch(paginationChanged(input));
  };

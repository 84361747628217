export interface HelpStructure {
  [module: string]: {
    description: string;
    topics: {
      [topic: string]: {
        [article: string]: string;
      };
    };
  };
}

//use "public/helpinfo" folder to keep *.md files

const SOFAS_DESCRIPTION = `Upload data, edit, review and export populated documents`;
const CLAIMS_DESCRIPTION = `Match, search, filter, categorize, assign actions and review all claims in this project`;
// const CONTRACTS_DESCRIPTION = `Match, search, filter, categorize, assign actions and review all claims in this project`;

export const helpStructure: HelpStructure = {
  Claims: {
    description: CLAIMS_DESCRIPTION,
    topics: {
      Setup: {
        'Using Data Uploader Module': 'claim_using_data_uploader_module.md',
        'Matching Scheduled Claims': 'claim_matching_scheduled_claims.md',
      },
      Estimation: {
        'Claim Listing: Categorize': 'claim_listing_categorise.md',
        'Claim Listing: Match (Individual)': 'claim_listing_match.md',
        'Claim Listing: Manage Columns': 'claim_listing_manage_columns.md',
        'Claim Listing: Display My Claims': 'claim_listing_display_my_claims.md',
        'Claim Listing: Reset Filters': 'claim_listing_reset_filters.md',
        'Claim Listing: Export Selected Claims':
          'claim_listing_export_selected_claims_columns.md',
        'Claim Listing: Export Selected Claims - All Columns':
          'claim_listing_export_selected_claims_all_columns.md',
        'Claim Listing: Export Entire Audit Log':
          'claim_listing_export_entire_audit_log.md',
        'Claim Listing: Exhibit Exporter': 'claim_listing_exhibit_exporter.md',

        'Trump Schedules': 'claim_objection_trump_schedule.md',
        'Set-up Resolution Names (Objection Hearings)':
          'claim_objection_setup_action_names.md',
        'Claim Objections: Amended Claims': 'claim_objection_amended_claims.md',
        'Claim Objections: Reduce and Reclass':
          'claim_objection_reduce_and_reclass.md',
        'Claim Objections: Wrong Debtor': 'claim_objection_wrong_debtor.md',
        'Claim Settlement': 'claim_objection_claim_settlement.md',
        'Manage reconciliation support documentation':
          'claim_objection_claim_settlement.md',
      },
      Resolution: {
        'Resolution: Setup "Resolution Name" to create omnibus objection exhibit':
          'claims_resolutions_setup_resolution_name_omnibus.md',
        'Resolution: link claims to an omnibus objection "Action Name" and create an Omnibus Objection Exhibit - Amended and Superseded objection':
          'claims_resolutions_link_claims.md',
        'Resolution: Create Omnibus Objection, Add claims, use prior exhibit template':
          'claims_resolutions_create_omnibus_objection.md',
        'Resolution: Change Claims Status after Objection is filed':
          'claims_resolutions_change_claims_status.md',
        'Resolution: Change Claims Status to Approved Objections or Continued Objections':
          '_cooming_soon.md',
        'Resolution: Create Omnibus Objection - Satisfied Claims':
          'claims_resolutions_satisfied_claims.md',
        'Resolution: Create Omnibus Objection - Misclassify':
          'claims_resolutions_missclassified_claims.md',
        'Resolution: Create Omnibus Objection - Reduce':
          'claims_resolutions_reduce_claims.md',
      },
    },
  },

  Settlements: {
    description: 'Agreement Template',
    topics: {
      Settings: {
        'Agreement Template': 'Settinngs__Agreement-Templates.md',
      },
    },
  },

  'SOFAs and Schedules': {
    description: SOFAS_DESCRIPTION,
    topics: {
      ALL: {
        'Upload Templates': '_cooming_soon.md',
        Slipsheets: '_cooming_soon.md',
        Preview: '_cooming_soon.md',
        Signatories: '_cooming_soon.md',
        'Global Notes': '_cooming_soon.md',
      },
      SOFAs: {
        'View Data': '_cooming_soon.md',
        'Upload Data': '_cooming_soon.md',
      },
      Schedule: {
        'View Data': '_cooming_soon.md',
        'Upload Data': '_cooming_soon.md',
      },
    },
  },

  // Contracts: {
  //   description: CONTRACTS_DESCRIPTION,
  //   topics: {
  //     Setup: {
  //       'Contract Objection - Reduce and Reclass': 'test_01.md',
  //       'Using Data Uploader Module': 'test_01.md',
  //       'Matching Scheduled Contracts': 'test_02.md',
  //     },
  //     Estimation: {
  //       'Contract Listing: Categorize': 'test_01.md',
  //       'Contract Listing: Match (Individual)': 'test_02.md',
  //       'Contract Listing: Manage Columns': 'test_03.md',
  //       'Contract Listing: Display My Contracts': 'test_04.md',
  //       'Contract Listing: Reset Filters': 'test_05.md',
  //       'Contract Listing: Export Selected Contracts / Columns': 'test_06.md',
  //       'Contract Listing: Export Selected Contracts - All Columns': 'test_07.md',
  //       'Contract Listing: Export Entire Audit Log': 'test_08.md',
  //       'Contract Listing: Exhibit Exporter': 'test_09.md',
  //       'Trump Schedules': 'test_10.md',
  //       'Set-up Action Names (objection hearings)': 'test_11.md',
  //       'Contract Objections: Amended Contracts': 'test_12.md',
  //       'Contract Objections: Reduce and Reclass': 'test_13.md',
  //       'Contract Objections: Wrong Debtor': 'test_01.md',
  //       'Contract Settlement': 'test_02.md',
  //       'Manage reconciliation support documentation': 'test_03.md',
  //     },
  //     Resolution: {
  //       'Setup "Action Name" to create omnibus objection exhibit': 'test_01.md',
  //       'Link Contracts to an omnibus objection "Action Name" and create an Omnibus Objection Exhibit - Amended and Superseded objection':
  //         'test_02.md',
  //       'Create Omnibus Objection, Add Contracts, use prior exhibit template':
  //         'test_03.md',
  //       'Change Contracts Status after Objection is filed': 'test_04.md',
  //       'Change Contracts Status to Approved Objections or Continued Objections':
  //         'test_05.md',
  //       'Create Omnibus Objection - Satisfied Contracts': 'test_06.md',
  //       'Create Omnibus Objection - Misclassify': 'test_07.md',
  //       'Create Omnibus Objection - Reduce': 'test_08.md',
  //       'Contract Listing: Exhibit Exporter': 'test_09.md',
  //     },
  //   },
  // },
};

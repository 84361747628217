import { ApButtonMain, ApIcon } from '@alixpartners/ui-components';
import { UploadModal } from 'components/ModalWindows/UploadModal';
import { useContractAttachmentsContext } from '../../context/ContractAttachmentsProvider';
import { CONTRACT_ATTACHMENTS_OPTIONS } from '../../types';

import { useState } from 'react';
import './SingleContractDocs.css';
import { SingleContractDocTable } from './SingleContractDocTable';

interface Props {
  contractId: number;
}

export const SingleContractDocuments = ({ contractId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { addAttachments } = useContractAttachmentsContext();

  return (
    <div>
      <ApButtonMain
        className="single-contracts-documents__upload"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <ApIcon iconName="add" iconSize="sm" />
        Upload new document
      </ApButtonMain>

      <SingleContractDocTable />

      {isModalOpen ? (
        <UploadModal
          label="Upload filed contracts data"
          typeOptions={CONTRACT_ATTACHMENTS_OPTIONS}
          onSubmit={addAttachments}
          onClosed={() => {
            setIsModalOpen(false);
          }}
          options={{ commentMaxLength: 500 }}
        />
      ) : undefined}
    </div>
  );
};

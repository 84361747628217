import { downloadFile, get, getQuiet, post, put } from 'api/apiRequest';
import { BackgroundjobStatus } from './backgroundJob';
import { SettlementId } from './settlements';

export const directorySettlementUser = 'SettlementUsersBatchUpload';

export type SettlementUserRole = 'Counterparty' | 'Client' | '';

export type SettlementUser = {
  id: number;
  userId: string;
  userName: string;
  name: string;
  email: string;
  title: string | null;
  companyName: string;
  phoneNumber: string;
  userRole: SettlementUserRole;
};

export interface SettlementAssignedUser {
  id: number;
  name: string;
  email: string;
  role: SettlementUserRole;
}

export type SettlementUserRoleUpdate = Pick<SettlementUser, 'id' | 'userRole'>;
export type SettlementUserNew = Pick<SettlementUser, 'name' | 'email' | 'userRole'>;

export type SettlemetnBackgroundResponse = {
  jobId: number;
};

export type SettlementBackgroundStatus = {
  id: 6;
  fileName: string;
  displayName: string;
  status: BackgroundjobStatus;
};

export const settlementUsers = {
  getAll: () => get<SettlementUser[]>(`v1/SettlementUsers`),
  postNew: (value: SettlementUserNew) =>
    post<SettlementUser>(`v1/SettlementUsers`, value),

  getByRole: (userRole: SettlementUserRole) =>
    get<SettlementUser[]>(`v1/SettlementUsers/${userRole}`),
  getAssigned: (id: SettlementId) =>
    get<SettlementAssignedUser[]>(`v1/SettlementUsers/assigned/${id}`),

  updateUser: (value: SettlementUserRoleUpdate) =>
    put(`v1/SettlementUsers/${value.id}`, value),

  resetUserPassword: (id: SettlementUser['id']) =>
    put<SettlementUser>(`v1/SettlementUsers/${id}/resetpassword`, null),

  batchUpload: (fileName: string, displayName: string) =>
    post<SettlemetnBackgroundResponse>('v1/SettlementUsers/batchUpload', {
      fileName,
      displayName,
      directoryName: directorySettlementUser,
      containerName: 'excelcontainer',
    }),
  getBatchErrorsFile: (jobId: number) =>
    downloadFile(`v1/SettlementUsers/BatchUploadError/${jobId}`, 'ErrorLog.xlsx'),

  getBatchUploadJob: (jobId: number) =>
    getQuiet<SettlementBackgroundStatus>(`v1/SettlementUsers/batchUpload/${jobId}`),
  getBatchUploadActiveJobs: () =>
    get<SettlementBackgroundStatus[]>(`v1/SettlementUsers/batchUpload/activeJobs`),
};

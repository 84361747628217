import { GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useState } from 'react';

let massSelectionChange = false;

function setPageSelection(api: GridApi<any>, checked: boolean, onDone: () => void) {
  const startIndex = api.paginationGetCurrentPage() * api.paginationGetPageSize();
  const endIndex = startIndex + api.paginationGetPageSize();
  massSelectionChange = true;
  api.forEachNode((node) => {
    if (
      node.rowIndex === null ||
      node.rowIndex < startIndex ||
      node.rowIndex >= endIndex
    )
      return;
    // @todo: check if node is selectable
    // @todo: check if node is already selected
    node.setSelected(checked);
  });
  // we just need to execute this on the next tick
  // because node.setSelected causes ag-grid callbacks to be called en mass
  setTimeout(() => {
    massSelectionChange = false;
    onDone();
  });
}

/**
 * This hook manages selection in the grid
 * @param ref
 * @param ready
 * @returns
 */
export function useSelectionManager<T>(
  ref: React.RefObject<AgGridReact<any>>,
  ready: boolean,
) {
  const [selectedRows, selSelectedRows] = useState<T[]>([]);

  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  // @todo: move to selection manager
  const onSelectionChanged = useCallback(() => {
    if (massSelectionChange) return;
    if (!ref.current) return;
    const api = ref.current.api;
    selSelectedRows(api.getSelectedRows());
  }, [ref]);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      if (!ready) return;
      setSelectAllCheckbox(checked);
      if (!ref.current) return;
      const api = ref.current.api;
      // get all all ag-grid nodes
      setPageSelection(api, checked, onSelectionChanged);
    },
    [onSelectionChanged, ref, ready],
  );

  const onPaginationChanged = useCallback(() => {
    setSelectAllCheckbox(false);
    ref.current?.api?.deselectAll();
  }, [ref]);

  return {
    selectedRows,
    selectAllCheckbox,
    onSelectionChanged,
    handleCheckboxChange,
    onPaginationChanged,
  };
}

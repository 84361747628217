import { DataPipelineStatusResponse } from 'api/endpoints';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import {
  FileType,
  IUploadItem,
  IUploadSection,
  UnpublishedFilesStatuses,
} from 'types/dataUploaderTypes';
import { NotificationText } from '../components/NotificationText';
import { PublishingStatus, TRequiredFiles } from '../types/uploadItemsTypes';
import './DataUploadHeader.css';

export interface IDataUploaderProps {
  uploadItems: IUploadItem[];
  uploadSections: IUploadSection[];
  pipeline: DataPipelineStatusResponse | undefined;
  publishingStatus: PublishingStatus;
  onHeaderClick: () => unknown;
}

export const DataUploadHeader = ({
  uploadItems,
  uploadSections,
  pipeline,
  publishingStatus,
  onHeaderClick,
}: IDataUploaderProps) => {
  const { requiredFiles, serverFiles } = useMemo(
    () => ({
      requiredFiles: calculateRequiredFiles(uploadItems, uploadSections),
      serverFiles: filterServerFiles(uploadItems),
    }),
    [uploadItems, uploadSections],
  );

  return (
    <div className="data-upload_header">
      <div className="data-upload_header-label" onClick={onHeaderClick}>
        Data Uploader
      </div>
      <div className="data-upload_header-menu">
        <div className="data-upload_header-notification">
          <NotificationText
            status={pipeline?.processingStatus}
            requiredFiles={requiredFiles}
            publishingCount={serverFiles.length}
            publishingStatus={publishingStatus}
          />
        </div>
      </div>
    </div>
  );
};

const calculateRequiredFiles = (
  uploadItems: IUploadItem[],
  uploadSections: IUploadSection[],
): TRequiredFiles => {
  const total = sum(uploadSections.map((r) => r.requiredFiles));

  const upload = sum(
    uploadSections.map((section) => {
      const filesToUpload = uploadItems.filter(
        (i) => i.uploadedFile?.fileType === section.type,
      );
      return Math.min(filesToUpload.length, section.requiredFiles);
    }),
  );

  return { total, upload };
};

const filterServerFiles = (uploadItems: IUploadItem[]): IUploadItem[] =>
  uploadItems.filter(
    (i) =>
      UnpublishedFilesStatuses.includes(i.status!) &&
      // Matches upload has moved to Matches page
      i.type !== FileType.BaselineMatches,
  );

import { ICellEditorParams } from 'ag-grid-community';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { inputStringToNumber, isInputIntegerValid } from './numberUtils';
import './TableComponents.css';

export const CellNumberEditor: React.FC<ICellEditorParams> = forwardRef(
  (props: ICellEditorParams, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => inputStringToNumber(value),
      focusIn: () => inputRef.current?.focus(),
    }));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (!isInputIntegerValid(newValue)) return;

      setValue(newValue);
    };

    return (
      <div className="editorcell-custom ag-input-wrapper ag-text-field-input-wrapper">
        <input
          ref={inputRef}
          className="ag-input-field-input ag-text-field-input"
          value={value}
          onChange={handleChange}
        />
      </div>
    );
  },
);

import { GlobalNoteLegalEntity } from 'api/endpoints';
import { IOption } from 'components/UIComponents/MultiSelect';

export const legalEntityToOption = (list: GlobalNoteLegalEntity[] | undefined) =>
  list?.map(mapLegalEntityToOption);

export function mapLegalEntityToOption(le: GlobalNoteLegalEntity): IOption {
  const opt: IOption = {
    label: le.legalEntityName,
    value: `${le.legalEntityId}`,
    data: le,
  };
  return opt;
}

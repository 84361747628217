import { ClaimRow } from 'api/endpoints';
import {
  ApModal,
  ApButtonGroup,
  ApButtonSecondary,
  ApButtonMain,
} from '@alixpartners/ui-components';
import './MatchesAcceptedPopup.css';

interface MatchesAcceptedPopupProps {
  claims: Array<ClaimRow>;
  onAccept: () => unknown;
  onClose: () => unknown;
  isOpen: boolean;
}

export const MatchesAcceptedPopup = ({
  isOpen,
  onAccept,
  onClose,
  claims,
}: MatchesAcceptedPopupProps) => {
  return (
    <ApModal
      header="Match-group creation"
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonSecondary onClick={() => onClose()}>Cancel</ApButtonSecondary>
          <ApButtonMain
            onClick={() => {
              onAccept();
              onClose();
            }}
          >
            Done
          </ApButtonMain>
        </ApButtonGroup>
      )}
      isDismissible={false}
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
    >
      <div className="matches-accepted-popup">
        <div>
          You are about to accept matches with 100% scores for the following claims:
        </div>
        {claims.map((c) => (
          <div className="claim" key={`counterparty_${c.id}`}>
            <div className="counterparty-name">{c.counterpartyName}</div>
            <div className="match-code">
              Match Code: {c.oneHundredPercentMatchCode}
            </div>
          </div>
        ))}
        <div>Are you sure you want to make these edits?</div>
      </div>
    </ApModal>
  );
};

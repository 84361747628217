import { ApLoaderDefault } from '@alixpartners/ui-components';
import { LinkText } from 'components/UIComponents/LinkText';
import './PrimaryDocLink.css';

interface Props {
  name: string;
  loading?: boolean;
  onClick: () => void;
}

export const PrimaryDocLink = ({ name, loading, onClick }: Props) => {
  return (
    <div className="single-entity__primary-doc">
      Primary Document:
      <LinkText onClick={onClick}>{name}</LinkText>
      {loading && <ApLoaderDefault />}
    </div>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AllClaimsCategories,
  DropdownCategories,
  EditClaimCategoryRequest,
} from 'api/endpoints';

type State = {
  dropdownCategories: DropdownCategories[];
  allCategories: AllClaimsCategories[];
  fetching: boolean;
};

const InitialState: State = {
  dropdownCategories: [],
  allCategories: [],
  fetching: false,
};

export const claimsCategoriesReducer = createSlice({
  name: 'claimsCategoriesSettings',
  initialState: InitialState,
  reducers: {
    fetchingAllCategories: (state: State, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    fetchDropdownCategoriesDone: (
      state: State,
      action: PayloadAction<DropdownCategories[]>,
    ) => {
      state.dropdownCategories = action.payload;
    },
    fetchAllCategoriesDone: (
      state: State,
      action: PayloadAction<AllClaimsCategories[]>,
    ) => {
      state.allCategories = action.payload;
      state.fetching = false;
    },
    editCategoryDone: (
      state: State,
      action: PayloadAction<EditClaimCategoryRequest>,
    ) => {
      state.allCategories = [...state.allCategories].map((category) => {
        return {
          ...category,
          enabled:
            category.minorCategoryId === action.payload.minorCategoryId
              ? action.payload.enabled
              : category.enabled,
          minorCategoryName:
            category.minorCategoryId === action.payload.minorCategoryId &&
            action.payload.minorCategoryName
              ? action.payload.minorCategoryName
              : category.minorCategoryName,
        };
      });
    },
  },
});
export default claimsCategoriesReducer.reducer;
export const {
  fetchDropdownCategoriesDone,
  fetchAllCategoriesDone,
  editCategoryDone,
  fetchingAllCategories,
} = claimsCategoriesReducer.actions;

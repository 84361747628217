import {
  contractPaymentPositionsFetch,
  contractPaymentPositionsLoaded,
} from 'reducer/contractPaymentPositionsReducer';
import { contracts as contractsApi } from 'api/endpoints';

export const loadContractPaymentPositions = async (dispatch: any) => {
  dispatch(contractPaymentPositionsFetch());
  const contractsPaymentPositions = await contractsApi.getPaymentPositions();
  dispatch(contractPaymentPositionsLoaded(contractsPaymentPositions as any));
};

import { ISnackProps, snack } from '@alixpartners/ui-components';
import { throttle, uniqueId } from 'lodash';
import { HttpErrorInfo } from './HttpErrorInfo';
import { IHttpError } from './requestTypes';

const SINGLETON_SNACK_ID = 'snack-http-error-id';
const snackDialogDefaultProps: ISnackProps = {
  position: 'top-right',
  showIcon: true,
  suppressHide: true,
};

// only one Error message is shown at a time
export const showHttpSingleError = throttle((err: IHttpError) => {
  if (isErrorAlreadyShown()) return;

  snack.custom({
    id: SINGLETON_SNACK_ID,
    custom: (hide) => <HttpErrorInfo err={err} hide={hide} />,
    ...snackDialogDefaultProps,
  });
}, 1000);

export const showHttpError = (err: IHttpError) => {
  snack.custom({
    id: uniqueId('snack-http-error-'),
    custom: (hide) => <HttpErrorInfo err={err} hide={hide} />,
    ...snackDialogDefaultProps,
  });
};

const isErrorAlreadyShown = () => {
  const el = document.getElementById(SINGLETON_SNACK_ID);
  return !!el;
};

import { ApModal, IOption } from '@alixpartners/ui-components';
import { GlobalNote } from 'api/endpoints';
import classNames from 'classnames';
import { BrowserFileRow } from 'components/ModalWindows/UploadModal/FileRowLite';
import { DataContentBox } from 'pages/DataUploaderPage/components/DataContentBox';
import {
  Extension,
  FileUploadPanel,
} from 'pages/DataUploaderPage/components/FileUploadPanel';

import { FileDisplay } from 'pages/SOFAsAndSchedules/Components/ActionList';
import { ModalUploadFooter } from 'pages/SOFAsAndSchedules/Components/ModalUploadFooter';
import { SofaLegalEntityMultiselect } from 'pages/SOFAsAndSchedules/Components/SofaLegalEntityMultiselect';

import { useMemo, useState } from 'react';
import './GlobalNotesUploadModal.css';
import { legalEntityToOption } from './globalNotesUtils';

const EXTENSIONS: Extension[] = ['.docx', '.pdf'];
const MAX_FILES = 1;

export type GlobalNotesUploadModalProps = {
  item: GlobalNote | undefined;
  onClosed: () => void;
  onSave: (files: File[], entityIds: number[]) => void;
};

export const GlobalNotesUploadModal = ({
  item,
  onClosed,
  onSave,
}: GlobalNotesUploadModalProps) => {
  const [userSelectedFiles, setUserSelectedFiles] = useState<File[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<IOption[]>(
    legalEntityToOption(item?.legalEntities) ?? [],
  );

  const handleFileDelete = (file: File) => {
    setUserSelectedFiles((oldFiles) => oldFiles.filter((r) => r !== file));
  };

  const handleUpload = () => {
    const entityIds = selectedEntities.map((o) => +o.value);
    onSave(userSelectedFiles, entityIds);
  };

  const { header, buttonName } = useMemo(
    () => ({
      header: !item ? 'Upload a Global Note' : 'Edit entities for',
      buttonName: !item ? 'Proceed' : 'Save',
    }),
    [item],
  );

  const fileSelected = !!item || !!userSelectedFiles.length;
  const submitDisabled = !selectedEntities.length || !fileSelected;

  return (
    <div>
      <ApModal
        className={classNames('global-notes-upload-modal', {})}
        header={header}
        isOpen
        isDismissible={true}
        footer={() => (
          <ModalUploadFooter
            mainLabel={buttonName}
            disabled={submitDisabled}
            onClick={handleUpload}
            onCancel={onClosed}
          />
        )}
        hasClosed={onClosed}
      >
        {item && <FileDisplay displayName={item.displayName} />}
        <SofaLegalEntityMultiselect
          selected={selectedEntities}
          onChange={setSelectedEntities}
        />

        {!item ? (
          <AddNewFileSection
            files={userSelectedFiles}
            onUpload={setUserSelectedFiles}
            onDelete={handleFileDelete}
          />
        ) : (
          <div className="global-notes-upload-modal__bottom-padding" />
        )}
      </ApModal>
    </div>
  );
};

const AddNewFileSection = (props: {
  files: File[];
  onUpload: (file: File[]) => void;
  onDelete: (file: File) => void;
}) => {
  const { files, onUpload, onDelete } = props;
  return (
    <>
      <DataContentBox label={''}>
        <FileUploadPanel
          accept={EXTENSIONS}
          maxFiles={MAX_FILES}
          onUpload={onUpload}
        />
      </DataContentBox>

      {files.map((f) => (
        <div className="anim-appear" key={f.name}>
          <BrowserFileRow file={f} onDelete={onDelete} />
        </div>
      ))}
    </>
  );
};

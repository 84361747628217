import { ClaimRow } from 'api/endpoints';
import { ActionName } from 'api/endpoints/action-names';
import cs from 'classnames';
import { ClaimButton } from 'components/ExhibitsExport/ClaimButton';
import { ExhibitSelect } from 'components/ExhibitsExport/ExhibitSelect';
import { ExibitErrorMessage } from 'components/ExhibitsExport/ExibitErrorMessage';
import { InputText } from 'components/UIComponents/InputText';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import _, { isEmpty } from 'lodash';
import { getTemplateName } from 'pages/ExhibitExporterPage/templates/exibitType';
import { useMemo } from 'react';
import { ActionSelectOptionsReduxData } from 'reducer/actionsSelectOptions';
import {
  deleteSelectedClaims,
  setActionId,
  setExhibitNumber,
  setObjectionBasis,
} from 'reducer/exhibits/exhibitReducer';
import { mapOptions } from '../../utils/field';
import { AddClaimPopup } from '../AddClaimPopup';
import { ClaimsTable } from '../ClaimsTable';

const NONE_ID = 0;

interface Props {
  actionType: number | undefined;
  actionSubstatus: number | undefined;
  isShowAddClaimPopup: boolean;
  selectedClaims: ClaimRow[];
  actionName: string;
  hasEmptyClaimNumbers: boolean;
  getSelectedClaims: (claims: ClaimRow[]) => void;
  getActionNames: (
    actionTypeId: number | undefined,
    actionSubstatusId: number | undefined,
  ) => ActionName[] | undefined;
  updateActionSubstatus: (as?: number) => unknown;
  handleChangeActionName: (value: number) => void;
  setIsShowAddClaimPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExhibitDataSection = ({
  actionType,
  actionSubstatus,
  isShowAddClaimPopup: showAddClaimPopup,
  selectedClaims,
  actionName,
  hasEmptyClaimNumbers,
  getSelectedClaims,
  getActionNames,
  updateActionSubstatus,
  handleChangeActionName,
  setIsShowAddClaimPopup,
}: Props) => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((s) => s.actionsSelectOptions);
  const { currentExhibit, loading } = useAppSelector((s) => s.exhibit);

  const { actionTypeOptions, actionSubstatuses } = useMemo(
    () => getItemsWithTemplate(options.data),
    [options.data],
  );

  const actionSubstatusOptions = actionSubstatuses[actionType!] ?? [];

  const resolutionNameOptions = useMemo(() => {
    const resulutionNames = getActionNames(actionType, actionSubstatus);
    return mapOptions(resulutionNames, 'name');
  }, [actionSubstatus, actionType, getActionNames]);

  const handleDeleteGridRows = () => {
    const selectedIds = selectedClaims.map((c: ClaimRow) => c.id);
    dispatch(deleteSelectedClaims(selectedIds));
  };

  return (
    <div className="exhibit-exporter__section">
      <div className="exhibit-exporter__section-title">Exhibit Data</div>
      <div className="exhibit-exporter__section-content">
        <div className="exhibit-exporter__section-content-row">
          <InputText
            autoFocus={false}
            disabled={loading}
            label="Exhibit Title*:"
            placeholder="Enter exhibit title"
            value={currentExhibit.exhibitNumber}
            onChange={(e) => dispatch(setExhibitNumber(e.target?.value))}
            required
            style={
              !currentExhibit.exhibitNumber ? { border: '1px solid #e95959' } : {}
            }
          />
          <InputText
            autoFocus={false}
            disabled={loading}
            label="Basis for objection*:"
            placeholder="Enter text"
            value={currentExhibit.objectionBasis}
            onChange={(e) => dispatch(setObjectionBasis(e.target?.value))}
            required
            style={
              !currentExhibit.objectionBasis ? { border: '1px solid #e95959' } : {}
            }
          />
        </div>

        <div className="exhibit-exporter__section-content-row">
          <ExhibitSelect
            className="exhibit-exporter__resolution-select"
            label="Resolution type:"
            placeholder="Select a resolution type"
            disabled={loading}
            required
            options={actionTypeOptions}
            value={actionType}
            onChange={(value) => handleChangeActionName(+value)}
          />

          <ExhibitSelect
            className="exhibit-exporter__resolution-select"
            label="Resolution subtype:"
            placeholder="Select resolution subtype"
            disabled={!actionType && !loading}
            required
            options={actionSubstatusOptions}
            value={actionSubstatus}
            onChange={(value) => updateActionSubstatus(+value)}
          />

          <ExhibitSelect
            className="exhibit-exporter__resolution-select"
            label="Resolution name:"
            placeholder="Select a resolution name"
            disabled={!actionSubstatus && !loading}
            required
            options={resolutionNameOptions}
            value={currentExhibit.actionNameId}
            onChange={(value) => dispatch(setActionId(+value))}
          />
        </div>
      </div>
      <div
        className={cs('exhibit-exporter__section-content-row', 'align-flex-end ', {
          hide: !currentExhibit.actionNameId,
        })}
      >
        <ClaimsTable
          actionName={actionName}
          actionSubstatus={actionSubstatus}
          getSelectedClaims={getSelectedClaims}
        />
        <div className="exhibit-exporter__section-content-row_buttons-right-container">
          <ClaimButton
            disabled={loading}
            iconName="add"
            onClick={() => setIsShowAddClaimPopup(true)}
          >
            Add other claims
          </ClaimButton>
          <ClaimButton
            disabled={!selectedClaims.length}
            iconName="delete"
            onClick={handleDeleteGridRows}
          >
            Delete {selectedClaims?.length! > 1 ? 'claims' : 'claim'}
          </ClaimButton>
        </div>
      </div>
      {hasEmptyClaimNumbers && (
        <ExibitErrorMessage
          text={'Please add the “Claim Number” to all the claims you want to export'}
        />
      )}
      {showAddClaimPopup && (
        <AddClaimPopup
          handleClose={() => setIsShowAddClaimPopup(false)}
          exhibitClaims={currentExhibit.claims}
        />
      )}
    </div>
  );
};

function getItemsWithTemplate(data: ActionSelectOptionsReduxData): any {
  const actionSubstatuses = _.mapValues(
    data.actionSubstatusesByActionType,
    (subItems) => {
      const itemsWithTemplate = subItems?.filter((a) => getTemplateName(a.id));
      return mapOptions(itemsWithTemplate, 'actionSubStatusName');
    },
  );

  const actionTypesWithTemplate = data.actionTypes.filter(
    (r) => r.id !== NONE_ID && !isEmpty(actionSubstatuses[r.id]),
  );
  const actionTypeOptions = mapOptions(actionTypesWithTemplate, 'actionTypeName');

  return { actionTypeOptions, actionSubstatuses };
}

type BookmarkBodyTextareaProps = {
  title?: string;
  label: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  defaultValue?: string;
};

export const BookmarkBodyWithTextarea = ({
  title,
  label,
  handleChange,
  defaultValue = '',
}: BookmarkBodyTextareaProps) => {
  return (
    <div className="bookmarks-page__popup">
      {title && <p>{title}</p>}
      <label htmlFor="bookmark">{label}</label>
      <textarea
        name="bookmark"
        id="bookmark"
        maxLength={100}
        onChange={handleChange}
        autoFocus
        defaultValue={defaultValue}
      />
    </div>
  );
};

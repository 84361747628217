import { ReactChild } from 'react';
import { markdownToText } from 'utils/markdownToText/markdownToText';
import { HelpSearchContent } from '../api';
import { HelpSearchResult } from '../types';

export const searchEngine = (searchText: string, itemList: HelpSearchContent[]) => {
  return itemList
    .filter((item) => !!item.content)
    .map(({ content, ...rest }) => {
      const simpleText = markdownToText(content ?? '');
      const snippet = findTextSnippet(simpleText, searchText);

      return { ...rest, snippet } as HelpSearchResult;
    })
    .filter((r) => !!r.snippet);
};

function findTextSnippet(source: string, search: string): ReactChild | undefined {
  //case-insensitive search
  const lowerCaseSource = source.toLowerCase();
  const lowerCaseSearch = search.toLowerCase();

  const index = lowerCaseSource.indexOf(lowerCaseSearch);
  if (index < 0) return undefined;

  const start = Math.max(0, index - 100);
  const end = Math.min(source.length, index + search.length + 100);

  const before = source.substring(start, index);
  const highlighted = source.substring(index, index + search.length);
  const after = source.substring(index + search.length, end);

  const startDots = start > 0 ? '...' : '';
  const endDots = end < source.length - 1 ? '...' : '';

  return (
    <>
      {`${startDots}${before}`}
      <b>{highlighted}</b>
      {`${after}${endDots}`}
    </>
  );
}

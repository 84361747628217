import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';
import { ClaimEditValues } from 'api/endpoints';
import classNames from 'classnames';
import 'components/UIComponents/ui-button-reset.css';
import { useSingleClaimMatches } from '../sections/SingleClaimMatches.hooks';
import { SingleClaimSubheaderType } from '../types';
import './SingleClaimSubheader.css';
import { useMemo } from 'react';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';

export interface SingleClaimSubheaderProps {
  claim: ClaimEditValues;
  tab: SingleClaimSubheaderType;
  onTabClick: (s: SingleClaimSubheaderType) => unknown;
}

const SINGLE_CLAIM_SUBHEADER_OPTIONS = [
  {
    type: SingleClaimSubheaderType.Overview,
    label: 'Overview',
    hidden: false,
  },
  {
    type: SingleClaimSubheaderType.Matches,
    label: 'Matches',
    hidden: false,
  },
  {
    type: SingleClaimSubheaderType.ChangesAndComments,
    label: 'Changes & Comments',
    hidden: false,
  },
  {
    type: SingleClaimSubheaderType.Documents,
    label: 'Documents',
    hidden: false,
  },
];

export const SingleClaimSubheader = ({
  claim,
  tab,
  onTabClick,
}: SingleClaimSubheaderProps) => {
  const { matchedClaims } = useSingleClaimMatches(claim);
  const { attachments } = useClaimAttachmentsContext();

  const matchesCount = useMemo(
    () =>
      matchedClaims && matchedClaims?.length > 0 ? ` [${matchedClaims.length}]` : '',
    [matchedClaims],
  );

  const documentsCount = useMemo(
    () => (attachments.length > 0 ? ` [${attachments.length}]` : ''),
    [attachments],
  );

  return (
    <div className="single-claim-subheader">
      <div className="single-claim-subheader__tabs">
        <ApButtonGroup justifyContent="initial" spacingSize={0}>
          {SINGLE_CLAIM_SUBHEADER_OPTIONS.map((s) => {
            const disableEmptyMatchesTab =
              s.type === SingleClaimSubheaderType.Matches &&
              matchedClaims?.length === 0;

            const label =
              s.type === SingleClaimSubheaderType.Matches
                ? `${s.label}${matchesCount}`
                : s.type === SingleClaimSubheaderType.Documents
                ? `${s.label}${documentsCount}`
                : s.label;

            return (
              <ApButtonTab
                key={s.type}
                className={classNames('single-claim-subheader__tab', {
                  selected: tab === s.type,
                })}
                autoFocus={false}
                isSelected={tab === s.type}
                onClick={() => onTabClick(s.type)}
                theme="light"
                disabled={disableEmptyMatchesTab}
              >
                {label}
              </ApButtonTab>
            );
          })}
        </ApButtonGroup>
      </div>
    </div>
  );
};

import {
  ApButtonDanger,
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';
import { URL_PATH } from 'appUrls';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSavedGridState, setSavedGridState } from 'utils/local-storage';
import { useBookmarksData } from '../../hooks/useBookmarksData';
import { BookmarkCardAction, BookmarkCardData } from '../../types';
import { BookmarkBodyWithTextarea } from '../BookmarkBodyWithTextarea';
import './BookmarksCard.css';
import { BookmarksCardContent } from './BookmarksCardContent';

type BookmarkCardProps = {
  bookmark: BookmarkCardData;
};

export const BookmarksCard = ({
  bookmark,
  bookmark: { filter, section, id: bookmarkId = 0 },
}: BookmarkCardProps) => {
  const [hovered, setHovered] = useState(false);
  const [cardAction, setCardAction] = useState('');
  const navigate = useNavigate();
  const {
    handleBookmarkEdit,
    handleBookmarkDelete,
    bookmarkTitle,
    setBookmarkTitle,
  } = useBookmarksData(section);

  const handleOpenBookmark = () => {
    if (!isEmpty(filter))
      setSavedGridState({ ...getSavedGridState(), [section]: filter });
    navigate(`${URL_PATH.CLAIMS}?section=${section}`);
  };

  const handleBookmarkAction = (type: BookmarkCardAction) => {
    if (type === 'delete') handleBookmarkDelete(bookmarkId);
    else if (type === 'edit') handleBookmarkEdit(bookmarkId, bookmarkTitle);
    setCardAction('');
  };

  const handleCardAction = (type: BookmarkCardAction) => {
    setCardAction(type);
    setHovered(false);
  };

  const modalFooter = () => {
    return (
      <ApButtonGroup className="bookmarks-page__popup-footer">
        <ApButtonSecondary onClick={() => setCardAction('')}>
          Cancel
        </ApButtonSecondary>
        {cardAction === 'delete' ? (
          <ApButtonDanger onClick={() => handleBookmarkAction('delete')}>
            Delete bookmark
          </ApButtonDanger>
        ) : (
          <ApButtonMain onClick={() => handleBookmarkAction('edit')}>
            Update
          </ApButtonMain>
        )}
      </ApButtonGroup>
    );
  };

  return (
    <div
      className="bookmarks-page__card"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <BookmarksCardContent
        {...{ hovered, handleOpenBookmark, handleCardAction, bookmark }}
      />

      <ApModal
        isOpen={!!cardAction}
        hasClosed={() => setCardAction('')}
        header={cardAction === 'delete' ? 'Confirm deletion' : 'Edit bookmark'}
        footer={modalFooter}
        isDismissible
      >
        {cardAction === 'delete' ? (
          <p>Are you sure you want to delete this bookmark?</p>
        ) : (
          <BookmarkBodyWithTextarea
            handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setBookmarkTitle(e.target.value)
            }
            label="Edit bookmark title"
            defaultValue={bookmark?.title}
          />
        )}
      </ApModal>
    </div>
  );
};

import React from 'react';
import { ApIcon } from '@alixpartners/ui-components';

export const Dropdown: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ApIcon
      className={className}
      iconName="caret"
      iconColor="var(--ap-color-ui-grey-light)"
    />
  );
};

export const SearchIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <ApIcon
      className={className}
      iconName="search"
      iconColor="var(--ap-color-ui-grey-light)"
    />
  );
};

import Excel, { Column } from 'exceljs';
import { ContractExhibit } from '../exhibityContractType';

export interface ContractExhibitExport extends ContractExhibit {
  fileName: string;
  includeRejDate?: boolean;
}

export interface IExhibitExcelCreator {
  getWorkbook: (exhibit: ContractExhibitExport) => Excel.Workbook | null;
}

const cellStyle: Column['style'] = {
  font: {
    size: 10,
    name: 'Tahoma',
  },
};

const wrappedCellStyle: Column['style'] = {
  ...cellStyle,
  alignment: {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true,
  },
};

export const columnDefaults: Partial<Column> = {
  style: cellStyle,
};

export const wrappedColumnsDefault: Partial<Column> = {
  style: wrappedCellStyle,
};

export const titleStyle: Column['style'] = {
  font: {
    size: 10,
    name: 'Tahoma',
    bold: true,
  },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD9D9D9' },
  },
};

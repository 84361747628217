import 'components/UIComponents/simple-table.css';
import '../general-page.css';

import { ApLoaderDefault } from '@alixpartners/ui-components';
import { SourceType } from 'api/endpoints';
import classNames from 'classnames';
import { SingleEntityMenu } from 'components/SingleEntityMenu';
import { useSubheaderMenuNav } from 'hooks/useSubheaderMenuNav';
import { useMemo } from 'react';
import { CounterpartyHeader } from './CounterpartyHeader';
import {
  ClaimsFullTable,
  ContractsTable,
  FiledClaimsTallyTable,
  ScheduledClaimsTallyTable,
} from './CounterpartyPage.components';
import './CounterpartyPage.css';
import { useCounterpartyData } from './hooks/useCounterpartyData';
import { Layout } from 'components/Layout';
import { filedClaimsKeys, scheduledClaimsKeys } from './Counterparty.const';
import { ClaimTableType } from './Counterparty.types';

export const CounterpartyPage = () => {
  const {
    counterpartyData,
    allClaims,
    allContracts,
    claimTallyItems,
    isLoading,
    section,
  } = useCounterpartyData();

  const { handleBackClick, handleSubMenuClick } = useSubheaderMenuNav(section);

  const [filedClaims, scheduledClaims] = useMemo(
    () => [
      allClaims.filter((r) => r.sourceType === SourceType.FiledClaim),
      allClaims.filter((r) => r.sourceType === SourceType.ScheduledClaim),
    ],
    [allClaims],
  );

  if (!counterpartyData || !claimTallyItems || isLoading) {
    return <ApLoaderDefault />;
  }

  return (
    <Layout className={classNames('counterparty-page')}>
      <SingleEntityMenu
        selected={section}
        onSectionClick={handleSubMenuClick}
        onClickBack={handleBackClick}
      />
      <div className="general-page__outer-container">
        <div className="general-page__inner-container">
          <CounterpartyHeader summary={counterpartyData} />
          <div className="single-claim__sections-container">
            {claimTallyItems.map((tally, index) => {
              switch (tally.type) {
                case SourceType.FiledClaim:
                  return (
                    <FiledClaimsTallyTable
                      key={`tally${index}`}
                      title={`Filed Claim Amounts[${tally.count}]`}
                      items={tally.item}
                    />
                  );
                case SourceType.ScheduledClaim:
                  return (
                    <ScheduledClaimsTallyTable
                      key={`tally${index}`}
                      title={`Scheduled Claim Amounts[${tally.count}]`}
                      items={tally.item}
                    />
                  );
                default:
                  return null;
              }
            })}
            {filedClaims && filedClaims.length > 0 && (
              <ClaimsFullTable
                items={filedClaims}
                title="Filed Claims"
                keys={filedClaimsKeys}
                tableType={ClaimTableType.FILED}
              />
            )}
            {scheduledClaims && scheduledClaims.length > 0 && (
              <ClaimsFullTable
                items={scheduledClaims}
                title="Scheduled Claims"
                keys={scheduledClaimsKeys}
                tableType={ClaimTableType.SCHEDULED}
              />
            )}
            {allContracts && allContracts.length > 0 && (
              <ContractsTable items={allContracts} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

import { ExportPdfResponse } from 'api/endpoints/exportPdf';
import { AccordionSection } from 'components/AccordionSection';
import { ConfirmRedModal } from 'components/ModalWindows/ConfirmRedModal/ConfirmRedModal';
import { IconLoading } from 'components/UIComponents/IconLoading';
import { ExportCancelConfirmModal } from 'pages/SOFAsAndSchedules/Components/ExportCancelConfirmModal';

import { useSofasExportContext } from 'pages/SOFAsAndSchedules/Domain/SofasExportContextProvider';
import { useState } from 'react';
import { SofaExportProgressDetails } from './components/SofaExportProgressDetails';
import { SofaExportQueuedDetails } from './components/SofaExportQueuedDetails';
import './SofaExportInProgress.css';
import { getHeaderText } from './SofaExportInProgressUtils';

type DialogProps = React.ComponentProps<typeof ConfirmRedModal>;

type ConfirmDialog = Pick<DialogProps, 'title' | 'onConfirm'>;

export const SofaExportInProgress = () => {
  const { inProgress, queued, cancelExport } = useSofasExportContext();
  const [dialogCancel, setDialogCancel] = useState<ConfirmDialog>();

  const handleCancelClick = (item: ExportPdfResponse) => {
    const title = `Cancel export "${item.fileName}"?`;

    const onConfirm = async (value: boolean) => {
      if (value) {
        await cancelExport(item);
      }
      setDialogCancel(undefined);
    };

    setDialogCancel({ title, onConfirm });
  };

  const isExporting = !!inProgress?.length;

  return (
    <>
      <AccordionSection
        className="sofa-export__in-progress"
        header={
          <div className="sofa-export__in-progress__header">
            {isExporting && <IconLoading />}
            <span>{getHeaderText(inProgress?.length, queued?.length)}</span>
          </div>
        }
      >
        {inProgress?.map((item, id) => (
          <SofaExportProgressDetails
            key={`sofa-export-${id}`}
            item={item}
            onCancel={handleCancelClick}
          />
        ))}
        {queued && <SofaExportQueuedDetails items={queued} />}
      </AccordionSection>
      {dialogCancel && <ExportCancelConfirmModal {...dialogCancel} />}
    </>
  );
};

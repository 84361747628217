import classNames from 'classnames';
import { Footer } from 'components/Footer';
import { NavBarContainer } from 'components/NavBar';

interface Props {
  className?: string;
  children: React.ReactNode;
  stickyFooter?: boolean;
}

export const Layout = (props: Props) => {
  return (
    <div className={classNames('layout', props.className)}>
      <NavBarContainer />
        {props.children}
      <Footer sticky={props.stickyFooter} />
    </div>
  );
};
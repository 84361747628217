import { useRef } from 'react';
import { SvgIcon } from '../SvgIcon';
import { iconImage } from '../SvgIcon/iconImageList';

import './button.css';

interface IProps {
  color?: string;
  icon?: keyof typeof iconImage;
  width?: number;
  height?: number;
  iconSize?: number;
  onClick?: () => void;
  onChange?: (value: string) => void;
}

export const ButtonColorPicker = ({
  color,
  icon = 'format_color_fill',
  width = 36,
  height = 36,
  iconSize = 24,
  onClick,
  onChange,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnClick = () => {
    inputRef.current?.click();
    onClick?.();
  };

  return (
    <button
      className="c11-buttonicon"
      onClick={handleOnClick}
      style={{ width, height }}
    >
      <div className="c11-buttoncolorpicker">
        <input
          ref={inputRef}
          type="color"
          value={color}
          onChange={(e: React.FormEvent<any>) => {
            onChange?.(e.currentTarget.value);
          }}
        ></input>
        <SvgIcon iconName={icon} width={iconSize} height={iconSize} fill={color} />
      </div>
    </button>
  );
};

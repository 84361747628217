import { ExportPdfResponse } from 'api/endpoints/exportPdf';
import { ApProgressBar } from 'components/ApProgressBar';
import { Animation } from 'components/UIComponents/Animation';
import cubesAnimation from 'components/UIComponents/Animation/cubes.json';
import { ApButtonRed } from 'components/UIComponents/Buttons/ApButtonRed';
import pluralize from 'pluralize';

import './SofaExportProgressDetails.css';

interface Props {
  item: ExportPdfResponse;
  onCancel: (item: ExportPdfResponse) => void;
}

export const SofaExportProgressDetails = ({ item, onCancel }: Props) => {
  const { fileName, totalFiles, processedFiles, createdBy } = item;

  const fileNameText = fileName ? `"${fileName}"` : '';

  return (
    <div className="sofa-export-progress">
      <div className="sofa-export-progress__icon">
        <Animation lottie={cubesAnimation} width={48} height={48} />
      </div>
      <div className="sofa-export-progress__body">
        <div className="sofa-export-progress__filename">
          Exporting {fileNameText} by {createdBy}{' '}
        </div>
        <div className="sofa-export-progress__progress">
          <ApProgressBar progress={processedFiles / totalFiles} />
        </div>
        <div className="sofa-export-progress__details">
          <b>
            {processedFiles} of {totalFiles}
          </b>
          {` ${pluralize('entity', totalFiles)} exported `}
          {/* todo: uncomment the remaining time when it is done on the Back-end */}
          {/* {remainingTime && <b> {remainingTime} min remaining</b>} */}
        </div>
      </div>
      <div className="sofa-export-progress__footer">
        <ApButtonRed onClick={() => onCancel(item)}>Cancel export</ApButtonRed>
      </div>
    </div>
  );
};

import {
  ClaimsData,
  ContractsData,
  SectionType,
} from 'pages/ClaimsContractsToolPage/types';
import { createContext, useContext } from 'react';
import { useBatchUploadBackgroundJob } from './hooks/useBatchUploadBackgroundJob';

type BatchEditContextData = {
  onSubmit: (file: File) => Promise<void>;
  setShowErrorModal: (show: boolean) => void;
  setShowUploadModal: (show: boolean) => void;
  showErrorModal: boolean;
  showUploadModal: boolean;
  isUploading: boolean;
  sectionType: SectionType;
  fileName: string;
  errorLogId?: number;
  setErrorLogId: (id?: number) => void;
};

const BatchEditContext = createContext<BatchEditContextData>(
  {} as BatchEditContextData,
);

export const BatchEditContextProvider = ({
  data,
  children,
  sectionType,
}: {
  data: ClaimsData | ContractsData;
  children: JSX.Element;
  sectionType: SectionType;
}) => {
  const {
    onSubmit,
    setShowErrorModal,
    setShowUploadModal,
    showErrorModal,
    showUploadModal,
    isUploading,
    fileName,
    errorLogId,
    setErrorLogId,
  } = useBatchUploadBackgroundJob(data.refresh, sectionType);

  return (
    <BatchEditContext.Provider
      value={{
        onSubmit,
        setShowErrorModal,
        setShowUploadModal,
        showErrorModal,
        showUploadModal,
        isUploading,
        sectionType,
        fileName,
        errorLogId,
        setErrorLogId,
      }}
    >
      {children}
    </BatchEditContext.Provider>
  );
};

export const useBatchEditContextData = () => useContext(BatchEditContext);

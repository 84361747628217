import { ApLoaderDefault } from '@alixpartners/ui-components';
import { DriveFileAnchor } from 'pages/SingleClaimPage/components/DriveFileAnchor';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { useMemo } from 'react';
import './DocFileLink.css';

export const DocFileLink = (props: {
  name: string;
  loading?: boolean;
  onClick?: () => void;
  isClaimImage?: boolean;
  claimImageUrl?: string;
}) => {
  const { canDownloadFlag } = useClaimAttachmentsContext();
  const { name, loading, onClick, isClaimImage, claimImageUrl } = props;

  const claimImageNewTab = useMemo(
    () => isClaimImage && !canDownloadFlag,
    [isClaimImage, canDownloadFlag],
  );

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (claimImageNewTab) return;
    e.preventDefault();
    onClick?.();
  };

  return loading ? (
    <div className="doc-file-link_loader">
      <ApLoaderDefault loaderSize="sm" />
      {name}
    </div>
  ) : (
    <DriveFileAnchor
      href={`${claimImageNewTab ? claimImageUrl : '_'}`}
      className="simple-table-link"
      onClick={handleClick}
      tabIndex={0}
      rel="noreferrer"
      target={claimImageNewTab ? '_blank' : ''}
    >
      {name}
    </DriveFileAnchor>
  );
};

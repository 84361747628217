import { del, downloadFile, downloadFileGet, get, post } from 'api/apiRequest';

export interface SettlementAgreementResponse {
  id: number;
  name: string;
  displayName: string;
  fileName: string;
  createdBy: string;
  createdDate: string;
}

export interface SettlementAgreementPost {
  name: string;
  displayName: string;
  fileName: string;
}

export type SettlementAgreementId = SettlementAgreementResponse['id'];

export const settlementAgreementTemplates = {
  get: () => get<SettlementAgreementResponse[]>('SettlementAgreementTemplates'),
  post: (params: SettlementAgreementPost) =>
    post('SettlementAgreementTemplates', params),
  delete: (id: SettlementAgreementId) => del(`SettlementAgreementTemplates/${id}`),
  download: (id: SettlementAgreementId, fileName: string) =>
    downloadFile(`SettlementAgreementTemplates/${id}/download`, fileName),
  downloadHelp: () =>
    downloadFile(
      'SettlementAgreementTemplates/help',
      'Settlement-Agreement-Help.docx',
    ),
  downloadSample: () =>
    downloadFile('SettlementAgreementTemplates/sample', 'Agreement-Template.docx'),

  getPreviewBlob: (id: SettlementAgreementId) =>
    downloadFileGet(`SettlementAgreementTemplates/${id}/preview`),
};

export const SCHEDULE_AB_10_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: [
        'Patents',
        'Web',
        'Licenses',
        'Customers',
        'Other',
        'Goodwill',
      ],
      dataType: 'enum',
      displayName: 'Type of Intangible Asset',
      propertyName: 'typeOfIntangibleAsset',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Net Book Value',
      propertyName: 'netBookValue',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Valuation Method',
      propertyName: 'valutationMethod',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Current Value',
      propertyName: 'currentValue',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Identifiable Information',
      propertyName: 'identifiableInformation',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Amortization or Other Similar Schedule',
      propertyName: 'amortizationOrOtherSimilarSchedule',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Appraised in the Last Year',
      propertyName: 'appraisedInLastYear',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

export const SCHEDULE_EF_2_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    // FIELDS GO HERE

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Creditor Name',
      propertyName: 'creditorName',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Date Debt Incurred',
      propertyName: 'dateDebtIncurred',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Last 4 Account Digits',
      propertyName: 'last4AccountDigits',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'SpecifyCodeSubsection',
      propertyName: 'specifyCodeSubsection',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Is the Claim Contingent',
      propertyName: 'isTheClaimContingent',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Is the Claim Unliquidated',
      propertyName: 'isTheClaimUnliquidated',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Is the Claim Disputed',
      propertyName: 'isTheClaimDisputed',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Basis for the Claim',
      propertyName: 'basisForTheClaim',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Subject to Offset',
      propertyName: 'subjectToOffset',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Amount of Claim',
      propertyName: 'amountOfClaim',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Category',
      propertyName: 'category',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

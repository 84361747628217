import {
  ApButtonGroup,
  ApButtonIcon,
  ApButtonMain,
  ApIcon,
} from '@alixpartners/ui-components';
import classNames from 'classnames';
import 'components/UIComponents/css-animations.css';
import { DeleteModal } from 'pages/SettingsPage/sections/UserGroups/components/DeleteModal/DeleteModal';
import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { UseSubmitHandler, useSubmitHandler } from 'utils/form/form-utils';
import { useEntityContext } from './EntityContext';
import './EntityTable.css';
import {
  EntityEditRowProps,
  EntityRowState as Ers,
  IdEntity,
} from './EntityTable.types';
import { useControlledValues } from './useControlledValues';

export const EntityEditRow = <T extends IdEntity>({
  item,
  add,
  hasDeleteRow,
}: EntityEditRowProps<T>) => {
  const formRef: LegacyRef<any> = useRef();
  const {
    onEditSave,
    editColumnKeys,
    inputComponent: InputComp,
  } = useEntityContext();
  const [expanded, setExpanded] = useState<Ers>(Ers.Initial);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const { controlledValues, setValue, getValue, setValidity, setControlledValues } =
    useControlledValues();
  const [originalValues, setOriginalValues] =
    useState<Record<string, any> | undefined>(undefined);

  const disabledConditions = useMemo(
    () => (item as any)?.usedInClaims || (item as any)?.usedInContracts,
    [item],
  );
  const inputsDisabled = expanded !== Ers.Expading || disabledConditions;

  const { showCategoriesInfoMessage, categoriesInfoTitle } = useMemo(
    () => ({
      showCategoriesInfoMessage:
        (item as any)?.usedInClaims || (item as any)?.usedInContracts,
      categoriesInfoTitle: 'Category in use',
    }),
    [item],
  );

  const handleSave: UseSubmitHandler = useCallback(
    (_, { validState }, formValues) => {
      setError(null);
      if (validState === 'invalid') return;
      setExpanded(Ers.Saving);
      const values = {
        ...formValues,
        ...controlledValues,
      };

      onEditSave(values, item).then((result) => {
        if (result?.type === FetchStatus.Error) {
          setError(result.error);
          setExpanded(Ers.Expading);
        } else {
          setError(null);
          setExpanded(Ers.Collapsing);
        }
      });
    },
    [item, onEditSave, controlledValues],
  );
  const { handleSubmit, validState } = useSubmitHandler(handleSave);

  const cn = classNames('dynamicfields__row-el transition-expand utils-form', {
    trigger: expanded === Ers.Expading || expanded === Ers.Saving,
    'utils-form-invalid': validState === 'invalid',
    'dynamicfields__row-el-expanded':
      expanded === Ers.Expading || expanded === Ers.Saving,
    'dynamicfields__row-el-saving utils-obstruct': expanded === Ers.Saving,
    'dynamicfields__row-el-collapsed': expanded === Ers.Collapsing,
  });

  const handleEdit = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault();
      setOriginalValues(controlledValues);
      setExpanded(Ers.Expading);
    },
    [controlledValues],
  );

  const handleReset: React.FormEventHandler<HTMLFormElement> = useCallback(() => {
    setControlledValues(originalValues!);
    setError(null);
    setExpanded(Ers.Collapsing);
  }, [setControlledValues, originalValues]);

  const showSave = expanded === Ers.Expading || expanded === Ers.Saving;
  const saveWrapperCn = classNames('entity-table__action', {
    'utils-none': !showSave,
  });

  const saveCn = classNames('dynamicfields__save');
  const editCn = classNames('dynamicfields__edit entity-table__action', {
    'utils-none': showSave,
  });
  const deleteCn = classNames('dynamicfields__delete', {
    'utils-none': showSave,
  });
  const resetCn = classNames(
    'ui-button-reset entity-table__reset dynamicfields__edit',
    {
      'utils-none': !showSave || expanded === Ers.Saving,
    },
  );

  const handleToggleClick = () => {
    if (formRef.current) {
      const submitButton = formRef.current.querySelector('button[type="submit"]');
      submitButton.click();
    }
  };

  // For removing default tooltip from Label title in ApToggleSwitch component
  useEffect(() => {
    const labelElement = document.querySelector('label[title]');

    if (labelElement) {
      labelElement.removeAttribute('title');
    }
  }, [editColumnKeys, expanded]);

  return (
    <>
      <form
        ref={formRef}
        action="#"
        autoComplete="off"
        noValidate
        className={cn}
        onReset={handleReset}
        onSubmit={handleSubmit}
      >
        {editColumnKeys.map((ck) => {
          const isToggleInput = (columnKey: keyof T): columnKey is 'id' =>
            columnKey === 'enabled';

          return (
            <div
              key={ck}
              className={`dynamicfields__cell ${
                add ? 'dynamicfields__cell-add' : 'dynamicfields__cell-edit'
              }`}
            >
              <InputComp
                columnKey={ck}
                item={item}
                fromTable={{
                  operation: 'edit',
                  setValidity,
                  setValue,
                  getValue,
                  disabled:
                    // inputs with toggle or empty dynamic fields  are not disabled so you can toggle them w/o clicking 'Edit'
                    ((item as any)?.propertyName?.includes('custom') &&
                      !(item as any)?.name) ||
                    (isToggleInput(ck) && !disabledConditions)
                      ? false
                      : inputsDisabled,
                  readonly: !expanded,
                  className: 'dynamicfields__cell__body',
                  showHoverData:
                    expanded === Ers.Initial || expanded === Ers.Collapsing,
                }}
                handleToggleChange={handleToggleClick}
              />
            </div>
          );
        })}
        <span className={saveWrapperCn}>
          <ApButtonMain type="submit" className={saveCn}>
            Save
          </ApButtonMain>
        </span>

        {showCategoriesInfoMessage ? (
          <div className="edit-row-info" title={categoriesInfoTitle}>
            <ApIcon iconName="error" iconColor="#D97706" />
          </div>
        ) : (
          <ApButtonGroup className="edit-row-buttons">
            {!(item as any)?.hideEdit && (
              <ApButtonIcon
                className={editCn}
                type="button"
                iconName="create"
                onClick={handleEdit}
              />
            )}
            {hasDeleteRow && (
              <ApButtonIcon
                iconName="delete_outline"
                className={deleteCn}
                type="button"
                onClick={() => setDeleteModalOpened(true)}
              />
            )}
          </ApButtonGroup>
        )}

        <button type="reset" className={resetCn}>
          x
        </button>
        {error && (
          <p className="anim-appear entity-table__error-row entity-table__error-row__edit">
            {error.message}
          </p>
        )}
      </form>
      {hasDeleteRow && (
        <DeleteModal
          deleteModalOpened={deleteModalOpened}
          item={item as any}
          setDeleteModalOpened={setDeleteModalOpened}
        />
      )}
    </>
  );
};

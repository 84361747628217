import { useCallback, useMemo, useState } from 'react';
import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import {
  ContractAttachment,
  ContractAttachmentDocumentType,
  ContractAttachmentPrimaryParam,
  contractAttachments,
  CreateContractAttachmentParams,
  PostError,
} from 'api/endpoints/contractAttachments';
import { ErrorResponse, ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { SubmitData } from 'components/ModalWindows/UploadModal/UploadModal';

import {
  generateContractAttachmentDownloadUrl,
  uploadContractAttachment,
} from 'utils/uploader';

export type ContractAttachmentWithUrl = ContractAttachment & {
  downloadUrl: string;
  newlyAdded?: boolean;
};

type UploadOkResult = {
  type: 'ok';
  result: { name: string; url: string };
  entry: SubmitData['entries'][0];
};

function showAttachmentsUploadError(
  contractId: number,
  response: ErrorResponse<PostError>,
) {
  const { type } = response;
  let title = 'An unexpected error occured.';
  let messages = ['Please contact your IT administrator if the problem persists.'];
  if (type === ResponseType.BAD_REQ) {
    if (response.data.status === 404) {
      title = `Contract with id "${contractId}" was not found.`;
      messages = [];
    } else if (response.data.status === 400) {
      const badItems = response.data.error;
      title = `Several documents ecountered errors:`;
      messages = badItems.map((bi) => bi.error);
    }
  }

  showHttpSingleError({
    title,
    messages,
  });
}

function attachmentRespToAttachmentWithUrl(
  at: ContractAttachment,
  newlyAdded: boolean,
): ContractAttachmentWithUrl {
  return {
    ...at,
    newlyAdded: newlyAdded,
    downloadUrl: generateContractAttachmentDownloadUrl(at.id),
    // at the moment of writing server does not return a indication for the timezone
  };
}

const getContractAttatchments = async (
  contractId: number,
): Promise<ContractAttachmentWithUrl[]> => {
  const response = await contractAttachments.getDocuments(contractId);
  if (response.type === ResponseType.OK) {
    return response.data.map((at) => attachmentRespToAttachmentWithUrl(at, false));
  }

  showAttachmentsUploadError(contractId, response);
  return [];
};

const postContractAttatchments = async (
  params: CreateContractAttachmentParams,
): Promise<ContractAttachmentWithUrl[] | null> => {
  const response = await contractAttachments.postDocument(params);
  const { type } = response;

  if (type === ResponseType.OK) {
    return response.data.map((at) => attachmentRespToAttachmentWithUrl(at, true));
  }

  showAttachmentsUploadError(params.contractId, response);
  return null;
};

const MAX_BLOB_UPLOAD_PROGRESS_RATIO = 0.9;
const MIN_BLOB_UPLOAD_PROGRESS_RATIO = 0.1;
const DELTA_BLOB_UPLOAD_PROGRESS_RATIO =
  MAX_BLOB_UPLOAD_PROGRESS_RATIO - MIN_BLOB_UPLOAD_PROGRESS_RATIO;

export function useContractAttachments(contractId: number) {
  const [attachments, setAttachments] = useState<ContractAttachmentWithUrl[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [deletingDoc, setDeletingDoc] = useState(false);

  const [uploadStatus, setUploadStatus] = useState<{ progress: number }>({
    progress: -1,
  });

  const fetchAttachmentsList = useCallback(async () => {
    const serverAttachments = await getContractAttatchments(contractId);
    setAttachments(serverAttachments ?? []);
  }, [contractId]);

  const loadList = useCallback(async () => {
    setIsDataLoading(true);
    await fetchAttachmentsList();
    setIsDataLoading(false);
  }, [fetchAttachmentsList]);

  const setPrimaryDocument = useCallback(
    async (param: ContractAttachmentPrimaryParam) => {
      await contractAttachments.setPrimaryDocument(param);
      await fetchAttachmentsList();
    },
    [fetchAttachmentsList],
  );

  const addAttachments = useCallback(
    async (data: SubmitData) => {
      const totalSize = data.entries.reduce((acc, crt) => acc + crt.file.size, 0);
      const totalCount = data.entries.length;
      const comment = data.comment ?? '';

      let totalLoaded = 0;

      setUploadStatus({ progress: MIN_BLOB_UPLOAD_PROGRESS_RATIO });

      const uploadPromises = data.entries.map(async (entry) => {
        try {
          let prevLoaded = 0;
          const result = await uploadContractAttachment(
            entry.file,
            contractId,
            (loaded) => {
              const delta = loaded - prevLoaded;
              totalLoaded = totalLoaded + delta;
              const ratio =
                MIN_BLOB_UPLOAD_PROGRESS_RATIO +
                (totalLoaded / totalSize) * DELTA_BLOB_UPLOAD_PROGRESS_RATIO;

              setUploadStatus({ progress: ratio });
              prevLoaded = loaded;
            },
          );
          if (result) {
            return { type: 'ok', result, entry };
          }
          throw new Error('No result.');
        } catch (error) {
          return { type: 'fail', error, entry };
        }
      });

      const uploadResults = await Promise.all(uploadPromises);
      // TODO also output the errors
      const okResults = uploadResults.filter(
        (u) => u.type === 'ok',
      ) as UploadOkResult[];

      setUploadStatus({
        progress: MAX_BLOB_UPLOAD_PROGRESS_RATIO + (5 - (totalCount % 5)) * 0.01,
      });

      if (okResults.length) {
        const items = getAttachmentsItems(contractId, okResults, comment);
        await postContractAttatchments(items);
      }

      await fetchAttachmentsList();

      setUploadStatus({ progress: -1 });
    },
    [contractId, fetchAttachmentsList],
  );

  const deleteAttachment = useCallback(
    async (id: number) => {
      setDeletingDoc(true);
      await contractAttachments.deleteDocument(id);
      await fetchAttachmentsList();
      setDeletingDoc(false);
    },
    [fetchAttachmentsList],
  );

  const { fistImage, primaryDoc } = useMemo(() => {
    const imageType = ContractAttachmentDocumentType.ContractImage;
    return {
      fistImage: attachments.find(
        (item) => item.contractDocumentTypeId === imageType,
      ),
      primaryDoc: attachments.find((item) => item.isPrimaryDocument),
    };
  }, [attachments]);

  return useMemo(
    () => ({
      attachments,
      fistImage,
      primaryDoc,
      isDataLoading,
      uploadStatus,
      loadList,
      setPrimaryDocument,
      addAttachments,
      deleteAttachment,
      deletingDoc,
    }),
    [
      attachments,
      fistImage,
      primaryDoc,
      isDataLoading,
      uploadStatus,
      loadList,
      setPrimaryDocument,
      addAttachments,
      deleteAttachment,
      deletingDoc,
    ],
  );
}

const getAttachmentsItems = (
  contractId: number,
  okResults: UploadOkResult[],
  comment: string,
): CreateContractAttachmentParams => {
  const contractUploadDocuments = okResults.map(({ entry, result }) => {
    return {
      referenceNumber: entry.referenceNumber ?? '',
      documentName: result.name,
      displayName: entry.file.name,
      contractDocumentTypeId: +entry.documentType,
      comment,
    };
  });

  return { contractId, contractUploadDocuments };
};

import { FileStatus, IUploadItem, IUploadSection } from 'types/dataUploaderTypes';

export enum eUploadStatus {
  None = 'None',
  Unpublished = 'Unpublished',
  UploadedAll = 'UploadedAll',
  Error = 'Error',
}

export interface IMenuItem extends IUploadSection {
  status: eUploadStatus;
}

export const getStatusMapper =
  (fileUploaded: IUploadItem[]) => (section: IUploadSection) => {
    const status = getUploadStatus(section, fileUploaded);
    return { ...section, status };
  };

const getUploadStatus = (
  section: IUploadSection,
  fileUploaded: IUploadItem[],
): eUploadStatus => {
  const sectionFiles = fileUploaded.filter((r) => r.type === section.type);

  const isEmpty = !sectionFiles.length;
  if (isEmpty) return eUploadStatus.None;

  const isErrors = sectionFiles.some((r) => !!r.errors);
  if (isErrors) return eUploadStatus.Error;

  const inUnpublish = sectionFiles.some((r) => r.status !== FileStatus.Published);
  if (inUnpublish) return eUploadStatus.Unpublished;

  const isAllPublished = section.requiredFiles <= sectionFiles.length;
  if (isAllPublished) return eUploadStatus.UploadedAll;

  return eUploadStatus.None;
};

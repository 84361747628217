import _ from 'lodash';
import AzureStorageClient from 'utils/azureStorageClient';
import { filenameSanitizer } from 'utils/file';
import { v4 as uuidv4 } from 'uuid';

export type AzureUploadResult = {
  type: 'ok' | 'fail';
  fileName?: string;
  error?: string;
};

export const azureStorageUpload = async (
  azureClient: AzureStorageClient,
  file: File,
  folder?: string,
  onProgress?: (loaded: number) => unknown,
): Promise<AzureUploadResult> => {
  try {
    const { fileUniqueName, fileFullPath } = createAzureFileName({
      file: file.name,
      folder,
    });

    await azureClient.upload(file, fileFullPath, {
      onProgress: ({ loadedBytes }) => onProgress?.(file.size / loadedBytes),
    });

    return { type: 'ok', fileName: fileUniqueName };
  } catch (e: any) {
    return { type: 'fail', error: e?.message };
  }
};

function createAzureFileName(props: { file: string; folder?: string }) {
  const folderPath = !_.isEmpty(props.folder) ? `${props.folder}/` : '';
  const fileNameValid = filenameSanitizer(props.file);

  const fileUniqueName = `${uuidv4()}_${fileNameValid}`;
  const fileFullPath = `${folderPath}${fileUniqueName}`;

  return { fileUniqueName, fileFullPath };
}

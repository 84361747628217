import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';

interface Props {
  title: string;
  body: React.ReactChild;
  isOpen?: boolean;
  handleConfirm: (confirm: boolean) => void;
}

export const ConfirmModal = ({
  title,
  body,
  isOpen = true,
  handleConfirm,
}: Props) => {
  const handleClick = (value: boolean) => () => handleConfirm(value);

  return (
    <ApModal
      header={title}
      isDismissible
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
      hasClosed={handleClick(false)}
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonSecondary onClick={handleClick(false)}>Cancel</ApButtonSecondary>
          <ApButtonMain onClick={handleClick(true)}>Ok</ApButtonMain>
        </ApButtonGroup>
      )}
    >
      <div>{body}</div>
    </ApModal>
  );
};

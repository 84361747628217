export interface ISvgIcon {
  data: JSX.Element;
  width?: number;
  height?: number;
}

export const iconImage = {
  // check alert_3 and see if this can be reverted
  alert_amber: {
    data: (
      <path
        d="M6 1.995L9.765 8.5H2.235L6 1.995ZM6 0L0.5 9.5H11.5L6 0ZM6.5 7H5.5V8H6.5V7ZM6.5 4H5.5V6H6.5V4Z"
        fill="#D97706"
      />
    ),
  },

  format_color_fill: {
    data: (
      <>
        <path
          fill="currentColor"
          d="M16.56 8.94 7.62 0 6.21 1.41l2.38 2.38-5.15 5.15a1.49 1.49 0 0 0 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12zM5.21 10 10 5.21 14.79 10H5.21zM19 11.5s-2 2.17-2 3.5c0 1.1.9 2 2 2s2-.9 2-2c0-1.33-2-3.5-2-3.5zM2 20h20v4H2v-4z"
        />
        <path d="M2 20h20v4H2v-4z" />
      </>
    ),
    width: 22,
    height: 24,
  },

  arrow_right: {
    data: (
      <path d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z" />
    ),
    width: 22,
    height: 14,
  },

  arrow_right_compact: {
    data: (
      <path
        fill="currentColor"
        d="M7.99996 3.16675L7.05996 4.10675L10.78 7.83341H2.66663V9.16675H10.78L7.05996 12.8934L7.99996 13.8334L13.3333 8.50008L7.99996 3.16675Z"
      />
    ),
    width: 16,
    height: 17,
  },

  caret: {
    data: (
      <path
        fill="currentColor"
        d="M17.65 12.6831L10 5.04977L2.35 12.6831L2.05443e-07 10.3331L10 0.333105L20 10.3331L17.65 12.6831Z"
      />
    ),
    width: 20,
    height: 13,
  },

  caret_inv: {
    data: (
      <path
        fill="currentColor"
        d="M2.35 0.316895L10 7.95023L17.65 0.316895L20 2.66689L10 12.6669L0 2.66689L2.35 0.316895Z"
      />
    ),
    width: 20,
    height: 13,
  },

  trash: {
    data: (
      <>
        <g fill="currentColor">
          <g>
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"></path>
          </g>
        </g>
      </>
    ),
    width: 26,
    height: 24,
  },
  dynamicFeed: {
    data: (
      <g>
        <path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z" />
        <path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z" />
        <path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z" />
      </g>
    ),
    width: 24,
    height: 24,
  },

  arrow_down: {
    data: (
      <path
        d="M0.94 0.726685L4 3.78002L7.06 0.726685L8 1.66668L4 5.66668L0 1.66668L0.94 0.726685Z"
        fill="currentColor"
      />
    ),
    width: 8,
    height: 6,
  },
  arrow_up: {
    data: (
      <path
        d="M4 0.333496L0 4.3335L0.94 5.2735L4 2.22016L7.06 5.2735L8 4.3335L4 0.333496Z"
        fill="currentColor"
      />
    ),
    width: 8,
    height: 6,
  },
  chevron_left: {
    data: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51825 9.99982L13.6582 15.2468C14.0743 15.6108 14.1163 16.2428 13.7522 16.6578C13.3882 17.0738 12.7563 17.1158 12.3413 16.7528L6.34125 10.7518C5.88625 10.3538 5.88625 9.64582 6.34125 9.24782L12.3413 3.24782C12.7563 2.88382 13.3882 2.92582 13.7522 3.34082C14.1163 3.75682 14.0743 4.38882 13.6582 4.75182L8.51825 9.99982Z"
      />
    ),
    width: 20,
    height: 20,
  },
  drive_file_icon: {
    data: (
      <>
        <g clipPath="url(#clip0_3819_178083)">
          <path d="M10.5 1.5H4.5C3.675 1.5 3.0075 2.175 3.0075 3L3 15C3 15.825 3.6675 16.5 4.4925 16.5H13.5C14.325 16.5 15 15.825 15 15V6L10.5 1.5ZM4.5 15V3H9.75V6.75H13.5V15H4.5Z" />
        </g>
        <defs>
          <clipPath id="clip0_3819_178083">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </>
    ),
    width: 18,
    height: 18,
  },
  copy: {
    data: (
      <path
        d="M9.66634 0.666687H1.66634C0.933008 0.666687 0.333008 1.26669 0.333008 2.00002V11.3334H1.66634V2.00002H9.66634V0.666687ZM8.99967 3.33335H4.33301C3.59967 3.33335 3.00634 3.93335 3.00634 4.66669L2.99967 14C2.99967 14.7334 3.59301 15.3334 4.32634 15.3334H11.6663C12.3997 15.3334 12.9997 14.7334 12.9997 14V7.33335L8.99967 3.33335ZM4.33301 14V4.66669H8.33301V8.00002H11.6663V14H4.33301Z"
        fill="#1A1A1A"
      />
    ),
    width: 25,
    height: 25,
  },
  download_white: {
    data: (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.39333 4.72667L6.66667 6.44667V0H5.33333V6.44667L3.60667 4.72667L2.66667 5.66667L6 9L9.33333 5.66667L8.39333 4.72667ZM10.6667 10.6667V6H12V10.6667C12 11.4 11.4 12 10.6667 12H1.33333C0.6 12 0 11.4 0 10.6667V6H1.33333V10.6667H10.6667Z"
        fill="white"
      />
    ),
    width: 12,
    height: 12,
  },
  download_colorless: {
    data: (
      <path d="M9.66634 4H6.99967V0H2.99967V4H0.333008L4.99967 8.66667L9.66634 4ZM4.33301 5.33333V1.33333H5.66634V5.33333H6.44634L4.99967 6.78L3.55301 5.33333H4.33301ZM0.333008 10H9.66634V11.3333H0.333008V10Z" />
    ),
    width: 16,
    height: 16,
  },

  upload: {
    data: (
      <path d="M4.16663 3.33301H15.8333V4.99967H4.16663V3.33301ZM4.16663 11.6663H7.49996V16.6663H12.5V11.6663H15.8333L9.99996 5.83301L4.16663 11.6663ZM10.8333 9.99967V14.9997H9.16663V9.99967H8.19163L9.99996 8.19134L11.8083 9.99967H10.8333Z" />
    ),
    width: 20,
    height: 20,
  },
  download_file: {
    data: (
      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z" />
    ),
    width: 24,
    height: 24,
  },
  spinner: {
    data: (
      <path
        fill="currentColor"
        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
      />
    ),
    width: 20,
    height: 20,
  },
  delete: {
    data: (
      <path
        d="M7.66634 4V10.6667H2.33301V4H7.66634ZM6.66634 0H3.33301L2.66634 0.666667H0.333008V2H9.66634V0.666667H7.33301L6.66634 0ZM8.99967 2.66667H0.999674V10.6667C0.999674 11.4 1.59967 12 2.33301 12H7.66634C8.39967 12 8.99967 11.4 8.99967 10.6667V2.66667Z"
        fill="#1A1A1A"
      />
    ),
    width: 20,
    height: 20,
  },
  svgImageFile: {
    data: (
      <g fill="var(--ap-color-ui-grey-light)" stroke="">
        <path d="M19.35,9.04 C18.67,5.59 15.64,3 12,3 C9.11,3 6.6,4.64 5.35,7.04 C2.34,7.36 0,9.91 0,13 C0,16.31 2.69,19 6,19 L19,19 C21.76,19 24,16.76 24,14 C24,11.36 21.95,9.22 19.35,9.04 Z M19,17 L6,17 C3.79,17 2,15.21 2,13 C2,10.79 3.79,9 6,9 L6.71,9 C7.37,6.69 9.48,5 12,5 C15.04,5 17.5,7.46 17.5,10.5 L17.5,11 L19,11 C20.66,11 22,12.34 22,14 C22,15.66 20.66,17 19,17 Z M8,12 L10.55,12 L10.55,15 L13.45,15 L13.45,12 L16,12 L12,8 L8,12 Z"></path>
      </g>
    ),
    height: 24,
    width: 24,
  },
  person: {
    data: (
      <path
        d="M4.50002 1.24996C5.09585 1.24996 5.58335 1.73746 5.58335 2.33329C5.58335 2.92913 5.09585 3.41663 4.50002 3.41663C3.90419 3.41663 3.41669 2.92913 3.41669 2.33329C3.41669 1.73746 3.90419 1.24996 4.50002 1.24996ZM4.50002 6.66663C5.96252 6.66663 7.64169 7.36538 7.75002 7.74996H1.25002C1.3746 7.35996 3.04294 6.66663 4.50002 6.66663ZM4.50002 0.166626C3.30294 0.166626 2.33335 1.13621 2.33335 2.33329C2.33335 3.53038 3.30294 4.49996 4.50002 4.49996C5.6971 4.49996 6.66669 3.53038 6.66669 2.33329C6.66669 1.13621 5.6971 0.166626 4.50002 0.166626ZM4.50002 5.58329C3.05377 5.58329 0.166687 6.30913 0.166687 7.74996V8.83329H8.83335V7.74996C8.83335 6.30913 5.94627 5.58329 4.50002 5.58329Z"
        fill="#1A1A1A"
      />
    ),
    height: 9,
    width: 9,
  },
  pin: {
    data: (
      <path d="M7.5 2V5.75C7.5 6.59 7.7775 7.37 8.25 8H3.75C4.2375 7.355 4.5 6.575 4.5 5.75V2H7.5ZM9.75 0.5H2.25C1.8375 0.5 1.5 0.8375 1.5 1.25C1.5 1.6625 1.8375 2 2.25 2H3V5.75C3 6.995 1.995 8 0.75 8V9.5H5.2275V14.75L5.9775 15.5L6.7275 14.75V9.5H11.25V8C10.005 8 9 6.995 9 5.75V2H9.75C10.1625 2 10.5 1.6625 10.5 1.25C10.5 0.8375 10.1625 0.5 9.75 0.5Z" />
    ),
    width: 12,
    height: 16,
  },
  columns: {
    data: (
      <path d="M0 .75v9.75h12.75V.75H0Zm7.5 1.5V9H5.25V2.25H7.5Zm-6 0h2.25V9H1.5V2.25ZM11.25 9H9V2.25h2.25V9Z" />
    ),
    width: 13,
    height: 11,
  },
  // arrow_drop_down exists in ApIcon library but it is 24 by 24 and inefficient path
  triangle_chevron: {
    data: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.5.5-5 5-5-5"
        fill="#737373"
      />
    ),
    width: 11,
    height: 6,
  },
  check_green: {
    data: (
      <g fill="none">
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="#5CB335" />
        <path
          d="M14.75 6.25L13.25 4.75L7.76 10.25L4.75 7.25L3.25 8.75L7.76 13.25L14.75 6.25Z"
          fill="white"
        />
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#5CB335" />
      </g>
    ),
    width: 19,
    height: 19,
  },
  cancel_red: {
    data: (
      <g fill="none">
        <path
          d="M9.5 7.59L12.09 5L13.5 6.41L10.91 9L13.5 11.59L12.09 13L9.5 10.41L6.91 13L5.5 11.59L8.09 9L5.5 6.41L6.91 5L9.5 7.59Z"
          fill="#EE3A36"
        />
        <rect x="1" y="0.5" width="17" height="17" rx="8.5" stroke="#EE3A36" />
      </g>
    ),
    width: 19,
    height: 19,
  },
  check_white: {
    data: (
      <path
        d="m11.75 2.25-1.5-1.5-5.49 5.5-3.01-3-1.5 1.5 4.51 4.5 6.99-7Z"
        fill="#5CB335"
      />
    ),
    width: 12,
    height: 10,
  },
  cancel_white: {
    data: (
      <path
        d="M4 2.59L6.59 0L8 1.41L5.41 4L8 6.59L6.59 8L4 5.41L1.41 8L0 6.59L2.59 4L0 1.41L1.41 0L4 2.59Z"
        fill="#fff"
      />
    ),
    width: 8,
    height: 8,
  },
  cancel_big: {
    data: (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.8334 5.34166L14.6584 4.16666L10.0001 8.82499L5.34175 4.16666L4.16675 5.34166L8.82508 9.99999L4.16675 14.6583L5.34175 15.8333L10.0001 11.175L14.6584 15.8333L15.8334 14.6583L11.1751 9.99999L15.8334 5.34166Z"
        fill="#254715"
      />
    ),
  },
  save: {
    data: (
      <path
        fill="#fff"
        d="M11.667.5h-10C.742.5 0 1.25 0 2.167v11.666C0 14.75.742 15.5 1.667 15.5h11.666c.917 0 1.667-.75 1.667-1.667v-10L11.667.5Zm1.666 13.333H1.667V2.167h9.308l2.358 2.358v9.308ZM7.5 8A2.497 2.497 0 0 0 5 10.5C5 11.883 6.117 13 7.5 13s2.5-1.117 2.5-2.5S8.883 8 7.5 8Zm-5-5H10v3.333H2.5V3Z"
      />
    ),
    width: 16,
    height: 16,
  },
  return_turret: {
    data: (
      <>
        <g clip-path="url(#a)">
          <path
            fill="#333"
            d="m9.5 7.5-3 3-.71-.71L7.585 8H2V2h1v5h4.585L5.79 5.21l.71-.71 3 3Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h12v12H0z" />
          </clipPath>
        </defs>
      </>
    ),
    width: 12,
    height: 12,
  },
  open_link: {
    data: (
      <path d="M16 16H2V2h7V0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V9h-2v7ZM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7Z" />
    ),
    width: 18,
    height: 18,
  },
  edit: {
    data: (
      <>
        <path id="background" fill="none" d="M0 0L24 0 24 24 0 24z"></path>
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z"></path>
      </>
    ),
    width: 24,
    height: 24,
  },
  alert: {
    data: (
      <path d="M0,14.2499995 L16.5,14.2499995 L8.25,0 L0,14.2499995 Z M9.0000001,11.9999999 L7.50000006,11.9999999 L7.50000006,10.4999998 L9.0000001,10.4999998 L9.0000001,11.9999999 Z M9.0000001,9.00000027 L7.50000006,9.00000027 L7.50000006,6.00000018 L9.0000001,6.00000018 L9.0000001,9.00000027 Z" />
    ),
    width: 18,
    height: 15,
  },
  comment: {
    data: (
      <path
        d="M13.6 2V11.8775L12.7225 11H1.59998V2H13.6ZM13.6 0.5H1.59998C0.774976 0.5 0.0999756 1.175 0.0999756 2V11C0.0999756 11.825 0.774976 12.5 1.59998 12.5H12.1L15.1 15.5V2C15.1 1.175 14.425 0.5 13.6 0.5ZM12.1 8H3.09998V9.5H12.1V8ZM12.1 5.75H3.09998V7.25H12.1V5.75ZM12.1 3.5H3.09998V5H12.1V3.5Z"
        fill="#333333"
      />
    ),
    width: 16,
    height: 16,
  },
  text_snippet: {
    data: (
      <path d="M14.17 5L19 9.83V19H5V5H14.17ZM14.17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9.83C21 9.3 20.79 8.79 20.41 8.42L15.58 3.59C15.21 3.21 14.7 3 14.17 3ZM7 15H17V17H7V15ZM7 11H17V13H7V11ZM7 7H14V9H7V7Z" />
    ),
    width: 24,
    height: 24,
  },
  exclam_circle: {
    data: (
      <path d="M7.33331 9.99992H8.66665V11.3333H7.33331V9.99992ZM7.33331 4.66659H8.66665V8.66659H7.33331V4.66659ZM7.99331 1.33325C4.31331 1.33325 1.33331 4.31992 1.33331 7.99992C1.33331 11.6799 4.31331 14.6666 7.99331 14.6666C11.68 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.68 1.33325 7.99331 1.33325ZM7.99998 13.3333C5.05331 13.3333 2.66665 10.9466 2.66665 7.99992C2.66665 5.05325 5.05331 2.66659 7.99998 2.66659C10.9466 2.66659 13.3333 5.05325 13.3333 7.99992C13.3333 10.9466 10.9466 13.3333 7.99998 13.3333Z" />
    ),
    width: 16,
    height: 16,
  },
  reset: {
    data: (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.99996 3.99996V5.99996L10.6666 3.33329L7.99996 0.666626V2.66663C5.05329 2.66663 2.66663 5.05329 2.66663 7.99996C2.66663 9.04663 2.97329 10.02 3.49329 10.84L4.46663 9.86663C4.16663 9.31329 3.99996 8.67329 3.99996 7.99996C3.99996 5.79329 5.79329 3.99996 7.99996 3.99996ZM12.5066 5.15996L11.5333 6.13329C11.8266 6.69329 12 7.32663 12 7.99996C12 10.2066 10.2066 12 7.99996 12V9.99996L5.33329 12.6666L7.99996 15.3333V13.3333C10.9466 13.3333 13.3333 10.9466 13.3333 7.99996C13.3333 6.95329 13.0266 5.97996 12.5066 5.15996V5.15996Z"
      />
    ),
    width: 16,
    height: 16,
  },
  check: {
    data: (
      <>
        <path id="background" fill="none" d="M0 0L24 0 24 24 0 24z"></path>
        <path
          data-name="outline_check_circle_outline"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
        />
      </>
    ),
    width: 22,
    height: 23,
  },

  stop: {
    data: (
      <>
        <path id="background" fill="none" d="M0 0L24 0 24 24 0 24z"></path>
        <path
          data-name="highlight_off"
          d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        ></path>
      </>
    ),
    width: 22,
    height: 23,
  },
  terminate: {
    data: (
      <path d="M14.07 3.43L15.66 4.84C17.1 6.29 18 8.29 18 10.5C18 14.92 14.42 18.5 10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.42 5.05 3.06 9 2.57V4.59C6.16 5.07 4 7.53 4 10.5C4 13.81 6.69 16.5 10 16.5C13.31 16.5 16 13.81 16 10.5C16 8.84 15.33 7.34 14.24 6.26L12.83 7.67C13.55 8.4 14 9.4 14 10.5C14 12.71 12.21 14.5 10 14.5C7.79 14.5 6 12.71 6 10.5C6 8.64 7.28 7.09 9 6.64V8.78C8.4 9.13 8 9.76 8 10.5C8 11.6 8.9 12.5 10 12.5C11.1 12.5 12 11.6 12 10.5C12 9.76 11.6 9.12 11 8.78V0.5H10C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 7.74 18.88 5.24 17.07 3.43Z" />
    ),
    width: 18,
    height: 23,
  },
};

import { EMPTY_OPTION_VALUE } from 'components/ApNiceSelect';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { WarningText } from 'components/UIComponents/WarningText';
import _ from 'lodash';
import { SettlementsAgreemantPreview } from 'pages/ClaimsSettlementsPage/components/SettlementsAgreemantPreview';
import { Link } from 'react-router-dom';
import { settlementStatusNames } from 'utils/settlements/settlementType';
import { SettlementAmountGrid } from '../../../../components/SettlementAmountGrid';
import {
  SingleInputWithLabel,
  SingleSelectWithLabel,
  SingleTextfieldWithLabel,
} from '../../../../components/SingleInputWithLabel';
import { SingleViewFieldsList } from '../../../../components/SingleViewFieldsList';
import { SingleViewPanel } from '../../../../components/SingleViewPanel';
import { SingleSettlement } from '../../api/apiSettlementSingle';
import { useSettlementSingleContext } from '../../dataProvider/SettlementSingleContextProvider';
import './SettlementSingleForm.css';

const urlSettlementTemplates =
  '/settings?item=Settlements&subItem=settlements-templates';

const statusOptionItems = _(settlementStatusNames)
  .toPairs()
  .map(([value, label]) => ({ label, value }))
  .value();

export const SettlementSingleForm = () => {
  const { data, userList, agreemantDocument, template, setFieldValue } =
    useSettlementSingleContext();

  const handleConterSignerChange =
    (field: keyof SingleSettlement) => (value: string) => {
      const fixedValue = value === EMPTY_OPTION_VALUE ? '' : value;
      setFieldValue(field)(fixedValue);
    };

  return (
    <div className="settlement-single-form">
      <SingleViewPanel title="ASSIGMENT">
        <SingleViewFieldsList>
          <SingleSelectWithLabel
            label="Client:"
            value={data?.clientId}
            options={userList.client}
            onChange={handleConterSignerChange('clientId')}
          />
          <SingleSelectWithLabel
            label="Counterparty:"
            value={data?.counterpartyId}
            options={userList.counterparty}
            onChange={handleConterSignerChange('counterpartyId')}
          />
        </SingleViewFieldsList>
      </SingleViewPanel>

      <SingleViewPanel title="SETTLEMENT STATUS">
        <SingleViewFieldsList>
          <SingleSelectWithLabel
            label="Status:"
            value={data?.status}
            options={statusOptionItems}
            onChange={setFieldValue('status')}
          />
          <div />
        </SingleViewFieldsList>
      </SingleViewPanel>

      <SingleViewPanel title="SETTLEMENT AMOUNTS">
        <SettlementAmountGrid claims={data?.claims} total={data?.claimsTotal} />
      </SingleViewPanel>

      <SingleViewPanel title="PAYMENT DETAILS">
        <SingleViewFieldsList>
          <SingleInputWithLabel
            preventBrowserAddressDialog
            label={'Payment Address:'}
            value={data?.counterpartyAddress}
            onChange={setFieldValue('counterpartyAddress')}
          />
          <SingleInputWithLabel
            preventBrowserAddressDialog
            label={'City:'}
            value={data?.counterpartyCity}
            onChange={setFieldValue('counterpartyCity')}
          />
          <SingleInputWithLabel
            preventBrowserAddressDialog
            label={'State:'}
            value={data?.counterpartyState}
            onChange={setFieldValue('counterpartyState')}
          />
          <SingleInputWithLabel
            preventBrowserAddressDialog
            label={'Zip code:'}
            value={data?.counterpartyZip}
            onChange={setFieldValue('counterpartyZip')}
          />
          <SingleInputWithLabel
            label={'Name:'}
            value={data?.counterpartyName}
            onChange={setFieldValue('counterpartyName')}
          />
          <SingleInputWithLabel
            label={'Email:'}
            value={data?.counterpartyEmail}
            onChange={setFieldValue('counterpartyEmail')}
          />
          <SingleInputWithLabel
            label={'Phone number:'}
            value={data?.counterpartyPhone}
            onChange={setFieldValue('counterpartyPhone')}
          />
          <SingleInputWithLabel
            preventBrowserAddressDialog
            label={'Country'}
            value={data?.counterpartyCountry}
            onChange={setFieldValue('counterpartyCountry')}
          />
        </SingleViewFieldsList>
      </SingleViewPanel>

      <SingleViewPanel title="ADDITIONAL INFORMATION">
        <SingleTextfieldWithLabel
          label="Notes:"
          value={data?.notes}
          onChange={setFieldValue('notes')}
        />
      </SingleViewPanel>

      <SingleViewPanel
        title="SETTLEMENT AGREEMENT"
        className="claims-settlement-single__agreement"
      >
        <div className="claims-settlement-single__label">
          <TemplateInfoText />
        </div>

        <div className="claims-settlement-single__generate-button">
          <ButtonMainLoader
            disabled={!template.exists}
            loading={agreemantDocument.generating}
            onClick={agreemantDocument.generateNew}
          >
            {agreemantDocument.documentReady ? 'Regenerate' : 'Generate'} Agreement
            (.pdf)
          </ButtonMainLoader>
        </div>

        {agreemantDocument.documentReady && (
          <SettlementsAgreemantPreview
            fileName={agreemantDocument.fileName}
            fileBlob={agreemantDocument.blob}
            onPreviewOpen={agreemantDocument.getBlob}
            onDownload={agreemantDocument.downloadFile}
          />
        )}
      </SingleViewPanel>
    </div>
  );
};

const TemplateInfoText = () => {
  const { template } = useSettlementSingleContext();

  if (!template.exists)
    return (
      <div>
        <WarningText message={'Warning. Template is empty'} iconSize={18} /> You can
        create a new template on this{' '}
        <Link to={urlSettlementTemplates}>Settings Page.</Link>
      </div>
    );

  return (
    <>
      <div>
        We are using <b>{template.name}</b> as a template for generating the
        Settlement Agreement. You can preview and edit this template on this{' '}
        <Link to={urlSettlementTemplates}>Settings Page.</Link>
      </div>
      <div>
        <p>
          Make sure all the information above is correct before generating the
          Settlement Agreement.
        </p>
      </div>
    </>
  );
};

import {
  DynamicField,
  DynamicFieldFilter,
  dynamicFields,
  PutParams,
} from 'api/endpoints/dynamic-fields';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { FetchStatus } from 'types/fetch-status.types';
import { dynamicFieldsActions } from '../dynamicFields';
import { AppDispatch, GetState } from '../store';

export type FetchDynamicFieldsInput = {
  filter: {
    type: DynamicFieldFilter;
  };
};

export const filterDynamicFields =
  (input: FetchDynamicFieldsInput, section: SectionType) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const existingSlice = getState().dynamicFields;

    if (existingSlice.type === FetchStatus.Done) {
      const all: DynamicField[] =
        section === SectionType.Claims
          ? existingSlice.allClaimsData ?? []
          : section === SectionType.Contracts
          ? existingSlice.allContractsData ?? []
          : [];
      let filtered: DynamicField[] = [];
      if (input.filter.type === 'all') {
        filtered = all;
      } else {
        filtered = all.filter((x) => x.type === input.filter.type);
      }
      dispatch(dynamicFieldsActions.done({ items: filtered, input, section }));
    }
  };

export const fetchDynamicFieldsThunk =
  (input: FetchDynamicFieldsInput, section: SectionType) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(dynamicFieldsActions.fetching(input));
    const response = await dynamicFields.get(section);

    if (response.type === ResponseType.OK) {
      dispatch(dynamicFieldsActions.setAll({ items: response.data, section }));
      dispatch(dynamicFieldsActions.done({ input, items: response.data, section }));
    } else {
      dispatch(dynamicFieldsActions.error({ message: 'Could not fetch data.' }));
    }
  };

export type DynamicFieldEditResponse =
  | {
      type: FetchStatus.Done;
      item: DynamicField;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
    };

export const editDynamicFieldsThunk =
  (input: PutParams, section: SectionType) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<DynamicFieldEditResponse> => {
    const result = await dynamicFields.put(input, section);

    if (result.type === ResponseType.OK) {
      dispatch(
        dynamicFieldsActions.editDone({ edited: input as DynamicField, section }),
      );
      return { type: FetchStatus.Done, item: result.data };
    } else if (result.type === ResponseType.BAD_REQ) {
      // pick the first message
      const message = result.data.error[0].errorMessage;
      return { type: FetchStatus.Error, error: { message } };
    }
    return {
      type: FetchStatus.Error,
      error: {
        message: 'An unexpected error occured when editing the dynamic field',
      },
    };
  };

import { configuration, ContractRow, GridColumn } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import _ from 'lodash';
import { GridColumnsSectionType } from 'pages/ClaimsContractsToolPage/types';
import { sortByNameAsc } from './const';
import { createGraphQLQueryFromParams } from './createGraphQLQueryFromParams';
import { FilterRequest } from './utils/hotchocolateUtils';

const REQUEST_DEFAULT: FilterRequest = {
  sortModel: sortByNameAsc,
};

export const graphqlGetContracts = async (
  requestValue: FilterRequest,
  client: GraphQlApolloClient,
  customRequestColumns?: string[],
) => {
  let gridColumns: GridColumn[] = [];
  if (!customRequestColumns) {
    // let's get the columns only if they're not sent as params
    gridColumns =
      (await configuration.getGridColumns(GridColumnsSectionType.Contracts)) ?? [];
  }
  const requestColumns = customRequestColumns
    ? [...customRequestColumns]
    : _.map(gridColumns, 'propertyName');

  const request = { ...REQUEST_DEFAULT, ...requestValue };

  const { query, variables } = createGraphQLQueryFromParams(
    request,
    requestColumns,
    {
      queryName: 'GetContracts',
      graphqlEntity: 'contracts',
      booleanColumns: [],
      gridColumns,
    },
  );

  const { data } =
    (await client.query({
      query,
      variables,
    })) ?? {};

  const result = data?.contracts?.items ?? [];
  return result as ContractRow[];
};

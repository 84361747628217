import { AppDispatch, GetState } from '../store';
import { slipSheetsActions } from '../slipSheetsReducer';
import { SlipSheetPostParams, SlipSheetPutParams, slipsheets } from 'api/endpoints';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';

export const slipSheetsListThunk =
  () => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(slipSheetsActions.fetching());
    const r = await slipsheets.list();
    if (r.type === ResponseType.OK) {
      dispatch(slipSheetsActions.done({ items: r.data }));
    } else {
      dispatch(
        slipSheetsActions.error({
          message: 'Unable to fetch the slip sheets list.',
        }),
      );
    }
  };

export const slipSheetsPostThunk =
  (params: SlipSheetPostParams) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    // dispatch(slipSheetsActions.fetching());
    const r = await slipsheets.post(params);
    if (r.type === ResponseType.OK) {
      dispatch(slipSheetsActions.postDone({ items: [r.data] }));
    } else {
      dispatch(
        slipSheetsActions.error({
          message: 'Unable to create slip sheets.',
        }),
      );
    }
  };

export const slipSheetsPutThunk =
  (params: SlipSheetPutParams) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    // dispatch(slipSheetsActions.fetching());
    const r = await slipsheets.put(params);
    if (r.type === ResponseType.OK) {
      dispatch(slipSheetsActions.putDone({ item: r.data }));
    } else {
      dispatch(
        slipSheetsActions.error({
          message: 'Unable to create slip sheet.',
        }),
      );
    }
  };

export const slipSheetsDeleteThunk =
  (params: { id: number }) => async (dispatch: AppDispatch, getState: GetState) => {
    // dispatch(slipSheetsActions.fetching());
    const r = await slipsheets.delete(params);
    if (r.type === ResponseType.OK) {
      dispatch(slipSheetsActions.deleteDone(params));
    } else {
      dispatch(
        slipSheetsActions.error({
          message: 'Unable to update slip sheet.',
        }),
      );
    }
  };

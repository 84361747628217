import { ReactNode } from 'react';

import './ClaimsPanelCard.css';

type Props = {
  title: ReactNode;
  children: ReactNode;
  footer: ReactNode;
};

export const ClaimsPanelCard = ({ title, footer, children }: Props) => {
  return (
    <div className="claims-panel-card">
      <div className="claims-panel-card__title">{title}</div>
      <div className="claims-panel-card__body"> {children}</div>

      <div className="claims-panel-card__footer">{footer}</div>
    </div>
  );
};

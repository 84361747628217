import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DropdownCategories,
  AllContractsCategories,
  EditContractCategoryRequest,
} from 'api/endpoints';

type State = {
  dropdownCategories: DropdownCategories[];
  allCategories: AllContractsCategories[];
  fetching: boolean;
};

const InitialState: State = {
  dropdownCategories: [],
  allCategories: [],
  fetching: false,
};

export const contractsCategoriesReducer = createSlice({
  name: 'contractsCategoriesSettings',
  initialState: InitialState,
  reducers: {
    fetchingAllCategories: (state: State, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    fetchDropdownCategoriesDone: (
      state: State,
      action: PayloadAction<DropdownCategories[]>,
    ) => {
      state.dropdownCategories = action.payload;
    },
    fetchAllCategoriesDone: (
      state: State,
      action: PayloadAction<AllContractsCategories[]>,
    ) => {
      state.allCategories = action.payload;
      state.fetching = false;
    },
    editCategoryDone: (
      state: State,
      action: PayloadAction<EditContractCategoryRequest>,
    ) => {
      state.allCategories = [...state.allCategories].map((category) => {
        return {
          ...category,
          enabled:
            category.contractTypeId === action.payload.contractTypeId
              ? action.payload.enabled
              : category.enabled,
          contractTypeName:
            category.contractTypeId === action.payload.contractTypeId &&
            action.payload.contractTypeName
              ? action.payload.contractTypeName
              : category.contractTypeName,
        };
      });
    },
  },
});
export default contractsCategoriesReducer.reducer;
export const {
  fetchDropdownCategoriesDone,
  fetchAllCategoriesDone,
  editCategoryDone,
  fetchingAllCategories,
} = contractsCategoriesReducer.actions;

import classNames from 'classnames';
import { LinkButtonSecondary } from 'components/UIComponents/LinkButton';

import './HelpTileBox.css';

export type Props = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  onClick: () => void;
};

export const HelpTileBox = ({ title, children, className, onClick }: Props) => {
  return (
    <div className={classNames('settings-panel-help anim-appear', className)}>
      {title && <h3 className="settings-panel-help__title">{title}</h3>}
      <div className="settings-panel-help__body">{children} </div>
      <div className="settings-panel-help__footer">
        <LinkButtonSecondary text="View more" onClick={onClick} />
      </div>
    </div>
  );
};

import { ContractEditValues, ContractRow } from 'api/endpoints';
import { useMatchCodeContractsQuery } from 'hooks/useMatchCodeContracts';
import { useEffect, useState } from 'react';

export type ContractMatchRow = Pick<
  ContractRow,
  | 'referenceNumber'
  | 'contractType'
  | 'category'
  | 'description'
  | 'contractDate'
  | 'contractStatus'
  | 'id'
>;

type Column = { title: string; field: keyof ContractMatchRow };

export const COLUMNS: Column[] = [
  { title: 'Contract ref number', field: 'referenceNumber' },
  { title: 'Contract Type', field: 'contractType' },
  { title: 'Category', field: 'category' },
  { title: 'Contract Title', field: 'description' },
  { title: 'Contract Date', field: 'contractDate' },
  { title: 'Workflow Status', field: 'contractStatus' },
];

export function useSingleContractMatches(contract: ContractEditValues) {
  const matchedContractsQuery = useMatchCodeContractsQuery(contract);

  const [matchedContracts, setMatchedContracts] = useState<ContractMatchRow[]>();
  const [headerContract, setHeaderContract] = useState<ContractMatchRow>();

  useEffect(() => {
    const items = matchedContractsQuery?.data?.contracts.items ?? [];
    const headerItem = items?.find(
      (r) => r.referenceNumber === contract.referenceNumber,
    );
    setMatchedContracts(items);
    setHeaderContract(headerItem);
  }, [contract.referenceNumber, matchedContractsQuery?.data?.contracts]);

  return {
    headerContract,
    matchedContracts,
    loading: matchedContractsQuery.loading,
  };
}

import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';
import { ReactChild } from 'react';
import './ClaimsMenuSectionTabs.css';

import { useAppSelector } from 'hooks/reducerHooks';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { SectionType } from '../../pages/ClaimsContractsToolPage/types';
import { getSections } from './claimsMenuItems';
import { GeneratingFileText } from './GeneratingFileText';

export type SectionItem = {
  type: SectionType;
  label: string;
  disabled?: boolean;
  resetFetchStatus?: boolean;
  subsections?: SectionType[];
};

interface Props {
  selected?: SectionType;
  additional?: ReactChild | ReactChild[];
  onSectionClick?: (section: SectionItem) => void;
  fileName?: string;
  generatingExcelFile?: boolean;
}

export const ClaimsMenuSectionTabs = ({
  selected,
  additional,
  onSectionClick,
}: Props) => {
  const { generating: generatingExcelFile, fileName } = useAppSelector(
    (s) => s.claimsContractsExport,
  );

  const user = useApplicationUser();

  const sections = getSections(user.data?.roleType ?? 'Client');

  const handleSectionBtnClick = (section: SectionItem) => {
    // to loose focus after clicking ApButtonTab
    (document.activeElement as HTMLElement)?.blur();
    onSectionClick?.(section);
  };

  return (
    <div className="claims-section-tabs__container">
      <div className="claims-section-tabs">
        <ApButtonGroup justifyContent="initial" spacingSize={0}>
          {sections.map((s) => (
            <ApButtonTab
              key={s.type}
              autoFocus={false}
              disabled={s.disabled}
              isSelected={selected === s.type || s.subsections?.includes(selected ?? '' as SectionType)}
              onClick={() => handleSectionBtnClick(s)}
              theme="light"
            >
              {s.label}
            </ApButtonTab>
          ))}
        </ApButtonGroup>
      </div>
      {generatingExcelFile && fileName && <GeneratingFileText fileName={fileName} />}
      {additional && (
        <div className="claims-section-tabs__additional">{additional}</div>
      )}
    </div>
  );
};

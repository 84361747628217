import partition from 'lodash/partition';
import {
  BatchEditPostBackground,
  ClaimBatchData,
  claims,
  contracts,
} from 'api/endpoints';

import { ClaimsReviewersBatchItem, IBatchItem } from '../claimsBatchEdit.type';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export const postBatchEdit = async (ids: string[], items: IBatchItem[]) => {
  const [reviewersItems, batchItems] = partition(
    items,
    (item) => item.type === 'group-multi-select',
  ) as [ClaimsReviewersBatchItem[], IBatchItem[]];

  const reviewersValueToSend = reviewersBatchItemsToPostData(reviewersItems);

  const nonEmptyItems = batchItems.filter((r) => r.propName);
  const values = nonEmptyItems.reduce((a, v) => ({ ...a, [v.propName]: v.value }), {
    ...reviewersValueToSend,
  } as ClaimBatchData['values']);

  const claimIds = ids.map((r) => +r);
  return claims.batchEdit({ claimIds, values });
};

export function reviewersBatchItemsToPostData(
  reviewersItems: ClaimsReviewersBatchItem[],
):
  | {
      claimReviewerUserNames: string[];
      claimReviewerUserGroupIds: number[];
    }
  | {} {
  if (!reviewersItems.length) return {};

  // Last item of same type takes precedence
  const lastReviwersItem = reviewersItems[reviewersItems.length - 1];
  let reviewersValue = lastReviwersItem?.value;

  return reviewersValue ?? {};
}

export const apiBatchEditUploadBackground = async (
  data: BatchEditPostBackground,
  section: SectionType,
) => {
  switch (section) {
    case SectionType.Claims:
      return claims.batchEditUploadBackground(data);
    case SectionType.Contracts:
      return contracts.batchEditUploadBackground(data);
  }
};

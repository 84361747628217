import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import './DocumentsPage.css';
import { useClaimToolQueryParams } from 'utils/hooks/useClaimToolQueryParams';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { DocumentsPageAllDocs } from './components/AllDocuments/DocumentsPageAllDocs';
import { DocumentsPageContextProvider } from './context/DocumentsPageContextProvider';
import { ClaimsPanel } from 'components/ClaimsPanel/ClaimsPanel';
import { ClaimsPanelCard } from 'components/ClaimsPanelCard/ClaimsPanelCard';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

export const DocumentsPage = () => {
  return (
    <ApUserRole>
      <Children />
    </ApUserRole>
  )
};

const Children = () => {
  const { subsection, setSubsection } = useClaimToolQueryParams();
  switch (subsection) {
    case SubsectionType.AllClaimsDocuments:
      return (
        <div className="documents-page flex-align-center">
          <DocumentsPageContextProvider
            sectionType={SectionType.Claims}
            subsection={subsection}
          >
            <DocumentsPageAllDocs subsection={subsection} />
          </DocumentsPageContextProvider>
        </div>
      );
    case SubsectionType.AllContractsDocuments:
      return (
        <div className="documents-page flex-align-center">
          <DocumentsPageContextProvider
            sectionType={SectionType.Contracts}
            subsection={subsection}
          >
            <DocumentsPageAllDocs subsection={subsection} />
          </DocumentsPageContextProvider>
        </div>
      );
    default:
      return (
        <ClaimsPanel>
          <ClaimsPanelCard
            title="Claims Documents"
            footer={
              <ButtonMainLoader
                onClick={() => setSubsection(SubsectionType.AllClaimsDocuments)}
              >
                Go to Claims documents
              </ButtonMainLoader>
            }
          >
            <div>Here you can:</div>
            <li>Add new Claims documents</li>
            <li>View previous uploaded documents for Claims</li>
            <li>Manage previous uploaded documents for Claims</li>
          </ClaimsPanelCard>

          <ClaimsPanelCard
            title="Contracts Documents"
            footer={
              <ButtonMainLoader
                onClick={() => setSubsection(SubsectionType.AllContractsDocuments)}
              >
                Go to Contracts documents
              </ButtonMainLoader>
            }
          >
            <div>Here you can:</div>
            <li>Add new Contracts documents</li>
            <li>View previous uploaded documents for Contracts</li>
            <li>Manage previous uploaded documents for Contracts</li>
          </ClaimsPanelCard>
        </ClaimsPanel>
      );
  }
}

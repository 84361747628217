import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export type DashboardDetailedViewData = {
  contractActiveSection?: ContractsDashboardCategory;
  contractDetailedData?: ContractsDetailedData[];
  claimsActiveSection?: ClaimsDashboardCategory;
  claimsDetailedData?: ClaimsDetailedData[];
  handleTaskClick?: TaskClick;
  gridClassName: string;
  fetchingContractsData?: boolean;
  fetchingApTasks?: boolean;
};

export type ActiveSection =
  | ClaimsDashboardCategory
  | ContractsDashboardCategory
  | undefined;

export type NavButtons = {
  label: string;
  value: ActiveSection;
};

export type ContractsBaseDetailedData = {
  id: ContractsDashboardCategory | null;
  label: string;
  count: number;
  percentage?: string;
  low?: number;
  high?: number;
  negotiatedCureAmount?: number;
};

export type ContractItemsData = ContractsBaseDetailedData & {
  items?: ContractsBaseDetailedData[] | null;
};

export type ContractsDetailedData = ContractsBaseDetailedData & {
  items: ContractItemsData[];
};

export enum ContractsDashboardCategory {
  AwaitingUnderReview = 'awaitingUnderReview',
  Assume = 'assume',
  Reject = 'reject',
  Terminate = 'terminate',
  NonExecutory = 'nonExecutory',
}

export type ClaimsItemsData = {
  count: number;
  label: string;
  totalProposed: number;
};

export type ClaimsDetailedData = {
  id: string;
  count: number;
  totalProposed: number;
  items: ClaimsItemsData[];
  to?: string;
};

export enum ClaimsDashboardCategory {
  Resolved = 'resolved',
  ResolutionProccessInitiated = 'resolutionProccessInitiated',
  ResolutionProcessPending = 'resolutionProcessPending',
  UnassignedClaims = 'unassignedClaims',
}

export enum DashboardAgGridFilter {
  DashboardCategory = 'dashboardCategory',
  ContractResolutionTypeName = 'contractActionTypeName',
}

export type TaskClick =
  | (({
      section,
      isTask,
      isBackToTop,
      sectionType,
    }: {
      section: ActiveSection;
      sectionType: SectionType | undefined;
      isTask?: boolean | undefined;
      isBackToTop?: boolean | undefined;
    }) => void)
  | undefined;

import { useEffect, useState } from 'react';
import { ApSelect, ApToggleSwitch } from '@alixpartners/ui-components';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { Panel } from 'components/UIComponents/EntityTable';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { settings } from 'api/endpoints';
import { ApLoaderDefault } from '@alixpartners/ui-components';
import './SofasSchedulesSettings.css';

const COVER_TYPES = ['Standard', 'Houston'];
const SETTING_NAME = 'SofasSettings';

export function SofasSchedulesSettings() {
  const [saving, setSaving] = useState<boolean>(false);
  const [coverType, setCoverType] = useState<string>(COVER_TYPES[0]);
  const [showRedacted, setShowRedacted] = useState<boolean>(false);
  const [loadingInitialInfo, setLoadingInitialInfo] = useState<boolean>(true);
  const [changed, setChanged] = useState<boolean>(false);

  // Set the initial settings
  useEffect(() => {
    settings
      .getSofaSettings()
      .then((res: any) => {
        // If the res is not good
        if (!res || res.error) {
          console.warn('Settings response', res.error);
          setLoadingInitialInfo(false);
          return;
        }

        // Response will be an array *promise.all*
        // Find the values CoverType and set them if found
        res.forEach(({ data }: any) => {
          if (data.name === 'CoverType') {
            setCoverType(data.value);
          }
          if (data.name === 'ShowRedacted') {
            setShowRedacted(data.value === 'true');
          }
        });

        // Set the loading to false
        setLoadingInitialInfo(false);
      })
      .catch((error) => {
        console.warn('Settings', error);
        setLoadingInitialInfo(false);
      });
  }, []);

  // Save the settings
  const saveClientInfo = async () => {
    setSaving(true);

    const infoToSave = {
      CoverType: coverType,
      ShowRedacted: showRedacted,
    };

    await settings.saveSofaSettings(SETTING_NAME, infoToSave);

    setSaving(false);
    setChanged(false);
  };

  // Set the values
  const changeCoverType = (value: string) => {
    setCoverType(value);
    setChanged(true);
  };
  const changeShowRedacted = (value: any) => {
    setShowRedacted(!showRedacted);
    setChanged(true);
  };

  // If the data is still loading show a loading icon
  if (loadingInitialInfo) {
    return <ApLoaderDefault loaderSize="lg" />;
  }

  return (
    <SettingsContentPanel className="sofas-settings__select">
      <section className="sofas-settings">
        <Panel title="Cover Pages">
          <p>Select the cover page to be used in SOFAs and Schedule PDFs.</p>
          <div className="select-cover">
            <ApSelect
              name="cover-page"
              value={coverType}
              options={COVER_TYPES.map((value) => ({
                label: value,
                value,
              }))}
              placeholder={coverType}
              onChange={changeCoverType}
            />
          </div>
        </Panel>

        <Panel title="Redaction">
          <p>
            Display redacted content in SOFAs and Schedule PDFs (warning this will
            show redacted content when generating files).
          </p>
          <div className="select-cover">
            <ApToggleSwitch
              name="enabled"
              defaultChecked={!!showRedacted}
              id="switch-redacted"
              onChange={changeShowRedacted}
            />
          </div>
        </Panel>
        <div className="sofa-settings__footer">
          <ButtonMainLoader
            onClick={saveClientInfo}
            loading={saving}
            disabled={!changed}
          >
            Save Changes
          </ButtonMainLoader>
        </div>
      </section>
    </SettingsContentPanel>
  );
}

import { ApButtonMain, ApLoaderDefault, IOption } from '@alixpartners/ui-components';
import { MultiSelectExtendable } from 'components/UIComponents/MultiSelectExtendable';
import { useAppSelector } from 'hooks/reducerHooks';
import { useMemo } from 'react';
import { formatLegalEntity } from 'utils/format';
import './ButtonGenerateByEntityId.css';

interface Props {
  selectedEntity: IOption[];
  onLegalEntityChange: (option: IOption[]) => void;
  onGenerateClick: () => void;
  showSpinner?: boolean;
}

const allValues = {
  value: 'allEntities',
  label: 'All entities',
};

export const ButtonGenerateByEntityId = ({
  selectedEntity,
  onLegalEntityChange,
  onGenerateClick,
  showSpinner,
}: Props) => {
  const { legalEntities } = useAppSelector((s) => s.selectable.data);

  const legalEntitiesOptions = useMemo(() => {
    const options: IOption[] = legalEntities.map((le) => ({
      value: `${le.id}`,
      label: formatLegalEntity(le),
    }));

    return [allValues, ...options];
  }, [legalEntities]);

  return (
    <div className="claims-reports__generate-by-entity">
      <MultiSelectExtendable
        options={legalEntitiesOptions}
        searchEnabled
        multiple={false}
        selected={selectedEntity}
        onChange={onLegalEntityChange}
      />
      <ApButtonMain onClick={onGenerateClick} iconName="save_alt">
        Generate report {showSpinner ? <ApLoaderDefault loaderSize="sm" /> : ''}
      </ApButtonMain>
    </div>
  );
};

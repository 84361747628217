import { SlipSheet } from 'api/endpoints';
import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  mapLegalEntityToOption,
  reportPartToOptionPrefixed,
} from '../../Domain/sofa-schedules-mapper';

export function useSlipSheetSelectedOptions(item: SlipSheet | undefined) {
  const se = useMemo(() => {
    return item?.legalEntities.map(mapLegalEntityToOption) ?? [];
  }, [item]);

  const sp = useMemo(() => {
    return item?.parts.map(reportPartToOptionPrefixed) ?? [];
  }, [item]);

  const [selectedEntities, setSelectedEntities] = useState<IOption[]>(se);
  const handleChangeSelectedEntities = useCallback((options: IOption[]) => {
    setSelectedEntities(options);
  }, []);

  const [selectedParts, setSelectedParts] = useState<IOption[]>(sp);
  const handleChangeSelectedParts = useCallback((options: IOption[]) => {
    setSelectedParts(options);
  }, []);

  useEffect(() => {
    setSelectedEntities(se);
    setSelectedParts(sp);
  }, [se, sp]);

  const ret = useMemo(() => {
    return {
      selectedEntities,
      selectedParts,
      handleChangeSelectedEntities,
      handleChangeSelectedParts,
    };
  }, [
    handleChangeSelectedEntities,
    handleChangeSelectedParts,
    selectedEntities,
    selectedParts,
  ]);

  return ret;
}

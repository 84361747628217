import {
  IMultiSelectProps,
  IOption,
  LabeledMultiSelectExtendable,
} from 'components/UIComponents/MultiSelectExtendable';
import { useMemo } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import {
  reportPartToOption,
  reportPartToOptionPrefixed,
} from '../Domain/sofa-schedules-mapper';
import { REPORT_TYPES_ENUM } from '../Domain/useReportLegalEntities';
import { useReportParts } from '../Domain/useReportParts';

export type ReportPartsSelectProps = Pick<
  IMultiSelectProps,
  'onChange' | 'selected' | 'multiple' | 'selectAll'
> & {
  selectedReportType:
    | { type: REPORT_TYPES_ENUM }
    | { type: 'all'; first: REPORT_TYPES_ENUM };
  className?: string;
  selectCn?: string;
};

const GROUP_VALUES = ['_sofa_group', '_schedule_group'];

export function SofaPartsMultiselect({
  selected,
  selectAll,
  selectedReportType,
  className,
  selectCn,
  multiple,
  onChange,
}: ReportPartsSelectProps) {
  const { data, type } = useReportParts();

  const options: IOption[] = useMemo(() => {
    if (type === FetchStatus.Done) {
      if (selectedReportType.type === 'all') {
        const allSofa = data
          .filter((part) => part.parentType === 'Sofa')
          .map(reportPartToOptionPrefixed);
        const allSchedule = data
          .filter((part) => part.parentType === 'Schedule')
          .map(reportPartToOptionPrefixed);

        const sofaGroup: IOption = {
          label: 'SOFAs',
          value: GROUP_VALUES[0],
        };
        const sofaSchedule: IOption = {
          label: 'Schedules',
          value: GROUP_VALUES[1],
        };

        let allOptions: IOption[] = [
          sofaGroup,
          ...allSofa,
          sofaSchedule,
          ...allSchedule,
        ];
        if (selectedReportType.first === REPORT_TYPES_ENUM.Schedule) {
          // flip the options to display Schedule first
          allOptions = [sofaSchedule, ...allSchedule, sofaGroup, ...allSofa];
        }

        return allOptions;
      }
      return data
        .filter((part) => part.parentType === selectedReportType.type)
        .map(reportPartToOption);
    }
    return [];
  }, [data, selectedReportType, type]);

  const labelString = useMemo(() => {
    if (!selected || selected.length === 0) {
      return undefined;
    }

    if (selected.length <= 8) {
      return selected.map((r) => r.label).join(', ');
    }

    return `${selected.length} parts selected`;
  }, [selected]);

  return (
    <LabeledMultiSelectExtendable
      className={className}
      selectCn={selectCn}
      menuClassName={'report-part-select'}
      topLabel="Search for Parts"
      options={options}
      searchEnabled
      multiple={multiple}
      selected={selected}
      selectAll={selectAll}
      renderLabel={labelString}
      onChange={onChange}
      isSelectable={isSelectable}
    />
  );
}

function isSelectable(o: IOption): boolean {
  const isGroup = !!GROUP_VALUES.find((g) => g === o.value);
  if (isGroup) return false;
  return true;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApDashboardTasksInfo,
  ClientDashboardTasksInfo,
} from 'api/endpoints/dashboard-tasks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ActiveSection, ClaimsDetailedData } from 'pages/Dashboard/types';
import {
  allContractsTasks,
  allClaimsTasks,
  clientsContractsTasks,
  clientsClaimsTasks,
} from 'pages/Dashboard/utils/dashboardTasksConfig';
import { FetchStatus } from 'types/fetch-status.types';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';
import { ApplicationUser } from 'utils/AppInitializer/application-user';

type DashboardTaskBase = {
  to: string;
  count?: number;
  description: string;
  filters?: AgGridFilterModel;
  footnote?: string;
  hasScroll?: boolean;
  subHeader?: string;
  indentLeft?: boolean;
  hasSeparator?: boolean;
  hide?: boolean;
  hasPercentage?: boolean;
  estimatesRejectionDamageLow?: number;
  estimatesRejectionDamageHigh?: number;
  totalProposed?: number;
  detailedViewValue?: ActiveSection;
  sectionType: SectionType;
};

export type ApDashboardTask = DashboardTaskBase & {
  id: keyof ApDashboardTasksInfo;
};

export type ClientDashboardTask = DashboardTaskBase & {
  id: keyof ClientDashboardTasksInfo;
};

export type DashboardTaskInfo = ApDashboardTask | ClientDashboardTask;

export type UserDashboardSlice = {
  status: FetchStatus;
  error: { message: string } | null;
  showMine: boolean;
  applicationUserId?: number;
  contractsData: ApDashboardTask[];
  claimsData: ApDashboardTask[];
  clientData: ClientDashboardTask[];
};

export const initialSlice: UserDashboardSlice = {
  status: FetchStatus.Idle,
  claimsData: [],
  contractsData: [],
  error: null,
  showMine: false,
  applicationUserId: undefined,
  clientData: [],
};

export type UserDashboardSOuterlice = {
  slice: UserDashboardSlice;
};

export const initalOuterSlice: UserDashboardSOuterlice = {
  slice: initialSlice,
};

const slice = createSlice({
  name: 'user-dashboard-tasks',
  initialState: initalOuterSlice,
  reducers: {
    fetching(state) {
      state.slice.status = FetchStatus.Fetching;
    },
    error(state, action: PayloadAction<{ message: string }>) {
      state.slice.error = action.payload;
      state.slice.status = FetchStatus.Error;
    },
    apTasksDone(
      state,
      action: PayloadAction<{
        tasks: ApDashboardTasksInfo;
        user: ApplicationUser;
        showMine?: boolean;
      }>,
    ) {
      state.slice.contractsData = apContractsTasksInfo(action.payload);
      state.slice.error = null;
      state.slice.status = FetchStatus.Done;
      state.slice.showMine = !!action.payload.showMine;
    },
    clientTasksDone(
      state,
      action: PayloadAction<{
        tasks: ClientDashboardTasksInfo;
        user: ApplicationUser;
      }>,
    ) {
      state.slice.clientData = clientTaksInfoToTasks({
        tasks: action.payload.tasks,
        user: action.payload.user,
      });
      state.slice.error = null;
      state.slice.status = FetchStatus.Done;
    },
    setShowMineToggle: (state, action: PayloadAction<boolean>) => {
      state.slice.status = FetchStatus.Idle;
      state.slice.showMine = action.payload;
    },
    appUserIdDone: (state, action: PayloadAction<number>) => {
      state.slice.applicationUserId = action.payload;
    },
    apClaimsTasksDone: (state, action: PayloadAction<ClaimsDetailedData[]>) => {
      state.slice.claimsData = apClaimsTasksInfo(
        action.payload,
        state.slice.showMine,
      );
    },
  },
});

export const userDashboardTasksActions = slice.actions;
export default slice.reducer;

function apContractsTasksInfo(payload: {
  tasks: ApDashboardTasksInfo;
  user: ApplicationUser;
  showMine?: boolean;
}): ApDashboardTask[] {
  return allContractsTasks.map((t) => {
    const count = payload.tasks[t.id];

    return {
      ...t,
      count,
      to: getTaskTo(t.to, !!payload.showMine),
      estimatesRejectionDamageLow:
        t.id === 'rejectionDamage'
          ? payload.tasks.estimatesRejectionDamageLow
          : undefined,
      estimatesRejectionDamageHigh:
        t.id === 'rejectionDamage'
          ? payload.tasks.estimatesRejectionDamageHigh
          : undefined,
    };
  });
}

const apClaimsTasksInfo = (
  tasks: ClaimsDetailedData[],
  showMine?: boolean,
): ApDashboardTask[] => {
  return allClaimsTasks.map((claim) => {
    const foundClaim = tasks.find((task) => task.id === claim.id);

    return {
      ...claim,
      count: foundClaim?.count ?? 0,
      totalProposed: foundClaim?.totalProposed ?? 0,
      to: getTaskTo(claim.to, !!showMine),
    };
  });
};

function clientTaksInfoToTasks(payload: {
  tasks: ClientDashboardTasksInfo;
  user: ApplicationUser;
}): ClientDashboardTask[] {
  return [...clientsClaimsTasks, ...clientsContractsTasks].map((t) => {
    const count = payload.tasks[t.id];
    return {
      ...t,
      count,
    };
  });
}

const getTaskTo = (original: string, showMine: boolean) => {
  if (!original) return '';
  let newValue = original;
  if (showMine) {
    newValue = newValue.replace('showMine=0', 'showMine=1');
  } else newValue.replace('showMine=1', 'showMine=0');
  return newValue;
};

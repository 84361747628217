import classnames from 'classnames';
import './SingleViewFieldsList.css';

interface Props {
  className?: string;
  children: JSX.Element[];
}

export const SingleViewFieldsList = ({ className, children }: Props) => {
  const [fist, second] = splitChildrens(children);

  return (
    <div className={classnames(className, 'single-view-fields-list')}>
      <div>{fist}</div>
      <div>{second}</div>
    </div>
  );
};

function splitChildrens(list: JSX.Element[]) {
  const half = Math.ceil(list.length / 2);

  const first = list.slice(0, half);
  const second = list.slice(half);

  return [first, second] as const;
}

import { WarningMessage } from 'components/UIComponents/WarningMessage/WarningMessage';
import { useMemo } from 'react';
import { FileStatus, IUploadItem } from 'types/dataUploaderTypes';
import { SCHEDULES_PARTS } from '../utils/dataUploader.config';
import { FileUploadPanel } from './FileUploadPanel';
import './SchedulesUpload.css';

type SchedulesProps = {
  onUpload: (files: File[], schedule?: string) => void;
  displayItems?: IUploadItem[];
};

export const SchedulesUpload = ({ onUpload, displayItems }: SchedulesProps) => {
  const fileToBeUploaded = useMemo(
    () => displayItems?.find((item: any) => item.status === FileStatus.Uploading),
    [displayItems],
  );

  return (
    <div className="schedules-upload">
      <WarningMessage warningMessage="When uploading files, please make sure the filenames contain only one pair of brackets" />
      {SCHEDULES_PARTS.map((schedule) => {
        return (
          <div className="schedules-part" key={schedule}>
            <label>{schedule}</label>
            <FileUploadPanel
              onUpload={onUpload}
              isScheduleSection
              schedule={schedule}
              maxFiles={1}
              fileToBeUploaded={fileToBeUploaded}
            />
          </div>
        );
      })}
    </div>
  );
};

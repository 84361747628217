import { useEffect } from 'react';
import { LogEntry, LogEntryResult } from 'api/endpoints/claimLogs';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { fetchClaimLogsThunk } from 'reducer/thunks/claimlogs-thunks';
import { PaginationInput } from 'reducer/claimLogsDataReducer';
import { fetchContractsLogsThunk } from 'reducer/thunks/contractslogs-thunk';
import { ContractLogEntryResult } from 'api/endpoints/contractLogs';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { FetchStatus } from 'types/fetch-status.types';

export type UseChangesCommentsLogsReturn = {
  data: LogEntryResult | ContractLogEntryResult | null;
  status: FetchStatus;
  latestComment?: LogEntry;
};

const LOG_DATA_MAP = {
  [SectionType.Claims]: 'claimLogs',
  [SectionType.Contracts]: 'contractLogs',
};

export function useClaimContractChangesCommentsLogs(
  pagination: PaginationInput,
  section: SectionType,
): UseChangesCommentsLogsReturn {
  const sliceName = (LOG_DATA_MAP as any)[section];
  const { fetchStatus, pageData, latestComment } = useAppSelector(
    (state) => (state as any)[sliceName],
  );

  let existingId;

  if (pageData && 'claimId' in pageData) {
    existingId = pageData?.claimId;
  } else if (pageData && 'contractId' in pageData) {
    existingId = pageData?.contractId;
  }

  const dispatch = useAppDispatch();

  const { currentPage, currentSort, id } = pagination;
  useEffect(() => {
    if (section === SectionType.Claims && fetchStatus === FetchStatus.Idle) {
      dispatch(
        fetchClaimLogsThunk({
          id,
          currentPage,
          currentSort,
        }),
      );
    } else if (
      section === SectionType.Contracts &&
      fetchStatus === FetchStatus.Idle
    ) {
      dispatch(
        fetchContractsLogsThunk({
          id,
          currentPage,
          currentSort,
        }),
      );
    }
    return;
  }, [id, currentPage, currentSort, dispatch, existingId, section, fetchStatus]);

  return {
    data: pageData,
    status: fetchStatus,
    latestComment,
  };
}

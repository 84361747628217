import _ from 'lodash';
import { SingleSettlement } from '../api/apiSettlementSingle';

export const validateSingleSettlement = (data: SingleSettlement | undefined) => {
  if (!data) return { valid: false };
  if (_.isNil(data.counterpartyId)) return { valid: false };
  if (_.isNil(data.clientId)) return { valid: false };
  if (_.isNil(data.statusId)) return { valid: false };

  return { valid: true };
};

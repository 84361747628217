import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionName, ContractActionName } from 'api/endpoints/action-names';
import { FetchStatus } from 'types/fetch-status.types';

type StaleState = {
  type: FetchStatus.Stale;
  markedById?: string;
  data: null;
  error: null;
  contractsData: null;
  contractsStatus: FetchStatus;
};

export type ActionNamesSlice =
  | {
      type: FetchStatus.Idle;
      data: null;
      error: null;
      contractsData: null;
      contractsStatus: FetchStatus.Idle;
    }
  | {
      type: FetchStatus.Done;
      data: ActionName[];
      error: null;
      contractsData: ContractActionName[];
      contractsStatus: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: ActionName[];
      contractsData: ContractActionName[];
      contractsStatus: null;
    }
  | {
      type: FetchStatus.Fetching;
      data: ActionName[];
      error: null;
      contractsData: ContractActionName[];
      contractsStatus: null;
    }
  | StaleState;

export const initialState: ActionNamesSlice = {
  type: FetchStatus.Idle,
  error: null,
  data: null,
  contractsData: null,
  contractsStatus: FetchStatus.Idle,
} as ActionNamesSlice;

const actionNamesSlice = createSlice({
  name: 'action-names',
  initialState: initialState,
  reducers: {
    markedAsStale(
      state: ActionNamesSlice,
      action: PayloadAction<{ markedById?: string }>,
    ) {
      state.type = FetchStatus.Stale;
      (state as StaleState).markedById = action.payload.markedById;
    },
    setActionNamesFetchingIdle(state: ActionNamesSlice) {
      state.type = FetchStatus.Idle;
      state.contractsStatus = FetchStatus.Idle;
    },
    actionNamesFetching(state: ActionNamesSlice) {
      state.type = FetchStatus.Fetching;
    },
    actionNamesDone(state: ActionNamesSlice, action: PayloadAction<ActionName[]>) {
      state.type = FetchStatus.Done;
      state.data = action.payload;
      state.contractsStatus = FetchStatus.Idle;
    },
    actionNameCreated(state: ActionNamesSlice, action: PayloadAction<ActionName>) {
      state.type = FetchStatus.Done;
      if (state.data) {
        state.data = [action.payload, ...state.data];
      }
    },
    actionNameEdit(state: ActionNamesSlice, action: PayloadAction<ActionName>) {
      state.type = FetchStatus.Done;
      if (state.data) {
        const index = state.data.findIndex((a) => a.id === action.payload.id);
        if (index > -1) {
          state.data[index] = action.payload;
        }
      }
    },
    contractActionNamesFetching(state: ActionNamesSlice) {
      state.contractsStatus = FetchStatus.Fetching;
    },
    contractActionNamesDone(
      state: ActionNamesSlice,
      action: PayloadAction<ContractActionName[]>,
    ) {
      state.type = FetchStatus.Idle;
      state.contractsData = action.payload;
      state.contractsStatus = FetchStatus.Done;
    },
    contractActionNameCreated(
      state: ActionNamesSlice,
      action: PayloadAction<ContractActionName>,
    ) {
      state.type = FetchStatus.Done;
      if (state.contractsData) {
        state.contractsData = [action.payload, ...state.contractsData];
      }
    },
    contractActionNameEdit(
      state: ActionNamesSlice,
      action: PayloadAction<ContractActionName>,
    ) {
      state.type = FetchStatus.Done;
      if (state.contractsData) {
        const index = state.contractsData.findIndex(
          (a) => a.id === action.payload.id,
        );
        if (index > -1) {
          state.contractsData[index] = action.payload;
        }
      }
    },
    actionNamesError(
      state: ActionNamesSlice,
      action: PayloadAction<{ message: string }>,
    ) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const {
  actionNamesFetching,
  actionNamesDone,
  actionNamesError,
  actionNameCreated,
  actionNameEdit,
  markedAsStale,
  contractActionNamesFetching,
  contractActionNamesDone,
  contractActionNameCreated,
  contractActionNameEdit,
  setActionNamesFetchingIdle,
} = actionNamesSlice.actions;
export default actionNamesSlice.reducer;

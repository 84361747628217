import { Outlet, Route, Routes } from 'react-router';
import { HeaderBar } from './Components/HeaderBar';
import { SofasSchedulesExportPanel } from './Export/SofasSchedulesExportPanel';
import { GlobalNotes } from './Sections/GlobalNotes';
import { Home } from './Sections/Home';
import { Signatories } from './Sections/Signatories/Signatories';
import { SlipSheets } from './Sections/SlipSheets';
import { CoverDocs } from './Sections/TableNotes/CoverDocs';
import { Tables } from './Sections/Tables';
import { SOFAsAndSchedulesMenu } from './SOFAsAndSchedulesMenu';
import './SOFAsAndSchedulesPage.css';

import { Layout } from 'components/Layout';
import { SofasExportContextProvider } from './Domain/SofasExportContextProvider';
import { ExportHistoryPanel } from './Sections/ExportHistory/ExportHistoryPanel';
import { Preview } from './Sections/Preview';
import { MENU_ITEMS } from './SOFAsAndSchedules.const';

export function SOFAsAndSchedulesPage() {
  return (
    <SofasExportContextProvider>
      <Layout>
        <HeaderBar />
        <div className="sofas-schedules-page">
          <SOFAsAndSchedulesMenu items={MENU_ITEMS} />

          <Outlet />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/export" element={<SofasSchedulesExportPanel />} />
            <Route path="/export-history" element={<ExportHistoryPanel />} />
            <Route path="/tables/:type/:section?/:part" element={<Tables />} />
            <Route path="/preview/:type" element={<Preview />} />
            <Route path="/notes/slipsheets/:type" element={<SlipSheets />} />
            <Route path="/signatories/*" element={<Signatories />} />
            <Route path="/cover-documents/:type" element={<GlobalNotes />} />
            <Route path="/notes/coverdocs/:type" element={<CoverDocs />} />
          </Routes>
        </div>
      </Layout>
    </SofasExportContextProvider>
  );
}

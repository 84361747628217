import { isEmpty, isNil, trim } from 'lodash';
import { ColumnBase } from 'utils/agGrid/agGridColumn';
import { isInt } from './hotchocolateUtils';
import { QueryFilter, QueryFilterItem, WhereQuery } from './types';

export function injectSearchQuery(
  whereQuery: WhereQuery,
  searchText: string,
  visibleColumns: string[],
  gridColumns: ColumnBase[],
): WhereQuery {
  const visibleGridColumns = gridColumns.filter((r) =>
    visibleColumns.includes(r.propertyName),
  );

  if (isEmpty(searchText) || isEmpty(visibleGridColumns)) return whereQuery;

  const searchItems = searchText
    .split(/[,;]/)
    .map(trim)
    .filter((r) => !isEmpty(r));

  let resultQuery = whereQuery;

  searchItems.forEach((text) => {
    resultQuery = addStringSearchCondition(resultQuery, visibleGridColumns, text);
    resultQuery = addIntegerSearchCondition(resultQuery, visibleGridColumns, text);
    resultQuery = addDecimalSearchCondition(resultQuery, visibleGridColumns, text);
  });

  return resultQuery;
}

function addStringSearchCondition(
  whereQuery: WhereQuery,
  requestGridColumns: ColumnBase[],
  searchText: string,
): WhereQuery {
  const stringColumns = requestGridColumns.filter((r) => r.dataType === 'string');
  if (stringColumns.length <= 0) return whereQuery;

  const variableId = whereQuery.variables.length;
  const whereCondition: QueryFilterItem[] = stringColumns.map((r) => ({
    [r.propertyName]: { contains: `$var${variableId}` },
  }));

  return {
    ...whereQuery,
    query: joinQuerySearchFilter(whereQuery.query, whereCondition),
    variables: [...whereQuery.variables, searchText],
  };
}

function addIntegerSearchCondition(
  whereQuery: WhereQuery,
  requestGridColumns: ColumnBase[],
  searchText: string,
): WhereQuery {
  const numberColumns = requestGridColumns.filter((r) => r.dataType === 'int');
  const searchNumber = +searchText;

  if (numberColumns.length <= 0 || !isStrNumberInt(searchNumber)) return whereQuery;

  const variableId = whereQuery.variables.length;
  const whereCondition = numberColumns.map((r) => ({
    [r.propertyName]: { eq: `$var${variableId}` },
  }));

  return {
    ...whereQuery,
    query: joinQuerySearchFilter(whereQuery.query, whereCondition),
    variables: [...whereQuery.variables, { type: 'int', value: searchNumber }],
  };
}

function addDecimalSearchCondition(
  whereQuery: WhereQuery,
  requestGridColumns: ColumnBase[],
  searchText: string,
): WhereQuery {
  const numberColumns = requestGridColumns.filter((r) => r.dataType === 'decimal');

  const searchNumber = +searchText;
  if (numberColumns.length <= 0 || !isFinite(searchNumber)) return whereQuery;

  const variableId = whereQuery.variables.length;
  const whereCondition = numberColumns.map((r) => ({
    [r.propertyName]: { eq: `$var${variableId}` },
  }));

  return {
    ...whereQuery,
    query: joinQuerySearchFilter(whereQuery.query, whereCondition),
    variables: [...whereQuery.variables, { type: 'decimal', value: searchNumber }],
  };
}

const joinQuerySearchFilter = (query: QueryFilter, items: QueryFilterItem[]) => {
  if (isNil(items)) return query;

  const queryOr = (query.or as QueryFilterItem[]) ?? [];
  return { ...query, or: [...queryOr, ...items] };
};

const isStrNumberInt = (value: number) => isFinite(value) && isInt(value);

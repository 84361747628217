import { ContractsUserGroupsResponse, UserInfo } from 'api/endpoints';
import { AssignedGroup } from 'api/endpoints/assigned-group-users';
import { Group, InternalOption } from 'components/UIComponents/GroupMultiSelect';
import partition from 'lodash/partition';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

const USERS_VALUE = 'users';
const GROUPS_VALUE = 'user-groups';

export type ReviewersGroupChecked = (ContractsUserGroupsResponse | AssignedGroup) & {
  checked?: boolean;
};

export type ReviewersBatchItem = {
  claimReviewerUserNames: number[];
  claimReviewerUserGroupIds: number[];
};

export type ContractReviewersBatchItem = {
  contractReviewerUserIds: number[];
  contractReviewerUserGroupIds: number[];
};

export function assignedGroupUsersToSelectGroups(
  groups: ReviewersGroupChecked[] | null,
  allUsers: UserInfo[],
): Group[] {
  if (!groups) return [];

  const selectGroupForUsers: Group = {
    label: 'Users',
    value: USERS_VALUE,
    options: allUsers.map((user: any) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: `${user.id}`,
      checked: user?.checked ?? false,
    })),
  };

  const selectGroupForUserGroups: Group = {
    label: 'User groups',
    value: GROUPS_VALUE,
    options: groups.map((item) => {
      const label =
        (item as ContractsUserGroupsResponse).name ??
        (item as AssignedGroup).userGroupName;

      return {
        label,
        value: `${item.id}`,
        checked: item?.checked ?? false,
      };
    }),
  };

  return [selectGroupForUsers, selectGroupForUserGroups];
}

export function selectOptionsToBatchItem(
  selected: InternalOption[],
  section: SectionType | undefined,
): ReviewersBatchItem | ContractReviewersBatchItem {
  const [usersOptions, groupsOptions] = partition(
    selected,
    (o) => o.groupInfo.value === USERS_VALUE,
  );

  const userNames = usersOptions.map(({ value }) => parseInt(value));
  const userGroups = groupsOptions.map(({ value }) => parseInt(value));

  const contractReviewers = {
    contractReviewerUserIds: [...userNames],
    contractReviewerUserGroupIds: [...userGroups],
  };

  const claimReviewers = {
    claimReviewerUserNames: [...userNames],
    claimReviewerUserGroupIds: [...userGroups],
  };

  return section === SectionType.Contracts
    ? { ...contractReviewers }
    : { ...claimReviewers };
}

import Select from 'components/UIComponents/Select';
import _ from 'lodash';
import { TAllowedEnums } from 'pages/ClaimsContractsToolPage/sections/ClaimsTable/ClaimsBatchEdit/claimsBatchEdit.type';
import { useMemo } from 'react';

const LABEL_TEXT = 'Value';

interface IProps {
  value: string | undefined;
  label?: string;
  allowedItems: TAllowedEnums | undefined;
  onChange: (value: string) => void;
}

export const ItemEditEnumarable = ({
  value,
  label = LABEL_TEXT,
  allowedItems,
  onChange,
}: IProps) => {
  const options = useMemo(() => {
    const pairs = _.toPairs(allowedItems)?.map(splitItemToOptions) ?? [];
    return withEmptyItem(pairs);
  }, [allowedItems]);

  const handleSelectChange = (r: React.ChangeEvent<HTMLSelectElement>) => {
    r.preventDefault();
    onChange(r?.target?.value);
  };

  return (
    <Select
      value={value}
      label={label}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

// ============  internal utils  ==============
type TOption = { label: string; value: string };
const withEmptyItem = (values: TOption[]) => {
  if (!values.length) return values;

  const emptyItem = { label: '', value: '' };
  return [emptyItem, ...values];
};

const splitItemToOptions = ([label, value]: string[]) => ({
  label,
  value,
});

// const handleChange =
//   (onChange: IProps['onChange']) => (r: React.ChangeEvent<HTMLSelectElement>) => {
//     r.preventDefault();
//     onChange(r?.target?.value);
//   };

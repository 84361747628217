import { IOption } from '@alixpartners/ui-components';

export const getMultiselectLablel = (
  selectedItems: IOption[],
  selectedText: string = 'selected',
) => {
  if (!selectedItems || selectedItems.length === 0) {
    return undefined;
  }

  if (selectedItems.length <= 2) {
    return selectedItems.map((r) => r.label).join(', ');
  }

  return `${selectedItems.length} ${selectedText}`;
};

import { IOption } from '@alixpartners/ui-components';
import { InputCheckbox } from 'components/UIComponents/InputCheckbox/InputCheckbox';
import { LabeledMultiSelectExtendable } from 'components/UIComponents/MultiSelectExtendable';
import { getMultiselectLablel } from 'pages/SOFAsAndSchedules/Domain/multiselectUtils';
import { useMemo } from 'react';
import {
  ExportDropdownItems,
  SelectedItems,
  SofasSelected,
} from '../sofaExportTypes';

interface Props {
  selected: SofasSelected;
  dropdownItems: ExportDropdownItems;
  onSelectedChange: (entities: SofasSelected) => void;
}

export const SofasFormExportPdf = ({
  selected,
  dropdownItems,
  onSelectedChange,
}: Props) => {
  return (
    <>
      <EntitiesInputs
        value={selected.entities}
        options={dropdownItems.entities}
        onChange={(entities) => onSelectedChange({ ...selected, entities })}
      />
      <PartsInputs
        value={selected.parts}
        options={dropdownItems.parts}
        onChange={(parts) => onSelectedChange({ ...selected, parts })}
      />
    </>
  );
};

const EntitiesInputs = (props: {
  value: SelectedItems;
  options: IOption[];
  onChange: (newValue: SelectedItems) => void;
}) => {
  const { value, options, onChange } = props;

  const handleUseAllChange = (useAll: boolean) => () =>
    onChange({ ...value, useAll });
  const handleChangeItems = (items: IOption[]) => onChange({ ...value, items });

  const labelString = useMemo(
    () => getMultiselectLablel(value.items, 'entities selected'),
    [value.items],
  );

  return (
    <div className="sofas-export-panel__input-group">
      <h5>Entities</h5>
      <div className="sofas-export-panel__radio-group">
        <InputCheckbox
          checked={value.useAll}
          onChange={handleUseAllChange(true)}
          type="radio"
        >
          All entities
        </InputCheckbox>
        <InputCheckbox
          checked={!value.useAll}
          onChange={handleUseAllChange(false)}
          type="radio"
        >
          Choose an entity
        </InputCheckbox>
      </div>
      {!value.useAll && (
        <LabeledMultiSelectExtendable
          topLabel="Search for entity"
          options={options}
          searchEnabled
          multiple
          selectAll
          selected={value.items}
          renderLabel={labelString}
          onChange={handleChangeItems}
        />
      )}
    </div>
  );
};

const PartsInputs = (props: {
  value: SelectedItems;
  options: IOption[];
  onChange: (newValue: SelectedItems) => void;
}) => {
  const { value, options, onChange } = props;

  const handleUseAllChange = (useAll: boolean) => () =>
    onChange({ ...value, useAll });
  const handleChangeItems = (items: IOption[]) => onChange({ ...value, items });

  const labelString = useMemo(
    () => getMultiselectLablel(value.items, 'parts selected'),
    [value.items],
  );

  return (
    <div className="sofas-export-panel__input-group">
      <h5>Parts</h5>
      <div className="sofas-export-panel__radio-group">
        <InputCheckbox
          checked={value.useAll}
          onChange={handleUseAllChange(true)}
          type="radio"
        >
          All Parts
        </InputCheckbox>
        <InputCheckbox
          checked={!value.useAll}
          onChange={handleUseAllChange(false)}
          type="radio"
        >
          Choose specific Parts
        </InputCheckbox>
      </div>

      {!value.useAll && (
        <LabeledMultiSelectExtendable
          menuClassName={'report-part-select'}
          topLabel="Search for Parts"
          options={options}
          searchEnabled
          multiple
          selected={value.items}
          selectAll
          renderLabel={labelString}
          onChange={handleChangeItems}
        />
      )}
    </div>
  );
};

import { ChangeEvent } from 'react';
import { SingleInput, SingleSpan } from './CustomComponents';
import { SingleInputSection } from './SingleInputSection';
import './SingleInputWithLabel.css';

interface Props {
  className?: string;
  label: string;
  readonly?: boolean;
  value?: string | null;
  preventBrowserAddressDialog?: boolean;
  onChange?: (value: string) => void;
}

export const SingleInputWithLabel = ({
  label,
  readonly,
  value,
  className,
  preventBrowserAddressDialog,
  onChange,
}: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onChange?.(e.target.value);
  };

  return (
    <SingleInputSection label={label} className={className}>
      {readonly ? (
        <SingleSpan>{value}</SingleSpan>
      ) : (
        <SingleInput
          type={'text'}
          value={value ?? ''}
          onChange={handleOnChange}
          preventBrowserAddressDialog={preventBrowserAddressDialog}
        />
      )}
    </SingleInputSection>
  );
};

import { useEffect } from 'react';
import { fetchUserDashboardTasksThunk } from 'reducer/thunks/dashboard-thunks';
import { FetchStatus } from 'types/fetch-status.types';
import { ApplicationUser } from 'utils/AppInitializer/application-user';
import { useAppDispatch, useAppSelector } from './reducerHooks';

export function useUserDashboardTasks(user?: ApplicationUser | null) {
  const dispatch = useAppDispatch();
  const slice = useAppSelector((s) => s.userDashboardTasks.slice);

  useEffect(() => {
    if (slice.status === FetchStatus.Idle && user) {
      dispatch(
        fetchUserDashboardTasksThunk({
          user,
          showMine: slice.showMine,
        }),
      );
    }
  }, [dispatch, user, slice.status, slice.showMine]);

  return slice;
}

import { IOption } from '@alixpartners/ui-components';
import { ReportType } from 'api/endpoints/sofas-schedules';
import {
  emptyDropdownItems,
  ExportDropdownItems,
  ExportSofasItems,
  SofasSelected,
} from './sofaExportTypes';

export const getPdfExportParams = (
  reportType: ReportType,
  dropdownItems: ExportDropdownItems,
  selected: SofasSelected,
) => {
  const selectedEntities = selected.entities.useAll
    ? dropdownItems.entities
    : sortItemsBy(selected.entities.items, dropdownItems.entities);

  const selectedParts = selected.parts.useAll
    ? []
    : sortItemsBy(selected.parts.items, dropdownItems.parts);

  const exportParams = {
    fileType: reportType,
    legalEntityIds: selectedEntities.map((o) => +o.value),
    partsIds: selectedParts.map((o) => o.value),
  };
  return exportParams;
};

export const filterDropdownItemsByType = (
  items: ExportSofasItems | undefined,
  reportType: ReportType,
) => {
  if (!items) {
    return emptyDropdownItems;
  }

  if (reportType === 'Sofa') {
    return items.sofa;
  }
  if (reportType === 'Schedule') {
    return items.schedule;
  }

  return emptyDropdownItems;
};

export const sortItemsBy = (items: IOption[], orderByList: IOption[]): IOption[] => {
  // return selected items in the same order as in itemsList
  const selectedIds = items.map((r) => r.value) || [];
  return orderByList.filter((r) => selectedIds.includes(r.value));
};

import { ApModal } from '@alixpartners/ui-components';
import { PdfDocumentPreview } from 'components/PdfDocumentPreview';
import { useApiQuery } from 'hooks/useApiQuery';
import { apiSettlementsAgreementTemplates } from '../../api/apiSettlementsAgreementTemplates';
import { SettlementsAgreementPreview } from '../../useSettlementsAgreementTemplates';
import './SettlementAgreemantPreview.css';

interface Props {
  agreemant: SettlementsAgreementPreview;
  onClose: () => void;
}

export const SettlementAgreemantPreview = ({ agreemant, onClose }: Props) => {
  const file = useApiQuery({
    queryFn: () => apiSettlementsAgreementTemplates.downloadPreview(agreemant.id),
  });

  return (
    <div>
      <ApModal
        className="settlement-agreemant-preview thin-scrollbar"
        header="Agreement Preview"
        isDismissible
        isOpen
        hasClosed={onClose}
      >
        <PdfDocumentPreview file={file.data} loading={file.loading} />
      </ApModal>
    </div>
  );
};

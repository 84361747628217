import classNames from 'classnames';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import _ from 'lodash';
import { MouseEventHandler } from 'react';
import './ActionList.css';
import { Progress } from './ClaimProgress';

export type ActionListProps = {
  uploadProgress: number;
  hideOnEmptyList?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export function ActionList(props: ActionListProps) {
  const { uploadProgress, hideOnEmptyList, children } = props;
  const emptyChildrenList = _.isEmpty(children);

  if (hideOnEmptyList && emptyChildrenList && uploadProgress < 0) {
    return null;
  }

  return (
    <ul className={'action-list'}>
      <Progress progress={uploadProgress} />
      {emptyChildrenList && !hideOnEmptyList ? <EmptyPanel /> : null}
      {children}
    </ul>
  );
}

export type ActionListItemProps = {
  newlyAdded?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  id: string | number;
  displayName: string;
  onDownloadClick: MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: MouseEventHandler<HTMLButtonElement>;
  onEditClick?: MouseEventHandler<HTMLButtonElement>;
};

export function ActionListItem(props: ActionListItemProps) {
  const {
    newlyAdded,
    children,
    id,
    displayName,
    onDeleteClick,
    onDownloadClick,
    onEditClick,
  } = props;
  return (
    <li
      className={classNames('action-list-item', {
        'action-list-item-newly-added': newlyAdded,
      })}
    >
      {children}
      <span className="action-list-item__actions">
        {onEditClick && (
          <>
            <ButtonIcon
              dataId={`${id}`}
              dataName={displayName}
              onClick={onEditClick}
              className="action-list-item__edit-btn"
              type="button"
              icon="edit"
              iconColor="var(--ap-color-ui-grey-light)"
            />
            <span className="action-list-item__actions__divider"></span>
          </>
        )}
        <ButtonIcon
          dataId={`${id}`}
          dataName={displayName}
          onClick={onDownloadClick}
          className="action-list-item__download-btn"
          type="button"
          icon="download_colorless"
          iconColor="var(--ap-color-ui-grey-light)"
        />
        <span className="action-list-item__actions__divider"></span>
        <ButtonIcon
          dataId={`${id}`}
          className="action-list-item__delete-btn"
          onClick={onDeleteClick}
          type="button"
          icon="trash"
          iconColor="var(--ap-color-ui-grey-light)"
        />
      </span>
    </li>
  );
}

export function FileDisplay(props: { displayName: string }) {
  return (
    <div className="file-display">
      <SvgIcon
        className={'file-display__icon'}
        iconName={'drive_file_icon'}
        fill="currentColor"
      />
      <span className="file-display__name">{props.displayName}</span>
    </div>
  );
}

export function EmptyPanel() {
  return <div className={classNames('action-list-empty-panel')}></div>;
}

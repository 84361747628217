import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

type STATE_EMPTY_ALLOWED = {
  generating?: boolean;
  jobId?: number;
  fileName?: string;
  section?: SectionType;
};

const initialState: STATE_EMPTY_ALLOWED = {
  generating: false,
  jobId: undefined,
  fileName: '',
  section: SectionType.NONE,
};

const claimsContractsExport = createSlice({
  name: 'claimsContractsExport',
  initialState,
  reducers: {
    setExportState: (state, action: PayloadAction<STATE_EMPTY_ALLOWED>) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const { setExportState } = claimsContractsExport.actions;
export default claimsContractsExport.reducer;

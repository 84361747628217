import { URL_PATH } from 'appUrls';
import {
  AccordionMenu,
  AccordionMenuSelectedItem,
} from 'components/AccordionMenu/AccordionMenu';
import { Layout } from 'components/Layout';
import { PageHeaderTitle } from 'components/PageHeaderTitle';
import { useMemo, useState } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { compactObject } from 'utils/lodashEx';
import { SettingsEnvName } from './components/SettingsEnvName';
import { settingsPageItems } from './SettingsPage.const';
import './SettingsPage.css';

export const SettingsPage = () => {
  const { selectedMenuItem, setSelectedMenuItem } = useSelectedItemUrlState();

  const bodyComponent = useMemo(() => {
    const { item, subItem } = selectedMenuItem;
    return (subItem ?? item)?.component ?? <></>;
  }, [selectedMenuItem]);

  const { isClient } = useAppUserType();

  if (isClient) {
    return <Navigate to={URL_PATH.HOME} />;
  }

  return (
    <Layout className={'settings-page'}>
      <PageHeaderTitle title="Settings" />
      <div className="settings-page-body">
        <div className="settings-page-left">
          <div className="settings-page-nav">
            <AccordionMenu
              items={settingsPageItems}
              selected={selectedMenuItem}
              onChange={setSelectedMenuItem}
            />
          </div>
          <SettingsEnvName className="settings-page__env-name" />
        </div>
        {bodyComponent}
      </div>
    </Layout>
  );
};

const useSelectedItemUrlState = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [menuItem, setMenuItem] = useState<AccordionMenuSelectedItem>(() =>
    parseUrlSearchParams(urlParams),
  );

  const setSelectedMenuItem = (selected: AccordionMenuSelectedItem) => {
    setUrlParams(
      compactObject({
        item: selected.item?.id,
        subItem: selected.subItem?.id,
      }),
    );

    setMenuItem(selected);
  };

  return {
    selectedMenuItem: menuItem,
    setSelectedMenuItem,
  };
};

const parseUrlSearchParams = (
  searchParams: URLSearchParams,
): AccordionMenuSelectedItem => {
  const itemId = searchParams.get('item');
  const subItemId = searchParams.get('subItem');

  const firstItem = settingsPageItems[0];
  const item = settingsPageItems.find((r) => r.id === itemId) ?? firstItem;

  const firstSubItem = item.subItems?.[0];
  const subItem = item?.subItems?.find((r) => r.id === subItemId) ?? firstSubItem;

  return { item, subItem };
};

import { SectionType } from "pages/ClaimsContractsToolPage/types";
import { MatchingSectionCard } from "./components/MatchingSectionCard"
import './MatchingHomepage.css';

interface MatchingHomePageProps {
  handleSectionChange: (section: SectionType) => unknown;
}

export const MatchingHomePage = ({ handleSectionChange }: MatchingHomePageProps) => {
  return (
    <div className="matching-homepage__container">
      <section className="matching-homepage__leftside">
        <div className="matching-homepage-leftside__container">
          <div className="matching-homepage-leftside__textcontainer">
            <h1>Welcome to the Matching page</h1>
            <p>Here you can either run the matching through the excel file or through the table in the UI.</p>
          </div>
          <div className="matching-homepage__cardscontainer">
            <MatchingSectionCard
              handleClick={() => handleSectionChange(SectionType.MatchingExport)}
              section="excel file"
              description="Use for matching data (claims, contracts, vendor IDs) in bulk through an excel file."
              buttonDescription="Start matching"
            />
            <MatchingSectionCard
              // handleClick={() => handleSectionChange(SectionType.Matching)}
              handleClick={() => {}}
              section="UI Table"
              isDisabled
              description="Best used for updating existing match and submatch codes"
              // buttonDescription="Update matchcodes"
              buttonDescription="Coming Soon"
            />
          </div>
        </div>
      </section>
      <section className="matching-homepage__rightside">
        <h2>
          Which option should I choose?
        </h2>
        <p>
          If you are looking to match multiple items (claims, contracts, vendor IDs) at once you should go with the excel file approach. 
        </p>
        <p>
          If you want to manually filter and update just a few items that the UI Table is the appropriate option.
        </p>
      </section>
    </div>
  );
}
import { ApButtonSecondary } from '@alixpartners/ui-components';
import Select from 'components/UIComponents/Select';
import { useMemo } from 'react';
import '../../../ContractsTable/ContractsBatchEdit/components/BatchEditLine.css';

import { useEditActionField } from 'hooks/useEditActionField';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { colProp } from '../claimsBatchEdit.const';
import {
  IBatchEditableColumns,
  IBatchItem,
  TAllowedEnums,
  TAllowedMajMin,
} from '../claimsBatchEdit.type';
import { getSelectOptions } from '../utils/columnItems';
import { ActionNameEdit } from './ActionNameEdit';
import { ClaimReviewersEdit } from './ClaimReviewersEdit';
import {
  ItemEditBoolean,
  ItemEditDate,
  ItemEditDateTime,
  ItemEditDecimal,
  ItemEditNumber,
  ItemEditText,
  ItemEditUnknown,
} from './ItemEdit';
import { ItemEditEnumarable } from './ItemEditEnumarable';
import { ItemEditMinorMajor } from './ItemEditMinorMajor';
import { SurvivingClaimEdit } from './SurvivingClaimEdit';

export type BatchEditLineProps<BI extends IBatchItem> = {
  item: BI;
  optionItems: IBatchEditableColumns[];
  onChange: (item: BI) => void;
  onRemove: (id: BI) => void;
};

export const BatchEditLine = <BI extends IBatchItem>(
  props: BatchEditLineProps<BI>,
) => {
  const { resetActionFields } = useEditActionField(SectionType.Claims);
  const { item, optionItems, onChange, onRemove } = props;

  const dataTypeOptions = useMemo(
    () => getSelectOptions(optionItems),
    [optionItems],
  );

  const handleTypeChange = (val: string) => {
    const col = optionItems.find((r) => r.propertyName === val);
    if (!col) return;
    if (col.dataType === 'group-multi-select') {
      onChange({
        ...item,
        propName: val,
        type: col.dataType,
        allowedValues: col.allowedValues,
        value: [],
      });
      return;
    }
    if (col.dataType === 'action-name-id') {
      // reset redux state here
      resetActionFields();
      onChange({
        ...item,
        propName: val,
        type: col.dataType,
        allowedValues: col.allowedValues,
        value: null,
      });
      return;
    }

    onChange({
      ...item,
      propName: val,
      type: col.dataType,
      allowedValues: col.allowedValues,
      value: '',
    });
  };

  const handleValueChange = (value: IBatchItem['value']) => {
    onChange({ ...item, value });
  };

  return (
    <div className="batchedit-line">
      <div className="batchedit-selections">
        <Select
          label="Select data type"
          options={dataTypeOptions}
          value={item.propName}
          onChange={(r) => handleTypeChange(r?.target?.value)}
        />
        <FieldValueEdit item={item} onChange={handleValueChange} />
      </div>
      <ApButtonSecondary className="ap-button-casual" onClick={() => onRemove(item)}>
        Remove
      </ApButtonSecondary>
    </div>
  );
};

export const FieldValueEdit = (props: {
  item: IBatchItem;
  onChange: (val: IBatchItem['value']) => void;
}) => {
  const { item, onChange } = props;
  switch (item.propName) {
    case colProp.survivingClaimNumber:
      return (
        <SurvivingClaimEdit
          label="Select value"
          value={item.value as string}
          onChange={onChange}
        />
      );
  }

  switch (item.type) {
    case 'int':
      return <ItemEditNumber value={item.value} onChange={onChange} />;
    case 'date':
      return <ItemEditDate value={item.value} onChange={onChange} />;
    case 'datetime':
      return <ItemEditDateTime value={item.value} onChange={onChange} />;
    case 'decimal':
      return <ItemEditDecimal value={item.value} onChange={onChange} />;
    case 'bool':
      return <ItemEditBoolean value={item.value} onChange={onChange} />;
    case 'string':
      return <ItemEditText value={item.value} onChange={onChange} />;
    case 'maj-min':
      return (
        <ItemEditMinorMajor
          value={item.value}
          allowedItems={item.allowedValues as TAllowedMajMin}
          onChange={onChange}
        />
      );
    case 'enum':
      return (
        <ItemEditEnumarable
          value={item.value}
          allowedItems={item.allowedValues as TAllowedEnums}
          onChange={onChange}
        />
      );
    case 'group-multi-select': {
      return <ClaimReviewersEdit label="Select value" onChange={onChange} />;
    }
    case 'action-name-id': {
      return <ActionNameEdit label="Resolution name" onChange={onChange} />;
    }
  }

  return <ItemEditUnknown />;
};

import { ApIcon } from '@alixpartners/ui-components';
import { useState } from 'react';

import './ErrorBoundaryView.css';

interface IProps {
  message?: string;
  stackTrace?: string;
}

export const ErrorBoundaryView = ({ message, stackTrace }: IProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="error-boundary">
      <div className="error-boundary__title">
        <div>
          <ApIcon iconName="outline_warning" iconColor="black" />
        </div>
        <div className="error-boundary__title-text">
          Sorry, something went wrong.
        </div>
      </div>
      <div className="error-boundary__body">
        <div className="error-boundary__body-text">
          {message ?? 'Please contact your CDO representative for help'}
        </div>
      </div>

      {!!stackTrace && (
        <div className="error-boundary__stackinfo">
          <button
            onClick={() => setIsExpanded((r) => !r)}
            className="error-boundary__view-details"
          >
            {isExpanded ? 'Hide' : 'View'} Details
          </button>

          {isExpanded && (
            <div className="error-boundary__stack-trace">{stackTrace}</div>
          )}
        </div>
      )}
    </div>
  );
};

import {
  ClaimsMenuSectionTabs,
  SectionItem,
} from 'components/ClaimsMenuSectionTabs';
import { ClaimsGoBackButton } from 'components/ClaimsMenuSectionTabs/ClaimsGoBackButton';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

import { URL_PATH } from 'appUrls';
import { useNavigate } from 'react-router';
import './ExhibitExporterTopMenu.css';

const SELECTED_ITEM = SectionType.ExhibitsExport;

export const ExhibitExporterTopMenu = () => {
  const navigate = useNavigate();

  const handleClickBack = () =>
    navigate({ pathname: URL_PATH.CLAIMS, search: `?section=${SELECTED_ITEM}` });

  const handleSectionClick = (item: SectionItem) => {
    navigate({ pathname: URL_PATH.CLAIMS, search: `?section=${item.type}` });
  };

  return (
    <ClaimsMenuSectionTabs
      selected={SELECTED_ITEM}
      additional={<ClaimsGoBackButton onClick={handleClickBack} />}
      onSectionClick={handleSectionClick}
    />
  );
};

import './SubMatchCodeCell_.css';
import { ICellRendererParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { CellTextInput } from './CellTextInput';
export interface SubMatchCodeCellParams extends ICellRendererParams<ClaimRow, any> {
  onUpdateSubmatchCode: (
    id: number,
    matchCode: number,
    subMatchCode: number | undefined,
    cellProps: ICellRendererParams<ClaimRow, any>,
  ) => unknown;
  onLostFocus: () => unknown;
  isEditable: boolean;
}

export const SubMatchCodeCell = ({
  isEditable,
  onUpdateSubmatchCode,
  onLostFocus,
  ...props
}: SubMatchCodeCellParams) => {
  const { data } = props;

  // we temporarily enable MatchCode editing for all tabs
  // after we refactor matching, we'll have to re-enable this logic
  const temporarilyEnableEditing = true;

  if (!data?.matchCode) return <div>- -</div>;
  if (isEditable || temporarilyEnableEditing) {
    return (
      <div className={'submatch-code-cell'}>
        <CellTextInput
          placeholder="Submatch code"
          length={9}
          value={`${data?.subMatchCode ?? ''}`}
          onChange={(v) => {
            data?.id &&
              onUpdateSubmatchCode(
                data.id,
                data.matchCode,
                v ? +v : undefined,
                props,
              );
          }}
          onLostFocus={onLostFocus}
        />
      </div>
    );
  }

  return <div className={'submatch-code-cell'}>{data?.subMatchCode}</div>;
};

import { FileStatusIcon } from 'pages/DataUploaderPage/components/FileStatusIcon';
import { FileStatus, IUploadItem } from 'types/dataUploaderTypes';
import { formatUtcTimeAgo } from 'utils/format';

export const DeleteDataItem = ({
  file,
  showIcon,
}: {
  file: IUploadItem;
  showIcon?: boolean;
}) => {
  return (
    <>
      <article className="file-name">
        {showIcon && (
          <FileStatusIcon
            fileStatus={file.status}
            isError={!!file.errorCount}
            iconColor={
              file.status === FileStatus.Published
                ? 'var(--ap-color-ui-green-primary)'
                : ''
            }
          />
        )}
        <p>
          {file.displayName}
          <span>({file.status})</span>
        </p>
      </article>
      {file.uploadedFile?.createdBy && (
        <article className="file-author">
          <p>
            Uploaded by {file.uploadedFile?.createdBy}
            {file.uploadedFile?.createdDate && (
              <span>{formatUtcTimeAgo(file.uploadedFile?.createdDate)}</span>
            )}
          </p>
        </article>
      )}
    </>
  );
};

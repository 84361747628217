import _ from 'lodash';

/**
 * Checks if `value` is not `null` or `undefined`.
 *
 * @example
 * _.isNotNil(null);      // => false
 * _.isNotNil(undefined); // => false
 * _.isNotNil('aaaa');    // => true
 * _.isNotNil(0);         // => true
 */
export const isNotNil = <T>(value: T | null | undefined): value is T =>
  !_.isNil(value);

/**
 * Checks if `value` is not `empty`.
 *
 * @example
 * _.isNotEmpty(null);  // => false
 * _.isNotEmpty('');    // => false
 * _.isNotEmpty(0);     // => false
 * _.isNotEmpty({});    // => false
 * _.isNotEmpty([]);    // => false
 * _.isNotEmpty('aaaa');  // => true
 */
export const isNotEmpty = <T>(value: T | null | undefined): value is T =>
  !_.isEmpty(value);

/**
 * Removes null and undefined properties from object
 * @example
 * _.compactObject({ a: 1, c: null });      // => { a: 1 }
 * _.compactObject({ b: 1, d: undefined }); // => { b: 1 }
 */
export const compactObject = <T extends object>(obj: T) => _.pickBy(obj, isNotNil);

import { ApMenuItem } from '@alixpartners/ui-components';
import { DropdownMenuMain } from 'components/DropdownMenu/DropdownMenuMain';
import { useAppSelector } from 'hooks/reducerHooks';

import { useState } from 'react';
import './AdditionalPanel.css';
import { LoadingMenuItem } from './components/LoadingMenuItem/LoadingMenuItem';
import { exportContractTable } from './excelExport/exportContractTable';
import { exportClaimsTable } from './excelExport/exportClaimTable';
import { assertUnreachable } from 'utils/assertUnreachable';

type SectionType = 'Claims' | 'Contracts';

export const AdditionalPanel = ({ isClient }: { isClient: boolean }) => {
  if (isClient) return null;
  return (
    <div className="dashboard__additional-panel">
      <ExcelExportDropdown />
    </div>
  );
};

const ExcelExportDropdown = () => {
  const [isExporting, setIsExporting] = useState(false);
  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);

  const onExport = async (sectionType: SectionType) => {
    setIsExporting(true);

    switch (sectionType) {
      case 'Claims':
        await exportClaimsTable(showMine);
        break;
      case 'Contracts':
        await exportContractTable(showMine);
        break;
      default:
        assertUnreachable(sectionType);
    }

    setIsExporting(false);
  };

  return (
    <DropdownMenuMain
      label="Export"
      iconName="save_alt"
      thinShape
      disableCloseOnSubmenuClick={isExporting}
      buttonClassname={'dashboard__export-dropdown'}
      className={'dashboard__export-menu'}
    >
      {isExporting ? (
        <LoadingMenuItem text="Generating Excel file" />
      ) : (
        <>
          <ApMenuItem onClick={() => onExport('Contracts')}>
            Contracts Dashboard
          </ApMenuItem>
          <ApMenuItem onClick={() => onExport('Claims')}>
            Claims Dashboard
          </ApMenuItem>
        </>
      )}
    </DropdownMenuMain>
  );
};

import { useApiQuery } from 'hooks/useApiQuery';
import { apiSettlementsAgreementTemplates } from 'pages/SettingsPage/sections/SettlementsAgreementTemplates/api/apiSettlementsAgreementTemplates';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import {
  apiSettlementSingle,
  emptyUserList,
  SingleSettlement,
  UserList,
} from '../api/apiSettlementSingle';
import { validateSingleSettlement } from './settlementSingleContextUtils';
import { useSettlementAgreemant } from './useSettlementAgreemant';

type DialogInfo = {
  title: string;
  message: string;
  onClose: () => void;
};

interface SettlementSingleData {
  id: number;
  claimsField: string;
  loading: boolean;
  data: SingleSettlement | undefined;
  userList: UserList;
  isAllFieldsSet: boolean;
  infoDialog: DialogInfo | undefined;
  agreemantDocument: ReturnType<typeof useSettlementAgreemant>;
  template: { name: string; exists: boolean };

  setFieldValue: (
    field: keyof SingleSettlement,
  ) => (value: SingleSettlement[keyof SingleSettlement]) => void;
  push: () => Promise<boolean>;
}

const SettlementSingleContext = createContext<SettlementSingleData>(
  {} as SettlementSingleData,
);

export const SettlementSingleContextProvider = (props: PropsWithChildren<{}>) => {
  const id = Number(useParams().id) || 0;
  const [isAllFieldsSet, setIsAllFieldsSet] = useState(false);
  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const [infoDialog, setInfoDialog] = useState<DialogInfo>();

  const settlementItem = useApiQuery({
    queryFn: () => apiSettlementSingle.getData(id),
  });

  const userList = useApiQuery({
    queryFn: () => apiSettlementSingle.getUsersList(),
  });

  const agreemantTemplate = useApiQuery({
    queryFn: () => apiSettlementsAgreementTemplates.getTemplate(),
  });

  const data = settlementItem.data;

  const agreemantDocument = useSettlementAgreemant({
    id,
    fileName: data?.agreementFileName,
    onGenerateNew: () => settlementItem.fetch(),
  });

  const revalidateData = () => {
    const validation = validateSingleSettlement(data);
    setIsAllFieldsSet(validation.valid);
  };

  const claimsField = useMemo(
    () => data?.claims.map((r) => r.name).join(', ') ?? '',
    [data?.claims],
  );

  const push = async () => {
    setIsDataUpdating(true);
    const result = await apiSettlementSingle.update(data);
    setIsDataUpdating(false);

    if (!result) return false;

    setInfoDialog({
      title: 'Success',
      message: 'Settlement updated',
      onClose: () => setInfoDialog(undefined),
    });
    return result;
  };

  const setFieldValue =
    (field: keyof SingleSettlement) =>
    (value: SingleSettlement[keyof SingleSettlement]): void => {
      if (!data) return;
      const newData: SingleSettlement = { ...data, [field]: value };
      settlementItem.setData(newData);

      revalidateData();
    };

  const template = useMemo(() => {
    const { data, loading } = agreemantTemplate;
    return {
      name: data?.displayName ?? '',
      exists: !!data || loading,
    };
  }, [agreemantTemplate]);

  return (
    <SettlementSingleContext.Provider
      value={{
        id,
        claimsField,
        data,
        userList: userList.data ?? emptyUserList,
        loading: settlementItem.loading || userList.loading || isDataUpdating,
        isAllFieldsSet,
        infoDialog,
        agreemantDocument,
        template,
        setFieldValue,
        push,
      }}
    >
      {props.children}
    </SettlementSingleContext.Provider>
  );
};

export const useSettlementSingleContext = () => useContext(SettlementSingleContext);

import classNames from 'classnames';
import './ErrorMessage.css';
import { ApIcon } from '@alixpartners/ui-components';

export const ErrorMessage = ({
  errorMessage,
  className,
}: {
  errorMessage: string;
  className?: string;
}) => {
  return (
    <h6 className={classNames('error-message', className)}>
      <ApIcon iconName="outline_warning" iconColor="var(--ap-color-ui-red-alert)" />
      <span>{errorMessage}</span>
    </h6>
  );
};

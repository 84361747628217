import { GridColumn } from 'api/endpoints';
import { Group } from 'components/UIComponents/GroupMultiSelect';
import uniqueId from 'lodash/uniqueId';
import { ReviewersBatchItem } from '../../../../../components/ReviewersEdit/ReviewersEdit.utils';

interface Dictionary<T> {
  [index: string]: T;
}

export type TAllowedEnums = Dictionary<string>;
export type TAllowedMajMin = Dictionary<TAllowedEnums>;
export type TAllowedValues = TAllowedEnums | TAllowedMajMin;

export type TBatchDataType = GridColumn['dataType'] | 'maj-min' | undefined;

export type DefaultBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: TBatchDataType;
  allowedValues?: TAllowedValues;
  value: string | undefined;
};

export type ClaimsReviewersBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: 'group-multi-select';
  allowedValues?: Group[];
  value: ReviewersBatchItem | undefined;
};

export type ActionNameBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: 'action-name-id';
  allowedValues?: Group[];
  value: number | undefined;
};

export type IBatchItem =
  | DefaultBatchItem
  | ClaimsReviewersBatchItem
  | ActionNameBatchItem;

export const emptyItem = (): IBatchItem => ({
  id: uniqueId(),
  propName: '',
  type: undefined,
  value: undefined,
});

export type IBatchEditableColumns = {
  disabled?: boolean;
  propertyName: GridColumn['propertyName'];
  displayName: GridColumn['displayName'];
  dataType: TBatchDataType | 'group-multi-select' | 'action-name-id';
  allowedValues?: TAllowedValues | Group[];
};

export interface IBatchEditCategories {
  categories: TAllowedMajMin | undefined;
}

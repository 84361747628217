import dayjs from 'dayjs';
import { downloadFile, get, post } from '../apiRequest';

export enum CounterpartyMatchStatus {
  Matched = 'Matched',
  NotMatched = 'NotMatched',
}
export interface Counterparty {
  id: number;
  counterpartyName: string;
  matchCode: number;
  score: number;
  address1: string;
  address2: string;
  address3: string;
  address4: string | null;
  city: string;
  state: string;
  zip: string;
  matchStatus?: CounterpartyMatchStatus | undefined;
}

export interface CounterpartyRow {
  claimId: number;
  matches: Counterparty[];
}

export interface CounterpartyWithPathId extends Omit<Counterparty, 'id'> {
  id: string;
  claimId: number;
  counterpartyId: number;
}
export interface CounterpartyRowWithPathId {
  claimId: number;
  hasOneHundredPercentMatch: boolean;
  matches: CounterpartyWithPathId[];
}

export interface NewMatchCode {
  id: number;
}

export interface ApproveMatchData {
  claimId: number;
  matchCode: number;
}
export interface ApproveAllMatchData extends Array<number> {}

export interface ManualMatchData
  extends Array<{
    claimId: number;
    matchCode?: number | null;
    subMatchCode?: number | null;
  }> {}

export interface ManualContractMatchData
  extends Array<{
    contractId: number;
    matchCode?: number | null;
  }> {}

export interface UpdateMatchData {
  claimId: number;
  matchCode: number | null;
  subMatchCode?: number | null;
}

type CustomStatusResponse = {
  CurrentStep: number;
  Message: string;
  ProcessingStatus: MatchStatus;
  TotalSteps: number;
};

type StatusResponse = {
  instanceId: string;
  runtimeStatus: MatchStatus;
  input: string;
  customStatus: CustomStatusResponse;
  output: string;
  createdTime: string;
  lastUpdatedTime: string;
  isFailed: true;
};

export enum MatchStatus {
  Running = 'Running',
  Pending = 'Pending',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Terminated = 'Terminated',
  Completed = 'Completed',
  Suspended = 'Suspended',
  PublishFailed = 'PublishFailed',
}

export const match = {
  // matchingSuggestionsFile_2023-03-14_16-54-38
  getMatchingExport: () =>
    downloadFile(
      'v1/Claims/claimsMatchingExport',
      `matchingSuggestionsFile_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`,
    ),
  getMatches: () => get<CounterpartyRow[]>('v1/Match'),
  getNewMatchcode: () => get<NewMatchCode>('v1/Match/new-matchcode'),
  approveMatch: (body: ApproveMatchData) => post('v1/Match/approve', body),
  rejectMatch: (counterpartyId: number) =>
    post(`v1/Match/reject/${counterpartyId}`, {}),
  rejectAll: (claimId: number) => post(`v1/Match/reject-all/${claimId}`, {}),
  approveHundredPercentMatches: (body: ApproveAllMatchData) =>
    post('v1/Match/approveHundredPercentMatches', body),
  manualMatch: (body: ManualMatchData) => post('v1/Match/manualMatch', body),
  manualContractMatch: (body: ManualContractMatchData) =>
    post('v1/Match/manualContractMatch', body),
  updateMatch: (body: UpdateMatchData) => {
    return post(`v1/Match/updateMatch/`, body);
  },
  postNotMatch: (claimId: number) => {
    return post(`v1/Match/notMatch/${claimId}`, {});
  },
  getTrumpedSchedules: (body: UpdateMatchData) =>
    post<string[]>('v1/Match/GetTrumpedSchedules', body),
  getStatus: () => get<StatusResponse>('v1/Match/status'),
  trigger: () => post('v1/Match/trigger', {}),
};

import classNames from 'classnames';
import './SingleInputSection.css';

type SingleInputSectionProps = React.ComponentProps<'div'> & {
  label: string;
};

export const SingleInputSection = ({
  label,
  className,
  children,
  ...props
}: SingleInputSectionProps) => {
  return (
    <div className={classNames('single-input-section', className)} {...props}>
      <div className="single-input-section__label">{label}</div>
      <div className="single-input-section__body">{children}</div>
    </div>
  );
};

import { ClaimRow, ContractRow } from 'api/endpoints';
import { ContractReviewerData } from '../../reducer/contractReviewerReducer';
import { filedClaimsHeaders } from './Counterparty.const';

export type ClaimTallyItem = {
  label: string;
  count?: number;
  secured: number;
  admin: number;
  priority: number;
  unsecured: number;
  total: number;
};

export type ClaimTallyItemFormatted = ClaimTallyItem & {
  count_formatted?: string;
  secured_formatted: string;
  admin_formatted: string;
  priority_formatted: string;
  unsecured_formatted: string;
  total_formatted: string;
};

export type ScheduledClaimItem = ClaimRow & {
  claimImageFilePath: string;
  actionName: string;
  actionSubStatusName: string;
};

export type ScheduledClaimItemFormatted = ClaimRow & {
  originalTotal_formatted?: string;
  currentTotal_formatted?: string;
  proposedTotal_formatted?: string;
  officialClaimDate_formatted?: string;
  currentDisputed_formatted?: string;
  currentContingent_formatted?: string;
  currentUnliquidated_formatted?: string;
};

export type ContractItemFormatted = ContractRow & {
  rejectionDamageLow_formatted: string;
  rejectionDamagesHigh_formatted: string;
  contractsReviewers: string;
};

export type ContractRowFormatted = ContractRow & {
  contractsReviewers: ContractReviewerData;
};

export type FiledClaimItemFormatted = ClaimRow & {
  currentTotal_formatted?: string;
  officialClaimDate_formatted?: string;
  originalTotal_formatted?: string;
  clientTotal_formatted?: string;
  proposedTotal_formatted?: string;
  counselTotal_formatted?: string;
  highTotal_formatted?: string;
  lowTotal_formatted?: string;
  currentDisputed_formatted?: string;
  currentContingent_formatted?: string;
  currentUnliquidated_formatted?: string;
};

export type ClaimItemFormatted = FiledClaimItemFormatted &
  ScheduledClaimItemFormatted & { empty?: boolean };

export type claimKey = keyof typeof filedClaimsHeaders;

export enum ClaimTableType {
  SCHEDULED = 'Scheduled',
  FILED = 'Filed',
}

import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ReactNode, useState } from 'react';
import './AccordionSection.css';

type Props = React.PropsWithChildren<{
  header: ReactNode;
  className?: string;
  defaultExpanded?: boolean;
  hasAccordion?: boolean;
  caretSize?: number;
}>;

export const AccordionSection = ({
  header,
  children,
  defaultExpanded = false,
  hasAccordion = true,
  caretSize = 12,
  className,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const triggerExpanded = () => setExpanded((r) => !r);

  const handleClick = hasAccordion ? triggerExpanded : undefined;

  return (
    <div className={classNames('accordion-section', className)}>
      <div className="accordion-section__header" onClick={handleClick}>
        <div className="accordion-section__header-text">{header}</div>
        {hasAccordion && (
          <div
            className={classNames('accordion-section__show', {
              'accordion-icon-rotate': expanded,
            })}
          >
            <SvgIcon iconName="caret_inv" width={caretSize} />
          </div>
        )}
      </div>

      {expanded && <div className="accordion-section__content">{children}</div>}
    </div>
  );
};

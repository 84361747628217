import classNames from 'classnames';
import { InputComponent } from 'components/UIComponents/EntityTable';
import './ActionNames.css';
import { Datepicker } from 'components/UIComponents/Datepicker';
import { formatDate } from 'utils/formatDate';
import { useAppSelector } from 'hooks/reducerHooks';
import { FetchStatus } from 'types/fetch-status.types';
import { useEffect } from 'react';
import { ActionName } from 'api/endpoints/action-names';
import { useActionsSelectOptions } from './useActionNames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export const ActionNameTypeInput: InputComponent<ActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className, getValue, setValue } = fromTable;
  //TODO implement logic for dynamic arg based on section
  const { actionNameOptions, status: optionsStatus } = useActionsSelectOptions(
    SectionType.Claims,
  );

  const options = actionNameOptions.data.actionTypes;

  useEffect(() => {
    if (item && item[columnKey]) {
      setValue(columnKey, item[columnKey]);
    }
  }, [columnKey, item, setValue]);

  const selectedTypeId = getValue(columnKey) ?? '';
  return (
    <select
      name={columnKey}
      className={classNames(className, 'utils-clean-input')}
      required
      disabled={disabled || optionsStatus !== FetchStatus.Done}
      defaultValue={item?.typeId}
      value={selectedTypeId}
      onChange={(e) => setValue(columnKey, +e.target.value)}
    >
      {/* placeholder */}
      <option disabled value="">
        Select resolution type
      </option>
      {options.map((o) => (
        <option key={o.id} value={o.id}>
          {o.actionTypeName}
        </option>
      ))}
    </select>
  );
};

export const ActionNameSubstatusInput: InputComponent<ActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className, getValue } = fromTable;
  const selectedTypeId = getValue('typeId') ?? item?.typeId;
  const options =
    useAppSelector(
      (s) =>
        s.actionsSelectOptions.data.actionSubstatusesByActionType[selectedTypeId],
    ) ?? [];

  const defaultSubstatusId = item?.substatusId ?? '';

  return (
    <select
      name={columnKey}
      className={classNames(className, 'utils-clean-input')}
      required
      disabled={disabled}
      defaultValue={defaultSubstatusId}
    >
      {/* placeholder */}
      <option disabled value="">
        Select substatus
      </option>
      {options.map((o) => (
        <option key={o.id} value={o.id}>
          {o.actionSubStatusName}
        </option>
      ))}
    </select>
  );
};

export const ActionNameHearingDateInput: InputComponent<ActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className, getValue } = fromTable;
  const selectedTypeId = getValue('typeId');

  const required = useAppSelector(
    (s) =>
      s.actionsSelectOptions.data.hearingDateRequiredByActionType[selectedTypeId] ??
      false,
  );

  const cn = classNames(className, 'utils-clean-input');
  return (
    <Datepicker
      placeholder={item ? '' : 'Select date'}
      required={required}
      disabled={disabled}
      formatLabel={formatDate}
      defaultValue={item?.hearingDate}
      className={cn}
      name={columnKey}
    />
  );
};

export const ActionNameInput: InputComponent<ActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className } = fromTable;
  if (columnKey === 'name') {
    return (
      <input
        type="text"
        name={columnKey}
        maxLength={50}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        placeholder="Enter name"
        defaultValue={item?.name}
      />
    );
  }
  if (columnKey === 'typeId') {
    return (
      <ActionNameTypeInput columnKey={columnKey} fromTable={fromTable} item={item} />
    );
  }
  if (columnKey === 'substatusId') {
    return (
      <ActionNameSubstatusInput
        columnKey={columnKey}
        fromTable={fromTable}
        item={item}
      />
    );
  }
  if (columnKey === 'hearingDate') {
    return (
      <ActionNameHearingDateInput
        columnKey={columnKey}
        fromTable={fromTable}
        item={item}
      />
    );
  }

  return null;
};

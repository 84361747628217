import { downloadFile, get } from 'api/apiRequest';
import { FormatType, GridColumn, gridFieldToFormatType } from './configuration';

export type BaseLogEntry = {
  id: number;
  logTime: string;
  user: string;
  comment: string;
};

export type BaseFieldChange<T> = {
  id: number;
  displayName: string | null;
  propertyName: string;
  from: T | null;
  to: T | null;
};

export type StringFieldChange = BaseFieldChange<string> & {
  dataType: 'string';
};

export type EnumFieldChange = BaseFieldChange<string> & {
  dataType: 'enum';
};

export type BoolFieldChange = BaseFieldChange<boolean> & {
  dataType: 'bool';
};

export type IntFieldChange = BaseFieldChange<number> & {
  dataType: 'int';
};

export type DecimalFieldChange = BaseFieldChange<number> & {
  dataType: 'decimal';
};

export type CurrencyFieldChange = BaseFieldChange<number> & {
  dataType: 'currency';
};

export type DateFieldChange = BaseFieldChange<string> & {
  dataType: 'date';
};

export type DatetimeFieldChange = BaseFieldChange<string> & {
  dataType: 'datetime';
};

export type FieldChange =
  | DecimalFieldChange
  | DateFieldChange
  | CurrencyFieldChange
  | BoolFieldChange
  | DatetimeFieldChange
  | EnumFieldChange
  | IntFieldChange
  | StringFieldChange;

export type FieldChagesLogEntry = BaseLogEntry & {
  type: 'field-changes';
  fieldChanges: FieldChange[];
};

export type FileAddLogEntry = BaseLogEntry & {
  type: 'file-add';
  addedFile: {
    id: number;
    displayName: string;
    name: string;
  };
};

export type FileRemoveLogEntry = BaseLogEntry & {
  type: 'file-remove';
  addedFile: {
    id: number;
    displayName: string;
    name: string;
  };
};

export type LogEntry = FieldChagesLogEntry | FileAddLogEntry | FileRemoveLogEntry;

export type ClaimContractLogPaginationSort =
  | 'created-date-asc'
  | 'created-date-desc';

export type LogEntryParams = {
  claimId: number;
  page: number;
  sort: ClaimContractLogPaginationSort;
};

export type LogEntryResult = {
  entries: LogEntry[];
  currentPage: number;
  currentSort: ClaimContractLogPaginationSort;
  totalPages: number;
  totalEntries: number;
  entriesPerPage: number;
  claimId: number;
  _requestTime?: string;
};

export const claimLogs = {
  get: async (claimId: number) => {
    const _requestTime = new Date().toISOString();

    const _result = await get<LogEntryResult>(
      `v1/ClaimChangeLog?claimId=${claimId}`,
    );
    // filter out anything that is not type 'field-changes' as the BE is still to be defined for 'file-add' and 'file-remove'
    if (_result) {
      _result.entries = _result.entries.filter((le) => le.type === 'field-changes');
    }
    const result = destringifyFieldValues(_result);
    if (!result) return null;

    result.claimId = claimId;
    result._requestTime = _requestTime;

    // BE does not yet support the other fields apart from entries
    // filling up here
    result.currentPage = 1;
    result.currentSort = 'created-date-desc';
    result.entriesPerPage = result.entries.length + 1;
    result.totalPages = 1;
    result.totalEntries = result.entries.length;

    return result;
  },

  excelExportAll: (fileName: string) =>
    downloadFile('v1/ClaimChangeLog/excelExport/all', fileName),
};

/**
 * The sole purpuse of this function is to map values returned by the server such as strings ("null", "True")
 * when the web app expects JSON values (null, true);
 * Mapping this as close as possible to the response so that we use the actual values consistently.
 */
export function destringifyFieldValues(result: LogEntryResult | undefined) {
  if (!result) return;

  const cleanEntries: LogEntry[] = result.entries.map((le) => {
    if (le.type === 'field-changes') {
      const newFieldChanges = le.fieldChanges.map((fc) => {
        const formatType: FormatType = gridFieldToFormatType({
          propertyName: fc.propertyName,
          dataType: fc.dataType as GridColumn['dataType'],
        });

        fc.from = destringfyNull(fc.from);
        fc.to = destringfyNull(fc.to);

        if (fc.dataType === 'bool') {
          fc.from = destringfyBool(fc.from);
          fc.to = destringfyBool(fc.to);
        }

        fc.dataType = formatType;
        return fc;
      });

      le.fieldChanges = newFieldChanges;
      return le;
    }

    return le;
  });

  return {
    ...result,
    entries: cleanEntries,
  };
}

function destringfyNull(val: any): any {
  if (val === 'null') return null;
  return val;
}
function destringfyBool(val: any): boolean | null {
  if (val === 'True') return true;
  if (val === 'False') return false;
  return null;
}

import { ApTextInput } from '@alixpartners/ui-components';
import { BasicClientInfo } from 'api/endpoints';
import classNames from 'classnames';

import { useCallback, useState } from 'react';
import {
  SetupWizardStepPanel,
  WizardColorSelector,
  WizardErrorInformation,
  WizardFieldLabel,
  WizardLogoSelector,
} from '../component';

import './ClientInfo.css';

type TErrorType = 'clientCompanyName' | 'logo';

interface ClientBasicInfoProps {
  clientInfo: BasicClientInfo;
  setClientInfo: (ci: BasicClientInfo) => unknown;
  saveClientInfo: () => unknown;
  setLogo: (logoContent: ArrayBuffer, filename: string, fileType: string) => unknown;
}

export const ClientInfo = ({
  clientInfo,
  setClientInfo,
  saveClientInfo,
  setLogo,
}: ClientBasicInfoProps) => {
  const {
    clientCompanyName,
    internalProjectName,
    primaryBrandColor,
    secondaryBrandColor,
  } = clientInfo;

  const setClientInfoDetailsField = useCallback(
    (key: keyof BasicClientInfo, value: string) =>
      setClientInfo({
        ...clientInfo,
        [key]: value,
      }),
    [clientInfo, setClientInfo],
  );

  const [error, setError] = useState<TErrorType[]>();

  const validateData = () => {
    let result: TErrorType[] = [];
    if (!clientCompanyName || !clientCompanyName.trim()) {
      result = [...result, 'clientCompanyName'];
    }

    setError(result);
    return !result.length;
  };

  return (
    <SetupWizardStepPanel
      className="setup-wizard-step01"
      title={'Basic client information'}
      helpText={helpText}
      hideBackButton
      nextButtonText="Save & Continue"
      validate={validateData}
      nextStep={() => saveClientInfo()}
    >
      <div className="setup-wizard-section">
        <WizardFieldLabel text="Client company name *" />
        <ApTextInput
          id=""
          value={clientCompanyName}
          onChange={(e: React.FormEvent<any>) =>
            setClientInfoDetailsField('clientCompanyName', e.currentTarget.value)
          }
        ></ApTextInput>
        {error?.includes('clientCompanyName') && (
          <WizardErrorInformation text="Please enter company name" />
        )}
      </div>

      <div className="setup-wizard-section">
        <WizardFieldLabel text="Internal project name" />
        <div className="setup-wizard-field__fixedvalue">{internalProjectName}</div>
      </div>

      <div className="setup-wizard-section">
        <WizardFieldLabel text="Logo" info="Please, upload logo" />
        <WizardLogoSelector
          onLogoSelected={(content, filename, fileType) => {
            setLogo(content, filename, fileType);
          }}
        />
      </div>

      <div className={classNames('setup-wizard-section', 'brand-color')}>
        <div>
          <WizardFieldLabel
            text="Brand color"
            info="Please, select primary brand color"
          />
          <WizardColorSelector
            color={primaryBrandColor || ''}
            onChange={(color) =>
              setClientInfoDetailsField('primaryBrandColor', color)
            }
          />
        </div>

        <div>
          <WizardFieldLabel
            text="Brand color"
            info="Please, select secondary brand color"
          />
          <WizardColorSelector
            color={secondaryBrandColor || ''}
            onChange={(color) =>
              setClientInfoDetailsField('secondaryBrandColor', color)
            }
          />
        </div>
      </div>
    </SetupWizardStepPanel>
  );
};

const helpText = (
  <>
    Welcome to the Alix Partners Chapter 11 Platform.
    <p />
    To begin a new project please complete the basic project information making sure
    to fill out all required fields marked with *.
  </>
);

import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApIcon,
  ApLoaderDefault,
} from '@alixpartners/ui-components';

import './BatchEditUploadForm.css';

interface Props {
  title: string;
  hasError: boolean;
  onDownloadErrorClick: () => void;
  onDownloadTemplateClick: () => void;
  setShowUploadModal: (show: boolean) => void;
  isUploading?: boolean;
  fileName?: string;
}

export const BatchEditUploadForm = ({
  title,
  hasError,
  onDownloadErrorClick,
  onDownloadTemplateClick,
  isUploading,
  fileName,
  setShowUploadModal,
}: Props) => {
  if (isUploading && fileName) {
    return (
      <h4>
        Uploading {fileName}
        <ApLoaderDefault loaderSize="sm" />
      </h4>
    );
  }
  return (
    <div className="batchedit-upload-form">
      {hasError && (
        <span className="batchedit-upload-form__error-title">
          <ApIcon iconName="error" iconColor="#D97706" />
          Error: the file could not be uploaded. You can download the error log file{' '}
          <button className="download-btn" onClick={onDownloadErrorClick}>
            here
          </button>
        </span>
      )}
      <label>{title}</label>
      <ApButtonGroup>
        <ApButtonMain onClick={() => setShowUploadModal(true)}>
          Upload file
        </ApButtonMain>{' '}
        or{' '}
        <ApButtonSecondary
          className="ap-button-casual"
          onClick={onDownloadTemplateClick}
        >
          Download template
        </ApButtonSecondary>
      </ApButtonGroup>
    </div>
  );
};

export const base64 = {
  encode(value: string) {
    return btoa(encodeURIComponent(value));
  },
  decode(value: string) {
    return decodeURIComponent(atob(value));
  },
};

export function decodeJwt(token: string): string {
  const [, payload] = token.split('.');
  const decodedPayload = base64.decode(payload);
  return decodedPayload;
}

export function parseJwt<R = object>(token: string): R {
  const decodedPayload = decodeJwt(token);
  return JSON.parse(decodedPayload) as R;
}

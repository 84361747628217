import classNames from 'classnames';
import { ApNiceSelect, EMPTY_OPTION_VALUE } from 'components/ApNiceSelect';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { InputWithLabel } from 'pages/SingleClaimPage/components/InputWithLabel';
import { useClaimEditableForClient } from 'pages/SingleClaimPage/hooks/useClaimEditableForClient';
import { useCallback } from 'react';
import {
  actionNameIdSet,
  actionSubstatusIdSet,
  actionTypeIdSet,
} from 'reducer/claimActionNameSelectionReducer';
import './ClaimResolutionSection.css';

// @todo: convert to usual section format
export const ClaimResolutionSection = () => {
  const selectionState = useAppSelector((s) => s.claimActionNameSelection);
  const dispatch = useAppDispatch();
  const { actionTypesByStatus, claimActionInfo } = selectionState;

  const { actionTypeId, actionStatus } = claimActionInfo;

  const { editableByClient } = useClaimEditableForClient(actionStatus);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      e.preventDefault();
      const valueString = e.target.value;
      if (!valueString) return;
      let value: number | undefined = +valueString;
      if (valueString === EMPTY_OPTION_VALUE) {
        value = undefined;
      }
      const name = e.target.name;

      if (name === 'actionTypeId') {
        dispatch(actionTypeIdSet({ value }));
      } else if (name === 'actionSubstatusId') {
        dispatch(actionSubstatusIdSet({ value }));
      } else if (name === 'actionNameId') {
        dispatch(actionNameIdSet({ value }));
      }
    },
    [dispatch],
  );

  return (
    <div className="claim-resolution-section">
      <div className="label">How would you like to proceed with this claim?</div>
      <InputWithLabel
        label="Select which action you would like to take"
        className="single-claim-overview__input-label single-claim-overview__input-label-select"
      >
        <ApNiceSelect
          emptyDefaultOption
          disabled={!editableByClient}
          className={classNames('category-select__select')}
          onChange={handleChange}
          value={actionTypeId || EMPTY_OPTION_VALUE}
          name={'actionTypeId'}
          options={actionTypesByStatus.map((o) => ({
            label: `${o.actionExhibitTypeName}`,
            value: `${o.id}`,
          }))}
        />
      </InputWithLabel>
    </div>
  );
};

import {
  contractsResSubtypeReports,
  useClaimsContractsReports,
} from 'pages/ClaimsContractsToolPage/hooks/useClaimsContractsReports';
import './ClaimsReports.css';
import { ButtonGenerate } from './components/ButtonGenerate';
import { ButtonGenerateByEntityId } from './components/ButtonGenerateByEntityId';
import { ClaimsReportsCard } from './components/ClaimsReportsCard';
import { scheduleGReport } from 'api/endpoints';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

export const ClaimsReports = () => {
  const {
    reportLegalEntity,
    legalEntityChange,
    claimsReportDownload,
    downloadingState,
    downloadScheduleGReport,
    handleExportContractsTable,
    isApUser,
  } = useClaimsContractsReports();

  return (
    <ApUserRole>
      <div className="claims-reports">
        <ClaimsReportsCard
          title="Claims resolution by category"
          description="Summary of review status by claim category."
        >
          <ButtonGenerateByEntityId
            selectedEntity={reportLegalEntity.claimsByCategory}
            onLegalEntityChange={(n) => legalEntityChange(n, 'claimsByCategory')}
            onGenerateClick={() => claimsReportDownload('claimsByCategory')}
            showSpinner={
              downloadingState.downloading &&
              downloadingState.selectedReport === 'claimsByCategory'
            }
          />
        </ClaimsReportsCard>

        <ClaimsReportsCard
          title="Claims walk down summary"
          description="Summary analysis of scheduled and filed claims, adjusting for superseded
          schedules, ordered objections and pending objections to reflect overall
          claims estimates if pending objections are ordered."
        >
          <ButtonGenerateByEntityId
            selectedEntity={reportLegalEntity.claimsWalkDownSummary}
            onLegalEntityChange={(n) => legalEntityChange(n, 'claimsWalkDownSummary')}
            onGenerateClick={() => claimsReportDownload('claimsWalkDownSummary')}
            showSpinner={
              downloadingState.downloading &&
              downloadingState.selectedReport === 'claimsWalkDownSummary'
            }
          />
        </ClaimsReportsCard>

        <ClaimsReportsCard
          title="Claims walk down detailed"
          description=" Detail analysis of scheduled and filed claims, adjusting for superseded
            scheduled, ordered objections and pending objections to reflect overall
            claims estimates if pending objections are ordered. Summary includes two
            levels of analyses: 1) by review status; 2) by major claim category."
        >
          <ButtonGenerateByEntityId
            selectedEntity={reportLegalEntity.claimsWalkDownDetailed}
            onLegalEntityChange={(n) => legalEntityChange(n, 'claimsWalkDownDetailed')}
            onGenerateClick={() => claimsReportDownload('claimsWalkDownDetailed')}
            showSpinner={
              downloadingState.downloading &&
              downloadingState.selectedReport === 'claimsWalkDownDetailed'
            }
          />
        </ClaimsReportsCard>

        {isApUser && (
          <>
            <ClaimsReportsCard
              title="Contracts by Resolution Subtype"
              description='Create a report containing the "Contracts" data available on the dashboard'
            >
              <ButtonGenerate
                onClick={() => handleExportContractsTable()}
                showSpinner={
                  downloadingState.downloading &&
                  downloadingState.selectedReport === contractsResSubtypeReports
                }
              />
            </ClaimsReportsCard>
            <ClaimsReportsCard
              title="Schedule G Report"
              description="Excel file, generated from the Contracts module, that can be used as an upload template file for Schedule G in SOFAs & Schedules Module"
            >
              <ButtonGenerate
                onClick={downloadScheduleGReport}
                showSpinner={
                  downloadingState.downloading &&
                  downloadingState.selectedReport === scheduleGReport
                }
              />
            </ClaimsReportsCard>
          </>
        )}
      </div>
    </ApUserRole>
  );
};

import { useAppSelector } from 'hooks/reducerHooks';
import { createContext, useContext, useMemo } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import {
  ActiveSection,
  ClaimsDashboardCategory,
  ClaimsDetailedData,
  ContractsDashboardCategory,
  ContractsDetailedData,
  DashboardDetailedViewData,
  TaskClick,
} from '../types';

type DashboardContextProps = {
  children?: JSX.Element | JSX.Element[];
  contractActiveSection?: ContractsDashboardCategory;
  handleTaskClick?: TaskClick;
  contractDetailedData?: ContractsDetailedData[];
  claimsActiveSection?: ClaimsDashboardCategory;
  claimsDetailedData?: ClaimsDetailedData[];
};

const DashboardContext = createContext<DashboardDetailedViewData>(
  {} as DashboardDetailedViewData,
);

export const DashboardContextProvider = ({
  children,
  contractActiveSection,
  handleTaskClick,
  contractDetailedData,
  claimsDetailedData,
  claimsActiveSection,
}: DashboardContextProps) => {
  const {
    contractsDashboardDetailedData: { fetching },
    userDashboardTasks: {
      slice: { status },
    },
  } = useAppSelector((state) => state);

  const getColumnsNo = (section?: ActiveSection) => {
    if (!section) return '';
    let numberOfCols = 3;
    switch (section) {
      case ContractsDashboardCategory.Assume:
        numberOfCols = 4;
        break;
      case ContractsDashboardCategory.Reject:
        numberOfCols = 5;
        break;
    }
    return numberOfCols;
  };

  const gridClassName = useMemo(
    () =>
      `gridCol${getColumnsNo(
        contractActiveSection ?? claimsActiveSection,
      )} grid-col`,
    [contractActiveSection, claimsActiveSection],
  );

  const contextData = {
    contractActiveSection,
    contractDetailedData,
    claimsActiveSection,
    claimsDetailedData,
    fetchingApTasks: status === FetchStatus.Fetching,
    fetchingContractsData: fetching,
    gridClassName,
    handleTaskClick,
  };

  return (
    <DashboardContext.Provider value={contextData}>
      {children}
    </DashboardContext.Provider>
  );
};
export const useDashboardContextData = () => useContext(DashboardContext);

import classNames from 'classnames';
import './CustomComponents.css';

export const SingleInput = (
  props: React.ComponentProps<'input'> & {
    preventBrowserAddressDialog?: boolean;
  },
) => {
  const { className, preventBrowserAddressDialog, ...otherProps } = props;

  return (
    <input
      className={classNames('single-input', className)}
      //workaround - to not show "save this address" browser autocomplete dialog
      autoComplete={preventBrowserAddressDialog ? 'one-time-code' : undefined}
      {...otherProps}
    />
  );
};

export const SingleSpan = (props: React.ComponentProps<'span'>) => {
  const { className, ...otherProps } = props;
  return <span className={classNames('single-span', className)} {...otherProps} />;
};

export const SingleTextarea = (props: React.ComponentProps<'textarea'>) => {
  const { value, className, ...otherProps } = props;

  return (
    <textarea
      className={classNames('single-input single-text-area', className)}
      {...otherProps}
    />
  );
};

import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import {
  ClaimEditValues,
  LabeledClaimEditChangeOptionsMarkFlag,
} from 'api/endpoints';
import {
  ClaimAttachment,
  claimAttachments,
  CreateClaimAttachmentItem,
  CreateClaimAttachmentParams,
  PostError,
} from 'api/endpoints/claimAttachments';
import { ErrorResponse, ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { SubmitData } from 'components/ModalWindows/UploadModal/UploadModal';
import { useAppSelector } from 'hooks/reducerHooks';
import { computeChangeOptionsMarkFlags } from 'pages/SingleClaimPage/SingleClaimPage.utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import {
  generateClaimAttachmentDownloadUrl,
  uploadClaimAttachment,
} from 'utils/uploader';

export type ClaimAttachmentWithUrl = ClaimAttachment & {
  downloadUrl: string;
  newlyAdded?: boolean;
};

export type UseSingleClaimDataUploaderReturn = {
  attachments: ClaimAttachmentWithUrl[];
  addAttachments: (attachments: SubmitData) => Promise<void>;
  listStatus: FetchStatus;
  uploadStatus: { progress: number };
  getDownloadFlag: (claimId: number) => Promise<void>;
  canDownloadFlag: boolean;
  markFlags: LabeledClaimEditChangeOptionsMarkFlag[];
  claimImage?: string;
  sourceType?: string;
  deleteAttachment: (id: number) => void;
  deletingDoc: boolean;
  loadList: () => Promise<void>;
};

function showAttachmentsUploadError(
  claimId: number,
  response: ErrorResponse<PostError>,
) {
  const { type } = response;
  let title = 'An unexpected error occured.';
  let messages = ['Please contact your IT administrator if the problem persists.'];
  if (type === ResponseType.BAD_REQ) {
    if (response.data.status === 404) {
      title = `Claim with id "${claimId}" was not found.`;
      messages = [];
    } else if (response.data.status === 400) {
      const badItems = response.data.error;
      title = `Several documents ecountered errors:`;
      messages = badItems.map((bi) => bi.error);
    }
  }

  showHttpSingleError({
    title,
    messages,
  });
}

function attachmentRespToAttachmentWithUrl(
  at: ClaimAttachment,
  newlyAdded: boolean,
): ClaimAttachmentWithUrl {
  return {
    ...at,
    newlyAdded: newlyAdded,
    downloadUrl: generateClaimAttachmentDownloadUrl(at.id),
    // at the moment of writing server does not return a indication for the timezone
    createdDate:
      at.createdDate[at.createdDate.length - 1].toLowerCase() === 'z'
        ? at.createdDate
        : `${at.createdDate}Z`,
  };
}

const getClaimAttatchments = async (
  claimId: number,
): Promise<ClaimAttachmentWithUrl[]> => {
  const response = await claimAttachments.get(claimId);
  if (response.type === ResponseType.OK) {
    return response.data.map((at) => attachmentRespToAttachmentWithUrl(at, false));
  }

  showAttachmentsUploadError(claimId, response);
  return [];
};

const postClaimAttatchments = async (
  params: CreateClaimAttachmentParams,
): Promise<ClaimAttachmentWithUrl[] | null> => {
  const response = await claimAttachments.post(params);
  const { type } = response;

  if (type === ResponseType.OK) {
    return response.data.map((at) => attachmentRespToAttachmentWithUrl(at, true));
  }

  showAttachmentsUploadError(params.claimId, response);
  return null;
};

const MAX_BLOB_UPLOAD_PROGRESS_RATIO = 0.9;
const MIN_BLOB_UPLOAD_PROGRESS_RATIO = 0.1;
const DELTA_BLOB_UPLOAD_PROGRESS_RATIO =
  MAX_BLOB_UPLOAD_PROGRESS_RATIO - MIN_BLOB_UPLOAD_PROGRESS_RATIO;

export function useClaimAttachments(
  claimId: number,
  claim?: ClaimEditValues,
): UseSingleClaimDataUploaderReturn {
  const [attachments, setAttachments] = useState<ClaimAttachmentWithUrl[]>([]);
  const [listStatus, setListStatus] = useState<
    UseSingleClaimDataUploaderReturn['listStatus']
  >(FetchStatus.Idle);
  const [deletingDoc, setDeletingDoc] = useState(false);

  const [uploadStatus, setUploadStatus] = useState<
    UseSingleClaimDataUploaderReturn['uploadStatus']
  >({
    progress: -1,
  });
  const { data: applicationUser } = useApplicationUser();
  const {
    selectable: {
      data: { actionStatusDynamicLabels },
    },
    claimActionNameSelection: {
      toSend: { actionTypeId },
    },
  } = useAppSelector((state) => state);

  const [canDownloadFlag, setDownloadFlag] = useState(false);

  const loadList = useCallback(async () => {
    setListStatus(FetchStatus.Fetching);
    const serverAttachments = await getClaimAttatchments(claimId);
    setAttachments(serverAttachments);
    setListStatus(FetchStatus.Done);
  }, [claimId]);

  const getDownloadFlag = useCallback(async (claimId: number) => {
    const response = await claimAttachments.canDownloadClaimImage(claimId);
    if (response?.type === ResponseType.OK) {
      setDownloadFlag(response.data.canDownload);
    }
  }, []);

  useEffect(() => {
    getDownloadFlag(claimId);
  }, [getDownloadFlag, claimId]);

  useEffect(() => {
    if (listStatus === 'idle') loadList();
  }, [loadList, listStatus]);

  const markFlags = useMemo(
    () =>
      computeChangeOptionsMarkFlags({
        claim,
        applicationUser,
        labels: actionStatusDynamicLabels || [],
        actionTypeId: actionTypeId ?? claim?.actionExhibitTypeId ?? 0,
      }),
    [applicationUser, claim, actionStatusDynamicLabels, actionTypeId],
  );

  const addAttachments = useCallback(
    async (data: SubmitData) => {
      const comment = data.comment;

      const totalSize = data.entries.reduce((acc, crt) => {
        return acc + crt.file.size;
      }, 0);

      const totalCount = data.entries.length;

      let totalLoaded = 0;

      setUploadStatus({
        progress: MIN_BLOB_UPLOAD_PROGRESS_RATIO,
      });

      const uploadPromises = data.entries.map(async (entry) => {
        try {
          let prevLoaded = 0;
          const result = await uploadClaimAttachment(
            entry.file,
            claimId,
            (loaded) => {
              const delta = loaded - prevLoaded;
              totalLoaded = totalLoaded + delta;
              const ratio =
                MIN_BLOB_UPLOAD_PROGRESS_RATIO +
                (totalLoaded / totalSize) * DELTA_BLOB_UPLOAD_PROGRESS_RATIO;

              setUploadStatus({
                progress: ratio,
              });
              prevLoaded = loaded;
            },
          );
          if (result) {
            return { type: 'ok', result, entry };
          }
          throw new Error('No result.');
        } catch (e) {
          return { type: 'fail', error: e, entry };
        }
      });

      const uploadResults = await Promise.all(uploadPromises);
      // TODO also output the errors
      const okResults = uploadResults.filter((u) => u.type === 'ok') as {
        type: 'ok';
        result: { name: string; url: string };
        entry: SubmitData['entries'][0];
      }[];

      setUploadStatus({
        progress: MAX_BLOB_UPLOAD_PROGRESS_RATIO + (5 - (totalCount % 5)) * 0.01,
      });

      if (!okResults.length) return;

      const postParams: CreateClaimAttachmentParams = {
        claimId,
        claimUploadDocuments: [],
      };
      postParams.claimUploadDocuments = okResults.map((or) => {
        const { entry, result } = or;
        const item: CreateClaimAttachmentItem = {
          // @ts-ignore
          referenceNumber: entry.referenceNumber,
          documentType: +entry.documentType,
          comment,
          displayName: entry.file.name,
          documentName: result.name,
        };
        return item;
      });

      const newAttachments = await postClaimAttatchments(postParams);

      setUploadStatus({ progress: -1 });
      if (newAttachments) {
        setAttachments((prev) => [...newAttachments, ...prev]);
      }
    },
    [claimId],
  );

  const deleteAttachment = useCallback(
    async (id: number) => {
      setDeletingDoc(true);
      await claimAttachments.deleteDocument(id);
      await loadList();
      setDeletingDoc(false);
    },
    [loadList],
  );

  return {
    uploadStatus,
    attachments,
    listStatus,
    addAttachments,
    getDownloadFlag,
    canDownloadFlag,
    markFlags,
    claimImage: claim?.claimImage,
    sourceType: claim?.sourceType,
    deleteAttachment,
    deletingDoc,
    loadList,
  };
}

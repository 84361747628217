import { ColDef } from 'ag-grid-community';
import { ExhibitExportContract } from 'api/endpoints/contractExhibitExports';
import { useMemo } from 'react';
import { formatDateTime } from 'utils/formatDate';
import {
  ExhibitHistoryGrid,
  HistoryGridRow,
} from '../../../components/ExhibitsExport/ExhibitHistoryGrid';

const COLUMNS: ColDef[] = [
  {
    field: 'type',
    headerName: 'Exhibit type',
    flex: 1,
    suppressMenu: true,
  },
  {
    field: 'name',
    headerName: 'Resolution name',
    flex: 1,
    suppressMenu: true,
  },
  {
    field: 'date',
    headerName: 'Date created',
    width: 200,
    suppressMenu: true,
  },
];

interface Props {
  data: ExhibitExportContract[] | undefined;
  loading?: boolean;
  onCopy: (id: number) => void;
  onDownload: (id: number) => void;
  onDelete: (id: number) => void;
}

export const ExhibitContractHistoryTable = ({
  data,
  loading,
  onCopy,
  onDelete,
  onDownload,
}: Props) => {
  const gridRows = useMemo(() => {
    const result = data?.map(mapExhibitToRow);
    return result ?? [];
  }, [data]);

  return (
    <ExhibitHistoryGrid
      loading={loading}
      rows={gridRows}
      columns={COLUMNS}
      onCopy={onCopy}
      onDownload={onDownload}
      onDelete={onDelete}
    />
  );
};

const mapExhibitToRow = (value: ExhibitExportContract): HistoryGridRow => ({
  id: value.id,
  type: value.contractExhibitType.exhibitTypeName,
  name: value.contractActionName.contractActionName,
  date: formatDateTime(value.createdDate),
});

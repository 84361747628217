import { GridColumn } from 'api/endpoints';
import { ContractReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import { Group } from 'components/UIComponents/GroupMultiSelect';
import uniqueId from 'lodash/uniqueId';

export interface SelectOption {
  label: string;
  value: number | string;
}

export type TBatchDataType = GridColumn['dataType'] | undefined;

export type DefaultBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: TBatchDataType;
  allowedValues?: SelectOption[];
  value: string | undefined;
};

export type CascadingContractBatchItemType =
  | 'contractCategoryType'
  | 'contractResolutionItems'
  | 'contractResolutionName';

type ContractCascadingBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type?: CascadingContractBatchItemType;
  allowedValues?: SelectOption[];
  value: string | undefined | number;
};

export type ContractsReviewersBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: 'group-multi-select';
  allowedValues?: Group[];
  value: ContractReviewersBatchItem | undefined;
};

export type ActionNameBatchItem = {
  id: string;
  propName: GridColumn['propertyName'];
  type: 'action-name-id';
  allowedValues?: Group[];
  value: number | undefined;
};

export type IBatchItem =
  | DefaultBatchItem
  | ContractsReviewersBatchItem
  | ActionNameBatchItem
  | ContractCascadingBatchItem;

export const emptyItem = (): IBatchItem => ({
  id: uniqueId(),
  propName: '',
  type: undefined,
  value: undefined,
});

export type IBatchEditableColumns = {
  disabled?: boolean;
  propertyName: GridColumn['propertyName'];
  displayName: GridColumn['displayName'];
  dataType:
    | TBatchDataType
    | 'group-multi-select'
    | 'action-name-id'
    | CascadingContractBatchItemType;
  allowedValues?: SelectOption[] | Group[];
};

import { ActionStatus } from 'api/endpoints';
import { useAppSelector } from 'hooks/reducerHooks';
import { isNil } from 'lodash';
import { useMemo } from 'react';

export const useClaimEditableForClient = (
  actionStatus: ActionStatus | undefined,
) => {
  const labels = useAppSelector((s) => s.selectable.data.actionStatusDynamicLabels);

  const editableByClient = useMemo(() => {
    const action = labels?.find((l) => l.actionStatus === actionStatus);
    if (isNil(action?.actionStatusId)) return false;

    const clientReconciled = labels?.find(
      (l) => l.actionStatus === ActionStatus.ClientReconciled,
    );
    if (isNil(clientReconciled?.actionStatusId)) return false;

    // ClientReconciled is the last status from the list for which a client user can edit a claim
    return action!.actionStatusId <= clientReconciled!.actionStatusId;
  }, [actionStatus, labels]);

  return { editableByClient };
};

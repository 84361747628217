import { downloadFileGet, post } from 'api/apiRequest';
import { downloadBlob } from 'utils/file';
import { ExportPdfResponse } from './exportPdf';

type ExportPdfStatus = 'None' | 'Queued' | 'InProgress' | 'Completed' | 'Failed';

export type ReportType = 'Sofa' | 'Schedule' | 'Excel';

type ExcelExportParams = {
  templateId: string | null;
  label: string;
};

type MultiExportParams = {
  fileType: ReportType;
  legalEntityIds: number[] | null;
  partsIds: string[] | null;
};

export type ExportStatus = {
  id: number;
  exportStatus: ExportPdfStatus;
  filePath: string;
  processedFiles: number;
  totalFiles: number;
};

export const sofasAndSchedules = {
  /**
   * Export the Excel Data file
   * @param value - ExcelExportParams with a templateId
   * @param props - maxTime in milliseconds
   * @returns
   */
  excelExportReport: async ({ templateId, label }: ExcelExportParams) => {
    if (!templateId) return;
    const template = templateId.toLowerCase();
    const url: string = `v1/SofaSchedule/excelexport/${template}`;
    const response = await downloadFileGet(url);
    if (response.type === 'ok') {
      const filename = `${label.replace(' ', '-').toLowerCase()}-exported-data.xlsx`;
      await downloadBlob(response.data as unknown as Blob, filename);
    }

    return response;
  },

  /**
   * Polls the server for the status of the export and upon
   * success downloads the file.
   *
   * GET
   *  ​/api​/v1​/ExportPipeline​/export-log-list
   *  Explanation: List of available records which is generated and stored in blobstorage
   *
   * POST
   *  ​/api​/v1​/ExportPipeline​/export-pdf
   *  Explanation: Triggers import operation
   *
   * DELETE
   *  ​/api​/v1​/ExportPipeline​/export-log-delete​/{id}
   *  Explanation: Deletes an available log.
   */

  exportPdf: async (params: MultiExportParams) => {
    const { legalEntityIds, fileType, partsIds } = params;
    const props =
      legalEntityIds?.map((legalEntityId) => ({
        fileType: fileType.toLowerCase(),
        legalEntityId,
        part: partsIds?.map(partNameToPartId) ?? [],
      })) ?? [];

    return post<ExportPdfResponse>('v1/ExportPdf', props);
  },
};

const partNameToPartId = (partName: string | undefined): string => {
  if (!partName) return '';

  let result = partName.replace('Part', '');
  if (result === 'G1' || result === 'H1') {
    result = result.replace('1', '');
  }

  return result;
};

import { ApIcon } from '@alixpartners/ui-components';
import { SettlementAgreementResponse } from 'api/endpoints/settlementAgreementTemplates';
import {
  Extension,
  FileUploadSection,
  UploadProgress,
} from 'components/FileUploadSection';
import { ApButtonMainEx } from 'components/UIComponents/Buttons';
import { ApButtonRectIcon } from 'components/UIComponents/Buttons/ApButtonRectIcon';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useState } from 'react';
import './SettlementAgreemantUpload.css';

const EXTENSIONS: Extension[] = ['.docx'];

interface Props {
  item: SettlementAgreementResponse | undefined;
  loading: boolean;
  uploadProgress: UploadProgress | undefined;
  upload: (files: File) => Promise<boolean>;
  download: () => Promise<void>;
  deleteFile: () => Promise<void>;
  preview: () => void;
}

export const SettlementAgreemantUpload = (props: Props) => (
  <div className="settlement-agreemant-upload">
    <SettlementAgreemantBody {...props} />
  </div>
);

export const SettlementAgreemantBody = ({
  item,
  loading,
  uploadProgress,
  upload,
  download,
  deleteFile,
  preview,
}: Props) => {
  if (loading) {
    return <DivWithLoader loading />;
  }

  if (!item) {
    return (
      <AgreemantUploadSection uploadProgress={uploadProgress} upload={upload} />
    );
  }

  return (
    <AgreemantFileInfo
      item={item}
      download={download}
      deleteFile={deleteFile}
      preview={preview}
    />
  );
};

const AgreemantFileInfo = (
  props: Pick<Props, 'item' | 'download' | 'deleteFile' | 'preview'>,
) => {
  const { item, download, deleteFile, preview } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const hadnleDeownload = async () => {
    setIsDownloading(true);
    await download();
    setIsDownloading(false);
  };

  const hadnleDelete = async () => {
    setIsDeleting(true);
    await deleteFile();
    setIsDeleting(false);
  };

  return (
    <div className="settlement-agreemant-fileinfo">
      <ApIcon
        className="settlement-agreemant-fileinfo__icon"
        iconName="outline_check_circle_outline"
        iconColor={'var(--ap-color-ui-green-primary)'}
      />
      <div className="settlement-agreemant-fileinfo__body">
        <div className="settlement-agreemant-fileinfo__filename">
          {item?.displayName}
        </div>
        <div className="settlement-agreemant-fileinfo__createdby">
          (Uploaded by {item?.createdBy})
        </div>
      </div>
      <div className="settlement-agreemant-fileinfo__buttons thin-scrollbar">
        <ApButtonRectIcon
          iconName="download"
          color="var(--ap-color-ui-green-primary)"
          onClick={hadnleDeownload}
          disabled={isDownloading}
        />
        <div className="settlement-agreemant-fileinfo__separator" />
        <ApButtonRectIcon
          iconName="outline_visability"
          color="var(--ap-color-ui-green-primary)"
          onClick={preview}
        />
        <div className="settlement-agreemant-fileinfo__separator" />
        <ApButtonRectIcon
          iconName="delete_outline"
          color="var(--ap-color-red1)"
          onClick={hadnleDelete}
          disabled={isDeleting}
        />
      </div>
    </div>
  );
};

const AgreemantUploadSection = (props: Pick<Props, 'uploadProgress' | 'upload'>) => {
  const handleUpload = async (file: File[]) => props.upload(file[0]);

  return (
    <FileUploadSection
      accept={EXTENSIONS}
      maxFiles={1}
      onUpload={handleUpload}
      uploadProgress={props.uploadProgress}
    >
      <div className="settlement-agreemant-upload-template">
        <span>
          Drag your files here or browse files to upload the{' '}
          <b>agreement template</b> <ApIcon iconName="outline_info" iconSize={16} />
        </span>
        <ApButtonMainEx>Browse</ApButtonMainEx>
      </div>
    </FileUploadSection>
  );
};

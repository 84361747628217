import classNames from 'classnames';
import { iconImage, ISvgIcon } from './iconImageList';
import './SvgIcon.css';

interface IProps {
  className?: string;
  rightFlip?: boolean;
  iconName: keyof typeof iconImage;
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
}

export const SvgIcon = ({
  className,
  rightFlip,
  iconName,
  fill = 'none',
  stroke,
  ...props
}: IProps) => {
  const svg = iconImage[iconName] as ISvgIcon;

  const widthValue = props.width ?? svg?.width ?? 24;
  const heightValue = props.height ?? svg?.height ?? 24;

  return (
    <svg
      className={classNames('svg-icon', className, {
        'svg-icon--rotate-180': rightFlip,
      })}
      xmlns="http://www.w3.org/2000/svg"
      width={widthValue}
      height={heightValue}
      viewBox={`0 0 ${svg?.width} ${svg?.height}`}
      fill={fill}
      stroke={stroke}
    >
      {svg?.data}
    </svg>
  );
};

import { ReportType } from 'api/endpoints/sofas-schedules';
import { useApiQuery } from 'hooks/useApiQuery';
import { useMemo, useState } from 'react';
import { apiGetSofasExportDropdownItems } from './apiSofaExport';
import {
  emptyDropdownItems,
  ExportSofasItems,
  SofasSelected,
} from './sofaExportTypes';
import { filterDropdownItemsByType } from './sofaExportUtils';

export const defaultDropdownItems: ExportSofasItems = {
  sofa: emptyDropdownItems,
  schedule: emptyDropdownItems,
};

const defaultSelected: SofasSelected = {
  entities: { useAll: false, items: [] },
  parts: { useAll: true, items: [] },
};

export const KEY_EXPORT_STATUS = 'SOFAs-Export-status';

interface Props {
  reportType: ReportType;
}

export const useSofasExportItems = ({ reportType }: Props) => {
  const exportDropdownItems = useApiQuery({
    queryFn: apiGetSofasExportDropdownItems,
  });

  const [selected, setSelected] = useState(defaultSelected);

  const dropdownItems = useMemo(
    () => filterDropdownItemsByType(exportDropdownItems.data, reportType),
    [exportDropdownItems.data, reportType],
  );

  const isFieldsValid = useMemo(() => {
    if (dropdownItems.entities.length <= 0) return false;

    const { entities, parts } = selected;
    const isEntitiesSelected = entities.useAll || entities.items.length > 0;
    const isPartsSelected = parts.useAll || parts.items.length > 0;

    return isEntitiesSelected && isPartsSelected;
  }, [dropdownItems.entities.length, selected]);

  const cleanUpSelected = () => setSelected(defaultSelected);

  return {
    isFieldsValid,
    selected,
    dropdownItems,
    setSelected,
    cleanUpSelected,
  };
};

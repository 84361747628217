import { useNavigate } from 'react-router';
import { URL_PATH } from 'appUrls';

import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { ClaimsPanel } from 'components/ClaimsPanel/ClaimsPanel';
import { ClaimsPanelCard } from 'components/ClaimsPanelCard/ClaimsPanelCard';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

export const ExhibitExport = () => {
  const navigate = useNavigate();

  return (
    <ApUserRole>
      <ClaimsPanel>
        <ClaimsPanelCard
          title="Claim Exhibits"
          footer={
            <ButtonMainLoader onClick={() => navigate(URL_PATH.EXHIBITS_CLAIMS)}>
              Generate New Exhibit
            </ButtonMainLoader>
          }
        >
          <div>Here you can:</div>
          <li>create a new exhibit template for Claims</li>
          <li>view previous generated exhibits for Claims</li>
          <li>manage previous generated exhibit for Claims</li>
        </ClaimsPanelCard>
        <ClaimsPanelCard
          title="Contract Exhibits"
          footer={
            <ButtonMainLoader onClick={() => navigate(URL_PATH.EXHIBITS_CONTRACTS)}>
              Generate New Exhibit
            </ButtonMainLoader>
          }
        >
          <div>Here you can:</div>
          <li>create a new exhibit template for Contract</li>
          <li>view previous generated exhibits for Contract</li>
          <li>manage previous generated exhibit for Contract</li>
        </ClaimsPanelCard>
      </ClaimsPanel>
    </ApUserRole>
  );
};

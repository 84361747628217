export interface TSettingsItem {
  name: string;
  component: JSX.Element;
}
export enum SettingsMenuItem {
  Claims = 'claims',
  Contracts = 'contracts',
  Settlements = 'Settlements',
  CasesEntities = 'casesEntities',
  ClientNameLogo = 'clientNameLogo',
  SofasSettings = 'sofasSettings',
  DeleteData = 'deleteData',
  Users = 'users',
}

import { LegalEntity } from 'api/endpoints';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';
TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo('en-US');

export const formatUtcTimeAgo = (date: Date | string) => {
  const utcDateAgo = moment(date).utc(true);
  const currentTime = new Date();
  if (!utcDateAgo.isBefore(currentTime, 'minute')) {
    return '';
  }

  return timeAgo.format(utcDateAgo.toDate());
};

export const fromCamelCase = (str?: string) => {
  if (!str || !str.length) return '';
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

export const formatLegalEntity = (le: LegalEntity) => {
  return `${le.entityName} - ${le.caseNumber}`;
};

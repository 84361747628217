import classNames from 'classnames';
import './LabelLocked.css';

export type LabelLockedProps = {
  text: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export function LabelLocked(props: LabelLockedProps) {
  const { text, children, className, ...rest } = props;
  return (
    <span className={classNames('label-locked', className)} {...rest}>
      {children}
    </span>
  );
}

import { ContractStatuses, LegalEntity } from 'api/endpoints';
import { ContractActionName } from 'api/endpoints/action-names';
import {
  ContractActionSubstatus,
  ContractActionType,
} from 'api/endpoints/actions-select-options';
import { useAppSelector } from 'hooks/reducerHooks';
import { useMemo } from 'react';
import { ContractPaymentPositionsData } from 'reducer/contractPaymentPositionsReducer';
import { colProp, EDITABLE_COLUMNS_SORTED } from '../contractsBatchEdit.const';
import { IBatchItem, SelectOption } from '../contractsBatchEdit.type';

export const useBatchOptionItems = (selectedItems: IBatchItem[]) => {
  const {
    selectable: {
      data: { contractStatuses, legalEntities },
    },
    contractActionNameSelection: {
      _allActionNames: actionNames,
      _allActionTypes: actionTypes,
      substatusOptions,
      _allActionSubstatuses: allActionSubstatuses,
    },
    contractPaymentPositions: { data: paymentPositions },
  } = useAppSelector((state) => state);

  const items = useMemo(
    () => ({
      contractStatuses,
      actionNames,
      actionTypes,
      substatusOptions,
      allActionSubstatuses,
      paymentPositions,
      legalEntities,
    }),
    [
      actionNames,
      actionTypes,
      allActionSubstatuses,
      contractStatuses,
      legalEntities,
      paymentPositions,
      substatusOptions,
    ],
  );

  const itemsWithAllowedValues = useMemo(() => {
    const selectedProps = selectedItems.map((r) => r.propName);

    return EDITABLE_COLUMNS_SORTED.map((r) => ({
      ...r,
      disabled: selectedProps.includes(r.propertyName),
      allowedValues:
        getAllowedValues(r.propertyName, items, selectedProps) ?? r.allowedValues,
    }));
  }, [items, selectedItems]);

  return itemsWithAllowedValues;
};

const getAllowedValues = (
  colName: string,
  items: {
    contractStatuses: ContractStatuses[];
    actionNames: ContractActionName[];
    actionTypes: ContractActionType[];
    substatusOptions: ContractActionSubstatus[];
    allActionSubstatuses: ContractActionSubstatus[];
    paymentPositions: ContractPaymentPositionsData[];
    legalEntities: LegalEntity[];
  },
  selectedProps: string[],
): SelectOption[] | undefined => {
  if (colName === colProp.contractStatusId)
    return mapToSelectOptions(items.contractStatuses, 'contractStatus');

  if (colName === colProp.contractActionTypeId)
    return mapToSelectOptions(items.actionTypes, 'contractActionTypeName');

  if (colName === colProp.contractActionNameId)
    return mapToSelectOptions(items.actionNames, 'contractActionName');

  if (colName === colProp.paymentPositionId)
    return mapToSelectOptions(items.paymentPositions, 'paymentPositionName');

  if (colName === colProp.debtorId)
    return mapToSelectOptions(items.legalEntities, 'entityName');

  if (colName === colProp.contractActionSubStatusId) {
    const substatusOptions =
      selectedProps.includes(colProp.contractActionTypeId) &&
      items.substatusOptions?.length
        ? items.substatusOptions
        : items.allActionSubstatuses;
    return mapToSelectOptions(substatusOptions, 'contractActionSubStatusName');
  }

  return undefined;
};

function mapToSelectOptions<T extends { id: number }>(
  entities: T[],
  labelField: keyof T,
): SelectOption[] {
  return entities.map((r) => ({
    value: r.id,
    label: r[labelField] as any,
  }));
}

import { ApNiceSelect, OptionItem } from 'components/ApNiceSelect';
import { ChangeEvent } from 'react';
import { SingleSpan } from './CustomComponents';
import { SingleInputSection } from './SingleInputSection';
import './SingleSelectWithLabel.css';

interface Props {
  className?: string;
  label: string;
  readonly?: boolean;
  value?: string | number;
  options: OptionItem[];
  onChange?: (value: string) => void;
}

export const SingleSelectWithLabel = ({
  label,
  readonly,
  value,
  options,
  className,
  onChange,
}: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    onChange?.(e.target.value);
  };

  return (
    <SingleInputSection label={label} className={className}>
      {readonly ? (
        <SingleSpan>{value}</SingleSpan>
      ) : (
        <ApNiceSelect
          className="single-input-select"
          emptyDefaultOption
          value={value}
          options={options}
          onChange={handleOnChange}
        />
      )}
    </SingleInputSection>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportPart } from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';

export type ReportPartsSlice =
  | {
      type: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      type: FetchStatus.Done;
      data: ReportPart[];
      error: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: ReportPart[];
    }
  | {
      type: FetchStatus.Fetching;
      data: ReportPart[];

      error: null;
    };

export const initialState: ReportPartsSlice = {
  type: FetchStatus.Idle,
  error: null,
  data: null,
} as ReportPartsSlice;

const SLICE_NAME = 'report-parts';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetching(
      state: ReportPartsSlice,
    ) {
      state.type = FetchStatus.Fetching;
    },
    done(
      state: ReportPartsSlice,
      action: PayloadAction<{
        items: ReportPart[];
      }>,
    ) {
      state.type = FetchStatus.Done;
      state.data = action.payload.items;
    },
    error(
      state: ReportPartsSlice,
      action: PayloadAction<{ message: string }>,
    ) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const reportPartsActions = slice.actions;
export default slice.reducer;

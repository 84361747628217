import { NON_STICKY_SECTIONS } from 'pages/ClaimsContractsToolPage';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { useMemo } from 'react';
import { useQueryParam, withDefault } from 'use-query-params';

const DEFAULT_SECTION = SectionType.Vendors;
const DEFAULT_SUBSECTION = SubsectionType.AllVendors;

const SectionTypeParam = {
  encode: (v: SectionType) => v,
  decode: (v: any) =>
    Object.keys(SectionType).includes(v) ? (v as SectionType) : DEFAULT_SECTION,
};

const SubsectionTypeParam = {
  encode: (v: SubsectionType) => v,
  decode: (v: any) =>
    Object.keys(SubsectionType).includes(v)
      ? (v as SubsectionType)
      : DEFAULT_SUBSECTION,
};

export const useVendorsToolQueryParams = () => {
  const [section, setSection] = useQueryParam<SectionType>(
    'section',
    withDefault(SectionTypeParam, DEFAULT_SECTION),
  );
  const [subsection, setSubsection] = useQueryParam<SubsectionType>(
    'subsection',
    withDefault(SubsectionTypeParam, DEFAULT_SUBSECTION),
  );

  const footerIsSticky = useMemo(
    () => !NON_STICKY_SECTIONS.includes(section),
    [section],
  );

  return {
    section,
    setSection,
    subsection,
    setSubsection,
    footerIsSticky,
  };
};

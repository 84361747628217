import { ApToggleSwitch } from '@alixpartners/ui-components';
import { ContractEditValues } from 'api/endpoints';
import classNames from 'classnames';
import { SCPInput } from 'components/SingleClaim/SCPInput';
import upperFirst from 'lodash/upperFirst';
import { formatMoney } from 'utils/formatNumber';
import { EditFieldFunc } from '../../hook/useEditContract';
import { SCMatrix } from '../../types';
import { CategorySelect } from '../CategorySelect';
import './SingleContractMatrix.css';

export interface Props {
  matrix: SCMatrix;
  contract?: ContractEditValues;
  title?: string;
  editField: EditFieldFunc;
  isEditable: (row: string, column?: string) => boolean;
}

export const SingleContractMatrix = ({
  matrix,
  contract,
  title,
  editField,
  isEditable,
}: Props) => {
  return (
    <div className="single-contract-matrix">
      <table cellSpacing={0}>
        <thead>
          <tr className="header">
            {title ? (
              <td
                className={classNames('header-title section__title')}
                key={`td-title`}
              >
                <span>{title}</span>
              </td>
            ) : (
              <td key={'td0'} />
            )}
            {matrix.columns.map((column, index) => {
              if (column.type === 'empty') {
                return <td className={'header-label empty'} key={`td${index}`} />;
              }
              return (
                <td
                  className={classNames('header-label', column.field)}
                  key={`td${index}`}
                >
                  <span>{column.label}</span>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {matrix.rows.map((row, index) => {
            if (row?.type === 'empty') {
              return <tr className="row empty" key={`tr${index}`} />;
            }
            return (
              <tr className="row" key={`tr${index}`}>
                <td className="row-label">
                  <span>{row.label}:</span>
                </td>
                {matrix.columns.map((column, index) => {
                  if (column.type === 'empty')
                    return <td className="empty" key={`td${index}`} />;
                  const field = `${row.field}${upperFirst(column.field)}`;
                  let value = contract ? (contract as any)[field] : undefined;
                  if (column.type === 'number' && column.formatting === 'currency') {
                    value = formatMoney(value);
                  }

                  const editable = isEditable(row.field, column.field);

                  // if editable use SCPInput
                  if (row.hideBoolean && column.type === 'bool') {
                    return null;
                  }

                  return (
                    <td
                      className={classNames('row-value', { editable }, column.field)}
                      key={`td${index}`}
                    >
                      {column.type === 'number' && (
                        <span>
                          <SCPInput
                            value={value}
                            onBlur={(e) => {
                              var cleanedValue = e.target.value;
                              if (isNaN(Number(cleanedValue))) {
                                cleanedValue = cleanedValue.replace(/[^0-9.]/g, '');
                                if (cleanedValue.split('.').length > 2)
                                  cleanedValue = cleanedValue.replace(/\.+$/, '');
                              }
                              editField(field, cleanedValue);
                            }}
                            disabled={!editable}
                          />
                        </span>
                      )}
                      {column.type === 'bool' && (
                        <span>
                          <ApToggleSwitch
                            id={`switch${field}`}
                            checked={value}
                            onChange={(e) => editField(field, e.target.checked)}
                            disabled={!editable}
                          />
                        </span>
                      )}
                      {column.type === 'category' && column.category && (
                        <CategorySelect
                          {...column.category}
                          type={column.category.type}
                          name={field}
                          disabled={!editable}
                          editField={editField}
                          contract={contract}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import { ActionStatus, ClaimEditValues, ContractEditValues } from 'api/endpoints';
import { AgGridFilterModel } from './agGridHotchocolate/types';

export const agGridFIlterModelActionStatusGenerator = (
  actionStatus: ActionStatus[],
): AgGridFilterModel => {
  return {
    actionStatus: {
      values: actionStatus,
      filterType: 'set',
    },
  };
};

export const agGridTextFilter = (
  filterKey: keyof ContractEditValues | keyof ClaimEditValues,
  filter: string,
): AgGridFilterModel => {
  return {
    [filterKey]: {
      filter,
      filterType: 'text',
      type: 'contains',
    },
  };
};

export const getPercentage = (count: number, total?: number): number =>
  total ? Math.round((count * 100) / total) : 0;

export const formatToLink = (to: string, newValue: string) => `${to}${newValue}`;

import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';

interface Props {
  disabled: boolean;
  isGenerating?: boolean;
  text?: string;
  onClick: () => void;
}

export const SubmitButton = ({
  disabled,
  isGenerating,
  text = 'Export',
  onClick,
}: Props) => {
  return (
    <ApButtonMain
      className="sofas-schedules-export__button"
      disabled={disabled}
      type="submit"
      onClick={onClick}
    >
      <SvgIcon
        width={18}
        height={18}
        className={classNames({
          'anim-spin': isGenerating,
          'sofas-schedules-icon-fix': !isGenerating,
        })}
        iconName={isGenerating ? 'spinner' : 'download_colorless'}
        fill="currentColor"
      />
      {isGenerating ? 'Preparing file...' : text}
    </ApButtonMain>
  );
};

import calculateCounterparties from 'pages/ClaimsContractsToolPage/util/calculateCounterparties';
import { CounterpartyMatchStatus } from 'api/endpoints';

import { ClaimsDataState, SectionType } from 'pages/ClaimsContractsToolPage/types';
import { GridColumn, CounterpartyRow } from 'api/endpoints';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processGridColumns } from 'utils/processGridColumns';

const CLAIMS_DATA_DEFAULT_STATE = {
  data: [],
  claimsData: [],
  counterparties: [],
  section: undefined,
};
export const initialState: ClaimReducerState = {
  loading: true,
  data: CLAIMS_DATA_DEFAULT_STATE,
};

export interface ClaimReducerState {
  data: ClaimsDataState;
  error?: string;
  loading: boolean;
  section?: SectionType;
}

export interface ClaimsDataStateWithEmpty
  extends Omit<ClaimsDataState, 'gridColumns' | 'counterparties'> {
  gridColumns?: GridColumn[];
  counterparties?: CounterpartyRow[];
}

const claimsSlice = createSlice({
  name: 'claims',
  initialState: initialState,
  reducers: {
    claimsFetch(state, action) {
      state.loading = true;
    },
    claimsLoaded(state, action: PayloadAction<ClaimsDataStateWithEmpty>) {
      state.loading = false;
      state.data = {
        ...action.payload,
        gridColumns: processGridColumns(action.payload.gridColumns),
        counterparties: action.payload.counterparties
          ? calculateCounterparties(action.payload.counterparties)
          : [],
      };
    },
    oneHundrenPercentMatchesApproved(
      state,
      action: PayloadAction<{ claimIds: number[] }>,
    ) {
      const data = state.data;
      const { claimIds } = action.payload;
      const claims = data.counterparties.filter((c) => claimIds.includes(c.claimId));
      claims?.forEach((claim) => {
        if (!claim.matches) return;
        claim.matches = claim.matches.map((m) => ({
          ...m,
          matchStatus:
            m.score === 1
              ? CounterpartyMatchStatus.Matched
              : CounterpartyMatchStatus.NotMatched,
        }));
      });
    },
    matchApproved(
      state,
      action: PayloadAction<{ claimId: number; counterpartyId: number }>,
    ) {
      const data = state.data;
      const { claimId, counterpartyId } = action.payload;
      const claim = data.counterparties.find((c) => c.claimId === claimId);
      if (claim?.matches) {
        claim.matches = claim.matches.map((m) => ({
          ...m,
          matchStatus:
            m.counterpartyId === counterpartyId
              ? CounterpartyMatchStatus.Matched
              : CounterpartyMatchStatus.NotMatched,
        }));
      }
    },
    matchDeclined(
      state,
      action: PayloadAction<{ claimId: number; counterpartyId: number }>,
    ) {
      const data = state.data;
      const { claimId, counterpartyId } = action.payload;
      const claim = data.counterparties.find((c) => c.claimId === claimId);
      const match = claim?.matches.find((m) => m.counterpartyId === counterpartyId);
      if (match) {
        match.matchStatus = CounterpartyMatchStatus.NotMatched;
      }
    },
    matchCodeUpdated(
      state,
      action: PayloadAction<{ claimId: number; matchCode: number }>,
    ) {},
    setSection: (state, action: PayloadAction<SectionType | undefined>) => {
      state.section = action.payload;
    },
  },
});

export const {
  claimsFetch,
  claimsLoaded,
  matchApproved,
  matchDeclined,
  matchCodeUpdated,
  oneHundrenPercentMatchesApproved,
  setSection,
} = claimsSlice.actions;
export default claimsSlice.reducer;

import {
  ColumnApi,
  IServerSideGetRowsParams,
  SortModelItem,
} from 'ag-grid-community';
import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import { contractsExcelExport } from 'api/endpoints/contractsExport';
import isEmpty from 'lodash/isEmpty';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { claimsExcelExport, ExportResponse, GridColumn } from '../api/endpoints';
import { createGraphQLQueryFromParams } from './agGridHotchocolate/createGraphQLQueryFromParams';
import { AgGridFilterModel } from './agGridHotchocolate/types';
import { getRequestColumns } from './columns';

export interface SortModel {
  sortModel: SortModelItem[];
}

interface ExcelExport {
  filterModel: AgGridFilterModel;
  columnApi: ColumnApi;
  gridColumns: GridColumn[];
  allColumns?: boolean;
  auditLogs?: boolean;
  section: SectionType;
  endRow?: number;
}

const SECTION_TYPE_MAP = {
  [SectionType.Claims]: claimsExcelExport,
  [SectionType.Contracts]: contractsExcelExport,
};

const generateSortModel = (columnApi: ColumnApi) => {
  const columnState = columnApi.getColumnState();
  const model: SortModel = {
    sortModel: [],
  };

  columnState?.forEach((item: any) => {
    const { sort, colId } = item;
    if (sort) model.sortModel.push({ colId, sort } as SortModelItem);
  });

  return model;
};

export const showDownloadErrorResponse = (response: any = {}) => {
  showHttpSingleError({
    title: 'Download failed. Please contact your IT administrator',
    code: response.status || 0,
  });
};

const downloadFilePost = async (params: {
  query: string;
  variables: any;
  filtered: boolean;
  section: SectionType;
  allColumns?: boolean;
}) => {
  const { section } = params || {};
  const sectionType = (SECTION_TYPE_MAP as any)[section];

  return await sectionType.getFiltered(params);
};

async function excelExport({
  filterModel,
  columnApi,
  gridColumns,
  allColumns,

  section,
  endRow,
}: ExcelExport) {
  const request: any = {
    filterModel,
    ...generateSortModel(columnApi),
    endRow,
  };
  const columns = getRequestColumns(
    { columnApi } as IServerSideGetRowsParams,
    gridColumns,
  );

  if (section === SectionType.Contracts && !allColumns) {
    columns.splice(1, 0, 'counterpartyNames');
  }

  const booleanColumns = gridColumns
    .filter((col) => col.dataType === 'bool' && columns.includes(col.propertyName))
    .map((col) => col.propertyName);

  const { query, variables } = createGraphQLQueryFromParams(request, columns, {
    queryName: `Get${section}`,
    graphqlEntity: `excelExport${section}`,
    booleanColumns,
    gridColumns: gridColumns ?? [],
    ignoreId: true,
  });

  return (await downloadFilePost({
    query: query.loc?.source.body as string,
    variables: JSON.stringify(variables),
    filtered: !isEmpty(filterModel),
    section,
    allColumns,
  })) as ExportResponse;
}

export default excelExport;

import { gql } from '@apollo/client';
import { ISofaTableProps } from '../../tables.types';
import { graphQlFunctionName } from './graphQlEntity';
import { queryfyAsStrings } from './queryfy';

export const createUpdateQuery = (sofaTable: ISofaTableProps, data: any) => {
  const { name, field } = graphQlFunctionName(sofaTable, 'update');
  const dataItems = queryfyAsStrings(data);

  return gql`
      mutation ${name} {
        ${field}( item: ${dataItems} ) 
        {
          id
        }
      }
    `;
};

export const createAddQuery = (sofaTable: ISofaTableProps, data: any) => {
  const { name, field } = graphQlFunctionName(sofaTable, 'add');
  const dataItems = queryfyAsStrings(data);

  return gql`
      mutation ${name} {
        ${field}( item: ${dataItems} ) 
        {
          id
        }
      }
    `;
};

export const createDeleteQuery = (sofaTable: ISofaTableProps, data: any) => {
  const { name, field } = graphQlFunctionName(sofaTable, 'delete');
  const dataItems = queryfyAsStrings(data);

  return gql`
      mutation ${name} {
        ${field}( item: ${dataItems} )
      }
    `;
};

// **************** request should look like this  **************

// mutation UpdateSofasPart1 {
//     updateSofasPart1(
//         item: {
//            id: 125
//            otherRevDesc: "some value"
//            ...
//            ...
//            legalEntityId: 125
//         }
//     )
//     {
//       id
//       ...
//       ...
//       ...
//     }
//   }
// `;

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ContractRow } from 'api/endpoints';
import { ContractMatchRow } from 'pages/SingleContractPage/hooks/useSingleContractMatches';

export interface ContractMatchResult {
  contracts: {
    items: ContractMatchRow[];
    totalCount: number;
  };
}

const QUERY_FIELDS: (keyof ContractRow)[] = [
  'referenceNumber',
  'contractType',
  'category',
  'description',
  'contractDate',
  'contractStatus',
  'id',
];

const fieldParams = QUERY_FIELDS.join(' ');

const queryMatchCodeContracts = gql`
    query GetContractsForMatchCode($matchCode: Int!) {
      contracts(
        take: 1000,  
        where: {
          matchCode: { eq: $matchCode }
        }
      ) {
        totalCount
        items {
          ${fieldParams}
        }
      }
    }
  `;

const queryMatchCodeContract = gql`
    query GetContractForId($id: Int!) {
      contracts(where: {
        id: { eq: $id }
      }) {
        items {
          ${fieldParams}
        }
      }
    }
  `;

export type MatchCodeContract = { matchCode?: ContractRow['matchCode'] };

export function useMatchCodeContractsQuery(contract: MatchCodeContract | undefined) {
  const matchCode = contract?.matchCode;
  return useQuery<ContractMatchResult>(queryMatchCodeContracts, {
    variables: { matchCode },
    skip: !matchCode,
  });
}

export const useLazyContractQuery = () =>
  useLazyQuery<ContractMatchResult>(queryMatchCodeContract, {
    fetchPolicy: 'network-only',
  });

import classNames from 'classnames';
import 'components/UIComponents/ui-button-reset.css';
import './Pagination.css';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'components/UIComponents/SvgIcon';

export type PaginationProps = {
  id?: string;
  className?: string;
  total: number;
  current: number;
  formatUrl: (page: number) => string;
  showWhenSinglePage?: boolean;
  handlePaginationChange?: (pageNo: number) => void;
};

const delta = 1;
const offset = 1;

export const Pagination = ({
  id,
  className,
  current,
  total,
  formatUrl,
  showWhenSinglePage: showWhenSinglePageProp = true,
  handlePaginationChange,
}: PaginationProps) => {
  const prev = offset + ((current - offset - delta + total) % total);
  const prevUrl = formatUrl(prev);
  const next = offset + ((current - offset + delta) % total);
  const nextUrl = formatUrl(next);

  const showWhenSinglePage = total > 1 || showWhenSinglePageProp;

  return (
    <section
      id={id}
      className={classNames(
        'ui-pagination',
        { 'utils-none': !showWhenSinglePage },
        { 'ui-pagination-grid': showWhenSinglePage },
        className,
      )}
    >
      {
        <div className={classNames('ui-pagination__slider')}>
          <div className="ui-pagination__group">
            <Link
              to={prevUrl}
              className="ui-button-reset ui-pagination__base-control"
              onClick={() => handlePaginationChange?.(prev)}
            >
              <SvgIcon iconName="chevron_left" fill="currentColor" />
            </Link>
            {Array.from({ length: total }).map((_, i) => {
              const page = i + 1;
              const url = formatUrl(page);
              const isCurrent = current === page;

              return (
                <Link
                  to={url}
                  key={page}
                  className={classNames('ui-pagination__base-control', {
                    'ui-pagination__current': isCurrent,
                  })}
                  onClick={() => handlePaginationChange?.(page)}
                >
                  {page}
                </Link>
              );
            })}
            <Link
              to={nextUrl}
              className="ui-button-reset ui-pagination__base-control"
              onClick={() => handlePaginationChange?.(next)}
            >
              <SvgIcon rightFlip iconName="chevron_left" fill="currentColor" />
            </Link>
          </div>
        </div>
      }
    </section>
  );
};

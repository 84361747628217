import Select from 'components/UIComponents/Select';
import { useMemo } from 'react';
import { SelectOption } from '../contractsBatchEdit.type';

const LABEL_TEXT = 'Value';

interface IProps {
  value: string | undefined;
  label?: string;
  allowedItems: SelectOption[] | undefined;
  onChange: (value: string | number) => void;
}

export const ItemEditEnumarable = ({
  value,
  label = LABEL_TEXT,
  allowedItems,
  onChange,
}: IProps) => {
  const options = useMemo(() => {
    const values =
      allowedItems?.map((item) => ({
        label: item.label,
        value: item.value,
      })) ?? [];
    return withEmptyItem(values);
  }, [allowedItems]);

  const handleSelectChange = (r: React.ChangeEvent<HTMLSelectElement>) => {
    r.preventDefault();
    onChange(r?.target?.value);
  };

  return (
    <Select
      value={value}
      label={label}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

// ============  internal utils  ==============
const withEmptyItem = (values: SelectOption[]) => {
  if (!values.length) return values;

  const emptyItem = { label: '', value: '' };
  return [emptyItem, ...values];
};

import { useCallback, useEffect, useRef, useState } from 'react';

// why you should use this instead of setInterval
//    It's possible that is, the callback for setInterval() can in turn call setInterval() to start another interval running,
//    even though !!! the first one is still going !!!!

type TAsyncFn = () => Promise<void>;

const TIME_INTERVAL = 1000;

export const useIntervalTimer = () => {
  const callbackFn = useRef<TAsyncFn>();
  const timerId = useRef<number>();
  const timeout = useRef(TIME_INTERVAL);
  const [active, setActive] = useState(false);

  const timerFunction = useCallback(async () => {
    if (!callbackFn.current) return;

    try {
      await callbackFn.current?.();
    } finally {
      clearTimeout(timerId.current); //just in case
      timerId.current = window.setTimeout(() => timerFunction(), timeout.current);
    }
  }, [timeout]);

  const stop = useCallback(() => {
    callbackFn.current = undefined;
    clearTimeout(timerId.current);
    timerId.current = undefined;

    setActive(false);
  }, []);

  const start = useCallback(
    (fn: TAsyncFn, timeoutMs: number = TIME_INTERVAL) => {
      callbackFn.current = fn;
      timeout.current = timeoutMs;
      setActive(true);

      timerFunction();
    },
    [timerFunction],
  );

  // in case we need to run timer function between timers events
  const forceUpdate = useCallback(async () => {
    if (!active) return;

    clearTimeout(timerId.current);
    await timerFunction();
  }, [active, timerFunction]);

  useEffect(() => {
    return () => stop();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    active,
    start,
    stop,
    forceUpdate,
  };
};

import { ICellRendererParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import classNames from 'classnames';
import { validateAndFormatURL } from 'utils/validateAndFormatURL';

interface HyperlinkCellProps extends ICellRendererParams<ClaimRow, any> {
  propName: keyof ClaimRow;
}

export const HyperlinkField = ({ data: claim, propName }: HyperlinkCellProps) => {
  const url = validateAndFormatURL(claim?.[propName] as string);

  return (
    <a
      className={classNames('scp-link-field scp-input  ', {
        disabled: !url,
      })}
      href={url}
      target="_blank"
      rel="noreferrer"
      title={!url ? 'Not a valid URL' : ''}
    >
      {url ?? claim?.[propName]}{' '}
    </a>
  );
};

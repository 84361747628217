export const ACTION_SUBSTATUS = {
  'Amended & Replaced Claims': 1,
  'Duplicative Claims': 2,
  'Redundant Claims': 3,

  'Satisfied During the Case Claims': 4,
  'No Basis Claims': 5,
  'No Liability Claims': 6,
  'No Support Claims': 7,
  'Non-Debtor': 30,
  'Post-Petition': 31,
  'Late Filed Claims': 8,
  'Equity Interest Claims': 9,
  'Contract Assumed & Assigned Claims': 10,
  'Anticipatory Contract Rejection Claims': 11,
  'Beneficial Bondholder Claims': 12,

  'Void Claims': 13,
  'Withdrawn Claims': 14,
  'Anticipated Withdrawal': 15,
  'Allowed Claims': 16,
  'Agreement Stipulation Expunge': 17,
  'Proposed Settlement': 18,
  'Settlement Sent for Signature': 19,
  'Agreement Stipulation Alter': 20,

  'Wrong Debtor Claims': 21,

  'Reduce Claims': 22,
  'Misclassified Claims': 23,
  'Reduce & Reclass Claims': 24,
  'Wrong Debtor Reduce Claims': 25,
  'Wrong Debtor Misclassified Claims': 26,
  'Wrong Debtor Reduce & Reclass Claims': 27,
  'Modify Claims': 28,
  'Wrong Debtor Modify Claims': 29,
} as const;

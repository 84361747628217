import { useMemo } from 'react';
import { useAppSelector } from 'hooks/reducerHooks';
import { USER_ROLE } from 'utils/AppInitializer/application-user';
import { UserSectionId } from '../types';
import { downloadFileGet } from 'api/apiRequest';
import { downloadBlob } from 'utils/file';
import { showDownloadErrorResponse } from 'utils/excelExport';
import { UsersLogsData } from 'reducer/usersLogsReducer';
import {
  SORT_VALUES,
  useEntityTableSorting,
} from 'components/UIComponents/EntityTable/useTableSorting';

export const useUserLogsData = (id?: UserSectionId) => {
  const { users } = useAppSelector((s) => s.userLogs);

  const initialSortState = useMemo(
    () => ({
      field: 'date' as keyof UsersLogsData,
      direction: SORT_VALUES.DESC,
    }),
    [],
  );

  const { sortState, handleSort, sortIcon } =
    useEntityTableSorting<UsersLogsData>(initialSortState);

  const { apUsers, clientUsers } = useMemo(
    () => ({
      apUsers: users.filter(
        (u) =>
          u.role === USER_ROLE.TransactionApprover ||
          u.role === USER_ROLE.TransactionProposer,
      ),
      clientUsers: users.filter(
        (u) =>
          u.role === USER_ROLE.ClientApprover || u.role === USER_ROLE.ClientProposer,
      ),
    }),
    [users],
  );

  const sortedUsers = useMemo(() => {
    const { field, direction } = sortState;
    return [...(id === 'AP' ? apUsers : id === 'Clients' ? clientUsers : [])].sort(
      (a: UsersLogsData, b: UsersLogsData) =>
        direction === SORT_VALUES.ASC
          ? String(a[field]).localeCompare(String(b[field]))
          : String(b[field]).localeCompare(String(a[field])),
    );
  }, [sortState, apUsers, clientUsers, id]);

  const downloadReport = async () => {
    const response = await downloadFileGet('/v1/UserLog/historyExport');
    if (response.type === 'ok') {
      const FILENAME = `UsersLog-${new Date().toISOString()}.xlsx`;
      downloadBlob(response.data as unknown as Blob, FILENAME);
    } else showDownloadErrorResponse(response);
  };

  return {
    apUsersCount: apUsers.length,
    clientUsersCount: clientUsers.length,
    sortedUsers,
    downloadReport,
    sortState,
    handleSort,
    sortIcon,
  };
};

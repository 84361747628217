import classnames from 'classnames';
import { Fragment } from 'react';
import './PopularItems.css';

type Props = React.ComponentProps<'div'> & {
  items: string[];
  onItemClick: (value: string) => void;
};

export const PopularItems = ({
  items,
  className,
  onItemClick,
  ...divProps
}: Props) => {
  const lastItem = items[items.length - 1];

  return (
    <div className={classnames('help-popular-item', className)} {...divProps}>
      Popular:{' '}
      {items.map((item) => {
        const separator = item === lastItem ? ' ' : ', ';
        return (
          <Fragment key={item}>
            <u
              className="help-popular-item__example"
              onClick={() => onItemClick(item)}
            >
              {item}
            </u>
            {separator}
          </Fragment>
        );
      })}
    </div>
  );
};

//<u>Upload Data for Claims</u>, <u>Export Exhibits</u>, <u>Claim Objections</u>

import { ProcessingStatus } from 'api/endpoints';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { memo } from 'react';
import { FileStatus, IUploadItem } from 'types/dataUploaderTypes';

export const PublishButton = memo(
  (props: {
    items: IUploadItem[];
    isPublishing?: boolean;
    isComparingFiles: boolean;
    status: ProcessingStatus | undefined;
    onClick: () => unknown;
  }) => {
    const { items, isPublishing, status, onClick, isComparingFiles } = props;

    const canPublishFiles = items.some((r) => canFileBePublished(r.status));
    const disabled =
      !canPublishFiles ||
      isPublishing ||
      !canBePublished(status) ||
      isComparingFiles;

    return (
      <ButtonMainLoader
        onClick={onClick}
        disabled={disabled}
        loading={isComparingFiles}
      >
        Publish
      </ButtonMainLoader>
    );
  },
);

const canBePublished = (status?: ProcessingStatus) =>
  [
    ProcessingStatus.Unpublished,
    ProcessingStatus.Failed,
    ProcessingStatus.ApplicationException,
  ].includes(status!);

const canFileBePublished = (status?: FileStatus) =>
  [
    FileStatus.Unpublished,
    FileStatus.Failed,
    FileStatus.ApplicationException,
  ].includes(status!);

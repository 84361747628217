import {
  AccordionMenu,
  AccordionMenuSelectedItem,
} from 'components/AccordionMenu/AccordionMenu';
import {
  MenuItem,
  MenuSubItem,
} from 'components/DisclosureLinkMenu/DisclosureLinkMenu.types';
import { useMemo } from 'react';

import './HelpPageNavigation.css';
import { helpStructure } from './helpStructure';

const HELP_MENU_ITEMS: MenuSubItem[] = Object.keys(helpStructure).map((title) => ({
  id: title,
  label: title,
  component: '',
}));

const MENU_CATEGORY: MenuItem[] = [
  {
    id: 'home',
    label: 'Home',
    type: 'nested',
    isButton: true,
    component: '',
    subItems: HELP_MENU_ITEMS,
  },
];

interface Props {
  selected?: string;
  onSelectedChange: (module: string | undefined) => void;
}

export const HelpPageNavigation = ({ selected, onSelectedChange }: Props) => {
  const handleChange = (item: AccordionMenuSelectedItem) => {
    onSelectedChange(item.subItem?.id);
  };

  const selectedItem: AccordionMenuSelectedItem = useMemo(() => {
    const item = MENU_CATEGORY[0];
    const subItem = item.subItems?.find((item) => item.id === selected);
    return { item, subItem };
  }, [selected]);

  return (
    <div className="help-page-nav">
      <AccordionMenu
        items={MENU_CATEGORY}
        selected={selectedItem}
        onChange={handleChange}
      />
    </div>
  );
};

import React, { Fragment, useCallback, useEffect } from 'react';
import './ChangesCommentsList.css';
import 'components/UIComponents/simple-table.css';
import classNames from 'classnames';
import { LogEntry } from 'api/endpoints/claimLogs';
import { dataOrSkeleton } from 'utils/skeleton';
import { DriveFileAnchor } from 'pages/SingleClaimPage/components/DriveFileAnchor';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { labelEntries, skeletonEntries } from './ChangesCommentsList.utils';
import { downloadClaimAttachment } from 'utils/uploader';
import { FetchStatus } from 'types/fetch-status.types';

export type ChangesCommentsListProps = {
  changesIcon?: JSX.Element;
  cardFooter?: JSX.Element;
  section: SectionType;
  logEntriesResult: {
    status: FetchStatus;
    data: {
      entries: LogEntry[];
    } | null;
  };
};

export const ChangesCommentsList = ({
  logEntriesResult,
  changesIcon,
  cardFooter,
  section,
}: ChangesCommentsListProps) => {
  const { data: _data, status } = logEntriesResult;
  const entries = dataOrSkeleton(_data?.entries, skeletonEntries, status);
  const labeledEntries = labelEntries(entries);

  const handleFileClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      const anchor = e.target as HTMLAnchorElement;
      const filename = anchor.dataset['filename'];
      const fileID = anchor.dataset['fileid'];
      if (!filename || !fileID) return;
      // TODO check if doc logs capture claim attachments docs only
      downloadClaimAttachment(+fileID, filename);
    },
    [],
  );

  useEffect(() => {
    if (status === 'fetching') {
      window.scrollTo(0, 0);
    }
  }, [status]);

  const hasEntries = !!entries?.length;

  return (
    <>
      <div
        className={classNames('changes-comments-list', {
          'anim-pulse': status === 'fetching',
          'changes-comments-list-empty': !hasEntries,
        })}
      >
        {status === 'done' && !hasEntries && (
          <section role="table" className="simple-table">
            <div className="simple-table-row-for-empty">
              No changes or comments for this
              {section === SectionType.Claims ? ' claim' : ' contract'} yet.
            </div>
          </section>
        )}

        {labeledEntries?.map((le) => {
          return (
            <Fragment key={le.id}>
              <section
                key={`changes-${le.id}`}
                role="table"
                className="simple-table changes-comments__changes anim-appear"
              >
                <div className="simple-table-header">Log Time</div>
                <div className="simple-table-header">User</div>
                <div>{le.labelledLogTime}</div>
                <div>{le.user}</div>

                {le.type === 'field-changes' && (
                  <>
                    <div className="simple-table-header">Field Edited</div>
                    <div className="simple-table-header">Description of edit</div>
                    {le.labelledChanges?.map((fc) => {
                      return (
                        <Fragment key={fc.id}>
                          <div>{fc.displayName}</div>
                          <div className="changes_comments__field-label">
                            <span>{fc.label}</span> {changesIcon}
                          </div>
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </section>

              <section
                key={`comments-${le.id}`}
                role="table"
                className="simple-table changes-comments__comm-area anim-appear"
              >
                <div className="simple-table-header">Comment</div>
                <div className="changes-comments__comm-label">
                  {le.labelledComment}
                </div>

                {le.type === 'file-add' && section === SectionType.Claims && (
                  // there is no endpoint for ContractDocuments download yet
                  <>
                    <div className="special-row changes-comments-file-link">
                      <DriveFileAnchor
                        href="#"
                        data-filename={le.addedFile.displayName}
                        data-fileid={le.addedFile.id}
                        onClick={handleFileClick}
                      >
                        {le.addedFile.displayName}
                      </DriveFileAnchor>
                    </div>
                  </>
                )}
                {cardFooter && (
                  <div className="special-row changes-comments__card_footer">
                    {cardFooter}
                  </div>
                )}
              </section>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

import classNames from 'classnames';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ComponentProps } from 'react';
import './SliderDark.css';

type Props = ComponentProps<typeof RcSlider>;

export const SliderDark = ({ className, ...props }: Props) => (
  <RcSlider {...(props as any)} className={classNames(className, 'slider-dark')} />
);

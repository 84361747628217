import { ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { URL_PATH } from 'appUrls';
import classNames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useDashboardContextData } from 'pages/Dashboard/context/DashboardContextProvider';
import { useNavigate } from 'react-router-dom';
import { DashboardTaskInfo } from 'reducer/userDashboardTasksReducer';
import { DashboardTaskCount } from './DashboardTaskCount';

export const DashboardTask = ({
  task,
  firstTask,
  taskPercentage,
  taskDescription,
}: {
  task: DashboardTaskInfo;
  firstTask: boolean;
  taskPercentage?: number;
  taskDescription: string;
}) => {
  const navigate = useNavigate();
  const { handleTaskClick, fetchingApTasks, fetchingContractsData } =
    useDashboardContextData();

  const handleFirstTaskClick = () => {
    if (!firstTask || !task.to) return;
    navigate({ pathname: URL_PATH.CLAIMS, search: task.to.replace('/claims?', '') });
  };

  const { detailedViewValue, indentLeft, hasSeparator, sectionType, footnote } =
    task;

  const handleOnTaskClick = () => {
    if (!detailedViewValue) return;
    handleTaskClick?.({
      section: detailedViewValue,
      isTask: true,
      sectionType,
    });
  };

  if (task.id === 'separator') {
    return <span className="tasks-separator"></span>;
  }

  return (
    <div
      key={`${task.id}_${taskDescription}`}
      className={classNames('task', {
        firstTask,
        indentLeft,
        withBorder: hasSeparator,
        hasPointer: !!task.to,
        hasFootnote: !!task.footnote,
      })}
      onClick={handleFirstTaskClick}
    >
      <div>
        <span
          onClick={handleOnTaskClick}
          className={classNames('left-col', {
            hasPointer: !!detailedViewValue,
          })}
          title={`${
            detailedViewValue ? 'Click to see the detailed view for this type' : ''
          }`}
        >
          {taskDescription}
          {fetchingContractsData &&
            detailedViewValue &&
            !firstTask &&
            sectionType === SectionType.Contracts && (
              <ApLoaderDefault loaderSize="sm" />
            )}
          {firstTask && (
            <ApIcon iconName="baseline_arrow_forward" iconColor="white" />
          )}
        </span>
        <span className="right-col task-count">
          {fetchingApTasks ? (
            <ApLoaderDefault loaderSize="sm" />
          ) : (
            <DashboardTaskCount
              task={task}
              taskPercentage={taskPercentage}
              firstTask={firstTask}
            />
          )}
        </span>
      </div>
      {footnote && <b>{footnote}</b>}
    </div>
  );
};

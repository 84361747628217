import { ApButtonMain, ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './ButtonMainLoader.css';

type ButtonProps = React.ComponentProps<typeof ApButtonMain>;

interface Props extends ButtonProps {
  loading?: boolean;
}

export const ButtonMainLoader = ({
  loading,
  iconName,
  className,
  children,
  ...buttonProps
}: Props) => {
  return (
    <ApButtonMain
      autoFocus={false}
      disabled={loading}
      theme="light"
      iconName={!loading ? iconName : undefined}
      className={classNames('button-main-loader', className)}
      {...buttonProps}
    >
      {loading ? <ApLoaderDefault loaderSize="sm" theme="dark" /> : null}
      {children}
    </ApButtonMain>
  );
};

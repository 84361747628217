import { ApCheckbox } from '@alixpartners/ui-components';
import classnames from 'classnames';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { ComponentProps } from 'react';
import './SettlementViewSavePanel.css';

type Props = ComponentProps<'div'> & {
  comment?: string;
  regenerate?: boolean;
  saveEnabled?: boolean;
  showRegenerate?: boolean;
  onCommentChange: (value: string) => void;
  onRegenerageChange: (value: boolean) => void;
  onSaveClick: () => void;
};

export const SettlementViewSavePanel = ({
  className,
  comment,
  regenerate,
  saveEnabled = true,
  showRegenerate = false,
  onCommentChange,
  onRegenerageChange,
  onSaveClick,
  ...props
}: Props) => {
  return (
    <div
      className={classnames('settlememt-view-save-panel--container', className)}
      {...props}
    >
      <div className="settlememt-view-save-panel">
        {/* todo: uncomment comment field after backend is ready */}
        {/* <ApInput
          className="settlememt-view-save-panel__comment"
          //   disabled={!open}
          label="Add a comment about your updates for this settlement:"
          value={comment}
          onChange={onCommentChange}
        /> */}
        <div className="settlememt-view-save-panel__checkbox">
          {showRegenerate && (
            <ApCheckbox
              id={'settlements-regenerate-agreement'} //
              label=""
              type="checkbox"
              checked={regenerate}
              onChange={() => onRegenerageChange(!regenerate)}
            >
              Regenerate Settlement Agreement
            </ApCheckbox>
          )}
        </div>
        <ButtonMainLoader onClick={onSaveClick} disabled={!saveEnabled}>
          Save Changes
        </ButtonMainLoader>
      </div>
    </div>
  );
};

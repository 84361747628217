export const SOFA_10_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['18', '19', '20'],
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Account Number (Last 4 Digits)',
      propertyName: 'accountNumber',
    },
    {
      dataType: 'string',
      displayName:
        'Type Of Account (Checking, Savings, Money market, Brokerage, Other)',
      propertyName: 'typeOfAccount',
    },
    {
      dataType: 'string',
      displayName: 'Date Account Was Closed',
      propertyName: 'dateClosed',
    },
    {
      dataType: 'decimal',
      displayName: 'Last Balance Before Closing',
      propertyName: 'balance',
    },
    {
      dataType: 'string',
      displayName: 'Names Of Anyone With Access',
      propertyName: 'namesOfAccess',
    },
    {
      dataType: 'string',
      displayName: 'Address Of Anyone With Access',
      propertyName: 'accessAddress',
    },
    {
      dataType: 'string',
      displayName: 'Description of the Contents',
      propertyName: 'description',
    },
    {
      dataType: 'bool',
      displayName: 'Does Debtor Still Have It',
      propertyName: 'debtorOwnership',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

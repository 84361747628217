import { useState, useCallback, useMemo } from 'react';
import { PRIMARY_COLUMN, PIN_COLUMN } from '../const';
import { CellClickedEvent } from 'ag-grid-community';
import { SectionType } from '../types';
export interface PinRow {
  [PRIMARY_COLUMN]: number | string;
}

/**
 * This hook is used to pin rows in the grid
 * @param section
 * @returns
 */
function usePinRows<T extends PinRow>(section: SectionType) {
  const [pinned, setPinnedTopRowData] = useState<any[]>([]);

  const onPinCellClickedInjector = useCallback(
    (event: CellClickedEvent<T>) => {
      const { column, data } = event;
      if (column.getColDef().field !== PIN_COLUMN) return;

      const exists = pinned.find(
        (c) => c[PRIMARY_COLUMN] === data?.[PRIMARY_COLUMN],
      );
      if (!exists) {
        setPinnedTopRowData([...pinned, data]);
        return;
      }
      const newPinnedTopRowData = pinned.filter(
        (c) => c[PRIMARY_COLUMN] !== data?.[PRIMARY_COLUMN],
      );
      setPinnedTopRowData(newPinnedTopRowData);
    },
    [pinned],
  );

  const pinnedTopRowData = useMemo(() => {
    if (section === SectionType.Matching) return [];
    return pinned;
  }, [pinned, section]);

  return {
    pinnedTopRowData,
    onPinCellClickedInjector,
  };
}

export default usePinRows;

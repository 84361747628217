import { ACTION_SUBSTATUS } from '../types/exhibitConst';

export enum ExhibitTemplates {
  AllowedClaims = 'AllowedClaims',
  AlterClaimAmount = 'AlterClaimAmount',
  AlterClaimAmountProposeDebtor = 'AlterClaimAmountProposeDebtor',
  AlterDebtor = 'AlterDebtor',
  ExpungeNoSurviving = 'ExpungeNoSurviving',
  ExpungeWithSurviving = 'ExpungeWithSurviving',
}

export const getTemplateName = (
  substatusId: number | undefined,
): ExhibitTemplates | undefined => {
  switch (substatusId) {
    case ACTION_SUBSTATUS['Amended & Replaced Claims']:
    case ACTION_SUBSTATUS['Duplicative Claims']:
    case ACTION_SUBSTATUS['Redundant Claims']:
    case ACTION_SUBSTATUS['Beneficial Bondholder Claims']:
      return ExhibitTemplates.ExpungeWithSurviving;
    case ACTION_SUBSTATUS['Satisfied During the Case Claims']:
    case ACTION_SUBSTATUS['No Basis Claims']:
    case ACTION_SUBSTATUS['No Liability Claims']:
    case ACTION_SUBSTATUS['No Support Claims']:
    case ACTION_SUBSTATUS['Non-Debtor']:
    case ACTION_SUBSTATUS['Post-Petition']:
    case ACTION_SUBSTATUS['Late Filed Claims']:
    case ACTION_SUBSTATUS['Equity Interest Claims']:
    case ACTION_SUBSTATUS['Contract Assumed & Assigned Claims']:
    case ACTION_SUBSTATUS['Anticipatory Contract Rejection Claims']:
      return ExhibitTemplates.ExpungeNoSurviving;
    case ACTION_SUBSTATUS['Wrong Debtor Claims']:
      return ExhibitTemplates.AlterDebtor;
    case ACTION_SUBSTATUS['Reduce Claims']:
    case ACTION_SUBSTATUS['Misclassified Claims']:
    case ACTION_SUBSTATUS['Reduce & Reclass Claims']:
    case ACTION_SUBSTATUS['Modify Claims']:
      return ExhibitTemplates.AlterClaimAmount;
    case ACTION_SUBSTATUS['Wrong Debtor Reduce Claims']:
    case ACTION_SUBSTATUS['Wrong Debtor Misclassified Claims']:
    case ACTION_SUBSTATUS['Wrong Debtor Reduce & Reclass Claims']:
      return ExhibitTemplates.AlterClaimAmountProposeDebtor;
    case ACTION_SUBSTATUS['Allowed Claims']:
      return ExhibitTemplates.AllowedClaims;
    default:
      return undefined;
  }
};

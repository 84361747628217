import { useAppInitialDataContext } from 'utils/AppInitializer';
import { ModuleTip } from './ModuleCard';

export function ClientInfoModuleTip() {
  const { data } = useAppInitialDataContext();
  const { clientInfo: info } = data.basicInfo;
  return (
    <ModuleTip>
      Welcome to the module selector. Here you will find a range of tools you will
      need for your Chapter 11 engagement
      {info ? <> with {info.clientCompanyName}</> : null}.
    </ModuleTip>
  );
}

import classNames from 'classnames';
import { ApNiceSelect } from 'components/ApNiceSelect';
import { ChangeEvent, useCallback } from 'react';
import './ExhibitSelect.css';

type SelectProps = React.ComponentProps<typeof ApNiceSelect>;

interface Props extends Omit<SelectProps, 'onChange'> {
  label: string;
  onChange: (value: string) => void;
}

export const ExhibitSelect = ({
  label,
  className,
  onChange,
  ...selectProps
}: Props) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault();
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={classNames('exhibit-select', className)}>
      <label>{label}</label>
      <ApNiceSelect
        containerClassName="exhibit-select__container"
        className="exhibit-select__input"
        emptyDefaultOption
        required
        onChange={handleChange}
        {...selectProps}
      />
    </div>
  );
};

import { sofasAndSchedules } from 'api/endpoints/sofas-schedules';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { SelectedItems } from './sofaExportTypes';

const selectedTemplate: SelectedItems = { useAll: false, items: [] };

export const useTemplateLoading = () => {
  const [selected, setSelected] = useState(selectedTemplate);
  const [loading, setTemplateLoading] = useState(false);

  const submit = useCallback(async () => {
    if (loading || isEmpty(selected.items)) return;
    setTemplateLoading(true);

    try {
      await sofasAndSchedules.excelExportReport({
        templateId: selected.items[0].value,
        label: selected.items[0].label,
      });
    } finally {
      setTemplateLoading(false);
    }
  }, [selected.items, loading]);

  return {
    loading,
    selected,
    setSelected,
    submit,
  };
};

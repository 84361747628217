import { SortModelItem } from 'ag-grid-community';

// do not change, ag-grid scroll always loads 100 rows
export const PAGINATION_COUNT = 100;

// if you change this, you'll have to fix tests in dataSource.test.ts
export const GRAPHQL_VAR_NAME = 'var';

export const sortByNameAsc: SortModelItem[] = [
  {
    colId: 'counterpartyName',
    sort: 'asc',
  },
];

export const sortByRefAsc: SortModelItem[] = [
  {
    colId: 'referenceNumberSort',
    sort: 'asc',
  },
];

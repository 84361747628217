import { ActionExhibitTypeId } from 'api/endpoints/actions-select-options';

export function isEditableClient(
  field: string,
  actionTypeId: ActionExhibitTypeId | undefined | null,
) {
  if (actionTypeId === ActionExhibitTypeId.Expunge)
    return !CLIENT_EXPUNGE_PERMISSIONS.includes(field);

  if (
    [
      ActionExhibitTypeId.ExpungeSettlement,
      ActionExhibitTypeId.ExpungeOther,
    ].includes(actionTypeId!)
  )
    return !CLIENT_EXPUNGE_OTHERS_PERMISSIONS.includes(field);

  if (actionTypeId === ActionExhibitTypeId.Allow)
    return !CLIENT_EXPUNGE_OTHERS_PERMISSIONS.includes(field);

  if (actionTypeId === ActionExhibitTypeId.AlterSettlement)
    return !CLIENT_DEFAULT_PERMISSIONS.includes(field);

  if (actionTypeId === ActionExhibitTypeId.AlterObjection)
    return !CLIENT_DEFAULT_PERMISSIONS.includes(field);

  return !CLIENT_DEFAULT_PERMISSIONS.includes(field);
}
/**
 * in this file you can define permissions
 * field for client user per resolution type status
 */
export const CLIENT_BASE_READONLY_FIELDS = [
  'original',
  'current',
  'proposed',
  'high',
  'low',
  'councel', // todo: delete. not used
  'alixPartners',
  'claimReviewer',
  'purchaseOrderReference', // todo: delete. not used
  'itemQuantityCount', // todo: delete. not used
  'poRefOnInvoice', // todo: delete. not used
  'purchasingDepartment', // todo: delete. not used
  'purchaseOrderDate', // todo: delete. not used
  'dateInvoiceReceived', // todo: delete. not used

  // Claim Status
  'actionStatus',
  'actionNameId',
  'actionSubstatusId',
  'actionReviewStatus', // todo: delete. not used
  'reviewLevelId',
  'objectionCommentary',
];

export const CLIENT_DEFAULT_PERMISSIONS = [
  ...CLIENT_BASE_READONLY_FIELDS,
  'survivingClaimNumber',
];

const CLIENT_EXPUNGE_PERMISSIONS = [
  ...CLIENT_BASE_READONLY_FIELDS,

  'contactFullName',
  'email',
  'address1',
  'address2',
  'address3',
  'address4',
  'city',
  'phoneNumber',
  'state',
  'country',
  'zip',

  'proposedDebtor', // todo: delete. not used
  'outsideContact', // todo: delete. not used
];

export const CLIENT_EXPUNGE_OTHERS_PERMISSIONS = [
  ...CLIENT_EXPUNGE_PERMISSIONS,
  'survivingClaimNumber',
];

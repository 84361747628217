import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportLegalEntity } from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';

export type ReportLegalEntitiesSlice =
  | {
      type: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      type: FetchStatus.Done;
      data: ReportLegalEntity[];
      error: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: ReportLegalEntity[];
    }
  | {
      type: FetchStatus.Fetching;
      data: ReportLegalEntity[];

      error: null;
    };

export const initialState: ReportLegalEntitiesSlice = {
  type: FetchStatus.Idle,
  error: null,
  data: null,
} as ReportLegalEntitiesSlice;

const SLICE_NAME = 'report-legal-entities';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetching(state: ReportLegalEntitiesSlice) {
      state.type = FetchStatus.Fetching;
    },
    done(
      state: ReportLegalEntitiesSlice,
      action: PayloadAction<{
        items: ReportLegalEntity[];
      }>,
    ) {
      state.type = FetchStatus.Done;
      state.data = action.payload.items;
    },
    error(
      state: ReportLegalEntitiesSlice,
      action: PayloadAction<{ message: string }>,
    ) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const reportLegalEntitiesActions = slice.actions;
export default slice.reducer;

import Select from 'components/UIComponents/Select';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { IBatchItem } from '../../contractsBatchEdit.type';
import { colProp } from '../../contractsBatchEdit.const';
import {
  contractActionSubstatusIdSet,
  contractActionTypeIdSet,
  contractStatusIdSet,
} from 'reducer/contractActionNameReducer';
import { useState } from 'react';

/*
propName is used to trigger the onChange method if the field was selected directly from batch edit dropdown
or addCascadingItem method if the field is cascading type and wasn't directly selected from the dropdown
*/
export const ContractResolutionType = ({
  onChange,
  addCascadingItem,
  propName,
}: {
  addCascadingItem: (item: IBatchItem) => void;
  onChange: (val: number) => void;
  propName: string;
}) => {
  const [contractStatus, setContractStatus] = useState(0);
  const [resolutionType, setResolutionType] = useState(0);
  const { actionTypeOptions, substatusOptions } = useAppSelector(
    (s) => s.contractActionNameSelection,
  );
  const { contractStatuses } = useAppSelector((s) => s.selectable.data);
  const dispatch = useAppDispatch();

  return (
    <div className="action-name-edit">
      <div className="action-name-edit__selector">
        <label>Workflow status</label>
        <Select
          name={'Workflow status'}
          options={contractStatuses.map((c) => ({
            label: c.contractStatus,
            value: c.id,
          }))}
          onChange={(e) => {
            dispatch(contractStatusIdSet(+e.target.value));
            if (propName === colProp.contractStatusId) {
              onChange(+e.target.value);
            } else {
              addCascadingItem({
                id: '',
                propName: colProp.contractStatusId,
                value: +e.target.value,
              });
            }
            setContractStatus(+e.target.value);
          }}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution type</label>
        <Select
          name={'Resolution type'}
          options={actionTypeOptions.map((c) => ({
            label: c.contractActionTypeName,
            value: c.id,
          }))}
          onChange={(e) => {
            dispatch(contractActionTypeIdSet({ value: +e.target.value }));
            if (propName === colProp.contractActionTypeId) {
              onChange(+e.target.value);
            } else {
              addCascadingItem({
                id: '',
                propName: colProp.contractActionTypeId,
                value: +e.target.value,
              });
            }
            setResolutionType(+e.target.value);
          }}
          disabled={!contractStatus}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution subtype</label>
        <Select
          name={'Resolution subtype'}
          options={substatusOptions.map((c) => ({
            label: c.contractActionSubStatusName,
            value: c.id,
          }))}
          onChange={(e) => {
            dispatch(contractActionSubstatusIdSet({ value: +e.target.value }));
            if (propName === colProp.contractActionSubStatusId) {
              onChange(+e.target.value);
            } else {
              addCascadingItem({
                id: '',
                propName: colProp.contractActionSubStatusId,
                value: +e.target.value,
              });
            }
          }}
          disabled={!resolutionType}
        />
      </div>
    </div>
  );
};

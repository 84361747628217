import { MappingSummary, ProcessStatus } from 'api/endpoints/documents';
import { createContext, useContext } from 'react';
import { UploadProgressState } from '../types';
import { useDocuments } from '../hooks/useDocuments';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';

type DCProps = {
  children: JSX.Element;
  sectionType: SectionType.Claims | SectionType.Contracts;
  subsection: SubsectionType;
};

type DocumentsContextData = {
  handleCancelUpload: () => void;
  resetUploadState: () => void;
  summary: MappingSummary;
  sectionType: SectionType.Claims | SectionType.Contracts;
  downloadReport: ({
    url,
    fileName,
    resetUploadDetails,
  }: {
    url: string;
    fileName: string;
    resetUploadDetails?: boolean | undefined;
  }) => Promise<void>;
  zipFilesUploading?: number;
  unzipping?: boolean;
  docUnzipStatus?: ProcessStatus;
  mappingStatus?: ProcessStatus;
  uploadProgress?: UploadProgressState;
  handleUploadZipDocs: (files: File[]) => Promise<void>;
  handleUploadDocs: (files: File[]) => Promise<void>;
  handleUploadZipMapping: (files: File[]) => void;
  mappingFileUploading?: boolean;
  imageFilesUploading?: number;
  unzippingTotalFiles?: number;
  unzippingProcessedFiles?: number;
  helpSteps: {
    title: string;
    content: string;
  }[];
};

const DocumentsPageContext = createContext<DocumentsContextData>(
  {} as DocumentsContextData,
);

export const DocumentsPageContextProvider = ({
  children,
  sectionType,
  subsection,
}: DCProps) => {
  const {
    handleUploadZipDocs,
    summary,
    handleUploadDocs,
    handleCancelUpload,
    handleUploadZipMapping,
    downloadReport,
    resetUploadState,
    statusState,
    fileState,
    uploadProgress,
    helpSteps,
  } = useDocuments(sectionType, subsection);
  const { docUnzipStatus, mappingStatus } = statusState;
  const {
    zipFilesUploading,
    mappingFileUploading,
    unzipping,
    imageFilesUploading,
    unzippingProcessedFiles,
    unzippingTotalFiles,
  } = fileState;

  return (
    <DocumentsPageContext.Provider
      value={{
        handleUploadZipDocs,
        summary,
        handleUploadDocs,
        handleCancelUpload,
        handleUploadZipMapping,
        downloadReport,
        resetUploadState,
        zipFilesUploading,
        mappingFileUploading,
        unzipping,
        imageFilesUploading,
        docUnzipStatus,
        mappingStatus,
        uploadProgress,
        sectionType,
        unzippingProcessedFiles,
        unzippingTotalFiles,
        helpSteps,
      }}
    >
      {children}
    </DocumentsPageContext.Provider>
  );
};
export const useDocumentsContextData = () => useContext(DocumentsPageContext);

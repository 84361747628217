import { ApIconInfoTooltip } from 'components/UIComponents/ApIconTooltip';

export const WizardFieldLabel = (props: { text: string; info?: string }) => {
  return (
    <div className="setup-wizard-field__label">
      <span className="label">{props.text}</span>
      {props.info && <ApIconInfoTooltip message={props.info} />}
    </div>
  );
};

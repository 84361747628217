import {
  dataPipeline,
  DataPipelineStatusResponse,
  ProcessingStatus,
} from 'api/endpoints';
import { useCallback, useState } from 'react';
import { useMonitoringTimer } from 'utils/hooks/useMonitoringTimer';
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect';

const MONITOR_TIME_INTERVAL = 2 * 1000; //milliseconds
const MAX_ALLOWED_ERRORS = 20;
const ERROR_MESSAGE =
  'Error obtaining the publishing status. Try to refresh the page';

export const usePipeLineMonitoring = () => {
  const { startTimer, stopTimer } = useMonitoringTimer(MONITOR_TIME_INTERVAL);
  const [pipeline, setPipeline] = useState<DataPipelineStatusResponse | undefined>();
  const [errorsCount, setErrorsCount] = useState(0);

  const [monitorProcessing, setMonitorProcessing] = useState(false);

  const executeStatusMonitoring = useCallback(async () => {
    const { data } = await dataPipeline.getStatusQuiet();

    if (!data) {
      console.error('DataPipeline/status request - returns an empty data');
      setErrorsCount((r) => r + 1);
      return;
    }

    setPipeline(data);
    setErrorsCount(0);
    setMonitorProcessing(data.processingStatus === ProcessingStatus.InProgress);
  }, []);

  const startPipelineMonitoring = () => {
    setMonitorProcessing(true);
    setPipeline(undefined);
  };

  useUpdateEffect(() => {
    if (!monitorProcessing) {
      stopTimer();
      return;
    }

    startTimer(executeStatusMonitoring);
    //do not include "stopTimer" and "startTimer" into DependencyList
  }, [monitorProcessing]);

  const processingError =
    errorsCount > MAX_ALLOWED_ERRORS ? ERROR_MESSAGE : undefined;

  const resetPipelineProcessingStatus = useCallback(
    () => setPipeline((prev) => ({ ...prev!, processingStatus: undefined })),
    [],
  );

  return {
    pipeline,
    monitoring: {
      active: monitorProcessing,
      errorMessage: processingError,
    },
    initPipeline: executeStatusMonitoring,
    startPipelineMonitoring,
    resetPipelineProcessingStatus,
  };
};

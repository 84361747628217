import { upperFirst } from 'lodash';
import { del, get, put } from '../apiRequest';
import { Debtor } from 'pages/SingleContractPage/sections/SingleContractCounterpartiesAndDebtors/SingleContractCounterpartiesAndDebtorsTable';

export interface CounterpartiesOrDebtors {
  counterpartyName: string;
  debtorName?: string;
  matchCode?: number | string;
  isPrimary?: boolean;
  id: number;
}

export const counterpartiesAndDebtors = {
  getData: (contractId: number, type: string) => {
    return get<CounterpartiesOrDebtors[]>(`v1/Contracts/${contractId}/${type}`);
  },
  setPrimary: (id: number, type: Debtor) =>
    put(`v1/Contract${upperFirst(type)}/${id}/toggle-primary/true`, {}),
  delete: (id: number, type: Debtor) => del(`v1/Contract${upperFirst(type)}/${id}`),
};

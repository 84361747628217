import { ContractActionName } from 'api/endpoints/action-names';
import {
  AddFields,
  EditFieldsList,
  EntityTable,
  Panel,
} from 'components/UIComponents/EntityTable';
import { AwarePanel } from 'components/UIComponents/EntityTable/EntityTable.components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import './ActionNames.css';
import { ContractActionNameInput } from './ContractActionNameInput';
import { useEditAndAddActionNames } from './useActionNames';
import { filterExistingActionNames } from './utils';

const HEADERS = ['Resolution name', 'Exhibit type', 'Hearing Date'];
const COLUMN_KEYS: (keyof ContractActionName)[] = [
  'contractActionName',
  'contractExhibitTypeId',
  'hearingDate',
];

export function ContractsActionNames() {
  const { contractsActionNames, status, onContractAddSave, onContractEditSave } =
    useEditAndAddActionNames(SectionType.Contracts);
  const actionNames = contractsActionNames ?? [];

  return (
    <SettingsContentPanel>
      <section className="actionnames">
        <EntityTable
          items={filterExistingActionNames(actionNames)}
          status={status}
          addColumnKeys={COLUMN_KEYS}
          editColumnKeys={COLUMN_KEYS}
          inputComponent={ContractActionNameInput}
          onEditSave={onContractEditSave}
          onAddSave={onContractAddSave}
          section={SectionType.Contracts}
        >
          <Panel title="Add a new resolution name">
            <AddFields headers={HEADERS} />
          </Panel>
          <AwarePanel title="Resolution names">
            <EditFieldsList headers={HEADERS} />
          </AwarePanel>
        </EntityTable>
      </section>
    </SettingsContentPanel>
  );
}

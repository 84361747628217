import { claimLogs } from 'api/endpoints/claimLogs';
import {
  claimLogsFetching,
  claimLogsDone,
  claimLogsError,
  PaginationInput,
  paginationChanged,
} from '../claimLogsDataReducer';
import { AppDispatch, GetState } from '../store';

export const fetchClaimLogsThunk =
  (input: PaginationInput) => async (dispatch: AppDispatch, getState: GetState) => {
    // we should always fetch from server
    dispatch(claimLogsFetching(input));
    const response = await claimLogs.get(input.id);
    if (!response) {
      // errors are shown by the popup already but let the reducer know
      dispatch(claimLogsError({ message: 'Could not fetch claims log.' }));
      return;
    }
    dispatch(
      claimLogsDone({
        ...response,
        ...input,
      }),
    );

    dispatch(paginationChanged(input));
  };

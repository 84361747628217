import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApIcon,
} from '@alixpartners/ui-components';
import { SubmitData, UploadModal } from 'components/ModalWindows/UploadModal';

import { ErrorModal } from 'components/ModalWindows/UploadModal/ErrorModal';

import './BatchUploadDialogs.css';

export const BatchFilesUploadDialog = (props: {
  label: string;
  uploading: boolean;
  warningText?: string;
  onSubmit: (items: SubmitData) => void;
  onClose: () => void;
}) => {
  const { label, warningText, onSubmit, onClose } = props;

  return (
    <UploadModal
      label={label}
      options={{
        withExtension: '.xlsx',
        maxOneFile: true,
        warning: warningText,
        hideComments: true,
      }}
      onClosed={onClose}
      onSubmit={onSubmit}
    />
  );
};

export const BatchFilesErrorDialog = (props: {
  onClose: () => void;
  onDownloadError: () => void;
}) => {
  return (
    <ErrorModal
      header={
        <span className="upload-error-modal__title">
          <ApIcon iconName="error" iconColor="#D97706" />
          Error: the file couldn't be uploaded
        </span>
      }
      onClosed={props.onClose}
      renderFooter={() => (
        <ApButtonGroup>
          <ApButtonSecondary className="ap-button-casual" onClick={props.onClose}>
            Cancel
          </ApButtonSecondary>
          <ApButtonMain onClick={props.onDownloadError}>
            <ApIcon iconName="save_alt" />
            Download error log file
          </ApButtonMain>
        </ApButtonGroup>
      )}
      isOpen
      content={[
        "We've detected some errors in the file you are trying to upload.",
        'Please use the button below to download the errors file. Once you solved all the errors you can try upload the file again.',
      ]}
    />
  );
};

import { ApButtonSecondary } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { ApProgressBar } from 'components/ApProgressBar';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { useState } from 'react';
import './FileRow.css';

import { FileStatus, IUploadItem } from 'types/dataUploaderTypes';

import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { formatUtcTimeAgo } from 'utils/format';
import { getStatusText } from '../utils/fileStatusUtils';
import { FileStatusIcon } from './FileStatusIcon';

interface IProps {
  item: IUploadItem;
  disabled?: boolean;
  onDownload: (item: IUploadItem) => void;
  onDownloadErrors: (item: IUploadItem) => void;
  onDelete: (item: IUploadItem) => void;
}

export const FileRow = ({
  item,
  disabled,
  onDownload,
  onDelete,
  onDownloadErrors,
}: IProps) => {
  const [hidden, setHidden] = useState(true);
  const { errors, errorCount, status, uploadedFile, uploadProgress = 0 } = item;
  const { createdBy, createdDate } = uploadedFile ?? {};

  const statusText = getStatusText(errorCount, status);

  return (
    <div
      className={classNames('file-panel', {
        'file-panel__has-error': errors && errors.length,
      })}
    >
      <div className="file-panel__header">
        <div className="file-panel__icon">
          <FileStatusIcon fileStatus={status} isError={!!errors} />
        </div>
        <div className="file-panel__header-title">
          <div
            className={classNames('file-panel__filename', {
              unpublished: status === FileStatus.Unpublished,
            })}
            onClick={() => onDownload(item)}
          >
            {item.displayName}
          </div>
          <div className="file-panel__errors-hide-container">
            {uploadProgress > 0 && status === FileStatus.Uploading ? (
              <ApProgressBar
                progress={uploadProgress}
                className="file-panel__progress_bar"
              />
            ) : (
              <div className="file-panel__errors-label">{statusText}</div>
            )}

            {errors && (
              <ApButtonSecondary
                onClick={() => setHidden(!hidden)}
                className="file-panel__show-hide__button"
              >
                <span>{hidden ? 'Show' : 'Hide'}</span>
                <div
                  className={classNames('file-panel__chevron', {
                    'file-panel--rotated': hidden,
                  })}
                >
                  <SvgIcon iconName="caret" height={10} width={10} />
                </div>
              </ApButtonSecondary>
            )}
          </div>
        </div>
        <div className="file-panel__additional">
          <div className="file-panel__uploaded-by">
            {createdBy && <span>{`Uploaded by ${createdBy}`}</span>}
            {createdDate && <span>{formatUtcTimeAgo(createdDate)}</span>}
          </div>
          <div className="file-panel__download-btn">
            <ButtonIcon
              width={34}
              height={34}
              iconColor="var(--ap-color-ui-grey-darker)"
              icon="download_file"
              onClick={() => onDownload(item)}
            />
          </div>
          {status !== FileStatus.Published && (
            <div className="file-panel__delete-btn">
              <ButtonIcon
                width={34}
                height={34}
                icon="trash"
                disabled={disabled}
                onClick={() => onDelete(item)}
              />
            </div>
          )}
        </div>
      </div>
      {!hidden && errors && (
        <div className="file-panel__content">
          <div className="file-panel__errors">
            {errors.map((error, index) => (
              <div className="file-panel__error" key={`error${index}`}>
                <pre>{error}</pre>
              </div>
            ))}
          </div>
          <div className="file-panel__footer">
            <div>
              <ApButtonSecondary onClick={() => onDownloadErrors(item)}>
                Download Errors List
              </ApButtonSecondary>
            </div>
            <div>
              <ApButtonSecondary onClick={() => onDownload(item)}>
                Download Original File
              </ApButtonSecondary>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

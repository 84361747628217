import React, { forwardRef } from 'react';
import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';

type ApButtonProps = React.ComponentProps<typeof ApButtonMain>;
type Props = { thinShape?: boolean } & ApButtonProps;

// ---------------------------------------------------------------------------------------------
// it is a wrapper over ApButtonMain button to be able to apply Chapter 11 custom buttons styles
// ---------------------------------------------------------------------------------------------

export const ApButtonMainEx = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, thinShape, ...buttonProps } = props;
  return (
    <ApButtonMain
      {...buttonProps}
      className={classNames('ap-button-main-ex', className, {
        'ap-button-thin': thinShape,
      })}
      ref={ref}
    />
  );
});

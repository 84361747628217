import { IServerSideDatasource } from 'ag-grid-community';
import { userDashboardTasks } from 'api/endpoints/dashboard-tasks';
import { GraphQlApolloClient } from 'api/graphql';
import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import store from 'reducer/store';
import { createGraphQLQueryFromParams } from 'utils/agGridHotchocolate/createGraphQLQueryFromParams';
import {
  IServerSideGetRowsParamsWithFilterModel,
  IServerSideGetRowsRequestWithFilterModel,
} from 'utils/agGridHotchocolate/types';
import { getRequestColumns } from '../../../utils/columns';
import {
  CONTRACTS_DEFAULT_DISPLAY,
  CREATED_DATE_CELL,
  HAS_ONE_HUNDRED_PERCENT_MATCH_CELL,
  MATCH_CODE_COLUMN,
  NOT_MATCHED_CELL,
  NUMBER_OF_MATCHES_CELL,
  ONE_HUNDRED_PERCENT_MATCH_CODE_CELL,
  SOURCE_TYPE_COLUMN,
} from '../const';
import { ClaimsDataState, SectionType, SubsectionType } from '../types';
import calculateClaims from '../util/calculateClaims';
import datify from '../util/datify';

type DataSourceProps = {
  section: SectionType;
  subsection: SubsectionType;
  showMine: boolean;
  client: GraphQlApolloClient;
  setDataSourceLoading: (val: boolean) => void;
  isClient: boolean;
  setDisplayCount: (count: number) => unknown;
  searchText: string;
};

const CLAIMS_REQUIRED_COLUMNS = [
  NUMBER_OF_MATCHES_CELL,
  NOT_MATCHED_CELL,
  CREATED_DATE_CELL,
  ONE_HUNDRED_PERCENT_MATCH_CODE_CELL,
  HAS_ONE_HUNDRED_PERCENT_MATCH_CELL,
  MATCH_CODE_COLUMN,
  SOURCE_TYPE_COLUMN,
];

const CONTRACTS_REQUIRED_COLUMNS = [
  ...CONTRACTS_DEFAULT_DISPLAY,
  // these columns are not displayed on the table but they're needed alongside Counterparty name/Debtor
  'hasMultipleCounterparties',
  'counterpartyNames',
  'hasMultipleDebtors',
];

const getChildrenNodes = (
  params: IServerSideGetRowsParamsWithFilterModel,
  state: ClaimsDataState,
) => {
  const claimId = params.parentNode?.data?.id;
  if (!claimId) return;
  const counterparty = state.counterparties.find((c) => c.claimId === claimId);
  const rowData = orderBy(counterparty?.matches ?? [], ['score'], ['desc']);
  if (!counterparty) return;
  params.success({
    rowData,
    rowCount: counterparty.matches.length,
  });
};

// @todo: ask BE to provide it in the user context.
// For now, we use the "hackish" way to get it from the dashboard tasks endpoint
const retrieveApplicationUserId = async (
  showMine: boolean,
  isClient: boolean,
): Promise<number | undefined> => {
  if (!showMine) return undefined;
  const response = isClient
    ? await userDashboardTasks.getClientAppUserId()
    : await userDashboardTasks.getApplicationUserId();
  if (!response?.applicationUserId) return undefined;

  return response.applicationUserId;
};

const requestColumnsVariableName = 'items';
const totalCountVariableName = 'totalCount';

export const createDatasource = ({
  section,
  subsection,
  showMine,
  client,
  setDataSourceLoading,
  isClient,
  setDisplayCount,
  searchText,
}: DataSourceProps): IServerSideDatasource => {
  const contractsSection = section === SectionType.Contracts;

  return {
    // called by the grid when more rows are required
    getRows: async (params: IServerSideGetRowsParamsWithFilterModel) => {
      const { claims, contracts } = store.getState();
      const state = contractsSection ? contracts?.data : claims?.data;
      const { gridColumns, counterparties } = state || {};
      setDisplayCount(-1);

      if (!gridColumns) return;
      setDataSourceLoading(true);

      // get data for request from server
      const requestColumns = getRequestColumns(
        params,
        gridColumns,
        contractsSection ? CONTRACTS_REQUIRED_COLUMNS : CLAIMS_REQUIRED_COLUMNS,
      );

      if (params.parentNode.level > -1) {
        getChildrenNodes(params, state);
        return;
      }

      // if showMine is active, we should use claimsForReviewer query
      // that supports applicationUserId filter
      // no applicationUserId for showMine contracts query
      const applicationUserId = contractsSection
        ? undefined
        : await retrieveApplicationUserId(showMine, isClient);

      const graphqlEntity = contractsSection
        ? showMine || isClient
          ? 'contractsAssigned'
          : 'contracts'
        : showMine && applicationUserId
        ? 'claimsForReviewer'
        : 'claims';

      const booleanColumns = gridColumns
        .filter((c) => c.dataType === 'bool')
        .map((c) => c.propertyName);

      const request = fixRefenrenceNumberSort(params.request);

      const { query, variables } = createGraphQLQueryFromParams(
        request,
        requestColumns,
        {
          queryName: contractsSection ? 'GetContracts' : 'GetClaims',
          graphqlEntity,
          showMine,
          applicationUserId,
          booleanColumns,
          gridColumns,
          searchText,
        },
        {
          unmatchedClaims: subsection === SubsectionType.UnmatchedClaims,
          isMatchingEnabled: section === SectionType.Matching,
        },
      );

      const response = await client.query({ query, variables });

      const data = response.data as any;

      if (data) {
        const claims = data[graphqlEntity][requestColumnsVariableName];
        const rowResponse = calculateClaims(claims, counterparties);
        const rowData = datify(rowResponse, gridColumns);

        const rowCount = data[graphqlEntity][totalCountVariableName];

        setDisplayCount(rowCount);

        // supply rows for requested block to grid
        params.success({
          rowData,
          rowCount,
        });
      } else {
        // inform grid request failed
        params.fail();
      }
      setDataSourceLoading(false);
    },
  };
};

// for 'referenceNumber' column we need to use 'referenceNumberSort' for proper sorting
const fixRefenrenceNumberSort = (
  request: IServerSideGetRowsRequestWithFilterModel,
): IServerSideGetRowsRequestWithFilterModel => {
  if (isEmpty(request.sortModel)) return request;

  const fixedSortModel = request.sortModel.map((model) => {
    if (model.colId === 'referenceNumber') {
      return {
        ...model,
        colId: 'referenceNumberSort',
      };
    }

    return model;
  });

  return {
    ...request,
    sortModel: fixedSortModel,
  };
};

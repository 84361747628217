import _ from 'lodash';
import drop from 'lodash/drop';

export type Paginated<T> = {
  entries: T[];
  currentPage: number;
  totalPages: number;
  totalEntries: number;
  entriesPerPage: number;
};

export function paginate<T>(
  entries: T[],
  currentPage: number,
  entriesPerPage: number,
): Paginated<T> {
  const offset = (currentPage - 1) * entriesPerPage;
  const paginated: T[] = drop(entries, offset).slice(0, entriesPerPage);
  return {
    currentPage,
    entries: paginated,
    entriesPerPage,
    totalPages: Math.ceil(entries.length / entriesPerPage),
    totalEntries: entries.length,
  };
}

//example of use:
//   createArray(5, 1)  =>  [1,1,1,1,1]
export function createArray<T>(count: number, fillValue: T): T[] {
  return _.fill(Array(count), fillValue);
}

//example of use:
//   createArrayRange(3, 8)  => [3,4,5,6,7,8]
export function createArrayRange(start: number, end: number): number[] {
  const count = end - start + 1;
  if (count <= 0) return [];

  return createArray(count, 0).map((_, id) => start + id);
}

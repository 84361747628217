import DOMPurify from 'dompurify';
import Excel from 'exceljs';
import { isNil } from 'lodash';
/**
 * Trigger a file download in the browser.
 */
export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  const body: HTMLElement = document.body as any;
  body.appendChild(anchor);
  anchor.href = DOMPurify.sanitize(url);
  // Using `File([blob], filename)` to set the filename
  // did not seem to work at first attempt. We use
  // the anchor method instead.
  anchor.download = filename;
  anchor.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    body.removeChild(anchor);
  });
};

export const downloadExcelFile = async (
  workbook: Excel.Workbook,
  filename: string,
) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  downloadBlob(blob, filename);
};

export const blobToFile = (blob: Blob, name: string): File =>
  new File([blob], name, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });

export const fileToArrayBuffer = async (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onabort = () => reject(new Error('File reading was aborted'));
    reader.onerror = () => reject(new Error('File reading has failed'));
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const content = event.target?.result as ArrayBuffer;
      if (!content) return reject(new Error('File is empty'));
      resolve(content);
    };
    reader.readAsArrayBuffer(file);
  });
};

const MAX_EXT_LENGTH = 7;
export const splitFileName = (fileName: string, options?: { skipDot: boolean }) => {
  const skipDot = options?.skipDot ?? false;
  const extPos = fileName.lastIndexOf('.');

  const extLenght = fileName.length - extPos;
  if (extPos < 0 || extLenght > MAX_EXT_LENGTH) {
    return [fileName, ''];
  }

  const file = fileName.slice(0, extPos);
  const ext = fileName.slice(skipDot ? extPos + 1 : extPos);

  return [file, ext];
};

/**
 * output example fileBaseName('/home/user/file.txt') ==> 'file.txt'
 */
export const urlExtractFileName = (fileFullPath: string | undefined) => {
  if (!fileFullPath || isNil(fileFullPath)) return fileFullPath;

  return fileFullPath.substring(fileFullPath.lastIndexOf('/') + 1);
};

/**
 * this function sanitizes the fileName so that it doesn't contain any invalid characters
 *
 * it will remove and replace with "_" every char that isn't a-zA-z, 0-9, - or .
 *
 * output example filenameSanitizer($^invalid_file$name .xlsx) => invalid_file_name.xlsx
 */
export const filenameSanitizer = (filename: string) => {
  // Remove characters other than a-zA-z 0-9, hyphen, dot
  let sanitized = filename.replace(/[^\w\-.]/g, '_');

  // Replace multiple consecutive invalid characters with a single underscore
  sanitized = sanitized.replace(/_+/g, '_');

  const [file, ext] = splitFileName(sanitized);

  // Trim leading and trailing whitespace and underscores
  const fileName = file.trim().replace(/^_+|_+$/g, '');

  return `${!fileName ? 'doc' : fileName}${ext}`;
};

import { AgGridEvent, ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export type GridInitialState = {
  columnState?: ColumnState[];
  filter?: any;
  page?: number;
};

interface Props {
  gridRef: AgGridReact | null;
  initialState: GridInitialState;
  onGridStateChanged?: (state: GridInitialState) => void;
}

export const useGridStateManager = ({
  gridRef,
  initialState = {},
  onGridStateChanged,
}: Props) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [lastPage, setLastPage] = useState<number>();

  const applyGridInitialState = useCallback(() => {
    gridRef?.api.setFilterModel(initialState.filter ?? null);
    gridRef?.columnApi.applyColumnState({
      state: initialState.columnState,
      applyOrder: true,
    });

    // the next line will not working if data is not loaded yet.
    // so we have to duplicate this code in the `handeFirstDataRendered` function
    gridRef?.api.paginationGoToPage(initialState.page ?? 0);
  }, [gridRef, initialState]);

  const handleColumnChange = useCallback(
    (event: AgGridEvent<any>) => {
      const columnState = event.columnApi.getColumnState();
      onGridStateChanged?.({ columnState });
    },
    [onGridStateChanged],
  );

  const handleColumnDebouncedChange = useMemo(
    () => _.debounce(handleColumnChange, 500),
    [handleColumnChange],
  );

  const handleFilterChange = useCallback(
    (event: AgGridEvent<any>) => {
      const filter = event.api.getFilterModel();
      onGridStateChanged?.({ filter });
    },
    [onGridStateChanged],
  );

  const handlePaginationChange = useCallback(
    (event: AgGridEvent<any>) => {
      if (initialLoad) return;

      const page = event.api.paginationGetCurrentPage();

      // for some reason PaginationChange event is triggered even if page value is not changed
      // filter out redundunt pagination calls
      if (page === lastPage) return;
      setLastPage(page);

      onGridStateChanged?.({ page });
    },
    [initialLoad, lastPage, onGridStateChanged],
  );

  const handleFirstDataRendered = useCallback(() => {
    // this is workaround to set paginationGoToPage() value
    // pagination value can't be set if the data is not loaded
    if (!initialLoad) return;
    gridRef?.api.paginationGoToPage(initialState.page ?? 0);
    setInitialLoad(false);
  }, [gridRef, initialLoad, initialState.page]);

  return {
    applyGridInitialState,

    handleColumnChange,
    handleColumnDebouncedChange,
    handleFilterChange,
    handlePaginationChange,

    handleFirstDataRendered,
  };
};

import {
  MenuItem,
  MenuSubItem,
} from 'components/DisclosureLinkMenu/DisclosureLinkMenu.types';

const _MENU_ITEMS: MenuItem[] = [
  {
    type: 'nested',
    baseLink: '/sofas-schedules',
    label: 'SOFAs',
    subItems: [
      {
        label: 'Preview',
        link: 'preview/sofa',
      },
      {
        label: 'View Data',
        link: 'tables/sofa/1',
      },
      {
        label: 'Slipsheets',
        link: 'notes/slipsheets/sofa',
      },
      {
        label: 'Global Notes',
        link: 'cover-documents/sofas',
      },
      {
        label: 'Signatories',
        link: 'tables/sofa/14',
      },
    ],
  },
  {
    type: 'nested',
    baseLink: '/sofas-schedules',
    label: 'Schedules',
    subItems: [
      {
        label: 'Preview',
        link: 'preview/schedules',
      },
      {
        label: 'View Data',
        link: 'tables/schedules/ab/1',
      },

      {
        label: 'Slipsheets',
        link: 'notes/slipsheets/schedules',
      },
      {
        label: 'Global Notes',
        link: 'cover-documents/schedules',
      },
      {
        label: 'Signatories',
        link: 'tables/schedules/signature/1',
      },
    ],
  },
];
export const MENU_ITEMS: MenuItem[] = _MENU_ITEMS.map((item) => {
  if (item.type === 'nested') {
    const subItems = item.subItems?.map((subitem) => {
      const newItem: MenuSubItem = {
        ...subitem,
        defaultLink: item.baseLink,
        link: `${item.baseLink}/${subitem.link}`,
      };
      return newItem;
    });

    return {
      ...item,
      subItems,
    };
  }

  return item;
});

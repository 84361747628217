import { userDashboardTasks } from 'api/endpoints/dashboard-tasks';
import { userDashboardTasksActions } from '../userDashboardTasksReducer';
import { AppDispatch, GetState } from '../store';
import { ApplicationUser } from 'utils/AppInitializer/application-user';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import {
  fetchingData,
  fetchingDataDone as contractsFetchingDone,
} from '../contractsDashboardDetailedDataReducer';

import { fetchingDataDone as claimsFetchingDone } from '../claimsDashboardDetailedDataReducer';
import { error } from 'utils/alert';

export const fetchUserDashboardTasksThunk =
  ({ user, showMine }: { user: ApplicationUser; showMine?: boolean }) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(userDashboardTasksActions.fetching());

    try {
      switch (user.roleType) {
        case 'AP': {
          userDashboardTasksActions.fetching();
          const [contractsResponse, claimsResponse] = await Promise.all([
            userDashboardTasks.getApContractsItems(showMine),
            userDashboardTasks.getApClaimsItems(showMine),
          ]);

          if (claimsResponse.type === ResponseType.OK) {
            dispatch(
              userDashboardTasksActions.apClaimsTasksDone(claimsResponse.data),
            );
            dispatch(claimsFetchingDone(claimsResponse.data));
          } else {
            dispatchGenericError(dispatch);
          }
          if (contractsResponse.type === ResponseType.OK) {
            dispatch(
              userDashboardTasksActions.apTasksDone({
                tasks: contractsResponse.data,
                user,
                showMine,
              }),
            );
          } else {
            dispatchGenericError(dispatch);
          }
          break;
        }
        case 'Client': {
          userDashboardTasksActions.fetching();
          const [contractsResponse, claimsResponse] = await Promise.all([
            userDashboardTasks.getClientsContractItems(),
            userDashboardTasks.getClientTasks(),
          ]);
          if (
            claimsResponse.type === ResponseType.OK &&
            contractsResponse.type === ResponseType.OK
          ) {
            dispatch(
              userDashboardTasksActions.clientTasksDone({
                tasks: { ...claimsResponse.data, ...contractsResponse.data },
                user,
              }),
            );
          } else {
            dispatchGenericError(dispatch);
          }

          break;
        }
        default: {
          dispatch(
            userDashboardTasksActions.error({
              message: `Unknown role type "${user.roleType}"`,
            }),
          );
          break;
        }
      }
    } catch (e) {
      console.error(e);
      dispatchGenericError(dispatch);
    }
  };

export const fetchContractsDetailedDataThunk =
  (showMine?: boolean) => async (dispatch: AppDispatch) => {
    dispatch(fetchingData({ fetching: true, shouldFetchData: true }));
    const response = await userDashboardTasks.getDetailedContractData(showMine);
    if (response.type === ResponseType.OK)
      dispatch(contractsFetchingDone(response.data));
    else {
      error('Could not fetch contracts data');
      dispatch(fetchingData({ fetching: false, shouldFetchData: true }));
    }
  };

function dispatchGenericError(dispatch: AppDispatch) {
  dispatch(
    userDashboardTasksActions.error({
      message: 'Could not fetch dashboard tasks.',
    }),
  );
}

import { ManualMatchData, match } from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { ClaimsData } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useMemo, useState } from 'react';
import { fetchUserDashboardTasksThunk } from 'reducer/thunks/dashboard-thunks';
import { success } from 'utils/alert';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { postBatchEdit } from '../api/apiPostBatchEdit';
import { getEditableColumnItems } from '../utils/columnItems';
import { useBatchEditFields } from './useBatchEditFields';
import { useCategories } from './useCategories';

export const useClaimsToolBatchEdit = (claimsData: ClaimsData) => {
  const { selectedRows, onResetFilters } = claimsData;

  const category = useCategories();
  const dispatch = useAppDispatch();
  const user = useApplicationUser();
  const { actionStatuses } = useAppSelector(
    (state) => state.claimActionNameSelection,
  );
  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);

  const fields = useBatchEditFields(onResetFilters);
  const [loading, setLoading] = useState(false);

  const optionItems = useMemo(
    () =>
      getEditableColumnItems({
        data: category,
        items: fields.items,
        actionStatuses,
      }),
    [category, fields.items, actionStatuses],
  );

  const applyChanges = useCallback(async () => {
    setLoading(true);

    const selectedRowsIds = selectedRows.map((c) => `${c.id}`);
    const matchCode = fields.items.find((f) => f.propName === 'matchCode')?.value;
    let result;
    const shouldOnlyUpdateMatchCode = matchCode && fields.items.length === 1;
    if (matchCode) {
      const claimsToUpdate: ManualMatchData = selectedRowsIds.map((id) => ({
        claimId: +id,
        matchCode: +matchCode,
      }));
      result = await match.manualMatch(claimsToUpdate);
    }
    if (!shouldOnlyUpdateMatchCode) {
      result = await postBatchEdit(selectedRowsIds, fields.items);
    }
    if (!!result) {
      fields.clearAll();
      onResetFilters?.();
      success('Items changed successfully');
    }

    // we need to update the state of dashboard if we edit claims
    if (user && user.data)
      dispatch(fetchUserDashboardTasksThunk({ user: user.data, showMine }));
    setLoading(false);

    return result;
  }, [fields, selectedRows, user, dispatch, onResetFilters, showMine]);

  const applyEnabled = useMemo(() => {
    const isEditFieldsEmpty = fields.items.every((r) => !r.propName);
    const isRecordsSelected = !!selectedRows?.length;
    return !loading && isRecordsSelected && !isEditFieldsEmpty;
  }, [selectedRows, loading, fields.items]);

  return {
    fields,
    loading,
    optionItems,
    applyEnabled,
    applyChanges,
  };
};

import { ApButtonMain, ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { ClaimAttachment, claimAttachments } from 'api/endpoints/claimAttachments';
import {
  ContractAttachment,
  contractAttachments,
} from 'api/endpoints/contractAttachments';
import { RowAdditionalInfo } from 'components/DocumentsTable';
import { ApButtonCasual } from 'components/UIComponents/Buttons';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useEffect, useRef, useState } from 'react';

export const DocComment = ({
  loadList,
  document,
  section,
}: {
  loadList: () => Promise<void>;
  document: ContractAttachment | ClaimAttachment;
  section: SectionType;
}) => {
  const [comment, setComment] = useState('');
  const [commentOpened, setCommentOpened] = useState(false);
  const [saving, setSaving] = useState(false);
  const commnentRef = useRef<HTMLTextAreaElement>(null);

  const handleSaveComment = async () => {
    setSaving(true);
    setCommentOpened(false);
    setComment('');
    if (section === SectionType.Contracts) {
      await contractAttachments.saveComment(document.id, comment);
    } else if (section === SectionType.Claims) {
      await claimAttachments.saveComment(document.id, comment);
    }
    await loadList();
    setSaving(false);
  };

  const handleEditClick = () => {
    setCommentOpened((prev) => !prev);
    setComment('');
  };

  useEffect(() => {
    if (commentOpened) commnentRef?.current?.focus();
  }, [commentOpened]);

  return (
    <RowAdditionalInfo>
      <div className="doc-comment">
        {saving ? (
          <ApLoaderDefault loaderSize="lg" />
        ) : document.comment ? (
          <>
            {commentOpened ? (
              <>
                <label>Edit comment below:</label>
                <textarea
                  defaultValue={document.comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={!commentOpened}
                  ref={commnentRef}
                />
              </>
            ) : (
              <p>{document.comment}</p>
            )}
            <div className="doc-comment__buttons flex-align-center">
              <ApButtonCasual onClick={handleEditClick}>
                {!commentOpened && <ApIcon iconName="edit" />}
                {commentOpened ? 'Cancel' : 'Edit comment'}
              </ApButtonCasual>
              {comment && commentOpened && (
                <ApButtonMain onClick={handleSaveComment}>Save comment</ApButtonMain>
              )}
            </div>
          </>
        ) : (
          <>
            {commentOpened && (
              <>
                <label>Add a comment below:</label>
                <textarea
                  defaultValue={document.comment}
                  onChange={(e) => setComment(e.target.value)}
                  ref={commnentRef}
                />
              </>
            )}
            <div className="doc-comment__buttons flex-align-center">
              <ApButtonCasual onClick={() => setCommentOpened((prev) => !prev)}>
                {!commentOpened ? 'Add a comment' : 'Cancel'}
              </ApButtonCasual>
              {comment && commentOpened && (
                <ApButtonMain onClick={handleSaveComment}>Save comment</ApButtonMain>
              )}
            </div>
          </>
        )}
      </div>
    </RowAdditionalInfo>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'hooks/reducerHooks';
import { getSavedGridState } from 'utils/local-storage';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { bookmarksApi } from 'api/endpoints/bookmarks';
import { error, success } from 'utils/alert';
import { isEmpty } from 'lodash';
import { getBookmarksThunk } from 'reducer/thunks/bookmarksThunk';
import { BookmarkCardData } from '../types';
import { useAppUserType } from 'utils/hooks/useAppUserType';

const FILTER_PROP_MAP: { [val: string]: string } = {
  [SectionType.Claims]: 'section',
  [SectionType.Contracts]: 'section',
  myBookmarks: 'createdByEmail',
};

export const useBookmarksData = (activeBookmarkSection: SectionType) => {
  const [openModal, setOpenModal] = useState(false);
  const [bookmarkTitle, setBookmarkTitle] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const { userName } = useAppUserType();

  const dispatch = useAppDispatch();

  const getFilteredBookmarks = (bookmarks: BookmarkCardData[]) => {
    const filterProp = FILTER_PROP_MAP[selectedOption];
    const filterValue = filterProp === 'createdByEmail' ? userName : selectedOption;
    return filterProp
      ? bookmarks.filter((b: any) => b[filterProp] === filterValue)
      : [...bookmarks];
  };

  const getBookmarksCountByOption = (bookmarks: BookmarkCardData[]) => {
    return {
      claimsBookmarksCount: bookmarks.filter((b) => b.section === SectionType.Claims)
        ?.length,
      contractsBookmarksCount: bookmarks.filter(
        (b) => b.section === SectionType.Contracts,
      )?.length,
      myBookmarksCount: bookmarks.filter((b) => b.createdByEmail === userName)
        ?.length,
    };
  };

  const enableAddBookmark = useMemo(
    () =>
      activeBookmarkSection === SectionType.Claims ||
      activeBookmarkSection === SectionType.Contracts,
    [activeBookmarkSection],
  );

  const handleBookmarkSave = useCallback(async () => {
    const currentSavedState = getSavedGridState();
    const sectionState = currentSavedState?.[activeBookmarkSection] ?? {};
    const response = await bookmarksApi.saveBookmark({
      section: activeBookmarkSection,
      filter: sectionState,
      title: bookmarkTitle,
    });

    if (isEmpty(response.error)) {
      success('Bookmark succesfully saved');
      dispatch(getBookmarksThunk());
    } else error("Bookmark couldn't be saved. Please try again.");
  }, [bookmarkTitle, activeBookmarkSection, dispatch]);

  const handleBookmarkEdit = useCallback(
    async (bookmarkId: number, newTitle: string) => {
      const response = await bookmarksApi.editBookmark({
        id: bookmarkId,
        title: newTitle,
      });
      if (isEmpty(response.error)) {
        success('Bookmark succesfully edited');
        dispatch(getBookmarksThunk());
      } else error("Bookmark couldn't be saved. Please try again.");
    },
    [dispatch],
  );

  const handleBookmarkDelete = useCallback(
    async (bookmarkId: number) => {
      const response = await bookmarksApi.deleteBookmark(bookmarkId);
      if (isEmpty(response.error)) {
        success('Bookmark succesfully deleted');
        dispatch(getBookmarksThunk());
      } else error("Bookmark couldn't be deleted. Please try again.");
    },
    [dispatch],
  );

  return {
    openModal,
    setOpenModal,
    handleBookmarkSave,
    handleBookmarkEdit,
    handleBookmarkDelete,
    setBookmarkTitle,
    bookmarkTitle,
    enableAddBookmark,
    setSelectedOption,
    getFilteredBookmarks,
    getBookmarksCountByOption,
  };
};

import { GridColumn } from 'api/endpoints';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { dateFromString } from 'utils/formatDate';

interface GridRecord {
  [key: string]: any;
}

/**
 * Convert dates in JSON response to js dates (deprecated)
 * @param claims
 * @param gridColumns
 * @returns
 */
function datify(claims: GridRecord[], gridColumns: GridColumn[]) {
  return map(claims, (c) => {
    forEach(gridColumns, (column) => {
      if (!['datetime', 'date'].includes(column.dataType)) return;
      const value = c[column.propertyName];
      if (!value) return;
      const dateOrDateTime = dateFromString(value);
      c[column.propertyName] = dateOrDateTime;
    });
    return c;
  });
}
export default datify;

/**
 * Convert dates in JSON response to js dates
 * @param grid
 * @param columns
 * @returns
 */
export const convertDateValues = (grid: GridRecord, columns: GridColumn[]) => {
  const dateColumns = columns.filter((r) =>
    ['datetime', 'date'].includes(r.dataType),
  );
  if (!dateColumns.length) return grid;

  const newGrid = grid.map((row: any) => {
    const newRow = { ...row };

    dateColumns.forEach(({ propertyName }) => {
      const value = newRow[propertyName];
      if (value) {
        newRow[propertyName] = new Date(value);
      }
    });
    return newRow;
  });

  return newGrid;
};

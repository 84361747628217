import { PageHeaderTitle } from 'components/PageHeaderTitle';
import { useAppSelector } from 'hooks/reducerHooks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ChangeEvent, useMemo } from 'react';
import './BookmarksPage.css';
import { useBookmarksData } from './hooks/useBookmarksData';
import { BookmarksFilter } from './sections/BookmarksFilter/BookmarksFilter';
import { BookmarksList } from './sections/BookmarksList/BookmarksList';
import { Layout } from 'components/Layout';

export const BookmarksPage = () => {
  const { bookmarks = [] } = useAppSelector((s) => s.selectable.data);
  const { setSelectedOption, getFilteredBookmarks, getBookmarksCountByOption } =
    useBookmarksData(SectionType.NONE);

  const filteredBookmarks = useMemo(
    () => getFilteredBookmarks(bookmarks),
    [bookmarks, getFilteredBookmarks],
  );

  const { claimsBookmarksCount, contractsBookmarksCount, myBookmarksCount } =
    useMemo(
      () => getBookmarksCountByOption(bookmarks),
      [bookmarks, getBookmarksCountByOption],
    );

  return (
    <Layout>
      <div className="bookmarks-page">
        <PageHeaderTitle title="Bookmarks" />
        <div className="bookmarks-page__container">
          <BookmarksFilter
            bookmarksData={{
              claimsBookmarksCount,
              contractsBookmarksCount,
              myBookmarksCount,
              allBookmarksCount: bookmarks.length,
              bookmarksCount: filteredBookmarks.length,
            }}
            handleSelectChange={(event: ChangeEvent<HTMLSelectElement>) =>
              setSelectedOption(event.target.value)
            }
          />
          <BookmarksList bookmarks={filteredBookmarks} />
        </div>
      </div>
    </Layout>
  );
};

import { useState } from 'react';
import { InputComponent } from 'components/UIComponents/EntityTable';
import {
  DropdownAddCategory,
  SetterNameValues,
} from '../../../components/DropdownAddCategory';
import { IOption } from 'components/UIComponents/MultiSelect';
import classNames from 'classnames';
import { editContractCategoryThunk } from 'reducer/thunks/contractsCategoriesThunks';
import { useAppDispatch } from 'hooks/reducerHooks';
import { AllContractsCategories } from 'api/endpoints';
import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { ApLoaderDefault } from '@alixpartners/ui-components';

export const ContractCategoriesInput: InputComponent<AllContractsCategories> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { setValue, formSubmitted, options, disabled } = fromTable;
  const [toggleOn, setToggleOn] = useState(!!item?.enabled);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleToggleClick = async () => {
    setLoading(true);
    await dispatch(
      editContractCategoryThunk({
        enabled: !toggleOn,
        contractTypeId: item?.contractTypeId!,
        contractTypeName: null,
      }),
    );
    setToggleOn((prev) => !prev);
    setLoading(false);
  };

  if (columnKey === 'contractCategoryId') {
    return (
      <DropdownAddCategory
        options={options!}
        addButtonText="new category"
        handleClick={(name: SetterNameValues, value: IOption) =>
          setValue(name, value)
        }
        formSubmitted={formSubmitted}
        valueSetters={{
          optionClickSetter: 'contractCategoryId',
          inputSetter: 'categoryName',
        }}
      />
    );
  }
  if (columnKey === 'contractTypeName') {
    return (
      <input
        className="minor-category-input"
        type="text"
        placeholder="Enter name"
        onChange={(e) => setValue('contractTypeName', e.target.value)}
        disabled={disabled}
        defaultValue={item?.contractTypeName ?? ''}
      />
    );
  }

  if (columnKey === 'categoryName' && item?.categoryName) {
    return <p>{item?.categoryName}</p>;
  }

  if (columnKey === 'categoryType' && item?.categoryType) {
    return <p>{item?.categoryType}</p>;
  }
  if (columnKey === 'enabled') {
    if (loading) return <ApLoaderDefault loaderSize="sm" />;
    return (
      <span
        className={classNames('edit-table__cell-switch', {
          'utils-obstruct-panel': disabled || item?.usedInContracts,
        })}
      >
        <ApThreeWaySwitch
          value={!!item?.enabled ? ApThreeWayValue.Yes : ApThreeWayValue.No}
          onChange={handleToggleClick}
        />
      </span>
    );
  }

  return null;
};

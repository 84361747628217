import { downloadFileGet } from 'api/apiRequest';
import {
  jsonFetchDelete,
  jsonFetchGet,
  jsonFetchPost,
  jsonFetchPut,
} from 'api/jsonFetch/authJsonFetch';
import {
  OkResponse,
  ErrorResponse,
  ResponseType,
} from 'api/jsonFetch/jsonFetch.types';
import { downloadBlob } from 'utils/file';
import { ReportLegalEntity, ReportPart } from './legalEntities';

type SlipsheetBase = {
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  fileName: string;
  displayName: string;
};

type ServerPartForSlipSheet = {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  id: number;
  slipSheetFileInfoId: number;
  fileType: string;
  partName: string;
  description: string;
};

type ServerLegalEntityForSlipSheet = {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  id: number;
  slipSheetFileInfoId: number;
  legalEntityId: number;
  legalEntityName: string;
};

type ServerSlipSheet = SlipsheetBase & {
  parts: ServerPartForSlipSheet[];
  legalEntities: ServerLegalEntityForSlipSheet[];
};

export type PartForSlipSheet = ReportPart & {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  slipSheetFileInfoId: number;
};

export type LegalEntityForSlipSheet = ReportLegalEntity & {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  slipSheetFileInfoId: number;
};

export type SlipSheet = SlipsheetBase & {
  parts: PartForSlipSheet[];
  legalEntities: LegalEntityForSlipSheet[];
};

export type SlipSheetPutOrPostBase = {
  displayName: string;
  parts: {
    fileType: string; // same as parentType
    partName: string;
  }[];
  legalEntities: {
    legalEntityId: number;
  }[];
};

export type SlipSheetPostParams = SlipSheetPutOrPostBase & {
  fileName: string;
};
export type SlipSheetPutParams = SlipSheetPutOrPostBase & {
  fileName: string; // seems BE needs this and possibly supports it
  id: number;
};

export const slipsheets = {
  list: async (): Promise<OkResponse<SlipSheet[]> | ErrorResponse<any>> => {
    const res = await jsonFetchGet<ServerSlipSheet[]>(`v1/sofaschedule/SlipSheet`);
    if (res.type === ResponseType.OK) {
      const newRes: OkResponse<SlipSheet[]> = {
        type: ResponseType.OK,
        data: res.data.map(serverToApp),
      };

      return newRes;
    }

    // OK case is threaded above, same cases are identical, hence the type cast
    return res as OkResponse<SlipSheet[]> | ErrorResponse<any>;
  },
  post: async (params: SlipSheetPostParams) => {
    const res = await jsonFetchPost<ServerSlipSheet>(
      `v1/sofaschedule/SlipSheet`,
      params,
    );
    if (res.type === ResponseType.OK) {
      const newRes: OkResponse<SlipSheet> = {
        type: ResponseType.OK,
        data: serverToApp(res.data),
      };

      return newRes;
    }

    // OK case is threaded above, same cases are identical, hence the type cast
    return res as OkResponse<SlipSheet> | ErrorResponse<any>;
  },
  put: async (params: SlipSheetPutParams) => {
    const res = await jsonFetchPut<ServerSlipSheet>(
      `v1/sofaschedule/SlipSheet`,
      params,
    );
    if (res.type === ResponseType.OK) {
      const newRes: OkResponse<SlipSheet> = {
        type: ResponseType.OK,
        data: serverToApp(res.data),
      };

      return newRes;
    }

    // OK case is threaded above, same cases are identical, hence the type cast
    return res as OkResponse<SlipSheet> | ErrorResponse<any>;
  },
  delete: async (params: { id: number }) => {
    const skipBodyParse = true;
    return jsonFetchDelete<void>(
      `v1/sofaschedule/SlipSheet/${params.id}`,
      undefined,
      undefined,
      skipBodyParse,
    );
  },
  download: async (params: { id: number; displayName: string }) => {
    const response = await downloadFileGet(
      `v1/sofaschedule/SlipSheet/${params.id}/download`,
    );
    if (response.type === 'ok') {
      await downloadBlob(response.data as unknown as Blob, params.displayName);
    }

    return response;
  },
};

function serverToApp(serverSheet: ServerSlipSheet): SlipSheet {
  const { parts: serverParts, legalEntities: serverEntities, ...rest } = serverSheet;

  return {
    ...rest,
    parts: serverParts.map((serverP) => {
      const { fileType, description, ...rest } = serverP;
      const p: PartForSlipSheet = {
        ...rest,
        parentType: fileType as 'Sofa' | 'Schedule',
        partDescription: description,
      };
      return p;
    }),
    legalEntities: serverEntities.map((serverE) => {
      const { legalEntityName, ...rest } = serverE;
      const en: LegalEntityForSlipSheet = {
        ...rest,
        entityName: legalEntityName,
      };
      return en;
    }),
  };
}

import { CategorySelectType } from '../components/CategorySelect/config';
import {
  PageSections,
  SingleContractInputEntity,
  SingleContractSection,
} from '../types';

export function isEditableApUser(field: string) {
  return !AP_USER_READONLY_FIELDS.includes(field);
}
export function isEditableClient(field: string) {
  return !CLIENT_READONLY_FIELDS.includes(field);
}

// put fields that should be disabled
// !!!values bellow are just as example. They are not used actually
const AP_USER_READONLY_FIELDS = [
  'original',
  'current',
  'proposed',
  'sourceFileName',
];
const CLIENT_READONLY_FIELDS = [
  'original',
  'current',
  'proposed',
  'aPApprovalStatusId',
  'aPApprovalDate',
  'sourceFileName',
];

export const EMPTY_FIELD: SingleContractInputEntity = {
  label: '',
  field: '',
  type: 'empty',
};

export const AP_USER_DEFAULT_SECTIONS: SingleContractSection[] = [
  {
    id: PageSections.Categorization,
    title: 'Categorization',
    inputs: [
      {
        label: 'Category',
        field: 'contractCategoryId',
        category: {
          type: CategorySelectType.Category,
          name: 'contractCategoryId',
        },
      },
      {
        label: 'Contract type',
        field: 'contractTypeId',

        category: {
          type: CategorySelectType.ContractType,
          name: 'contractTypeId',
        },
      },
    ],
  },
  {
    id: PageSections.ContractStatus,
    title: 'Status',
    inputs: [
      {
        label: 'Workflow status',
        field: 'contractStatusId',
        category: {
          type: CategorySelectType.ContractStatus,
          name: 'contractStatusId',
        },
      },
      {
        label: 'Payment position',
        field: 'paymentPositionId',
        category: {
          type: CategorySelectType.PaymentPosition,
          name: 'paymentPositionId',
        },
      },
      {
        label: 'Resolution type',
        field: 'contractActionTypeId',
        category: {
          type: CategorySelectType.ContractActionType,
          name: 'contractActionTypeId',
        },
      },
      {
        label: 'Resolution subtype',
        field: 'contractActionSubStatusId',
        category: {
          type: CategorySelectType.ContractActionSubstatus,
          name: 'contractActionSubStatusId',
        },
      },
    ],
  },
  {
    id: PageSections.Exhibit,
    title: 'Exhibit',
    inputs: [
      {
        label: 'Exhibit type',
        field: 'contractExhibitTypeId',
        category: {
          type: CategorySelectType.ExhibitTypeId,
          name: 'contractExhibitTypeId',
        },
      },
      {
        label: 'Resolution Name',
        field: 'contractActionNameId',
        category: {
          type: CategorySelectType.ContractActionName,
          name: 'contractActionNameId',
        },
      },
      {
        label: 'Included in Schedule G',
        field: 'includedInScheduledG',
        toggle: true,
      },
      {
        label: 'Contact of Any Government Contract',
        field: 'contactOfAnyGovernmentContract',
      },
    ],
  },
  {
    id: PageSections.Assignment,
    title: 'Assignment',
    inputs: [
      {
        label: 'Contract reviewer',
        field: 'contractReviewer',
        category: {
          type: CategorySelectType.ContractReviewer,
          name: 'contractReviewer',
        },
      },
    ],
  },
  {
    id: PageSections.AdditionalInformation,
    title: 'Additional information',
    inputs: [
      {
        label: 'Contract Title',
        field: 'description',
        textField: true,
      },
      {
        label: 'Vendor number',
        field: 'vendorNumber',
      },
      {
        label: 'Company code',
        field: 'companyCode',
      },
      {
        label: 'Counterparty',
        field: 'counterpartyName',
      },
      {
        label: 'Debtor',
        field: 'debtorId',
        category: {
          type: CategorySelectType.LegalEntities,
          name: 'debtorId',
        },
      },
      {
        label: 'Co-Debtor(s)',
        field: 'coDebtors',
        hasTitle: true,
      },
      {
        label: 'Notes',
        field: 'notes',
        textField: true,
      },
      {
        label: 'Source',
        field: 'source',
      },
      {
        label: 'Lessor',
        field: 'lessor',
      },
      {
        label: 'Source File Name',
        field: 'sourceFileName',
        hasTitle: true,
      },
      EMPTY_FIELD,
      EMPTY_FIELD,
      {
        label: 'Leased Property City',
        field: 'leasedPropertyCity',
        type: 'address',
      },

      {
        label: 'Real Property Lease',
        field: 'realPropertyLease',
      },
      {
        label: 'Leased Property State',
        field: 'leasedPropertyState',
        type: 'address',
      },

      {
        label: 'Leased Property Address1',
        field: 'leasedPropertyAddress1',
        type: 'address',
      },

      {
        label: 'Leased Property Zip',
        field: 'leasedPropertyZip',
        type: 'address',
      },

      {
        label: 'Leased Property Address2',
        field: 'leasedPropertyAddress2',
        type: 'address',
      },
      {
        label: 'Leased Property Country',
        field: 'leasedPropertyCountry',
        type: 'address',
      },
      {
        label: 'Leased Property Address3',
        field: 'leasedPropertyAddress3',
        type: 'address',
      },

      EMPTY_FIELD,
      EMPTY_FIELD,
    ],
  },
  {
    id: PageSections.Assessment,
    title: 'Assessment',
    inputs: [
      {
        label: 'Parent contract number',
        field: 'parentContractNumber',
      },
      {
        label: 'Priority',
        field: 'priority',
        category: {
          type: CategorySelectType.Priority,
          name: 'priority',
        },
      },
      {
        label: 'Contract date',
        field: 'contractDate',
        dateField: true,
      },
      {
        label: 'Rejection damages low',
        field: 'rejectionDamageLow',
        amountField: true,
      },
      {
        label: 'Rejection damages high',
        field: 'rejectionDamagesHigh',
        amountField: true,
      },
      {
        label: 'Rejection date',
        field: 'rejectionDate',
        dateField: true,
      },
      {
        label: 'Assumption cure amount',
        field: 'assumptionCureAmount',
        amountField: true,
      },
      {
        label: 'Negotiated cure amount',
        field: 'negotiatedCureAmount',
        amountField: true,
      },
      {
        label: 'Lifetime Contract Value',
        field: 'contractValue',
        amountField: true,
      },
      {
        label: 'Expiration date',
        field: 'expirationDate',
        dateField: true,
      },
      {
        label: 'Client Approval Status',
        field: 'clientApprovalStatusId',
        category: {
          type: CategorySelectType.ClientApprovalStatus,
          name: 'clientApprovalStatusId',
        },
      },
      {
        label: 'Client Approval Date',
        field: 'clientApprovalDate',
        dateField: true,
      },
      {
        label: 'AlixPartners Approval Status',
        field: 'aPApprovalStatusId',
        category: {
          type: CategorySelectType.ApUserApprovalStatus,
          name: 'aPApprovalStatusId',
        },
      },
      {
        label: 'AlixPartners Approval date',
        field: 'aPApprovalDate',
        dateField: true,
      },
      {
        label: 'Non-debtor guarantee',
        field: 'nonDebtorGuarantee',
        category: {
          type: CategorySelectType.NonDebtorGuarantee,
          name: 'nonDebtorGuarantee',
        },
      },
      {
        label: 'Auto-renewal',
        field: 'autoRenewal',
        category: {
          type: CategorySelectType.AutoRenewal,
          name: 'autoRenewal',
        },
      },
      {
        label: 'FY Spend',
        field: 'fYSpend',
        amountField: true,
      },
      {
        label: 'LTM Spend',
        field: 'lastTwelveMonthsSpend',
        amountField: true,
      },
      {
        label: 'Total Match pre-petition value',
        field: 'totalMatchPrePetitionValue',
        amountField: true,
      },
      {
        label: 'Total Match pre-petition contract value',
        field: 'totalMatchPrePetitionContractValue',
        amountField: true,
      },
    ],
  },
  {
    id: PageSections.ContractSavings,
    title: 'Contract Savings',
    inputs: [
      {
        label: 'Cure cost reduction',
        field: 'cureCostReductionAmount',
        amountField: true,
      },
      {
        label: '12 month savings',
        field: 'twelveMonthSavings',
        amountField: true,
      },
      {
        label: 'One-time savings',
        field: 'oneTimeSavings',
        amountField: true,
      },
      {
        label: 'Contract savings notes',
        field: 'contractSavingsNotes',
        textField: true,
      },
    ],
  },
  {
    id: PageSections.CounterpartyInfo,
    title: 'Counterparty Contact Information',
    orientation: 'vertical',
    //type: 'address' - is used here to prevent to show browser "Save Address" dialog window
    inputs: [
      {
        label: 'Address Line 1',
        field: 'address1',
        type: 'address',
      },
      {
        label: 'Address Line 2',
        field: 'address2',
        type: 'address',
      },
      {
        label: 'Address Line 3',
        field: 'address3',
        type: 'address',
      },
      {
        label: 'Address Line 4',
        field: 'address4',
        type: 'address',
      },
      {
        label: 'City',
        field: 'city',
        type: 'address',
      },

      {
        label: 'State',
        field: 'state',
        type: 'address',
      },
      {
        label: 'Zip code',
        field: 'zip',
        type: 'address',
      },
      {
        label: 'Country',
        field: 'country',
        type: 'address',
      },
    ],
  },
];

import { URL_PATH } from 'appUrls';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  listSchedules,
  SchedulesType,
  listPartsSchedules,
} from './SofasPartsTables/Columns/types';
import { TableBody } from './TableBody';
import { ISofaTableProps } from './tables.types';

const initialSchedule = listSchedules[0];

export function Tables() {
  const navigate = useNavigate();
  const { type, part, section } = useParams();

  // Check the parameters and convert to the correct string literal
  // also check if the schedule is valid remove case sensitivity
  const paramSchedule =
    (section?.toLowerCase() === 'signature'
      ? 'Signature'
      : section?.toUpperCase()) || '';
  const scheduleId = Object.keys(listPartsSchedules).includes(paramSchedule)
    ? (paramSchedule as SchedulesType)
    : initialSchedule;

  // Check if the type is sofa or schedule and navigate correctly
  const navigateToPart = (newPart: string) => {
    if (type === 'sofa')
      navigate(`${URL_PATH.SOFAS_SCHEDULES}/tables/${type}/${newPart}`);
    else
      navigate(
        `${URL_PATH.SOFAS_SCHEDULES}/tables/${type}/${scheduleId}/${newPart}`,
      );
  };

  // Navigate to the correct schedule
  const navigateToSchedule = (newSchedule: string) => {
    navigate(`${URL_PATH.SOFAS_SCHEDULES}/tables/${type}/${newSchedule}/1`);
  };

  // Store props
  const sofaTable: ISofaTableProps = useMemo(
    () => ({
      type: type === 'sofa' ? 'sofa' : 'schedule',
      part,
      schedule: type === 'sofa' ? undefined : scheduleId,
    }),
    [part, scheduleId, type],
  );

  return (
    <div className="sofas-schedules__content">
      <TableBody
        sofaTable={sofaTable}
        onChangeTab={navigateToPart}
        onChangeSchedule={navigateToSchedule}
      />
    </div>
  );
}

import { orderBy } from 'lodash';
import { deleteFileInfo } from 'pages/DataUploaderPage/api/apiDataDownload';
import {
  azureStorageUpload,
  getUploadedFiles,
} from 'pages/DataUploaderPage/api/apiDataUploader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileStatus, FileType, IUploadItem } from 'types/dataUploaderTypes';
import { success } from 'utils/alert';

export const useMatchingUpload = () => {
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [prevUploaded, setPrevUploaded] = useState<IUploadItem[]>([]);
  const onProgress = (item: IUploadItem, loaded: number, size: number) => {
    item.uploadProgress = loaded / size;
  };

  const initFileInfo = useCallback(async () => {
    setFetchingFiles(true);
    const uploadedFiles = await getUploadedFiles();
    setPrevUploaded(
      uploadedFiles.filter((file) => file.type === FileType.BaselineMatches),
    );
    setFetchingFiles(false);
  }, []);

  const onUpload = useCallback(
    async (items: IUploadItem[]) => {
      setFetchingFiles(true);
      const itemsToUpload = items.map((r) => ({
        ...r,
        status: FileStatus.Uploading,
        type: FileType.BaselineMatches,
      }));
      await azureStorageUpload(itemsToUpload, onProgress);
      await initFileInfo();
      setFetchingFiles(false);
    },
    [initFileInfo],
  );

  const onDeleteFile = useCallback(
    async (item: IUploadItem) => {
      setPrevUploaded((ui) => ui.filter((uitem) => uitem !== item));
      const result = await deleteFileInfo(item);
      await initFileInfo();

      if (result) success(`${item.displayName} deleted`);
    },
    [initFileInfo],
  );

  const orderedFiles = useMemo(
    () =>
      orderBy(prevUploaded, (p) => [p.uploadedFile?.createdDate, p.status], 'desc'),
    [prevUploaded],
  );

  useEffect(() => {
    initFileInfo();
  }, [initFileInfo]);

  return {
    onUpload,
    prevUploaded: orderedFiles,
    onDeleteFile,
    fetchingFiles,
  };
};

import { ApLoaderDefault } from '@alixpartners/ui-components';
import { AddFields, EntityTable, Panel } from 'components/UIComponents/EntityTable';
import {
  AwarePanel,
  EditFieldsList,
} from 'components/UIComponents/EntityTable/EntityTable.components';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { FetchStatus } from 'types/fetch-status.types';
import { SettlementsUserManagementInput } from './SettlementsUserManagementInput';
import { useSettlementsUserManagement } from './useSettlementsUserManagement';

import { BatchUploadPanel } from 'components/BatchUploadPanel';
import { useSettlementsUserBatchUpload } from './useSettlementsUserBatchUpload';

import { BatchFilesErrorDialog } from 'components/BatchUploadPanel/BatchUploadDialogs';

import { ConfirmModal } from 'components/ModalWindows/ConfirmModal/ConfirmModal';
import { SettlementUserInfo } from './api/apiSettlementUser';
import './SettlementsUserManagement.css';

const HEADERS_INPUT = ['User', 'Email', 'Role'];
const HEADERS_EDIT = [...HEADERS_INPUT, 'Reset password'];
const COLUMN_KEYS: (keyof SettlementUserInfo)[] = ['name', 'email', 'userRole'];
const EDIT_COLUMN_KEYS: (keyof SettlementUserInfo)[] = [
  ...COLUMN_KEYS,
  'resetPassword',
];

interface Props {
  children?: React.ReactNode;
}

export const SettlementsUserManagement = (props: Props) => {
  const { data, loading, confirmDialog, add, edit, passwordReset, refresh } =
    useSettlementsUserManagement();

  const batchUpload = useSettlementsUserBatchUpload({ refreshList: refresh });

  return (
    <SettingsContentPanel>
      <section className="settlements-user-management">
        <EntityTable
          items={data}
          status={FetchStatus.Done}
          addColumnKeys={COLUMN_KEYS}
          editColumnKeys={EDIT_COLUMN_KEYS}
          inputComponent={SettlementsUserManagementInput({
            onResetPassword: passwordReset,
          })}
          onAddSave={add}
          onEditSave={edit}
        >
          <Panel
            title="User management"
            className="settlements-user-management__input-panel"
          >
            <BatchUploadPanel
              title="Do you want to bulk add users using our template?"
              className="settlements-user-management__batch-upload"
              backgroundjobStatus={batchUpload.backgroundjob?.status}
              fileName={batchUpload.fileName}
              uploadBatchEditDoc={batchUpload.uploadDocument}
              onDownloadTemplateClick={batchUpload.downloadTemplate}
              onDownloadErrorClick={batchUpload.downloadError}
            />
            <AddFields headers={HEADERS_INPUT} />
          </Panel>
          <AwarePanel
            title="Active Users"
            className="settlements-user-management__grid"
          >
            {loading ? (
              <ApLoaderDefault
                loaderSize="lg"
                className="settlements-user-management__loader"
              />
            ) : (
              <EditFieldsList headers={HEADERS_EDIT} pagination />
            )}
          </AwarePanel>
        </EntityTable>
      </section>
      {confirmDialog && <ConfirmModal {...confirmDialog} />}
      {batchUpload.uploadErrorDialog.show && (
        <BatchFilesErrorDialog
          onClose={() => batchUpload.uploadErrorDialog.close()}
          onDownloadError={batchUpload.downloadError}
        />
      )}
    </SettingsContentPanel>
  );
};

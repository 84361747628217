import { ICellRendererParams } from 'ag-grid-community';
import { SingleSettlementResponse } from 'api/endpoints/settlements';
import { SettlementStatusPill } from '../../../../components/SettlementStatusPill/SettlementStatusPill';
import './SettlementStatusCell.css';

type Props = ICellRendererParams<SingleSettlementResponse, string>;

export const SettlementStatusCell = ({ value, data }: Props) => {
  return (
    <div className="settlement-status-cell">
      <SettlementStatusPill status={data?.status} />
    </div>
  );
};

import {
  Desciption,
  EditFieldsList,
  EntityTable,
  FilterFields,
  Panel,
} from 'components/UIComponents/EntityTable';
import { AwarePanel } from 'components/UIComponents/EntityTable/EntityTable.components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { DynamicFieldFilter } from './DynamicFieldFilter';
import { DynamicFieldInput } from './DynamicFieldInput';
import './DynamicFields.css';
import { useEditAndFilterDynamicFields } from './useDynamicFields';
import { useEffect } from 'react';
import { dynamicFieldsActions } from '../../../../reducer/dynamicFields';
import { useAppDispatch } from '../../../../hooks/reducerHooks';
import { ApLoaderDefault } from '@alixpartners/ui-components';

export function ContractDynamicFields() {
  const dispatch = useAppDispatch();
  const { status, contractsData, onFilter, onEditSave, fetching } =
    useEditAndFilterDynamicFields(SectionType.Contracts);

  useEffect(() => {
    dispatch(dynamicFieldsActions.idle());
  }, [dispatch]);

  return (
    <SettingsContentPanel>
      <section className="dynamicfields">
        <EntityTable
          items={contractsData ?? []}
          status={status}
          editColumnKeys={['name', 'type', 'displayIn', 'enabled']}
          filterColumnKeys={['type']}
          inputComponent={DynamicFieldInput}
          filterComponent={DynamicFieldFilter}
          onFilter={onFilter}
          onEditSave={onEditSave}
          section={SectionType.Claims}
        >
          <Panel title="Dynamic fields">
            <Desciption>
              Dynamic fields can be added to capture additonal data for all
              contracts. These fields will appear on every contract and will also be
              available as a column within the contract tables that can be searched
              and filtered.
            </Desciption>
            <FilterFields headers={['Filter fields']} />
          </Panel>

          <AwarePanel title="Manage dynamic fields" className="edit-fields">
            {fetching ? (
              <ApLoaderDefault loaderSize="lg" />
            ) : (
              <EditFieldsList
                headers={[
                  'Field name',
                  'Field type',
                  'Display In',
                  'Enabled/Disabled',
                ]}
              />
            )}
          </AwarePanel>
        </EntityTable>
      </section>
    </SettingsContentPanel>
  );
}

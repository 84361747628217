// Bring in the gridColumns
import { SOFA_10_COLUMNS } from './Sofa/SOFA_10_Columns';
import { SOFA_11_COLUMNS } from './Sofa/SOFA_11_Columns';
import { SOFA_12_COLUMNS } from './Sofa/SOFA_12_Columns';
import { SOFA_13A_COLUMNS } from './Sofa/SOFA_13A_Columns';
import { SOFA_13B_COLUMNS } from './Sofa/SOFA_13B_Columns';
import { SOFA_14_COLUMNS } from './Sofa/SOFA_14_Columns';
import { SOFA_1_COLUMNS } from './Sofa/SOFA_1_Columns';
import { SOFA_2_COLUMNS } from './Sofa/SOFA_2_Columns';
import { SOFA_3_COLUMNS } from './Sofa/SOFA_3_Columns';
import { SOFA_4_COLUMNS } from './Sofa/SOFA_4_Columns';
import { SOFA_5_COLUMNS } from './Sofa/SOFA_5_Columns';
import { SOFA_6_COLUMNS } from './Sofa/SOFA_6_Columns';
import { SOFA_7_COLUMNS } from './Sofa/SOFA_7_Columns';
import { SOFA_8_COLUMNS } from './Sofa/SOFA_8_Columns';
import { SOFA_9_COLUMNS } from './Sofa/SOFA_9_Columns';
// Schedule AB columns
import { SCHEDULE_AB_10_COLUMNS } from './Schedules/SCHEDULE_AB_10_Columns';
import { SCHEDULE_AB_11_COLUMNS } from './Schedules/SCHEDULE_AB_11_Columns';
import { SCHEDULE_AB_1_COLUMNS } from './Schedules/SCHEDULE_AB_1_Columns';
import { SCHEDULE_AB_2_COLUMNS } from './Schedules/SCHEDULE_AB_2_Columns';
import { SCHEDULE_AB_3_COLUMNS } from './Schedules/SCHEDULE_AB_3_Columns';
import { SCHEDULE_AB_4_COLUMNS } from './Schedules/SCHEDULE_AB_4_Columns';
import { SCHEDULE_AB_5_COLUMNS } from './Schedules/SCHEDULE_AB_5_Columns';
import { SCHEDULE_AB_6_COLUMNS } from './Schedules/SCHEDULE_AB_6_Columns';
import { SCHEDULE_AB_7_COLUMNS } from './Schedules/SCHEDULE_AB_7_Columns';
import { SCHEDULE_AB_8_COLUMNS } from './Schedules/SCHEDULE_AB_8_Columns';
import { SCHEDULE_AB_9_COLUMNS } from './Schedules/SCHEDULE_AB_9_Columns';
// Schedule D columns
import { SCHEDULE_D_1_COLUMNS } from './Schedules/SCHEDULE_D_1_Columns';
import { SCHEDULE_D_2_COLUMNS } from './Schedules/SCHEDULE_D_2_Columns';
// Schedule EF columns
import { SCHEDULE_EF_1_COLUMNS } from './Schedules/SCHEDULE_EF_1_Columns';
import { SCHEDULE_EF_2_COLUMNS } from './Schedules/SCHEDULE_EF_2_Columns';
import { SCHEDULE_EF_3_COLUMNS } from './Schedules/SCHEDULE_EF_3_Columns';
// Schedule G columns
import { SCHEDULE_G_1_COLUMNS } from './Schedules/SCHEDULE_G_1_Columns';
import { SCHEDULE_H_1_COLUMNS } from './Schedules/SCHEDULE_H_1_Columns';

import { SCHEDULE_SIGNATURE_COLUMNS } from './Schedules/SCHEDULE_SIGNATURE_Columns';

import { ISofaTableProps } from '../../tables.types';
import { GridColumObj } from './columnsType';

// TODO propose this to be backend driven
// Reset types
const SOFAS_PART_INFO_MAP: Record<string, GridColumObj> = {
  '1': SOFA_1_COLUMNS as GridColumObj,
  '2': SOFA_2_COLUMNS as GridColumObj,
  '3': SOFA_3_COLUMNS as GridColumObj,
  '4': SOFA_4_COLUMNS as GridColumObj,
  '5': SOFA_5_COLUMNS as GridColumObj,
  '6': SOFA_6_COLUMNS as GridColumObj,
  '7': SOFA_7_COLUMNS as GridColumObj,
  '8': SOFA_8_COLUMNS as GridColumObj,
  '9': SOFA_9_COLUMNS as GridColumObj,
  '10': SOFA_10_COLUMNS as GridColumObj,
  '11': SOFA_11_COLUMNS as GridColumObj,
  '12': SOFA_12_COLUMNS as GridColumObj,
  '13A': SOFA_13A_COLUMNS as GridColumObj,
  '13B': SOFA_13B_COLUMNS as GridColumObj,
  '14': SOFA_14_COLUMNS as GridColumObj,
};

const SCHEDULES_PART_INFO_MAP: Record<string, GridColumObj> = {
  AB1: SCHEDULE_AB_1_COLUMNS as GridColumObj,
  AB2: SCHEDULE_AB_2_COLUMNS as GridColumObj,
  AB3: SCHEDULE_AB_3_COLUMNS as GridColumObj,
  AB4: SCHEDULE_AB_4_COLUMNS as GridColumObj,
  AB5: SCHEDULE_AB_5_COLUMNS as GridColumObj,
  AB6: SCHEDULE_AB_6_COLUMNS as GridColumObj,
  AB7: SCHEDULE_AB_7_COLUMNS as GridColumObj,
  AB8: SCHEDULE_AB_8_COLUMNS as GridColumObj,
  AB9: SCHEDULE_AB_9_COLUMNS as GridColumObj,
  AB10: SCHEDULE_AB_10_COLUMNS as GridColumObj,
  AB11: SCHEDULE_AB_11_COLUMNS as GridColumObj,
  D1: SCHEDULE_D_1_COLUMNS as GridColumObj,
  D2: SCHEDULE_D_2_COLUMNS as GridColumObj,
  EF1: SCHEDULE_EF_1_COLUMNS as GridColumObj,
  EF2: SCHEDULE_EF_2_COLUMNS as GridColumObj,
  EF3: SCHEDULE_EF_3_COLUMNS as GridColumObj,
  G1: SCHEDULE_G_1_COLUMNS as GridColumObj,
  H1: SCHEDULE_H_1_COLUMNS as GridColumObj,
  Signature: SCHEDULE_SIGNATURE_COLUMNS as GridColumObj,
};

export const getGridColumnObject = (sofaTable: ISofaTableProps) => {
  const { type, part, schedule = '' } = sofaTable;

  const partInfo =
    type === 'sofa'
      ? SOFAS_PART_INFO_MAP[part!]
      : SCHEDULES_PART_INFO_MAP[
          `${schedule}${schedule === 'Signature' ? '' : part}`
        ];

  return partInfo?.gridColumns;
};

import { showHttpError } from 'api/apiRequest/httpErrorAlert';
import {
  ExportPdfResponse,
  exportPdf_failed,
  exportPdf_notCompleted,
} from 'api/endpoints/exportPdf';
import { ReportType, sofasAndSchedules } from 'api/endpoints/sofas-schedules';
import * as localStorage from 'local-storage';
import { useEffect, useState } from 'react';
import { apiSofaExport } from '../api/apiSofaExport';
import { useSofasExportContext } from '../Domain/SofasExportContextProvider';
import { ExportDropdownItems, SofasSelected } from './sofaExportTypes';
import { getPdfExportParams } from './sofaExportUtils';
import { useSofasExportJobMonitoring } from './useSofasExportPdfMonitoring';

export const KEY_EXPORT_STATUS = 'SOFAs-Export-status';

interface Props {
  dropdownItems: ExportDropdownItems;
  selected: SofasSelected;
  isFieldsValid: boolean;
}

export const useSofasExport = ({
  dropdownItems,
  selected,
  isFieldsValid,
}: Props) => {
  const sofaExportContext = useSofasExportContext();

  const [exportStatus, setExportStatus] = useState<ExportPdfResponse>();
  const [showLoadingPanel, setShowLoadingPanel] = useState<boolean>(false);

  const backgroundJob = useSofasExportJobMonitoring({
    onStart: (details) => {
      setExportStatus(details);
      setShowLoadingPanel(true);
      sofaExportContext.forceUpdate();
    },
    onEnd: async (details) => {
      setExportStatus(details);
      sofaExportContext.forceUpdate();

      if (details?.exportStatus === 'Completed') {
        await apiSofaExport.downloadExportedPdf(details.filePath);
        closeLoadingPanel();
        return;
      }

      if (exportPdf_notCompleted.includes(details?.exportStatus!)) {
        closeLoadingPanel();
      }

      if (exportPdf_failed.includes(details?.exportStatus!)) {
        showHttpError({ title: 'Sofas PDF Export failed' });
      }
    },
    onProgress: (details) => setExportStatus(details),
  });

  useEffect(() => {
    const lsValue = localStorage.get<ExportPdfResponse>(KEY_EXPORT_STATUS);
    if (lsValue) {
      backgroundJob.start(lsValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = async (reportType: ReportType) => {
    if (showLoadingPanel || !isFieldsValid) return;
    if (!['Sofa', 'Schedule'].includes(reportType)) return;

    setExportStatus(undefined);
    setShowLoadingPanel(true);
    const params = getPdfExportParams(reportType, dropdownItems, selected);
    const result = await sofasAndSchedules.exportPdf(params);

    // to be able to continue downloading after page was refreshed
    localStorage.set(KEY_EXPORT_STATUS, result);
    backgroundJob.start(result);
  };

  const downloadFile = (value?: ExportPdfResponse) => {
    if (!value) return;
    apiSofaExport.downloadExportedPdf(value.filePath);
  };

  const cancelExport = async () => {
    await apiSofaExport.cancelExport(exportStatus?.id);
    backgroundJob.stop();

    await sofaExportContext.forceUpdate();
    closeLoadingPanel();
  };

  const closeLoadingPanel = () => {
    localStorage.remove(KEY_EXPORT_STATUS);
    setShowLoadingPanel(false);
    setExportStatus(undefined);
  };

  return {
    showLoadingPanel,
    exportStatus,
    submit,
    cancelExport,
    closeLoadingPanel,
    downloadFile,
  };
};

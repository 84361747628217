export const stringFilterOptions = [
  'contains',
  'startsWith',
  'endsWith',
  'equals',
  'notEqual',
  'notContains',
];

export const numberFilterOptions = [
  'equals',
  'lessThan',
  'greaterThan',
  'lessThanOrEqual',
  'greaterThanOrEqual',
  'inRange',
  'notEqual',
];

export const dateFilterOptions = [
  'equals',
  'lessThan',
  'greaterThan',
  'inRange',
  'notEqual',
];

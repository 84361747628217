import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { REFERENCE_NUMBER_COLUMN } from '../../const';
import { CellClickedEvent } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { SectionType, SubsectionType } from '../../types';
import { buildSingleClaimPath } from 'utils/hooks/useClaimToolQueryParams';
import { useAppDispatch } from 'hooks/reducerHooks';
import { clearToSend as clearActionToSendPayload } from 'reducer/claimActionNameSelectionReducer';

function useRefClick(section: SectionType, subsection: SubsectionType) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onRefCellClickedInjector = useCallback(
    (event: CellClickedEvent<ClaimRow>) => {
      const { column, data } = event;
      if (column.getColDef().field !== REFERENCE_NUMBER_COLUMN) return;
      const claimId = data?.id;
      if (!claimId) return;
      dispatch(clearActionToSendPayload({}));
      navigate(buildSingleClaimPath(data?.id, section, subsection));
    },
    [navigate, section, subsection, dispatch],
  );
  return {
    onRefCellClickedInjector,
  };
}

export default useRefClick;

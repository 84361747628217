import { SettlementDocumentPost, settlements } from 'api/endpoints/settlements';
import { SubmitEntity } from 'components/ModalWindows/UploadModal';
import { UploadResult } from 'utils/azureUploader/azureFileBatchUploader';

export const apiPostSettlementDocuments = async (
  id: number,
  entityList: UploadResult<SubmitEntity>[],
) => {
  if (entityList.length <= 0) return;

  const params = entityList.map(mapFielsToSettlementDocument);
  await settlements.postDocuments(id, params);
};

const mapFielsToSettlementDocument = (
  item: UploadResult<SubmitEntity>,
): SettlementDocumentPost => {
  return {
    documentName: item.fileName ?? '',
    displayName: item.entity.file.name,
    settlementDocumentTypeId: +item.entity.documentType,
    docketNumber: item.entity.referenceNumber ?? '',
  };
};

import { SettlementStatus } from 'api/endpoints/settlements';
import classnames from 'classnames';
import { ComponentProps } from 'react';
import { SettlementStatusPill } from '../SettlementStatusPill/SettlementStatusPill';
import './SettlementHeaderBox.css';

type Props = ComponentProps<'div'> & {
  title: string | undefined;
  settlementNum: string | number | undefined;
  claimsNumber: string | undefined;
  status: SettlementStatus | undefined;
};

export const SettlementHeaderBox = ({
  className,
  title,
  settlementNum,
  claimsNumber,
  status,
  ...props
}: Props) => {
  return (
    <div className={classnames('settlement-header-box', className)} {...props}>
      <div className="settlement-header-box__main">
        <div className="settlement-header-box__number">
          <span>Settlement Number: </span>
          <span className="settlement-header-box__numberlink">{settlementNum}</span>
        </div>
        <div className="settlement-header-box__claims">
          Claims Number: {claimsNumber}
        </div>
        <div className="settlement-header-box__name">{title}</div>
      </div>

      <div className="settlement-header-box__addition">
        <div className="settlement-header-box__status">Settlement Status</div>
        <SettlementStatusPill bigFont status={status} />
      </div>
    </div>
  );
};

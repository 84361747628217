import classnames from 'classnames';
import { ComponentProps } from 'react';
import './SettingsEnvName.css';

type Props = Omit<ComponentProps<'div'>, 'children'>;

export const SettingsEnvName = ({ className, ...props }: Props) => {
  return (
    <div className={classnames('settings-environment-name', className)} {...props}>
      <div>Environment Name:</div>
      <div className="settings-environment-name__name">
        {process.env.REACT_APP_PROJECT_CODE_NAME}
      </div>
    </div>
  );
};

import { claimKey } from './Counterparty.types';

export const filedClaimsHeaders = {
  referenceNumber: 'Ref ',
  counterpartyName: 'Counterparty name',
  subMatchCode: 'Sub-match code',
  majorCategory: 'Major Category',
  minorCategory: 'Minor Category',
  currentTotal_formatted: 'Current total',
  currentDebtor: 'Current debtor',
  actionStatus: 'Workflow status',
  officialClaimDate_formatted: 'Date filed',
  claimImage: 'Claim image URL',
  actionName: 'Resolution name',
  actionExhibitTypeName: 'Resolution type',
  actionSubStatusName: 'Resolution subtype',
  reviewLevel: 'Review level',
  originalCurrentProposed: 'Original current proposed',
  originalTotal_formatted: 'Original total',
  clientTotal_formatted: 'Proposed total',
  proposedTotal_formatted: 'Proposed total',
  counselTotal_formatted: 'Counsel total',
  currentContingent_formatted: 'Current contingent',
  currentUnliquidated_formatted: 'Current unliquidated',
  currentDisputed_formatted: 'Current disputed',
  empty: 'empty',
};

export const scheduledClaimsKeys: claimKey[] = [
  'referenceNumber',
  'counterpartyName',
  'empty',
  'currentDebtor',
  'subMatchCode',
  'majorCategory',
  'minorCategory',
  'actionStatus',
  'actionExhibitTypeName',
  'actionSubStatusName',
  'actionName',
  'originalTotal_formatted',
  'currentTotal_formatted',
  'proposedTotal_formatted',
  'currentContingent_formatted',
  'currentUnliquidated_formatted',
  'currentDisputed_formatted',
];

export const filedClaimsKeys: claimKey[] = [
  ...scheduledClaimsKeys,
  'officialClaimDate_formatted',
];

export const boldKeys: claimKey[] = ['referenceNumber', 'counterpartyName'];

export const hiddenColumnValues: claimKey[] = [
  'claimImage',
  'reviewLevel',
  'originalCurrentProposed',
];

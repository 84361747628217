import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import './PinCell.css';
import { SvgIcon } from 'components/UIComponents/SvgIcon';

export const PinCell = (props: ICellRendererParams) => (
  <div className={classNames('pin-cell')}>
    <SvgIcon
      className={classNames('pin-icon', { active: props.node?.rowPinned })}
      iconName="pin"
    />
  </div>
);

import _ from 'lodash';
import { ISofaTableProps } from '../../tables.types';

export const getGraphQlEntity = (sofaProps: ISofaTableProps) => {
  const { type, schedule = '', part } = sofaProps;
  const typeName = type === 'sofa' ? 'sofas' : type;

  const selectedPart = schedule === 'Signature' ? '' : `Part${part}`;

  return `${typeName}${schedule}${selectedPart}`;
};

export const getGrapQlQueryName = (sofaProps: ISofaTableProps) => {
  const { type, part } = sofaProps;
  const typeName = _.upperFirst(type.toLowerCase()) + 's';

  return `Get${typeName}Part${part}`;
};

type OperationType = 'get' | 'update' | 'delete' | 'add';

export const graphQlFunctionName = (
  sofaProps: ISofaTableProps,
  operation: OperationType,
) => {
  const entityName = getGraphQlEntity(sofaProps);

  if (operation === 'get') {
    return {
      name: `Get${_.upperFirst(entityName)}`,
      field: entityName,
    };
  }

  const field = `${operation}${_.upperFirst(entityName)}`;
  const name = _.upperFirst(field);

  return { name, field };
};

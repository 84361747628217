import classNames from 'classnames';
import { InputComponent } from 'components/UIComponents/EntityTable';
import '../claims/UserGroups.css';
import { ContractsUserGroupsResponse } from 'api/endpoints';
import { useCallback, useEffect, useMemo } from 'react';
import { useAllUsers } from 'hooks/useAllUsers';
import { MultiSelect } from 'components/UIComponents/MultiSelect';
import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { mapUserInfoToOption } from 'pages/SettingsPage/api/apiGetAllUsersOptions';
import {
  getSelectedCategoryLabel,
  getSelectedUserLabel,
} from 'pages/SettingsPage/components/UserGroupList/utils/userGroupLabelUtils';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppSelector } from 'hooks/reducerHooks';
import { FormValidState } from 'utils/form/form-utils';

const usersInputName = 'users';
const categoriesInputName = 'contractCategories';

export const UsersInput: InputComponent<ContractsUserGroupsResponse> = ({
  item,
  fromTable,
}) => {
  const { disabled, className, getValue, setValue, setValidity, showHoverData } =
    fromTable;
  const selectedUserIds = useMemo(() => {
    return (
      (getValue(usersInputName) as number[] | undefined) ||
      (item ? item.users?.map((au) => au.appUserId) : [])
    );
  }, [getValue, item]);

  const { data: users, status } = useAllUsers();

  const options: IOption[] = useMemo(() => {
    return users?.map(mapUserInfoToOption) ?? [];
  }, [users]);

  const selectedOptions: IOption[] = useMemo(() => {
    const idsAsStrings = selectedUserIds?.map((id) => `${id}`) ?? [];
    return options.filter((o) => idsAsStrings.includes(o.value));
  }, [options, selectedUserIds]);

  const handleChange = useCallback(
    (options: IOption[]) => {
      const ids: number[] = options.map((o) => +o.value);
      setValue(usersInputName, ids);
    },
    [setValue],
  );

  useEffect(() => {
    if (item && item.users) {
      const ids = item.users.map((au) => au.appUserId);
      setValue(usersInputName, ids);
    }
  }, [item, setValue]);

  useEffect(() => {
    const validity: FormValidState = selectedOptions?.length ? 'valid' : 'invalid';
    setValidity(usersInputName, validity);
  }, [selectedOptions?.length, setValidity]);

  const renderHoverData = useCallback(
    () =>
      selectedOptions ? (
        <div className="hover-data">
          {selectedOptions.map(
            (item, idx) =>
              `${item?.label}${idx + 1 < selectedOptions.length ? ',' : ''} `,
          )}
        </div>
      ) : null,
    [selectedOptions],
  );

  return (
    <MultiSelect
      labelCN={classNames('utils-clean-input', className)}
      label={item ? '' : undefined}
      disabled={disabled || status === FetchStatus.Fetching}
      options={options}
      selected={selectedOptions}
      search
      text={getSelectedUserLabel(selectedUserIds)}
      onChange={handleChange}
      hasHoverContainer={!!selectedOptions.length && showHoverData}
      renderHoverData={renderHoverData}
    />
  );
};

export const CategoriesInput: InputComponent<ContractsUserGroupsResponse> = ({
  item,
  fromTable,
}) => {
  const { disabled, className, getValue, setValue, setValidity } = fromTable;

  const {
    data: { contractCategories },
  } = useAppSelector((state) => state.selectable);

  const selectedOptions = useMemo(() => {
    return (
      getValue(categoriesInputName) ||
      (item
        ? computeInitialCategoryValue(item.contractCategories, contractCategories)
        : [])
    );
  }, [getValue, item, contractCategories]);

  const handleChange = useCallback(
    (options: IOption[]) => {
      setValue(categoriesInputName, options);
    },
    [setValue],
  );

  useEffect(() => {
    const validity: FormValidState = selectedOptions?.length ? 'valid' : 'invalid';
    setValidity(categoriesInputName, validity);
  }, [selectedOptions?.length, setValidity]);

  useEffect(() => {
    if (item && item.contractCategories) {
      const initalOptions: IOption[] = computeInitialCategoryValue(
        item.contractCategories,
        contractCategories,
      );
      setValue(categoriesInputName, initalOptions);
    }
  }, [item, contractCategories, setValue]);

  return (
    <MultiSelect
      labelCN={classNames('utils-clean-input', className)}
      label={item ? '' : undefined}
      disabled={disabled}
      options={
        contractCategories.map((c) => ({
          label: c.category,
          value: `${c.id}`,
        })) as IOption[]
      }
      selected={selectedOptions}
      search
      text={getSelectedCategoryLabel(selectedOptions)}
      onChange={handleChange}
    />
  );
};

export const UserGroupInput: InputComponent<ContractsUserGroupsResponse> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className } = fromTable;
  if (columnKey === 'name') {
    return (
      <input
        type="text"
        name={columnKey}
        maxLength={50}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        placeholder="Enter name"
        defaultValue={item?.name}
      />
    );
  }
  if (columnKey === 'users') {
    return <UsersInput columnKey={columnKey} item={item} fromTable={fromTable} />;
  }

  if (columnKey === 'contractCategories') {
    return (
      <CategoriesInput columnKey={columnKey} item={item} fromTable={fromTable} />
    );
  }

  return null;
};
function computeInitialCategoryValue(categories: any, options: any): IOption[] {
  const categoryIds = categories?.map((c: any) => c?.categoryId);
  const firstLevel =
    options
      ?.filter((o: any) => categoryIds?.includes(o.id))
      .map((o: any) => ({ value: `${o.id}`, label: o.category })) ?? [];

  return [...firstLevel];
}

import {
  GlobalNote,
  GlobalNotePut,
  globalNotes,
  LegalEntitiesIds,
  SofaOrScheduleFileType,
} from 'api/endpoints';
import {
  ErrorResponse,
  OkResponse,
  ResponseType,
} from 'api/jsonFetch/jsonFetch.types';
import { useAppDispatch } from 'hooks/reducerHooks';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import {
  fetchAllGlobalNotesThunks,
  updateGlobalNoteThunk,
} from 'reducer/thunks/globalNotes-thunks';
import { uploadGlobalNote } from 'utils/uploader';

export type UploadStatus = {
  progress?: number;
  error?: string[];
};

export type SubmitData = {
  files: File[];
  legalEntities: LegalEntitiesIds;
};

type UploadOkResult = {
  type: 'ok';
  result: { fileName: string };
  entry: SubmitData['files'][0];
};

type UploadFailedResult = {
  type: 'fail';
  error: any;
  entry: SubmitData['files'][0];
};

const MIN_UPLOAD_PROGRESS = 0.1;
const MAX_UPLOAD_PROGRESS = 0.9;
const DELTA_UPLOAD_PROGRESS = MAX_UPLOAD_PROGRESS - MIN_UPLOAD_PROGRESS;

export function useUploadGlobalNote(fileType: SofaOrScheduleFileType) {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({});
  const dispatch = useAppDispatch();

  const setUploadProgress = (progress: number | undefined) =>
    setUploadStatus((r) => ({ ...r, progress }));

  const createGlobalNote = useCallback(
    async ({ files, legalEntities }: SubmitData) => {
      if (_.isEmpty(files)) return;

      const uploadResults = await azureUploadGlobalNoteFiles(
        fileType,
        files,
        setUploadProgress,
      );

      const [uploaded, failedUpload] = parseUploadResults(uploadResults);

      const notesResults = await apiCreateGlobalNotes(
        uploaded,
        legalEntities,
        fileType,
      );

      const [createdNotes, failedNotes] = parseCreatedNotesResults(notesResults);
      if (createdNotes.length) {
        dispatch(fetchAllGlobalNotesThunks(fileType));
      }

      setUploadStatus({
        error: getErrorMessage(failedUpload, failedNotes),
      });
    },
    [dispatch, fileType],
  );

  const updateGlobalNote = useCallback(
    async (item: GlobalNotePut) => {
      await dispatch(updateGlobalNoteThunk(fileType, item));
      dispatch(fetchAllGlobalNotesThunks(fileType));
    },
    [fileType, dispatch],
  );

  const resetError = () => setUploadStatus((r) => ({ ...r, error: undefined }));

  return {
    uploadStatus,
    resetError,
    createGlobalNote,
    updateGlobalNote,
  };
}

async function azureUploadGlobalNoteFiles(
  fileType: SofaOrScheduleFileType,
  files: File[],
  setUploadStatus: (value: number) => void,
) {
  const totalSize = _(files)
    .map((f) => f.size)
    .sum();

  let totalLoaded = 0;

  setUploadStatus(MIN_UPLOAD_PROGRESS);

  const uploadPromises = files.map(async (file) => {
    try {
      let prevLoaded = 0;
      const result = await uploadGlobalNote(file, fileType, (loaded) => {
        const delta = loaded - prevLoaded;
        prevLoaded = loaded;
        totalLoaded = totalLoaded + delta;
        const progress =
          MIN_UPLOAD_PROGRESS + (totalLoaded / totalSize) * DELTA_UPLOAD_PROGRESS;

        setUploadStatus(progress);
      });
      if (result) {
        return { type: 'ok', result, entry: file } as UploadOkResult;
      }
      throw new Error('No result.');
    } catch (e) {
      return { type: 'fail', error: e, entry: file } as UploadFailedResult;
    }
  });

  const uploadResults = await Promise.all(uploadPromises);
  // TODO also output the errors
  setUploadStatus(MAX_UPLOAD_PROGRESS);
  return uploadResults;
}

async function apiCreateGlobalNotes(
  okResults: UploadOkResult[],
  legalEntities: LegalEntitiesIds,
  fileType: SofaOrScheduleFileType,
) {
  const promises = okResults.map((r) =>
    globalNotes.post({
      displayName: r.entry.name,
      name: r.result.fileName,
      fileType,
      legalEntities,
    }),
  );

  return await Promise.all(promises);
}

type ServerError400 = {
  isFileUploaded: boolean;
  isOpenXmlDocument: boolean;
  isValid: boolean;
  isValidOpenXmlDocument: boolean;
};

function getErrorMessage(
  failedUpload: UploadFailedResult[],
  failedNotes: ErrorResponse<any>[],
) {
  const uploadError = failedUpload.map((r) => `File upload failed: ${r.entry}`);

  const notesError = failedNotes.map((r) => {
    switch (r.type) {
      case ResponseType.FETCH_ERR:
      case ResponseType.PARSE_ERR:
        return 'Request Error';
      case ResponseType.SEVER_ERR:
        return `Server Error ${r.status}`;

      case ResponseType.BAD_REQ:
        if (r.data.status === 400) {
          const response = r.data.error as ServerError400;
          if (response?.isFileUploaded === false) return 'File is not uploaded';
          if (response?.isOpenXmlDocument === false)
            return 'File is not a document type';
          if (response?.isValidOpenXmlDocument === false)
            return 'File is not valid Xml Document';
          if (response?.isValid === false) return 'File is not valid';
          return 'Unknow 400 error status';
        }

        return `Bad request ${r.data.status}`;
      default:
        return 'Unknow error';
    }
  });

  const result = _.compact([...uploadError, ...notesError]);
  return !_.isEmpty(result) ? result : undefined;
}

function parseUploadResults(
  items: (UploadOkResult | UploadFailedResult)[],
): [UploadOkResult[], UploadFailedResult[]] {
  const succes = items.filter((u) => u.type === 'ok') as UploadOkResult[];
  const failed = items.filter((u) => u.type === 'fail') as UploadFailedResult[];
  return [succes, failed];
}

function parseCreatedNotesResults(
  items: (OkResponse<GlobalNote> | ErrorResponse<any>)[],
): [GlobalNote[], ErrorResponse<any>[]] {
  const succes = (
    items.filter((nr) => nr.type === ResponseType.OK) as OkResponse<GlobalNote>[]
  ).map((nr) => nr.data);

  const failed = items.filter(
    (r) => r.type !== ResponseType.OK,
  ) as ErrorResponse<any>[];

  return [succes, failed];
}

import './MultiSelect.css';
import { MultiSelectLabel, OptionsList } from './MultiSelectComponents';
import { useMemo, useRef, useState } from 'react';

import { ApMenuStyled } from './MultiSelect.styles';
import { ApSimpleSearch, IIconProps } from '@alixpartners/ui-components';
import classnames from 'classnames';

export interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
  iconName?: string;
  iconProps?: IIconProps;
  labelNode?: React.ReactNode;
  children?: IOption[];
  data?: any;
}

export interface IMultiSelectProps {
  options: IOption[];
  selected?: IOption[]; //todo: selected items should be array of IOption.values: "selected?: string[]""
  text?: string;
  iconName?: string;
  iconProps?: IIconProps;
  disabled?: boolean;
  multiple?: boolean;
  message?: string;
  search?: boolean;
  maxItems?: number;
  className?: string;
  labelCN?: string;
  label?: string;
  isSelectable?: (o: IOption) => boolean;
  onChange: (option: IOption[]) => void;
  hasHoverContainer?: boolean;
  renderHoverData?: () => JSX.Element | null;
}
const DEFAULT_LABEL = 'Select an option';

export const MultiSelect = ({
  options,
  selected,
  iconName,
  iconProps,
  disabled,
  text,
  multiple = true,
  message,
  search,
  maxItems,
  className,
  labelCN,
  label = DEFAULT_LABEL,
  isSelectable,
  onChange,
  hasHoverContainer,
  renderHoverData,
}: IMultiSelectProps) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showHoverData, setShowHoverData] = useState(false);

  const filteredOptions = useMemo(() => {
    const text = searchText.trim().toLowerCase();
    if (!search || !text) return options;

    return filterItemsByText(options, text) ?? [];
  }, [search, options, searchText]);

  const selectedOptionsCount = selected?.length ?? 0;
  const maxItemsExceeded = !!maxItems && selectedOptionsCount >= maxItems;

  const getSelectedLabel = () => {
    if (!selected) return label;

    return selected.map((option: IOption) => option.label).join(', ') || label;
  };

  const handleOptionSelect = (newSelectedOption: IOption) => {
    if (isSelectable && !isSelectable(newSelectedOption)) return;
    if (!selected || !multiple) {
      onChange([newSelectedOption]);
      return;
    }

    const newSelected = [...selected];

    const index = newSelected.findIndex(
      (option: IOption) => option.value === newSelectedOption.value,
    );

    if (index >= 0) {
      newSelected.splice(index, 1);
    } else if (!maxItemsExceeded) {
      newSelected.push(newSelectedOption);
    }
    onChange(newSelected);
  };

  //Bug in the ApSimpleSearch component. Have to use 'any' type instead of 'string'
  const handleSearchChange = (e: any) => setSearchText(e);
  const handleClear = () => setSearchText('');

  const handleMenuToggle = (open: boolean) => {
    setIsOpen(open);
    if (!open) setSearchText('');
  };

  return (
    <div
      className={classnames('multiselect--container', className, {
        'multiselect--container-shadow': isOpen,
        'multiselect--is-open': isOpen,
      })}
      ref={ref}
      onMouseEnter={hasHoverContainer ? () => setShowHoverData(true) : undefined}
      onMouseLeave={hasHoverContainer ? () => setShowHoverData(false) : undefined}
    >
      <ApMenuStyled
        isOpen={isOpen}
        className="multiselect--menu"
        compact={false}
        placement="bottom-start"
        width={ref.current?.clientWidth}
        button={(props: any) => (
          <MultiSelectLabel
            {...props}
            className={labelCN}
            text={text || getSelectedLabel()}
            iconName={iconName}
            iconProps={iconProps}
            disabled={disabled}
          />
        )}
        toggleMenu={handleMenuToggle}
      >
        {message && <div className="multiselect--title">{message}</div>}
        {search && (
          <div className="multiselect--search">
            <ApSimpleSearch
              id="id-multiselect-search-filed"
              value={searchText}
              placeholder="Search"
              onChange={handleSearchChange}
              onClear={handleClear}
            />
          </div>
        )}
        <OptionsList
          options={filteredOptions}
          multiple={multiple}
          selected={selected}
          maxItemsExceeded={maxItemsExceeded}
          onChange={handleOptionSelect}
        />
      </ApMenuStyled>
      {showHoverData && hasHoverContainer && renderHoverData?.()}
    </div>
  );
};

const filterItemsByText = (
  options: IOption[] | undefined,
  text: string,
): IOption[] | undefined => {
  if (!options) return undefined;

  const filteredChildren = options.map((r) => ({
    ...r,
    children: filterItemsByText(r.children, text),
  }));

  return filteredChildren.filter(
    (r) => r.label.toLowerCase().includes(text) || !!r.children?.length,
  );
};

import classNames from 'classnames';
import { formatValue } from 'pages/SingleContractPage/sections/SingleContractOverview/util/inputValueFormatter';
import { useState } from 'react';
import './SCPInput.css';

export const SCPInput = (
  props: React.ComponentProps<'input'> & {
    amountField?: boolean;
    preventBrowserAddressDialog?: boolean;
  },
) => {
  const {
    value,
    className,
    onChange,
    onBlur,
    amountField,
    preventBrowserAddressDialog,
    title,
    ...otherProps
  } = props;
  const [v, setV] = useState<typeof value>(value);

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(e);
    if (amountField) {
      const newVal = formatValue({ value: +e.target.value.replace(/[$,]/g, '') });
      setV(newVal);
    }
  };

  return (
    <>
      <input
        value={v}
        className={classNames('scp-input', className)}
        onChange={(e) => setV(e.target.value)}
        onBlur={handleOnBlur}
        //workaround - to not show "save this address" browser autocomplete dialog
        autoComplete={preventBrowserAddressDialog ? 'one-time-code' : undefined}
        title={title}
        {...otherProps}
      />
    </>
  );
};

export const SCPTextarea = (props: React.ComponentProps<'textarea'>) => {
  const { value, className, onChange, ...otherProps } = props;
  const [v, setV] = useState<typeof value>(value);

  return (
    <textarea
      className={classNames('scp-input scp-text-area', className)}
      value={v}
      onChange={(e) => setV(e.target.value)}
      {...otherProps}
    />
  );
};

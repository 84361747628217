import { Fragment, useMemo, useState } from 'react';

import { ClaimAttachmentDocumentType } from 'api/endpoints/claimAttachments';
import 'components/UIComponents/simple-table.css';
import {
  Cell,
  ClaimsDocumentsTable,
  Row,
} from 'components/ClaimsDocumentsTable';
import { DocFileLink } from 'components/UIComponents/GridCell';
import 'components/UIComponents/simple-table.css';
import { CLAIM_ATTACHMENTS_OPTIONS } from 'pages/SingleClaimPage/types';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { DocumentTableActions } from 'pages/SingleContractPage/sections/SingleContractDocs/DocumentTableActions';
import { findGridOptionsLabel, formatGridDate } from 'utils/singleClaimFormatter';
import { downloadClaimAttachment } from 'utils/uploader';
import { DocComment } from 'pages/SingleContractPage/sections/SingleContractDocs/DocComment';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

const GRID_HEADER_CAPTION = [
  'Document file',
  'Document type',
  'Docket/ ref number',
  'Added by',
  'Date added',
  'Actions',
];

export const ClaimAttachmentsTable = () => {
  const {
    attachments,
    listStatus,
    uploadStatus,
    deleteAttachment,
    deletingDoc,
    loadList,
  } = useClaimAttachmentsContext();
  const [downloadList, setDownloadList] = useState<number[]>([]);

  const filesUploadedByClaimId = useMemo(() => {
    return attachments.map((f) => ({
      ...f,
      referenceNumberLabel: f.referenceNumber ?? '--',
      timeAgoLabel: formatGridDate(f.createdDate),
      documentTypeLabel: findGridOptionsLabel(
        f.documentType.toString(),
        CLAIM_ATTACHMENTS_OPTIONS,
      ),
      documentType: f.documentType,
      claimImageUrl: f.claimImageUrl,
    }));
  }, [attachments]);

  const handleFileLinkClick =
    (file: { id: number; displayName: string }) => async () => {
      const { id, displayName } = file;

      setDownloadList((r) => [...r, id]);
      await downloadClaimAttachment(id, displayName);
      setDownloadList((r) => r.filter((r) => r !== id));
    };

  return (
    <>
      <ClaimsDocumentsTable
        className="single-claim-attachments"
        showLoader={listStatus === 'fetching'}
        progressValue={uploadStatus.progress}
        emptyListMessage={<span>No attachments for this Claim</span>}
        headerItems={GRID_HEADER_CAPTION}
      >
        {filesUploadedByClaimId.map((f) => {
          const itemIsLoading = downloadList.includes(f.id);

          return (
            <Fragment key={f.id}>
              <Row markAsNew={f.newlyAdded}>
                <Cell style={{ width: '30%' }}>
                  <DocFileLink
                    name={f.displayName}
                    loading={itemIsLoading}
                    onClick={handleFileLinkClick(f)}
                    isClaimImage={
                      f.documentType === ClaimAttachmentDocumentType.ClaimImage
                    }
                    claimImageUrl={f.claimImageUrl}
                  />
                </Cell>
                <Cell>{f.documentTypeLabel}</Cell>
                <Cell>{f.referenceNumberLabel}</Cell>
                <Cell>{f.createdBy}</Cell>
                <Cell>{f.timeAgoLabel}</Cell>
                <Cell>
                  <DocumentTableActions
                    deleteAttachment={() => deleteAttachment(f.id)}
                    downloadClick={handleFileLinkClick(f)}
                    deletingDoc={deletingDoc}
                  />
                </Cell>
              </Row>
              <DocComment
                section={SectionType.Claims}
                document={f}
                loadList={loadList}
              />
            </Fragment>
          );
        })}
      </ClaimsDocumentsTable>
    </>
  );
};

import { api } from 'api';
import { ApButtonCasual } from 'components/UIComponents/Buttons';

import './SettlementAgreemantGetTemplate.css';

interface Props {}

export const SettlementAgreemantGetTemplate = (props: Props) => {
  const handleSampleClick = () => api.settlementAgreementTemplates.downloadSample();

  return (
    <div className="settlement-agreement-get-template">
      <div>
        The Agreement Template represents the structure of the official settlement
        agreement. This document merges in one file the:
        <ul>
          <li> Cover Letter </li>
          <li> Settlement Agreement </li>
          <li> Signature Page </li>
          <li> Exhibit Page(s) </li>
        </ul>
      </div>

      <div>
        This file is going to be populated with specific data for each claim and sent
        out to the Clients and Counterpartys for signing
      </div>

      <h4>
        You can create a new agreement template starting with our standart agreemant
        file.{' '}
      </h4>
      <ApButtonCasual onClick={handleSampleClick}>
        Download a Standart Agreemant template
      </ApButtonCasual>
    </div>
  );
};

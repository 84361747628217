import classNames from 'classnames';

import './SettlementsAgreementSection.css';

type Props = React.ComponentProps<'div'> & {
  title: string;
};

export const SettlementsAgreementSection = ({
  title,
  className,
  children,
  ...props
}: Props) => {
  return (
    <div
      className={classNames('settlements-agreement-section', className)}
      {...props}
    >
      <div className="settlements-agreement-section__title">{title}</div>
      <div className="settlements-agreement-section__body">{children}</div>
    </div>
  );
};

import {
  ColDef,
  ColumnVisibleEvent,
  GridOptions,
  IServerSideDatasource,
  SideBarDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';
import { AgGridExtended, GridInitialState } from 'components/AgGridExtended';
import { debounce } from 'lodash';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

const GRID_HEADER_HEIGHT = 34;
const PAGE_SIZE = 100;

export const SIDE_BAR_CONFIG: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnExpandAll: true,
        suppressColumnSelectAll: true,
        suppressColumnMove: true,
      },
    },
  ],
  position: 'right',
};

const defaultColDef: ColDef = {
  sortable: true,
  width: 200,
  minWidth: 80,
  editable: false,
  floatingFilter: true,
  suppressMenu: true,
};

export interface DataTableGridType {
  resetFilters: () => void;
  showColumnsPanel: (open: boolean) => void;
}

interface Props {
  className?: string;
  initialState: GridInitialState;
  dataSource: IServerSideDatasource;
  columnDefs: GridOptions['columnDefs'];
  onGridStateChanged: (state: GridInitialState) => void;
  getRowId?: GridOptions['getRowId'];
}

export const DataTableGrid = forwardRef<DataTableGridType, Props>((props, ref) => {
  const gridRef = useRef<AgGridReact>(null);

  const refreshServerSide = useCallback(
    () => gridRef.current?.api.refreshServerSide(),
    [],
  );

  const refreshServerSideDebounce = useMemo(
    (timeout: number = 1000) => debounce(refreshServerSide, timeout),
    [refreshServerSide],
  );

  const handleColumnVisible = useCallback(
    (event: ColumnVisibleEvent) => {
      if (event.type !== 'columnVisible' || !event.visible) return;
      refreshServerSideDebounce();
    },
    [refreshServerSideDebounce],
  );

  useImperativeHandle(
    ref,
    () => ({
      resetFilters: () => gridRef.current?.api.setFilterModel({}),
      showColumnsPanel: (open: boolean) => {
        open
          ? gridRef.current?.api.openToolPanel('columns')
          : gridRef.current?.api.closeToolPanel();
      },
    }),
    [],
  );

  return (
    <div
      className={classnames('ag-theme-alpine', 'claims-tool-grid', props.className)}
    >
      <AgGridExtended
        ref={gridRef}
        initialState={props.initialState}
        headerHeight={GRID_HEADER_HEIGHT}
        defaultColDef={defaultColDef}
        floatingFiltersHeight={GRID_HEADER_HEIGHT}
        columnDefs={props.columnDefs}
        pagination
        paginationPageSize={PAGE_SIZE}
        suppressRowClickSelection
        rowSelection={'multiple'}
        animateRows
        rowModelType="serverSide"
        serverSideInfiniteScroll
        serverSideSortOnServer
        serverSideDatasource={props.dataSource}
        suppressContextMenu
        sideBar={SIDE_BAR_CONFIG}
        getRowId={props.getRowId}
        onGridStateChanged={props.onGridStateChanged}
        onColumnVisible={handleColumnVisible}
      />
    </div>
  );
});

import { ColDef } from 'ag-grid-community';
import _ from 'lodash';
import { useReportLegalEntities } from 'pages/SOFAsAndSchedules/Domain/useReportLegalEntities';

import { useMemo } from 'react';

import { LegalEntityKeys } from '../../types/legalEntity';
import { ISofaTableProps } from '../tables.types';
import { getGridColumnObject } from './Columns/getGridColumnObj';
import { mapSofasGridColumnToColDef } from './mapSofasGridColumnToColDef';

export function useSofasColumnDef<TData>(
  sofaTable: ISofaTableProps,
): ColDef<TData>[] {
  const legalEntity = useReportLegalEntities();

  const legalEntityKeys: LegalEntityKeys = useMemo(() => {
    const items = legalEntity?.data ?? [];
    return _(items).keyBy('legalEntityId').mapValues('entityName').value();
  }, [legalEntity?.data]);

  const result = useMemo(() => {
    const columns = getGridColumnObject(sofaTable);
    const colDefs = columns.map(mapSofasGridColumnToColDef({ legalEntityKeys }));

    return _.compact(colDefs);
  }, [sofaTable, legalEntityKeys]);

  return result;
}

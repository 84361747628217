export const SOFA_4_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['9'],
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Recipient Name',
      propertyName: 'recipientName',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },
    {
      dataType: 'string',
      displayName: 'Relationship to Debtor',
      propertyName: 'relationshipToDebtor',
    },
    {
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      dataType: 'string',
      displayName: 'Date Given',
      propertyName: 'dateGiven',
    },
    {
      dataType: 'string',
      displayName: 'Date Given End',
      propertyName: 'dateGivenEnd',
    },
    {
      dataType: 'decimal',
      displayName: 'Value',
      propertyName: 'value',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

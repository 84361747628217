import { AgGridReact } from 'ag-grid-react';
import { colProp } from '../contractsBatchEdit.const';
import {
  ContractActionSubstatus,
  ContractActionType,
} from 'api/endpoints/actions-select-options';

const FILTER_MAP = {
  // initially we'll only filter by action type

  // comment this as request in ticket BUG 118320 to disable filtering table
  // contractActionSubStatusId: 'contractActionTypeName',
  // contractActionTypeId: 'contractActionTypeName',
  // paymentPositionId: 'contractActionTypeName',
};

enum PAYMENT_POSITION {
  PendingCurePayment = 1,
  CurePaid = 2,
  Late = 3,
  InDispute = 4,
}

export type Filter = {
  name: string;
  value: number | undefined;
  listToFilter: any[];
};

export const setFilterTable = (
  gridRef: React.RefObject<AgGridReact<any>> | undefined,
  filter: Filter,
) => {
  const { name, value, listToFilter = [] } = filter;
  let foundItem = [];
  let filterString = '';
  const filterName = (FILTER_MAP as any)[name];

  switch (name) {
    case colProp.contractActionTypeId:
      foundItem = listToFilter.find((type: ContractActionType) => type.id === value);
      filterString = foundItem?.[filterName];
      break;
    case colProp.contractActionSubStatusId:
      foundItem = listToFilter.find((item: ContractActionType) =>
        item.contractActionSubStatuses.find(
          (status: ContractActionSubstatus) => status.id === value,
        ),
      );
      filterString = foundItem?.[filterName];

      break;

    case colProp.paymentPositionId:
      if (
        value === PAYMENT_POSITION.CurePaid ||
        value === PAYMENT_POSITION.PendingCurePayment
      ) {
        filterString = 'Assume / Amended';
      }
      break;
  }

  if (filterString && value !== undefined) {
    const currentFilter = gridRef?.current?.api?.getFilterModel();
    const newFilter = {
      ...currentFilter,
      [filterName]: {
        type: 'contains',
        filterType: 'text',
        filter: filterString,
      },
    };

    // for the moment not saving the filter state from batch editing in localStorage
    gridRef?.current?.api?.setFilterModel(newFilter);
  }
};

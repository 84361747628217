import { ApButtonIcon, ApToggleSwitch } from '@alixpartners/ui-components';
import { ProjectDetails } from 'api/endpoints/projectDetails';
import classNames from 'classnames';
import 'components/UIComponents/css-animations.css';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { PaginationBar } from '../PaginationBar';
import { SvgIcon } from '../SvgIcon';
import { WarningMessage } from '../WarningMessage/WarningMessage';
import { EntityAddRow } from './EntityAddRow';
import { useEntityContext } from './EntityContext';
import { EntityEditRow } from './EntityEditRow';
import { EntityFilterRow } from './EntityFilterRow';
import './EntityTable.css';
import {
  EditListProps,
  EntityInfo,
  HeadersBaseProps,
  IdEntity,
  PanelProps,
} from './EntityTable.types';
import { PAGINATION_COUNT, useTablePagination } from './useTablePagination';

export const Desciption = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >,
) => {
  const { children, className, ...rest } = props;
  return (
    <p className={classNames('edit-table__description', className)} {...rest}>
      {children}
    </p>
  );
};

export const Panel = ({ className, children, title }: PanelProps) => {
  return (
    <div className={classNames('anim-appear dynamicfields-table', className)}>
      {title && <h3 className="edit-table__title">{title}</h3>}
      {children}
    </div>
  );
};

export const AwarePanel = (props: PanelProps) => {
  const { className, ...rest } = props;
  const { status } = useEntityContext();

  return (
    <Panel
      {...rest}
      className={classNames(
        {
          'anim-pulse sk-on': status === FetchStatus.Fetching,
        },
        props.className,
      )}
    />
  );
};

export const FilterFields = ({ headers }: HeadersBaseProps) => {
  const { filterColumnKeys, status } = useEntityContext<IdEntity>();

  return (
    <>
      <div className="edit-table__header-row">
        {headers.map((h) => (
          <div key={h} className="edit-table__header">
            {h}
          </div>
        ))}
      </div>
      {filterColumnKeys.length > 0 ? (
        <EntityFilterRow
          className={status === FetchStatus.Fetching ? 'sk-on' : ''}
        />
      ) : null}
    </>
  );
};

export const AddFields = ({ headers }: HeadersBaseProps) => {
  const { addColumnKeys } = useEntityContext<IdEntity>();

  return (
    <>
      <div className="edit-table__header-row">
        {headers.map((h) => (
          <div key={h} className="edit-table__header">
            {h}
          </div>
        ))}
      </div>
      {addColumnKeys.length > 0 ? <EntityAddRow /> : null}
    </>
  );
};

const NO_ITEMS_MSG = 'There are no items here yet.';

export const EditFieldsList = <T extends ProjectDetails>({
  headers,
  singleItem,
  add,
  warningMessage,
  pagination,
}: EditListProps<T>) => {
  const {
    items: allItems,
    status,
    hasDeleteRow,
    toggleAll,
    handleToggleAll,
    editColumnKeys,
    sortState,
    handleSort,
    sortIcon,
    handleExportItems,
  } = useEntityContext<IdEntity>();

  const empty = !allItems.length && status === FetchStatus.Done;
  const hasSortingOption = useMemo(() => !!handleSort, [handleSort]);

  const rows = useTablePagination({ items: allItems, usePagination: pagination });

  return (
    <>
      {warningMessage && <WarningMessage warningMessage={warningMessage} />}
      <div className="edit-table__header-row">
        {hasSortingOption
          ? editColumnKeys.map((editColumn, idx) => (
              <div
                key={editColumn}
                className="edit-table__header"
                onClick={() => handleSort?.(editColumn)}
              >
                <span
                  className={classNames('sort-header', {
                    active: sortState?.field === editColumn,
                  })}
                >
                  {headers[idx]}
                </span>
                <SvgIcon iconName={sortIcon!} />
              </div>
            ))
          : headers.map((h) => (
              <div key={h} className="edit-table__header">
                {h}
              </div>
            ))}
      </div>
      {empty && <p className="enity-table__empty-msg">{NO_ITEMS_MSG}</p>}
      <ActionButtons
        toggleAll={toggleAll}
        handleExportItems={handleExportItems}
        handleToggleAll={handleToggleAll}
      />
      <div className="form-content thin-scrollbar">
        {!isEmpty(singleItem) && (
          <EntityEditRow
            item={singleItem as any}
            add={add}
            hasDeleteRow={hasDeleteRow}
          />
        )}
        {(!singleItem || isEmpty(singleItem)) &&
          rows.items.map((item) => {
            return (
              <EntityEditRow
                key={item.id}
                item={item}
                add={add}
                hasDeleteRow={hasDeleteRow}
              />
            );
          })}
      </div>
      {pagination && (
        <div className="edit-table__pagination">
          <PaginationBar
            currentPage={rows.page}
            totalCount={allItems.length}
            pageSize={PAGINATION_COUNT}
            siblingCount={5}
            onPageChange={rows.setPage}
          />
        </div>
      )}
    </>
  );
};

const ActionButtons = ({
  handleToggleAll,
  handleExportItems,
  toggleAll,
}: Pick<
  EntityInfo<IdEntity>,
  'handleExportItems' | 'handleToggleAll' | 'toggleAll'
>) => {
  const displayToggleAll = !!handleToggleAll;
  const displayExportItems = !!handleExportItems;

  if (!(displayToggleAll || displayExportItems)) {
    return null;
  }

  const toggleAllLabel = `${toggleAll ? 'Disable' : 'Enable'} all`;
  const downloadCn = classNames('dynamicfields__edit entity-table__action');

  return (
    <div className="switch-toggle-all">
      {toggleAllLabel}

      {displayToggleAll && (
        <ApToggleSwitch
          name="enabled"
          defaultChecked={!!toggleAll}
          id="switch-toggle-all"
          onChange={handleToggleAll}
        />
      )}

      {displayToggleAll && displayExportItems && (
        <span className="document-item__divider"></span>
      )}
      {displayExportItems && (
        <ApButtonIcon
          iconName="save_alt"
          className={downloadCn}
          type="button"
          onClick={handleExportItems}
        />
      )}
    </div>
  );
};

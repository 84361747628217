import { HelpStructure } from '../helpStructure';
import { HelpSearchItem } from '../types';

export const getHelpStructureAsList = (helpObj: HelpStructure) => {
  const resultList = Object.entries(helpObj).flatMap(([ModuleKey, modules]) => {
    return Object.entries(modules.topics).flatMap(([topicKey, topics]) => {
      return Object.entries(topics).flatMap(([articleKey, fileName]) => {
        return {
          module: ModuleKey,
          keys: [ModuleKey, topicKey, articleKey],
          file: fileName,
        } as HelpSearchItem;
      });
    });
  });

  return resultList;
};

import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { fetchReportLegalEntitiesThunk } from 'reducer/thunks/report-legal-entities-thunk';
import { FetchStatus } from 'types/fetch-status.types';

export function useReportLegalEntities() {
  const slice = useAppSelector((s) => s.reportLegalEntities);
  const { type } = slice;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === FetchStatus.Idle) {
      dispatch(fetchReportLegalEntitiesThunk());
    }
  }, [dispatch, type]);

  return slice;
}

export enum REPORT_TYPES_ENUM {
  Sofa = 'Sofa',
  Schedule = 'Schedule',
  Excel = 'Excel',
}

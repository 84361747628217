import { ICellRendererParams } from 'ag-grid-community';
import { ActionStatus, ClaimRow } from 'api/endpoints';
import classNames from 'classnames';
import { SectionType, SubsectionType } from '../types';
import { CellTextInput } from './CellTextInput';
import './MatchCodeCell.css';

export interface MatchCodeCellParams extends ICellRendererParams<ClaimRow, any> {
  section: SectionType;
  subsection: SubsectionType;
  onUpdateMatchCode: (
    id: number,
    matchCode: number,
    contract: boolean,
    cellProps: ICellRendererParams<ClaimRow, any>,
  ) => unknown;
  onFetchNextMatchCode: () => Promise<string>;
  onLostFocus: () => unknown;
}

export const MatchCodeCell = ({
  section,
  subsection,
  onUpdateMatchCode,
  onFetchNextMatchCode,
  onLostFocus,
  ...props
}: MatchCodeCellParams) => {
  const { data } = props;
  const key = data?.actionStatus;
  // const numberOfMatches = data?.numberOfMatches;
  // const notMatched = data?.notMatched;

  // we temporarily enable MatchCode editing for all tabs
  // after we refactor matching, we'll have to re-enable this logic

  /*
  const actionEditable =
    key && [ActionStatus.Categorized, ActionStatus.MatchingComplete].includes(key);
  const unmatchedClaimsSectionEditable =
    !numberOfMatches && notMatched && subsection === SubsectionType.UnmatchedClaims;
  const allClaimsSection = subsection === SubsectionType.AllClaims;
  const isMatching = section === SectionType.Matching;

  const matchCodeEditable =
    isMatching &&
    actionEditable &&
    (unmatchedClaimsSectionEditable || allClaimsSection);
  */

  // currently we're disabling this functionality
  const matchCodeEditable = false;

  if (matchCodeEditable) {
    return (
      <div className={'match-code-cell'}>
        <CellTextInput
          placeholder="Match code"
          value={`${data?.matchCode ?? ''}`}
          length={9}
          onChange={(v) =>
            data?.id && onUpdateMatchCode(data?.id, Number(v), false, props)
          }
          // editDisabled={true}
          regenerateEnabled
          onLostFocus={onLostFocus}
          getNextValue={onFetchNextMatchCode}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames('match-code-cell', {
        'matches-found': key === ActionStatus.MatchesFound,
        matched: key === ActionStatus.Matched,
        value: !!data?.matchCode,
      })}
    >
      {data?.matchCode ?? '- -'}
    </div>
  );
};

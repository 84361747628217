import classnames from 'classnames';
import { ComponentProps } from 'react';
import { createPortal } from 'react-dom';
import './ApLeftSliderModal.css';

interface SliderProps {
  noAnimation?: boolean;
  rootElement?: HTMLElement;
}

export const ApLeftSliderModal = ({
  className,
  noAnimation,
  children,
  rootElement,
  ...props
}: ComponentProps<'div'> & SliderProps) => {
  return createPortal(
    <div className={classnames('left-slider-modal', className)} {...props}>
      <div
        className={classnames('left-slider-modal__body', {
          'left-slider-modal--animation': !noAnimation,
        })}
      >
        {children}
      </div>
    </div>,
    rootElement ?? document.body,
  );
};

import { CategoryItem } from 'api/endpoints';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { SelectableData } from 'types/selectableDataTypes';
import { TAllowedEnums, TAllowedMajMin } from '../claimsBatchEdit.type';

export const mapCategories = (selectableData: SelectableData) => {
  const { categories } = selectableData || {};
  return {
    categories: categoriesMapper(categories),
  };
};

export const categoriesMapper = (
  arr: CategoryItem[] | undefined,
): TAllowedMajMin | undefined => {
  const groupedMajor = groupBy(arr, (r) => r.majorCategory);

  const minorMapper = (v: CategoryItem[]) =>
    v?.reduce(
      (a, v) => ({ ...a, [v.minorCategory]: v.id.toString() }),
      {} as TAllowedEnums,
    );
  const result = mapValues(groupedMajor, minorMapper);

  return result;
};

import { ReactNode } from 'react';
import './ClaimsPanel.css';

type Props = {
  children: ReactNode;
};

export const ClaimsPanel = ({ children }: Props) => {
  return <div className="claims-panel">{children}</div>;
};

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { downloadFileGet } from 'api/apiRequest';
import {
  MappingSummary,
  contractDocumentsApi,
  claimsDocumentsApi,
  SectionApi,
} from 'api/endpoints/documents';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  azureStorageUploadClaimImage,
  azureStorageUploadClaimImageMapping,
  azureStorageUploadClaimZipImage,
  azureStorageUploadContractImage,
  azureStorageUploadContractZipImage,
  azureStorageUploadContractZipImageMapping,
} from 'pages/DataUploaderPage/api/apiDataUploader';
import {
  resetState,
  setFileState,
  setStatusState,
  setUploadProgressState,
} from 'reducer/documentsReducer';
import { FileType, IUploadItem } from 'types/dataUploaderTypes';
import { error } from 'utils/alert';
import { showDownloadErrorResponse } from 'utils/excelExport';
import { downloadBlob } from 'utils/file';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';

const POLL_INTERVAL = 5000; // 5 seconds
const maxZipFileSizeContracts = 10000000000; // 10 GB for Contracts

type ValueType = 'imageType' | 'zipType' | 'mapType';

const SECTION_API_MAP: { [key: string]: SectionApi } = {
  [SectionType.Claims]: claimsDocumentsApi,
  [SectionType.Contracts]: contractDocumentsApi,
};

export const useDocuments = (
  section: SectionType.Claims | SectionType.Contracts,
  subsection: SubsectionType,
) => {
  const docsApi = useMemo(() => SECTION_API_MAP[section], [section]);
  const { fileState, statusState, uploadProgress } = useAppSelector(
    (s) => s.documents,
  );
  const [summary, setSummary] = useState<MappingSummary>({
    totalFiles: 0,
    mappedFiles: 0,
  });
  const dispatch = useAppDispatch();
  const [abortController, setAbortController] = useState<AbortController>();

  const intervalRef = useRef<NodeJS.Timeout>();

  // we use ref instead of state to persist value between re-renders
  // so we don't queue status API calls if prev one took longer than POLL_INTERVAL to complete
  const statusFetching = useRef(false);

  const loadSummary = useCallback(async () => {
    //loading summary only for active subsection
    if (subsection.indexOf(section) === -1) return;
    const resp = await docsApi.getSummary();
    if (resp) setSummary(resp);
  }, [docsApi, section, subsection]);

  useEffect(() => {
    loadSummary();
    return () => clearInterval(intervalRef.current);
  }, [loadSummary]);

  const resetUploadState = useCallback(() => {
    clearInterval(intervalRef?.current);
    dispatch(resetState());
  }, [dispatch]);

  const resetProgressState = useCallback(
    () =>
      dispatch(
        setUploadProgressState({
          progress: 0,
          currentItemUploading: 0,
        }),
      ),
    [dispatch],
  );

  const onProgress = useCallback(
    (item: IUploadItem, loaded: number, size: number, itemIndex?: number) => {
      item.uploadProgress = loaded / size;
      dispatch(
        setUploadProgressState({
          currentItemUploading: itemIndex ?? 0,
          progress: (loaded / size) * 100,
        }),
      );
    },
    [dispatch],
  );

  const getUnzipStatus = useCallback(async () => {
    if (statusFetching.current) return;

    statusFetching.current = true;
    const resp = await docsApi.getStatus();

    if (!resp || resp?.status === 'Failed') {
      resetUploadState();
      error('Unzipping failed. Please try again');
    }

    if (resp?.status === 'Completed') {
      clearInterval(intervalRef.current);
      resetProgressState();
      await loadSummary();
    }
    dispatch(
      setFileState({
        unzippingTotalFiles: resp?.totalFiles ?? 0,
        unzippingProcessedFiles: resp?.processedFiles ?? 0,
      }),
    );
    dispatch(
      setStatusState({
        docUnzipStatus: resp?.status,
      }),
    );
    statusFetching.current = false;
  }, [loadSummary, resetUploadState, dispatch, docsApi, resetProgressState]);

  const getMappingStatus = useCallback(async () => {
    if (statusFetching.current) return;

    statusFetching.current = true;
    const resp = await docsApi.getStatus();

    if (!resp || resp?.status === 'Failed') {
      error('Mapping failed. Please try again');
      resetUploadState();
    }
    if (resp?.status === 'Completed') {
      clearInterval(intervalRef.current);
      dispatch(
        setFileState({
          mappingFileUploading: false,
        }),
      );
      resetProgressState();
      await loadSummary();
    }

    dispatch(
      setStatusState({
        mappingStatus: resp?.status,
      }),
    );
    statusFetching.current = false;
  }, [loadSummary, resetUploadState, dispatch, docsApi, resetProgressState]);

  const handleUploadZipDocs = useCallback(
    async (files: File[]) => {
      resetUploadState();
      if (files.some((file) => file.size > maxZipFileSizeContracts)) {
        error('Please select a file with a maximum size of 10 GB (10000 MB)');
        return;
      }
      dispatch(
        setFileState({
          zipFilesUploading: files.length,
        }),
      );
      const type = getFileTypeBySection('zipType', section);

      const docs = files.map(
        (file) =>
          ({
            displayName: file.name,
            file,
            type,
          } as IUploadItem),
      );
      const abort = new AbortController();
      setAbortController(abort);
      try {
        if (section === SectionType.Contracts) {
          await azureStorageUploadContractZipImage(docs, abort.signal, onProgress);
        } else if (section === SectionType.Claims) {
          await azureStorageUploadClaimZipImage(docs, abort.signal, onProgress);
        }
        dispatch(
          setFileState({
            unzipping: true,
          }),
        );

        intervalRef.current = setInterval(getUnzipStatus, POLL_INTERVAL);
      } catch (err) {
        // work-around to avoid app crashing from the Abort signal
        error('Upload canceled');
        clearInterval(intervalRef.current);
        if (error.name === 'AbortError') return;
      }
      setAbortController(undefined);
    },
    [getUnzipStatus, dispatch, onProgress, resetUploadState, section],
  );

  const handleCancelUpload = useCallback(() => {
    abortController?.abort();
    resetUploadState();
  }, [abortController, resetUploadState]);

  const handleUploadDocs = useCallback(
    async (files: File[]) => {
      resetUploadState();
      const type = getFileTypeBySection('imageType', section);
      dispatch(
        setFileState({
          imageFilesUploading: files.length,
        }),
      );
      const docs = files.map(
        (file) =>
          ({
            displayName: file.name,
            file,
            type,
          } as IUploadItem),
      );
      const abort = new AbortController();
      setAbortController(abort);
      try {
        if (section === SectionType.Contracts) {
          await azureStorageUploadContractImage(docs, abort.signal, onProgress);
        } else if (section === SectionType.Claims) {
          await azureStorageUploadClaimImage(docs, abort.signal, onProgress);
        }
        dispatch(
          setStatusState({
            docUnzipStatus: 'Completed',
          }),
        );
        resetProgressState();
        loadSummary();
      } catch (err) {
        error('Upload canceled');
        if (error.name === 'AbortError') return;
      }
    },
    [
      loadSummary,
      dispatch,
      onProgress,
      section,
      resetUploadState,
      resetProgressState,
    ],
  );

  const downloadReport = useCallback(
    async ({
      url,
      fileName,
      resetUploadDetails,
    }: {
      url: string;
      fileName: string;
      resetUploadDetails?: boolean;
    }) => {
      const response = await downloadFileGet(url);
      if (response.type === 'ok') {
        const FILENAME = `${fileName}-${new Date().toISOString()}.xlsx`;
        downloadBlob(response.data as unknown as Blob, FILENAME);
        if (resetUploadDetails) resetUploadState();
      } else showDownloadErrorResponse(response);
    },
    [resetUploadState],
  );

  const handleUploadZipMapping = useCallback(
    async (files: File[]) => {
      const type = getFileTypeBySection('mapType', section);

      resetUploadState();
      const docs = files.map(
        (file) =>
          ({
            displayName: file.name,
            file,
            type,
          } as IUploadItem),
      );

      const abort = new AbortController();
      setAbortController(abort);
      try {
        dispatch(
          setFileState({
            mappingFileUploading: true,
          }),
        );
        if (section === SectionType.Contracts) {
          await azureStorageUploadContractZipImageMapping(
            docs,
            abort.signal,
            onProgress,
          );
        } else if (section === SectionType.Claims) {
          await azureStorageUploadClaimImageMapping(docs, abort.signal, onProgress);
        }
        intervalRef.current = setInterval(getMappingStatus, POLL_INTERVAL);
      } catch (err) {
        // work-around to avoid app crashing from the Abort signal
        error('Upload canceled');
        clearInterval(intervalRef.current);
        if (error.name === 'AbortError') return;
      }
      setAbortController(undefined);
    },
    [getMappingStatus, dispatch, onProgress, section, resetUploadState],
  );

  const HELP_STEPS = useMemo(
    () => [
      {
        title: 'Upload documents',
        content:
          'On this page click the “Browse” or “Browse zip files” button and add/drag your files there.',
      },
      {
        title: 'Map documents',
        content: `Export the documents mapped excel file or use the mapping template,
            update it and upload it in the “Map ${section} documents” section.`,
      },
      {
        title: `View documents in the ${section} Page`,
        content: `Once the mapping file has been uploaded the documents should be visible
            on the ${section.slice(0, section.length - 1)} single page, under
            the Documents tab.`,
      },
    ],
    [section],
  );

  return {
    handleUploadZipDocs,
    handleUploadDocs,
    handleCancelUpload,
    handleUploadZipMapping,
    downloadReport,
    summary,
    resetUploadState,
    statusState,
    fileState,
    uploadProgress,
    helpSteps: HELP_STEPS,
  };
};

const getFileTypeBySection = (
  type: ValueType,
  section: SectionType.Claims | SectionType.Contracts,
) => {
  if (section === SectionType.Claims) {
    switch (type) {
      case 'imageType':
        return FileType.ClaimImage;
      case 'zipType':
        return FileType.ClaimImageZip;
      default:
        return FileType.ClaimImageMap;
    }
  }
  if (section === SectionType.Contracts) {
    switch (type) {
      case 'imageType':
        return FileType.ContractImage;
      case 'zipType':
        return FileType.ContractImageZip;
      default:
        return FileType.ContractImageZipMap;
    }
  }
};

export const URL_PATH = {
  INDEX: '/',
  HOME: '/module-selector',
  BOOKMARKS: '/bookmarks',
  CLAIMS: '/claims',
  CLAIMS_SETTLEMENT: '/claims-settlement',
  DATA_UPLOAD: '/data-upload',
  EXHIBITS_CLAIMS: '/exhibit-exporter-claim',
  EXHIBITS_CONTRACTS: '/exhibit-exporter-contract',
  HELP: '/help',
  SOFAS_SCHEDULES: '/sofas-schedules',
  SETTINGS: '/settings',
  SETUP_WIZARD: '/setupwizard',
  CONTACT_US: '/contact-us',
  VENDORS: '/vendors',
};

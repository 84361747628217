import { UserRole } from 'utils/AppInitializer/application-user';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';

export interface RolePermissionProps {
  roles: UserRole[];
  children?: React.ReactNode;
}
/*
TransactionApprover: 'TransactionApprover',
  TransactionProposer: 'TransactionProposer',
  ClientApprover: 'ClientApprover',
  ClientProposer: 'ClientProposer',
  */

export const RolePermission = ({ roles, children }: RolePermissionProps) => {
  const { data: applicationUser } = useApplicationUser();
  if (!children) return null;
  if (!applicationUser) return null;
  if (!applicationUser.roles) return null;
  if (applicationUser.roles.some((role) => roles.includes(role))) {
    return <>{children}</>;
  }

  return null;
};

import './BookmarksFilter.css';
import Select from 'components/UIComponents/Select';
import { useMemo } from 'react';

export const BookmarksFilter = ({
  bookmarksData: {
    bookmarksCount,
    claimsBookmarksCount,
    contractsBookmarksCount,
    myBookmarksCount,
    allBookmarksCount,
  },
  handleSelectChange,
}: {
  bookmarksData: {
    bookmarksCount: number;
    claimsBookmarksCount: number;
    contractsBookmarksCount: number;
    myBookmarksCount: number;
    allBookmarksCount: number;
  };
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const options = useMemo(() => {
    return [
      {
        label: `All bookmarks (${allBookmarksCount})`,
        value: 'all',
      },
      {
        label: `My bookmarks (${myBookmarksCount})`,
        value: 'myBookmarks',
      },
      {
        label: `Claims bookmarks (${claimsBookmarksCount})`,
        value: 'Claims',
      },
      {
        label: `Contracts bookmarks (${contractsBookmarksCount})`,
        value: 'Contracts',
      },
    ];
  }, [
    claimsBookmarksCount,
    contractsBookmarksCount,
    myBookmarksCount,
    allBookmarksCount,
  ]);
  return (
    <section className="bookmarks-page__filter">
      <h3>{bookmarksCount} Bookmarks</h3>
      <Select options={options} onChange={handleSelectChange}></Select>
    </section>
  );
};

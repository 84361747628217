import { MenuItem } from 'components/DisclosureLinkMenu/DisclosureLinkMenu.types';
import { ENV } from 'env-variable';
import { ActionNames, ContractsActionNames } from './sections/ActionNames';
import { CasesAndEntities } from './sections/CasesEntities/CasesEntities';
import { ClaimsCategories } from './sections/Categories/claims/ClaimCategories';
import { SofasSchedulesSettings } from './sections/SofasSchedules/SofasSchedulesSettings';
import { ContractsCategories } from './sections/Categories/contracts/ContractsCategories';
import { ClientNameLogo } from './sections/ClientNameLogo';
import { DeleteData } from './sections/DeleteData/DeleteData';
import { ContractDynamicFields, DynamicFields } from './sections/DynamicFields';
import { SettlementsAgreementTemplates } from './sections/SettlementsAgreementTemplates/SettlementsAgreementTemplates';
import { SettlementsUserManagement } from './sections/SettlementsUserManagement/SettlementsUserManagement';

import { ContractUserGroups, UserGroups } from './sections/UserGroups';
import { SettingsMenuItem } from './types/SettingsPage.types';
import { UserLogs } from './Users/UserLogs';

export const settingsPageItems: MenuItem[] = [
  {
    id: SettingsMenuItem.Claims,
    label: 'Claims',
    type: 'nested',
    isButton: true,
    subItems: [
      {
        id: 'claims-user-groups',
        label: 'User group management',
        component: <UserGroups />,
      },
      {
        id: 'claims-dynamic-fields',
        label: 'Dynamic fields',
        component: <DynamicFields />,
      },
      {
        id: 'claims-resolution-names',
        label: 'Resolution Names',
        component: <ActionNames />,
      },
      {
        id: 'claims-categories',
        label: 'Categories',
        component: <ClaimsCategories />,
      },
    ],
  },
  {
    id: SettingsMenuItem.Contracts,
    label: 'Contracts',
    type: 'nested',
    isButton: true,
    subItems: [
      {
        id: 'contracts-user-groups',
        label: 'User group management',
        component: <ContractUserGroups />,
      },
      {
        id: 'contracts-dynamic-fields',
        label: 'Dynamic fields',
        component: <ContractDynamicFields />,
      },
      {
        id: 'contracts-resolution-names',
        label: 'Resolution Names',
        component: <ContractsActionNames />,
      },
      {
        id: 'contracts-categories',
        label: 'Categories',
        component: <ContractsCategories />,
      },
    ],
  },
  {
    id: SettingsMenuItem.Settlements,
    label: 'Settlements',
    type: 'nested',
    isButton: true,
    baseLink: '',
    hidden: !ENV.deploySettlements,
    subItems: [
      {
        id: 'settlements-templates',
        label: 'Agreement Templates',
        component: <SettlementsAgreementTemplates />,
        link: '',
      },
      {
        id: 'settlements-users',
        label: 'User Management',
        component: <SettlementsUserManagement />,
        link: '',
      },
    ],
  },
  {
    id: SettingsMenuItem.CasesEntities,
    label: 'Cases & Entities',
    type: 'direct',
    isButton: true,
    component: <CasesAndEntities />,
  },
  {
    id: SettingsMenuItem.ClientNameLogo,
    label: 'Client name and logo',
    type: 'direct',
    isButton: true,
    component: <ClientNameLogo />,
  },
  {
    id: SettingsMenuItem.SofasSettings,
    label: 'SOFAs and Schedules',
    type: 'direct',
    isButton: true,
    component: <SofasSchedulesSettings />,
  },
  {
    id: SettingsMenuItem.DeleteData,
    label: 'Delete data',
    type: 'direct',
    isButton: true,
    component: <DeleteData />,
  },
  {
    id: SettingsMenuItem.Users,
    label: 'Users',
    type: 'direct',
    isButton: true,
    component: <UserLogs />,
  },
];

import React, { useEffect, useRef } from 'react';
import SignaturePad from 'signature_pad';

const canvasW = 540;
const canvasH = 210;
const varStyle = {
  '--canvasW': `${canvasW}px`,
  '--canvasH': `${canvasH}px`,
} as React.CSSProperties;

const y = Math.ceil(0.75 * canvasH);
const lineStart = { x: 20, y: y };
const lineEnd = { x: canvasW - 20, y: y };

export function SignatoriesDraw() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const padRef = useRef<{ pad: SignaturePad } | null>(null);

  useEffect(() => {
    const canv = canvasRef.current;
    const ctx = canv?.getContext('2d');
    if (ctx && canv) {
      const signaturePad = new SignaturePad(canv);
      signaturePad.penColor = '#5CB335';
      if (padRef.current?.pad) {
        padRef.current.pad = signaturePad;
      }
      if (ctx.setLineDash !== undefined) {
        ctx.setLineDash([2, 4]);
      }
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#737373';
      ctx.moveTo(lineStart.x, lineStart.y);
      // ctx.lineTo(250, 75);
      ctx.lineTo(lineEnd.x, lineEnd.y);
      ctx.stroke();
    }
  }, []);
  return (
    <div style={varStyle} className="signatories-draw">
      <canvas width={canvasW} height={canvasH} ref={canvasRef}>
        ds
      </canvas>
    </div>
  );
}

export const SOFA_5_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['10'],
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },

    {
      dataType: 'decimal',
      displayName: 'Amount of Payments Received',
      propertyName: 'amount',
    },
    {
      dataType: 'string',
      displayName: 'Property Description',
      propertyName: 'propertyDescription',
    },
    {
      dataType: 'string',
      displayName: 'Date of Loss',
      propertyName: 'dateOfLoss',
    },
    {
      dataType: 'string',
      displayName: 'How Loss Occurred',
      propertyName: 'howLossOccurred',
    },
    {
      dataType: 'decimal',
      displayName: 'Property Value',
      propertyName: 'propertyValue',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },

    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity ID',
      propertyName: 'legalEntityId',
    },
  ],
};

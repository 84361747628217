import { useCounterpartiesAndDebtors } from '../SingleContractOverview/hook/useCounterpartiesAndDebtors';
import { CounterpartiesAndDebtorsTable } from './CounterpartiesAndDebtorsTable';
import { CounterpartiesDebtorsRow } from 'pages/SingleContractPage/components/CounterpartiesDebtorsTableRow';

export type Debtor = 'debtor' | 'counterparty';

interface Props {
  debtors: Debtor;
  contractId: number;
  contractRef: string;
}

export const SingleContractCounterpartiesAndDebtorsTable = ({
  debtors,
  contractId,
  contractRef,
}: Props) => {
  const { items, loading, handleDelete, handlePrimaryToggle } =
    useCounterpartiesAndDebtors(contractId, debtors);

  return (
    <CounterpartiesAndDebtorsTable
      debtors={debtors}
      showLoader={loading}
      emptyListMessage={<span>{`No ${debtors} data for this contract`}</span>}
    >
      {items?.map((item) => {
        return (
          <CounterpartiesDebtorsRow
            key={item.id}
            item={item}
            debtors={debtors}
            contractId={contractRef}
            handleDelete={handleDelete}
            handlePrimaryToggle={handlePrimaryToggle}
          />
        );
      })}
    </CounterpartiesAndDebtorsTable>
  );
};

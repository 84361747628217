import { jsonFetchGet, jsonFetchPut } from 'api/jsonFetch/authJsonFetch';
import { mapOk } from 'api/jsonFetch/jsonFetch';
import { CommonErrorBody } from './types';
import { SectionType } from '../../pages/ClaimsContractsToolPage/types';
import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';

export enum DynamicFieldType {
  Text = 'string',
  Numeric = 'decimal',
  Boolean = 'bool',
  Date = 'datetime',
  Hyperlink = 'hyperlink',
}

export type DynamicFieldFilter = DynamicFieldType | 'all';

export type DynamicField = {
  id: number;
  type: DynamicFieldType;
  name: string;
  enabled: boolean;
  placeholder?: string;
  propertyName: string;
  displayIn: PageSections;
};

export type ServerDynamicField = {
  id: number;
  dataType: DynamicFieldType;
  displayName: string;
  propertyName: string;
  enabled: boolean;
  displayIn: PageSections;
};

export const labelByFieldType: Record<DynamicFieldType, string> = {
  [DynamicFieldType.Boolean]: 'Boolean',
  [DynamicFieldType.Numeric]: 'Numeric',
  [DynamicFieldType.Text]: 'Text',
  [DynamicFieldType.Date]: 'Date',
  [DynamicFieldType.Hyperlink]: 'Hyperlink',
};

const lastDigitsRgx = /\d+$/;

const extractPlaceholder = (f: ServerDynamicField): string | undefined => {
  const { propertyName } = f;
  if (propertyName.startsWith('custom')) {
    const matches = propertyName.match(lastDigitsRgx);
    if (matches) {
      const suffix = matches[0];
      return `Dynamic ${labelByFieldType[f.dataType]} ${suffix}`;
    }
  }
  return undefined;
};

const serverToApp = (f: ServerDynamicField): DynamicField => ({
  id: f.id,
  name: f.displayName,
  enabled: f.enabled,
  type: f.dataType,
  propertyName: f.propertyName,
  placeholder: extractPlaceholder(f),
  displayIn: f.displayIn,
});

export type PutParams = {
  id: number;
  name: string;
  enabled: boolean;
  displayIn: PageSections;
  type: DynamicFieldType;
};

export type BadRequestError = {
  status: 400;
  error: CommonErrorBody[];
};

export const dynamicFields = {
  get: async (section: SectionType) => {
    const url =
      section === SectionType.Contracts
        ? `/v1/Configuration/grid-columns/contract`
        : `/v1/Configuration/grid-columns`;
    const serverResponse = await jsonFetchGet<ServerDynamicField[], BadRequestError>(
      url,
    );
    // Temp filter until BE gives a proper `custom` flag. TODO remove after
    return mapOk(serverResponse, (fields) =>
      fields.filter((f) => f.propertyName?.startsWith(`custom`)).map(serverToApp),
    );
  },
  put: async (params: PutParams, section: SectionType) => {
    const url =
      section === SectionType.Contracts
        ? `/v1/Configuration/custom-columns/contract`
        : `/v1/Configuration/custom-columns`;
    const serverResponse = await jsonFetchPut<ServerDynamicField[], BadRequestError>(
      url,
      [
        {
          id: params.id,
          enabled: params.enabled,
          displayName: params.name,
          displayIn: params.displayIn,
        },
      ],
    );
    const apResp = mapOk(serverResponse, (fields) => fields.map(serverToApp)[0]);
    return apResp;
  },
};

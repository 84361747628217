import { useEffect, useMemo, useRef, useState } from 'react';
import { MultiSelectLabel } from './components/MultiSelectLabel';
import { OptionsList } from './components/OptionsList';

import { ApSimpleSearch } from '@alixpartners/ui-components';
import classnames from 'classnames';
import { ApMenuStyled } from './MultiSelectExtendable.styles';
import { IOption, ItemRenderer, LabelRenderer } from './MultiSelectExtendable.types';

const DEFAULT_LABEL = 'Select an option';
const SELECT_ALL_VALUE = 'selectAll';

export interface IMultiSelectProps {
  className?: string;
  menuClassName?: string;
  options: IOption[];
  selected?: IOption[]; //todo: selected items should be array of IOption.values: "selected?: string[]""
  renderLabel?: string | LabelRenderer;
  disabled?: boolean;
  multiple?: boolean;
  searchEnabled?: boolean;
  maxSelectedItems?: number;
  searchFilter?: (o: IOption, text: string) => boolean;
  isSelectable?: (o: IOption) => boolean;
  itemRenderer?: ItemRenderer;
  onChange: (option: IOption[]) => void;
  selectAll?: boolean;
  hideFooter?: boolean;
}

export const MultiSelectExtendable = ({
  options,
  selected,
  disabled,
  renderLabel,
  multiple = true,
  searchEnabled,
  maxSelectedItems,
  className,
  menuClassName,
  searchFilter,
  itemRenderer,
  isSelectable,
  onChange,
  selectAll,
  hideFooter,
}: IMultiSelectProps) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const filteredOptions = useMemo(() => {
    const newOptions: IOption[] = selectAll
      ? [{ value: SELECT_ALL_VALUE, label: 'Select All' }, ...options]
      : options;
    const text = searchText.trim().toLowerCase();

    if (!searchEnabled || !text) return newOptions;

    if (searchFilter) return newOptions.filter((o) => searchFilter(o, text));

    return filterItemsByText(newOptions, text) ?? [];
  }, [selectAll, options, searchText, searchEnabled, searchFilter]);

  const selectedOptionsCount = selected?.length ?? 0;
  const maxItemsExceeded =
    !!maxSelectedItems && selectedOptionsCount >= maxSelectedItems;

  const handleOptionSelect = (newSelectedOption: IOption) => {
    if (isSelectable && !isSelectable(newSelectedOption)) return;
    if (!selected || !multiple) {
      onChange([newSelectedOption]);
      return;
    }

    if (newSelectedOption.value === SELECT_ALL_VALUE) {
      if (isSelectAllChecked) {
        onChange([]);
        setIsSelectAllChecked(false);
      } else {
        onChange(options);
        setIsSelectAllChecked(true);
      }

      return;
    }

    const newSelected = [...selected];

    const index = newSelected.findIndex(
      (option: IOption) => option.value === newSelectedOption.value,
    );

    if (index >= 0) {
      newSelected.splice(index, 1);
    } else if (!maxItemsExceeded) {
      newSelected.push(newSelectedOption);
    }
    onChange(newSelected);
  };

  useEffect(() => {
    if (!isOpen) return;
    setIsSelectAllChecked(options.length > 0 && selected?.length === options.length);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpen) return;
    setSearchText('');

    if (!searchEnabled) return;
    // Unfortunately, using ref on ApSimpleSearch is not
    // working, we we have to manually get find the input
    const searchInput = searchRef.current?.getElementsByClassName(
      'ap-simple-search',
    )?.[0] as HTMLInputElement;
    searchInput?.focus();
  }, [isOpen, searchEnabled, searchRef]);

  const handleClearSelection = () => {
    onChange([]);
    setIsSelectAllChecked(false);
  };

  const handleApllySelection = () => {
    if (selected) {
      onChange(selected);
      setIsOpen(false);
    }
  };

  return (
    <div
      className={classnames('multiselect--container', className, {
        'multiselect--container-shadow': isOpen,
        'multiselect--is-open': isOpen,
      })}
      ref={ref}
    >
      <ApMenuStyled
        isOpen={isOpen}
        className={classnames('multiselect--menu', menuClassName)}
        compact={false}
        placement="bottom-start"
        width={ref.current?.clientWidth}
        button={(props: any) => {
          if (typeof renderLabel === 'function') {
            return renderLabel(selected);
          }
          const selectedLabel =
            selected && selected.length
              ? selected.map((option: IOption) => option.label).join(', ')
              : DEFAULT_LABEL;
          const label = renderLabel ? renderLabel : selectedLabel;
          return <MultiSelectLabel {...props} text={label} disabled={disabled} />;
        }}
        toggleMenu={setIsOpen}
      >
        {searchEnabled && (
          <div className="multiselect--search" ref={searchRef}>
            <ApSimpleSearch
              id="id-multiselect-search-filed"
              value={searchText}
              placeholder="Search"
              //Bug in the ApSimpleSearch component. Have to use 'any' type instead of 'string'
              onChange={(e: any) => setSearchText(e)}
              onClear={() => setSearchText('')}
            />
          </div>
        )}
        <OptionsList
          options={filteredOptions}
          multiple={multiple}
          selected={selected}
          maxItemsExceeded={maxItemsExceeded}
          isSelectable={isSelectable}
          itemRenderer={itemRenderer}
          onChange={handleOptionSelect}
          isSelectAllChecked={isSelectAllChecked}
          // footer options
          clearSelection={handleClearSelection}
          applySelection={handleApllySelection}
          disabled={!selectedOptionsCount}
          hideFooter={hideFooter}
        />
      </ApMenuStyled>
    </div>
  );
};

const filterItemsByText = (
  options: IOption[] | undefined,
  text: string,
): IOption[] | undefined => {
  if (!options) return undefined;
  return options.filter((r) => r.label.toLowerCase().includes(text));
};

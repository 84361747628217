import { ApButtonGroup, ApModal } from '@alixpartners/ui-components';
import { ApButtonMainEx, ApButtonRed } from 'components/UIComponents/Buttons';
import { useState } from 'react';

import './ConfirmRedModal.css';

interface Props {
  title: React.ReactNode;
  message: React.ReactNode;
  disabled?: boolean;
  btnCancel?: string;
  btnConfirm?: string;
  onConfirm: (confirmed: boolean) => Promise<void>;
}

export const ConfirmRedModal = ({
  title,
  message,
  disabled,
  btnCancel = 'Cancel',
  btnConfirm = 'Yes',
  onConfirm,
}: Props) => {
  const [blockButton, setBlockButton] = useState(false);

  const handleConfirm = async (value: boolean) => {
    setBlockButton(true);
    await onConfirm(value);
    setBlockButton(false);
  };

  const disableButton = disabled || blockButton;

  return (
    <ApModal
      isDismissible={false}
      isFluid={false}
      isOpen={true}
      zIndex={998}
      hasClosed={() => handleConfirm(false)}
      footer={() => (
        <ApButtonGroup
          className="confirm-red-modal__footer"
          justifyContent="space-between"
        >
          <ApButtonMainEx
            onClick={() => handleConfirm(false)}
            disabled={disableButton}
          >
            {btnCancel}
          </ApButtonMainEx>
          <ApButtonRed onClick={() => handleConfirm(true)} disabled={disableButton}>
            {btnConfirm}
          </ApButtonRed>
        </ApButtonGroup>
      )}
    >
      <h3 className="confirm-red-modal__title">{title}</h3>
      <div>{message}</div>
    </ApModal>
  );
};

export const SCHEDULE_AB_3_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Over 90 Days',
      propertyName: 'over90Days',
    },
    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: 'Face Amount',
      propertyName: 'faceAmount',
    },
    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: 'Doubtful or Uncollecatble Accounts',
      propertyName: 'doubtfulOrUncollectableAccounts',
    },
    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: 'Value',
      propertyName: 'value',
    },
    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

import { ExportPdfResponse, exportPdf_finished } from 'api/endpoints/exportPdf';
import { ApButtonMainEx } from 'components/UIComponents/Buttons';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { LinkText } from 'components/UIComponents/LinkText';
import { ProgressMeter } from 'components/UIComponents/ProgressMeter';
import { isNil } from 'lodash';
import { ExportCancelConfirmModal } from 'pages/SOFAsAndSchedules/Components/ExportCancelConfirmModal';
import { useMemo, useState } from 'react';
import './SofasExportLoading.css';

interface Props {
  exportStatus?: ExportPdfResponse;
  onDownload?: (status?: ExportPdfResponse) => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const SofasExportLoading = ({
  exportStatus,
  onDownload,
  onClose,
  onCancel,
}: Props) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(false);

  const exportCompleted = exportPdf_finished.includes(exportStatus?.exportStatus!);

  const handleWarningConfirm = async (value: boolean) => {
    if (value) {
      onCancel?.();
      setCancelDisabled(true);
    }
    setConfirmDialog(false);
  };

  const cancelButtonName = exportCompleted ? 'Close' : 'Cancel';
  const handleCloseClick = () =>
    exportCompleted ? onClose?.() : setConfirmDialog(true);

  return (
    <div className="sofas-export-loading">
      <DivWithLoader
        loading={!exportCompleted}
        className="sofas-schedules-panel__loading-screen"
      >
        {exportCompleted ? (
          <FileIsReadyMessage onDownload={() => onDownload?.(exportStatus)} />
        ) : (
          <ExportProgressPanel
            processedFiles={exportStatus?.processedFiles}
            totalFiles={exportStatus?.totalFiles}
          />
        )}
      </DivWithLoader>
      <div className="sofas-export-loading__bottom-panel">
        <ApButtonMainEx disabled={cancelDisabled} onClick={handleCloseClick}>
          {cancelButtonName}
        </ApButtonMainEx>
      </div>
      {confirmDialog && (
        <ExportCancelConfirmModal
          title="Cancel export?"
          onConfirm={handleWarningConfirm}
        />
      )}
    </div>
  );
};

const FileIsReadyMessage = (props: { onDownload: () => void }) => {
  return (
    <div className="sofas-export__file-ready">
      <div className="sofas-export__file-ready__icon" />
      <div className="sofas-export__file-ready__text">
        <h5>File is ready!</h5>
        <span>
          If your download didn't start automatically click{' '}
          <LinkText onClick={props.onDownload}>here</LinkText>
        </span>
      </div>
    </div>
  );
};

const ExportProgressPanel = (props: {
  processedFiles: number | undefined;
  totalFiles: number | undefined;
}) => {
  const { processedFiles, totalFiles } = props;

  const progressPercentage = useMemo(() => {
    if (isNil(processedFiles) || isNil(totalFiles)) return 0;

    //A small lifehack for better user experience. (Not sure if this is a good idea though)
    const progressFraction = (processedFiles + 0.15) / (totalFiles + 0.15);
    return 100 * progressFraction;
  }, [processedFiles, totalFiles]);

  return (
    <>
      <div className="sofas-export-loading__title">
        We are exporting your report, this may take several minutes.
      </div>
      <div className="sofas-export-loading__loader">
        <div className="sofas-export-loading__loader-text">
          <span>Processed files: {processedFiles ?? ''}</span>
          <span>Total files: {totalFiles ?? ''}</span>
        </div>

        <ProgressMeter percentage={progressPercentage} />
      </div>
    </>
  );
};

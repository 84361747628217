import upperFirst from 'lodash/upperFirst';

export const USERS_CAPTION = ['user', 'users'];
export const CATEGORY_CAPTION = ['category', 'categories'];

export const getSelectedUserLabel = (list: any[] | undefined) =>
  getSelectedLabelText(list?.length, USERS_CAPTION);

export const getSelectedCategoryLabel = (list: any[] | undefined) =>
  getSelectedLabelText(list?.length, CATEGORY_CAPTION);

export const getSelectedLabelText = (
  count: number | undefined,
  middle?: string[],
) => {
  if (!count) return '';

  let middleText = '';
  if (middle) {
    middleText = count > 1 ? middle[1] : middle[0];
    middleText = upperFirst(middleText);
  }

  return `${count} ${middleText} Selected`;
};

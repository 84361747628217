import { downloadFile, downloadFileGet, get, post, put } from '../apiRequest';

export type SettlementId = number;

export type SettlementStatus =
  | 'SettlementPending'
  | 'AwaitingClientApproval'
  | 'AwaitingCounterpartyReview'
  | 'ViewedByCounterparty'
  | 'SignedByCounterparty'
  | 'DeclinedByCounterparty'
  | 'SettlementExecuted'
  | 'SettlementResolved'
  | 'SettlementPaid';

export interface ClaimValue {
  claimId: number;
  name: string;
  proposedSecured: number;
  proposedAdministrative: number;
  proposedPriority: number;
  proposedUnsecured: number;
  proposedTotal: number;
  currentSecured: number;
  currentAdministrative: number;
  currentPriority: number;
  currentUnsecured: number;
  currentTotal: number;
}

export interface SingleSettlementResponse {
  id: SettlementId;
  settlementNumber: number;
  statusId: number;
  status: SettlementStatus;
  statusChanged: string;
  counterpartyName: string;
  counterpartyEmail: string | null;
  counterpartyPhone: string | null;
  counterpartyCountry: string | null;
  counterpartyState: string | null;
  counterpartyCity: string | null;
  counterpartyZip: string | null;
  counterpartyAddress: string | null;
  notes: string | null;
  claims: ClaimValue[];
  claimsTotal: ClaimValue;
  agreementFileName: string | null;
}

export interface SettlementDocumentGet {
  id: number;
  settlementId: number;
  documentName: string;
  displayName: string;
  settlementDocumentTypeId: number;
  docketNumber: string;
  createdBy: string;
  createdDate: Date;
}

export type SettlementDocumentPost = Pick<
  SettlementDocumentGet,
  'settlementDocumentTypeId' | 'documentName' | 'displayName' | 'docketNumber'
>;

export interface SettlementStatusResponse {
  id: number;
  status: string;
  description: string;
}

export type SettlementDataPut = Pick<
  SingleSettlementResponse,
  | 'id'
  | 'counterpartyName'
  | 'counterpartyEmail'
  | 'counterpartyPhone'
  | 'counterpartyCountry'
  | 'counterpartyState'
  | 'counterpartyCity'
  | 'counterpartyZip'
  | 'counterpartyAddress'
  | 'notes'
> & {
  settlementUsersAssignment: number[];
};

export type SettlementGenerateAgreementResponse = Pick<
  SingleSettlementResponse,
  'agreementFileName'
>;

export interface SettlementDocumentType {
  id: number;
  type: string;
  description: string;
}

export const settlements = {
  get: (id: number) => get<SingleSettlementResponse>(`v1/Settlements/${id}`),
  put: (id: number, data: SettlementDataPut, regenerate?: boolean) => {
    const regenParam = regenerate ? '?regenerateAgeement=true' : '';
    return put<{}>(`v1/Settlements/${id}${regenParam}`, data);
  },
  agreemantGenerate: (id: number) =>
    post<SettlementGenerateAgreementResponse>(
      `v1/Settlements/${id}/agreement/generate`,
    ),
  agreemantDownload: (id: number) =>
    downloadFileGet(`v1/Settlements/${id}/agreement/download`),

  statuses: () => get<SettlementStatusResponse[]>('v1/Settlements/statuses'),

  documentTypes: () => get<SettlementDocumentType[]>('v1/Settlements/documenttypes'),

  getDocuments: (id: number) =>
    get<SettlementDocumentGet[]>(`v1/Settlements/${id}/documents`),
  postDocuments: (id: number, params: SettlementDocumentPost[]) =>
    post<SettlementDocumentPost>(`v1/Settlements/${id}/documents`, params),
  downloadDocument: (id: number, documentId: number, fileName: string) =>
    downloadFile(`v1/Settlements/${id}/documents/${documentId}/download`, fileName),
};

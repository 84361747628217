import { ReactChild } from 'react';
import './SofaCard.css';

interface Props {
  stepId: number;
  title: string;
  caption: ReactChild;
  footer?: JSX.Element;
}

export const SofaCard = ({ stepId, title, caption, footer }: Props) => (
  <div className="sofacard">
    <section>
      <div className="sofacard__body">
        <h3>{title}</h3>
        <p> {caption} </p>
      </div>
      <div className="sofacard__step">
        <SofaCardStepLabel value={`Step ${stepId}`} />
      </div>
    </section>
    {footer && <footer>{footer}</footer>}
  </div>
);

const SofaCardStepLabel = (props: { value: string }) => (
  <div className="sofacard__step-label">{props.value}</div>
);

import {
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';

export type FilterType = 'text' | 'number' | 'date';
export type TextOperator =
  | 'contains'
  | 'notContains'
  | 'equals'
  | 'notEqual'
  | 'startsWith'
  | 'endsWith';
export type NumberOperator =
  | 'equals'
  | 'notContains'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'inRange';
export type DateOperator =
  | 'equals'
  | 'notEqual'
  | 'lessThan'
  | 'greaterThan'
  | 'inRange';

/** Text Filter */

export function isTextFilterMulti(filter: TextFilterBase) {
  if ((filter as TextFilterMulti).condition1) {
    return true;
  }
  return false;
}
export interface TextFilterBase {
  filterType: 'text';
}
export interface TextFilterMulti extends TextFilterBase {
  operator: 'AND' | 'OR';
  condition1: {
    filterType: 'text';
    type: TextOperator;
    filter: string;
  };
  condition2: {
    filterType: 'text';
    type: TextOperator;
    filter: string;
  };
}
export interface TextFilterOne extends TextFilterBase {
  type: TextOperator;
  filter: string;
}
export type TextFilter = TextFilterOne | TextFilterMulti;

/** Number Filter */

export function isNumberFilterMulti(filter: NumberFilterBase) {
  if ((filter as NumberFilterMulti).condition1) {
    return true;
  }
  return false;
}
export function isNumberFilterRange(filter: NumberFilterBase) {
  if ((filter as NumberFilterRange).type === 'inRange') {
    return true;
  }
  return false;
}
export interface NumberFilterBase {
  filterType: 'number';
}
export interface NumberFilterMulti extends NumberFilterBase {
  operator: 'AND' | 'OR';
  condition1: {
    filterType: 'number';
    type: NumberOperator;
    filter: number;
  };
  condition2: {
    filterType: 'number';
    type: NumberOperator;
    filter: number;
  };
}
export interface NumberFilterOne extends NumberFilterBase {
  type: NumberOperator;
  filter: number;
}
export interface NumberFilterRange extends NumberFilterBase {
  type: 'inRange';
  filter: number;
  filterTo: number;
}
export type NumberFilter = NumberFilterOne | NumberFilterMulti | NumberFilterRange;

/** Date Filter */

export function isDateFilterMulti(filter: DateFilterBase) {
  if ((filter as DateFilterMulti).condition1) {
    return true;
  }
  return false;
}
export function isDateFilterRange(filter: DateFilterBase) {
  if ((filter as DateFilterRange).type === 'inRange') {
    return true;
  }
  return false;
}

export interface DateFilterBase {
  filterType: 'date';
}

export interface DateFilterRange extends DateFilterBase {
  type: 'inRange';
  dateFrom: string;
  dateTo: string;
}
export interface DateFilterMulti extends DateFilterBase {
  operator: 'AND' | 'OR';
  condition1:
    | DateFilterRange
    | {
        filterType: 'date';
        type: DateOperator;
        dateFrom: string;
        dateTo: null;
      };
  condition2:
    | DateFilterRange
    | {
        filterType: 'date';
        type: DateOperator;
        dateFrom: string;
        dateTo: null;
      };
}
export interface DateFilterOne extends DateFilterBase {
  type: DateOperator;
  dateFrom: string;
  dateTo: null;
}

export type DateFilter = DateFilterOne | DateFilterMulti | DateFilterRange;

export interface SetFilter {
  filterType: 'set';
  values: Array<string | boolean | number>;
}

export type AgGridFilter = TextFilter | NumberFilter | DateFilter | SetFilter;

export interface AgGridFilterModel {
  [colId: string]: AgGridFilter;
}

export interface IServerSideGetRowsRequestWithFilterModel
  extends IServerSideGetRowsRequest {
  filterModel: AgGridFilterModel;
}

export interface IServerSideGetRowsParamsWithFilterModel
  extends IServerSideGetRowsParams {
  request: IServerSideGetRowsRequestWithFilterModel;
}

import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import './SofasTemplateLoading.css';

export const SofaTemplateLoading = () => {
  return (
    <div className="sofas-template-loading">
      <DivWithLoader loading className="sofas-template-loading__loading-screen">
        <div className="sofas-template-loading__title">
          We are exporting your tempate, this may take several minutes.
        </div>
      </DivWithLoader>
    </div>
  );
};

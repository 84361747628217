import { ApLeftSliderModal } from 'components/UIComponents/ApLeftSliderModal/ApLeftSliderModal';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { ContractsData, SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useEffect, useMemo } from 'react';
import {
  fetchContractActionNamesThunk,
  fetchContractActionsSelectOptions,
} from 'reducer/thunks/actions-thunks';
import { loadContractPaymentPositions } from 'reducer/thunks/contractPaymentPositionsThunk';
import { FetchStatus } from 'types/fetch-status.types';
import { BatchEditorForm } from '../../../../../components/BatchEditor/BatchEditorForm';
import { BatchEditLine } from './components/BatchEditLine';
import 'pages/ClaimsContractsToolPage/sections/ClaimsTable/ClaimsBatchEdit/ClaimsBatchEdit.css';
import { useContractsToolBatchEdit } from './hooks/useContractsToolBatchEdit';
import { useBatchOptionItems } from './hooks/useOptionItems';
import { BatchEditContextProvider } from '../../ClaimsTable/ClaimsBatchEdit/BatchEditContextProvider';

export interface IProps {
  contractsData: ContractsData;
  onClose: () => void;
  cancelBatch: () => void;
}

export const ContractsBatchEdit = ({
  contractsData,
  onClose,
  cancelBatch,
}: IProps) => {
  const fetchType = useAppSelector((state) => state.actionsSelectOptions.type);

  const dispatch = useAppDispatch();
  const { fields, applyEnabled, applyChanges } =
    useContractsToolBatchEdit(contractsData);

  const optionItems = useBatchOptionItems(fields.items);

  const handleApply = async () => {
    const result = await applyChanges();
    if (result) onClose();
  };

  useEffect(() => {
    if (fetchType !== FetchStatus.Done) {
      dispatch(fetchContractActionsSelectOptions());
      dispatch(fetchContractActionNamesThunk());
      loadContractPaymentPositions(dispatch);
    }
  }, [dispatch, fetchType]);

  const modalTopPosition = useMemo(
    () => document.getElementById('id-contracts-grid')?.offsetTop ?? 0,
    [],
  );

  return (
    <BatchEditContextProvider
      sectionType={SectionType.Contracts}
      data={contractsData}
    >
      <ApLeftSliderModal style={{ top: modalTopPosition }} className="batcheditor">
        <BatchEditorForm
          sectionType={SectionType.Contracts}
          templateFileName="ContractBatchEdit.xlsx"
          applyEnabled={applyEnabled}
          onAddClick={fields.add}
          onCancel={cancelBatch}
          onApply={handleApply}
        >
          {fields.items.map((item) => (
            <BatchEditLine
              key={item.id}
              item={item}
              optionItems={optionItems}
              onChange={fields.change}
              onRemove={fields.remove}
              addCascadingItem={fields.addCascadingItem}
            />
          ))}
        </BatchEditorForm>
      </ApLeftSliderModal>
    </BatchEditContextProvider>
  );
};

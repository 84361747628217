import { userLogsApi } from 'api/endpoints/userLogsApi';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { AppDispatch, GetState } from 'reducer/store';
import { setFetching, setFetchingDone } from 'reducer/usersLogsReducer';
import { error } from 'utils/alert';

export const fetchUserLogsThunk =
  () => async (dispatch: AppDispatch, _state: GetState) => {
    dispatch(setFetching());
    const response = await userLogsApi.getLastActivity();
    if (response.type === ResponseType.OK)  {
        dispatch(setFetchingDone(response.data));
    }
    else { 
       error(`Couldn't fetch data`);
    }
  };

import { SettlementStatus } from 'api/endpoints/settlements';
import { isNotEmpty } from 'utils/lodashEx';

export const settlementStatusNames: Record<SettlementStatus, string> = {
  SettlementPending: 'Settlement Pending',
  AwaitingClientApproval: 'Awaiting Client Approval',
  AwaitingCounterpartyReview: 'Awaiting Counterparty Review',
  ViewedByCounterparty: 'Viewed by Counterparty',
  SignedByCounterparty: 'Signed by Counterparty',
  DeclinedByCounterparty: 'Declined by Counterparty',
  SettlementExecuted: 'Settlement Executed',
  SettlementResolved: 'Settlement Resolved',
  SettlementPaid: 'Settlement Paid',
};

export const settlementStatusValues =
  Object.keys(settlementStatusNames).filter(isNotEmpty);

export const getSettlementStatusName = (
  status: SettlementStatus | null | undefined,
) => {
  return settlementStatusNames[status!] ?? status;
};

import { ApButtonDanger, ApButtonMain, ApModal } from '@alixpartners/ui-components';
import './SofaDeleteConfirm.css';

type Props = React.PropsWithChildren<{
  title: string;
  yesButton?: string;
  onClose: (confirm: boolean) => void;
}>;

export const SofaDeleteConfirm = ({
  title,
  yesButton = 'Ok',
  children,
  onClose,
}: Props) => {
  const handleClick = (value: boolean) => () => onClose(value);

  return (
    <ApModal
      className="sofa-delete-modal"
      header={title}
      isDismissible
      isFluid={false}
      isOpen={true}
      zIndex={998}
      hasClosed={handleClick(false)}
      footer={() => (
        <>
          <ApButtonMain className="normal-text-case" onClick={handleClick(false)}>
            Cancel
          </ApButtonMain>
          <ApButtonDanger
            className="sofa-confirm-button normal-text-case"
            onClick={handleClick(true)}
          >
            {yesButton}
          </ApButtonDanger>
        </>
      )}
    >
      {children}
    </ApModal>
  );
};

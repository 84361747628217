import { del, get, post } from 'api/apiRequest';
import { ContractActionName } from './action-names';
import { EhxibitContractType } from './contractExhibitTypes';

export interface ExhibitExportContract {
  id: number;
  contractExhibitTypeId: number;
  contractActionNameId: number;
  contractExhibitType: EhxibitContractType;
  contractActionName: ContractActionName;
  createdDate: string;
  contractExhibitMembers: {
    id: number;
    contractExhibitExportId: number;
    contractId: number;
    referenceNumber: string;
    description: string;
  }[];
}

export interface ContractExhibitPost {
  contractExhibitTypeId: number;
  contractActionNameId: number;
  contractIds: number[];
}

export const contractExhibitExports = {
  all: () => get<ExhibitExportContract[]>('/v1/ContractExhibitExports/all'),
  post: (value: ContractExhibitPost) =>
    post<ExhibitExportContract>('/v1/ContractExhibitExports', value),
  get: (id: number) =>
    get<ExhibitExportContract>(
      `/v1/ContractExhibitExports?contractExhibitExportId=${id}`,
    ),
  delete: (id: number) =>
    del(`/v1/ContractExhibitExports?contractExhibitExportId=${id}`),
};

import { GlobalNote } from 'api/endpoints';
import { FileDisplay } from 'pages/SOFAsAndSchedules/Components';
import { SofaDeleteConfirm } from 'pages/SOFAsAndSchedules/Components/SofaDeleteConfirm';
import { GlobalNoteItemInfo } from './Components/GlobalNoteItemInfo';
import './GlobalNotesDeleteModal.css';

interface Props {
  item: GlobalNote;
  onClose: (confirmend: boolean) => void;
}

export const GlobalNotesDeleteModal = ({ item, onClose }: Props) => {
  return (
    <SofaDeleteConfirm
      title="Are you sure you want to delete this global note?"
      yesButton="Yes, delete global note"
      onClose={onClose}
    >
      <div className="global-notes-delete-info">
        <FileDisplay displayName={item.displayName} />
        <GlobalNoteItemInfo item={item} />
      </div>
    </SofaDeleteConfirm>
  );
};

import { SingleContractOverview } from './sections/SingleContractOverview/SingleContractOverview';

import { ContractEditValues } from 'api/endpoints';
import { SingleClaimContractChangesComments } from 'components/SingleClaimContractChangesComments/SingleClaimContractChangesComments';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SingleContractDocuments } from './sections/SingleContractDocs';
import { SingleContractCounterpartiesAndDebtors } from './sections/SingleContractCounterpartiesAndDebtors/SingleContractCounterpartiesAndDebtors';
import { EditContractHook } from './sections/SingleContractOverview/hook/useEditContract';
import { ContractTab } from './types';
import { SingleContractRelatedContracts } from './sections/SingleContractRelatedContracts/SingleContractRelatedContracts';
import { SingleContractMatches } from './sections/SingleContractMatches';

export interface SingleContractSectionsProps {
  tab: ContractTab;
  contract: ContractEditValues;
  editContractHook: EditContractHook;
}

export const SingleContractSections = ({
  tab,
  contract,
  editContractHook,
}: SingleContractSectionsProps) => {
  switch (tab) {
    case ContractTab.Overview:
      return <SingleContractOverview editContractHook={editContractHook} />;
    case ContractTab.ChangesAndComments:
      return (
        <SingleClaimContractChangesComments
          claimId={0}
          contractId={contract.id}
          section={SectionType.Contracts}
        />
      );
    case ContractTab.Matches:
      return <SingleContractMatches contract={contract} />;
    case ContractTab.Documents:
      return <SingleContractDocuments contractId={contract.id} />;
    case ContractTab.CounterpartiesAndDebtors:
      return (
        <SingleContractCounterpartiesAndDebtors
          contractId={contract.id}
          contractRef={contract.referenceNumber}
        />
      );
    case ContractTab.RelatedContracts:
      if (!contract.parentContractNumber) return null;
      return (
        <SingleContractRelatedContracts
          parentContractNumber={contract.parentContractNumber}
          selectedContractId={contract.id}
        />
      );
  }
  return null;
};

import classNames from 'classnames';

export const CasesEntitiesInput = ({
  columnKey,
  fromTable: { disabled, className },
  item,
}: any) => {
  const PLACEHOLDER_MAP = {
    projectName: 'Enter project name',
    primaryCaseNumber: 'Enter primary case number',
  };
  if (columnKey === 'primaryCaseNumber') {
    return (
      <input
        type="text"
        name={columnKey}
        maxLength={255}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        placeholder={(PLACEHOLDER_MAP as any)[columnKey]}
        defaultValue={item.primaryCaseNumber}
      />
    );
  }
  return (
    <input
      type="text"
      name={columnKey}
      maxLength={255}
      required
      className={classNames(className, 'utils-clean-input')}
      disabled={disabled}
      placeholder={(PLACEHOLDER_MAP as any)[columnKey]}
      defaultValue={item.projectName}
    />
  );
};

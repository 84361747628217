import { ClaimAttachmentDocumentType } from 'api/endpoints/claimAttachments';
import { ClaimContractLogPaginationSort } from 'api/endpoints/claimLogs';

export enum SingleClaimSubheaderType {
  Overview = 'Overview',
  Matches = 'Matches',
  ChangesAndComments = 'ChangesAndComments',
  Documents = 'Documents',
}

export const CLAIM_ATTACHMENTS_OPTIONS = [
  { value: `${ClaimAttachmentDocumentType.ClaimImage}`, label: 'Claim Image' },
  { value: `${ClaimAttachmentDocumentType.CourtExhibit}`, label: 'Court Exhibit' },
  {
    value: `${ClaimAttachmentDocumentType.VendorAgreement}`,
    label: 'Vendor Agreement',
  },
  {
    value: `${ClaimAttachmentDocumentType.SettlementAgreement}`,
    label: 'Settlement Agreement',
  },
  {
    value: `${ClaimAttachmentDocumentType.Communications}`,
    label: 'Communications',
  },
  {
    value: `${ClaimAttachmentDocumentType.Reconciliations}`,
    label: 'Reconciliations',
  },
  {
    value: `${ClaimAttachmentDocumentType.ScheduleDetail}`,
    label: 'Schedule Detail',
  },
  {
    value: `${ClaimAttachmentDocumentType.WithdrawalForm}`,
    label: 'Withdrawal Form',
  },
  {
    value: `${ClaimAttachmentDocumentType.Other}`,
    label: 'Other',
  },
  {
    value: `${ClaimAttachmentDocumentType.Email}`,
    label: 'Email',
  },
];

export type ClaimContractLogPaginationSortOption = {
  value: ClaimContractLogPaginationSort;
  label: string;
};

export const SORT_OPTIONS: ClaimContractLogPaginationSortOption[] = [
  {
    value: 'created-date-asc',
    label: 'Log Time (asc)',
  },
  {
    value: 'created-date-desc',
    label: 'Log Time (desc)',
  },
];

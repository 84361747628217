import { SofaModal } from 'pages/SOFAsAndSchedules/Components';
import './GlobalNotesErrorModal.css';

interface Props {
  errorList: string[];
  onClose: () => void;
}

export const GlobalNotesErrorModal = ({ errorList, onClose }: Props) => {
  return (
    <SofaModal
      header="Upload Error"
      hasClosed={onClose}
      btnConfirm={{
        name: 'OK',
        onClick: onClose,
      }}
    >
      <div className="global-notes-error-modal__body">
        {errorList.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    </SofaModal>
  );
};

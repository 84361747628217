import {
  backgroundJobs,
  BatchEditBackgroundResponse,
  BatchEditBackgroundResponseType,
  BatchEditPostBackground,
} from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { azureStorageUploadClaimsContractsBatchEdit } from 'pages/DataUploaderPage/api/apiDataUploader';
import { useCallback, useMemo, useRef, useState } from 'react';
import { setBatchEditDetails } from 'reducer/batchEditReducer';
import { FileType, IUploadItem } from 'types/dataUploaderTypes';
import { error, success } from 'utils/alert';
import { apiBatchEditUploadBackground } from '../api/apiPostBatchEdit';

const POLL_INTERVAL = 5000;

export const useBatchUploadBackgroundJob = (
  refreshData: () => void,
  sectionType: SectionType,
) => {
  const intevalRef = useRef<NodeJS.Timeout>();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const dispatch = useAppDispatch();
  const {
    claims: { fileUploading: claimsFileUploading, fileName: claimsFileName },
    contracts: {
      fileUploading: contractsFileUploading,
      fileName: contractsFileName,
    },
  } = useAppSelector((s) => s.batchEdit);
  const [errorLogId, setErrorLogId] = useState<number>();

  // we use ref instead of state to persist value between re-renders
  // so we don't queue status API calls if prev one took longer than POLL_INTERVAL to complete
  const statusFetching = useRef(false);

  const getUploadStatus = useCallback(
    async (backgroundJobId: number) => {
      if (statusFetching.current) return;

      statusFetching.current = true;
      const resp =
        await backgroundJobs.getBackgroundJobStatus<BatchEditBackgroundResponse>(
          backgroundJobId,
        );

      if (resp) statusFetching.current = false;

      if (!resp || resp?.status === 'Failed') {
        clearInterval(intevalRef.current);
        statusFetching.current = false;
        error('Upload failed. Please try again');
        dispatch(
          setBatchEditDetails({
            sectionType,
            fileUploading: false,
          }),
        );
        return;
      }

      if (resp?.status === 'Completed') {
        clearInterval(intevalRef.current);
        if (resp.data.status === BatchEditBackgroundResponseType.Failed) {
          setErrorLogId(resp.data.id);
          setShowErrorModal(true);
          return;
        }
        success(
          `${
            resp.data.claimsUpdated ?? resp.data.contractsUpdated
          } ${sectionType} were updated`,
        );
        refreshData();
        dispatch(
          setBatchEditDetails({
            sectionType,
            fileName: '',
            fileUploading: false,
          }),
        );
      }
    },
    [refreshData, sectionType, dispatch],
  );
  const runBackgroundJob = useCallback(
    async (data: BatchEditPostBackground) => {
      const resp = await apiBatchEditUploadBackground(data, sectionType);
      if (resp?.jobId) {
        intevalRef.current = setInterval(
          () => getUploadStatus(resp?.jobId),
          POLL_INTERVAL,
        );
      } else {
        dispatch(
          setBatchEditDetails({
            sectionType,
            fileUploading: false,
          }),
        );
      }
    },
    [getUploadStatus, dispatch, sectionType],
  );

  const batchFileType = useMemo(
    () =>
      sectionType === SectionType.Claims
        ? FileType.claimsBatchEditUpload
        : FileType.contractsBatchEditUpload,
    [sectionType],
  );

  const onSubmit = useCallback(
    async (file: File) => {
      dispatch(
        setBatchEditDetails({
          sectionType,
          fileUploading: true,
        }),
      );
      const resp = await azureStorageUploadClaimsContractsBatchEdit({
        file,
        displayName: file.name,
        type: batchFileType,
      } as IUploadItem);
      if (resp && resp.name) {
        dispatch(
          setBatchEditDetails({
            sectionType,
            fileName: file.name,
          }),
        );
        setShowUploadModal(false);
        runBackgroundJob({
          containerName: 'excelcontainer',
          directoryName: batchFileType,
          fileName: resp.name,
          displayName: file.name,
        });
      } else {
        dispatch(
          setBatchEditDetails({
            sectionType,
            fileUploading: false,
          }),
        );
      }
    },
    [runBackgroundJob, dispatch, sectionType, batchFileType],
  );

  return {
    onSubmit,
    showErrorModal,
    showUploadModal,
    setShowErrorModal,
    setShowUploadModal,
    isUploading: claimsFileUploading || contractsFileUploading,
    fileName: claimsFileName || contractsFileName,
    errorLogId,
    setErrorLogId,
  };
};

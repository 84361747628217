import { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getSavedGridState, setSavedGridState } from 'utils/local-storage';
import { getGridState } from '../util/getGridState';
import { SectionType } from '../types';

export const useResetFilters = (
  ref: React.RefObject<AgGridReact<any>> | undefined,
  section: SectionType,
  setShowMine?: (showMine: boolean) => void,
) => {
  const onResetFilters: () => void = useCallback(() => {
    if (!ref?.current) return;
    const gridOptions = ref.current;
    const currentSavedState = getSavedGridState();
    const sectionStateNoFilters = { ...getGridState(gridOptions), filterState: {} };

    const newColumnState = {
      ...currentSavedState,
      [section]: sectionStateNoFilters,
    };
    setSavedGridState(newColumnState);
    ref.current.api?.setFilterModel({});
    setShowMine?.(false);
  }, [ref, section, setShowMine]);
  return onResetFilters;
};

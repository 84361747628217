import { ApButtonMain, ApInput, ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { ChangeOptionsHook } from '../sections/SingleClaimOverview/hook/useEditClaim';
import { ChangeOptionCheckbox } from './ChangeOptionCheckbox';
import './SavePopup.css';

export interface SavePopupProps {
  className?: string;
  changeOptionsHook: ChangeOptionsHook;
  isSaving: boolean;
  open: boolean;
  onSave: () => unknown;
}

export const SavePopup = ({
  className,
  changeOptionsHook,
  isSaving,
  open,
  onSave,
}: SavePopupProps) => {
  const [changeOptions, setChangeOptions] = changeOptionsHook;
  const { isClient } = useAppUserType();
  const { markFlags } = useClaimAttachmentsContext();

  const mandatoryComment = isClient && !changeOptions.comment;

  return (
    <div data-open={open} className={classNames('save-popup', className)}>
      <div className="save-popup__inner-wrapper">
        <div className="save-popup__input">
          <ApInput
            disabled={!open}
            className={classNames('save-popup__comment', {
              hasError: mandatoryComment,
            })}
            placeholder="Add a comment about your updates or resolution for this claim:"
            value={changeOptions.comment}
            onChange={(comment) =>
              setChangeOptions({
                ...changeOptions,
                comment,
              })
            }
          />
        </div>
        <ChangeOptionCheckbox
          changeOptionsHook={changeOptionsHook}
          markFlags={markFlags}
          disabled={isSaving}
        />
        <div className="save-popup__btn-container">
          <ApButtonMain
            className="save-popup__save-btn"
            onClick={onSave}
            disabled={isSaving || !open || mandatoryComment}
            title={
              mandatoryComment
                ? 'Please include claim resolution notes / comments with your assessment'
                : ''
            }
          >
            {isSaving ? (
              <>
                <ApLoaderDefault loaderSize="sm" />
                Saving changes
              </>
            ) : (
              <>
                <SvgIcon iconName="save" />
                Save changes
              </>
            )}
          </ApButtonMain>
        </div>
      </div>
    </div>
  );
};

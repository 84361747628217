import { fileInfo } from 'api/endpoints';
import { jsonFetchPost } from 'api/jsonFetch/authJsonFetch';
import { IUploadItem } from 'types/dataUploaderTypes';
import { warning } from 'utils/alert';
import { downloadBlob, fileToArrayBuffer, splitFileName } from 'utils/file';

export const downloadFile = async (item: IUploadItem) => {
  const { uploadedFile, file } = item;

  if (uploadedFile) {
    const { fileType, id, displayName } = uploadedFile;
    await fileInfo.downloadFile(fileType, id, displayName);
    return;
  }

  if (file) {
    const ab = await fileToArrayBuffer(file);
    downloadBlob(new Blob([ab]), file.name);
  }
};

export const downloadErrors = async (item: IUploadItem) => {
  const { id, errors, displayName } = item;
  if (!errors) {
    warning('There are no errors');
    return;
  }

  const [file, ext] = splitFileName(displayName);
  await fileInfo.downloadErrors(id, `${file}_errors${ext}`);
};

export const deleteFileInfo = async (item: IUploadItem) => {
  //method returns false if file is not deleted due to some API error
  if (!item.uploadedFile) return true;

  const { id, fileType } = item.uploadedFile;
  const result = await fileInfo.deleteFile(fileType, id);
  return result;
};

export const deleteAllFiles = async () => {
  return jsonFetchPost<void>(
    '/v1/DataPipeline/trigger-reset',
    undefined,
    undefined,
    true,
  );
};

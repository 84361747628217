import { ContractRow } from 'api/endpoints';
import { OptionItem } from 'components/ApNiceSelect';
import { ClaimButton } from 'components/ExhibitsExport/ClaimButton/ClaimButton';
import { useState } from 'react';
import { asNumber } from 'utils/formatNumber';
import { ExhibitSelect } from '../../../components/ExhibitsExport/ExhibitSelect/ExhibitSelect';
import { AddContractPopup } from '../AddContractPopup';
import { ExhibitContractsTable } from '../ExhibitContractsTable';
import { ContractExhibit, ContractExhibitType } from '../exhibityContractType';
import './ExhibitDataSection.css';
import { ApRadio } from '@alixpartners/ui-components';

interface Props {
  exhibit: ContractExhibit;
  typeOptions: OptionItem[];
  actionNames: OptionItem[];
  loading?: boolean;
  onChange: (value: ContractExhibit) => void;
  setIncludeRejDate?: (flag: boolean) => void;
  includeRejDate?: boolean;
}

export const ExhibitDataSection = ({
  exhibit,
  typeOptions,
  actionNames,
  loading,
  onChange,
  setIncludeRejDate,
  includeRejDate,
}: Props) => {
  const [showContrastAddModal, setShowContrastAddModal] = useState(false);
  const [selected, setSelected] = useState<ContractRow[]>();

  const handleTypeChange = (value: string) => {
    const typeId = asNumber(value);
    onChange({ ...exhibit, typeId, actionNameId: undefined });
  };

  const handleNameChange = (value: string) => {
    const actionNameId = asNumber(value);
    onChange({ ...exhibit, actionNameId });
  };

  const handleAddGridRow = (newContracts: ContractRow[]) => {
    const contracts = [...exhibit.contracts, ...newContracts];
    onChange({ ...exhibit, contracts });
  };

  const handleDeleteGridRows = () => {
    const newContracts = exhibit.contracts.filter((r) => !selected?.includes(r));

    onChange({ ...exhibit, contracts: newContracts });
    setSelected(undefined);
  };

  const showGrid = !!exhibit.typeId && !!exhibit.actionNameId;

  return (
    <div className="exhibit-contract-exporter__section">
      <header>Exhibit Data</header>
      <div>
        <div className="exhibit-contract-exporter__input-section">
          <ExhibitSelect
            label="Exhibit type:"
            placeholder="Select a exhibit type"
            disabled={loading}
            options={typeOptions}
            value={exhibit.typeId}
            onChange={handleTypeChange}
          />

          <ExhibitSelect
            label="Resolution name:"
            placeholder="Select a resolution name"
            disabled={loading}
            options={actionNames}
            value={exhibit.actionNameId}
            onChange={handleNameChange}
          />
        </div>
      </div>
      {showGrid && (
        <>
          <div className="exhibit-contract-exporter__table">
            <ExhibitContractsTable
              rows={exhibit.contracts}
              loading={loading}
              onSelectedChange={setSelected}
            />
            <div className="exhibit-contract-exporter__table-buttons">
              <ClaimButton
                disabled={loading}
                iconName="add"
                onClick={() => setShowContrastAddModal(true)}
              >
                Add another contract
              </ClaimButton>
              <ClaimButton
                disabled={!selected?.length}
                iconName="delete"
                onClick={handleDeleteGridRows}
              >
                Delete {selected?.length! > 1 ? 'contracts' : 'contract'}
              </ClaimButton>
            </div>
          </div>
          {(exhibit.typeId === ContractExhibitType.RealEstateRejection ||
            exhibit.typeId === ContractExhibitType.NonRealEstateRejection) && (
            <div className="exhibit-exporter__section-content-row__radio">
              <label className="label">
                Do you want to include the Rejection Date on the Exhibit?
              </label>
              <div className="exhibit-exporter__section-content-row__radio-container">
                <ApRadio
                  autoFocus={false}
                  id="displayRejectionDateTrue"
                  name="radio"
                  theme="light"
                  type="radio"
                  onChange={() => setIncludeRejDate?.(true)}
                  defaultChecked={includeRejDate}
                >
                  Yes
                </ApRadio>
                <ApRadio
                  autoFocus={false}
                  id="displayRejectionDateFalse"
                  name="radio"
                  theme="light"
                  type="radio"
                  defaultChecked={!includeRejDate}
                  onChange={() => setIncludeRejDate?.(false)}
                >
                  No
                </ApRadio>
              </div>
            </div>
          )}
        </>
      )}
      {showContrastAddModal && (
        <AddContractPopup
          onAdd={handleAddGridRow}
          onClose={() => setShowContrastAddModal(false)}
          exhibitContracts={exhibit.contracts}
        />
      )}
    </div>
  );
};

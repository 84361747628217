import { gql } from '@apollo/client';
import {
  ClaimRow,
  claims as claimsApi,
  contracts as contractsApi,
  CounterpartySummary,
  CounterpartySummaryAmounts,
  SourceType,
} from 'api/endpoints';

import useGraphql from 'api/graphql/useGraphql';

import { useAppSelector } from 'hooks/reducerHooks';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ClaimTallyItem, ContractRowFormatted } from '../Counterparty.types';

export interface TallyItem {
  type: SourceType;
  count: number;
  item: ClaimTallyItem[];
}

export type TallyState = Array<TallyItem>;

function counterpartySummaryToClaimTallyItems(
  amount: CounterpartySummaryAmounts,
): TallyItem {
  return {
    type: amount.sourceType,
    count: amount.count,
    item: [
      {
        label: 'Original amounts',
        count: amount.count,
        secured: amount.originalSecured,
        admin: amount.originalAdmin,
        priority: amount.originalPriority,
        unsecured: amount.originalUnsecured,
        total: amount.originalTotal,
      },
      {
        label: 'Current amounts',
        count: amount.count,
        secured: amount.currentSecured,
        admin: amount.currentAdmin,
        priority: amount.currentPriority,
        unsecured: amount.currentUnsecured,
        total: amount.currentTotal,
      },
      {
        label: 'Proposed amounts',
        count: amount.count,
        secured: amount.proposedSecured,
        admin: amount.proposedAdmin,
        priority: amount.proposedPriority,
        unsecured: amount.proposedUnsecured,
        total: amount.proposedTotal,
      },
    ],
  };
}

export function useCounterpartyData() {
  let { id } = useParams();
  const [client, authorized] = useGraphql();
  const matchCode = Number(id);
  const [counterpartyData, setCounterpartyData] = useState<CounterpartySummary>();
  const [claimTallyItems, setClaimTallyItems] = useState<TallyState | undefined>();
  const [allClaims, setAllClaims] = useState<ClaimRow[]>([]);
  const [allContracts, setAllContracts] =
    useState<ContractRowFormatted[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const getAllContracts = () => {
    const contractColumns = [
      'id',
      'category',
      'counterpartyName',
      'matchCode',
      'category',
      'primaryDebtor',
      'debtor',
      'rejectionDamageLow',
      'rejectionDamagesHigh',
      'contractActionName',
      'contractActionSubStatusName',
      'contractStatus',
      'description',
      'referenceNumber',
    ];
    return gql`
      query GetMatchCodeContracts($matchCode: Int!) {
        contracts(where: {
          matchCode: { eq: $matchCode }
        }) {
          totalCount
          items {
            ${contractColumns.join(' ')}
          }
        }
      }
    `;
  };

  const getAllClaims = () => {
    const claimsColumns = [
      'id',
      'originalDebtorId',
      'currentDebtorId',
      'proposedDebtorId',
      'alixPartnersDebtorId',
      'clientDebtorId',
      'counselDebtorId',
      'pORDebtorId',
      'majorCategoryId',
      'minorCategoryId',

      'customString01',
      'customString02',
      'customString03',
      'customString04',
      'customString05',
      'customString06',
      'customString07',
      'customString08',
      'customString09',
      'customString10',
      'customString11',
      'customString12',
      'customString13',
      'customString14',
      'customString15',
      'customDecimal01',
      'customDecimal02',
      'customDecimal03',
      'customDecimal04',
      'customDecimal05',
      'customDecimal06',
      'customDecimal07',
      'customDecimal08',
      'customDecimal09',
      'customDecimal10',
      'customDecimal11',
      'customDecimal12',
      'customDecimal13',
      'customDecimal14',
      'customDecimal15',
      'customBoolean01',
      'customBoolean02',
      'customBoolean03',
      'customBoolean04',
      'customBoolean05',
      'customDateTime01',
      'customDateTime02',
      'customDateTime03',
      'customDateTime04',
      'customDateTime05',

      'sourceType',
      'referenceNumber',
      'counterpartyName',
      'originalDebtor',
      'currentDebtor',
      'proposedDebtor',
      'alixPartnersDebtor',
      'clientDebtor',
      'counselDebtor',
      'pORDebtor',
      'barDateRef',
      'scheduledDescription',
      'claimsAgentNumber',
      'confidentialResolution',
      'redactImage',
      'claimImage',
      'underSeal',
      'officialClaimDate',
      'dateRecdFromClaimsAgent',
      'originalSecured',
      'originalAdministrative',
      'originalPriority',
      'originalUnsecured',
      'originalTotal',
      'currentSecured',
      'currentAdministrative',
      'currentPriority',
      'currentUnsecured',
      'currentTotal',
      'proposedSecured',
      'proposedAdministrative',
      'proposedPriority',
      'proposedUnsecured',
      'proposedTotal',
      'alixPartnersSecured',
      'alixPartnersAdministrative',
      'alixPartnersPriority',
      'alixPartnersUnsecured',
      'alixPartnersTotal',
      'clientSecured',
      'clientAdministrative',
      'clientPriority',
      'clientUnsecured',
      'clientTotal',
      'counselSecured',
      'counselAdministrative',
      'counselPriority',
      'counselUnsecured',
      'counselTotal',
      'highSecured',
      'highAdministrative',
      'highPriority',
      'highUnsecured',
      'highTotal',
      'lowSecured',
      'lowAdministrative',
      'lowPriority',
      'lowUnsecured',
      'lowTotal',
      'pORSecured',
      'pORAdministrative',
      'pORPriority',
      'pORUnsecured',
      'pORTotal',
      'originalContingent',
      'originalUnliquidated',
      'originalDisputed',
      'currentContingent',
      'currentUnliquidated',
      'currentDisputed',
      'proposedContingent',
      'proposedUnliquidated',
      'proposedDisputed',
      'alixPartnersContingent',
      'alixPartnersUnliquidated',
      'alixPartnersDisputed',
      'clientContingent',
      'clientUnliquidated',
      'clientDisputed',
      'counselContingent',
      'counselUnliquidated',
      'counselDisputed',
      'pORContingent',
      'pORUnliquidated',
      'pORDisputed',
      'claimImage',
      'matchCode',
      'subMatchCode',
      'actionReview',
      'actionStatus',
      'originalUndetermined',
      'currentUndetermined',
      'proposedUndetermined',
      'alixPartnersUndetermined',
      'clientUndetermined',
      'counselUndetermined',
      'pORUndetermined',
      'majorCategory',
      'minorCategory',
      'contactFullName',
      'contactFirstName',
      'contactLastName',
      'title',
      'contactTitle',
      'address1',
      'address2',
      'address3',
      'address4',
      'city',
      'state',
      'zip',
      'country',
      'phone',
      'email',
      'numberOfMatches',
      'notMatched',
      'hasOneHundredPercentMatch',
      'oneHundredPercentMatchCode',
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate',
      'actionNameId',
      'actionName',
      'actionExhibitTypeId',
      'actionExhibitTypeName',
      'actionSubStatusId',
      'actionSubStatusName',
    ];
    return gql`
      query GetMatchCodeClaims($matchCode: Int!) {
        claims(where: {
          matchCode: { eq: $matchCode }
        }) {
          totalCount
          items {
            ${claimsColumns.join(' ')}
          }
        }
      }
    `;
  };

  const fetchCounterpartyDataMemoized = useCallback(async () => {
    const counterpartyData = await claimsApi.getCounterpartySummary(matchCode);
    setCounterpartyData(counterpartyData);
    if (counterpartyData) {
      const items = [];
      const filed = counterpartyData.counterpartySummaryAmounts.find(
        (amount) => amount.sourceType === SourceType.FiledClaim,
      );
      const scheduled = counterpartyData.counterpartySummaryAmounts.find(
        (amount) => amount.sourceType === SourceType.ScheduledClaim,
      );
      if (filed) items.push(counterpartySummaryToClaimTallyItems(filed));
      if (scheduled) items.push(counterpartySummaryToClaimTallyItems(scheduled));

      setClaimTallyItems(items);
    }
    setIsLoading(false);
  }, [matchCode, setCounterpartyData, setClaimTallyItems, setIsLoading]);

  const fetchClaimDataMemoized = useCallback(async () => {
    const { data }: any = await client.query({
      query: getAllClaims(),
      variables: {
        matchCode,
      },
    });
    setAllClaims(data?.claims?.items ?? []);
  }, [matchCode, client, setAllClaims]);

  const fetchContractDataMemoized = useCallback(async () => {
    const { data }: any = await client.query({
      query: getAllContracts(),
      variables: {
        matchCode,
      },
    });

    const contractData = [...data?.contracts?.items];

    for (let contract of contractData) {
      const contractsReviewers = await contractsApi.getContractReviewers(
        contract.id,
      );

      // Create a new object with the 'test' property
      const updatedContract = { ...contract, contractsReviewers };

      // Find the index of the current contract in the array
      const index = contractData.findIndex((c) => c.id === contract.id);

      // Replace the old contract with the updated one
      if (index !== -1) {
        contractData[index] = updatedContract;
      }
    }

    setAllContracts(contractData);
  }, [matchCode, client, setAllContracts]);

  useEffect(() => {
    if (!authorized) return;

    fetchClaimDataMemoized();
    fetchContractDataMemoized();
    fetchCounterpartyDataMemoized();
  }, [
    authorized,
    fetchClaimDataMemoized,
    fetchContractDataMemoized,
    fetchCounterpartyDataMemoized,
  ]);

  const { claims, contracts } = useAppSelector((s) => s);

  //it is not good approach to get section the next way
  // but it was done by original page logic
  const section = (claims.section ?? contracts.section)!;

  return {
    counterpartyData,
    allClaims,
    allContracts,
    claimTallyItems,
    isLoading,
    section,
  };
}

import { ExhibitExportContract } from 'api/endpoints/contractExhibitExports';
import { GraphQlApolloClient } from 'api/graphql';
import useGraphql from 'api/graphql/useGraphql';
import { useState } from 'react';
import { apiExhibitContract } from './apiExhibitContract';
import { ContractExhibitExport } from './exhibitContractExporter/templatesType';
import { ContractExhibit } from './exhibityContractType';

export const useExhibitsContractHistory = () => {
  const [client] = useGraphql();
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState<ExhibitExportContract[]>([]);
  const [loading, setLoading] = useState(false);

  const loadList = async () => {
    if (loading) return;

    setLoading(true);
    const result = await apiExhibitContract.historyList();
    setRows(result);
    setLoading(false);
  };

  const showSwitch = () => {
    const newValue = !show;

    if (newValue) loadList();
    setShow(newValue);
  };

  const refreshList = () => loadList();

  const deleteItem = async (id: number) => {
    setLoading(true);
    await apiExhibitContract.deleteHistoryItem(id);
    setLoading(false);
    loadList();
  };

  const getContractExhibitById = async (id: number) => {
    const exhibit = await getExhibitByContractId(id, { rows, client });
    return exhibit;
  };

  const getExhibitExportedById = async (
    id: number,
  ): Promise<ContractExhibitExport | undefined> => {
    const exhibit = await getExhibitByContractId(id, { rows, client });
    if (!exhibit) return;

    const historyExhibit = rows.find((r) => r.id === id);
    const fileName =
      historyExhibit?.contractActionName.contractActionName ?? 'action';

    return {
      ...exhibit,
      fileName,
    };
  };

  return {
    show,
    rows,
    loading,
    refreshList,
    showSwitch,
    deleteItem,
    getContractExhibitById,
    getExhibitExportedById,
  };
};

const getExhibitByContractId = async (
  id: number,
  extra: { client: GraphQlApolloClient; rows: ExhibitExportContract[] },
) => {
  const { client, rows } = extra;
  const item = rows.find((r) => r.id === id);
  if (!item) return;

  const contracts = await apiExhibitContract.getContractsByMembers(
    client,
    item.contractExhibitMembers,
  );

  const result: ContractExhibit = {
    typeId: item.contractExhibitTypeId,
    actionNameId: item.contractActionNameId,
    contracts,
  };

  return result;
};

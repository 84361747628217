import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { TemplateExportMultiselect } from '../../Components/ScheduleExportMultiselect';
import { SelectedItems } from '../sofaExportTypes';

interface Props {
  value: SelectedItems;
  onChange: (newValue: SelectedItems) => void;
}

export const SofasFormTemplate = ({ value, onChange }: Props) => {
  // handle changing excel template change
  const handleChangeItems = (items: IOption[]) => onChange({ ...value, items });

  return (
    <div className="sofas-export-panel__input-group">
      <h5>Export</h5>
      <TemplateExportMultiselect
        onChange={handleChangeItems}
        selected={value.items}
      />
    </div>
  );
};

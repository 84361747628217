function agGridCellSelector(rowIndex: number | null, colId: string) {
  if (rowIndex === null) return;
  const domCellArray = document.querySelectorAll<HTMLDivElement>(
    `.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${colId}"]`,
  );
  if (!domCellArray.length || !domCellArray[0]) return;
  const element = domCellArray[0];
  return element;
}
export default agGridCellSelector;

import useGraphql from 'api/graphql/useGraphql';
import { useEffect, useMemo, useState } from 'react';
import {
  apiExhibitContract,
  ExhibitTypeActionNameOptions,
} from './apiExhibitContract';
import { ContractExhibitExport } from './exhibitContractExporter/templatesType';
import { ContractExhibit } from './exhibityContractType';
import { useAppSelector } from 'hooks/reducerHooks';

const EXHIBIT_DEF: ContractExhibit = { contracts: [] };

export const useExhibitExporterContract = () => {
  const [client] = useGraphql();
  const [exhibit, setExhibit] = useState<ContractExhibit>(EXHIBIT_DEF);
  const [actionNameDict, setActionNameDict] = useState<ExhibitTypeActionNameOptions>(
    {},
  );
  // const [showHistory, setShowHistory] = useState(false);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [includeRejDate, setIncludeRejDate] = useState(false);

  const { contractExhibitTypes: exhibitTypes } = useAppSelector(
    (s) => s.selectable.data,
  );

  const loadTypes = async () => {
    const names = await apiExhibitContract.actionNames();
    setActionNameDict(names);
  };

  const loadContractsList = async (value: ContractExhibit) => {
    setContractsLoading(true);
    const contracts = await apiExhibitContract.getContracts(client, value);

    setExhibit((prev) => ({ ...prev, contracts }));

    setContractsLoading(false);
  };

  useEffect(() => {
    loadTypes();
  }, []);

  const resetExhibit = () => setExhibit(EXHIBIT_DEF);

  const applyNewExhibit = (value: ContractExhibit | undefined) => {
    setExhibit(value ?? EXHIBIT_DEF);
  };

  const exhibitChange = (value: ContractExhibit) => {
    const isChanged =
      value.actionNameId !== exhibit.actionNameId || value.typeId !== exhibit.typeId;
    setExhibit(value);

    if (isChanged) loadContractsList(value);
  };

  const getExhibitExported = (): ContractExhibitExport => {
    const { actionNameId, typeId } = exhibit;
    const actionList = actionNameDict[typeId!];
    const action = actionList?.find((r) => r.value === actionNameId);
    const fileName = action?.label.trim() ?? 'action';

    return {
      ...exhibit,
      fileName,
    };
  };

  const actionNames = useMemo(() => {
    const id = exhibit.typeId;
    return actionNameDict[id!] ?? [];
  }, [actionNameDict, exhibit.typeId]);

  return {
    exhibitTypes,
    actionNames,
    exhibit,
    actionNameDict,
    contractsLoading,
    resetExhibit,
    getExhibitExported,
    applyNewExhibit,
    exhibitChange,
    includeRejDate,
    setIncludeRejDate,
  };
};

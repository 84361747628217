import './ClaimsMatchingSummaryModal.css';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ClaimRow } from 'api/endpoints';
import { DetailsModal } from 'components/ModalWindows/DetailsModal/DetailsModal';
import { useMatchCodeClaimsQuery } from 'hooks/useMatchCodeClaims';
import { getValueFormatterFn } from 'pages/ClaimsContractsToolPage/grid/ClaimsGrid/ClaimsGrid.utils';
import { ClaimMatchRow } from 'pages/SingleClaimPage/sections/SingleClaimMatches.hooks';

type ColumnType = {
  title: string;
  field: keyof ClaimRow;
  width?: number;
};

const defaultColDef = {
  initialWidth: 150,
  sortable: true,
  resizable: true,
  suppressColumnsToolPanel: true,
  menuTabs: [],
};

const COLUMNS: ColumnType[] = [
  { title: 'REF', field: 'referenceNumber' },
  { title: 'Counterparty name', field: 'counterpartyName', width: 200 },
  { title: 'Submatch code', field: 'subMatchCode' },
  { title: 'Source type', field: 'sourceType' },
  { title: 'Major Category', field: 'majorCategory' },
  { title: 'Current total', field: 'currentTotal', width: 200 },
];

const columnDefs: ColDef<ClaimMatchRow>[] = COLUMNS.map(({ title, ...fields }) => ({
  ...fields,
  headerValueGetter: () => title,
  valueFormatter: getValueFormatterFn(fields.field),
}));

interface IProps {
  claim: ClaimRow | undefined;
  onClose: () => void;
}

export const ClaimsMatchingSummaryModal = ({ claim, onClose }: IProps) => {
  const { data } = useMatchCodeClaimsQuery(claim);

  const claims = data?.claims?.items;

  const title = `Match summary for: ${claim?.matchCode}`;

  return (
    <DetailsModal isOpen title={title} onClose={onClose}>
      <div
        className="ag-theme-alpine claims-tool-grid claimsmatching-summary-grid"
        style={{ height: 400 }}
      >
        <AgGridReact
          rowData={claims}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressContextMenu
          suppressDragLeaveHidesColumns
          suppressRowClickSelection
        />
      </div>
    </DetailsModal>
  );
};

import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useDashboardContextData } from 'pages/Dashboard/context/DashboardContextProvider';
import { ClaimsItemsData, ContractsBaseDetailedData } from 'pages/Dashboard/types';
import { useMemo } from 'react';
import { formatMoneyShort } from 'utils/formatNumber';

export const DetailedItemContent = ({
  data,
  firstItem,
  sectionType,
}: {
  data: ContractsBaseDetailedData | ClaimsItemsData;
  firstItem?: boolean;
  sectionType: SectionType;
}) => {
  const {
    label,
    count,
    percentage,
    low,
    high,
    negotiatedCureAmount,
    totalProposed,
  } = useMemo(
    () => ({
      percentage: 'percentage' in data && data.percentage,
      negotiatedCureAmount:
        'negotiatedCureAmount' in data ? data.negotiatedCureAmount : undefined,
      low: 'low' in data ? data.low : undefined,
      high: 'high' in data ? data.high : undefined,
      label: 'label' in data ? data.label : undefined,
      count: 'count' in data ? data.count : 0,
      totalProposed: 'totalProposed' in data ? data.totalProposed : 0,
    }),
    [data],
  );
  const { gridClassName } = useDashboardContextData();

  if (sectionType === SectionType.Contracts) {
    return (
      <div className={gridClassName}>
        <p className="resolution-type">
          {firstItem && <span>Resolution Type</span>}
          {label}
        </p>
        <p>
          {firstItem && <span>Count</span>}
          {count}
        </p>
        <p>
          {firstItem && <span>Percentage</span>}
          {percentage}%
        </p>
        {negotiatedCureAmount !== undefined && (
          <p>
            {firstItem && <span>Negotiated Cure Amount $</span>}
            {formatMoneyShort(negotiatedCureAmount)}
          </p>
        )}
        {low !== undefined && (
          <p>
            {firstItem && <span>Low $</span>}
            {formatMoneyShort(low)}
          </p>
        )}
        {high !== undefined && (
          <p>
            {firstItem && <span>High $</span>}
            {formatMoneyShort(high)}
          </p>
        )}
      </div>
    );
  }

  if (sectionType === SectionType.Claims) {
    return (
      <div className={gridClassName}>
        <p className="resolution-type">
          {firstItem && <span>Claims</span>}
          {label}
        </p>
        <p>
          {firstItem && <span>Count</span>}
          {count}
        </p>
        <p>
          {firstItem && <span>Total Proposed</span>}
          {formatMoneyShort(totalProposed)}
        </p>
      </div>
    );
  }
  return null;
};

import Excel, { Style } from 'exceljs';
import { createArray } from 'utils/array';
import { IHeaderFooterField } from './type';

const styleHeader: Record<'left' | 'middle' | 'right', Partial<Style>> = {
  left: {
    font: { name: 'Times New Roman', size: 8 },
    alignment: { vertical: 'top', horizontal: 'left', wrapText: true },
  },
  middle: {
    font: { name: 'Times New Roman', size: 14 },
    alignment: { vertical: 'top', horizontal: 'center', wrapText: true },
  },
  right: {
    font: { name: 'Times New Roman', size: 8 },
    alignment: { vertical: 'top', horizontal: 'right', wrapText: true },
  },
};

export type HeaderPositionCell = {
  left: number;
  middle: number;
  right: number;
  length: number;
};

export const excelAddPageHeader = (
  sheet: Excel.Worksheet,
  header: IHeaderFooterField,
  position: HeaderPositionCell,
) => {
  const { left, middle, right } = position;

  const emptyTitle = !header.left && !header.middle && !header.right;

  const headerValue = createArray(sheet.columnCount, '');
  headerValue[left - 1] = header.left;
  headerValue[middle - 1] = header.middle;
  headerValue[right - 1] = header.right;

  const headerRow = sheet.addRow(headerValue);
  headerRow.height = emptyTitle ? 12 : 50;

  headerRow.getCell(left).style = styleHeader.left;
  headerRow.getCell(middle).style = styleHeader.middle;
  headerRow.getCell(right).style = styleHeader.right;

  getHeaderCellsToMerge(position).forEach((r) => sheet.mergeCells(r));

  // Repeat specific rows on every printed page
  sheet.pageSetup.printTitlesRow = `1:${sheet.rowCount}`;
};

const getHeaderCellsToMerge = (
  value: HeaderPositionCell,
): [string, string, string] => {
  const { left, middle, right, length } = value;

  const char = (value: number) => String.fromCharCode(64 + value);
  const lastColumn = length + left - 1;

  return [
    `${char(left)}1:${char(middle - 1)}1`,
    `${char(middle)}1:${char(right - 1)}1`,
    `${char(right)}1:${char(lastColumn)}1`,
  ];
};

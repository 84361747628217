import { jsonFetchGet } from 'api/jsonFetch/authJsonFetch';
import { get, getQuiet } from '../apiRequest';
import { ReportType } from './sofas-schedules';

export interface LegalEntity {
  id: number;
  legalEntityId: string;
  caseNumber: string;
  entityName: string;
  dateFiled?: string;
}

export type ReportPart = {
  parentType: ReportType;
  partName: string;
  partDescription: string;
};

export type ReportLegalEntity = {
  legalEntityId: number;
  entityName: string;
};

export const legalEntities = {
  list: () => get<LegalEntity[]>(`v1/LegalEntities/list`),
};

export const reportLegalEntities = {
  get: async () => {
    return await jsonFetchGet<ReportLegalEntity[]>(`v1/SofaSchedule/legalentities`);
  },

  getAssigned: async (type: ReportType) =>
    getQuiet<ReportLegalEntity[]>(`v1/SofaSchedule/assignedLegalEntities/${type}`),
};

export const reportParts = {
  getList: () => getQuiet<ReportPart[]>(`v1/SofaSchedule/parts`),
};

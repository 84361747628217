import { SlipSheet, SlipSheetPostParams, slipsheets } from 'api/endpoints';
import { OkResponse, ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useState } from 'react';
import { slipSheetsActions } from 'reducer/slipSheetsReducer';
import { uploadSlipSheet } from 'utils/uploader';

export type UploadStatus = {
  progress: number;
};

export type SubmitData = {
  entries: {
    file: File;
    input: Omit<SlipSheetPostParams, 'fileName'>;
  }[];
};

export type UploadOkResult = {
  type: 'ok';
  result: { fileName: string };
  entry: SubmitData['entries'][0];
};

const MAX_BLOB_UPLOAD_PROGRESS_RATIO = 0.9;
const MIN_BLOB_UPLOAD_PROGRESS_RATIO = 0.1;
const DELTA_BLOB_UPLOAD_PROGRESS_RATIO =
  MAX_BLOB_UPLOAD_PROGRESS_RATIO - MIN_BLOB_UPLOAD_PROGRESS_RATIO;

export function useSlipsheetUpload() {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    progress: -1,
  });
  const dispatch = useAppDispatch();

  const uploadAndCreateSlipSheet = useCallback(
    async (data: SubmitData) => {
      const totalSize = data.entries.reduce((acc, crt) => {
        return acc + crt.file.size;
      }, 0);

      const totalCount = data.entries.length;

      let totalLoaded = 0;

      setUploadStatus({
        progress: MIN_BLOB_UPLOAD_PROGRESS_RATIO,
      });

      const uploadPromises = data.entries.map(async (entry) => {
        try {
          let prevLoaded = 0;
          const result = await uploadSlipSheet(entry.file, (loaded) => {
            const delta = loaded - prevLoaded;
            prevLoaded = loaded;
            totalLoaded = totalLoaded + delta;
            const progress =
              MIN_BLOB_UPLOAD_PROGRESS_RATIO +
              (totalLoaded / totalSize) * DELTA_BLOB_UPLOAD_PROGRESS_RATIO;

            setUploadStatus({ progress });
          });
          if (result) {
            return { type: 'ok', result, entry };
          }
          throw new Error('No result.');
        } catch (e) {
          return { type: 'fail', error: e, entry };
        }
      });

      const uploadResults = await Promise.all(uploadPromises);
      // TODO also output the errors
      const okResults = uploadResults.filter(
        (u) => u.type === 'ok',
      ) as UploadOkResult[];

      setUploadStatus({
        progress: MAX_BLOB_UPLOAD_PROGRESS_RATIO + (5 - (totalCount % 5)) * 0.01,
      });

      if (!okResults.length) return;

      const notesResults = await createSlipSheets(okResults);
      const created = (
        notesResults.filter(
          (nr) => nr.type === ResponseType.OK,
        ) as OkResponse<SlipSheet>[]
      ).map((nr) => nr.data);

      if (created.length) {
        dispatch(slipSheetsActions.postDone({ items: created }));
      }

      // TODO call redux action for error, but support partial errors
      setUploadStatus({ progress: -1 });
    },
    [dispatch],
  );

  return { uploadStatus, uploadAndCreateSlipSheet };
}

async function createSlipSheets(okResults: UploadOkResult[]) {
  const promises = okResults.map((r) => {
    return slipsheets.post({
      ...r.entry.input,
      fileName: r.result.fileName,
    });
  });
  const all = await Promise.all(promises);

  return all;
}

import { FileStatus } from 'types/dataUploaderTypes';

const STATUS_MAP = {
  LocalError: '(LocalError)',
  Validating: '(Validating)',
  Uploading: '(Uploading)',
  InProgress: '(InProgress)',
  Failed: '(Failed)',
  ApplicationException: '(ApplicationException)',
  Unpublished: '(Awaiting Publishing)',
  Published: '(Published)',
} as const;

export const getStatusText = (
  errorsCount: number | undefined,
  status: FileStatus | undefined,
): string => {
  if (!errorsCount) {
    return STATUS_MAP[status!] ?? status ?? '';
  }

  const count = errorsCount > 99 ? '99+' : `${errorsCount}`;
  const text = errorsCount > 1 ? 'errors' : `error`;

  return `${count} ${text}`;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';

export type AllUsersSlice =
  | {
      status: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      status: FetchStatus.Done;
      data: UserInfo[];
      error: null;
    }
  | {
      status: FetchStatus.Error;
      error: { message: string };
      data: UserInfo[];
    }
  | {
      status: FetchStatus.Fetching;
      data: UserInfo[];
      error: null;
    };

export const initialState: AllUsersSlice = {
  status: FetchStatus.Idle,
  error: null,
  data: null,
} as AllUsersSlice;

const allUsersSlice = createSlice({
  name: 'allusers',
  initialState: initialState,
  reducers: {
    fetching(state: AllUsersSlice) {
      state.status = FetchStatus.Fetching;
    },
    done(state: AllUsersSlice, action: PayloadAction<UserInfo[]>) {
      state.status = FetchStatus.Done;
      state.data = action.payload;
    },
    error(state: AllUsersSlice, action: PayloadAction<{ message: string }>) {
      state.status = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const allUsersActions = allUsersSlice.actions;
export default allUsersSlice.reducer;

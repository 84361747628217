import { ApLoaderDefault } from '@alixpartners/ui-components';
import cloneDeep from 'lodash/cloneDeep';
import { ClaimRow, claimsReviewers as claimsReviewersApi } from 'api/endpoints';
import { ActionExhibitTypeId } from 'api/endpoints/actions-select-options';
import classNames from 'classnames';
import { ClientUserRole } from 'components/RolePermission/ClientUserRole';
import { Section } from 'components/SingleClaim/Section';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEditAndFilterDynamicFields } from '../../../SettingsPage/sections/DynamicFields/useDynamicFields';
import { useEffect, useState } from 'react';
import {
  claimReviewerFetch,
  claimReviewerLoaded,
} from 'reducer/claimReviewerReducer';

import { ClaimResolutionSection } from './components/ClaimResolutionSection/ClaimResolutionSection';
import { GreenEditableSection } from './components/GreedEditableSection/GreenEditableSection';
import { SingleClaimInputs } from './components/SingleClaimInputs';
import { SingleClaimMatrix } from './components/SingleClaimMatrix';
import { EditClaimHook } from './hook/useEditClaim';
import { SectionType } from '../../../ClaimsContractsToolPage/types';
import useSections from './hook/useSections';
import './SingleClaimOverview.css';
import { orderBy } from 'lodash';

export interface SingleClaimOverviewProps {
  claim?: ClaimRow;
  editClaimHook: EditClaimHook;
}

export const SingleClaimOverview = ({ editClaimHook }: SingleClaimOverviewProps) => {
  const dispatch = useAppDispatch();
  const { loading, claim, editField, isEditable } = editClaimHook;
  const { formatDynamicField, claimsData: allClaimsDynamicFields } =
    useEditAndFilterDynamicFields(SectionType.Claims);
  const sectionDefault = useSections();
  const [sections, setSections] = useState(sectionDefault);

  const { actionTypeId } = useAppSelector(
    (s) => s.claimActionNameSelection.claimActionInfo,
  );

  useEffect(() => {
    const defaultSections = cloneDeep(sectionDefault);
    const ordered = orderBy(allClaimsDynamicFields, (df) => df.propertyName);
    ordered?.forEach((field) => {
      defaultSections
        .find((section) => section.id === field.displayIn && field.enabled)
        ?.inputs?.push({ ...formatDynamicField(field) });
    });

    setSections(defaultSections);
  }, [allClaimsDynamicFields, sectionDefault, formatDynamicField]);

  useEffect(() => {
    const loadClaimReviewers = async (dispatch: any) => {
      if (!claim?.id) return;
      dispatch(claimReviewerFetch());
      const claimReviewers = await claimsReviewersApi.get(claim?.id);
      dispatch(claimReviewerLoaded(claimReviewers));
    };

    loadClaimReviewers(dispatch);
  }, [claim?.id, dispatch]);

  if (loading) return <ApLoaderDefault />;
  if (!claim) return null;
  return (
    <div className="single-claim-overview">
      <ClientUserRole>
        <Section
          title={'Claim Resolution'}
          className={classNames(
            'single-claim-overview__section',
            'claim-resolution',
          )}
          key={`sectionResolution`}
        >
          <ClaimResolutionSection />
        </Section>
        {actionTypeId &&
        ![ActionExhibitTypeId.Allow, ActionExhibitTypeId.None].includes(
          actionTypeId,
        ) ? (
          <GreenEditableSection />
        ) : (
          ''
        )}
      </ClientUserRole>
      {sections.map((section, index) => {
        const { title, inputs, matrix, orientation } = section;
        const isMatrix = !!matrix;
        return (
          <Section
            title={isMatrix ? undefined : title}
            className={classNames('single-claim-overview__section', {
              'single-claim-overview__section-is-matrix': isMatrix,
            })}
            key={`section${index}`}
          >
            {inputs && (
              <SingleClaimInputs
                orientation={orientation || 'horizontal'}
                inputs={inputs}
                claim={claim}
                isEditable={isEditable}
                editField={editField}
              />
            )}
            {isMatrix && (
              <div className="single-claim-overview__matrix-container">
                <SingleClaimMatrix
                  title={title}
                  matrix={matrix}
                  claim={claim}
                  isEditable={isEditable}
                  editField={editField}
                />
                <div className="single-claim-overview__matrix-label">
                  <p>
                    Please update fields marked in green based on your review of the
                    claim. Upon approval, these values will be reflected in the
                    'Proposed amounts' columns.
                  </p>
                  <p>
                    Upon court-order stipulation, these values will reflect the
                    final, allowed value of the claim.
                  </p>
                </div>
              </div>
            )}
          </Section>
        );
      })}
    </div>
  );
};

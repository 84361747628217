import classNames from 'classnames';
import { ReactNode } from 'react';
import './InputWithLabel.css';

export interface InputWithLabelProps {
  className?: string;
  label: string;
  children: ReactNode;
}

export const InputWithLabel = ({
  children,
  label,
  className,
}: InputWithLabelProps) => {
  return (
    <div className={classNames('input-with-label', className)}>
      <div className="input-with-label__label">{label}:</div>
      <div className="input-with-label__input">{children}</div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActionSelectOptionsData,
  ActionSubstatus,
} from 'api/endpoints/actions-select-options';
import { FetchStatus } from 'types/fetch-status.types';

export type ActionSelectOptionsReduxData = ActionSelectOptionsData & {
  actionSubstatuses: ActionSubstatus[];
};

export type ActionsSelectOptionsState =
  | {
      type: FetchStatus.Idle;
      data: ActionSelectOptionsReduxData;
      error: null;
    }
  | {
      type: FetchStatus.Done;
      data: ActionSelectOptionsReduxData;
      error: null;
    }
  | {
      type: FetchStatus.Error;
      error: { message: string };
      data: ActionSelectOptionsReduxData;
    }
  | {
      type: FetchStatus.Fetching;
      data: ActionSelectOptionsReduxData;
      error: null;
    };

export const initialState: ActionsSelectOptionsState = {
  type: FetchStatus.Idle,
  error: null,
  data: {
    actionTypes: [],
    actionSubstatuses: [],
    actionSubstatusesByActionType: {},
    hearingDateRequiredByActionType: {},
  },
} as ActionsSelectOptionsState;

const actionNameOptionsSlice = createSlice({
  name: 'actions-select-options',
  initialState: initialState,
  reducers: {
    setActionsSelectOptionsIdle(state: ActionsSelectOptionsState) {
      state.type = FetchStatus.Idle;
    },

    actionsSelectOptionsFetching(state: ActionsSelectOptionsState) {
      state.type = FetchStatus.Fetching;
    },

    actionsSelectOptionsDone(
      state: ActionsSelectOptionsState,
      action: PayloadAction<ActionSelectOptionsData>,
    ) {
      state.type = FetchStatus.Done;
      state.data = {
        ...action.payload,
        actionSubstatuses: Object.values(
          action.payload.actionSubstatusesByActionType,
        ).flatMap((bt) => bt),
      };
    },
    contractActionSelectOptionsDone(state: ActionsSelectOptionsState) {
      state.type = FetchStatus.Done;
    },

    actionsSelectOptionsError(
      state: ActionsSelectOptionsState,
      action: PayloadAction<{ message: string }>,
    ) {
      state.type = FetchStatus.Error;
      state.error = action.payload;
    },
  },
});

export const {
  actionsSelectOptionsFetching,
  actionsSelectOptionsDone,
  actionsSelectOptionsError,
  setActionsSelectOptionsIdle,
} = actionNameOptionsSlice.actions;
export default actionNameOptionsSlice.reducer;

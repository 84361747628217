import { ICellEditorParams } from 'ag-grid-community';
import { useReportLegalEntities } from 'pages/SOFAsAndSchedules/Domain/useReportLegalEntities';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

export const LegalEntityEditor = forwardRef((props: ICellEditorParams, ref) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const legalEntity = useReportLegalEntities();

  //props.value - contains string description value. So we have to use `props.data?.legalEntityId` parameter
  const [value, setValue] = useState<number | undefined>(props.data?.legalEntityId);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
    focusIn() {
      inputRef.current?.focus();
    },
  }));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(+event.target.value);
  };

  return (
    <div className="editorcell-custom ag-input-wrapper ag-text-field-input-wrapper">
      <div className="editorcell-custom ag-input-wrapper ag-text-field-input-wrapper">
        <select
          autoComplete="true"
          ref={inputRef}
          value={value}
          onChange={handleChange}
          className="ag-input-field-input ag-text-field-input"
        >
          {!value && <option value={undefined}></option>}
          {legalEntity?.data?.map((r) => (
            <option value={r.legalEntityId}>{r.entityName}</option>
          ))}
        </select>
      </div>
    </div>
  );
});

import { CheckboxSelectionCallbackParams, ITooltipParams } from 'ag-grid-community';
import { GridColumn } from 'api/endpoints';
import { CLAIM_REVIEWER_COLUMN } from '../const';

export const getTooltipValueGetterFn = <T>(row: GridColumn) => {
  if (row.propertyName === CLAIM_REVIEWER_COLUMN) {
    return ({ value }: ITooltipParams<T, string>) => value?.replaceAll(',', `\n`);
  }

  return (params: ITooltipParams<any>) => params.value;
};

export function checkboxSelection<T>(
  row: CheckboxSelectionCallbackParams<T>,
): boolean {
  // @todo: hasOneHundredPercentMatch is used in claims. We might need to pass
  // different function if we are in contracts tab
  const { hasOneHundredPercentMatch, oneHundredPercentMatchCode } =
    row.data || ({} as any);
  return !!hasOneHundredPercentMatch && !!oneHundredPercentMatchCode;
}

import { ApButtonGroup, ApButtonMain, ApModal } from '@alixpartners/ui-components';
import { FileComparisonResponse } from 'api/endpoints';
import { ApButtonCasual } from 'components/UIComponents/Buttons';
import { Fragment } from 'react';
import './DataUploadDuplicatesModal.css';

type Props = {
  filesComparisonResponse: FileComparisonResponse;
  onConfirm: () => void;
  onDismiss: () => void;
};

export const DataUploadDuplicatesModal = ({
  filesComparisonResponse,
  onDismiss,
  onConfirm,
}: Props) => {
  return (
    <ApModal
      className="data-upload__duplicates-display-modal"
      hasClosed={onDismiss}
      header={
        <>
          {`We found ${filesComparisonResponse.duplicates.length} ${
            filesComparisonResponse.duplicates.length > 1
              ? 'duplicates'
              : 'duplicate'
          }. Are you sure you`}
          <br />
          want to publish the files?
        </>
      }
      isDismissible
      isOpen
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonCasual onClick={onDismiss}>Cancel</ApButtonCasual>
          <ApButtonMain onClick={onConfirm}>Confirm</ApButtonMain>
        </ApButtonGroup>
      )}
    >
      <div className="data-upload__duplicates-display-modal__duplicates-rows">
        {['Row number', 'Part/Question', 'Entity name'].map((header) => (
          <span
            key={header}
            className="data-upload__duplicates-display-modal__duplicates-header-cell"
          >
            {header}
          </span>
        ))}
        {filesComparisonResponse.duplicates.map((duplicate, i) => (
          <Fragment key={i}>
            <div className="data-upload__duplicates-display-modal__duplicates-cell-bold">
              {duplicate.row}
            </div>
            <div className="data-upload__duplicates-display-modal__duplicates-cell-bold">
              {duplicate.part}
            </div>
            <div className="data-upload__duplicates-display-modal__duplicates-cell">
              {duplicate.entityName}
            </div>
          </Fragment>
        ))}
      </div>
    </ApModal>
  );
};

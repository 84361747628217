import { ActionStatusDynamic } from 'api/endpoints';
import _ from 'lodash';
import keyBy from 'lodash/keyBy';
import { fromCamelCase } from 'utils/format';
import { colProp, EDITABLE_COLUMNS_SORTED } from '../claimsBatchEdit.const';
import {
  IBatchEditableColumns,
  IBatchEditCategories,
  IBatchItem,
} from '../claimsBatchEdit.type';

/**
 * Refactor so that all data comes from BE for all columns
 * @param category
 * @param industry
 * @param motion
 * @returns
 */
export const getEditableColumnItems = ({
  data: { categories },
  items,
  actionStatuses,
}: {
  data: IBatchEditCategories;
  items: IBatchItem[];
  actionStatuses: ActionStatusDynamic[];
}) => {
  // we need deep copy because we will mutate items
  const result = _.cloneDeep(EDITABLE_COLUMNS_SORTED);

  const itemMajorCat = result.find((r) => r.propertyName === colProp.majorCategory);
  const actionStatus = result.find((r) => r.propertyName === colProp.actionStatus);

  const actionStatusAllowedValues = mapGridColumnsArrToObject(
    actionStatuses?.map((status) => status.actionStatus) as string[],
  );

  itemMajorCat && (itemMajorCat.allowedValues = categories);
  actionStatus && (actionStatus.allowedValues = actionStatusAllowedValues);

  const itemsObj = keyBy(items, 'propName');
  const resultAfterAlreadyDisplayedFields = result.map((r) => {
    const itemAlreadySelected = !!itemsObj[r.propertyName];
    return {
      ...r,
      disabled: itemAlreadySelected,
    };
  });

  return resultAfterAlreadyDisplayedFields;
};

export const getSelectOptions = (columns: IBatchEditableColumns[]) => {
  const emptyItem = { label: '', value: '' };
  const optionsList = columns.map((r) => ({
    label: r.displayName,
    value: r.propertyName,
    disabled: r.disabled,
  }));

  return [emptyItem, ...optionsList];
};

function mapGridColumnsArrToObject(allowedValues: string[] | undefined) {
  const obj: { [key: string]: string } = {};
  allowedValues?.forEach((v) => {
    obj[fromCamelCase(v)] = v;
  });
  return obj;
}

import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';
import { useBookmarksData } from '../hooks/useBookmarksData';
import { BookmarkBodyWithTextarea } from '../components/BookmarkBodyWithTextarea';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

type ABProps = {
  handleClose: () => void;
  openModal: boolean;
  section: SectionType;
};

export const AddBookmark = ({ openModal, handleClose, section }: ABProps) => {
  const { handleBookmarkSave, setBookmarkTitle, bookmarkTitle } =
    useBookmarksData(section);

  const handleSave = () => {
    handleBookmarkSave();
    handleClose();
  };

  const modalFooter = () => {
    return (
      <ApButtonGroup className="bookmarks-page__popup-footer">
        <ApButtonSecondary onClick={handleClose}>Cancel</ApButtonSecondary>
        <ApButtonMain
          iconName="outlined_bookmark_border"
          onClick={handleSave}
          disabled={!bookmarkTitle}
        >
          Save
        </ApButtonMain>
      </ApButtonGroup>
    );
  };

  return (
    <ApModal
      header="Add bookmark"
      footer={modalFooter}
      isOpen={openModal}
      hasClosed={handleClose}
      isDismissible
    >
      <BookmarkBodyWithTextarea
        handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setBookmarkTitle(e.target.value)
        }
        title="Save the view state of your table and access anytime."
        label="Bookmark title:"
      />
    </ApModal>
  );
};

import { GetRowIdParams, ValueFormatterFunc } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { fromCamelCase } from 'utils/format';
import { formatMoney } from 'utils/formatNumber';
import {
  ACTION_REVIEW_COLUMN,
  MONEY_FIELDS,
  PRIMARY_COLUMN,
  SOURCE_TYPE_COLUMN,
} from '../../const';

export const getGridRowId = (params: GetRowIdParams) => params.data[PRIMARY_COLUMN];

type TClaimsFormatter = ValueFormatterFunc<ClaimRow>;

export const getValueFormatterFn = (
  propertyName: string,
  dataType?: string,
): ValueFormatterFunc<ClaimRow> | undefined => {
  if (PRIMARY_COLUMN === propertyName) return formatterPrimaryCol;
  if (MONEY_FIELDS.includes(propertyName) || dataType === 'decimal')
    return formatterCurrency;
  if ([ACTION_REVIEW_COLUMN, SOURCE_TYPE_COLUMN].includes(propertyName))
    return formatterCamelCase;

  return undefined;
};

export const formatterPrimaryCol: TClaimsFormatter = ({ value }) =>
  value ? `#${value}` : '';

export const formatterCurrency: TClaimsFormatter = ({ value }) => formatMoney(value);

export const formatterCamelCase: TClaimsFormatter = ({ value }) =>
  fromCamelCase(value);

import './BookmarksList.css';
import { BookmarksCard } from 'pages/BookmarksPage/components/BookmarksCard/BookmarksCard';
import { BookmarkCardData } from 'pages/BookmarksPage/types';

export const BookmarksList = ({ bookmarks }: { bookmarks: BookmarkCardData[] }) => {
  return (
    <div className="bookmarks-page__list">
      {!bookmarks.length ? (
        <h4>No bookmarks match the selected criteria</h4>
      ) : (
        bookmarks.map((bookmark: BookmarkCardData) => (
          <BookmarksCard
            key={bookmark.id ?? bookmark.createdDate}
            bookmark={bookmark}
          />
        ))
      )}
    </div>
  );
};

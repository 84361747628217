import React from 'react';
import { HeaderButton, HeaderTooltip } from './CustomComponents';
import './style.css';

export interface IProps extends React.ComponentProps<typeof HeaderButton> {
  tooltip?: string;
  active?: boolean;
}

export const NavIconButton = ({ tooltip, ...buttonProps }: IProps) => {
  return (
    <HeaderTooltip message={tooltip} disabled={buttonProps.disabled}>
      <HeaderButton {...buttonProps} />
    </HeaderTooltip>
  );
};

import cs from 'classnames';
import { ClaimButton } from 'components/ExhibitsExport/ClaimButton';
import { InputText } from 'components/UIComponents/InputText';
import Select from 'components/UIComponents/Select';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  addGlobalFootnote,
  deleteGlobalFootnote,
  setGlobalFootnote,
} from 'reducer/exhibits/exhibitReducer';
import { EXHIBIT_FOOTNOTE_APPEAR } from './headerFooterOptions';

export const GlobalFootnoteSection = () => {
  const dispatch = useAppDispatch();

  const { globalFootnotes = [] } = useAppSelector((s) => s.exhibit.currentExhibit);

  const { loading } = useAppSelector((s) => s.exhibit);

  const renderFootnoteRows = () =>
    globalFootnotes.map((row) => (
      <div className="exhibit-exporter__section-content-row" key={row.id}>
        <div className="exhibit-exporter__section-content-row__footnote-text">
          <InputText
            autoFocus={false}
            disabled={loading}
            label="Footnote text"
            placeholder="Input footnote text"
            value={row?.footnote}
            maxLength={180}
            onChange={(e) =>
              dispatch(
                setGlobalFootnote({
                  id: row?.id,
                  value: { footnote: e.target.value },
                }),
              )
            }
          />
        </div>

        <Select
          label="Where should footnote appear?"
          options={EXHIBIT_FOOTNOTE_APPEAR}
          value={row?.footnoteAppearOptionId}
          placeholder="Please select"
          onChange={(e) =>
            dispatch(
              setGlobalFootnote({
                id: row?.id,
                value: { footnoteAppearOptionId: +e.target.value },
              }),
            )
          }
          disabled={loading}
        />
        <button
          className={cs('exhibit-exporter__section-content-row__delete-btn', {
            show: globalFootnotes.length > 1,
          })}
          onClick={(e) => dispatch(deleteGlobalFootnote(row.id))}
          disabled={loading}
        >
          <SvgIcon iconName="delete" width={24} height={24} />
        </button>
      </div>
    ));

  return (
    <div className="exhibit-exporter__section">
      <div className="exhibit-exporter__section-title">Global Footnote</div>
      <div className="exhibit-exporter__section-content">
        {renderFootnoteRows()}
        <div className="exhibit-exporter__section-content-row-align-right">
          <ClaimButton
            disabled={loading}
            iconName="add"
            onClick={(e) => dispatch(addGlobalFootnote())}
          >
            Add another footnote
          </ClaimButton>
        </div>
      </div>
    </div>
  );
};

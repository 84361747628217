import { SideBarDef } from 'ag-grid-community';
import { DebtorsCounterpartiesTootlip } from './components/DebtorsCounterpartiesTootlip';
import { SectionType, SubsectionType } from './types';

export const SECONDARY_HEADER_SECTIONS = [
  {
    type: SubsectionType.UnmatchedClaims,
    label: 'Unmatched Claims',
  },
  {
    type: SubsectionType.AllClaims,
    label: 'All Claims',
  },
];

export const DATA_TYPE_MAP = {
  enum: 'agSetColumnFilter',
  bool: 'agSetColumnFilter',
  string: 'agTextColumnFilter',
  int: 'agNumberColumnFilter',
  decimal: 'agNumberColumnFilter',
  date: 'agDateColumnFilter',
  datetime: 'agDateColumnFilter',
};

export const PLACEHOLDERS: any = {
  referenceNumber: '',
  counterpartyName: 'Type name here',
  originalTotal: 'Number',
};

export const GENERIC_PLACEHOLDERS: any = {
  agTextColumnFilter: 'Type text',
  agNumberColumnFilter: 'Type number',
  agDateColumnFilter: 'Select date',
};

export const MONEY_FIELDS = [
  'currentTotal',
  'originalSecured',
  'originalTotal',
  'currentSecured',
  'proposedSecured',
  'proposedTotal',
  'alixPartnersSecured',
  'alixPartnersTotal',
  'clientSecured',
  'clientTotal',
  'counselSecured',
  'counselTotal',
  'highSecured',
  'highTotal',
  'lowSecured',
  'lowTotal',
  'porSecured',
  'porTotal',
  'rejectionDamageLow',
  'rejectionDamagesHigh',
  'assumptionCureAmount',
  'negotiatedCureAmount',
  'contractValue',
  'fYSpend',
  'lastTwelveMonthsSpend',
  'totalMatchPrePetitionValue',
  'totalMatchPrePetitionContractValue',
  'cureCostReductionAmount',
  'twelveMonthSavings',
  'oneTimeSavings',
];

// @todo: update column names and remove unneccessary when contract
// feature is ready
export const PRIMARY_COLUMN = 'id';
export const REFERENCE_NUMBER_COLUMN = 'referenceNumber';
export const COUNTERPARTY_NAME_COLUMN = 'counterpartyName';
export const ACTION_STATUS_COLUMN = 'actionStatus';
export const ACTION_REVIEW_COLUMN = 'actionReview';
export const MATCH_CODE_COLUMN = 'matchCode';
export const SOURCE_TYPE_COLUMN = 'sourceType';
export const CURRENT_TOTAL_COLUMN = 'currentTotal';
export const MAJOR_CATEGORY_COLUMN = 'majorCategory';
export const CLAIM_REVIEWER_COLUMN = 'claimReviewers';
export const SUB_MATCH_CODE_COLUMN = 'subMatchCode';
export const MATCH_SCORE_CELL = 'matchScore';
export const COMPANY_CODE_COLUMN = 'companyCode';
export const DEBTOR_COLUMN = 'debtor';
export const CATEGORY_COLUMN = 'category';
export const HAS_MULTIPLE_DEBTORS_COLUMN = 'hasMultipleDebtors';
export const HAS_MULTIPLE_COUNTERPARTIES_COLUMN = 'hasMultipleCounterparties';
export const HAS_MULTIPLE_MATCH_CODES = 'hasMultipleMatchCodes';
export const COUNTERPARTY_NAMES_COLUMN = 'counterpartyNames';
export const PRIMARY_DEBTOR_COLUMN = 'primaryDebtor';
export const MATCH_CODES_COLUMN = 'matchCodes';
export const RES_TYPE_COLUMN = 'actionExhibitTypeName';
export const RES_SUBTYPE_COLUMN = 'actionSubStatusName';
export const PROPOSED_TOTAL_COLUMN = 'proposedTotal';
export const ORIGINAL_DEBTOR_COLUMN = 'originalDebtor';
export const RES_NAME_COLUMN = 'actionName';

export const CONTRACT_TYPE_COLUMN = 'contractType';
export const CONTRACT_RES_TYPE_COLUMN = 'contractActionTypeName';
export const CONTRACT_RES_SUBTYPE_COLUMN = 'contractActionSubStatusName';
export const CONTRACT_DATE_COLUMN = 'contractDate';
export const CONTRACT_VALUE_COLUMN = 'contractValue';
export const CONTRACT_ACTION_STATUS_COLUMN = 'contractStatus';
export const CONTRACT_NEGOCIATED_CURE_COLUMN = 'negotiatedCureAmount';

export const NOT_MATCHED_CELL = 'notMatched';
export const NUMBER_OF_MATCHES_CELL = 'numberOfMatches';
export const CREATED_DATE_CELL = 'createdDate';
export const ONE_HUNDRED_PERCENT_MATCH_CODE_CELL = 'oneHundredPercentMatchCode';
export const HAS_ONE_HUNDRED_PERCENT_MATCH_CELL = 'hasOneHundredPercentMatch';

export const DO_NOT_DISPLAY_COLUMNS = [NOT_MATCHED_CELL];

export const MATCH_SCORE_COLUMN_POSITION_ORDER = 6;

export const PIN_COLUMN = 'pin';
export const FORMATTING_CURRENCY = 'currency';

export const CLAIMS_CONSTANT_COLUMNS = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
];

export const CLAIMS_DEFAULT_DISPLAY = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  SOURCE_TYPE_COLUMN,
  MATCH_CODE_COLUMN,
  SUB_MATCH_CODE_COLUMN,
  ACTION_STATUS_COLUMN,
  RES_TYPE_COLUMN,
  RES_SUBTYPE_COLUMN,
  RES_NAME_COLUMN,
  CURRENT_TOTAL_COLUMN,
  PROPOSED_TOTAL_COLUMN,
  ORIGINAL_DEBTOR_COLUMN,
  MAJOR_CATEGORY_COLUMN,
];

export const CONTRACTS_CONSTANT_COLUMNS = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
];

export const CONTRACTS_DEFAULT_DISPLAY = [
  REFERENCE_NUMBER_COLUMN,
  COUNTERPARTY_NAME_COLUMN,
  CATEGORY_COLUMN,
  CONTRACT_TYPE_COLUMN,
  MATCH_CODE_COLUMN,
  CONTRACT_ACTION_STATUS_COLUMN,
  CONTRACT_RES_TYPE_COLUMN,
  CONTRACT_RES_SUBTYPE_COLUMN,
  PRIMARY_DEBTOR_COLUMN,
  CONTRACT_DATE_COLUMN,
  CONTRACT_NEGOCIATED_CURE_COLUMN,
];

export const DEFAULT_DISPLAY_MAP: {
  [key: string]: string[];
} = {
  [SectionType.Claims]: CLAIMS_DEFAULT_DISPLAY,
  [SectionType.Contracts]: CONTRACTS_DEFAULT_DISPLAY,
};

export const SECTIONS_WITH_PREDEFINED_FILTERS = [
  SectionType.Claims,
  SectionType.Contracts,
];

export const DEFAULT_COL_DEF = {
  // disable for the moment, to save and use resized col width from cache
  //flex: 1,
  minWidth: 75,
  resizable: true,
  floatingFilter: true,
  tooltipComponent: DebtorsCounterpartiesTootlip,
};

export const DEFAULT_COL_DEF_CONTRACTS = {
  // disable for the moment, to save and use resized col width from cache
  //flex: 1,
  minWidth: 75,
  resizable: true,
  floatingFilter: true,
  tooltipComponent: DebtorsCounterpartiesTootlip,
};
export const SIDE_BAR_CONFIG: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnExpandAll: true,
        suppressColumnMove: true,
      },
    },
  ],
  defaultToolPanel: '',
  position: 'right',
};

export const GRID_HEADER_HEIGHT = 34;
export const GRID_FLOATING_FILTER_HEIGHT = GRID_HEADER_HEIGHT;

export const NEGATIVE_NUM_FORMAT = '_(#,##0_);_((#,##0);_("-"??_);_(@_)';

import { api } from 'api';
import { storage } from 'api/endpoints';
import { SubmitData, UploadTypeOptions } from 'components/ModalWindows/UploadModal';
import { useApiQuery } from 'hooks/useApiQuery';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { azureFileBatchUploader } from 'utils/azureUploader/azureFileBatchUploader';

import {
  apiGetSettlementDocuments,
  SettlementDocument,
} from '../api/apiGetSettlementDocuments';
import { apiPostSettlementDocuments } from '../api/apiPostSettlementDocuments';
import { useSettlementSingleContext } from './SettlementSingleContextProvider';

interface SettlementSingleAttachmentData {
  items: SettlementDocument[];
  statusOptions: UploadTypeOptions[];
  loading: boolean;
  uploadProgress: number | undefined;
  addAttachments: (data: SubmitData) => void;
  downloadFile: (document: SettlementDocument) => Promise<void>;
}

const SettlementSingleAttachmentsContext =
  createContext<SettlementSingleAttachmentData>(
    {} as SettlementSingleAttachmentData,
  );

export const SettlementSingleAttachmentsProvider = (
  props: PropsWithChildren<{}>,
) => {
  const { id } = useSettlementSingleContext();
  const [uploadProgress, setUploadProgress] = useState<number>();

  const query = useApiQuery({
    queryFn: () => apiGetSettlementDocuments(id),
  });

  const [items, statusOptions] = query.data || [];

  const addAttachments = useCallback(
    async (data: SubmitData) => {
      const result = await azureFileBatchUploader(data.entries, {
        folder: `${id}`,
        showErrorMessage: true,
        getCredentials: storage.getSettlementDocumentsContainerSasToken,
        uploaderProgress: setUploadProgress,
      });

      if (result.succes.length <= 0) {
        setUploadProgress(undefined);
        return;
      }

      await apiPostSettlementDocuments(id, result.succes);
      await query.fetch();
      setUploadProgress(undefined);
    },
    [id, query],
  );

  const downloadFile = useCallback(
    async ({ settlementId, id, displayName }: SettlementDocument) => {
      await api.settlements.downloadDocument(settlementId, id, displayName);
    },
    [],
  );

  return (
    <SettlementSingleAttachmentsContext.Provider
      value={{
        items: items ?? [],
        statusOptions: statusOptions ?? [],
        loading: query.loading,
        uploadProgress,
        addAttachments,
        downloadFile,
      }}
    >
      {props.children}
    </SettlementSingleAttachmentsContext.Provider>
  );
};

export const useSettlementSingleAttachmentsContext = () =>
  useContext(SettlementSingleAttachmentsContext);

import { ClientInfo } from 'api/endpoints';
import classNames from 'classnames';
import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useState } from 'react';
import { userDashboardTasksActions } from 'reducer/userDashboardTasksReducer';
import { ApplicationUser } from 'utils/AppInitializer/application-user';
import { AdditionalPanel } from './AdditionalPanel';
import './Dashboard.css';

export type UserInfoPanelProps = {
  className?: string;
  userInfo?: Pick<ApplicationUser, 'given_name' | 'family_name'> | null;
  clientInfo?: Pick<ClientInfo, 'clientCompanyName'>;
  isClient?: boolean;
};

export function UserInfoPanel({
  className,
  userInfo,
  clientInfo,
  isClient,
}: UserInfoPanelProps) {
  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);
  const [toggle, setToggle] = useState(showMine);
  const dispatch = useAppDispatch();

  const name =
    userInfo?.given_name || userInfo?.family_name
      ? `, ${userInfo?.given_name} ${userInfo?.family_name}`
      : '';

  const handleToggleClick = () => {
    dispatch(userDashboardTasksActions.setShowMineToggle(!toggle));
    setToggle((prev) => !prev);
  };

  return (
    <>
      <section
        className={classNames(className, 'dashboard__userinfo', {
          withBackground: isClient,
        })}
      >
        <h5 className="dashboard__userinfo__title">
          <span>Hello{name}</span>
          <span className="dashboard__actions">
            {!isClient && (
              <>
                <ApThreeWaySwitch
                  value={toggle ? ApThreeWayValue.Yes : ApThreeWayValue.No}
                  className="dashboard-show-mine-toggle"
                  label="Display only my items"
                  onChange={handleToggleClick}
                />
                <AdditionalPanel isClient={!!isClient} />
              </>
            )}
            {isClient && (
              <span>
                Welcome to the {clientInfo?.clientCompanyName} Chapter 11 Tool
              </span>
            )}
          </span>
        </h5>
      </section>
    </>
  );
}

import './DashboardDetailedView.css';
import { useMemo } from 'react';
import { DetailedItem } from 'pages/Dashboard/components/DetailedView/DetailedItem';
import { DetailedViewNavButtons } from 'pages/Dashboard/components/DetailedView/DetailedViewNavButtons';
import { useDashboardContextData } from 'pages/Dashboard/context/DashboardContextProvider';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ClaimsItemsData } from 'pages/Dashboard/types';
import { CLAIMS_DETAILED_VIEW_NAV_BTNS } from 'pages/Dashboard/utils/dashboardTasksConfig';

export const DashboardClaimsDetailedView = () => {
  const { claimsActiveSection, claimsDetailedData } = useDashboardContextData();

  const dataToRender = useMemo(
    () =>
      claimsDetailedData?.find((dataItem) => dataItem.id === claimsActiveSection),
    [claimsDetailedData, claimsActiveSection],
  );

  const firstItemData: ClaimsItemsData = useMemo(
    () => ({
      count: dataToRender?.count ?? 0,
      totalProposed: dataToRender?.totalProposed ?? 0,
      label:
        CLAIMS_DETAILED_VIEW_NAV_BTNS.find(
          (item) => item.value === claimsActiveSection,
        )?.label ?? '',
    }),
    [dataToRender?.count, dataToRender?.totalProposed, claimsActiveSection],
  );

  if (!claimsActiveSection || !dataToRender) return null;

  return (
    <div className="dashboard-detailed">
      <DetailedViewNavButtons sectionType={SectionType.Claims} />
      <div className="dashboard-detailed__data">
        <DetailedItem
          firstItem
          dataToRender={firstItemData}
          className="detailed-item"
          sectionType={SectionType.Claims}
        />
        {dataToRender?.items.map((item, idx) => {
          return (
            <DetailedItem
              dataToRender={item}
              className="detailed-item detailed-item__secondary"
              key={`${item.label}_${idx}`}
              sectionType={SectionType.Claims}
            />
          );
        })}
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SelectableData,
  SelectableDataEmptyAllowed,
} from 'types/selectableDataTypes';
import { mapMajorCategories } from 'utils/categoryMapper';
import { mapMajorCategories as mapMajorCategories2 } from 'utils/categoryMapper2';

const SELECTABLE_DATA_DEFAULT_STATE = {
  legalEntities: [],
  // Categories
  categories: [],
  categoriesMajorCategories: [],
  categoriesMajorCategoriesOptions: [],

  // we will remove previous line when we convert MultiSelect to MultiSelectExtendable
  categoriesMajorCategoriesOptions2: [],

  // Action statuses
  // TO DO: this was renamed as Workflow status for now only on UI side, to be renamed in the data as well
  actionStatuses: [],
  actionStatusDynamicLabels: [],

  //Contract
  contractCategories: [],
  contractStatuses: [],
  contractTypes: [],
  contractTypesByCategory: [],
  contractExhibitTypes: [],

  //Project details
  projectDetails: undefined,

  //Bookmarks
  bookmarks: [],
};

export const initialState: SelectableReducerState = {
  loading: true,
  data: SELECTABLE_DATA_DEFAULT_STATE,
};

export interface SelectableReducerState {
  data: SelectableData;
  error?: string;
  loading: boolean;
}

const selectableSlice = createSlice({
  name: 'selectable',
  initialState: initialState,
  reducers: {
    selectableFetch(state, action) {
      state.loading = true;
    },
    selectableLoaded(state, action: PayloadAction<SelectableDataEmptyAllowed>) {
      state.loading = false;
      const data = {
        ...state.data,
        ...action.payload,
      };
      if (action.payload.categoriesMajorCategories) {
        data.categoriesMajorCategoriesOptions = mapMajorCategories(
          action.payload.categoriesMajorCategories,
        );
        data.categoriesMajorCategoriesOptions2 = mapMajorCategories2(
          action.payload.categoriesMajorCategories,
        );
      }

      if (action.payload.actionStatuses) {
        data.actionStatuses = action.payload.actionStatuses;
      }

      if (action.payload.actionStatusDynamicLabels) {
        data.actionStatusDynamicLabels = action.payload.actionStatusDynamicLabels;
      }
      if (action.payload.contractCategories) {
        data.contractCategories = action.payload.contractCategories;
      }
      if (action.payload.contractTypes) {
        data.contractTypes = action.payload.contractTypes;
      }
      if (action.payload.contractExhibitTypes) {
        data.contractExhibitTypes = action.payload.contractExhibitTypes;
      }

      if (action.payload.contractStatuses) {
        data.contractStatuses = action.payload.contractStatuses;
      }

      if (action.payload.projectDetails) {
        data.projectDetails = action.payload.projectDetails;
      }
      if (action.payload.bookmarks) {
        data.bookmarks = action.payload.bookmarks;
      }

      state.data = data;
    },
    selectableUpdated(state, action: PayloadAction<SelectableDataEmptyAllowed>) {
      if (action.payload.projectDetails) {
        state.data.projectDetails = action.payload.projectDetails;
      }
      if (action.payload.useMatching !== undefined && state.data.projectDetails) {
        state.data.projectDetails.useMatching = action.payload.useMatching;
      }
      if (action.payload.contractCategoryId) {
        state.data.contractTypesByCategory = [
          ...state.data.contractTypes.filter(
            (c) => c.contractCategoryId === action.payload.contractCategoryId,
          ),
        ];
      }
    },
  },
});

export const { selectableFetch, selectableLoaded, selectableUpdated } =
  selectableSlice.actions;
export default selectableSlice.reducer;

import classnames from 'classnames';
import { SliderDark } from 'components/UIComponents/SliderDark';
import './InputRange.css';

interface Props extends React.ComponentProps<'div'> {
  name: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onValueChange: (value: number) => void;
}

export const InputRange = ({
  className,
  name,
  value,
  min,
  max,
  step,
  onValueChange,
  ...props
}: Props) => {
  return (
    <div {...props} className={classnames('input-range__container', className)}>
      <label htmlFor={name}>{name}:</label>

      <SliderDark
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onValueChange}
      />
      <span>{value}</span>
    </div>
  );
};

import './ProgressMeter.css';

interface Props {
  percentage: number;
}

export const ProgressMeter = ({ percentage }: Props) => {
  const percentageValue = percentage > 100 ? 100 : percentage;

  return (
    <div className="progress-meter">
      <div
        className="progress-meter__fill"
        style={{ width: `${percentageValue}%` }}
      />
    </div>
  );
};

import { useAppDispatch } from 'hooks/reducerHooks';
import { createContext, useContext, useEffect, useMemo } from 'react';
import {
  actionStatus,
  BasicClientInfo,
  claimCategories,
  contracts,
  legalEntities as legalEntitiesApi,
} from '../../api/endpoints';

import { bookmarksApi } from 'api/endpoints/bookmarks';
import { projectDetailsApi } from 'api/endpoints/projectDetails';
import { userLogsApi } from 'api/endpoints/userLogsApi';
import { apiExhibitContract } from 'pages/ExhibitExporterContractPage/apiExhibitContract';
import { selectableFetch, selectableLoaded } from 'reducer/selectableDataReducer';
import { FetchStatus } from 'types/fetch-status.types';
import { useBasicInfo } from './hooks/useBasicInfo';

export interface IData {
  loadingStatus: FetchStatus;
  data: {
    basicInfo: {
      initialized: boolean;
      loadingStatus: FetchStatus;
      clientInfo: BasicClientInfo;
      init: () => Promise<void>;
      setBasicClientInfo: (ci: BasicClientInfo) => unknown;
      setLogo: (ab: ArrayBuffer) => unknown;
    };
  };
}

const AppInitialDataContext = createContext<IData>({} as IData);

const loadSelectable = async (dispatch: any) => {
  dispatch(selectableFetch({}));
  const [
    legalEntities,
    categories,
    contractCategories,
    contractTypes,
    categoriesMajorCategories,
    actionStatuses,
    actionStatusDynamicLabels,
    contractStatuses,
    projectDetails,
    bookmarks,
    contractExhibitTypes,
  ] = await Promise.all([
    legalEntitiesApi.list(),
    claimCategories.get(),
    contracts.getContractsCategories(),
    contracts.getContractTypes(),
    claimCategories.majorCategories(),
    actionStatus.getActionStatusList(),
    actionStatus.getDynamicLabels(),
    contracts.staticGetStatuses(),
    projectDetailsApi.getProjectDetails(),
    bookmarksApi.getBookmarks(),
    apiExhibitContract.exhibitTypes(),
  ]);
  dispatch(
    selectableLoaded({
      legalEntities,
      categories,
      contractCategories,
      contractTypes,
      categoriesMajorCategories,
      actionStatuses,
      actionStatusDynamicLabels,
      contractStatuses,
      projectDetails,
      bookmarks,
      contractExhibitTypes,
    }),
  );
};

/**
 * Currently we load
      legalEntities,
      categories,
      contractCategories,
      contractTypes,
      categoriesMajorCategories,
      actionStatuses,
      actionStatusDynamicLabels,
      contractStatuses,
      projectDetails,
      bookmarks,

 total amount of data loaded it quite small but it will be used in most places of the app
 we make a conscious decision to load it at the page load as this data almost never changes on the BE
 */

// @todo: for now, we use both redux and react context api
// in future we should switch to redux completely. This is a good place to
// load all the initial data that application
// will depend on

export const AppInitialDataProvider = (props: { children: React.ReactNode }) => {
  const basicInfo = useBasicInfo();
  const { init, loadingStatus } = basicInfo;

  const dispatch = useAppDispatch();

  useEffect(() => {
    // initialize user tracking
    userLogsApi.track();
  }, []);

  useEffect(() => {
    if (loadingStatus === FetchStatus.Idle) {
      loadSelectable(dispatch);
      init();
    }
  }, [init, dispatch, loadingStatus]);

  const contextValue: IData = useMemo(() => {
    return {
      // if there are more hooks that are loading global app data
      // reflect that in loading variable
      loadingStatus: basicInfo.loadingStatus,
      data: {
        basicInfo,
      },
    };
  }, [basicInfo]);

  return (
    <AppInitialDataContext.Provider value={contextValue}>
      {props.children}
    </AppInitialDataContext.Provider>
  );
};

export const useAppInitialDataContext = () => useContext(AppInitialDataContext);

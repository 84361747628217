import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// React effect hook that ignores the first invocation (e.g. on mount)
// The signature is exactly the same as the useEffect hook

export const useUpdateEffect = (effectFn: EffectCallback, deps?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    return effectFn();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

import { ContractEditValues } from 'api/endpoints';
import './SingleContractMatches.css';

import classNames from 'classnames';
import { Section } from 'components/SingleClaim/Section';
import {
  ContractMatchRow,
  COLUMNS,
  useSingleContractMatches,
} from '../hooks/useSingleContractMatches';
import { assertUnreachable } from 'utils/assertUnreachable';
import { Link } from 'react-router-dom';
import { useContractToolQueryParams } from 'utils/hooks/useContractToolQueryParams';
import { useState } from 'react';

interface IProps {
  contract: ContractEditValues;
}

export const SingleContractMatches = ({ contract }: IProps) => {
  const {
    headerContract,
    matchedContracts,
    loading,
  } = useSingleContractMatches(contract);
  return (
    <div>
      <Section
        className={classNames('single-contract-matches', {
          'anim-bg-pulse': loading,
          'utils-obstruct': loading,
        })}
        title={`Match summary for: ${contract.matchCode}`}
      >
        <table className="single-contract-matches-grid">
          <thead>
            <tr className="contract-matches-grid--header">
              {COLUMNS.map((col) => (
                <th key={col.field}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {headerContract && (
              <tr className="contract-matches-grid--row contract-matches-grid--title-row">
                {COLUMNS.map((col) => (
                  <td
                    className="contract-matches-grid--cell"
                    key={`$title-${col.field}`}
                  >
                    <GridCell
                      field={col.field}
                      contract={headerContract}
                    />
                  </td>
                ))}
              </tr>
            )}

            {matchedContracts?.map((contractItem) => {
              return headerContract?.referenceNumber !== contractItem.referenceNumber ? (
                <tr key={`${contractItem.id}`} className="contract-matches-grid--row">
                  {COLUMNS.map((col) => (
                    <td
                      className="contract-matches-grid--cell"
                      key={`${contractItem.id}-${col.field}`}
                    >
                      <GridCell
                        field={col.field}
                        contract={contractItem}
                      />
                    </td>
                  ))}
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </Section>
    </div>
  );
};

interface IGridFieldProps {
  field: keyof ContractMatchRow;
  contract: ContractMatchRow;
  readOnly?: boolean;
}

const GridCell = ({
  field,
  contract,
}: IGridFieldProps) => {
  const { buildSingleContractPath } = useContractToolQueryParams();
  const [showHoverData, setShowHoverData] = useState<string | undefined>(undefined);

  switch (field) {
    case 'referenceNumber':
      return (
        <div className="contract-matches-grid--cell-bold">
          <Link target="_blank" to={buildSingleContractPath(contract.id)}>
            {contract[field]}
          </Link>
        </div>
      );
    case 'contractType':
      return <div className="contract-matches-grid--cell-bold">{contract[field]}</div>;
    case 'contractDate':
      {
        const isodate = new Date(contract[field]);
        const localedateformat = isodate.toLocaleDateString('en-US').split('T')[0].replaceAll('/', '.');
        return <div>{contract[field] ? localedateformat : ''}</div>;
      }
    case 'description':
      {
        return (
          <>
            <div 
              className="contract-matches-grid--cell-title"
              onMouseEnter={() => setShowHoverData(contract[field])}
            >
              {contract[field]}
            </div>
            {showHoverData && <HoverText text={contract[field]} onMouseLeave={() => setShowHoverData(undefined)}/>}
          </>
        )
      }
    case 'id':
    case 'category':
    case 'contractStatus':
      return <div>{contract[field]}</div>;
    default:
      return assertUnreachable(field);
  }
};

const HoverText = (props: {
  text: string;
  onMouseLeave: () => void;
}) => {
  return (
    <div className="hover-data" onMouseLeave={props.onMouseLeave}>
      {props.text}
    </div>
  );
};
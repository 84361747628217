import { ApButtonMain, ApButtonSecondary } from '@alixpartners/ui-components';
import classnames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ComponentProps } from 'react';
import './BatchEditorForm.css';
import { BatchEditUploadPanel } from './BatchEditUploadPanel';

type Props = ComponentProps<'div'> & {
  applyEnabled: boolean;
  sectionType: SectionType;
  templateFileName: string;
  onAddClick: () => void;
  onCancel: () => void;
  onApply: () => void;
};

export const BatchEditorForm = ({
  className,
  children,
  applyEnabled,
  sectionType,
  templateFileName,
  onAddClick,
  onCancel,
  onApply,
  ...props
}: Props) => {
  return (
    <div className={classnames('batcheditor-form', className)} {...props}>
      <BatchEditUploadPanel
        sectionType={sectionType}
        templateFileName={templateFileName}
      />
      <div className="batcheditor-form--title">{`Or use the options below to update selected ${sectionType}`}</div>

      <div className="batcheditor-form--body thin-scrollbar">
        {children}
        <div className="batcheditor-form--addpanel">
          <ApButtonMain onClick={onAddClick}>Add Another</ApButtonMain>
        </div>
      </div>

      <div className="batcheditor-form--footer">
        <ApButtonSecondary onClick={onCancel}>Cancel</ApButtonSecondary>
        <ApButtonMain onClick={onApply} disabled={!applyEnabled}>
          Apply Changes
        </ApButtonMain>
      </div>
    </div>
  );
};

import {
  FileType,
  IUploadItem,
  IUploadSection,
  ModuleToDelete,
  PublishSection,
} from 'types/dataUploaderTypes';
import { DataContentBox } from '../components/DataContentBox';
import { FileRow } from '../components/FileRow';
import { Extension, FileUploadPanel } from '../components/FileUploadPanel';
import './DataUploadContent.css';

import { DataPipelineStatusResponse, ProcessingStatus } from 'api/endpoints';
import { downloadErrors, downloadFile } from '../api/apiDataDownload';
import { PublishButton } from '../components/PublishButton';
import { SchedulesUpload } from '../components/SchedulesUpload';
import { CONTRACT_IMAGES_EXT } from '../utils/dataUploader.config';
import { DataUploadProgress } from './DataUploadProgress';

interface IProps {
  selectedSectionFiles: IUploadItem[];
  section: IUploadSection | undefined;
  pipeline: DataPipelineStatusResponse | undefined;
  isPublishing: boolean;
  isComparingFiles: boolean;
  onUpload: (
    items: IUploadItem[],
    moduleToDelete?: ModuleToDelete,
    sectionModule?: FileType,
  ) => void;
  onDelete: (item: IUploadItem) => void;
  onPublish: (fileType: PublishSection) => void;
}

export const DataUploadContent = ({
  selectedSectionFiles,
  section,
  pipeline,
  isPublishing,
  onUpload,
  onDelete,
  onPublish,
  isComparingFiles,
}: IProps) => {
  const handleOnUpload = (files: File[]) => {
    const type = section?.type;
    const items = files.map(
      (file) => ({ displayName: file.name, type, file } as IUploadItem),
    );
    onUpload(items, section?.moduleToDelete, type);
  };

  return (
    <div className="dataupload-content ">
      <DataUploadProgress pipeline={pipeline} isPublishing={isPublishing} />

      {!isPublishing && (
        <DataUploadBody
          isComparingFiles={isComparingFiles}
          section={section}
          displayItems={selectedSectionFiles}
          pipeline={pipeline}
          onUpload={handleOnUpload}
          onDelete={onDelete}
          onPublish={onPublish}
        />
      )}
    </div>
  );
};

export const DataUploadBody = (props: {
  isComparingFiles: boolean;
  section: IUploadSection | undefined;
  displayItems: IUploadItem[];
  pipeline: DataPipelineStatusResponse | undefined;
  onUpload: (files: File[]) => void;
  onDelete: (item: IUploadItem) => void;
  onPublish: (fileType: PublishSection) => void;
}) => {
  const {
    section,
    displayItems,
    pipeline,
    onUpload,
    onDelete,
    onPublish,
    isComparingFiles,
  } = props;

  const isMainScreen = !section;
  const isErrorShown = [
    ProcessingStatus.ApplicationException,
    ProcessingStatus.Failed,
  ].includes(pipeline?.processingStatus!);

  const sectionLabel = section
    ? `${section?.caption} files`
    : `All previously uploaded files`;

  return (
    <>
      {isMainScreen && !isErrorShown ? <DataUploadWelcomeText /> : null}

      {!isMainScreen && section.type !== FileType.Schedule ? (
        <DataFileUpload
          caption={section.caption}
          disabled={isComparingFiles}
          onUpload={onUpload}
          description={section?.description}
          uploadExtension={
            section.type === FileType.ContractImage ? CONTRACT_IMAGES_EXT : undefined
          }
        />
      ) : section?.type === FileType.Schedule ? (
        <SchedulesUpload onUpload={onUpload} displayItems={displayItems} />
      ) : null}

      {!!displayItems.length && (
        <div className="dataupload-body">
          <div className="dataupload-content__header">
            <span className="dataupload-content__label">{sectionLabel}</span>
            <div className="dataupload-content__actions">
              {section && (
                <PublishButton
                  isComparingFiles={isComparingFiles}
                  items={displayItems}
                  status={pipeline?.processingStatus}
                  onClick={() => onPublish(section.publishSection)}
                />
              )}
            </div>
          </div>

          {displayItems.map((item, index) => (
            <FileRow
              key={`fileRow${index}`}
              item={item}
              disabled={isComparingFiles}
              onDelete={onDelete}
              onDownload={downloadFile}
              onDownloadErrors={downloadErrors}
            />
          ))}
        </div>
      )}
    </>
  );
};

const DataUploadWelcomeText = () => (
  <DataContentBox label="Welcome to the data uploader">
    <div>
      Here you can upload all the data required for a Chapter 11 engagement.
      <p />
      Choose the appropriate data template on the right, input your data in Excel,
      export as a .csv file and upload to the corresponding data type on the left.
      <p />
      Files are validated at the point of upload. Once all required files have been
      uploaded the data can then be published to the Chapter 11 Platform for use
      within the Claims Tool.
    </div>
  </DataContentBox>
);

const DataFileUpload = (props: {
  caption: string;
  disabled?: boolean;
  onUpload: (files: File[]) => void;
  description?: string;
  uploadExtension?: Extension[];
}) => (
  <div className="dataupload-content__drag-box">
    <DataContentBox
      label={`Upload ${props.caption}`}
      description={props.description}
    >
      <FileUploadPanel
        onUpload={props.onUpload}
        accept={props.uploadExtension}
        disabled={props.disabled}
      />
    </DataContentBox>
  </div>
);

import { ApLoaderDefault } from '@alixpartners/ui-components';
import { LinkText } from 'components/UIComponents/LinkText';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';

interface Props {
  name: string;
  loading?: boolean;
  onClick: () => void;
}

export const ClaimImageLink = ({ name, loading, onClick }: Props) => {
  const { canDownloadFlag, claimImage } = useClaimAttachmentsContext();
  if (canDownloadFlag) {
    return (
      <div className="single-entity__primary-doc">
        <SvgIcon iconName={'text_snippet'} fill="var(--ap-color-ui-green-primary)" />
        <LinkText onClick={onClick}>{name}</LinkText>
        {loading && <ApLoaderDefault />}
      </div>
    );
  }

  // work-around to open the claim image in a new tab, if download from external service doesn't work
  return (
    <a
      className="single-entity__primary-doc ap-link-text"
      href={claimImage}
      target="_blank"
      rel="noreferrer"
    >
      <SvgIcon iconName={'text_snippet'} fill="var(--ap-color-ui-green-primary)" />
      {name}
    </a>
  );
};

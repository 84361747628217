import { useCallback, useRef } from 'react';
import cx from 'classnames';
import {
  ControlProps,
  OptionProps,
  MultiValueGenericProps,
  components,
} from 'react-select';
import {
  ComponentsConfig,
  InternalOption,
  InternalGroup,
  MainLabelType,
} from './GroupMultiSelect.types';
import { SearchIcon } from './GroupMultiSelect.icons';
import './GroupMultiSelect.css';
import { optionsToMainLabel } from './GroupMultiSelect.utils';
import { Checkbox, Emptybox } from '../InputCheckbox/InputCheckbox';

export const ReactSelectControl = (
  props: ControlProps<InternalOption, boolean, InternalGroup>,
) => {
  const { children } = props;
  return (
    <components.Control {...props}>
      <SearchIcon className="gms-search-icon" />
      {children}
    </components.Control>
  );
};

export const ReactSelectOption = (
  props: OptionProps<InternalOption, boolean, InternalGroup>,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const { data, className, isSelected, selectOption, isFocused } = props;
  const { label, value, checked, groupInfo } = data;

  const handleClick = useCallback(
    () => selectOption({ label, value, checked: !checked, groupInfo: groupInfo }),
    [checked, groupInfo, label, selectOption, value],
  );

  return (
    <div className={cx(className, 'gms__option')} onClick={handleClick} ref={ref}>
      <Checkbox className={cx('gms-checkbox gms-tick', { none: !isSelected })} />
      <Emptybox className={cx('gms-checkbox gms-empty', { none: isSelected })} />
      {data.label}
      <div
        className={cx('gms__focus-indicator', {
          'gms__focus-indicator-active': isFocused,
        })}
      />
    </div>
  );
};

export const ReactSelectMultiValueLabel = (
  props: MultiValueGenericProps<InternalOption, boolean, InternalGroup>,
) => {
  const { data } = props;
  return <span className={cx('gms__multi-value-label')}>{data.label}</span>;
};

export const customComponents: ComponentsConfig = {
  Option: ReactSelectOption,
  Control: ReactSelectControl,
  MultiValueLabel: ReactSelectMultiValueLabel,
  DropdownIndicator: null,
  IndicatorSeparator: null,
};

export const MainLabel: MainLabelType = (props) => {
  const { selected, className, placeholder } = props;
  const placeholderText = optionsToMainLabel(selected, placeholder);
  return <span className={className}>{placeholderText}</span>;
};

import { ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';

import './IconLoading.css';

type Props = React.ComponentProps<typeof ApLoaderDefault>;

export const IconLoading = ({ className, ...props }: Props) => {
  return (
    <ApLoaderDefault
      className={classNames('icon-loading', className)}
      loaderSize="sm"
      {...props}
    />
  );
};

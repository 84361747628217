import { FileType } from 'types/dataUploaderTypes';

export const getWarningMessage = (section: FileType) => {
  switch (section) {
    case FileType.Claims:
      return 'This operation will clear all Claims data (including: Scheduled or Filed Claims / Resolution Names / ClaimDocuments / Claim Comments  and Audit Logs /  Claim Reviewers and Reviewer Groups / Claim Categories /  Exhibit Reports / Dynamic Field) and delete the following files. Please note that this action is not reversible.';
    case FileType.Contracts:
      return 'This operation will clear all Contracts data (including: Contract data / Resolution Names / Contract Documents, Comments and Audit logs /  Contract Reviewers and Groups / Contract Types and Categories / Exhibit Reports / Dynamic Fields) and delete the following files. Please note that this action is not reversible.';
    case FileType.Sofa:
      return 'This operation will clear all SOFA`s data (including: Sofas part 1 to 14 and Global Notes / Slipsheets related to Sofas) and delete the following files. Please note that this action is not reversible.';
    case FileType.Schedule:
      return 'This operation will clear all Schedules data (Schedules data from AB to H and Global Notes / Slipsheets related to Schedules) and delete the following files. Please note that this action is not reversible.';
    case FileType.All:
      return 'This operation will clear ALL data (including: Legal Entities / Baseline matching data / Vendor data / module specific data) and delete the following files. Please note that this action is not reversible.';
    default:
      return `This operation will clear all ${section} data`;
  }
};

export const DeleteModalContentItem = ({
  title,
  renderContent,
}: {
  title: string;
  renderContent: () => JSX.Element | JSX.Element[] | string | undefined;
}) => {
  return (
    <div className="delete-modal-content__item">
      <p>{title}</p>
      <p>{renderContent()}</p>
    </div>
  );
};

import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import './ClaimsGoBackButton.css';

export const ClaimsGoBackButton = ({
  className,
  ...props
}: React.ComponentProps<typeof ApButtonMain>) => {
  return (
    <ApButtonMain
      className={classNames('claims-goback-button', className)}
      {...props}
    >
      <SvgIcon
        iconName="arrow_right"
        width={12}
        height={12}
        className="claims-goback-button__icon"
      />
      Go back
    </ApButtonMain>
  );
};

import { Column } from 'exceljs';
import { LegalEntity } from 'api/endpoints';
import {
  SORT_VALUES,
  useEntityTableSorting,
} from 'components/UIComponents/EntityTable/useTableSorting';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { createExcelWorkbook } from 'pages/ExhibitExporterContractPage/exhibitContractExporter/excelUtils';
import { columnDefaults } from 'pages/ExhibitExporterContractPage/exhibitContractExporter/templatesType';
import { useCallback, useMemo, useState } from 'react';
import { saveProjectDetailsThunk } from 'reducer/thunks/projectDetailsThunks';
import { downloadExcelFile } from 'utils/file';
import { formatDateAs } from 'utils/formatDate';

const EXCEL_COLUMN_LIST: Partial<Column>[] = [
  { header: 'Entity Name', width: 40, ...columnDefaults },
  { header: 'Case Number', width: 30, ...columnDefaults },
  { header: 'Legal entity ID', width: 15, ...columnDefaults },
];

export const useCasesEntities = () => {
  const { legalEntities, projectDetails } = useAppSelector((s) => s.selectable.data);
  const dispatch = useAppDispatch();
  const [exporting, setExporting] = useState(false);

  const initialSortState = useMemo(
    () => ({
      field: 'legalEntityId' as keyof LegalEntity,
      direction: SORT_VALUES.ASC,
    }),
    [],
  );

  const { sortIcon, sortState, handleSort } =
    useEntityTableSorting<LegalEntity>(initialSortState);

  const sortedItems = useMemo(
    () =>
      [...legalEntities].sort((a: LegalEntity, b: LegalEntity) => {
        const { field, direction } = sortState;
        if (field === 'legalEntityId') {
          return direction === SORT_VALUES.ASC
            ? Number(a.legalEntityId) - Number(b.legalEntityId)
            : Number(b.legalEntityId) - Number(a.legalEntityId);
        }
        return direction === SORT_VALUES.ASC
          ? String(a[field])?.localeCompare(String(b[field]))
          : String(b[field])?.localeCompare(String(a[field]));
      }),
    [legalEntities, sortState],
  );

  const onProjectDetailsEditSave = useCallback(
    async (formValues) => {
      const result = await dispatch(
        saveProjectDetailsThunk({
          projectName: formValues.projectName as string,
          primaryCaseNumber: formValues.primaryCaseNumber as number,
        }),
      );
      return result;
    },
    [dispatch],
  );

  const getWorkbook = useCallback(() => {
    const workbook = createExcelWorkbook('Legal entities', EXCEL_COLUMN_LIST);
    const firstSheet = workbook.getWorksheet(1);

    const sheetItems = sortedItems.map((r) => [
      r.entityName,
      r.caseNumber,
      r.legalEntityId,
    ]);
    sheetItems.forEach((item) => {
      firstSheet.addRow(item);
    });

    return workbook;
  }, [sortedItems]);

  const onEntitiesExport = useCallback(async () => {
    setExporting(true);
    const workbook = getWorkbook();

    await downloadExcelFile(
      workbook,
      `LegalEntities_${formatDateAs(new Date(), 'MM-DD-YYYY')}.xlsx`,
    );
    setExporting(false);
  }, [getWorkbook]);

  return {
    onProjectDetailsEditSave,
    projectDetails,
    legalEntities: sortedItems,
    sortIcon,
    handleSort,
    sortState,
    exporting,
    onEntitiesExport,
  };
};

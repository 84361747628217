import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';
import classnames from 'classnames';
import { ComponentProps } from 'react';
import './PageButtonsGroup.css';

export interface PageTabButton {
  id: string;
  label: string;
  disabled?: boolean;
  body?: React.ReactChild;
}

interface Props extends ComponentProps<'div'> {
  selectedId?: PageTabButton['id'];
  buttons: PageTabButton[];
  additional?: JSX.Element;
  onButtonClick?: (button: PageTabButton) => void;
}

export const PageButtonsGroup = ({
  selectedId,
  buttons,
  className,
  additional,
  onButtonClick,
  ...props
}: Props) => {
  return (
    <div className={classnames('page-button-group', className)} {...props}>
      <div className="page-button-group__buttons">
        <ApButtonGroup justifyContent="initial" spacingSize={0}>
          {buttons.map((btn) => (
            <ApButtonTab
              key={btn.id}
              autoFocus={false}
              disabled={btn.disabled}
              isSelected={selectedId === btn.id}
              onClick={() => onButtonClick?.(btn)}
              theme="light"
            >
              {btn.label}
            </ApButtonTab>
          ))}
        </ApButtonGroup>
      </div>

      {additional && (
        <div className="page-button-group__additional">{additional}</div>
      )}
    </div>
  );
};

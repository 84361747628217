import classNames from 'classnames';
import { useId } from 'hooks/useId';
import './InputCheckbox.css';

export const Checkbox: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={classNames('inputcheckbox-svg', className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447716 0 1V17C0 17.5523 0.447716 18 1 18H17C17.5523 18 18 17.5523 18 17V1C18 0.447715 17.5523 0 17 0H1ZM15.75 4.90651L14.1237 3.375L6.91096 10.3701L3.89752 7.36858L2.25 8.87924L6.88913 13.5L15.75 4.90651Z"
      />
    </svg>
  );
};

export const Emptybox: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={classNames('inputcheckbox-svg', className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 0H1.125C0.50368 0 0 0.50368 0 1.125V16.875C0 17.4963 0.50368 18 1.125 18H16.875C17.4963 18 18 17.4963 18 16.875V1.125C18 0.50368 17.4963 0 16.875 0ZM2.25 15.75V2.25H15.75V15.75H2.25Z"
      />
    </svg>
  );
};

export const CircleBase: React.FC<{ className?: string; isEmpty?: boolean }> = ({
  className,
  isEmpty = false,
}) => {
  return (
    <svg
      className={classNames('inputcheckbox-svg', className, 'circle-base')}
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26ZM16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
      />
      {isEmpty ? <></> : <circle cx="16" cy="16" r="6" />}
    </svg>
  );
};

export const FilledCircle: React.FC<{ className?: string }> = ({ className }) => (
  <CircleBase className={className} />
);

export const EmptyCircle: React.FC<{ className?: string }> = ({ className }) => (
  <CircleBase className={className} isEmpty />
);

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export type InputCheckboxProps = Omit<InputProps, 'type'> & {
  children?: React.ReactNode;
  type: 'radio' | 'checkbox';
};

export function InputCheckbox(props: InputCheckboxProps) {
  const { id: idProp, className, children, disabled, type, ...rest } = props;
  const id = useId(idProp);

  return (
    <span
      data-type={type}
      className={classNames(
        'inputcheckbox',
        { 'inputcheckbox-disabled': disabled },
        className,
      )}
    >
      <input
        id={id}
        className="inputcheckbox__input"
        type={type}
        disabled={disabled}
        {...rest}
      ></input>
      <label className="inputcheckbox__label" htmlFor={id}>
        {type === 'checkbox' ? (
          <Checkbox className="checkbox box" />
        ) : (
          <FilledCircle className="checkbox box" />
        )}
        {type === 'checkbox' ? (
          <Emptybox className="emptybox box" />
        ) : (
          <EmptyCircle className="emptybox box" />
        )}
        {children}
      </label>
    </span>
  );
}

import { useCallback, useState } from 'react';
import { FormValidState } from 'utils/form/form-utils';

export type UseControlledValuesReturn = {};

export function useControlledValues(
  setFormSubmitted?: (submitted: boolean) => void,
) {
  const [controlledValues, setControlledValues] = useState<Record<string, any>>({});
  const [controlledVaidities, setControlledValidities] = useState<
    Record<string, FormValidState>
  >({});

  const getValue = useCallback(
    (name: string) => {
      return controlledValues[name];
    },
    [controlledValues],
  );

  const setValue = useCallback(
    (name: string, value: any) => {
      setControlledValues((prev) => {
        return { ...prev, [name]: value };
      });
      setFormSubmitted?.(false);
    },
    [setFormSubmitted],
  );

  const getValidity = useCallback(
    (name: string) => {
      const validity: FormValidState = controlledVaidities[name] ?? 'idle';
      return validity;
    },
    [controlledVaidities],
  );

  const setValidity = useCallback((name: string, value: any) => {
    setControlledValidities((prev) => {
      return { ...prev, [name]: value };
    });
  }, []);

  const clearValues = useCallback(() => {
    setControlledValues({});
    setControlledValidities({});
  }, []);

  return {
    getValue,
    setValue,
    controlledValues,
    clearValues,
    setValidity,
    getValidity,
    generalValidity: computeAllValid(controlledVaidities),
    setControlledValues,
  };
}

function computeAllValid(
  validities: Record<string, FormValidState>,
): FormValidState {
  const validitiesValues = Object.values(validities);
  if (validitiesValues.length === 0) return 'idle';
  const allIdle = validitiesValues.every((v) => v === 'idle');
  if (allIdle) return 'idle';

  const allValid = validitiesValues.every((v) => v === 'valid');
  if (allValid) return 'valid';

  return 'invalid';
}

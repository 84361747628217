import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  DropdownCategories,
  ContractCategoryItemBase,
  AllContractsCategories,
  EditContractCategoryRequest,
  CategoryType,
} from 'api/endpoints';
import {
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import { uniqBy } from 'lodash';
import {
  editContractCategoryThunk,
  getAllContractsCategoriesThunk,
  getDropdownContractsCategoriesThunk,
  saveContractsCategoryThunk,
  setToggleAllThunk,
} from 'reducer/thunks/contractsCategoriesThunks';
import {
  SORT_VALUES,
  useEntityTableSorting,
} from 'components/UIComponents/EntityTable/useTableSorting';
import { exportCategoriesItems } from 'pages/SettingsPage/utils/exportCategoriesItems';

export const useContractsCategories = () => {
  const [toggleAll, setToggleAll] = useState(false);

  const initialSortState = useMemo(
    () => ({
      field: 'categoryName' as keyof AllContractsCategories,
      direction: SORT_VALUES.ASC,
    }),
    [],
  );

  const { sortState, handleSort, sortIcon } =
    useEntityTableSorting<AllContractsCategories>(initialSortState);

  const {
    dropdownCategories,
    allCategories: categories,
    fetching,
  } = useAppSelector((s) => s.contractCategoriesSettings);
  const dispatch = useAppDispatch();

  const sortedItems = useMemo(
    () =>
      [...categories].sort(
        (a: AllContractsCategories, b: AllContractsCategories) => {
          const { field, direction } = sortState;
          return direction === SORT_VALUES.ASC
            ? String(a[field])?.localeCompare(String(b[field]))
            : String(b[field])?.localeCompare(String(a[field]));
        },
      ),
    [categories, sortState],
  );

  const options = useMemo(
    () => uniqBy(mapCategoryByName(dropdownCategories), (c) => c.label),
    [dropdownCategories],
  );

  useEffect(() => {
    dispatch(getDropdownContractsCategoriesThunk());
    dispatch(getAllContractsCategoriesThunk());
  }, [dispatch]);

  const onAddSave: OnAddSave<ContractCategoryItemBase> | any = useCallback(
    async (formValues: ContractCategoryItemBase) => {
      const existing = categories.find(
        (c) => c.categoryName === formValues.categoryName,
      );
      if (existing) {
        return dispatch(
          saveContractsCategoryThunk({
            ...formValues,
            contractCategoryId: existing.contractCategoryId,
            contractTypeName: formValues.contractTypeName,
            categoryName: null,
          }),
        );
      }
      return dispatch(saveContractsCategoryThunk(formValues));
    },
    [dispatch, categories],
  );

  const onEditSave: OnEditSave<AllContractsCategories> | any = useCallback(
    async (
      formValues: EditContractCategoryRequest,
      item: AllContractsCategories,
    ) => {
      const editValues: EditContractCategoryRequest = {
        contractTypeId: item.contractTypeId,
        enabled: item.enabled,
        contractTypeName:
          formValues.contractTypeName !== item.contractTypeName
            ? formValues.contractTypeName
            : null,
      };
      return dispatch(editContractCategoryThunk(editValues));
    },
    [dispatch],
  );

  const warningMessage = useMemo(
    () =>
      `${
        categories.filter((categ) => categ.usedInContracts).length
      } categories in use. Please make sure the categories you disable are not assigned to any contracts`,
    [categories],
  );

  const allCategories = useMemo(
    () =>
      sortedItems.map((category) => ({
        ...category,
        hideEdit: category.categoryType === CategoryType.Baseline,
      })),
    [sortedItems],
  );

  const handleToggleAll = useCallback(async () => {
    setToggleAll((prev) => !prev);
    return dispatch(setToggleAllThunk(!toggleAll));
  }, [dispatch, toggleAll]);

  const exportEnabledCategories = async () => {
    await exportCategoriesItems(
      categories
        .filter((category) => category.enabled)
        .map((item) => [item.categoryName ?? '', item.contractTypeName]),
      {
        fileName: 'ContractsCategories.xlsx',
        columns: [
          { header: 'Category', width: 30 },
          { header: 'ContractType', width: 70 },
        ],
        templateName: 'ContractCategory.xlsx',
      },
    );
  };

  return {
    options,
    onAddSave,
    allCategories,
    warningMessage,
    onEditSave,
    toggleAll,
    handleToggleAll,
    fetching,
    sortState,
    handleSort,
    sortIcon,
    exportEnabledCategories,
  };
};

const mapCategoryByName = (categories: DropdownCategories[]) => {
  return categories?.map((category) => ({
    value: String(category.id),
    label: category.categoryName,
  }));
};

import { useState } from 'react';
import { Help, HelpDetails } from './Help/Help';
import { HelpPageNavigation } from './Help/HelpPageNavigation';

import { Layout } from 'components/Layout';
import { useSearchParams } from 'react-router-dom';
import { compactObject } from 'utils/lodashEx';
import { PageHeaderTitle } from '../../components/PageHeaderTitle';
import './HelpPage.css';

export const HelpPage = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [selected, setSelected] = useState(() => parseInitialUrlParams(urlParams));

  const handleMenuItemClick = (section: string | undefined) => {
    setSelected({ section });
    setUrlParams(compactObject({ section }));
  };

  const handleSelectedChange = (value: HelpDetails) => {
    setSelected(value);

    const { section } = value;
    setUrlParams(compactObject({ section }));
  };

  return (
    <Layout className={'help-page'}>
      <PageHeaderTitle title="Help" />
      <div className="help-page-body">
        <HelpPageNavigation
          selected={selected?.section}
          onSelectedChange={handleMenuItemClick}
        />
        <Help selected={selected} onChange={handleSelectedChange} />
      </div>
    </Layout>
  );
};

const parseInitialUrlParams = (params: URLSearchParams): HelpDetails | undefined => {
  const section = params.get('section') ?? undefined;
  const topics = params.get('topics')?.split(',') ?? undefined;
  const search = params.get('search') ?? undefined;

  if (!section && !topics && !search) return undefined;

  return {
    section,
    topics,
    search,
  };
};

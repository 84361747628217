import { ApLoaderDefault } from '@alixpartners/ui-components';
import { ContractRow, contracts as contractsApi } from 'api/endpoints';
import { SingleContractInputs } from './components/SingleContractInputs';
import { SingleContractMatrix } from './components/SingleContractMatrix';
import { EditContractHook } from './hook/useEditContract';
import './SingleContractOverview.css';

import classNames from 'classnames';
import { Section } from 'components/SingleClaim/Section';
import { useAppDispatch } from 'hooks/reducerHooks';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo } from 'react';
import { loadContractPaymentPositions } from 'reducer/thunks/contractPaymentPositionsThunk';
import {
  contractReviewerFetch,
  contractReviewerLoaded,
} from 'reducer/contractReviewerReducer';
import { selectableUpdated } from 'reducer/selectableDataReducer';
import { SectionType } from '../../../ClaimsContractsToolPage/types';
import { useEditAndFilterDynamicFields } from '../../../SettingsPage/sections/DynamicFields/useDynamicFields';
import { AP_USER_DEFAULT_SECTIONS } from './const/apUser';
import { setRelatedContractsNumber } from 'reducer/contractsReducer';
import { orderBy } from 'lodash';

export interface SingleContractOverviewProps {
  contract?: ContractRow;
  editContractHook: EditContractHook;
}

export const SingleContractOverview = ({
  editContractHook,
}: SingleContractOverviewProps) => {
  const { loading, contract, editField, isEditable } = editContractHook;
  const { contractsData: allContractDynamicFields, formatDynamicField } =
    useEditAndFilterDynamicFields(SectionType.Contracts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadContractReviewers = async (dispatch: any) => {
      if (!contract?.id) return;
      dispatch(contractReviewerFetch());
      const contractsReviewers = await contractsApi.getContractReviewers(
        contract?.id,
      );
      dispatch(contractReviewerLoaded(contractsReviewers as any));
    };

    loadContractReviewers(dispatch);
    loadContractPaymentPositions(dispatch);
  }, [contract?.id, dispatch]);

  useEffect(() => {
    dispatch(
      selectableUpdated({
        contractCategoryId: Number(contract?.contractCategoryId),
      }),
    );
    dispatch(setRelatedContractsNumber(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const sectionsWithDynamicFields = useMemo(() => {
    const pageSections = cloneDeep(AP_USER_DEFAULT_SECTIONS);
    const ordered = orderBy(allContractDynamicFields, (df) => df.propertyName);
    ordered?.forEach((field) => {
      pageSections
        ?.find((section) => section.id === field.displayIn && field.enabled)
        ?.inputs?.push({ ...formatDynamicField(field) });
    });

    return pageSections;
  }, [allContractDynamicFields, formatDynamicField]);

  if (loading) return <ApLoaderDefault />;
  if (!contract) return null;

  return (
    <div className="single-contract-overview">
      {sectionsWithDynamicFields.map((section, index) => {
        const { title, inputs, matrix, orientation } = section;
        const isMatrix = !!matrix;

        return (
          <Section
            title={isMatrix ? undefined : title}
            className={classNames('single-contract-overview__section', {
              'single-contract-overview__section-is-matrix': isMatrix,
            })}
            key={`section${index}`}
          >
            {inputs && (
              <SingleContractInputs
                orientation={orientation || 'horizontal'}
                inputs={inputs}
                contract={contract}
                isEditable={isEditable}
                editField={editField}
              />
            )}
            {isMatrix && (
              <div className="single-contract-overview__matrix-container">
                <SingleContractMatrix
                  title={title}
                  matrix={matrix}
                  contract={contract}
                  isEditable={isEditable}
                  editField={editField}
                />
                <div className="single-contract-overview__matrix-label">
                  <p>
                    Please update fields marked in green based on your review of the
                    contract. Upon approval, these values will be reflected in the
                    'Proposed amounts' columns.
                  </p>
                  <p>
                    Upon court-order stipulation, these values will reflect the
                    final, allowed value of the contract.
                  </p>
                </div>
              </div>
            )}
          </Section>
        );
      })}
    </div>
  );
};

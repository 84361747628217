import classNames from 'classnames';
import './SwitchButton.css';

type Props = {
  id?: string;
  className?: string;
  label?: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
};

export const SwitchButton = ({
  id,
  className,
  label,
  value,
  disabled,
  onChange,
}: Props) => {
  const handleClick = () => {
    if (!disabled) onChange?.(!value);
  };

  return (
    <div
      id={id}
      className={classNames(
        'switch-button',
        {
          'switch-button_disabled': disabled,
          'switch-button_on': value,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className="switch-button__switch">
        <div className="switch-button__circle" />
      </div>
      <div className="switch-button__label">{label}</div>
    </div>
  );
};

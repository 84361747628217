import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { fetchReportPartsThunks } from 'reducer//thunks/report-legal-entities-thunk';
import { FetchStatus } from 'types/fetch-status.types';

export function useReportParts() {
  const slice = useAppSelector((s) => s.reportParts);
  const { type } = slice;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === FetchStatus.Idle) {
      dispatch(fetchReportPartsThunks());
    }
  }, [dispatch, type]);

  return slice;
}

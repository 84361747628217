import { useState } from 'react';
import './SimpleAccordion.css';
import { ApIcon } from '@alixpartners/ui-components';

type SAProps = {
  summary: {
    openedTitle: string;
    closedTitle?: string;
    rightPart?: string | JSX.Element;
  };
  content: string;
  withIcon?: boolean;
  defaultOpened?: boolean;
  iconColor?: string;
};

export const SimpleAccordion = ({
  summary: { openedTitle, closedTitle, rightPart },
  content,
  withIcon,
  defaultOpened,
  iconColor,
}: SAProps) => {
  const [opened, setOpened] = useState(defaultOpened);

  return (
    <details className="details-accordion" open={defaultOpened}>
      <summary>
        <span
          className="details-accordion__left"
          onClick={() => setOpened((prev) => !prev)}
        >
          {opened ? openedTitle : closedTitle ?? openedTitle}
          {withIcon && (
            <ApIcon
              iconName={opened ? 'arrow_drop_up' : 'arrow_drop_down'}
              iconColor={iconColor ?? '#5CB335'}
            />
          )}
        </span>

        {!!rightPart && (
          <span className="details-accordion__right">{rightPart}</span>
        )}
      </summary>
      <p>{content}</p>
    </details>
  );
};

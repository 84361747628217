import classNames from 'classnames';
import React from 'react';
import './SettingsContentPanel.css';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export const SettingsContentPanel = ({ className, children }: IProps) => {
  return (
    <div className={classNames('settings-page-content', className)}>
      <div>
        <div className="settings-content__body">{children}</div>
      </div>
    </div>
  );
};

import classnames from 'classnames';
import { isNil } from 'lodash';
import { SurvivingClaimNoSelect } from 'pages/SingleClaimPage/sections/SingleClaimOverview/components/SurvivingClaimNoSelect';
import './SurvivingClaimEdit.css';

type SurvivingClaimProps = React.ComponentProps<typeof SurvivingClaimNoSelect>;
type Props = SurvivingClaimProps & {
  label?: string;
};

export const SurvivingClaimEdit = ({ label, ...selectProps }: Props) => {
  return (
    <div className={classnames('surviving-claim-edit')}>
      {!isNil(label) && (
        <label className="surviving-claim-edit__label">{label}</label>
      )}

      <SurvivingClaimNoSelect {...selectProps} />
    </div>
  );
};

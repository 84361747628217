import useGraphql from 'api/graphql/useGraphql';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { DataTableGrid } from 'pages/ClaimsSettlementsPage/components/DataTableGrid';
import { useSettlementGridContext } from 'pages/ClaimsSettlementsPage/SettlementGridContextProvider';
import { useMemo } from 'react';
import { createSettlementDataSource } from '../createSettlementDataSource';
import './SettlementGrid.css';
import {
  SETTLEMENT_ADMIN_COLUMNS,
  SETTLEMENT_ADMIN_GRID_COLUMNS,
} from './settlementGridColumns';

export const SettlementGrid = () => {
  const [client] = useGraphql();

  const { gridRef, gridState, setRowTotalCount, updateGridState } =
    useSettlementGridContext();

  const dataSource = useMemo(
    () =>
      createSettlementDataSource(client, SETTLEMENT_ADMIN_COLUMNS, {
        setRowTotalCount,
      }),
    [client, setRowTotalCount],
  );

  return (
    <DivWithLoader className="settlement-admin-list" loading={false}>
      <DataTableGrid
        ref={gridRef}
        className="settlement-admin__grid"
        initialState={gridState}
        dataSource={dataSource}
        columnDefs={SETTLEMENT_ADMIN_GRID_COLUMNS}
        onGridStateChanged={updateGridState}
      />
    </DivWithLoader>
  );
};

// const getRowIdValue = (param: any) => param?.data?.id;

import { ClaimRow } from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import _, { isEmpty } from 'lodash';
import { getClaimsBy } from 'pages/ExhibitExporterPage/utils/apiExhibitClaim';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { sortByRefAsc } from 'utils/agGridHotchocolate/const';
import { AgGridFilter } from 'utils/agGridHotchocolate/types';

const DEBOUNCED_DELEY = 700;

export function useClaimsSearchByRef(
  searchText: string,
  options?: { limit?: number },
) {
  const { limit } = options ?? {};
  const [client] = useGraphql();
  const [data, setData] = useState<ClaimRow[]>([]);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(
    async (text: string) => {
      setData([]);
      if (_.isEmpty(text)) return;

      setLoading(true);

      const filterModel: AgGridFilter = {
        filterType: 'text',
        type: 'contains',
        filter: text,
      };

      const data = await getClaimsBy(
        client,
        'referenceNumber',
        filterModel,
        sortByRefAsc,
        { limit },
      );

      setData(data);
      setLoading(false);
    },
    [client, limit],
  );

  const loadDataDebounced = useMemo(
    () => _.debounce(loadData, DEBOUNCED_DELEY),
    [loadData],
  );

  useEffect(() => {
    loadDataDebounced(searchText);

    if (isEmpty(searchText)) {
      setData([]);
    }
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, loading };
}

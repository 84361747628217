import {
  ContractsGroupUsersItem,
  ContractsUserGroupsResponse,
  contractUserGroup,
  GroupUsersItem,
} from 'api/endpoints';

import { useCallback, useEffect } from 'react';
import {
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  editContractUserGroupThunk,
  getContractUserGroupThunk,
  saveContractUserGroupThunk,
} from 'reducer/thunks/user-groups-thunks';

import { FetchStatus } from 'types/fetch-status.types';
import { error } from 'utils/alert';

export type GroupUsersItemData = {
  data: GroupUsersItem[];
  status: FetchStatus;
};

export function useEditAndAddContractsUserGroups() {
  const dispatch = useAppDispatch();
  const { data, status } = useContractsUserGroups();

  const formatFormValues = (formValues: ContractsUserGroupsResponse) => {
    return {
      name: formValues.name,
      userIds: [...formValues.users.map((u) => u)],
      contractCategoryIds: [
        ...formValues.contractCategories.map((c) => Number(c.value)),
      ],
    };
  };

  const onEditSave: OnEditSave<ContractsGroupUsersItem> | any = useCallback(
    async (
      formValues: ContractsUserGroupsResponse,
      item: ContractsGroupUsersItem,
    ) => {
      const values: Omit<ContractsGroupUsersItem, 'id'> = {
        ...formatFormValues(formValues),
      };
      return dispatch(editContractUserGroupThunk(values, item.id));
    },
    [dispatch],
  );

  const onAddSave: OnAddSave<ContractsGroupUsersItem> | any = useCallback(
    async (formValues: ContractsUserGroupsResponse) => {
      const values: Omit<ContractsGroupUsersItem, 'id'> = {
        ...formatFormValues(formValues),
      };
      return dispatch(saveContractUserGroupThunk(values));
    },
    [dispatch],
  );

  const onDelete = useCallback(
    async (userGroupId: number) => {
      const resp = await contractUserGroup.deleteContractAssignedGroupUsers(
        userGroupId,
      );
      if (!resp.error) {
        dispatch(getContractUserGroupThunk());
      } else {
        error(
          `${resp.error.title ? resp.error.title + '. ' : ''}${
            resp.error.body.errorMessage ??
            'Could not delete user group. Please try again'
          }`,
        );
      }
    },
    [dispatch],
  );

  return {
    onEditSave,
    onAddSave,
    status,
    data,
    onDelete,
  };
}

export function useContractsUserGroups() {
  const { data, status } = useAppSelector((s) => s.contractsUserGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === FetchStatus.Idle) {
      dispatch(getContractUserGroupThunk());
    }
  }, [dispatch, status]);

  return {
    status: status,
    data,
  };
}

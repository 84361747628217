import { ApButtonMain } from '@alixpartners/ui-components';
import { URL_PATH } from 'appUrls';
import classnames from 'classnames';
import { useNavigate } from 'react-router';
import './PageHeaderTitle.css';

interface Props {
  title?: string;
  className?: string;
}

export const PageHeaderTitle = ({ title, className }: Props) => {
  const navigate = useNavigate();
  const handleExit = () => {
    navigate(URL_PATH.HOME);
  };

  return (
    <div className={classnames('page-header-title', className)}>
      <h3>{title}</h3>
      <ApButtonMain
        iconName="outline_exit_to_app"
        className="help-content__exit-btn"
        onClick={handleExit}
      >
        Exit
      </ApButtonMain>
    </div>
  );
};

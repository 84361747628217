import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from 'reducer/store';

import { LicenseManager } from 'ag-grid-enterprise';
import { pdfjs } from 'react-pdf';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import './common.css';
import './index.css';

// !!!Important!!! the next two lines must be at the end of the import list
// otherwise you could see an error message on the deployment app
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// Set "pdf.worker.js" from "pdfjs-dist" library
// The "pdf.worker.js" file must be placed to the "Public" folder
// See details here: https://www.npmjs.com/package/react-pdf => "Configure PDF.js worker"
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

if (process.env.REACT_APP_INSIGHT_KEY) {
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSIGHT_KEY,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin as any],
    },
  });
  appInsights.loadAppInsights();
}

if (process.env.REACT_APP_AG_GRID_LICENSE) {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ReviewersEdit } from 'components/ReviewersEdit/ReviewersEdit';
import { ReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import { useAllUsers } from 'hooks/useAllUsers';
import { useAssignedGroupUsers } from 'hooks/useAssignedGroupUsers';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { FetchStatus } from 'types/fetch-status.types';

export type ClaimReviewersEditProps = {
  onChange: (item: ReviewersBatchItem) => void;
  label: string;
};

export const ClaimReviewersEdit = ({ onChange, label }: ClaimReviewersEditProps) => {
  const group = useAssignedGroupUsers();
  const user = useAllUsers();

  const isFetching =
    group.status === FetchStatus.Fetching || user.status === FetchStatus.Fetching;

  return (
    <ReviewersEdit
      placeholder={'Select users or user groups'}
      label={label}
      users={user.data || []}
      groups={group.data || []}
      loading={isFetching}
      section={SectionType.Claims}
      onChange={onChange}
    />
  );
};

import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';
import { CategorySelectType } from '../components/CategorySelect';
import { SCMatrix, SingleClaimSection } from '../types';

/**
 * in this file you can define displayed
 * field for ap user per resolution type status.
 *
 * For now only alter objection has a non-default permissions
 * and displayed fields
 */
const AMOUNTS_MATRIX: SCMatrix = {
  rows: [
    {
      label: 'Original',
      field: 'original',
    },
    {
      label: 'Current',
      field: 'current',
    },
    {
      label: 'Proposed',
      field: 'proposed',
    },
    {
      type: 'empty',
    },
    {
      label: 'High assessment',
      field: 'high',

      hideBoolean: true,
    },
    {
      label: 'Low assessment',
      field: 'low',

      hideBoolean: true,
    },
    {
      label: 'POR',
      field: 'pOR',
    },
    {
      label: 'Client assessed',
      field: 'client',
    },
    {
      label: 'Counsel assessed',
      field: 'counsel',
    },
    {
      label: 'AlixPartners assessed',
      field: 'alixPartners',
    },
  ],
  columns: [
    {
      label: 'Secured',
      field: 'secured',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Admin',
      field: 'administrative',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Priority',
      field: 'priority',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Unsecured',
      field: 'unsecured',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Total',
      field: 'total',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Debtor',
      field: 'debtorId',
      type: 'category',
      category: {
        type: CategorySelectType.LegalEntities,
        name: 'debtorId',
      },
    },
    {
      type: 'empty',
    },
    {
      label: 'Contingent',
      field: 'contingent',
      type: 'bool',
    },
    {
      label: 'Unliquidated',
      field: 'unliquidated',
      type: 'bool',
    },
    {
      label: 'Disputed',
      field: 'disputed',
      type: 'bool',
    },
  ],
};

export const AP_USER_DEFAULT_SECTIONS: SingleClaimSection[] = [
  {
    id: PageSections.Categorization,
    title: 'Categorization',
    inputs: [
      {
        label: 'Major/minor category',
        field: 'minorCategory',

        category: {
          type: CategorySelectType.Category,
          name: 'minorCategoryId',
        },
      },
    ],
  },
  {
    id: PageSections.Assignment,
    title: 'Assignment',
    inputs: [
      {
        label: 'Claim reviewer',
        field: 'claimReviewer',

        category: {
          type: CategorySelectType.ClaimReviewer,
          name: 'claimReviewer',
        },
      },
    ],
  },
  {
    id: PageSections.ClaimStatus,
    title: 'Claim status',
    orientation: 'vertical',
    inputs: [
      {
        label: 'Workflow status',
        field: 'actionStatus',

        category: {
          type: CategorySelectType.ActionStatus,
          name: 'actionStatus',
        },
      },
      {
        label: 'Resolution type',
        field: 'actionTypeId',

        category: {
          type: CategorySelectType.ActionType,
          name: 'actionTypeId',
        },
      },
      {
        label: 'Resolution subtype',
        field: 'actionSubstatusId',

        category: {
          type: CategorySelectType.ActionSubstatus,
          name: 'actionSubstatusId',
        },
      },
      {
        label: 'Resolution name',
        field: 'actionNameId',

        category: {
          type: CategorySelectType.ActionName,
          name: 'actionNameId',
        },
      },
      {
        label: 'Review level',
        field: 'reviewLevelId',
        category: {
          type: CategorySelectType.ReviewLevel,
          name: 'reviewLevelId',
        },
      },
      {
        label: 'Action review status',
        field: 'actionReview',
      },
      {
        label: 'Surviving claim no(s)',
        field: 'survivingClaimNumber',

        category: {
          type: CategorySelectType.SurvivingClaimNo,
          name: 'survivingClaimNumber',
        },
      },
      {
        label: 'Objection commentary',
        field: 'objectionCommentary',
        textField: true,
      },
    ],
  },
  {
    id: PageSections.AdditionalInformation,
    title: 'Additional information',
    inputs: [
      {
        label: 'Notes',
        field: 'notes',
        textField: true,
      },
      {
        label: 'Parent claim number',
        field: 'parentClaimNumber',
      },
      {
        label: 'Settlement number',
        field: 'settlementNumber',
      },
      {
        label: 'BarDateRef',
        field: 'barDateRef',
      },
      {
        label: 'POCSupport',
        field: 'pocSupport',
        toggle: true,
      },
      {
        label: 'MatchCurrentPrepetitionOpenAp',
        field: 'matchCurrentPrepetitionOpenAp',
        amountField: true,
      },
    ],
  },
  {
    id: PageSections.Amounts,
    title: 'Amounts',
    matrix: AMOUNTS_MATRIX,
  },
  {
    id: PageSections.CounterpartyInfo,
    title: 'Counterparty Contact Information',
    //type: 'address' - is used here to prevent to show browser "Save Address" dialog window
    inputs: [
      {
        label: 'Contact full name',
        field: 'contactFullName',
      },
      {
        label: 'Email',
        field: 'email',
        type: 'address',
      },
      {
        label: 'Address Line 1',
        field: 'address1',
        type: 'address',
      },
      {
        label: 'Address Line 2',
        field: 'address2',
        type: 'address',
      },
      {
        label: 'Address Line 3',
        field: 'address3',
        type: 'address',
      },
      {
        label: 'Address Line 4',
        field: 'address4',
        type: 'address',
      },
      {
        label: 'City',
        field: 'city',
        type: 'address',
      },
      {
        label: 'Phone number',
        field: 'phone',
        type: 'address',
      },
      {
        label: 'State',
        field: 'state',
        type: 'address',
      },
      {
        label: 'Country',
        field: 'country',
        type: 'address',
      },
      {
        label: 'Zip code',
        field: 'zip',
        type: 'address',
      },
    ],
  },
];

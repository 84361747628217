import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalNote, SofaOrScheduleFileType } from 'api/endpoints';
import { FileType } from 'types/dataUploaderTypes';
import { BasicFetchState, FetchStatus } from 'types/fetch-status.types';

export type GlobalNotesSlice = {
  data: GlobalNote[] | undefined;
  fetchState: BasicFetchState;
  deleteState: BasicFetchState;
  updateState: BasicFetchState;
};

export const initialState: GlobalNotesSlice = {
  data: undefined,
  fetchState: {
    status: FetchStatus.Idle,
  },
  deleteState: {
    status: FetchStatus.Idle,
  },
  updateState: {
    status: FetchStatus.Idle,
  },
} as GlobalNotesSlice;

const reducers = {
  fetching(state: GlobalNotesSlice) {
    state.fetchState.status = FetchStatus.Fetching;
  },
  done(
    state: GlobalNotesSlice,
    action: PayloadAction<{
      items: GlobalNote[];
    }>,
  ) {
    state.fetchState.status = FetchStatus.Done;
    state.data = action.payload.items;
  },
  error(state: GlobalNotesSlice, action: PayloadAction<{ message: string }>) {
    state.fetchState = {
      status: FetchStatus.Error,
      error: action.payload,
    };
  },
  add(
    state: GlobalNotesSlice,
    action: PayloadAction<{
      items: GlobalNote[];
    }>,
  ) {
    const newItems = action.payload.items.map((item) => ({
      ...item,
      newlyAdded: true,
    }));
    if (!newItems.length) return;

    const newData = state.data ? [...newItems, ...state.data] : newItems;
    state.data = newData;
  },
  updateBegin(state: GlobalNotesSlice) {
    state.updateState.status = FetchStatus.Fetching;
  },
  updateDone(state: GlobalNotesSlice, action: PayloadAction<{ item: GlobalNote }>) {
    state.updateState.status = FetchStatus.Done;
    if (!state.data) return;

    const { item } = action.payload;
    const id = state.data.findIndex((r) => r.id === item.id);
    if (id < 0) return;

    state.data[id] = item;
    state.data = [...state.data];
  },
  updateError(state: GlobalNotesSlice, action: PayloadAction<{ message: string }>) {
    state.updateState = {
      status: FetchStatus.Error,
      error: action.payload,
    };
  },
  deleteFetching(state: GlobalNotesSlice) {
    state.deleteState.status = FetchStatus.Fetching;
  },
  deleteDone(
    state: GlobalNotesSlice,
    action: PayloadAction<{
      id: number;
    }>,
  ) {
    state.deleteState.status = FetchStatus.Done;
    const { id: deletedId } = action.payload;
    if (state.data) {
      state.data = state.data.filter((x) => x.id !== deletedId);
    }
  },
  deleteError(state: GlobalNotesSlice, action: PayloadAction<{ message: string }>) {
    state.deleteState = {
      status: FetchStatus.Error,
      error: action.payload,
    };
  },
};

const sofaGlobalNotesSlice = createSlice({
  name: 'sofa-global-notes',
  initialState,
  reducers,
});

const scheduleGlobalNotesSlice = createSlice({
  name: 'schedule-global-notes',
  initialState,
  reducers,
});

export const sofaGlobalNotesActions = sofaGlobalNotesSlice.actions;
export const scheduleGlobalNotesActions = scheduleGlobalNotesSlice.actions;
export const sofaGlobalNotesReducer = sofaGlobalNotesSlice.reducer;
export const scheduleGlobalNotesReducer = scheduleGlobalNotesSlice.reducer;

export const getActions = (fileType: SofaOrScheduleFileType) =>
  fileType === FileType.Sofa ? sofaGlobalNotesActions : scheduleGlobalNotesActions;

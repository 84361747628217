import dayjs from 'dayjs';
import moment from 'moment';

const dayFormatter = {
  format(dayInMonth: number) {
    const lastDigit = dayInMonth % 10;
    let suffix = 'th';
    if (dayInMonth < 10 || dayInMonth >= 13) {
      if (lastDigit === 1) suffix = 'st';
      if (lastDigit === 2) suffix = 'nd';
      if (lastDigit === 3) suffix = 'rd';
    }
    return `${dayInMonth}${suffix}`;
  },
};

const monthShortFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  year: 'numeric',
});

const monthLongFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  year: 'numeric',
});

const hourFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
});

export const getCurrentDate = () => new Date();

export const formatDateSimple = (date: string | undefined) => {
  if (date) return dayjs(date).format('MM/DD/YYYY');
  return '';
};

/**
 * output example `9th November 2022`
 */
export const formatDate = (date: Date | string | undefined) => {
  if (!date) return '';

  const d = new Date(date);
  const day = dayFormatter.format(d.getDate());
  const monthYear = monthLongFormatter.format(d);
  return `${day} ${monthYear}`;
};

/**
 * output example `November 9th, 2022`
 */
export const formatUSDate = (date: Date | string | undefined) => {
  if (!date) return '';
  return moment(date).format('MMMM Do, YYYY');
};

export const formatDateAs = (date: Date | string | undefined, format: string) => {
  if (!date) return '-';
  return moment(date).format(format);
};
/**
 * output example `9th Dec 2022 11:13`
 */
export const formatDateTime = (date: Date | string | undefined) => {
  if (!date) return '';

  const d = new Date(date);
  const day = dayFormatter.format(d.getDate());
  const monthYear = monthShortFormatter.format(d);
  const hour = hourFormatter.format(d);
  return `${day} ${monthYear} ${hour}`;
};

//output example:  2023-08-23
export function dateISOtoISO8601(value?: string) {
  if (!value) return undefined;
  // make sure we have an iso date before we slice
  const isoDate = new Date(value).toISOString();
  // value needs to be formated in ISO8601 for input type 'date'
  const inputValue = isoDate.slice(0, 10);
  return inputValue;
}

//convert:  "2023-08-23" => "2023/08/23"
export function dateFromString(value?: string) {
  if (!value) return undefined;
  // take a string and return the value directly
  const date = value.slice(0, 10).split('-');
  const refinedDate = `${date[1]}/${date[2]}/${date[0]}`;
  return refinedDate;
}

// output example: 2023-08-23T17:13:33.658Z
export function dateISO8601toISO(value?: string) {
  if (!value) return undefined;
  return new Date(value).toISOString();
}

//output example:  2023-08-23
export function getIsoDate(value: Date | undefined) {
  return value && value?.toISOString().slice(0, 10);
}

import { SortModelItem } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import _ from 'lodash';
import { graphqlGetClaims } from 'utils/agGridHotchocolate/graphqlGetClaims';
import { AgGridFilter, AgGridFilterModel } from 'utils/agGridHotchocolate/types';
import { splitCommaSeparated } from 'utils/commaSeparatedText';

export const getClaimsBy = async (
  client: GraphQlApolloClient,
  fieldName: string,
  gridFilter: AgGridFilter,
  sortModel?: SortModelItem[],
  options?: { limit?: number },
) => {
  const filterModel: AgGridFilterModel = {
    [fieldName]: { ...gridFilter },
  };

  const endRow = options?.limit ?? 1000;

  return graphqlGetClaims({ filterModel, sortModel, endRow }, client);
};

export const getSurvivingClaims = async (
  client: GraphQlApolloClient,
  claims: ClaimRow[],
): Promise<ClaimRow[]> => {
  const values = _(claims)
    .flatMap((r) => r.survivingClaimNumber)
    .flatMap(splitCommaSeparated)
    .compact()
    .value();

  const filterModel: AgGridFilterModel = {
    referenceNumber: {
      filterType: 'set',
      values,
    },
  };

  return graphqlGetClaims({ filterModel, endRow: 1000 }, client);
};

export const getClaimsByColumnSet = async (
  client: GraphQlApolloClient,
  values: Array<number | string>,
  column: string,
) => {
  const filterModel: AgGridFilterModel = {
    [column]: { filterType: 'set', values },
  };

  return graphqlGetClaims({ filterModel, endRow: 1000 }, client);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractReviewersBatchItem } from '../components/ReviewersEdit/ReviewersEdit.utils';

const CONTRACT_REVIEWER_DEFAULT_STATE = {
  contractId: '',
  userGroupReviewers: [],
  userReviewers: [] as any,
};
export const initialState: ContractReviewerReducerState = {
  loading: true,
  data: CONTRACT_REVIEWER_DEFAULT_STATE,
};

export interface ContractReviewerData {
  contractId: string;
  userGroupReviewers: any[];
  userReviewers: any[];
}

export interface ContractReviewerReducerState {
  data: ContractReviewerData;
  error?: string;
  loading: boolean;
}

const contractReviewerSlice = createSlice({
  name: 'contractReviewer',
  initialState,
  reducers: {
    contractReviewerFetch(state) {
      state.loading = true;
    },
    contractReviewerLoaded(state, action: PayloadAction<ContractReviewerData>) {
      state.loading = false;
      state.data.userReviewers = action.payload.userReviewers.map(
        (user: any) => user.id,
      );
      state.data.userGroupReviewers = action.payload.userGroupReviewers.map(
        (group: any) => group.id,
      );
    },
    setContractReviewers(state, action: PayloadAction<ContractReviewersBatchItem>) {
      state.data.userGroupReviewers = action.payload.contractReviewerUserGroupIds;
      state.data.userReviewers = action.payload.contractReviewerUserIds;
    },
  },
});

export const {
  contractReviewerFetch,
  contractReviewerLoaded,
  setContractReviewers,
} = contractReviewerSlice.actions;
export default contractReviewerSlice.reducer;

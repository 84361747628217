import { useMemo, useState } from 'react';

export const PAGINATION_COUNT = 100;

interface Props<T> {
  items: T[];
  usePagination?: boolean;
}

export const useTablePagination = <T>({ items, usePagination }: Props<T>) => {
  const [page, setPage] = useState(1);

  const paginatedItems = useMemo(() => {
    if (!usePagination) return items;

    const start = (page - 1) * PAGINATION_COUNT;
    const end = start + PAGINATION_COUNT;
    return items.slice(start, end);
  }, [items, usePagination, page]);

  return {
    items: paginatedItems,
    page,
    setPage,
  };
};

import { SingleClaimContractChangesComments } from 'components/SingleClaimContractChangesComments/SingleClaimContractChangesComments';
import { SingleClaimMatches } from './sections/SingleClaimMatches';
import { SingleClaimOverview } from './sections/SingleClaimOverview/SingleClaimOverview';
import { SingleClaimSubheaderType } from './types';

import { ClaimEditValues } from 'api/endpoints';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SingleClaimDocuments } from './sections/SingleClaimDocuments';
import { EditClaimHook } from './sections/SingleClaimOverview/hook/useEditClaim';

export interface SingleClaimSectionsProps {
  tab: SingleClaimSubheaderType;
  claim: ClaimEditValues;
  editClaimHook: EditClaimHook;
}

export const SingleClaimSections = ({
  tab,
  claim,
  editClaimHook,
}: SingleClaimSectionsProps) => {
  switch (tab) {
    case SingleClaimSubheaderType.Overview:
      return <SingleClaimOverview editClaimHook={editClaimHook} />;
    case SingleClaimSubheaderType.Matches:
      return <SingleClaimMatches claim={claim} />;
    case SingleClaimSubheaderType.ChangesAndComments:
      return (
        <SingleClaimContractChangesComments
          claimId={claim.id}
          contractId={0}
          section={SectionType.Claims}
        />
      );
    case SingleClaimSubheaderType.Documents:
      return <SingleClaimDocuments />;
  }
};

import { ApButtonGroup, ApButtonMain, ApIcon } from '@alixpartners/ui-components';
import { ProcessStatus } from 'api/endpoints/documents';
import classNames from 'classnames';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { ProgressMeter } from 'components/UIComponents/ProgressMeter';
import { useDocumentsContextData } from 'pages/DocumentsPage/context/DocumentsPageContextProvider';
import { useMemo } from 'react';

type ProgressProps = {
  filesUploading?: number;
  className?: string;
};

export const DocumentsPageUploadProgress = ({
  filesUploading,
  className,
}: ProgressProps) => {
  const {
    mappingFileUploading,
    docUnzipStatus,
    unzipping,
    uploadProgress,
    handleCancelUpload,
    resetUploadState,
    mappingStatus,
    downloadReport,
    unzippingProcessedFiles,
    unzippingTotalFiles,
    sectionType,
  } = useDocumentsContextData();
  const downloadSectionName = useMemo(
    () => sectionType.slice(0, sectionType.length - 1),
    [sectionType],
  );

  const getProgressContent = () => {
    if (
      (mappingFileUploading && !mappingStatus) ||
      (filesUploading && !unzipping && !docUnzipStatus)
    ) {
      // file upload progress
      return (
        <ProgressMeterCaption
          filesUploading={filesUploading}
          currentItemUploading={uploadProgress?.currentItemUploading}
          uploadProgress={uploadProgress?.progress}
          handleCancelUpload={handleCancelUpload}
        />
      );
    }
    if (unzipping && docUnzipStatus !== 'Completed') {
      // unzipping file progress
      return (
        <>
          <p style={{ border: 'none' }}>
            <b className="success">{filesUploading} file(s) succesfully uploaded</b>
            <br></br>
            <b>Unzipping in progress...</b>{' '}
            <b>
              {unzippingProcessedFiles
                ? `${unzippingProcessedFiles} out of ${unzippingTotalFiles} files processed`
                : ''}
            </b>
            <br></br>This may take up to 2 hours, please do not refresh the page
          </p>
        </>
      );
    }
    if (docUnzipStatus) {
      // zip/images file status progress
      return (
        <>
          <p>
            {docUnzipStatus === 'Completed' ? (
              <b className="success">
                {filesUploading} file(s) succesfully uploaded
              </b>
            ) : docUnzipStatus === 'Failed' ? (
              <b className="failed">
                File upload failed. Please try uploading your file(s) again.
              </b>
            ) : (
              ''
            )}
          </p>
          <ApButtonMain onClick={resetUploadState} className="upload-new-file">
            Upload new files
          </ApButtonMain>
        </>
      );
    }
    if (mappingStatus) {
      // mapping file status progress
      return (
        <>
          {mappingStatus === 'Completed' ? (
            <>
              <p>
                <b className="success">File succesfully uploaded</b>
                <br></br>
                The mapped files count has been updated.
              </p>
              <ApButtonMain onClick={resetUploadState} className="upload-new-file">
                Upload new files
              </ApButtonMain>
            </>
          ) : mappingStatus === 'Failed' ? (
            <>
              <p>
                <b className="failed">
                  Upload failed, please download the error log
                </b>
                <br></br>
                We've detected some errors in the file you are trying to upload.
              </p>
              <ApButtonGroup>
                <ApButtonMain onClick={resetUploadState} className="upload-new-file">
                  Upload new files
                </ApButtonMain>
                <ApButtonCasual
                  onClick={() =>
                    downloadReport?.({
                      url: `v1/${downloadSectionName}Documents/mapping/errors/file`,
                      fileName: 'ErrorLog',
                      resetUploadDetails: true,
                    })
                  }
                >
                  <ApIcon iconName="save_alt" iconColor="red" />
                  Download the error log
                </ApButtonCasual>
              </ApButtonGroup>
            </>
          ) : (
            <>
              <p style={{ border: 'none' }}>
                <b className="success">File succesfully uploaded</b>
                <br></br>
                <b>Processing file...</b> <br></br>Please do not refresh the page
              </p>
              <ApButtonCasual onClick={handleCancelUpload}>Cancel</ApButtonCasual>
            </>
          )}
        </>
      );
    }
  };

  const getIconByStatus = (
    status?: ProcessStatus,
    fileUploading?: boolean,
    unzipping?: boolean,
  ) => {
    const showUploadedIcon = status || status === 'Completed' || unzipping;
    if (status === 'Failed') {
      return <ApIcon iconName="outline_cancel" iconColor="var(--ap-color-red1)" />;
    }
    if (fileUploading && !showUploadedIcon) {
      return <img alt="uploading" src="img/datauploader/uploading.gif" />;
    }
    if (showUploadedIcon) {
      return (
        <ApIcon
          iconName="outline_check_circle_outline"
          iconColor="var(--ap-color-green2)"
        />
      );
    }
  };

  return (
    <div className={classNames('documents-page_upload-progress', className)}>
      {getIconByStatus(
        docUnzipStatus || mappingStatus,
        !!(filesUploading || mappingFileUploading),
        unzipping,
      )}
      {getProgressContent()}
    </div>
  );
};

const ProgressMeterCaption = ({
  filesUploading = 0,
  currentItemUploading = 0,
  uploadProgress = 0,
  handleCancelUpload,
}: {
  filesUploading?: number;
  currentItemUploading?: number;
  uploadProgress?: number;
  handleCancelUpload?: () => void;
}) => {
  return (
    <>
      <div className="progress-meter-container">
        <p>
          Uploading{' '}
          <b>
            {filesUploading > 1
              ? `file ${currentItemUploading} of ${filesUploading}`
              : '1 file'}
            ...{Math.round(uploadProgress)}%
          </b>
        </p>
        <ProgressMeter percentage={Math.round(uploadProgress)} />
        Do not refresh the page
      </div>
      <ApButtonCasual onClick={handleCancelUpload}>Cancel upload</ApButtonCasual>
    </>
  );
};

import { get } from 'api/apiRequest';
import { ActionStatus, ClaimEditChangeOptionsMarkFlags } from './claims';
import { ServerExhibitType } from './actions-select-options';

export type ActionStatusDynamic = {
  id: string;
  actionStatus: ActionStatus;
  actionExhibitTypes: ServerExhibitType[];
  actionStatusDisplayName: string;
};

export interface ActionStatusLabel {
  actionStatusId: number;
  actionStatus: string;
  transactionApproverLabel: string;
  transactionProposerLabel: string;
  transactionApproverFlag: keyof ClaimEditChangeOptionsMarkFlags;
  transactionProposerFlag: keyof ClaimEditChangeOptionsMarkFlags;
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}

export const actionStatus = {
  getActionStatusList: async () => {
    return await get<ActionStatusDynamic[]>('v1/Claims/workflowstatus');
  },
  getDynamicLabels: () =>
    get<ActionStatusLabel[]>(`v1/Claims/getActionStatusDynamicLabels`),
};

import { ApIcon } from '@alixpartners/ui-components';
import { ConfirmRedModal } from 'components/ModalWindows/ConfirmRedModal/ConfirmRedModal';

type Props = React.ComponentProps<typeof ConfirmRedModal>;

export const ExportCancelConfirmModal = (props: Omit<Props, 'message'>) => {
  return (
    <ConfirmRedModal
      message={
        <span className="sofas-export__modal-cancel__message">
          <ApIcon
            iconName="error"
            iconColor="var(--cancel-icon-color)"
            iconSize={24}
          />
          <span>This action is not reversible</span>
        </span>
      }
      btnConfirm={'Yes, cancel export'}
      btnCancel={'No, continue exporting'}
      {...props}
    />
  );
};

import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import React, { useRef } from 'react';
import inputClasses from '../InputText/InputText.module.css';
import classes from './Select.module.css';

interface Props {
  label?: string;
  options: {
    label: string;
    value?: string | number;
    disabled?: boolean;
  }[];
  value?: string | number | null;
  defaultValue?: string | number | null;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
  touched?: boolean;
  required?: boolean;
  hint?: React.ReactNode;
  className?: string;
  placeholder?: string;
  name?: string;
  autoComplete?: string;
  form?: string;
}

/**
 * Input select component.
 *
 * Proxies the native HTML `<select>` component as closely as possible:
 *
 * - doesn't modify the 'onChange' handler which lets us access the input change event
 * - passes React ref to the underlying `<input>`
 *
 * Supports 'error' and 'touched' props for easy embedding in forms with field-level
 * validation (formik, react-final-form and similar).
 */
const Select: React.FC<Props> = ({
  label,
  disabled,
  value,
  defaultValue,
  options,
  error,
  touched,
  className,
  hint,
  required,
  onChange,
  placeholder,
  autoComplete,
  name,
  form,
}) => {
  const id = useRef(uniqueId());

  return (
    <div
      className={classNames(
        'ap-input-select',
        classes.root,
        disabled && classes.disabled,
        error && touched && classes.error,
      )}
    >
      {!isNil(label) && (
        <label
          htmlFor={id.current}
          className={`${inputClasses.label} ${classes.label}`}
        >
          {label}
        </label>
      )}

      <div className={classes.container}>
        <select
          className={classNames(classes.select, className)}
          value={value as string}
          defaultValue={defaultValue ?? ''}
          name={name}
          disabled={disabled}
          onChange={onChange}
          id={id.current}
          data-testid="input-select-box"
          required={required}
          autoComplete={autoComplete}
          form={form}
        >
          {!isNil(placeholder) && (
            <option value="" disabled className={classes.placeholder}>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={String(option.value)}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>

        <ApIcon
          className={classes.caret}
          iconName="caret"
          iconColor="var(--ap-color-ui-grey-light)"
        />
      </div>

      {hint && <div className={classes.hint}>{hint}</div>}

      {error && touched && (
        <div className={inputClasses.error}>
          <div className={inputClasses.errorIcon}>
            <ApIcon
              iconName="info_outline"
              material
              iconSize="10px"
              iconColor="var(--ap-color-red1)"
            />
          </div>
          {error}
        </div>
      )}
    </div>
  );
};

export default Select;

import { ApSimpleSearch } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { debounce, isNil } from 'lodash';
import './SubheaderSearch.css';

type SimpleSearchProps = React.ComponentProps<typeof ApSimpleSearch>;

interface Props extends Omit<SimpleSearchProps, 'id'> {
  onSearchChanged: (text: string) => void;
  count: number | undefined;
  type: 'claims' | 'contracts';
}

export const SubheaderSearch = ({
  className,
  count,
  type,
  onSearchChanged,
  ...props
}: Props) => {
  const onSearchChangedDebounced = debounce(onSearchChanged, 1000);

  const placeholder =
    !isNil(count) && count >= 0
      ? `Search in ${count} ${type}`
      : count === -1
      ? 'Loading...'
      : '';

  return (
    <ApSimpleSearch
      className={classNames('subheader__search', className)}
      {...props}
      id="claimssearch"
      placeholder={placeholder}
      onChange={(v: any) => onSearchChangedDebounced(v)}
      onClear={onSearchChanged}
      onSearch={onSearchChanged}
    />
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataUploadContent,
  DataUploadHeader,
  DataUploadHelp,
  DataUploadMenu,
} from './sections';

import { FileComparisonResponse } from 'api/endpoints/dataPipeline';
import { DataUploadDuplicatesModal } from 'components/DataUploadDuplicatesModal/DataUploadDuplicatesModal';
import { DataUploadReplaceAppendDataModal } from 'components/DataUploadReplaceAppendDataModal/DataUploadReplaceAppendDataModal';
import { Layout } from 'components/Layout';
import { FileType, IUploadSection } from 'types/dataUploaderTypes';
import { assertUnreachable } from 'utils/assertUnreachable';
import './DataUploaderPage.css';
import { useDataUploader } from './hooks/useDataUploader';
import { sectionHeaders, uploadSections } from './utils/dataUploader.config';

type PopupState =
  | {
      type: 'closed';
    }
  | { type: 'replace_append_data'; filesComparisonResponse: FileComparisonResponse }
  | { type: 'duplicates_display'; filesComparisonResponse: FileComparisonResponse };

export const DataUploaderPage = () => {
  const {
    selectedSection,
    files,
    pipeline,
    publishingStatus,
    setSelectedSection,
    init,
    initFileInfo,
    addFiles,
    deleteFile,
    publish,
    getFilesComparisonResponse,
    updateSelectedUnpublishedFilesTypeToAppend,
    getAppendFilesComparisonResult,
  } = useDataUploader();

  const [isComparingFiles, setIsComparingFiles] = useState(false);

  const sectionTypeToFileTypes = useCallback((section: IUploadSection) => {
    switch (section.type) {
      case FileType.Sofa:
        return [FileType.Sofa, FileType.SofaAppend];
      case FileType.Schedule:
        return [FileType.Schedule, FileType.ScheduleAppend];
      default:
        return [section.type];
    }
  }, []);

  const selectedSectionFiles = useMemo(
    () =>
      selectedSection
        ? files.filter((file) =>
            sectionTypeToFileTypes(selectedSection).includes(file.type),
          )
        : files,
    [files, sectionTypeToFileTypes, selectedSection],
  );

  const [popupState, setPopupState] = useState<PopupState>({ type: 'closed' });

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePublish = async () => {
    if (!selectedSection || isComparingFiles) {
      return;
    }

    setIsComparingFiles(true);

    try {
      const filesComparisonResponse = await getFilesComparisonResponse();
      setIsComparingFiles(false);

      if (filesComparisonResponse === 'not_applicable') {
        publish(selectedSection.publishSection);
        return;
      }

      if (filesComparisonResponse.delete > 0) {
        setPopupState({
          type: 'replace_append_data',
          filesComparisonResponse,
        });
        return;
      }

      if (filesComparisonResponse.duplicateCount > 0) {
        setPopupState({
          type: 'duplicates_display',
          filesComparisonResponse,
        });
        return;
      }

      publish(selectedSection.publishSection);
    } catch (e) {
      setIsComparingFiles(false);
    }
  };

  return (
    <Layout className="data-upload">
      <DataUploadHeader
        pipeline={pipeline}
        uploadSections={uploadSections}
        uploadItems={files}
        publishingStatus={publishingStatus}
        onHeaderClick={() => setSelectedSection(undefined)}
      />
      <div className="data-upload_body">
        <DataUploadMenu
          sections={uploadSections}
          files={files}
          selected={selectedSection}
          isPublishing={publishingStatus.active}
          onSelect={setSelectedSection}
          sectionHeaders={sectionHeaders}
        />
        <DataUploadContent
          isComparingFiles={isComparingFiles}
          selectedSectionFiles={selectedSectionFiles}
          section={selectedSection}
          pipeline={pipeline}
          isPublishing={publishingStatus.active}
          onUpload={addFiles}
          onDelete={deleteFile}
          onPublish={handlePublish}
        />
        <DataUploadHelp selectedSection={selectedSection} />
      </div>
      {(() => {
        switch (popupState.type) {
          case 'closed':
            return null;
          case 'replace_append_data':
            return selectedSection ? (
              <DataUploadReplaceAppendDataModal
                filesComparisonResponse={popupState.filesComparisonResponse}
                onDismiss={() => setPopupState({ type: 'closed' })}
                onAppend={async () => {
                  setPopupState({ type: 'closed' });

                  setIsComparingFiles(true);
                  await updateSelectedUnpublishedFilesTypeToAppend();

                  // @TODO - try to convince BE to return duplicates on initial compare call
                  // i.e. for FileType.Sofa & FileType.Schedul
                  // this workaround and additional fetch can be avoided in such case
                  const newFileInfo = await initFileInfo();
                  const duplicatesComparisonResult =
                    await getAppendFilesComparisonResult(newFileInfo);
                  setIsComparingFiles(false);

                  if (
                    duplicatesComparisonResult === 'not_applicable' ||
                    duplicatesComparisonResult.duplicates.length === 0
                  ) {
                    await publish(selectedSection.publishSection);
                    return;
                  }

                  setPopupState({
                    type: 'duplicates_display',
                    filesComparisonResponse: duplicatesComparisonResult,
                  });
                }}
                // replace is the default behaviour so we don't need to do anything,
                // but leaving the handler in place in case the logic changes later
                onReplace={() => {
                  setPopupState({ type: 'closed' });
                  publish(selectedSection.publishSection);
                }}
              />
            ) : null;
          case 'duplicates_display':
            return selectedSection ? (
              <DataUploadDuplicatesModal
                filesComparisonResponse={popupState.filesComparisonResponse}
                onDismiss={() => setPopupState({ type: 'closed' })}
                onConfirm={() => {
                  setPopupState({ type: 'closed' });
                  publish(selectedSection.publishSection);
                }}
              />
            ) : null;
          default:
            return assertUnreachable(popupState);
        }
      })()}
    </Layout>
  );
};

import { showHttpError } from 'api/apiRequest/httpErrorAlert';
import { HelpSearchItem } from './types';

export type HelpSearchContent = HelpSearchItem & {
  content: string | undefined;
};

export const fetchAllMarkdownFiles = async (
  itemList: HelpSearchItem[],
  signal: AbortSignal,
) => {
  const promiseList = itemList.map(async (item) => {
    const { text, error } = await fetchFile(item.module, item.file, signal);
    if (error) console.error(error);

    return { ...item, content: text } as HelpSearchContent;
  });

  return Promise.all(promiseList);
};

export const fetchMarkdownFile = async (
  module: string,
  file: string,
  signal?: AbortSignal,
) => {
  const { text, error } = await fetchFile(module, file, signal);

  if (error) showHttpError({ title: 'File not found' });
  return text ?? '';
};

const fetchFile = async (module: string, file: string, signal?: AbortSignal) => {
  try {
    const fileFullName = `/helpinfo/${module}/${file}`;
    const result = await fetch(fileFullName, { signal });
    const text = await result.text();

    // for some reson if file not found it returns an main page "index.html" file.
    const fileNotFound = text.startsWith('<!DOCTYPE html>');
    if (fileNotFound) {
      return { error: `File not found: ${fileFullName}` };
    }

    return { text };
  } catch (e: any) {
    if (e.name === 'AbortError') return { text: '' };
    return { error: e?.message as string };
  }
};

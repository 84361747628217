import { ColDef } from 'ag-grid-community';
import {
  dateFilterOptions,
  numberFilterOptions,
  stringFilterOptions,
} from './columnFilter';

export interface ColumnBase {
  propertyName: string;
  displayName: string;
  dataType: 'bool' | 'string' | 'int' | 'date' | 'datetime' | 'decimal' | 'enum';
  allowedValues?: string[] | number[] | null;
}

export const getGridFilterProps = (
  column: ColumnBase,
): Pick<ColDef, 'filter' | 'filterParams'> => {
  switch (column.dataType) {
    case 'date':
      return {
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      };
    case 'datetime':
      return {
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      };
    case 'int':
      return {
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      };
    case 'decimal':
      return {
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      };
    case 'bool':
      return {
        filter: 'agSetColumnFilter',
        filterParams: { values: [true, false] },
      };
    case 'enum':
      return {
        filter: 'agSetColumnFilter',
        filterParams: {
          values: column.allowedValues,
        },
      };
    case 'string':
      return {
        filter: 'agTextColumnFilter',
        filterParams: {
          applyMiniFilterWhileTyping: true,
          filterOptions: stringFilterOptions,
        },
      };
    default:
      return {};
  }
};

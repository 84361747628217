import { useMemo } from 'react';
import { IBatchEditCategories } from '../claimsBatchEdit.type';
import { useAppSelector } from 'hooks/reducerHooks';
import { mapCategories } from '../utils/categoryMapper';

export const useCategories = (): IBatchEditCategories => {
  const selectable = useAppSelector((state) => state.selectable);

  const { categories } = useMemo(
    () => (selectable.loading ? ({} as any) : mapCategories(selectable.data)),
    [selectable],
  );

  return {
    categories,
  };
};

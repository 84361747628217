import { dateFromString } from 'utils/formatDate';
import { HtmlFormInputElement } from './DatePicker.types';

const submitListenerOptions = { capture: true };
export function registerFormDataUpdate(input: HtmlFormInputElement) {
  const form = input.form;
  if (!form) return;
  const listener = (e: FormDataEvent) => {
    const isoValue = dateFromString(input.value);
    if (form && e.formData && input.name && isoValue) {
      e.formData.set(input.name, isoValue);
    }
  };
  form.addEventListener('formdata', listener, submitListenerOptions);
  return () => {
    if (!form) return;
    form.removeEventListener('formdata', listener);
  };
}

export function registerFormReset(
  input: HtmlFormInputElement,
  onReset: (e: Event) => void,
) {
  const form = input.form;
  if (!form) return;

  form.addEventListener('reset', onReset, submitListenerOptions);
  return () => {
    if (!form) return;
    form.removeEventListener('reset', onReset);
  };
}

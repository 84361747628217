import { ApButtonMain, ApModal } from '@alixpartners/ui-components';
import { SlipSheet, SlipSheetPostParams, SlipSheetPutParams } from 'api/endpoints';
import classNames from 'classnames';
import { BrowserFileRow } from 'components/ModalWindows/UploadModal/FileRowLite';
import { useId } from 'hooks/useId';
import { DataContentBox } from 'pages/DataUploaderPage/components/DataContentBox';
import {
  Extension,
  FileUploadPanel,
} from 'pages/DataUploaderPage/components/FileUploadPanel';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FileDisplay } from '../../Components/ActionList';
import { SofaLegalEntityMultiselect } from '../../Components/SofaLegalEntityMultiselect';
import {
  ReportPartsSelectProps,
  SofaPartsMultiselect,
} from '../../Components/SofaPartsMultiselect';
import { REPORT_TYPES_ENUM } from '../../Domain/useReportLegalEntities';
import './SlipSheetModal.css';
import { useSlipSheetSelectedOptions } from './useSlipSheetSelectedOptions';

export type SubmitData = {
  entries: {
    file: File;
    documentType: string;
    referenceNumber: string | null;
  }[];
  comment: string | null;
};

const EXTENSIONS: Extension[] = ['.docx', '.pdf'];
const MAX_FILES = 1;

export type OkParam =
  | {
      type: 'add';
      params: Omit<SlipSheetPostParams, 'fileName'>;
      file: File;
    }
  | {
      type: 'edit';
      params: SlipSheetPutParams;
    };

export type SlipSheetModalProps = {
  item: SlipSheet | undefined;
  onClosed: () => void;
  onOk: (params: OkParam) => void;
};

function useReportTypeParamOrDefault() {
  const { type } = useParams();

  // TODO we need to be careful about the plurals and set up a convention
  if (type?.toLowerCase() === 'schedules') {
    return REPORT_TYPES_ENUM.Schedule;
  }
  return REPORT_TYPES_ENUM.Sofa;
}

export const SlipSheetModal = ({ item, onClosed, onOk }: SlipSheetModalProps) => {
  const formId = useId();
  const reportTypeFromRoute = useReportTypeParamOrDefault();

  const selectedReportTypeObj = useMemo(() => {
    return {
      type: 'all',
      first: reportTypeFromRoute,
    } as ReportPartsSelectProps['selectedReportType'];
  }, [reportTypeFromRoute]);

  const [userSelectedFiles, setUserSelectedFiles] = useState<File[]>([]);

  const handleClose = useCallback(() => {
    setUserSelectedFiles([]);
    onClosed();
  }, [onClosed]);

  const submitDisabled = item === null ? userSelectedFiles.length === 0 : false;

  const {
    selectedEntities,
    selectedParts,
    handleChangeSelectedEntities,
    handleChangeSelectedParts,
  } = useSlipSheetSelectedOptions(item);

  const handleUpload = useCallback((files: File[]) => {
    setUserSelectedFiles(files);
  }, []);
  const labels = decideLabels(item);

  const handleFileDelete = useCallback(
    (file: File) => {
      const newFiles = userSelectedFiles.filter((f) => f.name !== file.name);
      setUserSelectedFiles(newFiles);
    },
    [userSelectedFiles],
  );

  const handleSubmitClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.preventDefault();

      const parts = selectedParts.map((o) => {
        return {
          fileType: o.data?.parentType,
          partName: o.value,
        };
      });
      const legalEntities = selectedEntities.map((o) => ({
        legalEntityId: +o.value,
      }));

      if (item) {
        // edit
        onOk({
          type: 'edit',
          params: {
            id: item.id,
            displayName: item.displayName,
            fileName: item.fileName,
            legalEntities,
            parts,
          },
        });
      } else {
        const uploadedFile = userSelectedFiles[0];
        if (!uploadedFile) return;
        onOk({
          type: 'add',
          file: uploadedFile,
          params: {
            displayName: uploadedFile.name,
            legalEntities,
            parts,
          },
        });
      }
    },
    [item, onOk, selectedEntities, selectedParts, userSelectedFiles],
  );

  const renderFooter = useCallback(
    (_footerProps: { handleClose?(): void }) => {
      return (
        <ApButtonMain
          type="submit"
          onClick={handleSubmitClick}
          form={formId}
          className="upload-modal__action"
          disabled={submitDisabled}
        >
          {labels.submit}
        </ApButtonMain>
      );
    },
    [formId, handleSubmitClick, labels.submit, submitDisabled],
  );

  return (
    <div>
      <ApModal
        isOpen
        className={classNames('global-notes-upload-modal slip-sheet-modal', {
          'slip-sheet-modal-edit': !!item,
        })}
        header={labels.title}
        hasClosed={handleClose}
        isDismissible={true}
        footer={renderFooter}
      >
        {item && <FileDisplay displayName={item.displayName} />}
        <SofaLegalEntityMultiselect
          selected={selectedEntities}
          onChange={handleChangeSelectedEntities}
        />
        <SofaPartsMultiselect
          className={classNames({
            'labeled-multiselect-pad-bump': !!item,
          })}
          selected={selectedParts}
          onChange={handleChangeSelectedParts}
          selectedReportType={selectedReportTypeObj}
        />
        {!item ? (
          <>
            <DataContentBox label={''}>
              <FileUploadPanel
                accept={EXTENSIONS}
                maxFiles={MAX_FILES}
                onUpload={handleUpload}
              />
            </DataContentBox>
            {userSelectedFiles.map((item) => (
              <div className="anim-appear" key={item.name}>
                <BrowserFileRow file={item} onDelete={handleFileDelete} />
              </div>
            ))}
          </>
        ) : undefined}
      </ApModal>
    </div>
  );
};

type Labels = {
  title: string;
  submit: string;
};

function decideLabels(item: SlipSheet | undefined): Labels {
  if (item) {
    return {
      title: 'Edit entities and part for',
      submit: 'Save',
    };
  }

  return {
    title: 'Upload a Slip Sheet',
    submit: 'Proceed',
  };
}

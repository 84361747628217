import { SlipSheet } from 'api/endpoints';
import classNames from 'classnames';
import { useMemo } from 'react';
import './SlipSheetsItemDetails.css';

interface Props {
  item: SlipSheet;
}

export const SlipSheetsItemDetails = ({ item }: Props) => {
  const { names, parts } = useMemo(
    () => ({
      names: item.legalEntities.map((x) => `${x.entityName}`),
      parts: item.parts.map((x) => x.partDescription),
    }),
    [item],
  );
  return (
    <div className={classNames('slip-sheet-list-item__details')}>
      <Enumerator title={pluralizeEntity(names.length)} items={names} countItems />
      <Enumerator title={afterPartsTitle(parts)} items={parts} />
    </div>
  );
};

function afterPartsTitle(partDescriptions: string[]): string {
  if (partDescriptions.length === 0)
    return 'No parts selected to put the Slip Sheet after.';
  return 'Slip Sheet added for all the above entities after:';
}

function pluralizeEntity(count: number) {
  if (count === 0) return `No Entities.`;
  return count === 1 ? 'One Entity:' : `${count} Entities:`;
}

export type EnumeratorProps = {
  title: string;
  items: string[];
  countItems?: boolean;
};

export function Enumerator(props: EnumeratorProps) {
  const { title, items, countItems } = props;
  return (
    <div className="enumerator">
      <div className="enumerator__text enumerator__title">{title}</div>
      <ul className="enumerator__list">
        {items.map((item, i) => {
          const content = countItems ? `${i + 1} - ${item}` : item;
          return (
            <li key={item} className="enumerator__text">
              {content}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

import { ClaimRow } from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setActionId } from 'reducer/exhibits/exhibitReducer';

function useExhibitExporter() {
  const dispatch = useAppDispatch();
  const [actionType, setActionType] = useState<number>();
  const [actionSubstatus, setActionSubstatus] = useState<number>();
  const [isShowAddClaimPopup, setIsShowAddClaimPopup] = useState(false);
  const actionNames = useAppSelector((s) => s.actionNames);
  const { currentExhibit } = useAppSelector((s) => s.exhibit);
  const [selectedClaims, setSelectedClaims] = useState<ClaimRow[]>([]);

  const updateActionSubstatus = useCallback(
    (as?: number) => setActionSubstatus(as),
    [setActionSubstatus],
  );

  useEffect(() => {
    if (!currentExhibit.actionNameId) return;

    const actionName = actionNames.data?.find(
      (name) => name.id === currentExhibit.actionNameId,
    );
    if (actionName?.id) {
      setActionType(actionName.typeId);
      updateActionSubstatus(actionName.substatusId);
    }
  }, [actionNames.data, currentExhibit.actionNameId, updateActionSubstatus]);

  const getSelectedClaims = (claims: ClaimRow[]) => {
    setSelectedClaims(claims);
  };

  const getActionNames = (
    actionTypeId: number | undefined,
    actionSubstatusId: number | undefined,
  ) => {
    if (!actionSubstatusId && !actionTypeId) return [];
    return actionNames.data?.filter(
      (action) =>
        action.typeId === actionTypeId && action.substatusId === actionSubstatusId,
    );
  };

  const actionName = useMemo(() => {
    return (
      actionNames.data?.find((a) => a.id === currentExhibit.actionNameId)?.name || ''
    );
  }, [actionNames.data, currentExhibit.actionNameId]);

  const handleChangeActionName = (value: number) => {
    setActionType(value);
    updateActionSubstatus(undefined);
    dispatch(setActionId(undefined));
  };

  return {
    actionType,
    actionSubstatus,
    isShowAddClaimPopup,
    selectedClaims,
    actionName,
    updateActionSubstatus,
    getSelectedClaims,
    getActionNames,
    handleChangeActionName,
    setIsShowAddClaimPopup,
  };
}

export default useExhibitExporter;

import { RolePermission } from './RolePermission';

export interface ApUserRoleProps {
  children?: React.ReactNode;
}

export const ApUserRole = ({ children }: ApUserRoleProps) => (
  <RolePermission roles={['TransactionApprover', 'TransactionProposer']}>
    {children}
  </RolePermission>
);

import { ClaimRow } from 'api/endpoints';
import { ClaimsGrid } from 'pages/ClaimsContractsToolPage/grid/ClaimsGrid/ClaimsGrid';
import { ClaimsData, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { ClaimsToolSubheader } from './ClaimsToolSubheader';
import { ApUserRole } from 'components/RolePermission/ApUserRole';
import './ClaimsToolMatching.css'

interface IProps {
  claimsCount?: number;
  isColumnSelectionOpen: boolean;
  claimsData: ClaimsData;
  subsection: SubsectionType;
  selectedRows: ClaimRow[];
  setSubsection: (s: SubsectionType) => void;
  onMatchGroupSelectedClaims?: () => unknown;
  onAcceptMatches?: () => unknown;
}

export const ClaimsToolMatching = ({
  claimsCount,
  isColumnSelectionOpen,
  claimsData,
  subsection,
  selectedRows,
  setSubsection,
  onMatchGroupSelectedClaims,
  onAcceptMatches,
}: IProps) => {
  return (
    <>
      <ApUserRole>
        <ClaimsToolSubheader
          selectedRows={selectedRows}
          claimsExpandState={claimsData.claimsExpandState}
          claimsCount={claimsCount}
          subsection={subsection}
          isColumnSelectionOpen={isColumnSelectionOpen}
          onSubsectionClick={setSubsection}
          onMatchGroupSelectedClaims={onMatchGroupSelectedClaims}
          onAcceptMatches={onAcceptMatches}
          onExportClaims={claimsData.onExport}
          isExporting={claimsData.isExporting}
        />
        <div className='matching-all--body'>
          <ClaimsGrid
            isColumnSelectionOpen={isColumnSelectionOpen}
            gridOptions={claimsData.grid}
            dataSourceLoading={claimsData.dataSourceLoading}
          />
        </div>
      </ApUserRole>
    </>
  );
};

import { ApButtonMain, ApIcon } from '@alixpartners/ui-components';
import './AddButton.css';

type AddButtonProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export function AddButton(props: AddButtonProps) {
  return (
    <ApButtonMain
      disabled={props.disabled}
      onClick={props.onClick}
      className={'sofas-schedules-add-btn'}
      type="button"
    >
      <ApIcon iconName="add" iconSize="sm" />
      <span>Add New</span>
    </ApButtonMain>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractsDetailedData } from 'pages/Dashboard/types';

type StateBase = {
  data: ContractsDetailedData[];
  fetching: boolean;
  shouldFetchData: boolean;
};

const initialState: StateBase = {
  data: [],
  fetching: false,
  shouldFetchData: true,
};
const contractsDashboardDetailedDataReducer = createSlice({
  name: 'contractsDashboardDetailedData',
  initialState,
  reducers: {
    fetchingData: (
      state: StateBase,
      action: PayloadAction<{ fetching: boolean; shouldFetchData: boolean }>,
    ) => {
      state.fetching = action.payload.fetching;
      state.shouldFetchData = action.payload.shouldFetchData;
    },
    fetchingDataDone: (
      state: StateBase,
      action: PayloadAction<ContractsDetailedData[]>,
    ) => {
      state.fetching = false;
      state.data = [...action.payload];
      state.shouldFetchData = false;
    },
  },
});

export const { fetchingData, fetchingDataDone } =
  contractsDashboardDetailedDataReducer.actions;
export default contractsDashboardDetailedDataReducer.reducer;

import { delQuiet, get, getQuiet, postQuiet, putQuiet } from 'api/apiRequest';
import { jsonFetchPost } from 'api/jsonFetch/authJsonFetch';
import { CommonErrorBody } from './types';

export interface ICategories {
  majorCategoryName: string;
  minorCategoryName: string;
  majorCategoryId: number;
  minorCategoryId: number;
}

export interface IMajorMinorValue {
  majorCategoryId: number | null;
  minorCategoryId: number | null;
}

export interface GroupUsersParams {
  id: number | undefined;
  name: string;
  majorMinorCategoryId: IMajorMinorValue[];
  applicationUserId: number[];
}

export interface UserInfo {
  id: number;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IApplicationUser {
  userGroupId: number;
  adUserId: string;
  appUserId: number;
  firstName: string;
  lastName: string;
}

export interface GroupUsersItem {
  id: number;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  userGroupName: string;

  categories: ICategories[];
  applicationUsers: IApplicationUser[];
}
type ContractCategoriesResponse = {
  userGroupId: number;
  categoryId: number;
  categoryName: string;
  value?: string;
};

export type ContractsGroupUsersItem = {
  id: number;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
  name: string;
  contractCategoryIds: number[];
  userIds: IApplicationUser[];
};

export type ContractsUserGroupsResponse = Omit<
  ContractsGroupUsersItem,
  'contractCategoryIds' | 'userIds'
> & {
  contractCategories: ContractCategoriesResponse[];
  users: IApplicationUser[];
};

export const claimUserGroup = {
  getAllUsers: () => get<UserInfo[]>('v1/UserGroup/getAllUsers'),

  assignGroupUsers: (param: GroupUsersParams) =>
    postQuiet('v1/UserGroup/assignGroupUsers', param),
  getAssignedGroupUsers: () =>
    get<GroupUsersItem[]>('v1/UserGroup/getAssignedGroupUsers'),
  getAssignedGroupUsersId: (id: number) =>
    get<GroupUsersItem>(`v1/UserGroup/getAssignedGroupUsers/${id}`),
  deleteAssignedGroupUsers: (userGroupId: number) =>
    delQuiet(`v1/UserGroup/${userGroupId}`),
  createClaimUserGroup(params: Omit<GroupUsersParams, 'id'>) {
    return jsonFetchPost<void, CreateUserGroupError>(
      'v1/UserGroup/assignGroupUsers',
      params,
      undefined,
      true,
    );
  },
  editClaimUserGroup(params: Omit<GroupUsersParams, 'id'> & { id: number }) {
    return jsonFetchPost<void, CreateUserGroupError>(
      `v1/UserGroup/assignGroupUsers`,
      params,
      undefined,
      true,
    );
  },
};

export const contractUserGroup = {
  getContractUserGroup: () =>
    getQuiet<ContractsUserGroupsResponse[]>('v1/ContractUserGroup'),
  getContractUserGroupById: () =>
    getQuiet<ContractsUserGroupsResponse[]>('v1/ContractUserGroup'),
  createContractUserGroup: (params: Omit<ContractsGroupUsersItem, 'id'>) =>
    jsonFetchPost<void, CreateUserGroupError>(
      'v1/ContractUserGroup',
      params,
      undefined,
      true,
    ),
  deleteContractAssignedGroupUsers: (userGroupId: number) =>
    delQuiet(`v1/ContractUserGroup/${userGroupId}`),
  editContractUserGroup: (params: Omit<ContractsGroupUsersItem, 'id'>, id: number) =>
    putQuiet(`v1/ContractUserGroup/${id}`, params),
};

export type CreateUserGroupError = {
  status: 409;
  error: CommonErrorBody;
};

export type EditUserGroupError =
  | {
      status: 409;
      error: CommonErrorBody;
    }
  | {
      status: 404;
      error: CommonErrorBody;
    };

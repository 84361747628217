import { ApplyColumnStateParams, ColDef } from 'ag-grid-community';
import { orderBy } from 'lodash';

const ALWAYS_FIRST_COLS = ['checkbox', 'pin', 'referenceNumber', 'counterpartyName'];

const idEndOfTheList = 99999999999;

export const sortColumnsByState = <T>(
  columns: ColDef<T>[],
  columnState: ApplyColumnStateParams | undefined,
) => {
  const order = columnState?.state
    ?.map((col) => col.colId)
    .filter((col) => !ALWAYS_FIRST_COLS.includes(col));

  if (!order) return columns;

  const sortOrder = [...ALWAYS_FIRST_COLS, ...order];
  const sortedColumns = orderBy(columns, (col) => {
    const idx = sortOrder.indexOf(col.field!);
    return idx >= 0 ? idx : idEndOfTheList;
  });

  return sortedColumns;
};

import uniqueId from 'lodash/uniqueId';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';

/**
 * Provides a globally unique id that will be persisted throught the lifetime of the component.
 * @param overrideId Pass this through in cases when a component could have an optional id as prop.
 */
export function useId(overrideId?: string): string {
  const generatedId = useMemo(() => uniqueId(), []);
  const id = isNil(overrideId) ? `${generatedId}` : overrideId;
  return id;
}

import { formDataToObject } from 'utils/form/form-utils';
import { SubmitData } from './UploadModal';

const MULTIPLE_DOCS = { referenceNumber: true, documentType: true };

interface DocumentForm {
  comment?: string;
  documentType: string[];
  referenceNumber: string[];
}

export function formDataToSubmitData(
  formData: FormData,
  userSelectedFiles: File[],
): SubmitData {
  const formProps = formDataToObject<DocumentForm>(formData, MULTIPLE_DOCS);

  const entries: SubmitData['entries'] = userSelectedFiles.map((file, index) => ({
    file,
    documentType: formProps.documentType?.[index]?.trim(),
    referenceNumber: emptyToNull(formProps.referenceNumber?.[index]?.trim()),
  }));

  return {
    comment: emptyToNull(formProps.comment?.trim()),
    entries,
  };
}

const emptyToNull = (s: string | undefined) => (!!s ? s : null);

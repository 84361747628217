import { fileInfo } from 'api/endpoints';
import { useEffect, useMemo, useState } from 'react';
import { IUploadSection } from 'types/dataUploaderTypes';
import { UploadHelpPanel } from '../components/UploadHelpPanel';
import { HELP_SECTION_TEMPLATES_MAP } from '../utils/dataUploader.config';
import './DataUploadHelp.css';

export const DataUploadHelp = ({
  selectedSection,
}: {
  selectedSection?: IUploadSection;
}) => {
  const [templates, setTemplates] = useState<string[] | undefined>();
  useEffect(() => {
    async function downloadTemplates() {
      const result = await fileInfo.getTemplates();
      if (result) {
        setTemplates(result);
      }
    }
    downloadTemplates();
  }, []);

  const templatesToRender = useMemo(() => {
    return selectedSection?.type
      ? (HELP_SECTION_TEMPLATES_MAP as any)[selectedSection?.type]
      : templates;
  }, [selectedSection, templates]);

  return (
    <div className="dataupload-help">
      <UploadHelpPanel
        className="dataupload-help__info"
        title="What format should my data be in?"
      >
        {helpText_whatFormatData}
      </UploadHelpPanel>

      {templatesToRender && (
        <UploadHelpPanel
          title="Data templates"
          className="dataupload-help__data-templates"
        >
          <ul className="dataupload-help__templates">
            {templatesToRender.map((t: string, index: number) => (
              <li key={`template${index}`}>
                <span onClick={() => fileInfo.downloadTemplate(t)}>{t}</span>
              </li>
            ))}
          </ul>
        </UploadHelpPanel>
      )}
    </div>
  );
};

const helpText_whatFormatData = (
  <>
    Your data file should be uploaded in .xlsx format.
    <p />
    To pass validation your file must be in a specific column structure. Please
    download the appropriate template from the list below.
  </>
);

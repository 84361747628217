import React from 'react';
import { ApMenu, ApMenuItem } from '@alixpartners/ui-components';
import { HeaderButton, HeaderTooltip } from './CustomComponents';
import './style.css';

interface IProps extends React.ComponentProps<typeof HeaderButton> {
  tooltip?: string;
  children: React.ReactElement<typeof ApMenuItem>[];
}

export const NavIconMenu = ({ tooltip, children, ...buttonProps }: IProps) => {
  return (
    <HeaderTooltip message={tooltip} disabled={buttonProps.disabled}>
      <div>
        <ApMenu button={<HeaderButton {...buttonProps} />}>{children}</ApMenu>
      </div>
    </HeaderTooltip>
  );
};

import { ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import _ from 'lodash';
import { forwardRef } from 'react';
import './DivWithLoader.css';

type Props = React.ComponentProps<'div'> & {
  loading?: boolean;
  loaderParams?: React.ComponentProps<typeof ApLoaderDefault>;
};

export const DivWithLoader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { loading, className, children, loaderParams, ...restProps } = props;
  const loaderProps = _.omit(loaderParams, 'className');

  return (
    <div ref={ref} className={classNames('loader-block', className)} {...restProps}>
      {loading ? (
        <div className="loader-block__shadow">
          <ApLoaderDefault className={loaderParams?.className} {...loaderProps} />
        </div>
      ) : undefined}

      {children}
    </div>
  );
});

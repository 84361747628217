import { ActionExhibitTypeId as Type } from 'api/endpoints/actions-select-options';
import { useAppSelector } from 'hooks/reducerHooks';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { AP_USER_DEFAULT_SECTIONS } from '../const/apUser';
import {
  ALLOW_SECTIONS,
  EXPUNGE_OBJECTIONS_SECTIONS,
  EXPUNGE_OTHER_SECTIONS,
  EXPUNGE_SETTLEMENT_SECTIONS,
  OBJECTION_SECTIONS,
  SETTLEMENT_SECTIONS,
} from '../const/clientUser';

function useSections() {
  const { actionTypeId } = useAppSelector(
    (s) => s.claimActionNameSelection.claimActionInfo,
  );

  const { isClient, isApUser } = useAppUserType();

  if (isApUser) {
    return AP_USER_DEFAULT_SECTIONS;
  }

  if (isClient) {
    if (actionTypeId === Type.Expunge) return EXPUNGE_OBJECTIONS_SECTIONS;
    if (actionTypeId === Type.AlterSettlement) return SETTLEMENT_SECTIONS;
    if (actionTypeId === Type.AlterObjection) return OBJECTION_SECTIONS;
    if (actionTypeId === Type.Allow) return ALLOW_SECTIONS;

    if (actionTypeId === Type.ExpungeOther) return EXPUNGE_OTHER_SECTIONS;
    if (actionTypeId === Type.ExpungeSettlement) return EXPUNGE_SETTLEMENT_SECTIONS;

    return ALLOW_SECTIONS;
  }

  return ALLOW_SECTIONS;
}

export default useSections;

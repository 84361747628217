import { IOption } from 'components/UIComponents/MultiSelect';

export const EXHIBIT_FOOTER_OPTIONS: IOption[] = [
  {
    label: 'Global footnotes',
    value: '1',
  },
  {
    label: 'Claim count',
    value: '3',
  },
];

export const EXHIBIT_HEADER_OPTIONS: IOption[] = [
  {
    label: 'Hearing name',
    value: '1',
  },
  {
    label: 'Exhibit name',
    value: '2',
  },
  {
    label: 'Basis for objection',
    value: '3',
  },
  {
    label: 'Case number',
    value: '4',
  },
  {
    label: 'Case name',
    value: '5',
  },
];

export const EXHIBIT_FOOTNOTE_APPEAR: IOption[] = [
  {
    label: 'First page only',
    value: '1',
  },
  {
    label: 'All pages',
    value: '4',
  },
];

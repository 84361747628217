import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './ApNiceSelect.css';
// unfortunately ApTooltip is buggy with disabled elements (doesn't disappear after hover away)
// so we have to use react-tooltip directly

export interface OptionItem {
  value: string | number;
  label: string;
}

export interface ApNiceSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  containerClassName?: string;
  emptyDefaultOption?: boolean;
  options: OptionItem[];
  showValueAsTooltip?: boolean;
  placeholder?: string;
}

export const EMPTY_OPTION_VALUE = 'ap-nice-select-empty-option-value';

export const ApNiceSelect = (props: ApNiceSelectProps) => {
  const {
    options,
    emptyDefaultOption,
    containerClassName,
    showValueAsTooltip,
    className,
    value,
    ...select
  } = props;
  const selectedOption = options?.find((o) => `${o.value}` === `${props.value}`);
  const id = uniqueId();
  return (
    <div
      className={classNames('ap-nice-select', containerClassName)}
      id={id}
      key={id}
      data-tooltip-id={id}
    >
      {showValueAsTooltip && (
        <Tooltip id={id} place="top" className="tooltip">
          {selectedOption?.label}
        </Tooltip>
      )}

      <select
        className={classNames(className, 'ap-nice-select__select')}
        value={value ?? undefined} // value can not be null
        {...select}
      >
        {emptyDefaultOption ? (
          <option value={EMPTY_OPTION_VALUE} key={'option0'}>
            {props.placeholder}
          </option>
        ) : null}
        {options?.map(({ value, label }, index) => (
          <option key={`option${index}`} value={value}>
            {label}
          </option>
        ))}
      </select>

      <div className="ap-nice-select__arrow">
        <ApIcon iconName="baseline_arrow_drop_down" />
      </div>
    </div>
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { useAppDispatch } from 'hooks/reducerHooks';
import { setSection as setClaimsSection } from 'reducer/claimsReducer';
import { setSection as setContractsSection } from 'reducer/contractsReducer';
import { useClaimToolQueryParams } from 'utils/hooks/useClaimToolQueryParams';
import { SectionType } from '../types';
import { ConterpartyLink } from './CounterpartyNameCell';

type CounterpartyNameCellProps = ICellRendererParams<ClaimRow, string>;

export const ClaimCounterpartyNameCell = (props: CounterpartyNameCellProps) => {
  const claim = props.data as ClaimRow;
  const { buildCounterpartyPath } = useClaimToolQueryParams();
  const dispatch = useAppDispatch();

  const handleCounterpartyClick = () => {
    //todo: do we really need these two dispatch???
    dispatch(setClaimsSection(SectionType.Claims));
    dispatch(setContractsSection(undefined));
  };

  if (claim.matchCode)
    return (
      <ConterpartyLink
        text={claim.counterpartyName}
        to={buildCounterpartyPath(claim.matchCode)}
        onClick={handleCounterpartyClick}
      />
    );

  return claim.counterpartyName;
};

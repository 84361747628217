import { useCallback } from 'react';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import './FileRowLite.css';
import { ApIcon } from '@alixpartners/ui-components';

export type BrowserFileRowProps = {
  file: File;
  onDelete: (file: File) => void;
};
export const BrowserFileRow = ({ file, onDelete }: BrowserFileRowProps) => {
  const handleDelete = useCallback(() => {
    onDelete(file);
  }, [file, onDelete]);

  return (
    <div className="browserfilerow">
      <span className="browserfilerow__icon">
        <ApIcon iconName="outline_check_circle_outline" />
      </span>

      <span className="browserfilerow__name">{file.name}</span>
      <span className="browserfilerow__delete-btn" onClick={handleDelete}>
        <ButtonIcon
          type="button"
          icon="trash"
          iconColor="var(--ap-color-ui-grey-light)"
        />
      </span>
    </div>
  );
};

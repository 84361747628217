import './TrumpedMatchClaimPopup.css';
import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';
import { useMemo } from 'react';

interface IProps {
  claimId?: string[];
  onAccept: () => void;
  onClose: () => void;
}

export const TrumpedMatchClaimPopup = ({ claimId, onAccept, onClose }: IProps) => {
  const claimList = useMemo(
    () => claimId?.map((r) => `#${r}`).join(', '),
    [claimId],
  );

  return (
    <ApModal
      header="Trumped Scheduled Claim"
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonSecondary onClick={onClose}>Cancel</ApButtonSecondary>
          <ApButtonMain onClick={onAccept} autoFocus>
            Save Sub Match Code
          </ApButtonMain>
        </ApButtonGroup>
      )}
      isDismissible={false}
      isFluid={false}
      isOpen
      zIndex={998}
      hasClosed={onClose}
    >
      <div className="trumped-match-claim-popup">
        <div>
          The sub match code entered is the same as the sub match code on the matched
          scheduled claims {claimList}
        </div>

        <div>Saving this sub match code will trump the scheduled claim</div>

        <div>Do you wish to continue?</div>
      </div>
    </ApModal>
  );
};

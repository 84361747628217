import { Workbook } from 'exceljs';
import { formatUSDate, getCurrentDate } from 'utils/formatDate';
import {
  rowStyleClaimsWorflowValues,
  rowStyleWorkflowHeader,
  styleBoldText,
} from './tableStyles';
import { CellValue, excelAddRow, excelMergeCellsVertical } from './excelUtils';

const HEADER_TEXT_WORKFLOW = [
  '',
  'Dashboard Categories',
  'Workflow Status',
  'Count',
  'Total Proposed',
];

export type ClaimsRowSection = {
  name: string;
  values: CellValue[][];
};

export type ClaimsData = ClaimsRowSection[];

export const claimTableWorkbook = async (data: ClaimsData) => {
  const workbook = new Workbook();
  const firstSheet = workbook.addWorksheet('Claims Report', {
    views: [{ showGridLines: false, zoomScale: 80 }],
  });

  firstSheet.columns = [
    { width: 10 },
    { width: 35 },
    { width: 45 },
    { width: 25 },
    { width: 25 },
  ];

  excelAddRow(firstSheet, ['Claims by Workflow Statuses'], [styleBoldText]);

  excelAddRow(firstSheet, [formatUSDate(getCurrentDate())], [styleBoldText]);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);

  excelAddRow(firstSheet, HEADER_TEXT_WORKFLOW, rowStyleWorkflowHeader);

  const colNumTitle = 1;

  data.forEach(({ values, name }) => {
    const rowValues = values.map((v) => ['', '', ...v]);

    rowValues[0][colNumTitle] = name;

    const addedRows = rowValues.map((values) =>
      excelAddRow(firstSheet, values, rowStyleClaimsWorflowValues),
    );
    excelMergeCellsVertical(addedRows, colNumTitle + 1);
  });

  return workbook;
};

import { ContractsUserGroupsResponse, UserInfo } from 'api/endpoints';
import classNames from 'classnames';
import { ApNiceSelect } from 'components/ApNiceSelect/ApNiceSelect';
import { ReviewersEdit } from 'components/ReviewersEdit/ReviewersEdit';
import { ContractReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import isEqual from 'lodash/isEqual';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  ContractReviewerData,
  setContractReviewers,
} from 'reducer/contractReviewerReducer';
import { FetchStatus } from 'types/fetch-status.types';
import { formatLegalEntity } from 'utils/format';
import { EditFieldFunc } from '../../hook/useEditContract';
import { CategorySelectDef } from '../../types';
import {
  ACTION_LABEL_MAP,
  ACTION_VALUE_MAP,
  APROVAL_STATUSES_APUSER,
  APROVAL_STATUSES_CLIENT,
  BOOLEAN_OPTIONS,
  CategorySelectType,
  CATEGORY_SELECT_MAP,
  OPTIONS_VALUE_MAP,
  PRIORITY_OPTIONS,
} from './config';
import 'pages/SingleClaimPage/sections/SingleClaimOverview/components/CategorySelect/CategorySelect.css';

export interface CategorySelectProps extends CategorySelectDef {
  editField: EditFieldFunc;
  disabled?: boolean;
}

export const CategorySelect = ({
  type,
  name,
  contract,
  disabled,
  editField,
}: CategorySelectProps) => {
  const dispatch = useAppDispatch();
  const {
    contractActionNameSelection: selectionState,
    contractReviewer: activeReviewers,
    selectable: { data: selectableData },
    allUsers,
    contractsUserGroups: allGroups,
    contractPaymentPositions: { data: paymentPositions },
  } = useAppSelector((state) => state);

  const getActiveUserReviewers = (activeUserIds: number[], users: UserInfo[]) =>
    users.map((u) => ({
      ...u,
      checked: activeUserIds.includes(u.id) && !u.hasOwnProperty('userGroupName'),
    }));

  const getActiveGroupReviewers = (
    activeUserGroupIds: number[],
    groups: ContractsUserGroupsResponse[],
  ) =>
    groups.map((g) => ({
      ...g,
      checked: activeUserGroupIds.includes(Number(g.id)),
    }));

  const isContractReviewerChanged = (
    e: ContractReviewersBatchItem,
    data: ContractReviewerData,
  ) => {
    return (
      !isEqual(e.contractReviewerUserIds, data.userReviewers) ||
      !isEqual(e.contractReviewerUserGroupIds, data.userGroupReviewers)
    );
  };

  const isContractReviewersLoading = () => {
    return (
      allGroups.status !== FetchStatus.Done || allUsers.status !== FetchStatus.Done
    );
  };

  if (!selectableData) return null;
  const optionKey = CATEGORY_SELECT_MAP[type];
  const optionsBeforeType = selectableData[optionKey];
  const value = (contract as any)[name];

  if (
    type === CategorySelectType.Category ||
    type === CategorySelectType.ContractType ||
    type === CategorySelectType.ContractStatus
  ) {
    let options = optionsBeforeType as any[];
    if (type === CategorySelectType.ContractType) {
      const emptyItem = { label: '', value: '' };
      options = [emptyItem, ...selectableData.contractTypesByCategory];
    }

    const optionLabel = (OPTIONS_VALUE_MAP as any)[type];

    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        value={value}
        name={name}
        options={options?.map((c) => ({
          label: c[optionLabel],
          value: `${c.id}`,
        }))}
        onChange={(e) => {
          editField(name, e.target.value);
        }}
      />
    );
  }

  if (type === CategorySelectType.LegalEntities) {
    const options = optionsBeforeType as any[];

    return (
      <ApNiceSelect
        showValueAsTooltip
        emptyDefaultOption
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        onChange={(e) => {
          if (disabled) return;
          editField(name, e.target.value);
        }}
        value={value}
        name={name}
        options={options.map((c) => ({
          label: formatLegalEntity(c),
          value: `${c.id}`,
        }))}
      />
    );
  }

  if (type === CategorySelectType.ContractReviewer) {
    const { data } = activeReviewers;

    return (
      <ReviewersEdit
        users={getActiveUserReviewers(data.userReviewers, allUsers.data || [])}
        groups={getActiveGroupReviewers(
          data.userGroupReviewers,
          allGroups.data || [],
        )}
        disabled={disabled}
        loading={isContractReviewersLoading()}
        onChange={(e) => {
          if (isContractReviewerChanged(e, data)) {
            dispatch(setContractReviewers(e));
            editField(name, e);
          }
        }}
        section={SectionType.Contracts}
      />
    );
  }

  if (
    type === CategorySelectType.ContractActionName ||
    type === CategorySelectType.ContractActionSubstatus ||
    type === CategorySelectType.ContractActionType
  ) {
    const { contractActionInfo } = selectionState;
    const options = (selectionState as any)[OPTIONS_VALUE_MAP[type]];
    const label = ACTION_LABEL_MAP[type];
    const valueKey = ACTION_VALUE_MAP[type];
    const value = (contractActionInfo as any)[valueKey];

    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        value={value}
        name={name}
        options={options?.map((c: any) => ({
          label: c[label],
          value: `${c.id}`,
        }))}
        onChange={(e) => editField(name, e.target.value)}
      />
    );
  }

  if (
    type === CategorySelectType.NonDebtorGuarantee ||
    type === CategorySelectType.AutoRenewal ||
    type === CategorySelectType.Priority ||
    type === CategorySelectType.ExhibitTypeId
  ) {
    const TYPE_MAP: { [key: string]: any[] } = {
      [CategorySelectType.Priority]: PRIORITY_OPTIONS,
      [CategorySelectType.ExhibitTypeId]: optionsBeforeType,
    };
    const options = TYPE_MAP[type] ?? BOOLEAN_OPTIONS;

    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        value={value}
        name={name}
        options={options}
        onChange={(e) => editField(name, e.target.value)}
      />
    );
  }

  if (type === CategorySelectType.PaymentPosition) {
    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        value={value}
        name={name}
        options={
          paymentPositions?.map((c: any) => ({
            label: c.paymentPositionName,
            value: c.id,
          })) || []
        }
        onChange={(e) => editField(name, e.target.value)}
      />
    );
  }

  if (
    type === CategorySelectType.ApUserApprovalStatus ||
    type === CategorySelectType.ClientApprovalStatus
  ) {
    const options =
      type === CategorySelectType.ApUserApprovalStatus
        ? APROVAL_STATUSES_APUSER
        : APROVAL_STATUSES_CLIENT;

    return (
      <ApNiceSelect
        disabled={disabled}
        className={classNames('category-select__select', { disabled })}
        value={value}
        name={name}
        options={options}
        emptyDefaultOption
        onChange={(e) => editField(name, e.target.value)}
      />
    );
  }

  return <div />;
};

import React from 'react';
import { ErrorBoundaryView } from './ErrorBoundaryView';

interface IProps {
  children?: React.ReactNode;
}

interface IState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

const emptyState: IState = {
  error: undefined,
  errorInfo: undefined,
};

export class ErrorBoundary extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = emptyState;
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (!error) return this.props.children;

    return (
      <ErrorBoundaryView
        message={error?.message}
        stackTrace={errorInfo?.componentStack}
      />
    );
  }
}

import { getQuiet, post, put } from '../apiRequest';

export interface ClientInfo {
  clientCompanyName: string;
  internalProjectName: string;
  logoUrl?: string;
  logoFileName: string;
  primaryBrandColor: string;
  secondaryBrandColor: string;
}

export interface BasicClientInfo extends ClientInfo {
  logoContent?: ArrayBuffer;
}

export const settings = {
  // Basic Information
  getBasicInfo: () => getQuiet<ClientInfo>('v1/Settings/basic-info'),
  postBasicInfo: (clientInfo: ClientInfo) =>
    post('v1/Settings/basic-info', clientInfo),

  // Generic Settings methods
  getSettings: (setting: string) => getQuiet(`v1/Settings/${setting}`),
  saveSettings: (setting: string, value: string) => {
    put(`v1/Settings/${setting}?value=${value}`, {
      name: setting,
      value,
    });
  },

  // Sofa Specific Settings methods
  getSofaSettings: () => {
    const CoverType = getQuiet('v1/Settings/CoverType');
    const ShowRedacted = getQuiet('v1/Settings/ShowRedacted');
    return Promise.all([CoverType, ShowRedacted]);
  },
  saveSofaSettings: (
    setting: string,
    value: { CoverType: string; ShowRedacted: boolean },
  ) => {
    put(`v1/Settings/sofas-and-schedules`, {
      ...value,
    });
  },
};

import { ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { FileStatus } from 'types/dataUploaderTypes';

export const FileStatusIcon = (props: {
  fileStatus: FileStatus | undefined;
  isError: boolean;
  iconColor?: string;
}) => {
  if (props.isError)
    return <ApIcon iconName="outline_warning" iconColor="#EE3A36" />;

  switch (props.fileStatus) {
    case FileStatus.Published:
      return (
        <ApIcon
          iconName="outline_check_circle_outline"
          iconColor={props.iconColor}
        />
      );

    case FileStatus.Uploading:
    case FileStatus.InProgress:
      return <ApLoaderDefault loaderSize="sm" />;

    case FileStatus.Failed:
    case FileStatus.ApplicationException:
      return <ApIcon iconName="outline_warning" iconColor="#EE3A36" />;

    case FileStatus.Unpublished:
      return <ApIcon iconName="outline_insert_drive_file" />;

    default:
      return null;
  }
};

import { useCallback, useMemo, useState } from 'react';

/**
 * Transform a form's element form data into a plain javascript object.
 * The keys of the object will be the input names of the form. If the input name ends in square brackets it will gather them into javascript array.
 * Usage:
 * ```
 * const formValues = formDataToObject(new FormData(form));
 * ```
 */
export function formDataToObject<R>(
  formData: FormData,
  isMulti?: Record<string, boolean>,
): R {
  const keys = Array.from(formData.keys());
  const formObject = keys.reduce((acc, key) => {
    if (isMulti) {
      acc[key] = isMulti?.[key] ? formData.getAll(key) : formData.get(key);
      return acc;
    }
    if (key.endsWith('[]')) {
      const actualKey = key.slice(0, -2);
      acc[actualKey] = formData.getAll(key);
      return acc;
    }

    acc[key] = formData.get(key);
    return acc;
  }, {} as any);

  return formObject as R;
}

export type FormValidState = 'idle' | 'invalid' | 'valid';
export type ValidStateHelpers = {
  validState: FormValidState;
  setValidState: (
    s: FormValidState,
  ) => void | ((func: (s: FormValidState) => FormValidState) => void);
};

export type UseSubmitHandler = (
  evt: React.FormEvent<HTMLFormElement>,
  helpers: {
    setValidState: (
      value:
        | ((prevState: 'idle' | 'invalid' | 'valid') => 'idle' | 'invalid' | 'valid')
        | 'idle'
        | 'invalid'
        | 'valid',
    ) => void;
    validState: string;
  },
  formValues: Record<string, any>,
) => void;

/**
 * Enhances the a form's 'onsubmit' handler by return FormData values as a javascript object and the validity state of the form.
 * Usage:
 * ```
 * const mySubmit = (e, { validState }, formValues) => {};
 * const { handleSubmit } = useSubmitHandler(mySubmit);
 * ...
 * <form onSubmit={handleSubmit}> </form>
 * ```
 */
export function useSubmitHandler(onSubmit: UseSubmitHandler) {
  const [validState, setValidState] = useState<FormValidState>('idle');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      const valid = form.checkValidity();
      const formData = new FormData(form);
      const formValues = formDataToObject<Record<string, any>>(formData);
      const vs = valid ? 'valid' : 'invalid';
      setValidState(vs);
      onSubmit(
        e,
        {
          validState: vs,
          setValidState,
        },
        formValues,
      );
    },
    [onSubmit],
  );

  return useMemo(() => {
    return {
      validState,
      setValidState,
      handleSubmit,
    };
  }, [handleSubmit, validState]);
}

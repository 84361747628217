import { SettlementStatus } from 'api/endpoints/settlements';
import classnames from 'classnames';
import { getSettlementStatusName } from 'utils/settlements/settlementType';
import './SettlementStatusPill.css';

// interface StatusTypeItem {
//   text: string;
//   color: string;
// }

const SettlementStatusColor: Record<SettlementStatus, string> = {
  SettlementPending: '#7C3AED',
  AwaitingClientApproval: '#0EA5E9',
  AwaitingCounterpartyReview: '#0891B2',
  ViewedByCounterparty: '#10B981',
  SignedByCounterparty: '#488929',
  DeclinedByCounterparty: '#DC2626',
  SettlementExecuted: '#F59E0B',
  SettlementResolved: '#D97706',
  SettlementPaid: '#92400E',
}; //satisfies Record<string, StatusTypeItem>

interface Props {
  className?: string;
  status: SettlementStatus | undefined;
  bigFont?: boolean;
}

export const SettlementStatusPill = ({ className, status, bigFont }: Props) => {
  const color = SettlementStatusColor[status!] ?? undefined;
  const name = getSettlementStatusName(status);

  return (
    <div
      className={classnames(
        'settlement-status-pill',
        bigFont ? 'settlement-status-pill__big' : 'settlement-status-pill__normal',
        className,
      )}
      style={{ background: color }}
    >
      <span>{name}</span>
    </div>
  );
};

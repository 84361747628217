import { ApSimpleSearch } from '@alixpartners/ui-components';
import { HelpTileBox } from './components/HelpTileBox';
import { HelpTitle } from './components/HelpTitle';
import { MainFixedWidthBox } from './components/MainFixedWidthBox';
import { PopularItems } from './components/PopularItems';
import './HelpMainPage.css';
import { helpStructure } from './helpStructure';

const PANELS_ITEMS = Object.entries(helpStructure).map(([key, value]) => ({
  key,
  description: value.description,
}));

const SEARCH_POPULAR_ITEMS = [
  'Data Uploader',
  'Exhibit Exporter',
  'Claim Settlement',
];

interface Props {
  onViewMoreClick: (section: string) => void;
  onSearch: (query: string) => void;
}

export const HelpMainPage = ({ onViewMoreClick, onSearch }: Props) => {
  const handlePopularItemClick = (value: string) => {
    const searchField = document.getElementById('Help search') as HTMLInputElement;
    if (searchField) searchField.value = value;

    //just for better user experince
    setTimeout(() => onSearch(value), 400);
  };

  const handleViewMoreClick = (section: string) => () => onViewMoreClick(section);

  return (
    <div className="settings-help">
      <MainFixedWidthBox>
        <div className="settings-help__title">
          <HelpTitle>What are you looking for?</HelpTitle>
          <div className="settings-help__search">
            <ApSimpleSearch id={'Help search'} onSearch={onSearch} />
          </div>
          <PopularItems
            items={SEARCH_POPULAR_ITEMS}
            onItemClick={handlePopularItemClick}
          />
        </div>
      </MainFixedWidthBox>
      <MainFixedWidthBox className="settings-help__body">
        <div className="settings-help__content">
          <header>Help</header>
          <div className="settings-help__panels">
            {PANELS_ITEMS.map(({ key, description }) => {
              return (
                <HelpTileBox
                  key={key}
                  title={key}
                  onClick={handleViewMoreClick(key)}
                >
                  {description}
                </HelpTileBox>
              );
            })}
          </div>
        </div>
      </MainFixedWidthBox>
    </div>
  );
};

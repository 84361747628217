import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import './MatchScoreCell.css';

export const MatchScoreCell = (props: ICellRendererParams) => {
  const score = props.data?.score;
  const numberOfMatches = props.data?.numberOfMatches;

  return (
    <div
      className={classNames('match-score-cell', {
        green: score >= 0.8,
        orange: score >= 0.4 && score <= 0.8,
        red: score < 0.4,
      })}
    >
      {typeof score === 'number' ? (
        <>
          <div className="match-score-cell__progress-bar">
            {score > 0 && (
              <div
                className="match-score-cell__bar"
                style={{
                  width: Math.ceil(score * 100),
                }}
              />
            )}
          </div>
          <div className="percent">{Math.floor(score * 100)}%</div>
        </>
      ) : (
        <div className="label">
          {numberOfMatches > 0 ? 'Multiple scores' : '- -'}
        </div>
      )}
    </div>
  );
};

export const SCHEDULE_G_1_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Codebtor Name',
      propertyName: 'codebtorName',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'State the Nature of Contract / Lease',
      propertyName: 'natureOfContractLease',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'The Term Remaining',
      propertyName: 'termRemaining',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Contact of Any Government Contract',
      propertyName: 'contactOfAnyGovernmentContract',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Name of Executory Contract / Lease',
      propertyName: 'natureOfContractLease',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Category',
      propertyName: 'category',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

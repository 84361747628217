import { useCallback, useState } from 'react';

import {
  ApButtonMain,
  ApButtonSecondary,
  ApLoaderDefault,
  ApModal,
} from '@alixpartners/ui-components';
import { BrowserFileRow } from 'components/ModalWindows/UploadModal/FileRowLite';
import { useId } from 'hooks/useId';
import { DataContentBox } from 'pages/DataUploaderPage/components/DataContentBox';
import { FileUploadPanel } from 'pages/DataUploaderPage/components/FileUploadPanel';

import 'components/ModalWindows/UploadModal/UploadModal.css';

const WARNING_TEXT =
  'Please note that the file you are uploading is going to overwrite any existing data.';

type UploadModalProps = {
  label: string;
  setShowUploadModal: (show: boolean) => void;
  onSubmit: (file: File) => Promise<void>;
  showUploadModal?: boolean;
  isUploading?: boolean;
};

export const BatchEditorUploadModal = ({
  label,
  onSubmit,
  setShowUploadModal,
  showUploadModal,
  isUploading,
}: UploadModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const formId = useId();

  const onUpload = useCallback((files: File[]) => {
    setSelectedFile(files[0]);
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (selectedFile) onSubmit(selectedFile);
  };

  const renderFooter = useCallback(
    (_footerProps: { handleClose?(): void }) => {
      if (!selectedFile) return;

      return (
        <>
          <ApButtonSecondary
            className="ap-button-casual cancel-button"
            onClick={() => setShowUploadModal(false)}
          >
            Cancel
          </ApButtonSecondary>
          <ApButtonMain type="submit" form={formId} className="upload-modal__action">
            Upload {isUploading && <ApLoaderDefault loaderSize="sm" />}
          </ApButtonMain>
        </>
      );
    },
    [formId, setShowUploadModal, selectedFile, isUploading],
  );

  return (
    <ApModal
      className={'upload-modal utils-form batchedit-modal'}
      isOpen={showUploadModal}
      isDismissible={true}
      header="Upload a new batch edit file"
      footer={renderFooter}
    >
      <DataContentBox label={label} warning={WARNING_TEXT}>
        {!selectedFile && (
          <FileUploadPanel
            accept={['.xls', '.xlsx']}
            onUpload={onUpload}
            maxFiles={1}
          />
        )}
      </DataContentBox>
      <form id={formId} onSubmit={handleSubmit} autoComplete="off" noValidate>
        {selectedFile && (
          <div className="upload-modal-appear">
            <BrowserFileRow
              file={selectedFile}
              onDelete={() => setSelectedFile(undefined)}
            />
          </div>
        )}
      </form>
    </ApModal>
  );
};

import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon/SvgIcon';
import { MouseEventHandler } from 'react';
import { iconImage } from '../SvgIcon/iconImageList';
import './button.css';

interface IProps {
  icon: keyof typeof iconImage;
  width?: number;
  height?: number;
  iconSize?: number;
  iconColor?: string;
  iconStroke?: string;
  dataId?: string;
  dataName?: string;
  type?: 'button' | 'reset' | 'submit';
  className?: string;
  disabled?: boolean;
  hint?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ButtonIcon = ({
  icon,
  width = 36,
  height = 36,
  iconSize,
  iconColor,
  iconStroke,
  type,
  dataId,
  dataName,
  className,
  disabled,
  hint,
  onClick,
}: IProps) => {
  return (
    <button
      data-id={dataId}
      data-name={dataName}
      type={type}
      data-testid="button-icon"
      className={classNames('c11-buttonicon', className)}
      onClick={onClick}
      style={{ width, height }}
      disabled={disabled}
      title={hint}
    >
      <SvgIcon
        iconName={icon}
        width={iconSize}
        height={iconSize}
        fill={iconColor}
        stroke={iconStroke}
      />
    </button>
  );
};

import { DynamicField } from 'api/endpoints/dynamic-fields';
import classNames from 'classnames';
import { FilterComponent } from 'components/UIComponents/EntityTable';
import { useAppSelector } from 'hooks/reducerHooks';
import { FILTER_OPTIONS } from './DynamicFields.const';
import './DynamicFields.css';

export const DynamicFieldFilter: FilterComponent<DynamicField> = ({
  filterKey,
  fromTable,
}) => {
  const { disabled, className } = fromTable;

  const selectedFilter = useAppSelector(
    (s) => s.dynamicFields.input.filter.type ?? '',
  );

  if (filterKey === 'type') {
    return (
      <select
        name="type"
        className={classNames(className, 'utils-clean-input', 'utils-capitalize')}
        required
        disabled={disabled}
        defaultValue={selectedFilter}
      >
        {FILTER_OPTIONS.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </select>
    );
  }

  return null;
};

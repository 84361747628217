import classNames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ClaimsItemsData, ContractsBaseDetailedData } from 'pages/Dashboard/types';
import { DetailedItemContent } from './DetailedItemContent';

export const DetailedItem = ({
  dataToRender,
  firstItem,
  className,
  children,
  sectionType,
}: {
  dataToRender: ContractsBaseDetailedData | ClaimsItemsData;
  firstItem?: boolean;
  className?: string;
  children?: JSX.Element | any;
  sectionType: SectionType;
}) => {
  return (
    <div
      className={classNames(className, {
        firstItem,
      })}
    >
      <DetailedItemContent
        data={dataToRender}
        firstItem={firstItem}
        sectionType={sectionType}
      />
      {children}
    </div>
  );
};

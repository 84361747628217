import {
  jsonFetchGet,
  jsonFetchPost,
  jsonFetchPut,
} from 'api/jsonFetch/authJsonFetch';
import { CommonErrorBody } from './types';

export type ServerActionName = {
  id: number;
  actionName: string;
  actionExhibitTypeId: number;
  actionExhibitTypeName: string;
  actionSubStatusId: number;
  actionSubStatusName: string;
  hearingDate?: string;
};

export type ServerContractActionName = {
  id: number;
  contractActionName: string;
  contractActionTypeId: number;
  contractActionNameId: number;
  contractActionTypeName: string;
  contractActionSubStatusId: number;
  contractActionSubStatusName: string;
  hearingDate?: string;
  contractExhibitTypeId: number;
};

export type ActionName = {
  id: number;
  name: string;
  typeId: number;
  substatusId: number;
  hearingDate?: string;
};

export type ContractActionName = {
  id: number;
  contractActionName: string;
  contractActionNameId: number;
  hearingDate?: string;
  contractExhibitTypeId: number;
};

export type PostParams = {
  actionName: string;
  actionSubStatusId: number;
  hearingDate?: string;
};

export type ContractPostParams = {
  contractExhibitTypeId: number;
  contractActionName: string;
  hearingDate?: string;
};

export type PutParams = PostParams & { id: number };
export type ContractPutParams = ContractPostParams & { id: number };

export type BadRequestError = {
  status: 400;
  error: CommonErrorBody[];
};

export const actionNames = {
  get: () => {
    return jsonFetchGet<ServerActionName[]>(`/v1/ActionNames`);
  },
  post: (params: PostParams) => {
    return jsonFetchPost<ServerActionName, BadRequestError>(
      `/v1/ActionNames`,
      params,
    );
  },
  put: (params: PutParams) => {
    return jsonFetchPut<ServerActionName, BadRequestError>(
      `/v1/ActionNames`,
      params,
    );
  },
  getContractActionNames: () => {
    return jsonFetchGet<ServerContractActionName[]>(`/v1/ContractActionNames`);
  },
  putContractActionNames: (params: ContractPutParams) => {
    return jsonFetchPut<ServerContractActionName, BadRequestError>(
      `/v1/ContractActionNames`,
      params,
    );
  },
  postContractActionNames: (params: ContractPostParams) => {
    return jsonFetchPost<ServerContractActionName, BadRequestError>(
      `/v1/ContractActionNames`,
      params,
    );
  },
};

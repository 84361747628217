import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ButtonNav } from 'pages/SettingsPage/components/ButtonNav';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './DiscloseLinkMenu.css';
import './DiscloseLinkMenu.transitions.css';
import { MenuItem } from './DisclosureLinkMenu.types';

export type Props = {
  item: MenuItem;
  selectedComponent?: JSX.Element;
  onChange?: (item: any) => void;
};

export function DiscloseLinkMenu(props: Props) {
  const { item, selectedComponent, onChange } = props;

  const isSelectedComponent = selectedComponent === item.component;

  const [isOpened, setIsOpened] = useState(item.defaultOpened);

  const itemCN = classNames('disclosurelinkmenu__item', {
    'disclosurelinkmenu__item-active': isOpened,
  });

  if (item.type === 'direct') {
    const link = item.baseLink ?? '';
    if (item.isButton) {
      return (
        <ButtonNav
          text={item.label}
          onClick={() => onChange?.(item.component)}
          className={classNames('disclosurelinkmenu__subitem', {
            'disclosurelinkmenu__subitem-active': isSelectedComponent,
          })}
          disabled={item.disabled}
        />
      );
    }
    return (
      <div className="disclosurelinkmenu">
        <Link className={itemCN} to={link}>
          <span>{item.label}</span>
          <SvgIcon iconName="arrow_right" width={14} height={14} className="" />
        </Link>
      </div>
    );
  }

  const handleSectionClick = () => {
    if (item.component && !isSelectedComponent) {
      setIsOpened(true);
    } else {
      setIsOpened((v) => !v);
    }
    item.component && onChange?.(item.component);
  };

  return (
    <Disclosure className="disclosurelinkmenu" defaultOpen={isOpened} as={'div'}>
      <Disclosure.Button className={itemCN} onClick={handleSectionClick}>
        <span>{item.label}</span>
        <SvgIcon
          iconName="chevron_left"
          className={
            isOpened
              ? 'disclosurelinkmenu__chevron-open'
              : 'disclosurelinkmenu__chevron'
          }
          width={16}
          height={16}
        />
      </Disclosure.Button>
      <Transition
        show={isOpened}
        enter="enter"
        enterFrom="enterFrom"
        enterTo="enterTo"
        leave="leave"
        leaveFrom="leaveFrom"
        leaveTo="leaveTo"
      >
        <Disclosure.Panel className="disclosurelinkmenu__panel">
          {item.subItems?.map((subItem) => {
            const { active, label, root, component, disabled } = subItem;
            const { defaultLink, link = '' } = subItem;

            if (item.isButton) {
              return (
                <ButtonNav
                  key={`disclose-${label}`}
                  text={label}
                  onClick={() => onChange?.(component)}
                  className={classNames('disclosurelinkmenu__subitem', {
                    'disclosurelinkmenu__subitem-active':
                      selectedComponent === component,
                  })}
                  disabled={disabled}
                />
              );
            }

            if (active === false)
              return (
                <div
                  className="disclosurelinkmenu__subitem disclosurelinkmenu__subitem-disabled"
                  key={`disclose-${label}`}
                >
                  <span>{label}</span>
                </div>
              );

            return (
              <NavLink
                className={createSubitemCN}
                key={link}
                to={root && defaultLink ? `/${defaultLink}` : link}
              >
                <span>{label}</span>
              </NavLink>
            );
          })}
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
}

function createSubitemCN({ isActive }: { isActive: boolean }) {
  return classNames('disclosurelinkmenu__subitem', {
    'disclosurelinkmenu__subitem-active': isActive,
  });
}

import { Link } from 'react-router-dom';
import './CounterpartyNameCell.css';

export const ConterpartyLink = (props: {
  text: string;
  to: string;
  onClick: () => void;
}) => {
  const { text, to, onClick } = props;

  return (
    <Link to={to} onClick={onClick} className="counterparty-name-cell">
      <span className="cell-name">{text}</span>
    </Link>
  );
};

export const ConterpartyLinkWithIcon = (props: {
  text: string;
  to: string;
  onClick: () => void;
}) => {
  const { text, to, onClick } = props;

  return (
    <div className="counterparty-name-cell__with-icon">
      <Link to={to} onClick={onClick} className="counterparty-name-cell">
        <span className="cell-name">{text}</span>
      </Link>

      <div className="cell-icon">+</div>
    </div>
  );
};

export const ConterpartyNameWithIcon = (props: { text: string }) => {
  const { text } = props;

  return (
    <div className="counterparty-name-cell__with-icon">
      <span className="cell-name">{text}</span>

      <div className="cell-icon">+</div>
    </div>
  );
};

import { IServerSideGetRowsParams } from 'ag-grid-community';
import { GridColumn } from '../api/endpoints';
import union from 'lodash/union';

export function getRequestColumns(
  params: IServerSideGetRowsParams,
  gridColumns: GridColumn[] | undefined,
  requiredColumns?: string[],
  allColumns?: boolean,
) {
  // Generate a GraphQL query based on column properties
  const columns: string[] = [...(gridColumns || [])]
    .sort((a, b) => a?.id - b?.id)
    .map((c) => (c.subProperty ? '' : c.propertyName))
    .filter((c) => c !== '');

  const displayedColumns = params.columnApi?.getAllDisplayedColumns()
    .map((c) => c.getColId());

  const requestColumns = allColumns
    ? [...columns]
    : [...displayedColumns].filter((col) => columns.includes(col));

  gridColumns?.forEach((c) => {
    if (c.subProperty) {
      requestColumns.push(`${c.propertyName} { ${c.subProperty} }`);
    }
  });

  return requiredColumns ? union(requestColumns, requiredColumns) : requestColumns;
}

import { ReportType } from 'api/endpoints/sofas-schedules';
import classNames from 'classnames';
import { useState } from 'react';

import { SofasExportForm } from './components/SofasExportForm';
import { SofasExportLoading } from './components/SofasExportLoading';
import { SofaTemplateLoading } from './components/SofasTemplateLoading';
import './SofasSchedulesExportPanel.css';
import { useSofasExport } from './useSofasExport';
import { useSofasExportItems } from './useSofasExportItems';
import { useTemplateLoading } from './useTemplateLoading';

export function SofasSchedulesExportPanel() {
  const [reportType, setReportType] = useState<ReportType>('Sofa');

  const items = useSofasExportItems({ reportType });
  const pdfExport = useSofasExport(items);
  const template = useTemplateLoading();

  const handleReportTypeChange = (value: ReportType) => {
    setReportType(value);
    items.cleanUpSelected();
  };

  const showLoaderPanel = pdfExport.showLoadingPanel || template.loading;

  return (
    <div className={classNames('sofas-schedules-panel sofas-export-panel')}>
      <h4>Exporter</h4>

      {showLoaderPanel ? (
        <div className="sofas-schedules-panel__inner-panel sofas-export-content">
          {pdfExport.showLoadingPanel && (
            <SofasExportLoading
              exportStatus={pdfExport.exportStatus}
              onCancel={pdfExport.cancelExport}
              onClose={pdfExport.closeLoadingPanel}
              onDownload={pdfExport.downloadFile}
            />
          )}
          {template.loading && <SofaTemplateLoading />}
        </div>
      ) : (
        <div className="sofas-schedules-panel__inner-panel sofas-export-content">
          <SofasExportForm
            reportType={reportType}
            exportSelected={items.selected}
            isFieldsValid={items.isFieldsValid}
            dropdownItems={items.dropdownItems}
            template={template.selected}
            onReportTypeChange={handleReportTypeChange}
            onExportSelectedChange={items.setSelected}
            onExportSubmit={() => pdfExport.submit(reportType)}
            onTemplateSelectedChange={template.setSelected}
            onTemplateSubmit={template.submit}
          />
        </div>
      )}
    </div>
  );
}

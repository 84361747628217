import './VendorsPage.css';
import { Layout } from 'components/Layout';
import { useVendorsToolQueryParams } from './hooks/useVendorsQueryParam';
import classNames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SectionItem } from 'components/ClaimsMenuSectionTabs';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { VendorsDashboard } from './sections/Dashboard/VendorsDashboard';
import { VendorsHeaderTabs } from './components/VendorsHeaderTabs';

export const VendorsPage = () => {
  const { section, setSection, footerIsSticky } = useVendorsToolQueryParams();

  const handleSectionBtnClick = (section: SectionItem) => {
    // to lose focus after clicking ApButtonTab
    (document.activeElement as HTMLElement)?.blur();
    setSection(section.type);
  };

  const user = useApplicationUser();

  return (
    <Layout stickyFooter={footerIsSticky}>
      <section
        className={classNames('vendors-tool', {
          onDashboard: section === SectionType.Dashboard,
        })}
      >
        <VendorsHeaderTabs
          section={section}
          handleSectionBtnClick={handleSectionBtnClick}
        />
        {section === SectionType.Dashboard && <VendorsDashboard user={user} />}
      </section>
    </Layout>
  );
};

import { ApLoaderDefault } from '@alixpartners/ui-components';
import { useState } from 'react';
import { TrumpedMatchClaimPopup } from './components/TrumpedMatchClaimPopup';
import { ClaimsTable } from './sections/ClaimsTable/ClaimsTable';
import { ClaimsToolMatching } from './sections/ClaimsToolMatching/ClaimsToolMatching';
import { MatchingPage } from './sections/ClaimsToolMatchingExport/MatchingPage';
import { ClaimsData, SectionType, SubsectionType } from './types';
import { MatchingHomePage } from './sections/ClaimsToolMatchingHomepage/MatchingHomepage';

interface Props {
  claimsCount?: number;
  claimsData: ClaimsData;
  section: SectionType;
  subsection: SubsectionType;
  setSubsection: (s: SubsectionType) => void;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
  onSectionClick: (section: SectionType) => unknown;
}

// depending on the whether Matching tab will have both contracts and claims,
// we might want to have it renamed to ClaimsContractsToolBody
export const ClaimsToolBody = ({
  claimsCount,
  section,
  subsection,
  claimsData,
  setSubsection,
  disableResetFilters,
  onResetFilters,
  onSectionClick
}: Props) => {
  const { onMatchGroupSelectedClaims, onAcceptMatches, trumpedMatchDialog } =
    claimsData;
  const [isColumnSelectionOpen, setColumnSelection] = useState(false);

  if (!claimsData) return null;

  if (!claimsData.gridColumnsReady)
    return <ApLoaderDefault className="loader-container" />;

  return (
    <>
      {trumpedMatchDialog && (
        <TrumpedMatchClaimPopup
          claimId={trumpedMatchDialog.matchedClaims}
          onAccept={trumpedMatchDialog.onAccept}
          onClose={trumpedMatchDialog.onCancel}
        />
      )}
      {section === SectionType.Claims && (
        <ClaimsTable
          summaryMatchDialog={claimsData.summaryMatchingDialog}
          claimsCount={claimsCount}
          claimsData={claimsData}
          isColumnSelectionOpen={isColumnSelectionOpen}
          disableResetFilters={disableResetFilters}
          onResetFilters={onResetFilters}
          setColumnSelection={setColumnSelection}
        />
      )}
      {section === SectionType.MatchingHomepage && (
        <MatchingHomePage handleSectionChange={onSectionClick} />
      )}
      {section === SectionType.Matching && (
        <ClaimsToolMatching
          claimsCount={claimsCount}
          selectedRows={claimsData.selectedRows}
          isColumnSelectionOpen={isColumnSelectionOpen}
          claimsData={claimsData}
          subsection={subsection}
          onMatchGroupSelectedClaims={onMatchGroupSelectedClaims}
          onAcceptMatches={onAcceptMatches}
          setSubsection={setSubsection}
        />
      )}
      {section === SectionType.MatchingExport && <MatchingPage />}
    </>
  );
};

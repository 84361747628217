import { createContext, useContext } from 'react';
import { ClaimEditValues } from 'api/endpoints';
import { useClaimAttachments } from 'pages/DataUploaderPage/hooks/useClaimAttachments';

type Data = ReturnType<typeof useClaimAttachments>;
const AppInitialDataContext = createContext<Data>({} as Data);

export const ClaimAttachmentProvider = (
  props: React.PropsWithChildren<{ claimId: number; claim?: ClaimEditValues }>,
) => {
  const { children, claim, claimId } = props;
  const attachments = useClaimAttachments(claimId, claim);

  return (
    <AppInitialDataContext.Provider value={attachments}>
      {children}
    </AppInitialDataContext.Provider>
  );
};

export const useClaimAttachmentsContext = () => useContext(AppInitialDataContext);

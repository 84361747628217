import { LegalEntity } from 'api/endpoints';
import { SortState } from 'components/UIComponents/EntityTable/useTableSorting';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { iconImage } from 'components/UIComponents/SvgIcon/iconImageList';

export type EntitiesHeader = {
  field: keyof LegalEntity;
  label: string;
};

export const CasesEntitiesTextField = ({
  headers,
  legalEntities,
  sortIcon,
  handleSort,
  sortState,
}: {
  headers: EntitiesHeader[];
  legalEntities: LegalEntity[];
  sortIcon: keyof typeof iconImage;
  handleSort: (field: keyof LegalEntity) => void;
  sortState: SortState<LegalEntity>;
}) => {
  return (
    <>
      <div className="edit-table__header-row">
        {headers.map((h) => (
          <div
            key={h.field}
            className="edit-table__header"
            onClick={() => handleSort(h.field)}
          >
            {sortState.field === h.field ? <b>{h.label}</b> : h.label}{' '}
            <SvgIcon iconName={sortIcon} />
          </div>
        ))}
      </div>
      {legalEntities.map((item) => {
        return (
          <div className="dynamicfields__row-el" key={item.id}>
            <p>{item.entityName}</p>
            <p>{item.caseNumber}</p>
            <p>{item.legalEntityId}</p>
          </div>
        );
      })}
    </>
  );
};

import { ApButtonMain } from '@alixpartners/ui-components';
import { URL_PATH } from 'appUrls';
import { Link, useNavigate } from 'react-router-dom';
import { ExportHistoryButton } from './ExportHistoryButton';
import './HeaderBar.css';

export function HeaderBar() {
  const navigate = useNavigate();
  const handleClick = () => navigate(`${URL_PATH.SOFAS_SCHEDULES}/export`);
  const handleHistoryClick = () =>
    navigate(`${URL_PATH.SOFAS_SCHEDULES}/export-history`);

  return (
    <div className="sofas-schedules-headerbar">
      <Link relative="route" to={`${URL_PATH.SOFAS_SCHEDULES}`}>
        <h3 className="sofas-schedules__heading">SOFAs & Schedules</h3>
      </Link>

      <div className="sofas-schedules-headerbar__export">
        <div>
          <ExportHistoryButton onClick={handleHistoryClick} />
        </div>

        <ApButtonMain
          iconName="download"
          className="sofas-schedules__export-btn"
          onClick={handleClick}
        >
          Export reports
        </ApButtonMain>
      </div>
    </div>
  );
}

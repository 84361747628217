import { ContractsUserGroupsResponse, GroupUsersItem } from 'api/endpoints';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useMemo } from 'react';
import { mapClaimsCategoriesData } from '../../hooks/useClaimsUserGroups';
import { DeleteModalContentItem } from './DeleteModalContentItem';

export const DeleteModalContent = ({
  claimItem,
  contractItem,
  section,
}: {
  claimItem?: GroupUsersItem;
  contractItem?: ContractsUserGroupsResponse;
  section?: SectionType;
}) => {
  const {
    categories: claimCategories,
    applicationUsers: claimUsers,
    userGroupName: claimUserGroupName,
  } = claimItem || {};
  const {
    contractCategories,
    users: contractUsers,
    name: contractsUserGroupName,
  } = contractItem || {};

  const mappedClaimsCategories = useMemo(
    () => mapClaimsCategoriesData(claimCategories ?? []),
    [claimCategories],
  );

  const renderCategories = useCallback(
    (section?: SectionType) => {
      if (section === SectionType.Claims) {
        return mappedClaimsCategories?.map((c, idx) => (
          <span key={`${c.majorCategory}_${idx}`}>
            {c.majorCategory}
            {c.minorCategories.some((c) => c)
              ? ` (${c.minorCategories.filter((c) => c).join(', ')})`
              : ''}
            {idx + 1 < mappedClaimsCategories.length ? ', ' : ''}
          </span>
        ));
      }
      if (section === SectionType.Contracts) {
        return contractCategories?.map((category, idx) => (
          <span key={`${category.categoryName}_${idx}`}>
            {category.categoryName}
            {idx + 1 < contractCategories?.length ? ', ' : ''}
          </span>
        ));
      }
    },
    [mappedClaimsCategories, contractCategories],
  );

  const renderUsers = (section?: SectionType) => {
    if (section === SectionType.Claims) {
      return claimUsers?.map((user, idx) => (
        <span key={`${idx}_${user.firstName}`}>
          {user.firstName} {user.lastName}
          {idx + 1 < claimUsers.length ? ', ' : ''}
        </span>
      ));
    }
    if (section === SectionType.Contracts) {
      return contractUsers?.map((user, idx) => (
        <span key={`${idx}_${user.firstName}`}>
          {user.firstName} {user.lastName}
          {idx + 1 < contractUsers.length ? ', ' : ''}
        </span>
      ));
    }
  };

  return (
    <div className="delete-modal-content">
      <DeleteModalContentItem
        title="Group name:"
        renderContent={() =>
          section === SectionType.Claims
            ? claimUserGroupName
            : section === SectionType.Contracts
            ? contractsUserGroupName
            : '-'
        }
      />
      <DeleteModalContentItem
        title="Categories:"
        renderContent={() => renderCategories(section)}
      />
      <DeleteModalContentItem
        title="Users::"
        renderContent={() => renderUsers(section)}
      />
    </div>
  );
};

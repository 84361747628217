import { ContractEditValues } from 'api/endpoints';
import classNames from 'classnames';
import { SCPInput, SCPTextarea } from 'components/SingleClaim/SCPInput';
import { Datepicker } from 'components/UIComponents/Datepicker';
import { SwitchButton } from 'components/UIComponents/SwitchButton';
import { InputWithLabel } from 'pages/SingleContractPage/components/InputWithLabel';
import { formatDate } from 'utils/formatDate';
import { EditFieldFunc } from '../../hook/useEditContract';
import { SingleContractInputsType } from '../../types';
import { formatValue } from '../../util/inputValueFormatter';
import { CategorySelect } from '../CategorySelect';
import './SingleContractInputs.css';

export interface SingleContractInputsProps {
  orientation: 'vertical' | 'horizontal';
  inputs: SingleContractInputsType;
  contract?: ContractEditValues;
  isEditable: (field: string) => boolean;
  editField: EditFieldFunc;
}

export const SingleContractInputs = ({
  orientation,
  inputs,
  contract,
  isEditable,
  editField,
}: SingleContractInputsProps) => {
  const textFieldsInputs = inputs.filter(({ textField }) => textField);
  const otherInputs = inputs.filter(({ textField }) => !textField);

  const handleNumberChange = (
    value: string,
    field: string,
    originalValue: string,
  ) => {
    if (value !== originalValue) {
      const formattedValue = +value.replace(/[$,]/g, '');
      editField(field, formattedValue || 0);
    }
  };

  const textFields = textFieldsInputs.map(({ type, label, field }, index) => {
    const disabled = !isEditable(field);
    if (type === 'empty') return <div />;

    return (
      <InputWithLabel
        label={label}
        className="single-contract-overview__textfield-label"
        key={`textfieldWithLabel${index}`}
      >
        <SCPTextarea
          className={classNames({
            editable: !disabled,
          })}
          value={(contract as any)[field]}
          disabled={disabled}
          onBlur={(e) => editField(field, e.target.value)}
        />
      </InputWithLabel>
    );
  });

  const inputsElements = otherInputs.map(
    (
      { type, label, field, category, dateField, amountField, toggle, hasTitle },
      index,
    ) => {
      const disabled = !isEditable(field);
      if (type === 'empty')
        return <div key={index} className="single-contract-overview__input-label" />;

      const value = (contract as any)[field];

      return (
        <InputWithLabel
          label={label}
          className={classNames('single-contract-overview__input-label', {
            notesField: field === 'notes',
          })}
          key={`inputWithLabel${index}`}
        >
          {category ? (
            <CategorySelect
              {...category}
              disabled={disabled}
              editField={editField}
              contract={contract}
            />
          ) : dateField ? (
            <Datepicker
              className={classNames('scp-input', {
                editable: !disabled,
              })}
              defaultValue={value}
              disabled={disabled}
              onChange={(e) => (e ? editField(field, e) : value)}
              formatLabel={formatDate}
            />
          ) : toggle ? (
            <SwitchButton
              className="contract-item__primary-switch pl-8"
              value={value}
              onChange={(toggle) => editField(field, toggle)}
            />
          ) : (
            <SCPInput
              className={classNames({
                editable: !disabled,
                withTitle: hasTitle,
              })}
              type="text"
              value={formatValue({
                value: amountField ? value ?? 0 : value,
                dateField,
                field,
              })}
              disabled={disabled}
              preventBrowserAddressDialog={type === 'address'}
              onBlur={(e) =>
                amountField
                  ? handleNumberChange(e.target.value, field, value)
                  : editField(field, e.target.value)
              }
              amountField={amountField}
              title={hasTitle && value}
            />
          )}
        </InputWithLabel>
      );
    },
  );

  const rowsCount = Math.ceil(otherInputs.length / 2) + textFieldsInputs.length * 2;
  const height = rowsCount * 40 - 8;
  const style: any = {};
  if (orientation === 'vertical') style.height = height;

  return (
    <div className={classNames('single-contract-inputs', orientation)} style={style}>
      {orientation === 'horizontal' && (
        <>
          {inputsElements} {textFields}
        </>
      )}
      {orientation === 'vertical' && (
        <>
          <div className="left-column">
            {inputsElements.slice(0, Math.ceil(inputsElements.length / 2))}
          </div>
          <div className="right-column">
            {inputsElements.slice(
              Math.ceil(inputsElements.length / 2),
              inputsElements.length,
            )}
          </div>
          <div className="textfields">{textFields}</div>
        </>
      )}
    </div>
  );
};

import { ContractAttachmentDocumentType } from 'api/endpoints/contractAttachments';

export enum ContractTab {
  Overview = 'Overview',
  Matches = 'Matches',
  ChangesAndComments = 'ChangesAndComments',
  Documents = 'Documents',
  Approval = 'Approval',
  CounterpartiesAndDebtors = 'CounterpartiesAndDebtors',
  RelatedContracts = 'RelatedContracts',
}

export const CONTRACT_ATTACHMENTS_OPTIONS = [
  {
    value: `${ContractAttachmentDocumentType.ContractImage}`,
    label: 'Contract Image',
  },
  {
    value: `${ContractAttachmentDocumentType.CourtExhibit}`,
    label: 'Court Exhibit',
  },
  {
    value: `${ContractAttachmentDocumentType.VendorAgreement}`,
    label: 'Vendor Agreement',
  },
  {
    value: `${ContractAttachmentDocumentType.SettlementAgreement}`,
    label: 'Settlement Agreement',
  },
  {
    value: `${ContractAttachmentDocumentType.Communications}`,
    label: 'Communications',
  },
  {
    value: `${ContractAttachmentDocumentType.Reconciliations}`,
    label: 'Reconciliations',
  },
  {
    value: `${ContractAttachmentDocumentType.ScheduleDetail}`,
    label: 'Schedule Detail',
  },
  {
    value: `${ContractAttachmentDocumentType.FinancingAgreement}`,
    label: 'Financing Agreement',
  },
  {
    value: `${ContractAttachmentDocumentType.Email}`,
    label: 'Email',
  },
];

import { ApplyColumnStateParams, ColDef } from 'ag-grid-community';
import { ClaimRow, GridColumn } from 'api/endpoints';
import { useMemo } from 'react';

import { MatchScoreCell } from '../../components/MatchScoreCell';
import { SectionType, SubsectionType } from '../../types';

import compact from 'lodash/compact';
import { MATCH_SCORE_CELL, MATCH_SCORE_COLUMN_POSITION_ORDER } from '../../const';

import { isUndefined } from 'lodash';
import { sortColumnsByState } from '../columnsSortingUtils';
import {
  ClaimsColumnDefsCallbacks,
  claimsGridColumnMapper,
  claimsStaticColumnDef,
} from './claimsGridColumnMapper';

const MATCH_COLUMN_DEFINITION = {
  field: MATCH_SCORE_CELL,
  cellClass: MATCH_SCORE_CELL,
  width: 140,
  maxWidth: 140,
  resizable: false,
  suppressColumnsToolPanel: true,
  // menuTabs: [],
  cellRendererSelector: () => ({ component: MatchScoreCell }),
};

export const useClaimsColumnDef = (
  gridColumns: GridColumn[] | undefined,
  section: SectionType,
  subsection: SubsectionType,
  columnState: ApplyColumnStateParams | undefined,
  selectAllCheckbox: boolean,
  callbacks: ClaimsColumnDefsCallbacks,
) => {
  const columnDefs: ColDef<ClaimRow>[] = useMemo(() => {
    if (isUndefined(gridColumns)) return [];

    const staticColDef = claimsStaticColumnDef(
      section,
      subsection,
      selectAllCheckbox,
      callbacks,
    );

    const columnMapperFn = claimsGridColumnMapper(section, subsection, callbacks);
    const columnDef = compact(gridColumns?.map(columnMapperFn));
    let result = [...staticColDef, ...columnDef];

    result = sortColumnsByState(result, columnState);

    // this section is not used at this moment. Do we still need it??
    if (section === SectionType.Matching) {
      // we need to insert match score at particular place
      const position = Math.min(
        result.length - 1,
        MATCH_SCORE_COLUMN_POSITION_ORDER,
      );

      result.splice(position, 0, MATCH_COLUMN_DEFINITION);
    }

    return result;
  }, [gridColumns, subsection, section, callbacks, selectAllCheckbox, columnState]);

  return columnDefs;
};

import { ICellRendererParams } from 'ag-grid-community';
import { ContractRow } from 'api/endpoints';
import { useAppDispatch } from 'hooks/reducerHooks';
import { setSection as setClaimsSection } from 'reducer/claimsReducer';
import { setSection as setContractsSection } from 'reducer/contractsReducer';
import { useClaimToolQueryParams } from 'utils/hooks/useClaimToolQueryParams';
import { SectionType } from '../types';
import {
  ConterpartyLink,
  ConterpartyLinkWithIcon,
  ConterpartyNameWithIcon,
} from './CounterpartyNameCell';
import { useMemo } from 'react';

type CounterpartyNameCellProps = ICellRendererParams<ContractRow, any>;

export const ContractCounterpartyNameCell = (props: CounterpartyNameCellProps) => {
  const contract = props.data as ContractRow;
  const { buildCounterpartyPath } = useClaimToolQueryParams();
  const dispatch = useAppDispatch();

  const handleCounterpartyClick = () => {
    //todo: do we really need these two dispatch???
    dispatch(setClaimsSection(undefined));
    dispatch(setContractsSection(SectionType.Contracts));
  };

  const { hasMultipleCounterparties, hasMatchCode } = useMemo(
    () => ({
      hasMultipleCounterparties: contract.hasMultipleCounterparties,
      hasMatchCode: contract.matchCode,
    }),
    [contract.hasMultipleCounterparties, contract.matchCode],
  );

  if (hasMultipleCounterparties && hasMatchCode) {
    return (
      <ConterpartyLinkWithIcon
        text={contract.counterpartyName}
        to={buildCounterpartyPath(contract.matchCode)}
        onClick={handleCounterpartyClick}
      />
    );
  }

  if (hasMultipleCounterparties) {
    return <ConterpartyNameWithIcon text={contract.counterpartyName} />;
  }

  if (hasMatchCode) {
    return (
      <ConterpartyLink
        text={contract.counterpartyName}
        to={buildCounterpartyPath(contract.matchCode)}
        onClick={handleCounterpartyClick}
      />
    );
  }

  return contract.counterpartyName;
};

import React from 'react';
import './DataContentBox.css';
import { WarningMessage } from 'components/UIComponents/WarningMessage/WarningMessage';

export interface IDataUploaderProps {
  label: string;
  children: React.ReactNode;
  warning?: string;
  description?: string;
}

export const DataContentBox = ({
  label,
  children,
  warning,
  description,
}: IDataUploaderProps) => {
  return (
    <div className="datacontent-box">
      <div className="datacontent-box__header">
        {label}
        {!!warning && <WarningMessage warningMessage={warning} />}
        {!!description && (
          <div className="datacontent-box__description">{description}</div>
        )}
      </div>
      <div className="datacontent-box__content">{children}</div>
    </div>
  );
};

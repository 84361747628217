import isNil from 'lodash/isNil';

export const notEmpty = (value: any) => !!value;

export const defined = (value: any) => !isNil(value);

export function allValuesUndefined(value: object) {
  const values = Object.values(value);
  for (const v of values) {
    if (v !== undefined) {
      return false;
    }
  }

  return true;
}

/**
 * short version of "?" operator
 * @example
 * const result = condition ? 10 : undefined
 * const result = iif(condition, 10);
 * // result is 10 when someCondition === true, otherwise undefined
 */
export const iif = <T>(condition: any, value: T): T | undefined =>
  condition ? value : undefined;

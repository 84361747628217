import _ from 'lodash';
import { ComponentProps, memo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';

import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import './PdfDocumentPreview.css';

type DocumentProps = ComponentProps<typeof Document>;

interface Props {
  file: DocumentProps['file'];
  loading?: boolean;
  className?: string;
}

export const PdfDocumentPreview = memo(({ file, loading, className }: Props) => {
  const [pdfPageTotal, setPdfPageTotal] = useState(0);
  const handleDocumentLoadSuccess = (doc: DocumentCallback) =>
    setPdfPageTotal(doc.numPages);

  if (loading) return <DivWithLoader className="pdf-preview__loading" loading />;

  return (
    <Document
      className={className}
      file={file}
      loading={<PdfEmptyDocument />}
      noData={<NoFileSpecified />}
      onLoadSuccess={handleDocumentLoadSuccess}
    >
      {_.range(1, pdfPageTotal + 1).map((r) => (
        <Page key={r} pageNumber={r} width={800} />
      ))}
    </Document>
  );
});

const PdfEmptyDocument = () => <div className="pdf-preview__empty-document" />;

const NoFileSpecified = () => (
  <div className="pdf-preview__empty-document pdf-preview__nofile-specified">
    <h3>PDF file is empty</h3>
  </div>
);

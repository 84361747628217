import { PageMainPanel } from 'components/PageMainPanel';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { SettlementButtonsGroup } from '../../components/SettlementButtonsGroup';
import { useSettlementGridContext } from '../../SettlementGridContextProvider';
import { SettlementGrid } from './SettlementGrid/SettlementGrid';
import './SettlementTable.css';

export const SettlementTable = () => {
  const { isFilterApplied, gridResetFilters, gridShowColumns } =
    useSettlementGridContext();

  return (
    <PageMainPanel>
      <SettlementButtonsGroup
        onButtonClick={() => {}}
        additional={
          <div className="settlement-overview__buttons-group__additional">
            <ApButtonCasual
              disabled={!isFilterApplied}
              svgIcon="reset"
              onClick={gridResetFilters}
            >
              Reset filters
            </ApButtonCasual>
            <ApButtonCasual svgIcon="columns" onClick={gridShowColumns}>
              Columns
            </ApButtonCasual>
          </div>
        }
      />
      <SettlementGrid />
    </PageMainPanel>
  );
};

import { ApButtonMain, ApMenuItem } from '@alixpartners/ui-components';
import { ExcelExportParams } from 'ag-grid-community';
import {
  ClaimsMenuSectionTabs,
  SectionItem,
} from 'components/ClaimsMenuSectionTabs';
import { DropdownMenuMain } from 'components/DropdownMenu/DropdownMenuMain';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useAuditLogGetter } from 'pages/ClaimsContractsToolPage/hooks/useAuditLogGetter';
import { setActionNamesFetchingIdle } from 'reducer/actionNamesReducer';
import { setActionsSelectOptionsIdle } from 'reducer/actionsSelectOptions';
import { setContractLogsIdle } from 'reducer/contractLogsDataReducer';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  ExportExcelArgs,
  SectionType,
} from '../../pages/ClaimsContractsToolPage/types';
import './ClaimsToolHeader.css';
import { useSubheaderMenuNav } from 'hooks/useSubheaderMenuNav';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ApThreeWaySwitch, ApThreeWayValue } from 'components/ApThreeWayToggleSwitch';

export interface IClaimsToolHeaderProps {
  section: SectionType;
  onResetFilters?: () => unknown;
  onSectionClick: (section: SectionType) => unknown;
  isExporting?: boolean;
  onExport?:
    | ((params: ExcelExportParams) => void)
    | ((params: ExportExcelArgs) => void)
    | undefined;
  hasBackNavButton?: boolean;
}

export const ClaimsToolHeader = ({
  section,
  onResetFilters,
  onSectionClick,
  isExporting,
  onExport,
  hasBackNavButton,
}: IClaimsToolHeaderProps) => {
  const dispatch = useAppDispatch();
  const auditLog = useAuditLogGetter(section);

  const [predefinedFilter, setPredefinedFilter] = useQueryParam(
    'predefinedFilter',
    StringParam,
  );

  const handleSectionBtnClick = (section: SectionItem) => {
    if (section.resetFetchStatus) {
      dispatch(setActionNamesFetchingIdle());
      dispatch(setActionsSelectOptionsIdle());
      dispatch(setContractLogsIdle());
    }

    onResetFilters?.();
    onSectionClick(section.type);
    if (predefinedFilter) setPredefinedFilter(undefined);
  };

  const hasGridMenuOptions = [SectionType.Claims, SectionType.Contracts].includes(
    section,
  );
  const { handleBackClick } = useSubheaderMenuNav(section);
  // const showMatchingSwitch = [SectionType.MatchingExport, SectionType.Matching].includes(section);
  const showMatchingSwitch = false;

  return (
    <div className='claims-tool-header__container'>
      <ClaimsMenuSectionTabs
        selected={section}
        additional={
          hasGridMenuOptions ? (
            <ExportContainer
              isExporting={isExporting}
              section={section}
              onExport={onExport}
              onAuditLogs={auditLog.download}
            />
          ) : hasBackNavButton ? (
            <ApButtonMain onClick={handleBackClick}>
              <SvgIcon
                iconName="arrow_right"
                width={12}
                height={12}
                className="claims-goback-button__icon"
              />{' '}
              Back
            </ApButtonMain>
          ) : undefined
        }
        onSectionClick={handleSectionBtnClick}
      />
      {showMatchingSwitch &&
        <MatchingSwitch
          onSectionClick={onSectionClick}
          section={section}
        />
      }
    </div>
  );
};

interface MatchingSwitchProps { 
  onSectionClick: (section: SectionType) => unknown;
  section: SectionType;
};

const MatchingSwitch = ({ onSectionClick, section } : MatchingSwitchProps) => {
  const isChecked = section === SectionType.Matching;

  const handleChange= () => {
    onSectionClick(isChecked ? SectionType.MatchingExport : SectionType.Matching)
  }

  return (
    <div className="claims-tool-header__switch">
      <ApThreeWaySwitch
        id="claims-matching-switch"
        value={isChecked ? ApThreeWayValue.Yes : ApThreeWayValue.No}
        onChange={handleChange}
      />
      <p>Switch to table view</p>
    </div>
  )
}

const ExportContainer = (props: {
  isExporting?: boolean;
  section: SectionType;
  onExport?:
    | ((params: ExcelExportParams) => void)
    | ((params: ExportExcelArgs) => void)
    | undefined;
  onAuditLogs?: () => void;
}) => {
  const { isExporting, section, onExport, onAuditLogs } = props;

  if (isExporting) return null;
  return (
    <div className="claims-tool-header__menu">
      <DropdownMenuMain
        label="Export"
        iconName="save_alt"
        thinShape
        disableCloseOnSubmenuClick={isExporting}
        className={'contracts-tool-subheader__export-menu'}
        buttonClassname={'subheader__export-button'}
      >
        <ApMenuItem onClick={() => onExport?.({})}>
          Export {section} table
        </ApMenuItem>
        <ApMenuItem onClick={() => onExport?.({ allColumns: true })}>
          Export All {section}
        </ApMenuItem>
        <ApMenuItem onClick={onAuditLogs}>Export Audit Log</ApMenuItem>
      </DropdownMenuMain>
    </div>
  );
};

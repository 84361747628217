import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useState } from 'react';
import { actionStatusSet } from 'reducer/claimActionNameSelectionReducer';
import { emptyItem, IBatchItem } from '../claimsBatchEdit.type';

export const useBatchEditFields = (resetFilters: (() => unknown) | undefined) => {
  const [items, setItems] = useState<IBatchItem[]>([emptyItem()]);
  const dispatch = useAppDispatch();

  const add = useCallback(() => {
    setItems((r) => [...r, emptyItem()]);
  }, []);

  const change = useCallback(
    (item: IBatchItem) => {
      const newValues = items.map((r) => (r.id === item.id ? item : r));
      setItems(newValues);
      if (item.propName === 'actionStatus' && item.value) {
        dispatch(actionStatusSet(item.value as string));
      }
    },
    [items, dispatch],
  );

  const remove = useCallback(
    (item: IBatchItem) =>
      setItems((oldItems) => {
        const result = oldItems.filter((r) => r.id !== item.id);
        if (!result.length) resetFilters?.();
        return result.length > 0 ? result : [emptyItem()];
      }),
    [resetFilters],
  );

  const clearAll = useCallback(() => {
    setItems([emptyItem()]);
  }, []);

  return { items, add, change, remove, clearAll };
};

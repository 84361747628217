import { FileStatus, FileType } from 'types/dataUploaderTypes';
import { del, downloadFile, get, post } from '../apiRequest';

export interface FileInfoRequest {
  fileType: FileType;
  fileState: FileStatus;
  errorCount: number | null;
  name: string;
  displayName: string;
  isOverride: boolean;
  schedulePart?: string;
}
export interface FileInfoItemResponse extends FileInfoRequest {
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}

export interface IErrorsSummaryResponse {
  fileName: string;
  hasError: true;
  errorMessages: string[];
  errorCount: number;

  // ==== not used ====
  // activityLog: string;
  // fileState: string;
  // relevantFiles: string[];
  // summary: string[];
  // uploadedBy: string;
  // uploadedDate: string;
}

export interface FileInfoResponse extends Array<FileInfoItemResponse> {}

export interface FileInfoTemplatesResponse extends Array<string> {}

export const fileInfo = {
  getFileInfo: () => get<FileInfoResponse>('v1/FileInfo'),
  deleteFile: (fileType: FileType, id: number) =>
    del(`v1/FileInfo/${fileType}/${id}`),
  getTemplates: () => get<FileInfoTemplatesResponse>('v1/FileInfo/templates'),

  downloadTemplate: (fileName: string) =>
    downloadFile(`v1/FileInfo/templates/${fileName}`, fileName),
  downloadFile: (fileType: FileType, id: number, fileName: string) =>
    downloadFile(`v1/FileInfo/download/${fileType}/${id}`, fileName),

  postFileInfo: (fileInfoRequest: FileInfoRequest) =>
    post('v1/FileInfo', fileInfoRequest),

  getErrorsSummary: (fileType: FileType) =>
    get<IErrorsSummaryResponse[]>(`v1/FileInfo/errors/type/${fileType}/summary`),
  downloadErrors: (fileId: number, fileName: string) =>
    downloadFile(`v1/FileInfo/download-errors/${fileId}`, fileName),
};

import { ApLoaderDefault } from '@alixpartners/ui-components';

export const InitialLoadingPage = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90vh',
    }}
  >
    <ApLoaderDefault />
  </div>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from 'types/fetch-status.types';

type State = {
  users: UsersLogsData[];
  status: FetchStatus;
};

export type UsersLogsData = {
  id: number;
  name: string;
  email: string;
  role: string;
  date: string;
};

const initialState: State = {
  users: [],
  status: FetchStatus.Idle,
};
const userLogsReducer = createSlice({
  name: 'userLogs',
  initialState,
  reducers: {
    setFetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    setFetchingDone: (state, action: PayloadAction<UsersLogsData[]>) => {
      state.status = FetchStatus.Done;
      state.users = action.payload;
    },
  },
});

export const { setFetching, setFetchingDone } = userLogsReducer.actions;
export default userLogsReducer.reducer;

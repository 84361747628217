import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Exhibit } from 'api/endpoints/exhibits';

export const initialState: ExhibitsReducerState = {
  loading: true,
  data: [],
};

export interface ExhibitsReducerState {
  data: Exhibit[];
  error?: string;
  loading: boolean;
}

const exhibitsSlice = createSlice({
  name: 'exhibits',
  initialState,
  reducers: {
    exhibitsFetch(state) {
      state.loading = true;
    },
    exhibitsLoaded(state, action: PayloadAction<Exhibit[]>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { exhibitsFetch, exhibitsLoaded } = exhibitsSlice.actions;
export default exhibitsSlice.reducer;

import { acquireToken } from '@alixpartners/ui-utils';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

const { REACT_APP_API_URL } = process.env;
const uri = `${REACT_APP_API_URL}v1/graphql`;

export type GraphQlApolloClient = ApolloClient<NormalizedCacheObject>;

export const createApolloClient = async () => {
  const token = await acquireToken();

  const headers = {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const gql = new ApolloClient<NormalizedCacheObject>({
    uri,
    cache: new InMemoryCache(),
    headers,
  });

  return gql;
};

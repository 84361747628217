import classnames from 'classnames';
import { useMemo } from 'react';
import './PaginationBar.css';

const DOTS = '...';

interface Props {
  className?: string;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

export const PaginationBar = ({
  className,
  totalCount,
  currentPage,
  pageSize,
  siblingCount = 3,
  onPageChange,
}: Props) => {
  const pageRange = useMemo(() => {
    const params = { currentPage, totalCount, siblingCount, pageSize };
    return getPaginationRange(params);
  }, [totalCount, pageSize, siblingCount, currentPage]);

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);

  let lastPage = pageRange[pageRange.length - 1];
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  return (
    <ul className={classnames('pagination-bar__container', className)}>
      <PageButton disabled={isFirstPage} onClick={onPrevious}>
        <div className="pagination-bar__left" />
      </PageButton>

      {pageRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <PageButton key={index}>&#8230;</PageButton>;
        }

        return (
          <PageButton
            key={pageNumber}
            selected={currentPage === pageNumber}
            onClick={() => onPageChange(pageNumber as number)}
          >
            {pageNumber}
          </PageButton>
        );
      })}

      <PageButton disabled={isLastPage} onClick={onNext}>
        <div className="pagination-bar__right" />
      </PageButton>
    </ul>
  );
};

const PageButton = (props: {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}) => {
  const { disabled, selected, className, children, onClick } = props;
  return (
    <li
      className={classnames('pagination-bar__item', {
        'pagination-bar__disabled': disabled,
        'pagination-bar__button': !disabled && onClick,
        'pagination-bar__selected': selected,
        className,
      })}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </li>
  );
};

//------------------- HELPER FUNCTIONS -------------------//

const getPaginationRange = (props: {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}): (string | number)[] => {
  const { totalCount, pageSize, siblingCount = 1, currentPage } = props;

  const totalPageCount = Math.ceil(totalCount / pageSize);

  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5;

  /*
    If the number of pages is less than the page numbers we want to show in our
    paginationComponent, we return the range [1..totalPageCount]
 */
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

  /*
    We do not want to show dots if there is only one position left
    after/before the left/right page count as that would lead to a change if our Pagination
    component size which we do not want
  */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * siblingCount;
    let leftRange = range(1, leftItemCount);

    return [...leftRange, DOTS, totalPageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * siblingCount;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
    return [firstPageIndex, DOTS, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }

  return range(1, totalPageCount);
};

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

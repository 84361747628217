import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimsDetailedData } from 'pages/Dashboard/types';

type StateBase = {
  data: ClaimsDetailedData[];
  fetching: boolean;
};

const initialState: StateBase = {
  data: [],
  fetching: false,
};
const claimsDashboardDetailedDataReducer = createSlice({
  name: 'claimsDashboardDetailedData',
  initialState,
  reducers: {
    fetchingData: (state: StateBase) => {
      state.fetching = true;
    },
    fetchingDataDone: (
      state: StateBase,
      action: PayloadAction<ClaimsDetailedData[]>,
    ) => {
      state.fetching = false;
      state.data = [...action.payload];
    },
  },
});

export const { fetchingData, fetchingDataDone } =
  claimsDashboardDetailedDataReducer.actions;
export default claimsDashboardDetailedDataReducer.reducer;

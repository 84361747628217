import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ContractPaymentPositionReducerState = {
  loading: true,
  data: [{ id: 0, paymentPositionName: 'None' }],
};

export type ContractPaymentPositionsData = {
  id: number;
  paymentPositionName: string;
};

export type ContractPaymentPositionReducerState = {
  data: Array<ContractPaymentPositionsData>;
  error?: string;
  loading: boolean;
};

const contractPaymentPositionSlice = createSlice({
  name: 'contractPaymentPositions',
  initialState,
  reducers: {
    contractPaymentPositionsFetch(state) {
      state.loading = true;
    },
    contractPaymentPositionsLoaded(
      state,
      action: PayloadAction<ContractPaymentPositionsData[]>,
    ) {
      state.loading = false;
      state.data = [...action.payload];
    },
  },
});

export const { contractPaymentPositionsFetch, contractPaymentPositionsLoaded } =
  contractPaymentPositionSlice.actions;
export default contractPaymentPositionSlice.reducer;

import './DashboardClients.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApLoaderDefault } from '@alixpartners/ui-components';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { DashboardContextProvider } from 'pages/Dashboard/context/DashboardContextProvider';
import { FetchStatus } from 'types/fetch-status.types';
import { DashboardTasks } from '../../components/DashboardTask/DasboardTasks';
import groupBy from 'lodash/groupBy';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import classNames from 'classnames';
import { ContractsDashboardCategory, TaskClick } from 'pages/Dashboard/types';
import { fetchContractsDetailedDataThunk } from 'reducer/thunks/dashboard-thunks';
import { DashboardDetailedView } from '../DashboardDetailedView/DashboardDetailedView';
import { userDashboardTasksActions } from 'reducer/userDashboardTasksReducer';

export const DashboardClients = () => {
  const [contractActiveSection, setContractActiveSection] =
    useState<ContractsDashboardCategory>();
  const [activeSectionType, setSectionType] = useState<SectionType>();

  const detailedViewRef = useRef<null | HTMLDivElement>(null);

  const { clientData, status, showMine } = useAppSelector(
    (s) => s.userDashboardTasks.slice,
  );
  const { shouldFetchData, data: contractDetailedData } = useAppSelector(
    (s) => s.contractsDashboardDetailedData,
  );

  const dispatch = useAppDispatch();

  const data = groupBy(clientData, (c) => c.sectionType);

  const { claimsData, contractsData, showClaimsData, showContractsData } = useMemo(
    () => ({
      claimsData: data[SectionType.Claims] ?? [],
      contractsData: data[SectionType.Contracts] ?? [],
      showClaimsData: data[SectionType.Claims]?.some((claim) => !!claim.count),
      showContractsData: data[SectionType.Contracts]?.some(
        (contract) => !!contract.count,
      ),
    }),
    [data],
  );

  const showDetailedView = useMemo(
    () => !!(contractActiveSection && !!contractDetailedData?.length),
    [contractActiveSection, contractDetailedData],
  );

  const handleTaskClick: TaskClick = ({
    section,
    isTask,
    isBackToTop,
    sectionType,
  }) => {
    /*
    this handler is used by both contract dashboard tasks - scroll into view of selected task and fetch data
    and nav buttons from contract data detailed view - change detailed view section
    */
    if (isTask && sectionType === SectionType.Contracts) {
      if (shouldFetchData) {
        dispatch(fetchContractsDetailedDataThunk(true));
      }
    } else if (isBackToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setSectionType(undefined);
      setContractActiveSection(undefined);

      document.body.style.overflow = 'auto';
    }
    setSectionType(sectionType);
    if (sectionType === SectionType.Contracts)
      setContractActiveSection(section as ContractsDashboardCategory);
  };

  useEffect(() => {
    if (detailedViewRef?.current && showDetailedView) {
      detailedViewRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      document.body.style.overflow = 'hidden';
    }
  }, [showDetailedView]);

  useEffect(() => {
    dispatch(userDashboardTasksActions.setShowMineToggle(true));
  }, [dispatch]);

  return (
    <DashboardContextProvider
      handleTaskClick={handleTaskClick}
      contractActiveSection={contractActiveSection}
      contractDetailedData={contractDetailedData}
    >
      <section className="dashboard-clients">
        {status === FetchStatus.Fetching && !clientData.length ? (
          <ApLoaderDefault loaderSize="lg" />
        ) : (
          <>
            {showClaimsData && (
              <div
                className={classNames('claims-tasks', {
                  fullWidth: !showContractsData,
                })}
              >
                <DashboardTasks tasks={claimsData} showMine={showMine} />
              </div>
            )}
            {showContractsData && (
              <div
                className={classNames('contracts-tasks', {
                  fullWidth: !showClaimsData,
                })}
              >
                <DashboardTasks tasks={contractsData} showMine={showMine} />
              </div>
            )}
          </>
        )}
      </section>
      {activeSectionType && showDetailedView ? (
        <section className="dashboard__detailed-view" ref={detailedViewRef}>
          <DashboardDetailedView sectionType={activeSectionType} />
        </section>
      ) : (
        <></>
      )}
    </DashboardContextProvider>
  );
};

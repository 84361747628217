import {
  ApButtonGroup,
  ApButtonIcon,
  ApButtonMain,
} from '@alixpartners/ui-components';
import { formatDate } from 'utils/formatDate';
import { BookmarkCardAction, BookmarkCardData } from '../../types';

export const BookmarksCardContent = ({
  handleOpenBookmark,
  handleCardAction,
  hovered,
  bookmark,
}: {
  handleOpenBookmark: () => void;
  handleCardAction: (type: BookmarkCardAction) => void;
  hovered: boolean;
  bookmark: Omit<BookmarkCardData, 'filter'>;
}) => {
  if (hovered) {
    return (
      <div className="buttons-wrapper anim-appear">
        <ApButtonMain
          iconName="outlined_bookmark_border"
          onClick={handleOpenBookmark}
        >
          Open bookmark
        </ApButtonMain>
        <ApButtonGroup>
          <ApButtonIcon
            iconName="delete_outline"
            iconProps={{ iconSize: 30 }}
            onClick={() => handleCardAction('delete')}
          />
          <ApButtonIcon
            iconName="edit"
            iconProps={{ iconSize: 30 }}
            onClick={() => handleCardAction('edit')}
          />
        </ApButtonGroup>
      </div>
    );
  }

  const { section, createdBy, title, createdDate } = bookmark;
  return (
    <div className="text-wrapper anim-appear">
      <h3 className="bookmarks-page__card-title">{section}</h3>
      <p>{formatDate(createdDate)}</p>
      <div className="summary">
        <h3 title={title}>{title}</h3>
        <p>{createdBy}</p>
      </div>
    </div>
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { SingleSettlementResponse } from 'api/endpoints/settlements';
import { apiSettlementSingle } from 'pages/ClaimsSettlementsPage/sections/SettlementSingleView/api/apiSettlementSingle';
import { useState } from 'react';
import './SettlementAgreementCell.css';

type Props = ICellRendererParams<SingleSettlementResponse, string>;

export const SettlementAgreementCell = ({ value, data }: Props) => {
  const [loading, setLoading] = useState(false);
  const documentReady = !!data?.agreementFileName;

  const handleClick = async () => {
    const { id, agreementFileName } = data ?? {};

    setLoading(true);
    await apiSettlementSingle.agreemantDownloadFile(id, agreementFileName);
    setLoading(false);
  };

  if (!documentReady) {
    return <div className="settlement-agreement-cell__disabled">N/A</div>;
  }

  if (loading) {
    return <div className="settlement-agreement-cell__disabled">Loading...</div>;
  }

  return (
    <div onClick={handleClick} className="settlement-agreement-cell">
      Download Agreement
    </div>
  );
};

import { PdfDocumentPreview } from 'components/PdfDocumentPreview';
import { ApButtonCasual } from 'components/UIComponents/Buttons';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useRef, useState } from 'react';
import './SettlementsAgreemantPreview.css';

interface Props {
  fileName: string | undefined | null;
  fileBlob: Blob | undefined;
  loading?: boolean;
  onPreviewOpen: () => Promise<any>;
  onDownload: () => Promise<void>;
}

export const SettlementsAgreemantPreview = ({
  fileName,
  fileBlob,
  loading,
  onPreviewOpen,
  onDownload,
}: Props) => {
  const pdfPreviewRef = useRef<HTMLDivElement>(null);
  const [preview, setPreview] = useState(false);

  const scrollToThePdfPreview = () => {
    pdfPreviewRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const hadleShowPreviewClick = () => {
    const newPreview = !preview;
    setPreview(newPreview);

    if (!newPreview) return;

    onPreviewOpen();
    setTimeout(scrollToThePdfPreview, 100);
  };

  const previewLabel = preview ? 'Collapse Preview ⏶' : 'Open Preview ⏷';
  return (
    <DivWithLoader
      id="claims-settlement-preview"
      ref={pdfPreviewRef}
      className="claims-settlement-preview"
    >
      <div className="claims-settlement-preview__nav">
        <div className="claims-settlement-preview__name">{fileName}</div>
        <div className="claims-settlement-preview__nav-space" />
        <ApButtonCasual onClick={onDownload}>Download Agreement</ApButtonCasual>
        <ApButtonCasual onClick={hadleShowPreviewClick}>
          {previewLabel}
        </ApButtonCasual>
      </div>
      {preview && (
        <div className="claims-settlement-preview-preview thin-scrollbar">
          <PdfDocumentPreview file={fileBlob} loading={loading} />
        </div>
      )}
    </DivWithLoader>
  );
};

import React from 'react';
import { ApButtonMain, ApIcon } from '@alixpartners/ui-components';
import './AuthnErrorPage.css';

interface IProps {
  errorType: string;
  errorDescription: string;
  onClick: (event: any) => void;
}

export const AuthnErrorPage: React.FC<IProps> = ({
  errorType,
  errorDescription,
  onClick,
}) => (
  <div className="authn-error">
    <div className="authn-error__title">
      <div>
        <ApIcon iconName="outline_warning" iconColor="black" />
      </div>
      <div className="authn-error__title-text">Sorry, something went wrong.</div>
    </div>
    <div className="authn-error__body">
      <div className="authn-error__body-errortype">{errorType}</div>
      {errorDescription && (
        <div className="error-boundary__body-text">{errorDescription}</div>
      )}
    </div>
    <div className="authn-error__button">
      <ApButtonMain onClick={onClick}>Try again</ApButtonMain>
    </div>
  </div>
);

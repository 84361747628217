import { userDashboardTasks } from 'api/endpoints/dashboard-tasks';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';

import { error } from 'utils/alert';
import { downloadExcelFile } from 'utils/file';
import { getCurrentDate, getIsoDate } from 'utils/formatDate';
import { ContractItemsData, ContractsDetailedData } from '../types';
import {
  ContractData,
  ContractRowSection,
  contractTableWorkbook,
} from './contractTableWorkbook';
import { CellValue } from './excelUtils';

export const exportContractTable = async (showMine?: boolean) => {
  const response = await userDashboardTasks.getDetailedContractData(showMine);
  if (response.type !== ResponseType.OK) {
    error('Could not fetch contracts data');
    return;
  }

  const dataToExport = mapDataToExport(response.data);

  const excelWorkbook = await contractTableWorkbook(dataToExport);

  const fileName = 'Contracts - Dashboard Export';
  const currentIsoDate = getIsoDate(getCurrentDate());
  await downloadExcelFile(excelWorkbook, `${fileName} ${currentIsoDate}.xlsx`);
};

export const mapDataToExport = (data: ContractsDetailedData[]): ContractData => {
  const workflowTable = data.find((r) => r.id === 'awaitingUnderReview');
  const workflowItems = workflowTable?.items.filter((r) =>
    ['Under Review', 'Awaiting Review'].includes(r.label),
  );
  const workflow = workflowItems?.map(mapWorkflowValues) ?? [];

  const resolutionItems = data.filter((r) =>
    ['assume', 'reject', 'terminate', 'nonExecutory'].includes(r.id!),
  );

  const resolution = resolutionItems.map(mapResolutionValues);

  return {
    workflow,
    resolution,
  };
};

const mapWorkflowValues = (item: ContractItemsData): ContractRowSection => {
  const name = item.label;
  const children = item.items ?? [item];
  const values = children.map(
    (r) => [r.label, r.count, toPercentage(r.percentage)] as CellValue[],
  );

  return { name, values };
};

const mapResolutionValues = (item: ContractsDetailedData): ContractRowSection => {
  const name = item.label;
  const children = item.items?.flatMap((r) => r.items ?? [r]);

  const values = children.map(
    (r) =>
      [
        r.label,
        r.count,
        toPercentage(r.percentage),
        r.low,
        r.high,
        r.negotiatedCureAmount,
      ] as CellValue[],
  );

  return { name, values };
};

const toPercentage = (value: string | number | undefined): number => {
  return value ? +value / 100 : 0;
};

import '../Categories.css';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { useContractsCategories } from './useContractsCategories';
import { AddFields, EntityTable, Panel } from 'components/UIComponents/EntityTable';
import {
  AwarePanel,
  EditFieldsList,
} from 'components/UIComponents/EntityTable/EntityTable.components';
import { ContractCategoriesInput } from './ContractsCategoriesInput';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ApLoaderDefault } from '@alixpartners/ui-components';
import { AllContractsCategories } from 'api/endpoints';

const HEADERS = ['Contract category name', 'Contract type'];
const EDIT_HEADERS = [
  'Contract category',
  'Contract type',
  'Category type',
  'Enabled/Disabled',
];

const ADD_COLUMN_KEYS: (keyof AllContractsCategories)[] = [
  'contractCategoryId',
  'contractTypeName',
];
const EDIT_COLUMN_KEYS: (keyof AllContractsCategories)[] = [
  'categoryName',
  'contractTypeName',
  'categoryType',
  'enabled',
];

export const ContractsCategories = () => {
  const {
    options,
    onAddSave,
    allCategories,
    warningMessage,
    onEditSave,
    handleToggleAll,
    toggleAll,
    fetching,
    sortState,
    handleSort,
    sortIcon,
    exportEnabledCategories,
  } = useContractsCategories();

  return (
    <SettingsContentPanel>
      <section className="categories">
        <EntityTable
          items={allCategories}
          addColumnKeys={ADD_COLUMN_KEYS}
          editColumnKeys={EDIT_COLUMN_KEYS}
          inputComponent={ContractCategoriesInput}
          onEditSave={onEditSave}
          onAddSave={onAddSave}
          section={SectionType.Contracts}
          options={options}
          toggleAll={toggleAll}
          handleToggleAll={handleToggleAll}
          sortState={sortState}
          handleSort={handleSort}
          sortIcon={sortIcon}
          handleExportItems={exportEnabledCategories}
        >
          <Panel title="Add Contracts categories" className="add-category">
            <AddFields headers={HEADERS} />
          </Panel>
          <AwarePanel title="Contracts Categories" className="edit-category">
            {fetching ? (
              <ApLoaderDefault loaderSize="lg" />
            ) : (
              <EditFieldsList
                headers={EDIT_HEADERS}
                warningMessage={warningMessage}
              />
            )}
          </AwarePanel>
        </EntityTable>
      </section>
    </SettingsContentPanel>
  );
};

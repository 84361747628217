import { IBatchEditableColumns } from '../contractsBatchEdit.type';

export const getSelectOptions = (columns: IBatchEditableColumns[]) => {
  const emptyItem = { label: '', value: '' };
  const optionsList = columns.map((r) => ({
    label: r.displayName,
    value: r.propertyName,
    disabled: r.disabled,
  }));

  return [emptyItem, ...optionsList];
};

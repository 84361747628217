import { SectionType } from "pages/ClaimsContractsToolPage/types";
import { SectionItem } from "./ClaimsMenuSectionTabs";
import { RoleType } from "utils/AppInitializer/application-user";

const CLIENT_SECTIONS: SectionItem[] = [
  {
    type: SectionType.Dashboard,
    label: 'Dashboard',
  },
  {
    type: SectionType.Claims,
    label: 'Claims',
    resetFetchStatus: true,
  },
  {
    type: SectionType.Contracts,
    label: 'Contracts',
    resetFetchStatus: true,
  }
];

const AP_SECTIONS: SectionItem[] = [
  ...CLIENT_SECTIONS,
  {
    type: SectionType.MatchingHomepage,
    label: 'Matching',
    subsections: [SectionType.MatchingExport, SectionType.Matching]
  },
  {
    type: SectionType.ExhibitsExport,
    label: 'Exhibits',
  },
  {
    type: SectionType.Documents,
    label: 'Documents',
  },
  {
    type: SectionType.Reports,
    label: 'Reports',
  },
];

const ROLE_TYPE_MAP: {[key in RoleType]: SectionItem[]} = {
  AP: AP_SECTIONS,
  Client: CLIENT_SECTIONS,
}

export const getSections = (roleType: RoleType) => ROLE_TYPE_MAP[roleType];
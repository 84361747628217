import { AgGridReact } from 'ag-grid-react';
import {
  ContractActionSubstatus,
  ContractActionType,
} from 'api/endpoints/actions-select-options';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useState } from 'react';
import { colProp } from '../contractsBatchEdit.const';
import { emptyItem, IBatchItem } from '../contractsBatchEdit.type';
import { setSelectActionSubStatus } from '../utils/setSelectValues';
import { contractActionTypeIdSet } from 'reducer/contractActionNameReducer';
import { Filter, setFilterTable } from '../utils/setFilterTable';
import { ContractTypeItem } from 'api/endpoints';

const itemsToSetFilterFor: string[] = [
  colProp.contractActionTypeId,
  colProp.contractActionSubStatusId,
  colProp.paymentPositionId,
];

export const useBatchEditFields = ({
  gridRef,
  resetFilters,
  contractData: { actionTypes = [], allActionSubstatuses = [], contractTypes = [] },
}: {
  gridRef: React.RefObject<AgGridReact<any>> | undefined;
  resetFilters: (() => unknown) | undefined;
  contractData: {
    actionTypes?: ContractActionType[];
    allActionSubstatuses?: ContractActionSubstatus[];
    contractTypes?: ContractTypeItem[];
  };
}) => {
  const [items, setItems] = useState<IBatchItem[]>([emptyItem()]);
  const [cascadingItems, setCascadingItems] = useState<IBatchItem[]>([emptyItem()]);
  const dispatch = useAppDispatch();

  const add = useCallback(() => {
    setItems((r) => [...r, emptyItem()]);
  }, []);

  const change = useCallback(
    (item: IBatchItem) => {
      const newValues = items.map((r) => {
        if (item.propName === colProp.contractActionTypeId) {
          dispatch(contractActionTypeIdSet({ value: Number(item.value) }));
          setSelectActionSubStatus({
            items,
            actionTypes,
            actionTypeId: Number(item.value),
            allActionSubstatuses,
          });
        }

        if (itemsToSetFilterFor.includes(item.propName)) {
          const filter: Filter = {
            name: item.propName,
            value: item.value !== '' ? Number(item.value) : undefined,
            listToFilter: actionTypes,
          };
          setFilterTable(gridRef, filter);
        }

        return r.id === item.id ? item : r;
      });
      setItems(newValues);
    },
    [items, actionTypes, dispatch, gridRef, allActionSubstatuses],
  );

  const addCascadingItem = useCallback(
    (item: IBatchItem) => setCascadingItems((prev) => [...prev, item]),
    [],
  );

  const remove = useCallback(
    (item: IBatchItem) =>
      setItems((oldItems) => {
        const result = oldItems.filter((r) => {
          if (item.propName === colProp.contractActionTypeId) {
            setSelectActionSubStatus({
              items,
              actionTypes: undefined,
              actionTypeId: undefined,
              allActionSubstatuses,
            });
          }

          return r.id !== item.id;
        });
        if (!result.length) resetFilters?.();
        return result.length > 0 ? result : [emptyItem()];
      }),
    [items, allActionSubstatuses, resetFilters],
  );

  const clearAll = useCallback(() => {
    setItems([emptyItem()]);
  }, []);

  return { items, add, change, remove, clearAll, addCascadingItem, cascadingItems };
};

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import 'components/UIComponents/simple-table.css';
import 'components/UIComponents/css-animations.css';
import { UseSubmitHandler, useSubmitHandler } from 'utils/form/form-utils';
import { EntityFilterRowProps, EntityRowState as Ers } from './EntityTable.types';
import { useEntityContext } from './EntityContext';
import './EntityTable.css';

export const EntityFilterRow = (
  props: EntityFilterRowProps & { className?: string },
) => {
  const { className } = props;
  const {
    onFilter,
    filterColumnKeys,
    filterComponent: FilterComp,
  } = useEntityContext();
  const [expanded, setExpanded] = useState<Ers>(Ers.Initial);

  const inputsDisabled = expanded === Ers.Saving;

  const handleFilter: UseSubmitHandler = useCallback(
    (_, { validState }, formValues) => {
      if (validState === 'invalid') return;
      setExpanded(Ers.Saving);
      onFilter(formValues).finally(() => {
        setExpanded(Ers.Initial);
      });
    },
    [onFilter],
  );

  const handleChange: React.FormEventHandler<HTMLFieldSetElement> = useCallback(
    (evt) => {
      const target = evt.target as HTMLElement & { form: HTMLFormElement | null };
      target.form?.requestSubmit();
    },
    [],
  );

  const { handleSubmit, validState } = useSubmitHandler(handleFilter);

  const cn = classNames(
    'dynamicfields__row-el entity entity-table__filter-row utils-form',
    className,
    {
      'utils-form-invalid': validState === 'invalid',
      'utils-obstruct': expanded === Ers.Saving,
    },
  );

  return (
    <form
      action="#"
      autoComplete="off"
      noValidate
      className={cn}
      onSubmit={handleSubmit}
    >
      <fieldset className="utils-no-appearance" onChange={handleChange}>
        {filterColumnKeys.map((ck, i) => {
          const isLast = i === filterColumnKeys.length - 1 && i !== 0;
          return (
            <div
              key={ck}
              className={classNames(
                'dynamicfields__cell dynamicfields__cell-filter',
                { 'edit-table__cell-last': isLast },
              )}
            >
              <FilterComp
                filterKey={ck}
                fromTable={{
                  disabled: inputsDisabled,
                  className: 'dynamicfields__cell__body',
                }}
              />
            </div>
          );
        })}
      </fieldset>
    </form>
  );
};

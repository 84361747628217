import { gql } from '@apollo/client';
import { ColumnBase } from 'utils/agGrid/agGridColumn';
import { PAGINATION_COUNT } from './const';
import { AgGridFilterModel } from './types';
import {
  agGridFilterModelToWhereStatement,
  createGraphQLOrder,
  createVariablesObject,
  createVaribalesArguments,
  FilterRequest,
} from './utils/hotchocolateUtils';
import { injectSearchQuery } from './utils/injectSearchQuery';
import { WhereQuery } from './utils/types';
import {
  getFilterWithUnmatched,
  MatchingOptions,
} from './utils/unmatchedClaimsFilterModel';

export interface QueryGridOptions {
  queryName: string;
  graphqlEntity: string;
  booleanColumns: string[];
  showMine?: boolean;
  applicationUserId?: number;
  gridColumns: ColumnBase[];
  ignoreId?: boolean;
  searchText?: string;
}

const requestColumnsField = 'items';
const totalCountField = 'totalCount';

export const createGraphQLQueryFromParams = (
  request: FilterRequest,
  requestColumns: string[],
  options: QueryGridOptions,
  matching?: MatchingOptions,
) => {
  const { sortModel = [], filterModel = {}, startRow, endRow } = request;

  const skip = startRow ?? 0;
  const take = endRow ? endRow - skip : PAGINATION_COUNT;

  const order = createGraphQLOrder(sortModel, matching?.unmatchedClaims);
  const filterWithMatching = getFilterWithUnmatched(filterModel, matching);

  const graphql = createGraphQLQuery(
    filterWithMatching,
    skip,
    take,
    order,
    requestColumns,
    options,
  );

  return {
    query: gql(graphql.query),
    variables: graphql.variables,
  };
};

export const createGraphQLQuery = (
  filterModel: AgGridFilterModel,
  skip: number,
  take: number,
  order: Record<string, 'DESC' | 'ASC'>,
  requestColumns: string[],
  options: QueryGridOptions,
) => {
  const {
    queryName,
    graphqlEntity,
    booleanColumns,
    showMine,
    applicationUserId,
    gridColumns,
    ignoreId,
    searchText = '',
  } = options;

  let whereQuery = agGridFilterModelToWhereStatement(filterModel, booleanColumns);

  whereQuery = injectSearchQuery(
    whereQuery,
    searchText,
    requestColumns,
    gridColumns,
  );

  whereQuery = injectApplicationUserId(whereQuery, showMine, applicationUserId);

  const orderString = JSON.stringify(order).replaceAll(`"`, '');
  const whereString = JSON.stringify(whereQuery.query).replaceAll(`"`, '');

  const queryVariablesArguments = createVaribalesArguments(
    whereQuery.variables,
    gridColumns,
  );

  const query = `
      query ${queryName}${queryVariablesArguments} {
        ${graphqlEntity}(
          skip: ${skip},
          take: ${take},
          ${whereQuery.additionalFilter ?? ''}
          where: ${whereString},
          order: ${orderString}
        ) 
        {
          ${totalCountField}
          ${requestColumnsField} {
            ${ignoreId ? '' : 'id'}
            ${requestColumns?.join(' ') ?? ''}
           }
        }
      }
    `;

  const variables = createVariablesObject(whereQuery.variables);
  if (showMine && applicationUserId) {
    variables.applicationUserId = applicationUserId;
  }

  return { query, variables };
};

const injectApplicationUserId = (
  whereQuery: WhereQuery,
  showMine: boolean | undefined,
  applicationUserId: number | undefined,
): WhereQuery => {
  if (!showMine || !applicationUserId) {
    return whereQuery;
  }

  const additionalFilter = `applicationUserId: $var${whereQuery.variables.length},`;

  return {
    ...whereQuery,
    variables: [
      ...whereQuery.variables,
      { key: 'applicationUserId', value: applicationUserId },
    ],
    additionalFilter,
  };
};

import { useMemo } from 'react';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';

export const useAppUserType = () => {
  const { data } = useApplicationUser();

  return useMemo(
    () => ({
      isApUser: data?.roleType === 'AP',
      isClient: data?.roleType === 'Client',
      userName: data?.unique_name,
    }),
    [data?.roleType, data?.unique_name],
  );
};

import { useEffect } from 'react';
import { fetchAllUsersThunk } from 'reducer/thunks/allUsers-thunk';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppDispatch, useAppSelector } from './reducerHooks';

export function useAllUsers() {
  const slice = useAppSelector((s) => s.allUsers);
  const dispatch = useAppDispatch();
  const { status } = slice;

  useEffect(() => {
    if (status === FetchStatus.Idle) {
      dispatch(fetchAllUsersThunk());
    }
  }, [dispatch, status]);

  return slice;
}

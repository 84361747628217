import { api } from 'api';
import { LinkText } from 'components/UIComponents/LinkText';
import { useNavigate } from 'react-router';
import './SettlementAgreemantHelp.css';

const urlHelpPage = '/help?section=Settlements&topics=Settings,Agreement%20Template';

export const SettlementAgreemantHelp = () => {
  const navigate = useNavigate();
  const handleDownloadHelpClick = () =>
    api.settlementAgreementTemplates.downloadHelp();

  const handleHelpPageClick = () => navigate(urlHelpPage);

  return (
    <div className="settlement-agreement-help">
      <HelpSection step={1} title="Download our Standart Agreement template">
        On this page click on the "Download a Standart Agreement template" button
      </HelpSection>
      <HelpSection step={2} title="Edit Agreemant document using Microsoft Word">
        You can edit the agreement's content to follow the Court's requirement and
        use tokes to populate the agreement with sensitive data (names, amounts,
        claim numbers). More info on how to create and use tokens you can{' '}
        <LinkText onClick={handleDownloadHelpClick}>find here</LinkText>
      </HelpSection>
      <HelpSection step={3} title="Upload new agreement">
        After you finish editing your template you need to upload it using the
        "Browse" button from the upload section on this page. The new uploaded
        template will be used on the Single Settlement Page to generate the
        settlement agreements - in PDF format - ready to be sent to the client and
        counterparties
      </HelpSection>
      <div className="settlement-agreement-help__additional-info">
        For more information on how to create and edit the agreement templates you
        can find <LinkText onClick={handleHelpPageClick}>on this page</LinkText>
      </div>
    </div>
  );
};

const HelpSection = (props: {
  step: number;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { step, title, children } = props;
  return (
    <div className="settlement-agreement-help__section">
      <ol className="settlement-agreement-help__title">
        <li value={step}>{title}</li>
      </ol>
      <div className="settlement-agreement-help__body">{children}</div>
    </div>
  );
};

import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { SingleSpan, SingleTextarea } from './CustomComponents';
import { SingleInputSection } from './SingleInputSection';
import './SingleTextfieldWithLabel.css';

interface Props {
  className?: string;
  label: string;
  readonly?: boolean;
  value?: string | null;
  onChange?: (value: string) => void;
}

export const SingleTextfieldWithLabel = ({
  label,
  readonly,
  value,
  className,
  onChange,
}: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    onChange?.(e.target.value);
  };

  return (
    <SingleInputSection
      label={label}
      className={classNames('single-text-section', className)}
    >
      {readonly ? (
        <SingleSpan>{value ?? ''}</SingleSpan>
      ) : (
        <SingleTextarea
          className="single-input-textarea"
          value={value ?? ''}
          onChange={handleOnChange}
        />
      )}
    </SingleInputSection>
  );
};

import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import React from 'react';
import './ModuleCard.css';

type CardBaseProps = {
  className?: string;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  icon: React.ReactNode;
};

function CardBase(props: CardBaseProps) {
  const { title, className, children, icon } = props;
  return (
    <>
      <article className={classNames('modulecard', className)}>
        <div className="modulecard__title modulecard__title-animated">
          <Title icon={icon} title={title} />
        </div>
        <div className="modulecard__title modulecard__title-original">
          <Title icon={icon} title={title} />
        </div>
        <div className="modulecard__content">{children}</div>
      </article>
    </>
  );
}

function Title(props: { title: string; icon: React.ReactNode }) {
  return (
    <h5 className="modulecard__title-text">
      {props.title}
      {props.icon}
    </h5>
  );
}

export function ModuleCard(props: Omit<CardBaseProps, 'icon'>) {
  return (
    <CardBase
      {...props}
      icon={<SvgIcon iconName="open_link" fill="currentColor" />}
    />
  );
}

export function ToolCard(props: Omit<CardBaseProps, 'icon'>) {
  return (
    <CardBase
      {...props}
      className={'modulecard-tool'}
      icon={<SvgIcon iconName="arrow_right" fill="currentColor" />}
    />
  );
}


export type ModuleTipProps = {
  children: React.ReactNode | React.ReactNode[];
};

export function ModuleTip(props: ModuleTipProps) {
  const { children } = props;
  return (
    <article role="note" className="moduletip">
      {children}
    </article>
  );
}

import './DashboardDetailedView.css';
import { useCallback, useMemo } from 'react';
import { DetailedItem } from 'pages/Dashboard/components/DetailedView/DetailedItem';
import { DetailedSubItems } from 'pages/Dashboard/components/DetailedView/DetailedSubItems';
import { DetailedViewNavButtons } from 'pages/Dashboard/components/DetailedView/DetailedViewNavButtons';
import { useDashboardContextData } from 'pages/Dashboard/context/DashboardContextProvider';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export const DashboardContractsDetailedView = () => {
  const { contractActiveSection, contractDetailedData } = useDashboardContextData();

  const dataToRender = useMemo(
    () =>
      contractDetailedData?.find(
        (dataItem) => dataItem.id === contractActiveSection,
      ),
    [contractDetailedData, contractActiveSection],
  );

  const renderItems = useCallback(
    () =>
      dataToRender?.items.map((item, idx) => {
        return (
          <DetailedItem
            dataToRender={item}
            className="detailed-item detailed-item__secondary"
            key={`${item.label}_${idx}`}
            sectionType={SectionType.Contracts}
          >
            {!!item.items?.length ? (
              <DetailedSubItems subItems={item.items} />
            ) : undefined}
          </DetailedItem>
        );
      }),
    [dataToRender],
  );

  if (!contractActiveSection || !dataToRender) return null;

  return (
    <div className="dashboard-detailed">
      <DetailedViewNavButtons sectionType={SectionType.Contracts} />
      <div className="dashboard-detailed__data">
        <DetailedItem
          firstItem
          dataToRender={dataToRender}
          className="detailed-item"
          sectionType={SectionType.Contracts}
        />
        {renderItems()}
      </div>
    </div>
  );
};

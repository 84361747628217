import { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from './useUpdateEffect';

// ======  Deprecated ===========
// Use useIntervalTimer() instead
// ==============================

// why you should use this instead of setInterval
//    It's possible that is, the callback for setInterval() can in turn call setInterval() to start another interval running,
//    even though !!! the first one is still going !!!!

type TAsyncFn = () => Promise<void>;

export const useMonitoringTimer = (timeout: number = 1000) => {
  const monitoringFn = useRef<TAsyncFn>();
  const timerId = useRef<number | undefined>();

  const [timerRun, setTimerRun] = useState(false);
  const [gettingData, setGettingData] = useState(false);

  const mainTimerFunction = async () => {
    if (!monitoringFn.current) return;

    try {
      setGettingData(true);
      await monitoringFn.current();
    } finally {
      setGettingData(false);
    }
  };

  useUpdateEffect(() => {
    if (!timerRun || gettingData) return;
    timerId.current = window.setTimeout(mainTimerFunction, timeout);
  }, [timerRun, gettingData]);

  useEffect(() => {
    return () => stopTimer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stopTimer = useCallback(() => {
    setTimerRun(false);
    clearTimeout(timerId.current);
    timerId.current = undefined;
    monitoringFn.current = undefined;
  }, [timerId]);

  const startTimer = useCallback(
    (fn: TAsyncFn) => {
      stopTimer();

      monitoringFn.current = fn;
      setTimerRun(true);
    },
    [stopTimer],
  );

  return { startTimer, stopTimer, timerRun };
};

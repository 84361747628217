import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import uniqueId from 'lodash/uniqueId';

import { ClaimRow } from 'api/endpoints';
import { Claim, ClaimGlobalFootnote, Exhibit } from 'api/endpoints/exhibits';
import { IOption } from 'components/UIComponents/MultiSelect';
import {
  EXHIBIT_FOOTER_OPTIONS,
  EXHIBIT_HEADER_OPTIONS,
} from 'pages/ExhibitExporterPage/components/sections/headerFooterOptions';

const HeaderAllowedValues = EXHIBIT_HEADER_OPTIONS.map((r) => r.value);
const FoterAllowedValues = EXHIBIT_FOOTER_OPTIONS.map((r) => r.value);

export interface IExhibitState {
  exhibitNumber: string;
  objectionBasis: string;
  actionNameId: number | undefined;
  claims: ClaimRow[];
  structureHeaderOptions: IOption[];
  structureFooterOptions: IOption[];
  globalFootnotes: ClaimGlobalFootnote[];
  displayObjection?: boolean;
  primaryCaseNumber?: number | string;
  projectName?: string;
}

const initialExhibitState: IExhibitState = {
  exhibitNumber: '',
  objectionBasis: '',
  actionNameId: undefined,
  claims: [],
  structureHeaderOptions: [],
  structureFooterOptions: [],
  globalFootnotes: [createClaimGlobalFootnote()],
  displayObjection: true,
};

const initialState = {
  currentExhibit: initialExhibitState,
  loading: false,
  error: undefined as string | undefined,
};

const exhibitSlice = createSlice({
  name: 'exhibit',
  initialState,
  reducers: {
    exhibitLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    exhibitLoaded(state, { payload }: PayloadAction<Exhibit>) {
      state.currentExhibit = {
        ...initialExhibitState,
        exhibitNumber: payload.exhibitNumber,
        objectionBasis: payload.objectionBasis,
        actionNameId: payload.actionNameId,
        claims: payload.claims.map(mapClaimToClaimRow),
        globalFootnotes: payload.globalFootnotes,

        structureHeaderOptions: payload.structureHeaders
          .filter((r) => HeaderAllowedValues.includes(`${r.headerOptionId}`))
          .map((r) => ({
            label: r.name,
            value: `${r.headerOptionId}`,
          })),

        structureFooterOptions: payload.structureFooters
          .filter((r) => FoterAllowedValues.includes(`${r.footerOptionId}`))
          .map((r) => ({
            label: r.name,
            value: `${r.footerOptionId}`,
          })),
      };
    },
    setActionId(state, action: PayloadAction<number | undefined>) {
      state.currentExhibit.actionNameId = action.payload;
    },
    setObjectionBasis(state, action: PayloadAction<string>) {
      state.currentExhibit.objectionBasis = action.payload;
    },
    setExhibitNumber(state, action: PayloadAction<string>) {
      state.currentExhibit.exhibitNumber = action.payload;
    },

    setExhibitHeader(state, action: PayloadAction<IOption[]>) {
      state.currentExhibit.structureHeaderOptions = action.payload;
    },
    setExhibitFooter(state, action: PayloadAction<IOption[]>) {
      state.currentExhibit.structureFooterOptions = action.payload;
    },
    setDisplayObjection(state, action: PayloadAction<boolean>) {
      state.currentExhibit.displayObjection = action.payload;
    },
    clearExhibit(state) {
      state.currentExhibit = initialExhibitState;
    },
    addGlobalFootnote(state) {
      state.currentExhibit.globalFootnotes = [
        ...state.currentExhibit.globalFootnotes,
        createClaimGlobalFootnote(),
      ];
    },
    setGlobalFootnote(
      state,
      action: PayloadAction<{
        id: number;
        value: Partial<ClaimGlobalFootnote>;
      }>,
    ) {
      const payload = action.payload;
      state.currentExhibit.globalFootnotes =
        state.currentExhibit.globalFootnotes.map((r) =>
          r.id === payload.id ? { ...r, ...payload.value } : r,
        );
    },
    deleteGlobalFootnote(state, { payload }: PayloadAction<number>) {
      state.currentExhibit.globalFootnotes =
        state.currentExhibit.globalFootnotes.filter((r) => r.id !== payload);
    },
    setClaims(state, action: PayloadAction<any>) {
      const claims = [...state.currentExhibit.claims, ...action.payload];
      state.currentExhibit.claims = uniqBy(claims, 'id');
    },
    setClaimsByActionName(state, action: PayloadAction<any>) {
      state.currentExhibit.claims = action.payload;
    },
    deleteSelectedClaims(state, { payload }: PayloadAction<number[]>) {
      state.currentExhibit.claims = state.currentExhibit.claims.filter(
        (r) => !payload.includes(r.id),
      );
    },
  },
});

export default exhibitSlice.reducer;

export const {
  exhibitLoading,
  exhibitLoaded,
  setExhibitNumber,
  setObjectionBasis,
  setActionId,
  setExhibitHeader,
  setExhibitFooter,
  setDisplayObjection,
  addGlobalFootnote,
  deleteGlobalFootnote,
  setGlobalFootnote,
  setClaims,
  setClaimsByActionName,
  clearExhibit,
  deleteSelectedClaims,
} = exhibitSlice.actions;

const mapClaimToClaimRow = (c: Claim) =>
  ({
    id: c.claimId,
    referenceNumber: c.claimReferenceNumber,

    counterpartyName: c.counterPartyName,
    proposedDebtor: c.proposedDebtor,
    currentTotal: c.currentTotal,
  } as ClaimRow);

function createClaimGlobalFootnote(id?: number): ClaimGlobalFootnote {
  return {
    id: id ?? +uniqueId(),
    footnote: '',
    footnoteAppearOptionId: 1,
  };
}

import { assignedGroupUsers } from 'api/endpoints/assigned-group-users';
import { AppDispatch } from '../store';

export const fetchAssignedGroupUserThunk = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'fetch-asssignedgroupusers-fetching' });
  const r = await assignedGroupUsers.get();
  if (!r) {
    // error is visually handle by a toast, but let the reducer know it was an error
    dispatch({
      type: 'fetch-asssignedgroupusers-error',
      error: 'Could fetch data.',
    });
  }
  dispatch({ type: 'fetch-asssignedgroupusers-done', data: r });
};

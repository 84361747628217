import { ENV } from 'env-variable';

export type Module = {
  type: 'live' | 'coming-soon' | 'external';
  hidden?: boolean;
  name: string;
  content: string;
  link?: string;
};

export const MODULES: Module[] = [
  {
    type: 'live',
    hidden: ENV.hideSchedules,
    link: '/sofas-schedules',
    name: 'Sofa & Schedules',
    // content:
    // 'Process all statements of financial activities and generate all scheduled claims ready to export or use within the claims tool.',
    content: 'Upload data, edit, review and export populated documents',
  },
  {
    type: 'live',
    hidden: ENV.hideClaims,
    name: 'Claims & Contracts',
    link: '/claims?section=Dashboard',
    content:
      'Match, search, filter, categorize, assign actions and review all claims & contracts in this project.',
  },
  {
    type: 'external',
    hidden: ENV.hideMor,
    name: 'MOR / PCR Generator',
    link: 'https://ustforms.alixpartners.com',
    content:
      'Process and export your reports by uploading pre-formatted spreadsheet data.',
  },

  {
    type: ENV.deploySettlements ? 'live' : 'coming-soon',
    hidden: ENV.hideClaimsettlements,
    name: 'Claims Settlements',
    link: '/claims-settlement',
    content:
      'Settle claims with counterparties, including automation of correspondence and integration with claims module.',
  },
  {
    type: 'live',
    hidden: true,
    name: 'Vendor Management',
    link: '/vendors?section=Dashboard',
    content:
      'Manage vendors related data. Match vendors, generate reports and documents',
  },

  {
    type: 'live',
    hidden: true,
    name: 'Petitions',
    content:
      'Access and download the most up to date versions of the Petitions PDF forms.',
  },
];

export const ADMIN_TOOLS: Module[] = [
  {
    type: 'live',
    name: 'Data Uploader',
    link: '/data-upload',
    content: 'Upload and preview all data required for a Chapter 11 engagement.',
  },
  {
    type: 'live',
    hidden: true,
    name: 'Access Manager',
    content: 'Manage non-AP user information and access rights for this project',
  },
  {
    type: 'live',
    name: 'Settings',
    link: '/settings',
    content: 'Review and adjust all project settings',
  },
  {
    type: 'live',
    hidden: true,
    name: 'Data Gathering',
    content:
      'Securely request and collate all information required from all parties',
  },
  {
    type: 'live',
    hidden: true,
    name: 'Source Documents',
    content:
      'Upload, store, search, filter and review all documentation for this project.',
  },
];

import Excel, { Column } from 'exceljs';
import { downloadExcelFile } from 'utils/file';
import { loadTemplate } from 'pages/ExhibitExporterPage/templates/utils/exporterUtils';

type ExportCategoriesItemsOptions = {
  templateName: string;
  fileName: string;
  columns: Array<Partial<Column>>;
};

export const exportCategoriesItems = async (
  itemsToExport: string[][],
  { templateName, fileName, columns }: ExportCategoriesItemsOptions,
) => {
  const workbook = new Excel.Workbook();

  const template = await loadTemplate(templateName, 'categories');

  await workbook.xlsx.load(template);

  const worksheet = workbook.getWorksheet('Category');

  worksheet.columns = columns;

  itemsToExport.forEach((item) => {
    worksheet.addRow(item);
  });

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = {
      bold: true,
    };

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FFCCCCCC',
      },
    };
  });

  await downloadExcelFile(workbook, fileName);
};

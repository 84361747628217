import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';
import {
  ClaimCategoryItemBase,
  EditClaimCategoryRequest,
  claimCategories,
} from 'api/endpoints';
import { isEmpty } from 'lodash';
import {
  fetchDropdownCategoriesDone,
  fetchAllCategoriesDone,
  editCategoryDone,
  fetchingAllCategories,
} from 'reducer/claimCategoriesReducer';
import { selectableLoaded } from 'reducer/selectableDataReducer';
import { AppDispatch, GetState } from 'reducer/store';

export const getClaimsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    const [categoriesMajorCategories, categoriesResponse] = await Promise.all([
      claimCategories.majorCategories(),
      claimCategories.get(),
    ]);
    if (categoriesMajorCategories?.length && categoriesResponse?.length) {
      dispatch(
        selectableLoaded({
          categoriesMajorCategories,
          categories: categoriesResponse,
        }),
      );
    }
  };

export const getDropdownClaimsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await claimCategories.getDropdownCategories();
    if (response?.length) {
      dispatch(fetchDropdownCategoriesDone(response));
    }
  };

export const saveClaimsCategoryThunk =
  (newItem: ClaimCategoryItemBase) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await claimCategories.addNewCategory(newItem);
    if (response?.data) {
      dispatch(getAllClaimsCategoriesThunk());
      dispatch(getDropdownClaimsCategoriesThunk());
    } else if (response.error) {
      showHttpSingleError(response.error);
    }
  };

export const getAllClaimsCategoriesThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(fetchingAllCategories(true));
    const response = await claimCategories.getAllCategories();
    if (response?.length) {
      dispatch(
        fetchAllCategoriesDone(
          response.map((category) => ({
            ...category,
            id: category.minorCategoryId,
          })),
        ),
      );
    }
    dispatch(fetchingAllCategories(false));
  };

export const editClaimsCategoryThunk =
  (values: EditClaimCategoryRequest) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await claimCategories.editCategory(values);
    if (response?.data) {
      dispatch(editCategoryDone(values));
    } else if (response.error) {
      showHttpSingleError(response.error);
    }
  };

export const setToggleAllThunk =
  (toggle: boolean) => async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await claimCategories.setToggleAll(toggle);
    if (isEmpty(response)) {
      dispatch(getAllClaimsCategoriesThunk());
    } else {
      showHttpSingleError({
        title: "Request couldn't be completed",
      });
    }
  };

import { reportLegalEntities, reportParts } from 'api/endpoints';
import { ReportType } from 'api/endpoints/sofas-schedules';
import {
  mapLegalEntityToOption,
  reportPartToOption,
} from '../Domain/sofa-schedules-mapper';
import { ExportSofasItems } from './sofaExportTypes';

export const apiGetSofasExportDropdownItems = async () => {
  const [entitiesSofa, entitieSchedule, parts] = await Promise.all([
    fetchUsedLegalEntitiesOptions('Sofa'),
    fetchUsedLegalEntitiesOptions('Schedule'),
    fetchPartsOptions(),
  ]);

  const result: ExportSofasItems = {
    sofa: { entities: entitiesSofa, parts: parts.sofa },
    schedule: { entities: entitieSchedule, parts: parts.schedule },
  };
  return result;
};

const fetchUsedLegalEntitiesOptions = async (reportType: ReportType) => {
  const { data } = await reportLegalEntities.getAssigned(reportType);
  return data?.map(mapLegalEntityToOption) ?? [];
};

const fetchPartsOptions = async () => {
  const { data } = await reportParts.getList();
  const sofa =
    data?.filter((r) => r.parentType === 'Sofa').map(reportPartToOption) ?? [];
  const schedule =
    data?.filter((r) => r.parentType === 'Schedule').map(reportPartToOption) ?? [];

  return { sofa, schedule };
};

import {
  CellClickedEvent,
  CellKeyDownEvent,
  GetRowIdParams,
  GridApi,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { contractsFetch, contractsLoaded } from 'reducer/contractsReducer';
import setPlaceholders from '../util/setPlaceholders';
import useRefClick from './contract/useRefClick';
import usePinRows from './usePinRows';

import { configuration, ContractRow, match } from 'api/endpoints';

import { PRIMARY_COLUMN } from '../const';
import {
  ContractsData,
  ExportExcelArgs,
  ExtendedGridOptions,
  GridColumnsSectionType,
  SectionType,
  SubsectionType,
} from '../types';
import { useContractsColumnDef } from './contract/useContractsColumnDef';
import useGridState from './useGridState';
import useMatching from './useMatching';

import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import excelExport from '../../../utils/excelExport';
import { createDatasource } from '../lazyLoading/dataSource';
import agGridCellSelector from '../util/agGridCellSelector';
import { selectAllCheckboxEnabled } from '../util/selectAllCheckboxEnabled';
import { setSavedResizedColumnWidth } from '../util/setSavedResizedColumnWidth';
import { useResetFilters } from './useResetFilters';
import { useSelectionManager } from './useSelectionManager';
import { DEFUALT_INPUT, dynamicFieldsActions } from 'reducer/dynamicFields';
import { DynamicField } from 'api/endpoints/dynamic-fields';
import { setExportState } from 'reducer/claimsContractsExportReducer';
import { useExportBackgroundJob } from './useExportBackgroundJob';

const load = (dispatch: any) => async () => {
  dispatch(contractsFetch({}));

  const [gridColumns, counterparties] = await Promise.all([
    configuration.getGridColumns(GridColumnsSectionType.Contracts),
    match.getMatches(),
  ]);

  // We need to filter only enabled dynamic fields
  const filteredGridColumns = gridColumns?.filter(
    (el) => !(el.propertyName.includes('custom') && !el.enabled),
  );

  const dynamicFields =
    gridColumns
      ?.filter((col) => col.propertyName.startsWith('custom'))
      ?.map((col) => {
        return {
          id: col.id,
          enabled: col.enabled,
          type: col.dataType,
          name: col.displayName,
          propertyName: col.propertyName,
          displayIn: col.displayIn,
        } as DynamicField;
      }) ?? [];

  dispatch(
    contractsLoaded({
      gridColumns: filteredGridColumns,
      counterparties,
    }),
  );
  dispatch(
    dynamicFieldsActions.done({
      section: SectionType.Contracts,
      items: dynamicFields,
      input: DEFUALT_INPUT,
    }),
  );
  dispatch(
    dynamicFieldsActions.setAll({
      section: SectionType.Contracts,
      items: dynamicFields,
    }),
  );
};

function useContractsData(
  section: SectionType,
  subsection: SubsectionType,
  setDisplayCount: (count: number) => unknown,
): ContractsData {
  const state = useAppSelector((state) => state.contracts);
  const loading = state.loading;

  const dispatch = useAppDispatch();

  const ref = useRef<AgGridReact>(null);
  const [searchText, setSearchText] = useState('');

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [lastSelectedCell, setLastSelectedCell] =
    useState<{ rowIndex: number; colId: string } | undefined>();
  const [dataSourceLoading, setDataSourceLoading] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<number>();

  const { generating, fileName } = useExportBackgroundJob(SectionType.Contracts);

  const { isClient = false } = useAppUserType();
  // this variable is false until grid is rendered and all dependencies are loaded
  // expect it to turn true after loading all contracts table data dependencies
  // and never turn false after that
  const gridRenderedAndDependenciesLoaded = ready && !loading;

  // this variable might turn false when additional data is loaded (such as pagination or filtering)
  // otherwise it should be true
  const gridDataSourceReady =
    gridRenderedAndDependenciesLoaded && !dataSourceLoading;

  const {
    selectedRows,
    selectAllCheckbox,
    onSelectionChanged,
    handleCheckboxChange,
    onPaginationChanged,
  } = useSelectionManager<ContractRow>(ref, gridDataSourceReady);

  const [filterModel, setFilterModel] = useState<any>({});

  const { gridColumns } = state.data || {};

  const [client] = useGraphql();

  const handleCellKeyDown = useCallback((e: CellKeyDownEvent) => {
    if (e.rowIndex === null) return;
    setLastSelectedCell({
      rowIndex: e.rowIndex,
      colId: e.column.getColId(),
    });
  }, []);
  const refocusSelection = useCallback(() => {
    if (!lastSelectedCell) return;
    const { rowIndex, colId } = lastSelectedCell;
    const element = agGridCellSelector(rowIndex, colId);
    if (!element) return;
    element.focus();
  }, [lastSelectedCell]);

  const reloadGridData = async () => {
    await client?.resetStore();
    ref.current?.api?.refreshServerSide({ route: [] });
  };

  const matching = useMatching(
    ref,
    section,
    subsection,
    selectedRows,
    refocusSelection,
    reloadGridData,
  );

  const {
    columnState,
    showMine: showMineContracts,
    setShowMine: setShowMineContracts,
    onDisplayedColumnsChanged,
    onBodyScrollEnd,
    onFirstDataRendered,
    onFilterChanged,
  } = useGridState({
    gridRef: ref,
    section,
    gridColumns,
    ready: gridRenderedAndDependenciesLoaded,
  });

  const columnDefs = useContractsColumnDef(
    gridColumns,
    section,
    subsection,
    columnState,
    selectAllCheckbox,
    {
      refocusSelection,
      onCheckboxChange: handleCheckboxChange,
      ...matching.columnDefsEvents,
    },
  );

  const updatePlaceholders = useCallback(
    () => setPlaceholders(columnDefs),
    [columnDefs],
  );

  useEffect(() => {
    if (columnState?.state && columnState?.state?.length > 0) {
      setVisibleColumns(columnState.state.filter((c) => c.hide === false).length);
    }
  }, [columnState]);

  const dataSource = useMemo(
    () =>
      createDatasource({
        section,
        subsection,
        showMine: showMineContracts,
        client,
        setDataSourceLoading,
        isClient,
        setDisplayCount,
        searchText,
      }),

    // let's update datasource if gridColumns change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      section,
      subsection,
      gridColumns,
      client,
      showMineContracts,
      visibleColumns,
      searchText,
    ],
  );

  const { pinnedTopRowData, onPinCellClickedInjector } =
    usePinRows<ContractRow>(section);
  const { onRefCellClickedInjector } = useRefClick(section, subsection);

  const onExport = useCallback(
    async ({ allColumns }: ExportExcelArgs) => {
      if (!ref?.current?.columnApi) return;
      if (!gridColumns) return;

      const fileName = `${allColumns ? 'All contracts' : 'Contracts'} table export`;
      const resp = await excelExport({
        filterModel,
        columnApi: ref?.current?.columnApi,
        gridColumns,
        allColumns,
        section,
        endRow: 50000,
      });
      if (resp && resp.jobId) {
        dispatch(
          setExportState({ generating: true, fileName, jobId: resp.jobId, section }),
        );
      }
    },
    [ref, filterModel, gridColumns, section, dispatch],
  );

  // loader
  useEffect(() => {
    load(dispatch)();
  }, [dispatch]);

  useEffect(() => {
    // grid is rerendered on section change so we
    // need to set ready to false
    setReady(false);
  }, [section, gridApi]);

  useEffect(() => {
    if (!ref?.current?.api) return;
    ref.current.api.deselectAll();
  }, [subsection]);

  const onResetFilters = useResetFilters(ref, section, setShowMineContracts);

  const onGridReady = (params: { api: GridApi }): void => {
    setGridApi(params.api);
    setReady(true);
  };

  const grid: ExtendedGridOptions = {
    ref,
    pagination: selectAllCheckboxEnabled(section, subsection),
    onPaginationChanged,
    onBodyScrollEnd,
    onFirstDataRendered,
    pinnedTopRowData,
    columnDefs,
    maintainColumnOrder: false,
    serverSideDatasource: dataSource,
    suppressClipboardApi: true,
    suppressClipboardPaste: true,
    // isServerSideGroupOpenByDefault: true,
    getRowId: useCallback(
      (params: GetRowIdParams) => params.data[PRIMARY_COLUMN],
      [],
    ),
    onFilterChanged,
    onModelUpdated: (event: any) => {
      if (dataSourceLoading) return;
      if (!ref?.current?.api) return;
      setFilterModel(ref.current.api.getFilterModel());
    },
    onGridReady,
    onSelectionChanged,
    onViewportChanged: updatePlaceholders,
    onBodyScroll: updatePlaceholders,
    onDisplayedColumnsChanged,
    onCellClicked: (event: CellClickedEvent<ContractRow>) => {
      onRefCellClickedInjector(event);
      onPinCellClickedInjector(event);
    },
    onCellKeyDown: handleCellKeyDown,
    onColumnResized: (params: any) =>
      setSavedResizedColumnWidth(params, ref, section),
  };

  return {
    selectedRows,
    loading,
    isExporting: !!generating,
    gridColumns,
    gridColumnsReady: !!gridColumns,
    grid,
    showMineContracts,
    searchText,
    onSearchChanged: setSearchText,
    onShowMineContractsChange: setShowMineContracts,
    onResetFilters,
    onExport,
    refresh: () => {
      load(dispatch)();
      reloadGridData();
    },
    exportFileName: fileName,
    dataSourceLoading,
  };
}

export default useContractsData;

import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import React, { AnchorHTMLAttributes, FC } from 'react';
import './DriveFileAnchor.css';

export type DriveFileAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {};

export const DriveFileAnchor: FC<DriveFileAnchorProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <a className={classNames('drive-file-anchor', className)} {...rest}>
      <SvgIcon
        iconName={'drive_file_icon'}
        fill="var(--ap-color-ui-green-primary)"
      />
      {children}
    </a>
  );
};

import map from 'lodash/map';
import {
  CounterpartyRow,
  Counterparty,
  CounterpartyRowWithPathId,
} from 'api/endpoints';

function doesOneFullMatchExist(counterparties?: Counterparty[]) {
  if (!counterparties) return false;
  const fullMatches = counterparties.filter((m) => m.score === 1);
  if (fullMatches.length === 1) return true;

  return false;
}

function calculateCounterparties(
  counterparties?: CounterpartyRow[],
): CounterpartyRowWithPathId[] {
  return map(counterparties, (c) => ({
    ...c,
    hasOneHundredPercentMatch: doesOneFullMatchExist(c.matches),
    matches: map(c.matches, (m) => {
      return {
        ...m,
        id: `${c.claimId}_${m.id}`,
        counterpartyId: m.id,
        path: [c.claimId, m.id],
        claimId: c.claimId,
      };
    }),
  }));
}

export default calculateCounterparties;

import { getColStateWithResizedWidth } from './getColStateWithResizedWidth';
import { setSavedGridState } from 'utils/local-storage';
import { SectionType } from '../types';
import { AgGridReact } from 'ag-grid-react';

export const setSavedResizedColumnWidth = (
  params: any,
  ref: React.RefObject<AgGridReact<any>>,
  section: SectionType,
) => {
  const { column: resizedColumn } = params || {};
  const newColumnState = getColStateWithResizedWidth(ref, resizedColumn, section);
  if (newColumnState && params.source === 'uiColumnDragged' && params.finished) {
    setSavedGridState(newColumnState);
  }
};

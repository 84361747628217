import { useAppSelector } from 'hooks/reducerHooks';
import { useClaimEditableForClient } from 'pages/SingleClaimPage/hooks/useClaimEditableForClient';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { isEditableApUser } from '../const/isEditableApUser';
import { isEditableClient } from '../const/isEditableClient';

export const useEditableField = () => {
  const { actionTypeId, actionStatus } = useAppSelector(
    (s) => s.claimActionNameSelection.claimActionInfo,
  );
  const { isClient, isApUser } = useAppUserType();
  const { editableByClient } = useClaimEditableForClient(actionStatus);

  const isEditable = (field: string, column?: string): boolean => {
    // for now, we don't allow to edit total column here
    // but if it'll be neccessary to extend permission control for columns
    // we need to add column editing permissions to apUser, clientUser files
    if (column === 'total') {
      return false;
    }

    if (isApUser) {
      // for now, only alter objection has a non-default permissions
      return isEditableApUser(field);
    }

    if (isClient) {
      // this logic is defined in this https://www.figma.com/file/dhbOLStSQgvXpBobk2plE5/Claims-tool-workflow-by-Action-status?node-id=10%3A75759&t=BgQLgJP1LBr5yTzI-4
      // and this PBI https://alixpartners-dev.visualstudio.com/AP.Chapter11Platform/_workitems/edit/108971
      return editableByClient && isEditableClient(field, actionTypeId);
    }
    return true;
  };

  return { isEditable };
};

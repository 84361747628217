import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { DashboardClaimsDetailedView } from './DashboardClaimsDetailedView';
import { DashboardContractsDetailedView } from './DashboardContractsDetailedView';

export const DashboardDetailedView = ({
  sectionType,
}: {
  sectionType: SectionType;
}) => (
  <>
    {sectionType === SectionType.Contracts && <DashboardContractsDetailedView />}
    {sectionType === SectionType.Claims && <DashboardClaimsDetailedView />}
  </>
);

/**
 * in this file you can define permissions
 * field for ap user per resolution type status.
 */

const AP_USER_DEFAULT_READONLY = [
  'original',
  'current',
  'proposed',
  'purchaseOrderReference',
  'itemQuantityCount',
  'poRefOnInvoice',
  'outsideContact',
  'purchasingDepartment',
  'purchaseOrderDate',
  'dateInvoiceReceived',

  // Claim Status
  'actionReviewStatus',
];

export function isEditableApUser(field: string) {
  return !AP_USER_DEFAULT_READONLY.includes(field);
}

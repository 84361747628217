import {
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  PaginationChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ComponentProps, forwardRef, useImperativeHandle, useRef } from 'react';
import { GridInitialState, useGridStateManager } from './useGridStateManager';

type AgGridReactProps = ComponentProps<typeof AgGridReact>;

type Props = AgGridReactProps & {
  initialState: GridInitialState;
  onGridStateChanged?: (state: GridInitialState) => void;
};

/**
 * AgGridExtended is a wrapper component for the ag-grid-react component.
 * It allows to provide initial grid state (column width, filter, pagination).
 *
 * @param {Props} props - The props for the AgGridExtended component.
 * @param {GridInitialState} props.initialState - The initial state of the grid.
 * @param {function} props.onGridStateChanged - The function to be called when the grid state changes.
 * @returns {React.ReactElement} - The AgGridExtended component.
 */
export const AgGridExtended = forwardRef<AgGridReact, Props>((props, ref) => {
  const { initialState, onGridStateChanged, ...gridProps } = props;

  const gridRef = useRef<AgGridReact>(ref as any);

  useImperativeHandle(ref, () => gridRef.current, []);

  const stateManager = useGridStateManager({
    gridRef: gridRef.current,
    initialState: initialState,
    onGridStateChanged,
  });

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
    stateManager.applyGridInitialState();
    gridProps.onGridReady?.(event);
  };

  const handleFirstDataRendered = (event: FirstDataRenderedEvent) => {
    stateManager.handleFirstDataRendered();
    gridProps.onFirstDataRendered?.(event);
  };

  const handleFilterChanged = (event: FilterChangedEvent) => {
    stateManager.handleFilterChange(event);
    gridProps.onFilterChanged?.(event);
  };
  const handleSortChanged = (event: SortChangedEvent) => {
    stateManager.handleColumnChange(event);
    gridProps.onSortChanged?.(event);
  };
  const handleColumnMoved = (event: ColumnMovedEvent) => {
    stateManager.handleColumnChange(event);
    gridProps.onColumnMoved?.(event);
  };
  const handleColumnResized = (event: ColumnResizedEvent) => {
    stateManager.handleColumnDebouncedChange(event);
    gridProps.onColumnResized?.(event);
  };
  const handleColumnVisible = (event: ColumnVisibleEvent) => {
    stateManager.handleColumnChange(event);
    gridProps.onColumnVisible?.(event);
  };
  const handlePaginationChanged = (event: PaginationChangedEvent) => {
    stateManager.handlePaginationChange(event);
    gridProps.onPaginationChanged?.(event);
  };

  return (
    <AgGridReact
      ref={gridRef}
      columnDefs={props.columnDefs}
      suppressRowClickSelection
      rowSelection={'multiple'}
      {...gridProps}
      // these proms must be bellow {...gridProps}
      onGridReady={handleGridReady}
      onFilterChanged={handleFilterChanged}
      onSortChanged={handleSortChanged}
      onColumnMoved={handleColumnMoved}
      onColumnResized={handleColumnResized}
      onColumnVisible={handleColumnVisible}
      onPaginationChanged={handlePaginationChanged}
      onFirstDataRendered={handleFirstDataRendered}
      // onSelectionChanged={(event) => {
      //   const selectedRows = event.api.getSelectedRows();
      // }}
    />
  );
});

import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ClientInfo } from './component/ClientInfo';

import './SetupWizardPage.css';
import { SinglePageBase } from 'components/SinglePageBase';

import { useAppInitialDataContext } from 'utils/AppInitializer/AppInitalDataProvider';
import { settings } from 'api/endpoints';

import { uploadLogo } from 'utils/uploader';

interface LogoParams {
  name: string;
  type: string;
}

export const SetupWizardPage = () => {
  const navigate = useNavigate();

  const { data } = useAppInitialDataContext();
  const { basicInfo } = data;
  const { clientInfo, setLogo, setBasicClientInfo } = basicInfo;

  const [logoParams, setLogoParams] = useState<LogoParams | undefined>();

  const saveClientInfo = async () => {
    const clientInfoToSave = {
      ...clientInfo,
    };
    if (clientInfo.logoContent) {
      const logoFileId = await uploadLogo({
        name: logoParams?.name,
        type: logoParams?.type,
        logoContent: clientInfo.logoContent,
      });
      if (!logoFileId) {
        alert('Failed to upload the logo');
        return;
      }
      clientInfoToSave.logoFileName = logoFileId;
    }
    await settings.postBasicInfo(clientInfoToSave);
    await basicInfo.init();
    navigate('/');
  };

  const handleSetLogo = (content: ArrayBuffer, filename: string, type: string) => {
    setLogoParams({
      name: filename,
      type,
    });
    setLogo(content);
  };

  return (
    <SinglePageBase className="setup-page__content">
      <div className="setup-page__content-box">
        <ClientInfo
          clientInfo={clientInfo}
          setClientInfo={setBasicClientInfo}
          setLogo={handleSetLogo}
          saveClientInfo={saveClientInfo}
        />
      </div>
    </SinglePageBase>
  );
};

const TRUE = 'true';

export const ENV = {
  appVersion: process.env?.REACT_APP_VERSION,
  projectCodeName: process.env?.REACT_APP_PROJECT_CODE_NAME,

  hideSchedules: process.env?.REACT_APP_HIDE_SCHEDULES?.toLowerCase() === TRUE,
  hideClaims: process.env?.REACT_APP_HIDE_CLAIMS?.toLowerCase() === TRUE,
  hideMor: process.env?.REACT_APP_HIDE_MOR?.toLowerCase() === TRUE,
  hideClaimsettlements:
    process.env?.REACT_APP_HIDE_CLAIMSETTLEMENTS?.toLowerCase() === TRUE,
  deploySettlements:
    process.env?.REACT_APP_DEPLOY_SETTLEMENTS?.toLowerCase() === TRUE,
};

import { ApButtonMain, ApInput, ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ChangeOptionsHook } from '../sections/SingleContractOverview/hook/useEditContract';
import '../../SingleClaimPage/components/SavePopup.css';

export interface SavePopupProps {
  className?: string;
  changeOptionsHook: ChangeOptionsHook;
  isSaving: boolean;
  open: boolean;
  onSave: () => unknown;
}

export const SavePopup = ({
  className,
  changeOptionsHook,
  isSaving,
  open,
  onSave,
}: SavePopupProps) => {
  const [changeOptions, setChangeOptions] = changeOptionsHook;

  return (
    <div data-open={open} className={classNames('save-popup', className)}>
      <div className="save-popup__inner-wrapper">
        <div className="save-popup__input">
          <ApInput
            disabled={!open}
            className="save-popup__comment"
            placeholder="Add a comment about your updates or resolution for this contract:"
            value={changeOptions.comment}
            onChange={(comment) =>
              setChangeOptions({
                ...changeOptions,
                comment,
              })
            }
          />
        </div>

        <div className="save-popup__btn-container">
          <ApButtonMain
            className="save-popup__save-btn"
            onClick={onSave}
            disabled={isSaving || !open}
          >
            {isSaving ? (
              <>
                <ApLoaderDefault loaderSize="sm" />
                Saving changes
              </>
            ) : (
              <>
                <SvgIcon iconName="save" />
                Save changes
              </>
            )}
          </ApButtonMain>
        </div>
      </div>
    </div>
  );
};

import { IServerSideGetRowsParams } from 'ag-grid-community';
import { SingleSettlementResponse } from 'api/endpoints/settlements';
import { GraphQlApolloClient } from 'api/graphql';
import { ColumnBase } from 'utils/agGrid/agGridColumn';
import { createGraphQLQueryFromParams } from 'utils/agGridHotchocolate/createGraphQLQueryFromParams';
import { isNotEmpty } from 'utils/lodashEx';

export const createSettlementDataSource = (
  client: GraphQlApolloClient,
  columns: ColumnBase[],
  onReady?: {
    setRowTotalCount?: (value: number) => void;
  },
) => ({
  getRows: async (params: IServerSideGetRowsParams<SingleSettlementResponse>) => {
    const gridColumns = columns;
    const requestColumns = params.columnApi?.getAllDisplayedColumns()
      .map((r) => r.getColDef().field)
      .filter(isNotEmpty);

    const { query, variables } = createGraphQLQueryFromParams(
      params.request,
      requestColumns,
      {
        queryName: 'GetClaimSettlement',
        graphqlEntity: 'settlements',
        booleanColumns: [],
        gridColumns: gridColumns ?? [],
      },
    );

    const response = await client.query({
      query,
      variables,
      fetchPolicy: 'no-cache',
    });
    const responseData = response.data?.settlements;

    if (!responseData) {
      params.fail();
      return;
    }

    const rowCount = responseData?.totalCount;
    const rowData = responseData?.items;

    // supply rows for requested block to grid
    params.success({ rowData, rowCount });
    onReady?.setRowTotalCount?.(rowCount);
  },
});

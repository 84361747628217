export const SCHEDULE_AB_4_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: ['Mutual', 'NonPublic', 'Bond'],
      dataType: 'enum',
      displayName: 'Type of Investment',
      propertyName: 'typeOfInvestment',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Name / Description',
      propertyName: 'nameDescription',
    },
    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: '% of Ownership',
      propertyName: 'ofOwnership',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Valuation Method',
      propertyName: 'valuationMethod',
    },
    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: 'Value',
      propertyName: 'value',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

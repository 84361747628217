import { ICellEditorParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import './TableComponents.css';

const strTrue = Boolean(true).toString();
const strFalse = Boolean(false).toString();

export const CellBooleanEditor: React.FC<ICellEditorParams> = forwardRef(
  (props: ICellEditorParams, ref) => {
    const inputRef = useRef<HTMLSelectElement>(null);
    const [value, setValue] = useState<boolean | undefined>(props.value);

    useImperativeHandle(ref, () => ({
      getValue: () => value,
      focusIn: () => inputRef.current?.focus(),
    }));

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value === strTrue);
    };

    return (
      <div className="editorcell-custom ag-input-wrapper ag-text-field-input-wrapper">
        <select
          autoComplete={strFalse}
          ref={inputRef}
          value={value?.toString()}
          onChange={handleChange}
          className="ag-input-field-input ag-text-field-input"
        >
          {isNil(value) ? <option value={undefined}></option> : undefined}
          <option value={strTrue}>true</option>
          <option value={strFalse}>false</option>
        </select>
      </div>
    );
  },
);

export const listPartsSOFA = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13A',
  '13B',
  '14',
];

export const listPartsSchedules = {
  AB: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  D: ['1', '2'],
  EF: ['1', '2', '3'],
  G: ['1'],
  H: ['1'],
  Signature: [''],
};
export type SchedulesType = keyof typeof listPartsSchedules;

export const listSchedules = Object.keys(listPartsSchedules) as SchedulesType[]; // ['AB', 'D', 'EF', 'G', 'H', 'Signatures'];

import { ApButtonMain, ApTextInput } from '@alixpartners/ui-components';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fileToArrayBuffer } from 'utils/file';

import { WizardFieldLabel } from 'pages/SetupWizardPage/component';
import AvatarEditor, { Position } from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useUrlObjectCreator } from 'utils/hooks/useUrlObjectCreator';
import { InputRange } from './InputRange';
import './LogoSelector.css';

const IMAGE_WIDTH = 320;
const IMAGE_HEIGHT = 80;
const IMAGE_BORDER = 25;

export interface LogoSelectorRef {
  getImage: () => Promise<ArrayBuffer | undefined>;
}

interface Props {
  initialLogo?: ArrayBuffer;
  allowModify?: boolean;
  onLogoUploaded: (
    content: ArrayBuffer,
    filename: string,
    fileType: string,
  ) => unknown;
  onLogoRemoved: () => void;
}

export const LogoSelector = forwardRef<LogoSelectorRef, Props>((props, ref) => {
  const { initialLogo, allowModify, onLogoUploaded, onLogoRemoved } = props;

  const avatarEditorRef = useRef<AvatarEditor>(null);

  const [file, setFile] = useState<any>();

  const [imageRotate, setImageRotate] = useState(0);
  const [imageScale, setImageScale] = useState(1);
  const [imagePosition, setImagePosition] = useState<Position>({ x: 0.5, y: 0.5 });

  const [editedLogoBuffer, setEditedLogoBuffer] = useState<ArrayBuffer>();
  const urlObjectCreator = useUrlObjectCreator();

  const logoImageUrl = useMemo(() => {
    const logoToShow = editedLogoBuffer ?? initialLogo;
    return urlObjectCreator.create(logoToShow);
  }, [initialLogo, editedLogoBuffer, urlObjectCreator]);

  useImperativeHandle(ref, () => ({
    getImage: async () => {
      // We need to do this weird operations to get data from an image editor
      const dataUrl = await avatarEditorRef.current
        ?.getImageScaledToCanvas()
        .toDataURL();

      if (!dataUrl) return undefined;

      const res = await fetch(dataUrl);
      const buffer = await res.arrayBuffer();
      return buffer;
    },
  }));

  const onDrop = useCallback(
    async (files: File[]) => {
      if (!files?.length) return;

      const file = files[0];
      setFile(file);

      const content = await fileToArrayBuffer(file);
      setEditedLogoBuffer(content);

      onLogoUploaded(content, file.name, file.type);
    },
    [onLogoUploaded],
  );

  const onRemoveLogoHandler = () => {
    setFile({});
    setEditedLogoBuffer(undefined);
    onLogoRemoved();
  };

  const dropZone = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: ['.png', '.gif', '.jpg', '.jpeg', '.jfif', '.jif', '.webp'],
    onDrop,
  });

  const isImageUploaded = !!logoImageUrl;
  const canBeEdited = allowModify && isImageUploaded;

  return (
    <>
      <div className="client-name-logo__line">
        {isImageUploaded ? (
          <>
            <ApButtonCasual
              style={{ textTransform: 'none' }}
              iconName="close"
              onClick={onRemoveLogoHandler}
            >
              Remove logo
            </ApButtonCasual>

            {file?.path && (
              <div className="client-name-logo__filename">
                <ApTextInput id="filename" value={file?.name} readOnly />
              </div>
            )}
          </>
        ) : (
          <ApButtonMain onClick={dropZone.open}>Upload Logo</ApButtonMain>
        )}
      </div>

      <div className="client-name-logo__image" {...dropZone.getRootProps()}>
        <input {...dropZone.getInputProps()} />
        <WizardFieldLabel
          text="Uploaded logo"
          info="Please ensure that the logo you are uploading is visible on the color background you are using. 
We recommend you to upload image up to 2 MB, in landscape format."
        />
        <div className="client-name-logo__info-text">
          You can drag the image or use the zoom to fit it into the visible area.
        </div>

        {isImageUploaded ? (
          <AvatarEditor
            ref={avatarEditorRef}
            image={logoImageUrl}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            border={IMAGE_BORDER}
            color={[200, 200, 200, 0.5]} // RGBA
            scale={imageScale}
            rotate={imageRotate}
            position={imagePosition}
            onPositionChange={setImagePosition}
          />
        ) : (
          <ImageDropZone
            style={{
              width: `${IMAGE_WIDTH + 2 * IMAGE_BORDER}px`,
              height: `${IMAGE_HEIGHT + 2 * IMAGE_BORDER}px`,
            }}
            onClick={dropZone.open}
          />
        )}
      </div>

      {canBeEdited && (
        <ImageEditorPanel
          rotate={imageRotate}
          setRotate={setImageRotate}
          scale={imageScale}
          setScale={setImageScale}
        />
      )}
    </>
  );
});

const ImageDropZone = (props: React.ComponentProps<'div'>) => {
  return (
    <div className="client-name-logo__drop-zone" {...props}>
      <SvgIcon iconName="svgImageFile" />
    </div>
  );
};

const ImageEditorPanel = (props: {
  rotate: number;
  scale: number;
  setRotate: (value: number) => void;
  setScale: (value: number) => void;
}) => {
  const { rotate, scale, setRotate, setScale } = props;

  return (
    <div className="client-name-logo__image-controls">
      <InputRange
        name="Rotate"
        min={-180}
        max={180}
        step={1}
        value={rotate}
        onValueChange={setRotate}
      />
      <InputRange
        name="Zoom"
        min={0.5}
        max={3}
        step={0.1}
        value={scale}
        onValueChange={setScale}
      />
    </div>
  );
};

import _ from 'lodash';
import { parseJwt } from 'utils/encode-decode';

export type UserRole =
  | 'TransactionApprover'
  | 'TransactionProposer'
  | 'ClientApprover'
  | 'ClientProposer';

//not sure if we need the next object
export const USER_ROLE: Record<string, UserRole> = {
  TransactionApprover: 'TransactionApprover',
  TransactionProposer: 'TransactionProposer',
  ClientApprover: 'ClientApprover',
  ClientProposer: 'ClientProposer',
};

export const AP_ROLES: UserRole[] = ['TransactionApprover', 'TransactionProposer'];
export const CLIENT_ROLES: UserRole[] = ['ClientApprover', 'ClientProposer'];

export type RoleType = 'AP' | 'Client';

export type ApplicationJwtPayload = {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  aio: string;
  amr: string[];
  email: string;
  family_name: string;
  given_name: string;
  groups: string[];
  idp: string;
  ipaddr: string;
  name: string;
  nonce: string;
  oid: string;
  rh: string;
  roles: string[];
  sub: string;
  tid: string;
  unique_name: string;
  uti: string;
  ver: string;
};

export type ApplicationUser = Pick<
  ApplicationJwtPayload,
  'unique_name' | 'family_name' | 'given_name' | 'groups' | 'email'
> & {
  roles: UserRole[];
  roleType: RoleType;
};

export function decodeApplicationUser(token: string): ApplicationUser {
  const result = parseJwt<ApplicationJwtPayload>(token);
  const { unique_name, given_name, family_name, groups, roles, email } = result;
  const userroles = roles as UserRole[];

  return {
    family_name,
    given_name,
    unique_name,
    groups,
    email,
    roles: userroles,
    roleType: getRoleType(userroles),
  };
}

function getRoleType(roles: UserRole[]): RoleType {
  const isApUser = _.intersection(roles, AP_ROLES).length > 0;
  if (isApUser) return 'AP';

  const isClient = _.intersection(roles, CLIENT_ROLES).length > 0;
  if (isClient) return 'Client';

  throw new Error(
    `Could not find AP or Client role types in ${JSON.stringify(roles)}`,
  );
}

import classNames from 'classnames';
import './DivEx.css';

type PropsDiv = React.ComponentProps<'div'>;

interface Props extends PropsDiv {
  disabled?: boolean;
  hidden?: boolean;
}

export const DivEx = ({
  disabled,
  hidden,
  className,
  children,
  ...divProps
}: Props) => {
  return (
    <div className={classNames('div-extended', className)} {...divProps}>
      {!!disabled ? <div className="div-extended__disabled" /> : undefined}
      {!hidden ? children : undefined}
    </div>
  );
};

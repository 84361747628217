import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from 'types/fetch-status.types';
import { ApplicationUser } from 'utils/AppInitializer/application-user';

export type ApplicationUserOuterSlice = {
  slice: ApplicationUserSlice;
};

export const initialSlice: ApplicationUserSlice = {
  status: FetchStatus.Idle,
  data: null,
  error: null,
};

export const initalOuterSlice: ApplicationUserOuterSlice = {
  slice: initialSlice,
};

export type ApplicationUserSlice =
  | {
      status: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      status: FetchStatus.Done;
      data: ApplicationUser;
      error: null;
    }
  | {
      status: FetchStatus.Fetching;
      data: ApplicationUser | null;
      error: { message: string } | null;
    }
  | {
      error: { message: string };
      data: ApplicationUser | null;
      status: FetchStatus.Error;
    };

const slice = createSlice({
  name: 'application-user',
  initialState: initalOuterSlice,
  reducers: {
    fetching(state) {
      state.slice.status = FetchStatus.Fetching;
    },
    error(state, action: PayloadAction<{ message: string }>) {
      state.slice.error = action.payload;
      state.slice.status = FetchStatus.Error;
    },
    done(state, action: PayloadAction<ApplicationUser>) {
      state.slice.data = action.payload;
      state.slice.error = null;
      state.slice.status = FetchStatus.Done;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;

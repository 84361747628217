import { isNotNil } from 'utils/lodashEx';
import './Help.css';
import { HelpMainPage } from './HelpMainPage';
import { HelpModulePage } from './HelpModulePage';
import { HelpSearch } from './HelpSearchResults';

export interface HelpDetails {
  section?: string;
  topics?: string[];
  search?: string;
}

interface Props {
  selected: HelpDetails | undefined;
  onChange: (value: HelpDetails) => void;
}

export const Help = ({ selected, onChange }: Props) => {
  const handleModuleClick = (section: string) => {
    onChange({ section });
  };

  const handleRedirectClick = (value: string[]) => {
    const [section, ...topics] = value;
    onChange({ section, topics });
  };

  const handleSearch = (search: string) => {
    onChange({ search });
  };

  if (isNotNil(selected?.search)) {
    return (
      <div className="settings-help__container">
        <HelpSearch
          searchText={selected!.search}
          onSearch={handleSearch}
          onRedirect={handleRedirectClick}
        />
      </div>
    );
  }

  if (selected?.section) {
    return (
      <div className="settings-help__container">
        <HelpModulePage
          section={selected.section}
          selectedKeys={selected.topics}
          onSearch={handleSearch}
        />
      </div>
    );
  }

  return (
    <div className="settings-help__container settings-help__background">
      <HelpMainPage onViewMoreClick={handleModuleClick} onSearch={handleSearch} />
    </div>
  );
};

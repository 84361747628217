import { ActionStatus } from 'api/endpoints';
import { isNil } from 'lodash';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';

//todo: I did only quick refactoring here. Need to refactor this code.
export interface MatchingOptions {
  unmatchedClaims: boolean;
  isMatchingEnabled: boolean;
}

const UNMATCHED_CLAIMS_ACTION_STATUS_FILTER = {
  actionStatus: { values: [ActionStatus.Categorized], filterType: 'set' },
};

export const getFilterWithUnmatched = (
  filterModel: AgGridFilterModel,
  options?: MatchingOptions,
) => {
  if (isNil(options) || !options.isMatchingEnabled) return filterModel;

  const { unmatchedClaims } = options;

  return {
    ...filterModel,
    notMatched: { values: [unmatchedClaims], filterType: 'set' },
    ...(unmatchedClaims ? UNMATCHED_CLAIMS_ACTION_STATUS_FILTER : {}),
  } as AgGridFilterModel;
};

import { ApIconInfoTooltip } from 'components/UIComponents/ApIconTooltip';
import { DivEx } from 'components/UIComponents/DivEx';
import { useCallback, useMemo } from 'react';
import {
  IUploadItem,
  IUploadSection,
  IUploadSectionHeaders,
  SectionHeaders,
} from 'types/dataUploaderTypes';
import { DataUploadMenuItem } from '../components/DataUploadMenuItem';
import { getStatusMapper } from '../utils/dataUploadMenuUtils';
import './DataUploadMenu.css';

interface IProps {
  sections: IUploadSection[];
  files: IUploadItem[];
  selected: IUploadSection | undefined;
  isPublishing: boolean;
  onSelect: (item: IUploadSection) => void;
  sectionHeaders: IUploadSectionHeaders[];
}

export const DataUploadMenu = ({
  sections,
  selected,
  files,
  isPublishing,
  onSelect,
  sectionHeaders,
}: IProps) => {
  const itemsWithStatus = useMemo(
    () => sections.map(getStatusMapper(files)),
    [files, sections],
  );

  const renderMenuItems = useCallback(
    (name: SectionHeaders) => {
      const filteredItems = itemsWithStatus.filter(({ header }) => header === name);
      return filteredItems.map((i) => (
        <DataUploadMenuItem
          key={i.id}
          label={i.caption}
          status={i.status}
          active={i.type === selected?.type}
          onClick={() => onSelect(i)}
        />
      ));
    },
    [onSelect, selected, itemsWithStatus],
  );

  return (
    <DivEx className="dataupload-menu" disabled={isPublishing}>
      {sectionHeaders.map(({ name, title, tooltip }: IUploadSectionHeaders) => {
        return (
          <div key={name} className="dataupload-menu-section">
            <div className="dataupload-menu__section-title">
              {name} {tooltip && <ApIconInfoTooltip message={tooltip} />}
            </div>
            {renderMenuItems(name)}
          </div>
        );
      })}
    </DivEx>
  );
};

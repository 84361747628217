import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApIcon,
  ApLoaderDefault,
} from '@alixpartners/ui-components';
import {
  BackgroundjobStatus,
  BACKGROUND_IN_PROGRESS,
} from 'api/endpoints/backgroundJob';
import classNames from 'classnames';
import { SubmitData } from 'components/ModalWindows/UploadModal';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { useState } from 'react';
import { BatchFilesUploadDialog } from './BatchUploadDialogs';

import './BatchUploadPanel.css';

const TITLE_DEFAULT = `Do you have a file with updated content?`;

export type BatchUpdateResponse = {
  errorCode?: number;
};

interface Props {
  className?: string;
  title?: string;
  backgroundjobStatus: BackgroundjobStatus | undefined;
  fileName?: string;
  uploadBatchEditDoc: (data: SubmitData) => Promise<boolean>;
  onDownloadTemplateClick: () => void;
  onDownloadErrorClick: () => Promise<void>;
}

export const BatchUploadPanel = ({
  className,
  title = TITLE_DEFAULT,
  backgroundjobStatus,
  fileName,
  uploadBatchEditDoc,
  onDownloadTemplateClick,
  onDownloadErrorClick,
}: Props) => {
  const [uploading, setUploading] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const hasErrors = backgroundjobStatus === 'Failed';
  const showProgress = BACKGROUND_IN_PROGRESS.includes(backgroundjobStatus!);

  const handleFileUpload = async (data: SubmitData) => {
    setUploading(true);
    const uploadResult = await uploadBatchEditDoc(data);
    setUploading(false);

    //upload error
    if (!uploadResult) return;

    setShowUploadDialog(false);
  };

  const handleFileUploadClick = () => setShowUploadDialog(true);

  return (
    <DivWithLoader
      className={classNames('batchupload-panel', className)}
      loading={uploading}
      loaderParams={{ className: 'batchupload-panel__loader' }}
    >
      {hasErrors && (
        <ErrorMessage
          fileName={fileName}
          onErrorDownloadClick={onDownloadErrorClick}
        />
      )}

      {showProgress ? (
        <UploadProgress fileName={fileName} />
      ) : (
        <UploadPanel
          title={title}
          onFileUpload={handleFileUploadClick}
          onTemplateDownload={onDownloadTemplateClick}
        />
      )}

      {showUploadDialog && (
        <BatchFilesUploadDialog
          label={
            'Upload the users file and press the “Confirm” button to add them to the platform'
          }
          uploading={uploading}
          onSubmit={handleFileUpload}
          onClose={() => setShowUploadDialog(false)}
        />
      )}
    </DivWithLoader>
  );
};

const ErrorMessage = (props: {
  fileName: string | undefined;
  onErrorDownloadClick: () => void;
}) => {
  return (
    <span className="batchupload-panel__error-title">
      <ApIcon iconName="error" iconColor="#D97706" />
      Error: the file could not be uploaded. You can download the error log file
      <button className="download-btn" onClick={props.onErrorDownloadClick}>
        here
      </button>
    </span>
  );
};

const UploadProgress = (props: { fileName: string | undefined }) => {
  const text = props.fileName ? `Uploading ${props.fileName}` : 'File Uploading';

  return (
    <div className="batchupload-panel__uploading">
      <span>{text}</span>
      <ApLoaderDefault loaderSize="sm" />
    </div>
  );
};

const UploadPanel = (props: {
  title: string;
  onFileUpload: () => void;
  onTemplateDownload: () => void;
}) => {
  return (
    <>
      <label className="batchupload-panel__label">{props.title}</label>
      <ApButtonGroup>
        <ApButtonMain onClick={props.onFileUpload}>Upload file</ApButtonMain> or
        <ApButtonSecondary
          className="ap-button-casual"
          onClick={props.onTemplateDownload}
        >
          Download template
        </ApButtonSecondary>
      </ApButtonGroup>
    </>
  );
};

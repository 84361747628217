import { URL_PATH } from 'appUrls';
import { ClaimsGoBackButton } from 'components/ClaimsMenuSectionTabs/ClaimsGoBackButton';
import { InfoModal } from 'components/InfoModal/InfoModal';
import { PageButtonsGroup, PageTabButton } from 'components/PageButtonsGroup';
import { PageMainPanel } from 'components/PageMainPanel';
import { SettlementButtonsGroup } from 'pages/ClaimsSettlementsPage/components/SettlementButtonsGroup/SettlementButtonsGroup';
import { SettlementHeaderBox } from 'pages/ClaimsSettlementsPage/components/SettlementHeaderBox';
import { SettlementViewSavePanel } from 'pages/ClaimsSettlementsPage/components/SettlementViewSavePanel';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  SettlementSingleAttachmentsProvider,
  useSettlementSingleAttachmentsContext,
} from './dataProvider/SettlementSingleAttachmentsProvider';
import {
  SettlementSingleContextProvider,
  useSettlementSingleContext,
} from './dataProvider/SettlementSingleContextProvider';
import './SettlementSingleView.css';
import { SettlementSingleDocuments } from './tabs/SettlementSingleDocuments';
import { SettlementSingleForm } from './tabs/SettlementSingleForm';

const SectionButtons: PageTabButton[] = [
  {
    id: 'dashboard',
    label: 'Overview',
    body: <SettlementSingleForm />,
  },
  {
    id: 'documents',
    label: 'Documents',
    body: <SettlementSingleDocuments />,
  },
  // *** uncomment the next tab when it is ready
  // {
  //   id: 'message',
  //   label: 'Message',
  //   disabled: true,
  // },
];

export const SettlementSingleView = () => {
  return (
    <SettlementSingleContextProvider>
      <SettlementSingleAttachmentsProvider>
        <SettlementSingleViewBody />
      </SettlementSingleAttachmentsProvider>
    </SettlementSingleContextProvider>
  );
};

const SettlementSingleViewBody = () => {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(URL_PATH.CLAIMS_SETTLEMENT);

  const {
    data,
    claimsField,
    loading,
    infoDialog,
    agreemantDocument,
    setFieldValue,
    push,
  } = useSettlementSingleContext();
  const documents = useSettlementSingleAttachmentsContext();

  const [selectedTab, setSelectedTab] = useState(SectionButtons[0]);

  const SectionButtonsWithNumber = useMemo(
    () =>
      SectionButtons.map((r) => {
        if (r.id === 'documents' && documents.items.length > 0) {
          return { ...r, label: `${r.label} [${documents.items.length}]` };
        }

        return r;
      }),
    [documents.items.length],
  );

  const handlePageTabClick = (tab: PageTabButton) => {
    if (tab.id === 'main') handleBackClick();
  };

  return (
    <PageMainPanel loading={loading}>
      <SettlementButtonsGroup
        onButtonClick={handlePageTabClick}
        additional={<ClaimsGoBackButton onClick={handleBackClick} />}
      />
      <div className="claims-settlement-single">
        <div className="claims-settlement-single__body">
          <SettlementHeaderBox
            title={data?.counterpartyName}
            settlementNum={data?.settlementNumber}
            claimsNumber={claimsField}
            status={data?.status}
          />
          <div className="claims-settlement-single__content">
            <PageButtonsGroup
              className="claims-settlement-single__tabs"
              buttons={SectionButtonsWithNumber}
              selectedId={selectedTab.id}
              onButtonClick={setSelectedTab}
            />
            <div className="claims-settlement-single__tabs-body">
              {selectedTab.body}
            </div>
          </div>
        </div>
      </div>
      <SettlementViewSavePanel
        comment={data?.saveComment}
        regenerate={data?.regenerate}
        showRegenerate={agreemantDocument.documentReady}
        onCommentChange={setFieldValue('saveComment')}
        onRegenerageChange={setFieldValue('regenerate')}
        onSaveClick={push}
        saveEnabled={!loading}
      />
      {infoDialog && (
        <InfoModal
          title={infoDialog.title}
          message={infoDialog.message}
          onCloseClick={infoDialog.onClose}
        />
      )}
    </PageMainPanel>
  );
};

import './GridCountHeaderLabel.css';

export const GridCountHeaderLabel = (props: {
  count: number | undefined;
  notMatched?: boolean;
  type: 'claims' | 'contracts';
}) => {
  const { count, notMatched, type } = props;
  return (
    <div className="claims-count-label">
      {!!count ? (
        count === -1 ? (
          <>Loading {type}...</>
        ) : (
          <>
            Displaying <strong>{count}</strong> {type}{' '}
            {notMatched && <>with possible matches</>}
          </>
        )
      ) : (
        <b>No {type} found</b>
      )}
    </div>
  );
};

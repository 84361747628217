import { acquireToken } from '@alixpartners/ui-utils';
import { jsonFetch } from './jsonFetch';
import { ErrorBody, ErrorResponse, OkResponse } from './jsonFetch.types';

const { REACT_APP_API_URL } = process.env;

export async function authJsonFetch<T, E extends ErrorBody = any>(
  urlPath: string,
  reqBody?: object,
  init?: RequestInit,
  skipBodyParse?: boolean,
): Promise<OkResponse<T> | ErrorResponse<E>> {
  const token = await acquireToken();
  const url = `${REACT_APP_API_URL}${urlPath}`;

  let reqInit: RequestInit = init ?? {};
  const headers = reqInit.headers ?? {};
  reqInit = {
    ...reqInit,
    headers: {
      ...headers,
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return jsonFetch(url, reqBody, reqInit, skipBodyParse);
}

export async function jsonFetchGet<T, E extends ErrorBody = any>(
  urlPath: string,
  init?: RequestInit,
): Promise<OkResponse<T> | ErrorResponse<E>> {
  return authJsonFetch(urlPath, undefined, {
    ...init,
    method: 'GET',
  });
}

export async function jsonFetchPost<T, E extends ErrorBody = any>(
  urlPath: string,
  reqBody?: object,
  init?: RequestInit,
  skipBodyParse?: boolean,
): Promise<OkResponse<T> | ErrorResponse<E>> {
  return authJsonFetch(
    urlPath,
    reqBody,
    {
      ...init,
      method: 'POST',
    },
    skipBodyParse,
  );
}

export async function jsonFetchPut<T, E extends ErrorBody = any>(
  urlPath: string,
  reqBody?: object,
  init?: RequestInit,
): Promise<OkResponse<T> | ErrorResponse<E>> {
  return authJsonFetch(urlPath, reqBody, {
    ...init,
    method: 'PUT',
  });
}

export async function jsonFetchDelete<T, E extends ErrorBody = any>(
  urlPath: string,
  reqBody?: object,
  init?: RequestInit,
  skipBodyParse?: boolean,
): Promise<OkResponse<T> | ErrorResponse<E>> {
  return authJsonFetch(
    urlPath,
    reqBody,
    {
      ...init,
      method: 'DELETE',
    },
    skipBodyParse,
  );
}

import { ENV } from 'env-variable';
import { PageNotFoundPage } from 'pages/AppDefaultPages';
import { Route, Routes } from 'react-router';

import { SettlementSingleView } from './sections/SettlementSingleView/SettlementSingleView';
import { SettlementTable } from './sections/SettlementTable';
import { SettlementGridContextProvider } from './SettlementGridContextProvider';

export const ClaimsSettlementsPage = () => {
  if (!ENV.deploySettlements) return null;

  return (
    <SettlementGridContextProvider>
      <Routes>
        <Route index element={<SettlementTable />} />
        <Route path="/view/:id" element={<SettlementSingleView />} />
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </SettlementGridContextProvider>
  );
};

import './FileUploadSupportedFormats.css';
import { ApIcon } from '@alixpartners/ui-components';

export const FileUploadSupportedFormats = ({
  supportedFormats,
}: {
  supportedFormats: string;
}) => {
  return (
    <>
      <ApIcon iconName="outline_info" className="supported-formats-icon" />
      <span className="supported-formats">
        Supported formats: <b>{supportedFormats}</b>
      </span>
    </>
  );
};

import React from 'react';
import { ApButtonMain, ApButtonSecondary } from '@alixpartners/ui-components';
import { IWizardStepProps } from 'pages/SetupWizardPage/type/setupWizardTypes';

import './SetupWizardStepPanel.css';

interface IProps {
  className?: string;
  title: string;
  helpText: React.ReactText | React.ReactElement;
  hideBackButton?: boolean;
  nextButtonText?: string;
  validate?: () => boolean;
  children: React.ReactNode;
}

export const SetupWizardStepPanel = ({
  className,
  title,
  helpText,
  hideBackButton,
  nextButtonText = 'Next',
  validate,
  children,
  ...wizard
}: IProps & IWizardStepProps) => {
  const handleNextPress = () => {
    if (validate && !validate()) return;
    wizard.nextStep?.();
  };

  return (
    <div className="setup-wizard-steppanel">
      <div className="setup-wizard-box">
        <div className="setup-wizard-box__title">{title}</div>

        <div className={`${className}`}>{children}</div>

        <div className="setup-wizard-box__footer">
          {!hideBackButton && (
            <ApButtonSecondary onClick={wizard.previousStep}>Back</ApButtonSecondary>
          )}
          <ApButtonMain autoFocus onClick={handleNextPress}>
            {nextButtonText}
          </ApButtonMain>
        </div>
      </div>

      <div className="setup-wizard-helpbox"> {helpText}</div>
    </div>
  );
};

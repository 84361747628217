import Lottie from 'react-lottie';
import './Animation.css';

interface Props {
  lottie: any;
  loop?: boolean;
  autoplay?: boolean;
  isPaused?: boolean;
  isStopped?: boolean;
  height?: number;
  width?: number;
}

export const Animation = ({
  lottie,
  loop = true,
  autoplay = true,
  isStopped = false,
  isPaused = false,
  height = 200,
  width = 200,
}: Props) => {
  return (
    <div className="ap-animation">
      <Lottie
        options={{
          loop,
          autoplay,
          animationData: lottie,
          rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
        }}
        height={height}
        width={width}
        isStopped={isStopped}
        isPaused={isPaused}
        isClickToPauseDisabled
      />
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractsUserGroupsResponse } from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';

export type ContractsUserGroupsSlice =
  | {
      status: FetchStatus.Idle;
      data: null;
    }
  | {
      status: FetchStatus.Done;
      data: ContractsUserGroupsResponse[];
    }
  | {
      status: FetchStatus.Error;
      data: ContractsUserGroupsResponse[];
    }
  | {
      status: FetchStatus.Fetching;
      data: ContractsUserGroupsResponse[];
    };

export const initialState: ContractsUserGroupsSlice = {
  status: FetchStatus.Idle,
  data: null,
} as ContractsUserGroupsSlice;

const contractsUserGroups = createSlice({
  name: 'contractsUserGroups',
  initialState: initialState,
  reducers: {
    fetching(state: ContractsUserGroupsSlice) {
      state.status = FetchStatus.Fetching;
    },
    done(
      state: ContractsUserGroupsSlice,
      action: PayloadAction<ContractsUserGroupsResponse[]>,
    ) {
      state.status = FetchStatus.Done;
      state.data = action.payload;
    },
  },
});

export const contractUserGroupsActions = contractsUserGroups.actions;
export default contractsUserGroups.reducer;

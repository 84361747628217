import { ICellEditorParams } from 'ag-grid-community';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { getIsoDate } from 'utils/formatDate';
import './TableComponents.css';

export const CellDateEditor = forwardRef((props: ICellEditorParams, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(getIsoDate(props.value) ?? '');

  useImperativeHandle(ref, () => ({
    getValue: () => stringToDate(value),
    focusIn: () => inputRef.current?.focus(),
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className="editorcell-custom ag-input-wrapper ag-text-field-input-wrapper">
      <input
        ref={inputRef}
        className="ag-input-field-input ag-text-field-input"
        type="date"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
});

const stringToDate = (value: string) => {
  const date = moment(value).utc(true).startOf('day');
  return date.isValid() ? date.toDate() : undefined;
};

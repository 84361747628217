import {
  IMultiSelectProps,
  IOption,
  LabeledMultiSelectExtendable,
} from 'components/UIComponents/MultiSelectExtendable';
import { useMemo } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { mapLegalEntityToOption } from '../Domain/sofa-schedules-mapper';
import { useReportLegalEntities } from '../Domain/useReportLegalEntities';

type Props = Pick<IMultiSelectProps, 'onChange' | 'selected'> & {};

export function SofaLegalEntityMultiselect(props: Props) {
  const { selected, onChange } = props;
  const { data, type } = useReportLegalEntities();

  const options: IOption[] = useMemo(() => {
    if (type === FetchStatus.Done) {
      return data.map(mapLegalEntityToOption);
    }
    return [];
  }, [data, type]);

  return (
    <LabeledMultiSelectExtendable
      topLabel="Search for entity"
      options={options}
      searchEnabled
      multiple={true}
      selected={selected}
      onChange={onChange}
      selectAll
    />
  );
}

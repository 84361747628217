import { URL_PATH } from 'appUrls';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useNavigate } from 'react-router';
import { clearToSend as clearClaimsActionToSendPayload } from 'reducer/claimActionNameSelectionReducer';
import { setClaimLogsIdle } from 'reducer/claimLogsDataReducer';
import { clearToSend as clearContractsActionToSendPayload } from 'reducer/contractActionNameReducer';
import { setContractLogsIdle } from 'reducer/contractLogsDataReducer';
import { useAppDispatch } from './reducerHooks';
import { resetState as resetDocumentsState } from 'reducer/documentsReducer';

export const useSubheaderMenuNav = (section: SectionType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cleanUpReduxState = () => {
    dispatch(resetDocumentsState());
    if (section === SectionType.Claims) {
      dispatch(clearClaimsActionToSendPayload({}));
      dispatch(setClaimLogsIdle());
      return;
    }

    if (section === SectionType.Contracts) {
      dispatch(clearContractsActionToSendPayload({}));
      dispatch(setContractLogsIdle());
      return;
    }
  };

  return {
    handleBackClick: () => {
      cleanUpReduxState();
      navigate(`${URL_PATH.CLAIMS}?section=${section}`);
    },
    handleSubMenuClick: () => {
      cleanUpReduxState();
    },
  };
};

import { AppDispatch, GetState } from '../store';
import { actions } from '../applicationUserReducer';
import { acquireToken } from '@alixpartners/ui-utils';
import { decodeApplicationUser } from 'utils/AppInitializer/application-user';

export const acquireUserThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(actions.fetching());
    try {
      const token = await acquireToken();
      const user = decodeApplicationUser(token);
      dispatch(actions.done(user));
      return user;
    } catch (e) {
      console.error(e);
      dispatch(actions.error({ message: 'Could not decode user access token.' }));
    }
  };

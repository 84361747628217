import {
  SettlementUser,
  SettlementUserNew,
  SettlementUserRole,
  settlementUsers,
} from 'api/endpoints/settlementUsers';
import _ from 'lodash';

export type SettlementUserInfo = {
  id: number;
  userId: string;
  name: string;
  email: string;
  userRole: SettlementUserRole;
  resetPassword: boolean;
};

export type SettlementUserEditable = Pick<
  SettlementUser,
  'name' | 'email' | 'userRole'
>;

export const apiSettlementUser = {
  getAll: async () => {
    const users = await settlementUsers.getAll();
    const result = users?.map(mapSettlementResposeToSettlementUser);
    return result ?? [];
  },

  postNew: async (formValues: Record<string, string | string[]>) => {
    const { name, email, userRole } = formValues as SettlementUserEditable;
    const value: SettlementUserNew = { name, email, userRole };

    const newUser = await settlementUsers.postNew(value);
    if (!newUser) return undefined;

    return mapSettlementResposeToSettlementUser(newUser);
  },

  updateUserRole: async (value: SettlementUserInfo) => {
    const data = _.pick(value, ['id', 'userRole']);
    return await settlementUsers.updateUser(data);
  },
};

//-------------------------
const mapSettlementResposeToSettlementUser = (
  value: SettlementUser,
): SettlementUserInfo => ({ ...value, resetPassword: false });

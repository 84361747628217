import { MajorCategories } from 'api/endpoints';
import { ItemRendererParams } from 'components/UIComponents/MultiSelectExtendable';
import { SvgIcon } from 'components/UIComponents/SvgIcon';

export const MajorMinorCategoryOption = ({ option }: ItemRendererParams) => {
  const item = option.data;
  if (!item) return false;
  if ((item as MajorCategories).majorCategory) return option.label;
  return (
    <div className="category-select__item">
      <SvgIcon iconName="return_turret" className="return_turren__icon" />
      <div>{option.labelNode || option.label}</div>
    </div>
  );
};

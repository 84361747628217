import {
  BlobServiceClient,
  BlobUploadCommonResponse,
  BlockBlobParallelUploadOptions,
  ContainerClient,
} from '@azure/storage-blob';
import { BlobStorageCredentials } from 'api/endpoints';
import { downloadBlob } from './file';
import { parseQueryParam } from './queryParams';

export default class AzureStorageClient {
  blobServiceClient: BlobServiceClient | undefined;
  containerClient: ContainerClient | undefined;
  host: string | undefined;
  sasToken: string | undefined;
  getCredentials: () => Promise<BlobStorageCredentials>;

  constructor(options: { getCredentials: () => Promise<BlobStorageCredentials> }) {
    this.getCredentials = options.getCredentials;
  }

  needsAuthenticating(): boolean {
    if (!this.sasToken) {
      return true;
    }
    const expiryDateString = parseQueryParam<string>(
      { search: this.sasToken },
      'se',
    );
    if (!expiryDateString) {
      return true;
    }
    const expiryDate = new Date(expiryDateString);
    const didExpire = +expiryDate <= Date.now();
    return didExpire;
  }

  async authenticate() {
    const { host, sasToken, containerName } = await this.getCredentials();
    this.host = host;
    this.sasToken = sasToken;
    this.blobServiceClient = new BlobServiceClient(`${host}${sasToken}`);
    this.containerClient = this.blobServiceClient.getContainerClient(containerName);
  }

  async upload(
    file: File,
    blobName: string = file.name,
    options?: BlockBlobParallelUploadOptions,
  ): Promise<BlobUploadCommonResponse> {
    if (this.needsAuthenticating()) {
      await this.authenticate();
    }

    let blockSize = getUploadChunksSize(file.size);
    const client = this.containerClient!.getBlockBlobClient(blobName);

    const response = await client.uploadData(file, {
      blockSize,
      maxSingleShotSize: blockSize,
      concurrency: 1,
      ...options,
    });
    return response;
  }

  async uploadBuffer(
    content: ArrayBuffer,
    blobName: string,
    options?: BlockBlobParallelUploadOptions,
  ): Promise<BlobUploadCommonResponse> {
    if (this.needsAuthenticating()) {
      await this.authenticate();
    }
    // Size of chunks
    const blockSize = 1024 * 1024 * 2; // 2mb
    const client = this.containerClient!.getBlockBlobClient(blobName);
    const response = await client.uploadData(content, {
      blockSize,
      ...options,
    });
    return response;
  }

  async download(blobName: string, fileName?: string) {
    if (this.needsAuthenticating()) {
      await this.authenticate();
    }
    const client = this.containerClient!.getBlockBlobClient(blobName);
    const response = await client.download();
    const blob = await response.blobBody;
    if (blob) {
      downloadBlob(blob, fileName ?? blobName);
    }
  }
}

const getUploadChunksSize = (fileSize: number) => {
  const size_32Mb = 32 * 1024 * 1024; // 32mb
  const size_2Mb = 2 * 1024 * 1024; // 32mb
  const size_256kb = 256 * 1024; // 256 kb

  return fileSize > size_32Mb ? size_2Mb : size_256kb;
};

import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { ContractsData } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useMemo, useState } from 'react';
import { fetchUserDashboardTasksThunk } from 'reducer/thunks/dashboard-thunks';
import { success } from 'utils/alert';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { postBatchEdit } from '../api/apiPostBatchEdit';
import { useBatchEditFields } from './useBatchEditFields';

export const useContractsToolBatchEdit = (contractsData: ContractsData) => {
  const { selectedRows, grid, onResetFilters } = contractsData;

  const {
    selectable: {
      data: { contractTypes },
    },
    contractActionNameSelection: {
      _allActionTypes: actionTypes,
      _allActionSubstatuses: allActionSubstatuses,
    },
  } = useAppSelector((state) => state);
  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);

  const dispatch = useAppDispatch();
  const user = useApplicationUser();

  const fields = useBatchEditFields({
    gridRef: grid.ref,
    resetFilters: onResetFilters,
    contractData: {
      actionTypes,
      allActionSubstatuses,
      contractTypes,
    },
  });
  const [loading, setLoading] = useState(false);

  const applyChanges = useCallback(async () => {
    setLoading(true);

    const selectedRowsIds = selectedRows.map((c) => `${c.id}`);
    const result = await postBatchEdit(selectedRowsIds, [
      ...fields.items,
      ...fields.cascadingItems,
    ]);
    if (!!result) {
      fields.clearAll();
      onResetFilters?.();
      success('Items changed successfully');
    }

    // we need to update the state of dashboard if we edit contracts
    if (user && user.data) {
      dispatch(fetchUserDashboardTasksThunk({ user: user.data, showMine }));
    }
    setLoading(false);

    return result;
  }, [selectedRows, fields, user, dispatch, onResetFilters, showMine]);

  const applyEnabled = useMemo(() => {
    const isEditFieldsEmpty = fields.items.every((r) => !r.propName);
    const isRecordsSelected = !!selectedRows?.length;
    return !loading && isRecordsSelected && !isEditFieldsEmpty;
  }, [selectedRows, loading, fields.items]);

  return {
    fields,
    loading,
    applyEnabled,
    applyChanges,
  };
};

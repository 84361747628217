import { ApDashboardTasksInfo } from 'api/endpoints/dashboard-tasks';
import { DashboardTaskInfo } from 'reducer/userDashboardTasksReducer';
import { getPercentage } from 'utils/dashboardHelpers';
import { DashboardTask } from './DashboardTask';

export const DashboardTasks = ({
  tasks,
  showMine,
}: {
  tasks: DashboardTaskInfo[];
  showMine?: boolean;
}) => {
  const getCount = (taskId: keyof ApDashboardTasksInfo) => {
    return tasks?.find((task) => task.id === taskId)?.count;
  };

  return (
    <div className="dashboard-tasks">
      {tasks.map((task, idx: number) => {
        if (task.hide) return null;

        if (task.subHeader) {
          return (
            <p key={task.id} className="task-subheader">
              {task.subHeader}
            </p>
          );
        }

        const firstTask = idx === 0;
        const contractsTotalPercentage = task.hasPercentage
          ? getCount('contractsTotalPercentage')
          : 0;
        const taskPercentage = task.hasPercentage
          ? firstTask
            ? contractsTotalPercentage
            : getPercentage(task.count ?? 0, getCount('allContracts'))
          : undefined;
        const taskDescription = firstTask
          ? showMine
            ? `My ${task.description}`
            : `All ${task.description}`
          : task.description;

        return (
          <DashboardTask
            key={task.id}
            task={task}
            firstTask={firstTask}
            taskPercentage={taskPercentage}
            taskDescription={taskDescription}
          />
        );
      })}
    </div>
  );
};

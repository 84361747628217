import partition from 'lodash/partition';
import { ContractBatchData, contracts } from 'api/endpoints';

import { ContractsReviewersBatchItem, IBatchItem } from '../contractsBatchEdit.type';

export const postBatchEdit = async (ids: string[], items: IBatchItem[]) => {
  const [reviewersItems, batchItems] = partition(
    items,
    (item) => item.type === 'group-multi-select',
  ) as [ContractsReviewersBatchItem[], IBatchItem[]];

  const reviewersValueToSend = reviewersBatchItemsToPostData(reviewersItems);

  const nonEmptyItems = batchItems.filter((r) => r.propName);
  const values = nonEmptyItems.reduce((a, v) => ({ ...a, [v.propName]: v.value }), {
    ...reviewersValueToSend,
  } as ContractBatchData['values']);

  const contractIds = ids.map((r) => +r);
  return contracts.batchEdit({ contractIds, values });
};

export function reviewersBatchItemsToPostData(
  reviewersItems: ContractsReviewersBatchItem[],
):
  | { contractReviewerUserIds: string[]; contractReviewerUserGroupIds: number[] }
  | {} {
  if (!reviewersItems.length) return {};

  // Last item of same type takes precedence
  const lastReviwersItem = reviewersItems[reviewersItems.length - 1];
  let reviewersValue = lastReviwersItem?.value;

  return reviewersValue ?? {};
}

import { ITooltipParams } from 'ag-grid-community';
import { useMemo } from 'react';
import './DebtorsCounterpartiesTootlip.css';
export const DebtorsCounterpartiesTootlip = (
  props: ITooltipParams & { type: string },
) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
    [props.api, props.rowIndex],
  );

  const column: string | undefined = props?.column?.getUniqueId();

  if (column === 'counterpartyName' && data?.hasMultipleCounterparties) {
    return (
      <div className="debtors_counterparties_tootlip-wrapper">
        {data?.counterpartyNames}
      </div>
    );
  }

  if (column === 'debtor' && data?.hasMultipleDebtors) {
    return (
      <div className="debtors_counterparties_tootlip-wrapper">{data?.debtor}</div>
    );
  }

  if (column === 'matchCode' && data?.hasMultipleMatchCodes) {
    return (
      <div className="debtors_counterparties_tootlip-wrapper">
        {data?.matchCodes}
      </div>
    );
  }

  return '';
};
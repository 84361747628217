import { LinkButtonSecondary } from 'components/UIComponents/LinkButton';
import { LinkText } from 'components/UIComponents/LinkText/LinkText';
import { useState } from 'react';
import { SofaCard, SofaSectionPanel } from '../../Components';
import './Home.css';
import { ModalDownloadTemplate } from './ModalDownloadTemplate';
import { ModalFormatDetails } from './ModalFormatDetails';

export function Home() {
  const [showTemplates, setShowTemplates] = useState(false);
  const [showFormatDetails, setShowFormatDetails] = useState(false);

  return (
    <SofaSectionPanel>
      <div className="sofas-home__container">
        <div className="sofas-home__header">
          <h3>Welcome to the SOFAs and Schedules App</h3>
          <h5>Upload data, edit, review and export populated documents</h5>
        </div>
        <div className="sofas-home__body">
          <SofaCard
            stepId={1}
            title="Download our template and add the data"
            caption="Check our complete list of templates, choose the one you need and use it for adding your client’s data"
            footer={
              <LinkButtonSecondary
                text="Download Template"
                onClick={() => setShowTemplates(true)}
              />
            }
          />
          <SofaCard
            stepId={2}
            title="Upload the populated template into the app"
            caption={
              <>
                View more details about the format of the file and other requirements
                by{' '}
                <LinkText onClick={() => setShowFormatDetails(true)}>
                  clicking this link
                </LinkText>
              </>
            }
            footer={<LinkButtonSecondary text="Upload data" href="/data-upload" />}
          />
          <SofaCard
            stepId={3}
            title="Prepare your Schedules and SOFAs Report"
            caption="You can view and edit data, add slipsheets, add global notes and preview in the report template"
            //todo: uncomment footer when we have link to the documentation
            //footer={<LinkButtonText text="Read more..." href="" />}
          />
          <SofaCard
            stepId={4}
            title="Export files"
            caption="You can select single or multiple entitites and choose which parts of the report to export."
            footer={
              <LinkButtonSecondary
                text="Export Reports"
                href="/sofas-schedules/export"
              />
            }
          />
        </div>
      </div>
      {showTemplates ? (
        <ModalDownloadTemplate onClose={() => setShowTemplates(false)} />
      ) : undefined}
      {showFormatDetails ? (
        <ModalFormatDetails onClose={() => setShowFormatDetails(false)} />
      ) : undefined}
    </SofaSectionPanel>
  );
}

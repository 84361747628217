import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractActionName } from 'api/endpoints/action-names';
import {
  ContractActionTypeId,
  ContractActionSubstatus,
  ContractActionType,
  ContractActionSelectOptionsData,
} from 'api/endpoints/actions-select-options';

export type ContractActionInfo = {
  id: number;
  contractActionTypeId?: ContractActionTypeId;
  contractActionSubStatusId?: number;
  contractActionNameId?: number;
  _fetchTimestamp?: string;
};

export enum CANSSliceType {
  Intitial = 1,
  Populated,
  Touched,
}

export type Base = {
  type: CANSSliceType;
  contractId: number;
  contractActionInfo: Omit<ContractActionInfo, 'id'>;
  toSend: Omit<ContractActionInfo, 'id'>;
  substatusOptions: ContractActionSubstatus[];
  actionNameOptions: ContractActionName[];
  actionTypeOptions: ContractActionType[];
  _allActionTypes: ContractActionType[];
  _allActionSubstatuses: ContractActionSubstatus[];
  _allActionNames: ContractActionName[];
  _fetchTimestamp?: string;
  contractExhibitTypeId: number;
  contractStatusId: number;
};

export type ContractActionNameSelectionSlice = Base;

export const initialState: ContractActionNameSelectionSlice = {
  type: CANSSliceType.Intitial,
  actionNameOptions: [],
  actionTypeOptions: [],
  substatusOptions: [],
  _allActionNames: [],
  _allActionSubstatuses: [],
  _allActionTypes: [],
  contractId: -1,
  contractActionInfo: {
    contractActionNameId: undefined,
    actionSubstatusId: undefined,
    actionContractActionTypeId: undefined,
  },
  toSend: {
    contractActionNameId: undefined,
    contractActionSubstatusId: undefined,
    contractActionTypeId: undefined,
  },
  contractExhibitTypeId: 1,
  contractStatusId: 0,
} as ContractActionNameSelectionSlice;

const SLICE_NAME = 'contract-actionname-selection';
const CONTRACT_SET_ACTION_TYPE = `${SLICE_NAME}/contractSet`;
const ACTION_TYPE_ID_SET_ACTION_TYPE = `${SLICE_NAME}/contractActionTypeIdSet`;
const ACTION_NAME_ID_SET_ACTION_TYPE = `${SLICE_NAME}/contractNameIdSet`;
const ACTION_SUBSTATUS_ID_SET_ACTION_TYPE = `${SLICE_NAME}/contractSubStatusIdSet`;
const ACTION_EXHIBIT_ID_SET_ACTION_TYPE = `${SLICE_NAME}/contractExhibitTypeIdSet`;
const ACTION_CONTRACT_STATUS_ID_SET_ACTION_TYPE = `${SLICE_NAME}/contractStatusIdSet`;
const ACTION_CLEAR_TO_SEND = `${SLICE_NAME}/clear-toSend`;

export function contractSet(payload: {
  contractActionInfo: ContractActionInfo;
  contractExhibitTypeId: number;
  contractStatusId: number;
}) {
  return { type: CONTRACT_SET_ACTION_TYPE, payload };
}
export function contractActionTypeIdSet(payload: { value: number | undefined }) {
  return { type: ACTION_TYPE_ID_SET_ACTION_TYPE, payload };
}

export function contractActionSubstatusIdSet(payload: {
  value: number | undefined;
}) {
  return { type: ACTION_SUBSTATUS_ID_SET_ACTION_TYPE, payload };
}

export function contractActionNameIdSet(payload: { value: number | undefined }) {
  return { type: ACTION_NAME_ID_SET_ACTION_TYPE, payload };
}

export function contractExhibitTypeIdSet(payload: number) {
  return { type: ACTION_EXHIBIT_ID_SET_ACTION_TYPE, payload };
}

export function contractStatusIdSet(payload: number) {
  return { type: ACTION_CONTRACT_STATUS_ID_SET_ACTION_TYPE, payload };
}

export function clearToSend(payload: {}) {
  return { type: ACTION_CLEAR_TO_SEND, payload };
}
/**
 * Node (a): The reducers {} field uses a proxies the state and prevents setting it in helper function, thus this switches
 *       the more 'classic' extraReducers approach.
 * Node (b): We use the action-* action types to hook up to the data loading flow
 */
const contractActionNameSelectionSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  extraReducers: {
    'action-names/contractActionNamesDone': (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<ContractActionName[]>,
    ) => {
      state._allActionNames = action.payload;
      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
    'actions-select-options/contractActionSelectOptionsDone': (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<ContractActionSelectOptionsData>,
    ) => {
      state._allActionTypes = [...action.payload.contractActionTypes];
      state._allActionSubstatuses = Object.values(
        action.payload.actionSubstatusesByActionType,
      ).flatMap((bt) => bt);
      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
    [CONTRACT_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<{
        contractActionInfo: ContractActionInfo;
        contractExhibitTypeId: number;
        contractStatusId: number;
      }>,
    ) => {
      state.contractId = action.payload.contractActionInfo.id;
      state._fetchTimestamp = action.payload.contractActionInfo._fetchTimestamp;
      state.contractExhibitTypeId = action.payload.contractExhibitTypeId;
      state.contractStatusId = action.payload.contractStatusId;

      const contractActionInfo = cascadeInputResets(
        action.payload.contractActionInfo,
      );
      state.contractActionInfo = contractActionInfo;

      state.actionNameOptions = addEmptyOptionToActionNameOptions(
        state._allActionNames,
        state.contractExhibitTypeId,
      );
      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
    [ACTION_TYPE_ID_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<{ value: number }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      const { contractActionSubStatusId, contractActionNameId } =
        state.contractActionInfo;

      const contractActionInfo = cascadeInputResets({
        contractActionTypeId: value,
        contractActionSubStatusId,
        contractActionNameId,
      });
      state.contractActionInfo = contractActionInfo;
      state.toSend = { ...contractActionInfo };

      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
    [ACTION_SUBSTATUS_ID_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<{ value: number }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      const { contractActionTypeId, contractActionNameId } =
        state.contractActionInfo;

      const contractActionInfo = cascadeInputResets({
        contractActionTypeId,
        contractActionSubStatusId: value,
        contractActionNameId,
      });
      state.contractActionInfo = contractActionInfo;

      state.toSend = { ...contractActionInfo };

      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
    [ACTION_NAME_ID_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<{ value: number | undefined }>,
    ) => {
      state.type = CANSSliceType.Touched;

      const { value } = action.payload;
      const { contractActionSubStatusId, contractActionTypeId } =
        state.contractActionInfo;

      const contractActionInfo = cascadeInputResets({
        contractActionTypeId,
        contractActionSubStatusId,
        contractActionNameId: value,
      });

      state.contractActionInfo = contractActionInfo;

      state.toSend = { ...contractActionInfo };

      setSelectionOptionsOnState(state, state.contractActionInfo);
    },

    [ACTION_CLEAR_TO_SEND]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<{}>,
    ) => {
      state.toSend = action.payload;
    },
    [ACTION_EXHIBIT_ID_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<number>,
    ) => {
      state.contractExhibitTypeId = action.payload;
      state.actionNameOptions = addEmptyOptionToActionNameOptions(
        state._allActionNames,
        action.payload,
      );
    },
    [ACTION_CONTRACT_STATUS_ID_SET_ACTION_TYPE]: (
      state: ContractActionNameSelectionSlice,
      action: PayloadAction<number>,
    ) => {
      state.contractStatusId = action.payload;

      const contractActionInfo = cascadeInputResets({
        contractActionTypeId: 0,
        contractActionSubStatusId: 0,
        contractActionNameId: state.contractActionInfo.contractActionNameId,
      });
      state.contractActionInfo = contractActionInfo;

      state.toSend = { ...contractActionInfo };
      setSelectionOptionsOnState(state, state.contractActionInfo);
    },
  },
  reducers: {},
});

export default contractActionNameSelectionSlice.reducer;

function addEmptyOptionToSubstatusOptions(
  options: ContractActionSubstatus[],
  contractActionTypeId: number,
): ContractActionSubstatus[] {
  return [
    {
      id: 0,
      contractActionSubStatusName: 'None',
      typeId: contractActionTypeId,
    },
    ...options.filter((o) => o.contractActionSubStatusName !== 'None'),
  ];
}

function addEmptyOptionToActionNameOptions(
  options: ContractActionName[],
  contractExhibitTypeId: number,
): ContractActionName[] {
  return [
    {
      id: 0,
      contractActionName: 'None',
      contractExhibitTypeId,
      contractActionNameId: 0,
    },
    ...options.filter(
      (o) =>
        o.contractActionName !== 'None' &&
        o.contractExhibitTypeId === contractExhibitTypeId,
    ),
  ];
}

export function computeSelectionOptions(
  state: Base,
  contractActionInfo: Omit<ContractActionInfo, 'id'>,
) {
  const substatusOptions = addEmptyOptionToSubstatusOptions(
    state._allActionSubstatuses.filter(
      (a) => a.typeId === contractActionInfo?.contractActionTypeId,
    ),
    contractActionInfo?.contractActionTypeId || 0,
  );
  const actionTypeOptions = state?._allActionTypes.filter(
    (at) => at.contractStatusId === state.contractStatusId && at.contractStatusId,
  );

  return {
    substatusOptions,
    actionTypeOptions,
  };
}
function cascadeInputResets(
  original: Omit<ContractActionInfo, 'id'>,
): Omit<ContractActionInfo, 'id'> {
  const { contractActionTypeId, contractActionNameId, contractActionSubStatusId } =
    original;
  const curated = {
    contractActionTypeId,
    contractActionNameId,
    contractActionSubStatusId,
  };
  // cascade resets
  if (!original.contractActionTypeId) {
    curated.contractActionSubStatusId = 0;
  }

  return curated;
}
function setSelectionOptionsOnState(
  state: Base,
  contractActionInfo: Omit<ContractActionInfo, 'id'>,
) {
  const { actionTypeOptions, substatusOptions } = computeSelectionOptions(
    state,
    contractActionInfo,
  );
  state.actionTypeOptions = addEmptyOptionToActionTypeOptions(
    state,
    actionTypeOptions,
  ) as ContractActionType[];
  state.substatusOptions = substatusOptions;
  return state;
}

function addEmptyOptionToActionTypeOptions(
  state: Base,
  options: ContractActionType[],
) {
  return [
    {
      id: 0,
      contractActionTypeName: 'None',
      contractActionSubStatuses: { ...state._allActionSubstatuses[0] },
      contractStatusId: 0,
      hearingDateRequired: false,
    },
    ...options,
  ];
}

import { ApButtonMain, ApTextInput } from '@alixpartners/ui-components';
import { useCallback, useState } from 'react';
import { fileToArrayBuffer } from 'utils/file';

import { useDropzone } from 'react-dropzone';

export interface WizardLogoSelectorProps {
  onLogoSelected: (
    content: ArrayBuffer,
    filename: string,
    fileType: string,
  ) => unknown;
}

export const WizardLogoSelector = ({ onLogoSelected }: WizardLogoSelectorProps) => {
  const [fileName, setFileName] = useState('');

  const onDrop = useCallback(
    async (files: File[]) => {
      const file = files[0];
      const filename = file?.name ?? '';
      const filetype = file?.type ?? '';
      setFileName(filename);
      if (!file) return;
      const content = await fileToArrayBuffer(file);
      onLogoSelected(content, filename, filetype);
    },
    [onLogoSelected],
  );

  const { open, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: ['.png', '.gif', '.jpg'],
    onDrop,
  });

  return (
    <>
      <div className="setup-wizard-line" {...getRootProps()}>
        <input {...getInputProps()} />
        <ApButtonMain onClick={open}>Upload Logo</ApButtonMain>
        <ApTextInput id="" value={fileName} readOnly />
      </div>
    </>
  );
};

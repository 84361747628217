import { ProjectDetails } from 'api/endpoints/projectDetails';
import {
  EditFieldsList,
  EntityTable,
  Panel,
} from 'components/UIComponents/EntityTable';
import { AwarePanel } from 'components/UIComponents/EntityTable/EntityTable.components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import './CasesEntities.css';
import { CasesEntitiesInput } from './CasesEntitiesInput';
import { CasesEntitiesTextField, EntitiesHeader } from './CasesEntitiesTextField';
import { useCasesEntities } from './useCasesEntities';
import { ExportButton } from './ExportButton';
const HEADERS = ['Project name', 'Primary case number'];
const LIST_HEADERS: EntitiesHeader[] = [
  { field: 'entityName', label: 'Entity Name' },
  { field: 'caseNumber', label: 'Case Number' },
  { field: 'legalEntityId', label: 'Legal Entity ID' },
];
const COLUMN_KEYS: (keyof ProjectDetails)[] = ['projectName', 'primaryCaseNumber'];

export function CasesAndEntities() {
  const {
    onProjectDetailsEditSave,
    projectDetails,
    sortIcon,
    legalEntities,
    handleSort,
    sortState,
    exporting,
    onEntitiesExport,
  } = useCasesEntities();

  return (
    <div className='cases-entities__container'>
      <SettingsContentPanel className='cases-entities__select'>
        <section className="cases-entities">
          <EntityTable
            items={[]}
            editColumnKeys={COLUMN_KEYS}
            inputComponent={CasesEntitiesInput}
            onEditSave={onProjectDetailsEditSave}
            section={SectionType.CasesEntities}
          >
            <Panel className='cases-entities-project-select' title="Project name and Primary case number">
              <EditFieldsList headers={HEADERS} singleItem={projectDetails} add />
            </Panel>
          </EntityTable>
        </section>
      </SettingsContentPanel>
      <SettingsContentPanel  className='cases-entities__list'>
        <section className="cases-entities">
          <EntityTable
            items={[]}
            editColumnKeys={COLUMN_KEYS}
            inputComponent={CasesEntitiesInput}
            onEditSave={onProjectDetailsEditSave}
            section={SectionType.CasesEntities}
          >
            <Panel className='cases-entities-header__container' title={`Legal Entities (${legalEntities.length ?? 0})`}>
              <ExportButton onClick={onEntitiesExport} exporting={exporting}/>
            </Panel>
            <AwarePanel
              className="cases-entities_text"
            >
              <CasesEntitiesTextField
                legalEntities={legalEntities}
                headers={LIST_HEADERS}
                sortIcon={sortIcon}
                handleSort={handleSort}
                sortState={sortState}
              />
            </AwarePanel>
          </EntityTable>
        </section>
      </SettingsContentPanel>
    </div>
  );
}

import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import React, { memo, useRef } from 'react';
import classes from './InputText.module.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputRef?: React.Ref<HTMLElement>;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  inputComponent?: any;
  error?: string;
  touched?: boolean;
  maxlength?: number;
}

/**
 * Input text component.
 *
 * Proxies the native HTML `<input>` component as closely as possible:
 *
 * - doesn't modify the 'onChange' handler which lets us access the input change event
 * - passes React ref to the underlying `<input>`
 *
 * Supports 'error' and 'touched' props for easy embedding in forms with field-level
 * validation (formik, react-final-form and similar).
 */
export const InputText = memo(
  ({
    label,
    inputRef,
    wrapperProps,
    type = 'text',
    error,
    touched,
    inputComponent: InputComponent = 'input',
    ...rest
  }: Props) => {
    const id = useRef(uniqueId());
    return (
      <div className={classNames('ap-input-text', classes.root)} {...wrapperProps}>
        {!isNil(label) && (
          <label className={classes.label} htmlFor={id.current}>
            {label}
          </label>
        )}
        <InputComponent
          type={type}
          id={id.current}
          {...rest}
          ref={inputRef}
          className={classNames(
            classes.input,
            error && touched && classes.inputError,
            rest.className,
          )}
        />
        {error && touched && (
          <div className={classes.error}>
            <div className={classes.errorIcon}>
              <ApIcon
                iconName="outline_error"
                iconSize="14px"
                iconColor="var(--ap-color-red1)"
              />
            </div>
            {error}
          </div>
        )}
      </div>
    );
  },
);

import { BasicClientInfo, settings } from 'api/endpoints';
import { useCallback, useMemo, useState } from 'react';
import { FetchStatus } from 'types/fetch-status.types';

export const DEFAULT_CLIENT_INFO: BasicClientInfo = {
  clientCompanyName: '',
  internalProjectName: '',
  logoFileName: '',
  primaryBrandColor: '',
  secondaryBrandColor: '',
};

export const useBasicInfo = () => {
  const [loadingStatus, setLoadingStatus] = useState(FetchStatus.Idle);
  const [initialized, setInitialized] = useState(true);

  const [basicClientInfo, setBasicClientInfo] =
    useState<BasicClientInfo>(DEFAULT_CLIENT_INFO);

  const [logoContent, setLogoContent] = useState<ArrayBuffer | undefined>(undefined);

  const init = useCallback(async () => {
    setLoadingStatus(FetchStatus.Fetching);
    const { data, error } = await settings.getBasicInfo();
    setLoadingStatus(FetchStatus.Done);

    if (error) {
      //connection error
      return;
    }

    if (!data) {
      //todo: I am not sure if this case is valid yet
      setInitialized(false);
      return;
    }

    setBasicClientInfo(data);
  }, []);

  const clientInfo = useMemo(
    () =>
      ({
        ...basicClientInfo,
        logoContent,
      } as BasicClientInfo),
    [basicClientInfo, logoContent],
  );

  return {
    initialized,
    loadingStatus,
    clientInfo,
    init,
    setBasicClientInfo,
    setLogo: setLogoContent,
  };
};

import { isNil, upperFirst } from 'lodash';
import pluralize from 'pluralize';

export const getHeaderText = (
  inProgressCount: number | undefined,
  queuedCount: number | undefined,
) => {
  if (isNil(inProgressCount) && isNil(queuedCount)) {
    return '';
  }

  if (inProgressCount === 0 && queuedCount === 0) {
    return 'No exports currently in progress';
  }

  const textProgressCount = inProgressCount! > 0 ? `${inProgressCount} ` : '';
  const queueCount = queuedCount! > 0 ? ` (+ ${queuedCount} in queue)` : '';
  const exportPlural = pluralize('export', inProgressCount || 1);

  const result = `${textProgressCount}${exportPlural} in progress${queueCount}`;
  return upperFirst(result);
};

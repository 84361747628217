import { ApAppBar, ApMenuItem } from '@alixpartners/ui-components';
import { INotification } from '@alixpartners/ui-components/dist/types/components/ApNotifications/NotificationItem';
import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';
import { BasicClientInfo } from 'api/endpoints';
import { URL_PATH } from 'appUrls';
import DOMPurify from 'dompurify';
import { useAppSelector } from 'hooks/reducerHooks';
import { useBookmarksData } from 'pages/BookmarksPage/hooks/useBookmarksData';
import { AddBookmark } from 'pages/BookmarksPage/sections/AddBookmark';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppInitialDataContext } from 'utils/AppInitializer';
import { urlExtractFileName } from 'utils/file';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { useUrlObjectCreator } from 'utils/hooks/useUrlObjectCreator';
import { clearAll } from 'utils/local-storage';
import { NavIconButton } from './components/NavIconButton';
import { NavIconMenu } from './components/NavIconMenu';
import { NavItemSeparator } from './components/NavItemSeparator';
import { NavLinkTab } from './components/NavLinkTab';
import './NavBar.css';

export const NavBarContainer = (props: { hideHeaderMenu?: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useAppInitialDataContext();
  const { basicInfo } = data;
  const { clientInfo } = basicInfo;

  return (
    <ApAuthenticationContextConsumer>
      {({ logout }) => (
        <NavBar
          clientInfo={clientInfo}
          notification={undefined}
          hideHeaderMenu={props.hideHeaderMenu}
          location={location}
          logout={logout}
          navigate={navigate}
        />
      )}
    </ApAuthenticationContextConsumer>
  );
};

export const NavBar = (props: {
  clientInfo: BasicClientInfo;
  notification?: INotification[];
  hideHeaderMenu?: boolean;
  location?: Location;
  navigate?: (url: string) => void;
  logout?(): void;
}) => {
  const { clientInfo, hideHeaderMenu, notification, location, navigate, logout } =
    props;

  const handleLogoutClick = useCallback(() => {
    logout?.();
    clearAll();
  }, [logout]);

  return (
    <ApAppBar
      className="header-navbar"
      backgroundColor={'var(--client-secondary-brand-color)'}
      position="static"
      isFluid
      thin
      left={() => <NavBarLeftContent clientInfo={clientInfo} />}
      right={() =>
        !hideHeaderMenu && (
          <NavBarRightContent
            location={location}
            notification={notification}
            navigate={navigate}
            logout={handleLogoutClick}
          />
        )
      }
    />
  );
};

const NavBarLeftContent = (props: { clientInfo: BasicClientInfo }) => {
  const { clientInfo } = props;
  const { clientCompanyName, logoFileName, logoContent } = clientInfo;

  const urlObjectCreator = useUrlObjectCreator();

  const logoContentUrl = useMemo(
    () => urlObjectCreator.create(logoContent),
    [logoContent, urlObjectCreator],
  );

  const imageFileUrl = useMemo(() => {
    if (logoContentUrl) return logoContentUrl;

    //Workaround. If logo is not uploaded, the back-end provides path without filename
    // todo: create Bug and assign in to the some Back-end programmer
    const hasLogo = urlExtractFileName(logoFileName);
    if (hasLogo) return logoFileName;

    return '';
  }, [logoFileName, logoContentUrl]);

  return (
    <div className="header-navbar_logo">
      <Link to={'/module-selector'} className="header-navbar_logolink">
        <div className="header-navbar_main-logo" />
        <NavItemSeparator color="var(--client-primary-brand-color)" />
        <div className="header-avbar_appname">Chapter 11 Platform</div>
      </Link>

      {clientCompanyName && (
        <>
          <div className="header-navbar_client">
            <NavItemSeparator color="var(--ap-color-ui-white)" />
          </div>

          <div className="header-navbar_clientname">{clientCompanyName}</div>
        </>
      )}

      {imageFileUrl && (
        <>
          <div className="header-navbar_client">
            <NavItemSeparator color="var(--ap-color-ui-white)" />
          </div>

          <div className="header-navbar_image_wrapper">
            <img
              src={DOMPurify.sanitize(imageFileUrl)}
              alt="client-logo"
              className="header-navbar_image"
            />
          </div>
        </>
      )}
    </div>
  );
};

const NavBarRightContent = (prop: {
  notification?: INotification[];
  location?: Location;
  navigate?: (url: string) => void;
  logout?: () => void;
}) => {
  const { location, navigate, logout } = prop;
  const { search, pathname } = location || {};

  const isActive = getIsActivePageFn(pathname);
  const navigateTo = (url: string) => () => navigate?.(url);
  const { isApUser } = useAppUserType();
  const { bookmarks = [] } = useAppSelector((s) => s.selectable.data);
  const section = useMemo(() => {
    if (search?.includes('section=Claims')) return SectionType.Claims;
    if (search?.includes('section=Contracts')) return SectionType.Contracts;
    return SectionType.NONE;
  }, [search]);

  const { openModal, setOpenModal, enableAddBookmark } = useBookmarksData(section);

  return (
    <div className="header-navbar_menu">
      <NavLinkTab
        label="Module Selector"
        active={isActive('/module-selector')}
        onClick={navigateTo('/module-selector')}
      />

      <NavItemSeparator color="#4B4B4B" />

      {/* todo: uncomment this when notification is implemented */}
      {/* <NavNotificationButton tooltip="Notifications" notification={notification} /> */}

      <NavIconButton
        active={isActive(URL_PATH.HELP)}
        onClick={navigateTo(URL_PATH.HELP)}
        tooltip="Help"
        iconName={'baseline_help_outline'}
      />

      <NavIconButton
        active={isActive(URL_PATH.CONTACT_US)}
        onClick={navigateTo(URL_PATH.CONTACT_US)}
        tooltip="Contact us"
        iconName={'baseline_email_outline'}
      />

      <NavIconMenu
        active={isActive('/bookmarks')}
        tooltip="Bookmarks"
        iconName={'outlined_bookmark_border'}
      >
        <ApMenuItem disabled={!enableAddBookmark} onClick={() => setOpenModal(true)}>
          Add bookmark
        </ApMenuItem>
        <ApMenuItem onClick={navigateTo('/bookmarks')} disabled={!bookmarks.length}>
          See all bookmarks ({bookmarks.length})
        </ApMenuItem>
      </NavIconMenu>

      <NavIconButton
        active={isActive('/data-upload')}
        onClick={navigateTo('/data-upload')}
        tooltip="Data uploading"
        iconName={'cloud_upload_outlined'}
      />

      <NavIconMenu
        active={isActive(['/settings', '/options'])}
        tooltip="General settings"
        iconName={'settings_outline'}
      >
        {isApUser ? (
          <ApMenuItem onClick={navigateTo('/settings')}>Settings</ApMenuItem>
        ) : (
          <></>
        )}
        <ApMenuItem onClick={() => logout?.()}>Log out</ApMenuItem>
      </NavIconMenu>
      {openModal && (
        <AddBookmark
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          section={section}
        />
      )}
    </div>
  );
};

const getIsActivePageFn =
  (locationPage: string = '') =>
  (url: string | string[]) => {
    if (typeof url === 'string') {
      return locationPage === url;
    }

    return url.includes(locationPage);
  };

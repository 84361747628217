import * as ls from 'local-storage';

import { GridState } from 'pages/ClaimsContractsToolPage/hooks/useGridState';

export function clearAll() {
  localStorage.clear();
}

// if format of grid state changes,
// make sure to bump GRID_STATE_VERSION
// number
const GRID_STATE_VERSION = 2;
const GRID_STATE_LS_KEY = `columnState_${GRID_STATE_VERSION}`;

export function getSavedGridState() {
  return ls.get<GridState>(GRID_STATE_LS_KEY) ?? {};
}

export function setSavedGridState(gridState: GridState | undefined) {
  ls.set(GRID_STATE_LS_KEY, gridState);
}

import { backgroundJobs } from './endpoints';
import { actionNames } from './endpoints/action-names';
import { actionSelectOptions } from './endpoints/actions-select-options';
import { actionStatus } from './endpoints/actionStatus';
import { assignedGroupUsers } from './endpoints/assigned-group-users';
import { backgroundjob } from './endpoints/backgroundJob';
import { bookmarksApi } from './endpoints/bookmarks';
import { claimCategories, contractCategories } from './endpoints/categories';
import { claimAttachments } from './endpoints/claimAttachments';
import { claimLogs } from './endpoints/claimLogs';
import { claimsReviewers } from './endpoints/claimReviewers';
import { claims } from './endpoints/claims';
import { claimsExcelExport } from './endpoints/claimsExcelExport';
import { clientClaims } from './endpoints/clientClaims';
import { configuration } from './endpoints/configuration';
import { contractAttachments } from './endpoints/contractAttachments';
import { counterpartiesAndDebtors } from './endpoints/contractCounterpartiesAndDebtors';
import { contractExhibitExports } from './endpoints/contractExhibitExports';
import { contractExhibitTypes } from './endpoints/contractExhibitTypes';
import { contractLogs } from './endpoints/contractLogs';
import { contracts } from './endpoints/contracts';
import { contractsExcelExport } from './endpoints/contractsExport';
import { userDashboardTasks } from './endpoints/dashboard-tasks';
import { dataPipeline } from './endpoints/dataPipeline';
import { dynamicFields } from './endpoints/dynamic-fields';
import { exhibits } from './endpoints/exhibits';
import { exportPdf } from './endpoints/exportPdf';
import { fileInfo } from './endpoints/fileInfo';
import { globalNotes } from './endpoints/global-notes';
import { legalEntities } from './endpoints/legalEntities';
import { match } from './endpoints/match';
import { projectDetailsApi } from './endpoints/projectDetails';
import { reportGenerator } from './endpoints/reportGenerator';
import { reports } from './endpoints/reports';
import { settings } from './endpoints/settings';
import { settlementAgreementTemplates } from './endpoints/settlementAgreementTemplates';
import { settlements } from './endpoints/settlements';
import { settlementUsers } from './endpoints/settlementUsers';
import { slipsheets } from './endpoints/slipsheets';
import { sofasAndSchedules } from './endpoints/sofas-schedules';
import { storage } from './endpoints/storage';
import { claimUserGroup } from './endpoints/userGroup';
import { userLogsApi } from './endpoints/userLogsApi';

//plese keep this in alphabetical order
export const api = {
  actionNames,
  actionSelectOptions,
  actionStatus,
  assignedGroupUsers,
  backgroundJobs,
  bookmarksApi,
  backgroundjob,
  claimAttachments,
  claimCategories,
  claimLogs,
  claimUserGroup,
  claims,
  claimsExcelExport,
  claimsReviewers,
  clientClaims,
  configuration,
  contractAttachments,
  contractCategories,
  contractExhibitExports,
  contractExhibitTypes,
  contractLogs,
  contracts,
  contractsExcelExport,
  counterpartiesAndDebtors,
  dataPipeline,
  dynamicFields,
  exhibits,
  exportPdf,
  fileInfo,
  globalNotes,
  legalEntities,
  match,
  projectDetailsApi,
  reportGenerator,
  reports,
  settlementAgreementTemplates,
  settlements,
  settlementUsers,
  settings,
  slipsheets,
  sofasAndSchedules,
  storage,
  userDashboardTasks,
  userLogsApi,
};

import { ApRadio } from '@alixpartners/ui-components';
import { optionsToMainLabel } from 'components/UIComponents/GroupMultiSelect/GroupMultiSelect.utils';
import { MultiSelect } from 'components/UIComponents/MultiSelect';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  setDisplayObjection,
  setExhibitFooter,
  setExhibitHeader,
} from 'reducer/exhibits/exhibitReducer';
import {
  EXHIBIT_FOOTER_OPTIONS,
  EXHIBIT_HEADER_OPTIONS,
} from './headerFooterOptions';

export type OptionData = {
  createdBy: string;
  createdDate: string;
  id: number;
  name: string;
  updatedBy: string;
  updatedDate: string;
};

export const ExhibitStructureSection = () => {
  const dispatch = useAppDispatch();
  const {
    structureHeaderOptions: selectedHeaderOptions = [],
    structureFooterOptions: selectedFooterOptions = [],
    displayObjection,
  } = useAppSelector((s) => s.exhibit.currentExhibit);
  const { loading } = useAppSelector((s) => s.exhibit);

  return (
    <div className="exhibit-exporter__section">
      <div className="exhibit-exporter__section-title">Exhibit Structure</div>
      <div className="exhibit-exporter__section-content-row">
        <div className="exhibit-exporter__section-content-row__multiselect-container">
          <label className="label">
            Information to be included in header of exhibit
          </label>
          <MultiSelect
            options={EXHIBIT_HEADER_OPTIONS}
            selected={selectedHeaderOptions}
            text={optionsToMainLabel(selectedHeaderOptions, 'Please select')}
            onChange={(e) => dispatch(setExhibitHeader(e))}
            disabled={loading}
          />
        </div>
        <div className="exhibit-exporter__section-content-row__multiselect-container">
          <label className="label">
            Information to be included in footer of exhibit
          </label>
          <MultiSelect
            options={EXHIBIT_FOOTER_OPTIONS}
            selected={selectedFooterOptions}
            label={optionsToMainLabel(selectedFooterOptions, 'Please select')}
            onChange={(e) => dispatch(setExhibitFooter(e))}
            disabled={loading}
          />
        </div>

        <div className="exhibit-exporter__section-content-row__radio">
          <label className="label">
            Display objection commentary for each claim
          </label>
          <div className="exhibit-exporter__section-content-row__radio-container">
            <ApRadio
              autoFocus={false}
              checked={!!displayObjection}
              onChange={() => dispatch(setDisplayObjection(true))}
              id="displayObjectionTrue"
              name="radio"
              theme="light"
              type="radio"
            >
              Yes
            </ApRadio>
            <ApRadio
              autoFocus={false}
              checked={!displayObjection}
              onChange={() => dispatch(setDisplayObjection(false))}
              id="displayObjectionFalse"
              name="radio"
              theme="light"
              type="radio"
            >
              No
            </ApRadio>
          </div>
        </div>
      </div>
    </div>
  );
};

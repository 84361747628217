import { SofaOrScheduleFileType } from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { fetchAllGlobalNotesThunks } from 'reducer/thunks/globalNotes-thunks';
import { GlobalNotesSlice } from 'reducer/globalNotesReducer';
import { FileType } from 'types/dataUploaderTypes';
import { FetchStatus } from 'types/fetch-status.types';

export function useGlobalNotes(fileType: SofaOrScheduleFileType) {
  const gnSlice: GlobalNotesSlice = useAppSelector((s) => {
    return fileType === FileType.Sofa ? s.sofaGlobalNotes : s.scheduleGlobalNotes;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (gnSlice.fetchState.status === FetchStatus.Idle) {
      dispatch(fetchAllGlobalNotesThunks(fileType));
    }
  }, [dispatch, fileType, gnSlice.fetchState.status]);

  return gnSlice;
}

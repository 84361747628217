import { ContractEditValues } from 'api/endpoints';
import { CategorySelectType } from './components/CategorySelect/config';

export type SingleContractSection = {
  title: string;
  id: PageSections;
  orientation?: 'horizontal' | 'vertical';
  inputs?: SingleContractInputsType;
  matrix?: SCMatrix;
  type?: undefined;
};

export type SingleContractInputsType = Array<SingleContractInputEntity>;

export type SingleContractInputEntity = {
  type?: 'empty' | 'address';
  label: string;
  field: string;
  textField?: boolean;
  category?: CategorySelectDef;
  dateField?: boolean;
  amountField?: boolean;
  toggle?: boolean;
  hasTitle?: boolean;
};

export interface CategorySelectDef {
  type: CategorySelectType;
  contract?: ContractEditValues;
  isMajor?: boolean;
  name: string;
}

export interface SCMatrix {
  rows: Array<
    | {
        type?: 'row';
        label: string;
        field: string;
        hideBoolean?: boolean;
      }
    | { type: 'empty' }
  >;
  columns: Array<
    | {
        label: string;
        field: string;
        // extend types if needed
        type: 'number' | 'bool' | 'category';
        category?: CategorySelectDef;
        formatting?: 'currency';
      }
    | { type: 'empty' }
  >;
}

export enum PageSections {
  Categorization = 'categorization',
  AdditionalInformation = 'additionalInformation',
  Assignment = 'assignment',
  ClaimStatus = 'claimStatus',
  Amounts = 'amounts',
  CounterpartyInfo = 'counterpartyContactInformation',
  ContractStatus = 'contractStatus',
  Exhibit = 'exhibit',
  Assessment = 'assessment',
  ContractSavings = 'contractSavings',
}

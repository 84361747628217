import { ApLoaderDefault } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './HeaderInfoPanel.css';

type DivProps = React.ComponentProps<'div'>;

export const HeaderInfoPanel = ({ children, className, ...props }: DivProps) => {
  return (
    <div className={classNames('headerinfo', className)} {...props}>
      {children}
    </div>
  );
};

export const InfoText = ({ className, ...props }: DivProps) => (
  <span className={classNames('headerinfo__text', className)} {...props} />
);

export const InfoBold = ({ className, ...props }: DivProps) => (
  <span className={classNames('headerinfo__bold', className)} {...props} />
);

export const InfoLink = ({ className, ...props }: DivProps) => (
  <span className={classNames('headerinfo__link', className)} {...props} />
);

export const InfoBoldLink = ({ className, children, ...props }: DivProps) => (
  <span
    className={classNames('headerinfo__bold headerinfo__link', className)}
    {...props}
  />
);

interface LoadingLinkProps extends DivProps {
  loading?: boolean;
}

export const InfoLoadingLink = ({
  className,
  loading,
  children,
  ...props
}: LoadingLinkProps) => (
  <span
    className={classNames(
      'headerinfo__loadinglink headerinfo__bold headerinfo__link',
      className,
    )}
    {...props}
  >
    {children}
    {loading ? <ApLoaderDefault className="download-spinner" /> : null}
  </span>
);

import { ICellRendererParams } from 'ag-grid-community';
import { ContractRow } from 'api/endpoints';
import { SubsectionType } from '../types';
import './CounterpartyNameCell/CounterpartyNameCell.css';

export interface DebtorNameCellProps extends ICellRendererParams<ContractRow, any> {
  subsection: SubsectionType;
}

export const DebtorNameCell = (props: DebtorNameCellProps) => {
  const contract = props.data;
  if (!contract) return <span></span>;

  if (contract.hasMultipleDebtors) {
    return (
      <div className="counterparty-name-cell__with-icon">
        <span className="cell-name">{contract.primaryDebtor}</span>
        <div className="cell-icon">+</div>
      </div>
    );
  }

  return contract.primaryDebtor;
};

/**
 * Case-insensitive search of a property in an object
 * (the same as object[key], but key is Case-insensitive}
 *
 * Example: getPropertyByKey({a: 'value'}, 'A') => 'value'
 */
export const getPropertyByKey = <T>(object: Record<string, T>, key: string) => {
  const asLowercase = key.toLowerCase();
  const field = Object.keys(object).find((k) => k.toLowerCase() === asLowercase);

  const value = !!field ? object[field] : undefined;

  return [field, value] as const;
};

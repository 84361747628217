import { isNil } from 'lodash';

export type QueryFilterItem = { [x: string]: { [operation: string]: string } };

//todo: improve this type
export type QueryFilter =
  | {
      and?: QueryFilterItem[];
      or?: QueryFilterItem[];
    }
  | QueryFilterItem;

export type VariableValue = string | number | boolean | Date;
export type VariableObject = {
  key?: string;
  type?: string;
  value: VariableValue;
};

export type VariableType = VariableValue | VariableObject;

export interface WhereQuery {
  query: QueryFilter;
  variables: VariableType[];
  additionalFilter?: string;
}

export const dicomposeVariableType = (variable: VariableType) => {
  const v = variable as VariableObject;

  if (!isNil(v.key) || !isNil(v.type)) {
    return v;
  }

  return {
    value: variable as VariableValue,
    key: undefined,
    type: undefined,
  };
};

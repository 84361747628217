import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { DataContentBox } from 'pages/DataUploaderPage/components/DataContentBox';
import {
  Extension,
  FileUploadPanel,
} from 'pages/DataUploaderPage/components/FileUploadPanel';
import { useDocumentsContextData } from 'pages/DocumentsPage/context/DocumentsPageContextProvider';
import { DocumentsPageUploadProgress } from './DocumentsPageUploadProgress';
import { FileUploadSupportedFormats } from 'components/FileUploadSupportedFormats/FileUploadSupportedFormats';

const DOCS_EXTENSIONS: Extension[] = [
  //documents
  '.xlsx',
  '.xls',
  '.pdf',
  '.docx',
  //image
  '.png',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.jif',
  '.heic', // Image used on the Mac/IPhone
  '.webp',
  // emails
  '.eml',
  '.msg',
  '.pst',
  '.edb',
  '.ost',
  '.mbox',
  // additional claims&contracts extensions
  '.rtf',
  '.bmp',
  '.s',
  '.a',
  '.xlsm',
  '.tif',
  '.txt',
  '.DOTX',
];

export const DocumentsUploadSection = ({
  title,
  sectionType,
  mappingSection,
}: {
  title: string;
  sectionType: SectionType;
  mappingSection?: boolean;
}) => {
  const {
    mappingFileUploading,
    docUnzipStatus,
    mappingStatus,
    handleUploadZipMapping,
    zipFilesUploading,
    imageFilesUploading,
    handleUploadDocs,
    handleUploadZipDocs,
    uploadProgress,
  } = useDocumentsContextData();

  const getContent = () => {
    if (mappingSection) {
      return (
        <div className="mapping-file-upload">
          {mappingFileUploading || mappingStatus ? (
            <DocumentsPageUploadProgress />
          ) : (
            <DataContentBox label="">
              <FileUploadPanel
                onUpload={handleUploadZipMapping}
                accept={['.xls', '.xlsx']}
                hideWarningMessage
                hideUploadIcon
                maxFiles={1}
                caption={
                  <p className="flex-align-center">
                    <span>
                      Drag your files here or browse files to upload{' '}
                      <b>{sectionType}</b> mapping file (supported formats:
                      <b> xlsx, xls</b>)
                    </span>
                    <ApButtonMain>Browse</ApButtonMain>
                  </p>
                }
                className={classNames({
                  disabled:
                    (zipFilesUploading || imageFilesUploading || docUnzipStatus) &&
                    !!uploadProgress?.progress,
                })}
              />
            </DataContentBox>
          )}
        </div>
      );
    }
    return (
      <DataContentBox label="">
        <>
          {zipFilesUploading ? (
            <DocumentsPageUploadProgress filesUploading={zipFilesUploading} />
          ) : (
            <FileUploadPanel
              onUpload={handleUploadZipDocs}
              accept={['.zip']}
              hideWarningMessage
              hideUploadIcon
              caption={
                <p className="flex-align-center">
                  <span>
                    Drag your <b>zip</b> file here or browse files to upload{' '}
                    <b>{sectionType}</b> documents in <b>zip</b> format
                  </span>
                  <ApButtonMain>Browse</ApButtonMain>
                </p>
              }
              maxFiles={1}
              className={classNames({
                disabled:
                  (mappingFileUploading || mappingStatus || imageFilesUploading) &&
                  !!uploadProgress?.progress,
              })}
            />
          )}
          <p>or</p>
        </>
        {imageFilesUploading ? (
          <DocumentsPageUploadProgress
            filesUploading={imageFilesUploading}
            className={classNames({
              claimsDocUpload: sectionType === SectionType.Claims,
            })}
          />
        ) : (
          <FileUploadPanel
            onUpload={handleUploadDocs}
            accept={DOCS_EXTENSIONS}
            hideWarningMessage
            hideUploadIcon
            caption={
              <p className="flex-align-center">
                <span>
                  Drag your files here or browse files to upload{' '}
                  <b>{sectionType} </b>
                  documents
                  <FileUploadSupportedFormats
                    supportedFormats={DOCS_EXTENSIONS.join(', ').replace(/\./g, '')}
                  />
                </span>
                <ApButtonMain>Browse</ApButtonMain>
              </p>
            }
            className={classNames({
              disabled:
                (mappingFileUploading || mappingStatus || zipFilesUploading) &&
                !!uploadProgress?.progress,
              claimsDocUpload: sectionType === SectionType.Claims,
            })}
          />
        )}
      </DataContentBox>
    );
  };
  return (
    <div className="documents-page_upload">
      <h3>{title}</h3>
      {getContent()}
    </div>
  );
};

import classNames from 'classnames';
import 'components/UIComponents/css-animations.css';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import _ from 'lodash';
import './SingleContractCounterpartiesAndDebtors.css';
import { Debtor } from './SingleContractCounterpartiesAndDebtorsTable';

type Components<T> = React.ReactElement<T> | React.ReactElement<T>[];

type Props = {
  debtors?: Debtor;
  showLoader?: boolean;
  emptyListMessage?: React.ReactNode;
  children: Components<typeof Row> | undefined;
};

export const CounterpartiesAndDebtorsTable = ({
  debtors,
  showLoader,
  emptyListMessage,
  children,
}: Props) => {
  const showEmptyMessage = emptyListMessage && _.isEmpty(children) && !showLoader;
  const isCounterparty = debtors === 'counterparty';
  const debtorsName = `${_.upperFirst(debtors)} name`;

  return (
    <DivWithLoader
      loading={showLoader}
      className="contract-counterparties-and-debtors-table"
    >
      <table>
        <thead>
          <tr className="table-header-row">
            <th style={{ width: isCounterparty ? '60%' : '70%' }}>{debtorsName}</th>
            {isCounterparty && <th style={{ width: '25%' }}>Match code</th>}
          </tr>
        </thead>
        <tbody>
          {showEmptyMessage ? (
            <EmptyListMessage value={emptyListMessage} />
          ) : (
            children
          )}
        </tbody>
      </table>
    </DivWithLoader>
  );
};

type RowProps = React.ComponentProps<'tr'>;
export const Row = ({ className, ...props }: RowProps) => {
  return <tr className={classNames('table-item-row', className)} {...props} />;
};

export const Cell = (props: React.ComponentProps<'td'>) => {
  return <td {...props} />;
};

const EmptyListMessage = (props: { value: React.ReactNode }) => {
  return (
    <Row>
      <Cell colSpan={Number.MAX_SAFE_INTEGER}>
        <div className="table-item__empty-list-message"> {props.value} </div>
      </Cell>
    </Row>
  );
};

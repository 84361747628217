import { useContractAttachments } from 'pages/DataUploaderPage/hooks/useContractAttachments';
import { createContext, useContext, useEffect } from 'react';

type Data = ReturnType<typeof useContractAttachments>;
const AppInitialDataContext = createContext<Data>({} as Data);

export const ContractAttachmentProvider = (
  props: React.PropsWithChildren<{ contractId: number }>,
) => {
  const { children, contractId } = props;
  const attachments = useContractAttachments(contractId);

  useEffect(() => {
    attachments.loadList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppInitialDataContext.Provider value={attachments}>
      {children}
    </AppInitialDataContext.Provider>
  );
};

export const useContractAttachmentsContext = () => useContext(AppInitialDataContext);

import { FileInfoItemResponse } from 'api/endpoints';
import { eUploadStatus } from 'pages/DataUploaderPage/utils/dataUploadMenuUtils';

export interface ValidationParams {
  type: 'string' | 'number' | 'date' | 'boolean';
  allowEmpty?: boolean;
}

export interface ISheetTemplate {
  sheetName: string | undefined;
  columns: {
    [columnName: string]: ValidationParams;
  };
}

export enum FileType {
  Category = 'Category',
  LegalEntity = 'LegalEntity',
  ScheduledClaims = 'ScheduledClaims',
  FiledClaims = 'FiledClaims',
  BaselineMatches = 'BaselineMatches',
  Contract = 'Contract',
  ContractCategory = 'ContractCategory',
  ContractImage = 'ContractImage',
  Vendor = 'Vendor',
  Sofa = 'Sofa',
  SofaAppend = 'SofaAppend',
  Schedule = 'Schedule',
  ScheduleAppend = 'ScheduleAppend',
  Claims = 'Claims',
  Contracts = 'Contracts',
  All = 'All',
  claimsBatchEditUpload = 'claimsBatchEditUpload',
  contractsBatchEditUpload = 'contractsBatchEditUpload',
  ContractImageZip = 'ContractImageZip',
  ContractImageZipMap = 'ContractImageZipMap',
  ClaimImage = 'ClaimImage',
  ClaimImageMap = 'ClaimImageMap',
  ClaimImageZip = 'ClaimImageZip',
}

export enum FileStatus {
  // local types
  Uploading = 'Uploading',

  // server types
  InProgress = 'InProgress',
  Failed = 'Failed',
  ApplicationException = 'ApplicationException',
  Unpublished = 'Unpublished',
  Published = 'Published',
}

export interface IUploadItem {
  displayName: string;
  name: string;
  id: number;
  type: FileType;
  file?: File & { schedulePart?: string };
  status?: FileStatus;
  uploadProgress?: number;
  errors?: string[];
  uploadedFile?: FileInfoItemResponse;
  schedulePart?: string;
  errorCount?: number;
}

export interface IUploadSection {
  id: string;
  type: FileType;
  caption: string;
  requiredFiles: number;
  header: SectionHeaders;
  status?: eUploadStatus;
  moduleToDelete?: ModuleToDelete;
  publishSection: PublishSection;
  description?: string;
}

export const ServerStatusTypes = [
  FileStatus.InProgress,
  FileStatus.Failed,
  FileStatus.ApplicationException,
  FileStatus.Unpublished,
  FileStatus.Published,
];

export const UnpublishedFilesStatuses = [
  FileStatus.InProgress,
  FileStatus.Failed,
  FileStatus.ApplicationException,
  FileStatus.Unpublished,
];

export type IUploadSectionHeaders = {
  name: SectionHeaders;
  title: string;
  tooltip?: string;
};

export enum SectionHeaders {
  Global = 'Global',
  Sofas = 'Sofas',
  Claims = 'Claims',
  Contracts = 'Contracts',
  Vendors = 'Vendors',
}

export enum PublishSection {
  AllModules = 'AllModules',
  ClaimsAndContracts = 'ClaimsAndContracts',
  SofasAndSchedules = 'SofasAndSchedules',
  Claims = 'Claims',
  Contracts = 'Contracts',
  ContractsImage = 'ContractsImage',
  LegalEntities = 'LegalEntities',
  Sofas = 'Sofas',
  Schedules = 'Schedules',
  Baseline = 'Baseline',
}

export enum ModuleToDelete {
  SofasSchedules = 'sofas-and-schedules',
  Claims = 'claims',
  Contracts = 'contracts',
  Sofas = 'sofas',
  Schedules = 'schedules',
  AllModules = 'allModules',
}

export type DeleteSection = {
  type: FileType;
  label: string;
  moduleToDelete: ModuleToDelete;
};

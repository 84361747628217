import { ClaimRow } from 'api/endpoints';
import {
  ApModal,
  ApButtonGroup,
  ApButtonSecondary,
  ApButtonMain,
} from '@alixpartners/ui-components';
import maxBy from 'lodash/maxBy';
import './MatchGroupCreationPopup.css';
import { useMemo } from 'react';

interface MatchGroupCreationPopupProps {
  claims: Array<ClaimRow>;
  onAccept: () => unknown;
  onClose: () => unknown;
  isOpen: boolean;
}

export const MatchGroupCreationPopup = ({
  isOpen,
  onAccept,
  onClose,
  claims,
}: MatchGroupCreationPopupProps) => {
  const mostRecent = useMemo(
    () =>
      maxBy(claims, (c) =>
        c.createdDate ? new Date(c.createdDate).getTime() : undefined,
      ),
    [claims],
  );

  return (
    <ApModal
      header="Match-group creation"
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonSecondary onClick={() => onClose()}>Cancel</ApButtonSecondary>
          <ApButtonMain
            onClick={() => {
              onAccept();
              onClose();
            }}
          >
            Done
          </ApButtonMain>
        </ApButtonGroup>
      )}
      isDismissible={false}
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
    >
      <div className="match-group-creation-popup">
        <div>You are about to manually match the following claims</div>
        {claims.map((c) => (
          <div className="claim" key={`counterparty_${c.id}`}>
            <div className="counterparty-name">{c.counterpartyName}</div>
            <div className="match-code">Match Code: {c.matchCode}</div>
          </div>
        ))}
        <div>
          They will be grouped and given the match code and counterparty name of the
          most recent claim:
        </div>
        {mostRecent && (
          <div className="claim most-recent">
            <div className="counterparty-name">{mostRecent?.counterpartyName}</div>
            <div className="match-code">Match Code: {mostRecent?.matchCode}</div>
          </div>
        )}
        <div>Are you sure you want to make these edits?</div>
      </div>
    </ApModal>
  );
};

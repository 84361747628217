import { downloadFileGet } from 'api/apiRequest';
import {
  jsonFetchDelete,
  jsonFetchGet,
  jsonFetchPost,
  jsonFetchPut,
} from 'api/jsonFetch/authJsonFetch';
import { FileType } from 'types/dataUploaderTypes';
import { downloadBlob } from 'utils/file';

export interface GlobalNoteLegalEntity {
  id: number;
  legalEntityId: number;
  legalEntityName: string;
}

export interface GlobalNote {
  id: number;
  fileType: SofaOrScheduleFileType;
  name: string;
  displayName: string;
  legalEntities: GlobalNoteLegalEntity[];
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}

export type LegalEntitiesIds = Pick<GlobalNoteLegalEntity, 'legalEntityId'>[];

export type GlobalNotePost = {
  id?: number;
  legalEntities: LegalEntitiesIds;
} & Pick<GlobalNote, 'fileType' | 'name' | 'displayName'>;

export type GlobalNotePut = {
  legalEntities: LegalEntitiesIds;
} & Pick<GlobalNote, 'fileType' | 'id'>;

export type SofaOrScheduleFileType = FileType.Sofa | FileType.Schedule;

export const globalNotes = {
  async getList(fileType: SofaOrScheduleFileType) {
    return jsonFetchGet<GlobalNote[]>(`v1/GlobalNotesFileInfo?FileType=${fileType}`);
  },
  async post(params: GlobalNotePost) {
    return jsonFetchPost<GlobalNote>(`v1/GlobalNotesFileInfo`, params);
  },
  async put(params: GlobalNotePut) {
    return jsonFetchPut(`v1/GlobalNotesFileInfo`, params);
  },
  async setAsCurrent(params: { id: number }) {
    return jsonFetchPut<GlobalNote>(
      `v1/GlobalNotesFileInfo/${params.id}/setascurrentnotes`,
    );
  },
  async delete(params: { id: number }) {
    return jsonFetchDelete<void>(
      `v1/GlobalNotesFileInfo/${params.id}`,
      undefined,
      undefined,
      true,
    );
  },
  download: async (params: { id: number; displayName: string }) => {
    const response = await downloadFileGet(
      `v1/GlobalNotesFileInfo/${params.id}/download`,
    );
    if (response.type === 'ok') {
      await downloadBlob(response.data as Blob, params.displayName ?? 'file.docx');
    }
  },
};

import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { iif } from 'utils/object';
import { fetchMarkdownFile } from './api';
import { HelpAccordion } from './components/HelpAccordion';
import { ModuleBox, TopicsBox } from './components/HelpContentsBox';

import './HelpModulePage.css';
import { HelpStructure, helpStructure } from './helpStructure';
import { getPropertyByKey } from './utils/getPropertyByKey';

interface Props {
  section: string;
  selectedKeys: string[] | undefined;
  onSearch: (searchText: string) => void;
}

export const HelpModulePage = ({ section, selectedKeys = [], onSearch }: Props) => {
  const [moduleName, categoryItems] = useMemo(() => {
    const [pageName, pageSection] = getPropertyByKey(helpStructure, section);
    const items = pageSection ? Object.entries(pageSection.topics) : undefined;
    return [pageName, items] as const;
  }, [section]);

  const [selectedArticle, selectedTopic] = useMemo(
    () => [...selectedKeys].reverse(),
    [selectedKeys],
  );

  return (
    <div className="help-module-page">
      {_.isNil(categoryItems) ? (
        <ModuleBox title={`Section not found: ${section}`} />
      ) : (
        <ModuleBox title={moduleName} onSearch={onSearch}>
          {categoryItems.map(([topic, items]) => {
            const isSelected = topic?.toLowerCase() === selectedTopic?.toLowerCase();
            return (
              <HelpTopics
                key={topic}
                module={moduleName ?? ''}
                title={topic}
                items={items}
                expandedArticle={iif(isSelected, selectedArticle)}
              />
            );
          })}
        </ModuleBox>
      )}
    </div>
  );
};

const HelpTopics = (props: {
  module: string;
  title: string;
  items: HelpStructure[0]['topics'][0];
  expandedArticle: string | undefined;
}) => {
  const { module, title, items, expandedArticle } = props;
  const itemsToShow = useMemo(() => Object.entries(items), [items]);

  return (
    <TopicsBox title={title}>
      {itemsToShow.map(([article, file]) => {
        const isSelected = article?.toLowerCase() === expandedArticle?.toLowerCase();
        return (
          <HelpArticle
            key={article}
            module={module}
            title={article}
            file={file}
            expanded={isSelected}
          />
        );
      })}
    </TopicsBox>
  );
};

const HelpArticle = (props: {
  module: string;
  title: string;
  file: string;
  expanded: boolean | undefined;
}) => {
  const expandedProp = props.expanded;
  const { module, title, file } = props;

  const [content, setContent] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleExpandedChange = async (isExpanded: boolean) => {
    setExpanded(isExpanded);

    const needFetchContent = isExpanded && !content && !loading;
    if (needFetchContent) {
      setLoading(true);
      const text = await fetchMarkdownFile(module, file);
      setContent(text ?? '');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!expandedProp) return;
    handleExpandedChange(true);

    setTimeout(
      () => contentRef.current?.scrollIntoView({ behavior: 'smooth' }),
      100,
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HelpAccordion
      className="help-module-page__article"
      header={title}
      expanded={expanded}
      onExpandedChange={handleExpandedChange}
    >
      <DivWithLoader
        ref={contentRef}
        className="help-module-page__preview thin-scrollbar"
        loading={loading}
      >
        <ReactMarkdown>{content ?? ''}</ReactMarkdown>
      </DivWithLoader>
    </HelpAccordion>
  );
};

import { ActionName, ContractActionName } from 'api/endpoints/action-names';
import {
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useEffect } from 'react';
import { markedAsStale } from 'reducer/actionNamesReducer';
import {
  creatActionNameThunk,
  editActionNameThunk,
  fetchActionNamesThunk,
  fetchContractActionNamesThunk,
  fetchContractActionsSelectOptions,
  editAContractsctionNameThunk,
  createContractActionNameThunk,
  fetchActionsSelectOptions,
} from 'reducer/thunks/actions-thunks';
import { FetchStatus } from 'types/fetch-status.types';

export type ActionNameData = {
  data: ActionName[];
  status: FetchStatus;
};

const EDIT_AND_ADD_STALED_CALLER_ID = 'edit-and-add-action-names';

export function useEditAndAddActionNames(section?: SectionType) {
  const dispatch = useAppDispatch();
  const { actionNames, status, contractsActionNames } = useActionNames({
    callerId: EDIT_AND_ADD_STALED_CALLER_ID,
    section,
  });

  const onEditSave: OnEditSave<ActionName> = useCallback(
    async (formValues, item: ActionName) => {
      const result = await dispatch(
        editActionNameThunk({
          id: item.id,
          name: formValues.name as string,
          substatusId: +formValues.substatusId,
          hearingDate: formValues.hearingDate as string,
        }),
      );
      if (result.type === FetchStatus.Done) {
        dispatch(markedAsStale({ markedById: EDIT_AND_ADD_STALED_CALLER_ID }));
      }
      return result;
    },
    [dispatch],
  );

  const onContractEditSave: OnEditSave<ContractActionName> = useCallback(
    async (formValues, item: ContractActionName) => {
      const result = await dispatch(
        editAContractsctionNameThunk({
          id: item.id,
          contractExhibitTypeId: Number(formValues.contractExhibitTypeId),
          contractActionName: formValues.contractActionName as string,
          hearingDate: formValues.hearingDate as string,
        }),
      );
      if (result.type === FetchStatus.Done) {
        dispatch(markedAsStale({ markedById: EDIT_AND_ADD_STALED_CALLER_ID }));
      }
      return result;
    },
    [dispatch],
  );

  const onAddSave: OnAddSave<ActionName> = useCallback(
    async (formValues) => {
      const result = await dispatch(
        creatActionNameThunk({
          name: formValues.name as string,
          substatusId: +formValues.substatusId,
          hearingDate: formValues.hearingDate as string,
        }),
      );
      if (result.type === FetchStatus.Done) {
        dispatch(markedAsStale({ markedById: EDIT_AND_ADD_STALED_CALLER_ID }));
      }

      return result;
    },
    [dispatch],
  );

  const onContractAddSave: OnAddSave<ContractActionName> = useCallback(
    async (formValues) => {
      const result = await dispatch(
        createContractActionNameThunk({
          contractActionName: formValues.contractActionName as string,
          hearingDate: formValues.hearingDate as string,
          contractExhibitTypeId: Number(formValues.contractExhibitTypeId),
        }),
      );
      if (result.type === FetchStatus.Done) {
        dispatch(markedAsStale({ markedById: EDIT_AND_ADD_STALED_CALLER_ID }));
      }

      return result;
    },
    [dispatch],
  );

  return {
    onEditSave,
    onAddSave,
    status,
    actionNames,
    onContractEditSave,
    onContractAddSave,
    contractsActionNames,
  };
}

export function useActionNames({
  callerId,
  section,
}: {
  callerId?: string;
  section?: SectionType;
}) {
  const actionNamesSlice = useAppSelector((s) => s.actionNames);
  const {
    data,
    type: claimStatus,
    contractsData,
    contractsStatus,
  } = actionNamesSlice;
  const dispatch = useAppDispatch();

  const isIdle =
    (section === SectionType.Contracts && contractsStatus === FetchStatus.Idle) ||
    (section === SectionType.Claims && claimStatus === FetchStatus.Idle);

  useEffect(() => {
    if (isIdle) {
      if (section !== SectionType.Contracts) dispatch(fetchActionNamesThunk());
      else dispatch(fetchContractActionNamesThunk());
    } else if (claimStatus === FetchStatus.Stale) {
      const { markedById } = actionNamesSlice;
      if (markedById === undefined || markedById !== callerId) {
        if (section !== SectionType.Contracts) dispatch(fetchActionNamesThunk());
        else dispatch(fetchContractActionNamesThunk());
      }
    }
  }, [
    actionNamesSlice,
    callerId,
    dispatch,
    claimStatus,
    section,
    contractsData,
    isIdle,
  ]);

  return {
    status: claimStatus,
    actionNames: data,
    contractsActionNames: contractsData,
  };
}

export function useActionsSelectOptions(section: SectionType) {
  const actionsSelectOptions = useAppSelector((s) => s.actionsSelectOptions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (actionsSelectOptions.type === FetchStatus.Idle) {
      if (section === SectionType.Claims) dispatch(fetchActionsSelectOptions());
      if (section === SectionType.Contracts)
        dispatch(fetchContractActionsSelectOptions());
    }
  }, [actionsSelectOptions.type, dispatch, section]);

  return {
    actionNameOptions: actionsSelectOptions,
    status: actionsSelectOptions.type,
  };
}

export enum CategorySelectType {
  Category = 'contractCategories',
  ContractType = 'contractTypes',
  LegalEntities = 'LegalEntities',
  ContractReviewer = 'ContractReviewer',
  ContractActionType = 'ContractActionType',
  ContractActionSubstatus = 'ContractActionSubstatus',
  ContractActionName = 'ActionName',
  ContractStatus = 'ContractStatus',
  PaymentPosition = 'PaymentPosition',
  Priority = 'priority',
  NonDebtorGuarantee = 'nonDebtorGuarantee',
  AutoRenewal = 'autoRenewal',
  ExhibitTypeId = 'exhibitTypeId',
  ClientApprovalStatus = 'clientApprovalStatus',
  ApUserApprovalStatus = 'apUserApprovalStatus',
}

export interface CategorySelectMap {
  [k: string]:
    | 'contractTypes'
    | 'contractCategories'
    | 'contractStatuses'
    | 'legalEntities'
    | 'contractExhibitTypes';
}

export const CATEGORY_SELECT_MAP: CategorySelectMap = {
  [CategorySelectType.Category]: 'contractCategories',
  [CategorySelectType.ContractType]: 'contractTypes',
  [CategorySelectType.ContractStatus]: 'contractStatuses',
  [CategorySelectType.LegalEntities]: 'legalEntities',
  [CategorySelectType.ExhibitTypeId]: 'contractExhibitTypes',
};

export const OPTIONS_VALUE_MAP: any = {
  [CategorySelectType.Category]: 'category',
  [CategorySelectType.ContractType]: 'contractType',
  [CategorySelectType.ContractStatus]: 'contractStatus',
  [CategorySelectType.ContractActionName]: 'actionNameOptions',
  [CategorySelectType.ContractActionSubstatus]: 'substatusOptions',
  [CategorySelectType.ContractActionType]: 'actionTypeOptions',
};

export const ACTION_LABEL_MAP: any = {
  [CategorySelectType.ContractActionName]: 'contractActionName',
  [CategorySelectType.ContractActionSubstatus]: 'contractActionSubStatusName',
  [CategorySelectType.ContractActionType]: 'contractActionTypeName',
};

export const ACTION_VALUE_MAP: any = {
  [CategorySelectType.ContractActionName]: 'contractActionNameId',
  [CategorySelectType.ContractActionSubstatus]: 'contractActionSubStatusId',
  [CategorySelectType.ContractActionType]: 'contractActionTypeId',
};

export const CONTRACT_STATUS_MAP = {
  AwaitingReview: 'Awaiting review',
  UnderReview: 'Under review',
  Assessed: 'Assessed',
};

export const BOOLEAN_OPTIONS = [
  { label: 'Please select', value: '' },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const PRIORITY_OPTIONS = [
  { label: 'High', value: 'High', id: 1 },
  { label: 'Medium', value: 'Medium', id: 2 },
  { label: 'Low', value: 'Low', id: 3 },
];

export const CONTRACT_STATUSES = [
  // these values will not change so we're using a static list
  { id: 0, contractStatus: '' },
  { id: 1, contractStatus: 'Awaiting Review' },
  { id: 2, contractStatus: 'Under Review' },
  { id: 3, contractStatus: 'Assessed' },
];

export enum ApprovalStatus {
  NotReadyForAlixPartnersApprovalAP = 1,
  ReadyForAlixPartnersApproval = 2,
  AlixPartnersApproved = 3,
  NotReadyForClientApproval = 4,
  ReadyForClientApproval = 5,
  ClientApproved = 6,
}

export const APROVAL_STATUSES_CLIENT = [
  {
    label: 'Not Ready for Client Approval',
    value: ApprovalStatus.NotReadyForClientApproval,
  },
  {
    label: 'Ready For Client Approval',
    value: ApprovalStatus.ReadyForClientApproval,
  },
  {
    label: 'Client Approved',
    value: ApprovalStatus.ClientApproved,
  },
];

export const APROVAL_STATUSES_APUSER = [
  {
    label: 'Not Ready for AlixPartners Approval',
    value: ApprovalStatus.NotReadyForAlixPartnersApprovalAP,
  },
  {
    label: 'Ready For AlixPartners Approval',
    value: ApprovalStatus.ReadyForAlixPartnersApproval,
  },
  {
    label: 'AlixPartners Approved',
    value: ApprovalStatus.AlixPartnersApproved,
  },
];

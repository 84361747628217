import { DataPipelineStatusResponse, ProcessingStatus } from 'api/endpoints';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import './DataUploadProgress.css';

interface Props {
  pipeline: DataPipelineStatusResponse | undefined;
  isPublishing: boolean;
}

export const DataUploadProgress = ({ pipeline, isPublishing }: Props) => {
  // right after publish button is pressed the "pipeline" value might be undefined for a few seconds
  if (isPublishing && pipeline?.processingStatus !== ProcessingStatus.InProgress) {
    return <DataUploadPublishStartMessage />;
  }

  switch (pipeline?.processingStatus) {
    case ProcessingStatus.InProgress:
      return <DataUploadProgressState pipeline={pipeline} />;

    case ProcessingStatus.ApplicationException:
      return (
        <DataUploadError
          title={'Application Exception'}
          message={pipeline.message}
        />
      );

    case ProcessingStatus.Failed:
      return <DataUploadError title={'Publish failed'} message={pipeline.message} />;

    default:
      return null;
  }
};

export const DataUploadError = (props: {
  title?: string;
  message?: React.ReactChild;
}) => {
  const { title, message } = props;
  return (
    <div className="data-upload-progress data-upload-progress__error">
      <div>
        <div className="data-upload-progress__err-image">
          <SvgIcon
            iconName="alert"
            width={32}
            height={32}
            fill="var(--ap-color-ui-red-alert)"
          />
        </div>
        <div className="data-upload-progress__text">
          {title && <p className="data-upload-progress__title">{title}</p>}
          <p className="data-upload-progress__footer">{message}</p>
        </div>
      </div>
    </div>
  );
};

export const DataUploadProgressState = (props: {
  pipeline: DataPipelineStatusResponse;
}) => {
  const { currentStep, totalSteps, message } = props.pipeline;

  return (
    <div className="data-upload-progress">
      <div>
        <div className="data-upload-progress__meter">
          <img alt="processing" src="img/datauploader/uploading.gif" />
        </div>
        <div className="data-upload-progress__text">
          <p className="data-upload-progress__title">
            {totalSteps > 2 ? (
              <>
                Step {currentStep} of {totalSteps}.{' '}
              </>
            ) : null}
            {message}
          </p>
          <p className="data-upload-progress__footer">
            Please wait while your files are being processed. This typically takes up
            to 10 minutes but may take longer for a particularly large dataset.
          </p>
        </div>
      </div>
    </div>
  );
};

export const DataUploadPublishStartMessage = () => {
  return (
    <DataUploadProgressState
      pipeline={{
        processingStatus: ProcessingStatus.InProgress,
        currentStep: 0,
        totalSteps: 0,
        message: 'Processing',
      }}
    />
  );
};

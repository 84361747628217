import {
  ApAuthenticationContextProvider,
  REDIRECT_URL,
} from '@alixpartners/ui-utils';
import { URL_PATH } from 'appUrls';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

type AuthProps = React.ComponentProps<typeof ApAuthenticationContextProvider>;

interface Props {
  errorComponent: AuthProps['errorComponent'];
  children: JSX.Element;
}

export const AppAuthenticationProvider = ({ errorComponent, children }: Props) => {
  return (
    <ApAuthenticationContextProvider errorComponent={errorComponent}>
      <SigninRedirectToInitialPage>{children}</SigninRedirectToInitialPage>
    </ApAuthenticationContextProvider>
  );
};

const SigninRedirectToInitialPage = (props: { children: JSX.Element }) => {
  useEffect(() => {
    redirectUserToInitialPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSigninPage = window.location.pathname === REDIRECT_URL;
  return isSigninPage ? null : props.children;
};

const redirectUserToInitialPage = () => {
  const initialPageUrl = Cookies.get('redirect');
  Cookies.remove('redirect'); // Important!!! "redirect" cookie must be removed despite of the page

  const isSigninPage = window.location.pathname === REDIRECT_URL;
  if (!isSigninPage) return;

  //to prevent infinite redirect loop we do check (initialPageUrl !== 'REDIRECT_URL')
  const isValidInitialPageUrl = initialPageUrl && initialPageUrl !== REDIRECT_URL;
  const redirectTo = isValidInitialPageUrl ? initialPageUrl : URL_PATH.HOME;

  window.location.replace(redirectTo);
};

import _ from 'lodash';
import { TAllowedMajMin } from 'pages/ClaimsContractsToolPage/sections/ClaimsTable/ClaimsBatchEdit/claimsBatchEdit.type';
import { useCallback, useMemo, useState } from 'react';

import { ItemEditEnumarable } from './ItemEditEnumarable';

interface IProps {
  value: string | undefined;
  allowedItems: TAllowedMajMin | undefined;
  onChange: (value: string) => void;
}
export const ItemEditMinorMajor = ({ value, allowedItems, onChange }: IProps) => {
  const [selMajor, setSelMajor] = useState('');

  const optionsMajor = useMemo(() => {
    const keys = _.keys(allowedItems);
    return keys.reduce((a, v) => ({ ...a, [v]: v }), {});
  }, [allowedItems]);

  const optionsMinor = useMemo(
    () => allowedItems?.[selMajor],
    [allowedItems, selMajor],
  );

  const handleMajorChange = useCallback((val: string) => {
    setSelMajor(val);
  }, []);

  return (
    <>
      <ItemEditEnumarable
        value={selMajor}
        label="Major category"
        allowedItems={optionsMajor}
        onChange={handleMajorChange}
      />
      <ItemEditEnumarable
        value={value}
        label="Minor value"
        allowedItems={optionsMinor}
        onChange={onChange}
      />
    </>
  );
};

import { ApSelect } from '@alixpartners/ui-components';
import { useReportLegalEntities } from '../../Domain/useReportLegalEntities';

import { useEffect, useMemo } from 'react';
import './PreviewHeader.css';

const emptyCharacter = String.fromCharCode(160);
const sofaParts = [
  { label: 'All', value: 'All' },
  { label: 'Part 1', value: '1' },
  { label: 'Part 2', value: '2' },
  { label: 'Part 3', value: '3' },
  { label: 'Part 4', value: '4' },
  { label: 'Part 5', value: '5' },
  { label: 'Part 6', value: '6' },
  { label: 'Part 7', value: '7' },
  { label: 'Part 8', value: '8' },
  { label: 'Part 9', value: '9' },
  { label: 'Part 10', value: '10' },
  { label: 'Part 11', value: '11' },
  { label: 'Part 12', value: '12' },
  { label: 'Part 13A', value: '13a' },
  { label: 'Part 13B', value: '13b' },
  { label: 'Signature', value: '14' },
];
const scheduleParts = [
  { label: 'All', value: 'All' },
  { label: 'Part AB1', value: 'AB1' },
  { label: 'Part AB2', value: 'AB2' },
  { label: 'Part AB3', value: 'AB3' },
  { label: 'Part AB4', value: 'AB4' },
  { label: 'Part AB5', value: 'AB5' },
  { label: 'Part AB6', value: 'AB6' },
  { label: 'Part AB7', value: 'AB7' },
  { label: 'Part AB8', value: 'AB8' },
  { label: 'Part AB9', value: 'AB9' },
  { label: 'Part AB10', value: 'AB10' },
  { label: 'Part AB11', value: 'AB11' },
  { label: 'Part AB12', value: 'AB12' },
  { label: 'Part D1', value: 'D1' },
  { label: 'Part D2', value: 'D2' },
  { label: 'Part EF1', value: 'EF1' },
  { label: 'Part EF2', value: 'EF2' },
  { label: 'Part EF3', value: 'EF3' },
  { label: 'Part EFS', value: 'EFS' },
  { label: 'Part G', value: 'G' },
  { label: 'Summary', value: 'summary' },
  { label: 'Signature', value: 'signature' },
];

interface Props {
  type: string | undefined;
  legalEntityId: string | undefined;
  part: string | undefined;
  onChangeLegalEntity: (id: string) => void;
  onChangePart: (id: string) => void;
}

export const PreviewHeader = ({
  legalEntityId,
  onChangeLegalEntity,
  part,
  onChangePart,
  type,
}: Props) => {
  const { data: legalEntity } = useReportLegalEntities();

  const options = useMemo(() => {
    const result = legalEntity?.map((r) => ({
      label: r.entityName,
      value: r.legalEntityId.toString(),
    }));

    return result ?? [];
  }, [legalEntity]);

  useEffect(() => {
    // set LegalEntity initial value
    if (!!legalEntityId) return;

    if (!!options?.length) {
      onChangeLegalEntity(options[0].value);
    }
  }, [options, legalEntityId, onChangeLegalEntity]);

  return (
    <div className="preview-sub-header">
      <div className="preview-sub-header__label">Select Legal Entity: </div>
      <ApSelect
        name="select-part"
        value={legalEntityId}
        options={options}
        placeholder={emptyCharacter}
        onChange={onChangeLegalEntity}
      />
      <div className="preview-sub-header__label">Part: </div>
      <ApSelect
        name="select-part"
        value={part}
        options={type === 'sofa' ? sofaParts : scheduleParts}
        placeholder={emptyCharacter}
        onChange={onChangePart}
      />
    </div>
  );
};

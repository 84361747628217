import Excel, { Column } from 'exceljs';
import { createExcelWorkbook, getSortedSheetItems } from './excelUtils';
import {
  columnDefaults,
  ContractExhibitExport,
  IExhibitExcelCreator,
} from './templatesType';
import { ContractRow } from 'api/endpoints';
import { formatDateAs } from 'utils/formatDate';

const COLUMN_LIST: Partial<Column>[] = [
  { header: 'Contract Number', width: 15, ...columnDefaults },
  { header: 'Counterparty Name', width: 30, ...columnDefaults },
  { header: 'Address1', width: 30, ...columnDefaults },
  { header: 'Address2', width: 30, ...columnDefaults },
  { header: 'Address3', width: 30, ...columnDefaults },
  { header: 'City', width: 30, ...columnDefaults },
  { header: 'State', width: 30, ...columnDefaults },
  { header: 'Zip', width: 30, ...columnDefaults },
  { header: 'Country', width: 30, ...columnDefaults },
  { header: 'Leased Property Address1', width: 30, ...columnDefaults },
  { header: 'Leased Property Address2', width: 30, ...columnDefaults },
  { header: 'Leased Property Address3', width: 30, ...columnDefaults },
  { header: 'Leased Property City', width: 30, ...columnDefaults },
  { header: 'Leased Property State', width: 30, ...columnDefaults },
  { header: 'Leased Property Zip', width: 30, ...columnDefaults },
  { header: 'Leased Property Country', width: 30, ...columnDefaults },
  { header: 'Debtor', width: 30, ...columnDefaults },
  { header: 'Cure Amount', width: 30, ...columnDefaults },
];

const SORT_KEY: keyof ContractRow = 'counterpartyName';

export const templateRealEstateRejection: IExhibitExcelCreator = {
  getWorkbook: (exhibit: ContractExhibitExport): Excel.Workbook | null => {
    const { includeRejDate } = exhibit;
    const workbook = createExcelWorkbook(
      'Real Estate Rejection',
      includeRejDate
        ? [
            ...COLUMN_LIST,
            { header: 'Rejection Date', width: 30, ...columnDefaults },
          ]
        : [...COLUMN_LIST],
    );
    const firstSheet = workbook.getWorksheet(1);

    const sheetItems = getSortedSheetItems(exhibit.contracts, SORT_KEY).map((r) =>
      mapContractToExcelRow(r, includeRejDate),
    );

    sheetItems.forEach((item) => firstSheet.addRow(item));

    return workbook;
  },
};

const mapContractToExcelRow = (row: ContractRow, includeRejDate?: boolean) => {
  return [
    row.referenceNumber,
    row.counterpartyName,
    row.address1,
    row.address2,
    row.address3,
    row.city,
    row.state,
    row.zip,
    row.country,
    row.leasedPropertyAddress1,
    row.leasedPropertyAddress2,
    row.leasedPropertyAddress3,
    row.leasedPropertyCity,
    row.leasedPropertyState,
    row.leasedPropertyZip,
    row.leasedPropertyCountry,
    row.primaryDebtor,
    row.negotiatedCureAmount,
    includeRejDate ? formatDateAs(row.rejectionDate, 'MM-DD-YYYY') : undefined,
  ];
};

import { PreviousExhibitsDropdown } from 'components/ExhibitsExport/PreviousExhibitsDropdown';
import './ExhibitExportPanel.css';

type Props = React.PropsWithChildren<{
  title: string;
  showHistory?: boolean;
  historyElement?: React.ReactElement;
  onShowHistoryClick: () => void;
}>;

export const ExhibitExportPanel = ({
  title,
  showHistory,
  historyElement,
  children,
  onShowHistoryClick,
}: Props) => {
  return (
    <div className="exhibit-exporter-panel__container">
      <div className="exhibit-exporter-panel">
        <header>
          <div className="exhibit-exporter-panel__top">
            <div className="exhibit-exporter-panel__title">{title}</div>
            <div className="exhibit-exporter-panel__right-side">
              <PreviousExhibitsDropdown toggleExhibitHistory={onShowHistoryClick} />
            </div>
          </div>
          {showHistory && historyElement}
        </header>
        <section>{children}</section>
      </div>
    </div>
  );
};

import { Route, Routes } from 'react-router';
import { NavLink } from 'react-router-dom';
import './Signatories.css';
import { SignatoriesDraw } from './SignatoriesDraw';

export function Signatories() {
  return (
    <div className="sofas-schedules__content sofas-schedules__content-signatories">
      <section className="signatories">
        <div className="signatories__panel">
          <h4 className="signatories-title">Signatories</h4>
          <div className="signatories-text signatories__tabs">
            <span className="signatories-subtle-text">Add your eSignature</span>
            <NavLink className={createNavTabCN} relative="route" to="draw">
              Draw
            </NavLink>
            <NavLink className={createNavTabCN} relative="route" to="type">
              Type
            </NavLink>
            <NavLink className={createNavTabCN} relative="route" to="upload">
              Upload
            </NavLink>
          </div>
          <Routes>
            <Route path="/draw" element={<SignatoriesDraw />} />
            <Route path="/type" element={<div>Type</div>} />
            <Route path="/upload" element={<div>Upload</div>} />
          </Routes>
        </div>
      </section>
    </div>
  );
}

function createNavTabCN({ isActive }: { isActive: boolean }) {
  return isActive ? 'signatories__tab-active' : '';
}

import { RolePermission } from './RolePermission';

export interface ClientUserRoleProps {
  children?: React.ReactNode;
}

export const ClientUserRole = ({ children }: ClientUserRoleProps) => (
  <RolePermission roles={['ClientApprover', 'ClientProposer']}>
    {children}
  </RolePermission>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReviewersBatchItem } from '../components/ReviewersEdit/ReviewersEdit.utils';

const CLAIM_REVIEWER_DEFAULT_STATE = {
  claimId: '',
  userGroupReviewers: [],
  userReviewers: [] as any,
};
export const initialState: ClaimReviewerReducerState = {
  loading: true,
  data: CLAIM_REVIEWER_DEFAULT_STATE,
};

export interface ClaimReviewerData {
  claimId: string;
  userGroupReviewers: any;
  userReviewers: number[];
}

export interface ClaimReviewerReducerState {
  data: ClaimReviewerData;
  error?: string;
  loading: boolean;
}

const claimReviewerSlice = createSlice({
  name: 'claimReviewer',
  initialState,
  reducers: {
    claimReviewerFetch(state) {
      state.loading = true;
    },
    claimReviewerLoaded(state, action: PayloadAction<ClaimReviewerData>) {
      state.loading = false;
      state.data.userReviewers = action.payload.userReviewers.map(
        (user: any) => user.id,
      );
      state.data.userGroupReviewers = action.payload.userGroupReviewers.map(
        (group: any) => group.id,
      );
    },
    setClaimReviewers(state, action: PayloadAction<ReviewersBatchItem>) {
      state.data.userGroupReviewers = action.payload.claimReviewerUserGroupIds;
      state.data.userReviewers = action.payload.claimReviewerUserNames;
    },
  },
});

export const { claimReviewerFetch, claimReviewerLoaded, setClaimReviewers } =
  claimReviewerSlice.actions;
export default claimReviewerSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupUsersItem } from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';

export type UserGroupsSlice =
  | {
      status: FetchStatus.Idle;
      data: null;
      error: null;
    }
  | {
      status: FetchStatus.Done;
      data: GroupUsersItem[];
      error: null;
    }
  | {
      status: FetchStatus.Error;
      error: { message: string };
      data: GroupUsersItem[];
    }
  | {
      status: FetchStatus.Fetching;
      data: GroupUsersItem[];
      error: null;
    };

export const initialState: UserGroupsSlice = {
  status: FetchStatus.Idle,
  error: null,
  data: null,
} as UserGroupsSlice;

const userGroups = createSlice({
  name: 'user-groups',
  initialState: initialState,
  reducers: {
    fetching(state: UserGroupsSlice) {
      state.status = FetchStatus.Fetching;
    },
    done(state: UserGroupsSlice, action: PayloadAction<GroupUsersItem[]>) {
      state.status = FetchStatus.Done;
      state.data = action.payload;
    },
    error(state: UserGroupsSlice, action: PayloadAction<{ message: string }>) {
      state.status = FetchStatus.Error;
      state.error = action.payload;
    },
    added(state: UserGroupsSlice, action: PayloadAction<GroupUsersItem[]>) {
      state.data = action.payload;
    },
    edited(state: UserGroupsSlice, action: PayloadAction<GroupUsersItem>) {
      if (!state.data) return;
      const existingIndex = state.data.findIndex((x) => x.id === action.payload.id);
      if (existingIndex > -1) {
        state.data[existingIndex] = action.payload;
      }
    },
  },
});

export const userGroupsActions = userGroups.actions;
export default userGroups.reducer;

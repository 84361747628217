import { EMPTY_OPTION_VALUE } from 'components/ApNiceSelect';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback } from 'react';
import {
  actionNameIdSet,
  actionSubstatusIdSet,
  actionTypeIdSet,
  actionStatusSet,
} from 'reducer/claimActionNameSelectionReducer';
import {
  contractActionTypeIdSet,
  contractActionNameIdSet,
  contractActionSubstatusIdSet,
} from 'reducer/contractActionNameReducer';
import { useAppDispatch } from './reducerHooks';

export function useEditActionField(section: SectionType) {
  const dispatch = useAppDispatch();

  const editActionField = useCallback(
    (field: string, origValue: string) => {
      if (!origValue) return;
      let value: number | undefined = +origValue;
      if (origValue === EMPTY_OPTION_VALUE) {
        value = undefined;
      }
      if (section === SectionType.Claims) {
        if (field === 'actionTypeId') {
          dispatch(actionTypeIdSet({ value }));
        } else if (field === 'actionSubstatusId') {
          dispatch(actionSubstatusIdSet({ value }));
        } else if (field === 'actionNameId') {
          dispatch(actionNameIdSet({ value }));
        } else if (field === 'actionStatus') {
          dispatch(actionStatusSet(origValue));
        }
      } else if (section === SectionType.Contracts) {
        if (field === 'contractActionTypeId') {
          dispatch(contractActionTypeIdSet({ value }));
        } else if (field === 'contractActionSubStatusId') {
          dispatch(contractActionSubstatusIdSet({ value }));
        } else if (field === 'contractActionNameId') {
          dispatch(contractActionNameIdSet({ value }));
        }
      }
    },
    [dispatch, section],
  );

  const resetActionFields = useCallback(() => {
    if (section === SectionType.Claims) {
      dispatch(actionTypeIdSet({ value: undefined }));
      dispatch(actionSubstatusIdSet({ value: undefined }));
      dispatch(actionNameIdSet({ value: undefined }));
    } else if (section === SectionType.Contracts) {
      dispatch(contractActionTypeIdSet({ value: undefined }));
      dispatch(contractActionNameIdSet({ value: undefined }));
      dispatch(contractActionSubstatusIdSet({ value: undefined }));
    }
  }, [dispatch, section]);

  return {
    editActionField,
    resetActionFields,
  };
}

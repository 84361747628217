import { getQuiet, post, postQuiet, get } from '../apiRequest';

export enum ProcessingStatus {
  None = 'None',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  ApplicationException = 'ApplicationException',
  Unpublished = 'Unpublished',
}

export interface DataPipelineStatusResponse {
  processingStatus: ProcessingStatus | undefined;
  currentStep: number;
  totalSteps: number;
  message: string;
}

export interface DataPipelineTriggerRequest {}

export interface DataUploadDuplicate {
  entityName: string;
  row: number;
  part: string;
}

export interface FileComparisonResponse {
  duplicateCount: number;
  add: number;
  delete: number;
  duplicates: DataUploadDuplicate[];
}

export const dataPipeline = {
  compareSofaFile: (fileId: number) =>
    get<FileComparisonResponse>(`v1/DataPipeline/file/sofa/${fileId}/compare`),
  compareScheduleFile: (fileId: number) =>
    get<FileComparisonResponse>(`v1/DataPipeline/file/schedule/${fileId}/compare`),
  // we use "quiet" request because it can be repeatedly called every second
  getStatusQuiet: () =>
    getQuiet<DataPipelineStatusResponse>('v1/DataPipeline/status'),

  postTrigger: (module: string) => post(`v1/DataPipeline/trigger/${module}`, null),
  postTriggerReset: () => post('v1/DataPipeline/trigger-reset', null),
  postTriggerResetModule: (moduleToReset: string) =>
    postQuiet(`/v1/DataPipeline/trigger-reset-${moduleToReset}`, null),
};

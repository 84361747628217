import { reportLegalEntities, reportParts } from 'api/endpoints';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { reportLegalEntitiesActions } from '../reportLegalEntitiesReducer';
import { reportPartsActions } from '../reportPartsReducer';
import { AppDispatch, GetState } from '../store';

export const fetchReportLegalEntitiesThunk =
  () => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(reportLegalEntitiesActions.fetching());
    const r = await reportLegalEntities.get();
    if (r.type === ResponseType.OK) {
      dispatch(reportLegalEntitiesActions.done({ items: r.data }));
    } else {
      dispatch(
        reportLegalEntitiesActions.error({
          message: 'Unable to fetch the legal entities to select for reports.',
        }),
      );
    }
  };

export const fetchReportPartsThunks =
  () => async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(reportPartsActions.fetching());
    const { data } = await reportParts.getList();
    if (data) {
      dispatch(reportPartsActions.done({ items: data }));
    } else {
      dispatch(
        reportPartsActions.error({
          message: 'Unable to fetch the report parts.',
        }),
      );
    }
  };

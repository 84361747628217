import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FileState,
  StatusState,
  UploadProgressState,
} from 'pages/DocumentsPage/types';

type State = {
  fileState: FileState;
  statusState: StatusState;
  uploadProgress: UploadProgressState;
};

const initialState: State = {
  fileState: {
    zipFilesUploading: 0,
    imageFilesUploading: 0,
    mappingFileUploading: false,
    unzipping: false,
    unzippingTotalFiles: 0,
    unzippingProcessedFiles: 0,
  },
  statusState: {
    mappingStatus: undefined,
    docUnzipStatus: undefined,
  },
  uploadProgress: {
    progress: 0,
    currentItemUploading: 0,
  },
};

const documentsReducer = createSlice({
  name: 'documentsReducer',
  initialState,
  reducers: {
    setFileState: (state: State, action: PayloadAction<Partial<FileState>>) => {
      state.fileState = { ...state.fileState, ...action.payload };
    },
    setStatusState: (state: State, action: PayloadAction<Partial<StatusState>>) => {
      state.statusState = { ...state.statusState, ...action.payload };
    },
    setUploadProgressState: (
      state: State,
      action: PayloadAction<UploadProgressState>,
    ) => {
      state.uploadProgress = { ...action.payload };
    },
    resetState: (state: State) => {
      state.fileState = { ...initialState.fileState };
      state.statusState = { ...initialState.statusState };
      state.uploadProgress = { ...initialState.uploadProgress };
    },
  },
});
export const { setFileState, setStatusState, setUploadProgressState, resetState } =
  documentsReducer.actions;

export default documentsReducer.reducer;

import {
  GroupMultiSelect,
  InternalOption,
} from 'components/UIComponents/GroupMultiSelect';
import {
  Group,
  MainLabelType,
} from 'components/UIComponents/GroupMultiSelect/GroupMultiSelect.types';
import { optionsToMainLabel } from 'components/UIComponents/GroupMultiSelect/GroupMultiSelect.utils';
import { useCallback, useMemo } from 'react';

import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { UserInfo } from '../../api/endpoints';
import {
  assignedGroupUsersToSelectGroups,
  ReviewersGroupChecked,
  selectOptionsToBatchItem,
} from './ReviewersEdit.utils';

const SHOW_SELECTED_MAX = 3;
const LABEL_MAX_LENGTH = 60;

export interface ClaimReviewersValues {
  claimReviewerUserGroupIds: number[];
  claimReviewerUserIds: number[];
}

export interface ContractReviewersValues {
  contractReviewerUserGroupIds: number[];
  contractReviewerUserIds: number[];
}

export const ReviewersEditLabel: MainLabelType = (props) => {
  const { selected, className, placeholder, loading } = props;
  const placeholderText = useMemo(() => {
    if (loading) {
      return 'Loading...';
    }

    const count = selected.length;
    if (count <= 0) {
      return placeholder;
    }

    const defaultResult = `${count} values selected`;
    if (count > SHOW_SELECTED_MAX) {
      return defaultResult;
    }

    const itemsList = optionsToMainLabel(selected, placeholder);
    //Bug quick fix - "text-overflow: ellipsis;"" doesn't work for label.
    //todo: We have to fix it by CSS properties. (or remove the GroupMultiSelect component at all )
    if (itemsList!.length > LABEL_MAX_LENGTH) {
      return defaultResult;
    }

    return itemsList;
  }, [selected, placeholder, loading]);

  return (
    <span className={`${{ className }}${loading ? ' gms-main-label--loading' : ''}`}>
      {placeholderText}
    </span>
  );
};

export type ReviewersEditProps = {
  users: UserInfo[];
  groups: ReviewersGroupChecked[];
  loading: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (item: any) => void;
  section: SectionType | undefined;
};

export const ReviewersEdit = ({
  users = [],
  groups = [],
  loading,
  disabled,
  label,
  placeholder,
  onChange,
  section,
}: ReviewersEditProps) => {
  const selectGroups: Group[] = useMemo(
    () => (!loading ? assignedGroupUsersToSelectGroups(groups, users) : []),
    [users, groups, loading],
  );

  const handleSubmit = useCallback(
    (selected: InternalOption[]) => {
      const batchItem = selectOptionsToBatchItem(selected, section);
      onChange(batchItem);
    },
    [onChange, section],
  );

  return (
    <GroupMultiSelect
      loading={loading}
      label={label}
      groups={selectGroups}
      hasSearch
      mainLabelComponent={ReviewersEditLabel}
      disabled={loading || disabled}
      onSubmit={handleSubmit}
      placeholder={placeholder}
    />
  );
};

import { fromCamelCase } from 'utils/format';

function inputValueFormatter(value: string, field: string) {
  if (!value) return '';
  if (field === 'actionReview') {
    const result = fromCamelCase(value);
    return result.replace('Alix Partners', 'AlixPartners');
  }
  return value;
}

export default inputValueFormatter;

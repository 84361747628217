import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';
import { SectionItem } from 'components/ClaimsMenuSectionTabs';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export const VENDOR_SECTIONS: SectionItem[] = [
  {
    type: SectionType.Dashboard,
    label: 'Dashboard',
  },
];

export const VendorsHeaderTabs = ({
  section,
  handleSectionBtnClick,
}: {
  section: SectionType;
  handleSectionBtnClick: (section: SectionItem) => void;
}) => {
  return (
    <div className="vendors-header-tabs">
      <ApButtonGroup justifyContent="initial" spacingSize={0}>
        {VENDOR_SECTIONS.map((s) => (
          <ApButtonTab
            key={s.type}
            autoFocus={false}
            disabled={s.disabled}
            isSelected={section === s.type}
            onClick={() => handleSectionBtnClick(s)}
            theme="light"
          >
            {s.label}
          </ApButtonTab>
        ))}
      </ApButtonGroup>
    </div>
  );
};

import { ContractRow } from 'api/endpoints';

export interface ContractExhibit {
  typeId?: number;
  actionNameId?: number;
  contracts: ContractRow[];
}

export enum ContractExhibitType {
  NonRealEstateAssumption = 1,
  RealEstateAssumption = 2,
  NonRealEstateRejection = 3,
  RealEstateRejection = 4,
}

export type ContractExhibitOptions = {
  includeRejDate?: boolean;
};

export interface ExhibitContractRow {}

import { ApIcon, ApMenu, ApMenuItem } from '@alixpartners/ui-components';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

export const MultiSelectLabelText = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? 'var(--ap-color-grey5})' : 'var(--ap-color-grey8})'};
`;

export const ApMenuItemStyled = styled(ApMenuItem)<{ level?: number }>`
  padding-left: ${({ level }) => `${16 + level * 20}px`};
`;

export const ApMenuStyled = styled(ApMenu)<{ width?: number }>`
  width: ${({ width }) => (!isNil(width) ? `${width}px` : '100%')};
`;

export const CheckIcon = styled(ApIcon)<{ visible?: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

import Excel, { AddWorksheetOptions, Column } from 'exceljs';

export type ExcelDocumentHeader = Pick<Column, 'header' | 'width'>;

const pageOptions: Partial<AddWorksheetOptions> = {
  pageSetup: {
    paperSize: undefined, // letter
    orientation: 'landscape',
    margins: {
      left: 0.2,
      right: 0.2,
      top: 0.2,
      bottom: 0.75,
      header: 0,
      footer: 0.3,
    },
  },
  views: [{ showGridLines: false, style: 'pageLayout' }],
};

export const createExcelWorkbook = (
  sheetName: string,
  columns: ExcelDocumentHeader[],
) => {
  const workbook = new Excel.Workbook();
  const firstSheet = workbook.addWorksheet(sheetName, pageOptions);

  const columnsWIdth = columns.map((r) => ({ width: r.width }));
  firstSheet.columns = columnsWIdth;
  firstSheet.pageSetup.printTitlesRow = '1:1';

  return workbook;
};

import classnames from 'classnames';
import { ComponentProps } from 'react';
import './SingleViewPanel.css';

interface Props extends ComponentProps<'div'> {
  title?: string;
}

export const SingleViewPanel = ({ title, className, children, ...props }: Props) => {
  return (
    <div className={'single-view-panel'} {...props}>
      {title && <SingleViewPanelTitle title={title} />}
      <div className={classnames('single-view-panel__body', className)}>
        {children}
      </div>
    </div>
  );
};

export const SingleViewPanelTitle = ({
  title,
  className,
  children,
  ...props
}: Props) => {
  return (
    <div className={classnames('single-view-panel__title', className)} {...props}>
      {title}
    </div>
  );
};

import { IServerSideDatasource } from 'ag-grid-community';
import { ReportLegalEntity } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import _ from 'lodash';
import { convertDateValues } from 'pages/ClaimsContractsToolPage/util/datify';

import { createGraphQLQueryFromParams } from 'utils/agGridHotchocolate/createGraphQLQueryFromParams';
import {
  IServerSideGetRowsParamsWithFilterModel,
  IServerSideGetRowsRequestWithFilterModel,
} from 'utils/agGridHotchocolate/types';
import { getRequestColumns } from 'utils/columns';
import { notEmpty } from 'utils/object';
import { ISofaTableProps } from '../tables.types';
import { getGridColumnObject } from './Columns/getGridColumnObj';
import { graphQlFunctionName } from './graphQlUtils/graphQlEntity';

const requestColumnsVariableName = 'items';
const totalCountVariableName = 'totalCount';

export const createSofasGridDataSource = (
  client: GraphQlApolloClient,
  sofaTable: ISofaTableProps,
  legalEntitiesIds: ReportLegalEntity[],
): IServerSideDatasource => {
  const legalEntitiesKeys = _(legalEntitiesIds)
    .keyBy('entityName')
    .mapValues('legalEntityId')
    .value();

  return {
    getRows: async (params: IServerSideGetRowsParamsWithFilterModel) => {
      const gridColumns = getGridColumnObject(sofaTable);
      if (!gridColumns) return;

      // get data for request from server
      const requestColumns = getRequestColumns(params, gridColumns);
      const field = graphQlFunctionName(sofaTable, 'get');

      const booleanColumns = gridColumns
        .filter((c) => c.dataType === 'bool')
        .map((c) => c.propertyName);

      let request = params.request;
      request = filterSetValueMapper(request, 'yearCategory', mapStringToNumber);
      request = filterSetValueMapper(
        request,
        'legalEntityId',
        mapLegalEntitiesIds(legalEntitiesKeys),
      );

      const { query, variables } = createGraphQLQueryFromParams(
        request,
        requestColumns,
        {
          queryName: field.name,
          graphqlEntity: field.field,
          showMine: false,
          applicationUserId: undefined,
          booleanColumns,
          gridColumns,
        },
      );

      const response = await client.query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      });
      const data = response.data[field.field];

      if (!data) {
        params.fail();
        return;
      }

      const rowResponse = data?.[requestColumnsVariableName] as any[];
      const rowData = convertDateValues(rowResponse, gridColumns);
      const rowCount = data?.[totalCountVariableName] as number;

      // supply rows for requested block to grid
      params.success({ rowData, rowCount });
    },
  };
};

type ValueArray = Array<string | boolean | number>;

// Function allows to convert one type of value into other in the "request.filter" field
// !! applicable only to "filterType == 'set'" !!
const filterSetValueMapper = (
  request: IServerSideGetRowsRequestWithFilterModel,
  fieldName: string,
  fnMapper: (field: ValueArray) => ValueArray,
) => {
  const fieldObject = request.filterModel?.[fieldName];
  if (fieldObject?.filterType !== 'set') return request;

  const newValues = fnMapper(fieldObject.values);
  if (newValues === fieldObject.values) return request;

  const newFieldObject = { ...fieldObject, values: newValues };

  return {
    ...request,
    filterModel: {
      ...request.filterModel,
      [fieldName]: newFieldObject,
    },
  };
};

const mapStringToNumber = (values: ValueArray) => values.map((r: any) => +r);

const mapLegalEntitiesIds = (legalEntitiesIds: _.Dictionary<number>) => {
  return (values: ValueArray) =>
    values.map((r: any) => legalEntitiesIds[r]).filter(notEmpty);
};

import { AgGridReact } from 'ag-grid-react';
import { forEach } from 'lodash';
import { getSavedGridState } from 'utils/local-storage';
import { SectionType } from '../types';
import { getGridState } from './getGridState';

export const getColStateWithResizedWidth = (
  ref: React.RefObject<AgGridReact<any>>,
  resizedColumn: any,
  section: SectionType,
) => {
  if (ref?.current?.api) {
    const savedGridColumnState = getSavedGridState();
    const currentSectionState = getGridState(ref.current);

    forEach(currentSectionState.colState, (col) => {
      if (col?.colId === resizedColumn?.colId) {
        col.width = resizedColumn.actualWidth;
        return false;
      }
    });

    return {
      ...savedGridColumnState,
      [section]: currentSectionState,
    };
  }
  return false;
};

import { URL_PATH } from 'appUrls';
import {
  ClaimsMenuSectionTabs,
  SectionItem,
} from 'components/ClaimsMenuSectionTabs';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useNavigate } from 'react-router';
import { ClaimsGoBackButton } from '../ClaimsMenuSectionTabs/ClaimsGoBackButton';

interface Props {
  onClickBack: () => void;
  onSectionClick: (section: SectionType) => unknown;
  selected?: SectionType;
}

export const SingleEntityMenu = ({
  selected,
  onClickBack,
  onSectionClick,
}: Props) => {
  const navigate = useNavigate();
  const handleSectionClick = (section: SectionItem) => {
    onSectionClick(section.type);
    navigate({ pathname: URL_PATH.CLAIMS, search: `?section=${section.type}` });
  };

  return (
    <ClaimsMenuSectionTabs
      selected={selected}
      onSectionClick={handleSectionClick}
      additional={<ClaimsGoBackButton onClick={onClickBack} />}
    />
  );
};

import { useMemo } from 'react';

import { IOption, ItemRenderer } from '../MultiSelectExtendable.types';
import { CheckboxOptionItem } from '../renderers/CheckboxOptionItem';

export const OptionItem = (props: {
  option: IOption;
  selected?: IOption[];
  multiple?: boolean;
  maxItemsExceeded?: boolean;
  itemRenderer?: ItemRenderer;
  isSelectAllChecked: boolean;
}) => {
  const {
    selected,
    multiple: multipleProp,
    option,
    maxItemsExceeded,
    itemRenderer,
    isSelectAllChecked,
  } = props;

  const checkedSelectAll = isSelectAllChecked && option.value === 'selectAll';

  const checked = useMemo(
    () => !!selected?.find((o: IOption) => o.value === option.value),
    [selected, option.value],
  );
  const disabled = !!(!checked && maxItemsExceeded);
  const multiple = !!multipleProp;

  if (!itemRenderer) {
    return (
      <CheckboxOptionItem
        multiple={multiple}
        option={option}
        disabled={disabled}
        checked={checked || checkedSelectAll}
      />
    );
  }
  return (
    <>
      {itemRenderer({
        option,
        multiple,
        disabled,
        checked,
      })}
    </>
  );
};

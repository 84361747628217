import { ReviewersEdit } from 'components/ReviewersEdit/ReviewersEdit';
import { ContractReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import { useAllUsers } from 'hooks/useAllUsers';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useContractsUserGroups } from 'pages/SettingsPage/sections/UserGroups/hooks/useContractsUserGroups';
import { FetchStatus } from 'types/fetch-status.types';

export type ContractReviewersEditProps = {
  onChange: (item: ContractReviewersBatchItem) => void;
  label: string;
};

export const ContractReviewersEdit = ({
  onChange,
  label,
}: ContractReviewersEditProps) => {
  const group = useContractsUserGroups();
  const users = useAllUsers();
  const isFetching =
    group.status === FetchStatus.Fetching || users.status === FetchStatus.Fetching;

  return (
    <ReviewersEdit
      placeholder={'Select users or user groups'}
      label={label}
      users={users.data || []}
      groups={group.data || []}
      loading={isFetching}
      onChange={onChange}
      section={SectionType.Contracts}
    />
  );
};

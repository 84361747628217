import { useEffect, useRef, useState, useMemo } from 'react';
import { ApIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { DashboardContextProvider } from 'pages/Dashboard/context/DashboardContextProvider';
import {
  ClaimsDashboardCategory,
  ContractsDashboardCategory,
  TaskClick,
} from 'pages/Dashboard/types';
import { fetchingData } from 'reducer/contractsDashboardDetailedDataReducer';
import { fetchContractsDetailedDataThunk } from 'reducer/thunks/dashboard-thunks';
import { FetchStatus } from 'types/fetch-status.types';
import { DashboardTasks } from '../../components/DashboardTask/DasboardTasks';
import { DashboardDetailedView } from '../DashboardDetailedView/DashboardDetailedView';
import './DashboardApUsers.css';
import {
  allClaimsTasks,
  allContractsTasks,
} from 'pages/Dashboard/utils/dashboardTasksConfig';

export const DashboardApUsers = () => {
  const [contractActiveSection, setContractActiveSection] =
    useState<ContractsDashboardCategory>();
  const [claimActiveSection, setClaimActiveSection] =
    useState<ClaimsDashboardCategory>();
  const [activeSectionType, setSectionType] = useState<SectionType>();

  const detailedViewRef = useRef<null | HTMLDivElement>(null);
  const { shouldFetchData, data: contractDetailedData } = useAppSelector(
    (s) => s.contractsDashboardDetailedData,
  );
  const { data: claimsDetailedData } = useAppSelector(
    (s) => s.claimsDashboardDetailedData,
  );
  const { showMine, claimsData, contractsData, status } = useAppSelector(
    (s) => s.userDashboardTasks.slice,
  );

  const dispatch = useAppDispatch();

  const showDetailedView = useMemo(
    () =>
      !!(
        (contractActiveSection && !!contractDetailedData?.length) ||
        (claimActiveSection?.length && !!claimsDetailedData?.length)
      ),
    [
      contractActiveSection,
      claimActiveSection,
      contractDetailedData,
      claimsDetailedData,
    ],
  );

  useEffect(() => {
    // updating shouldFetchData when showMine toggle changes
    dispatch(fetchingData({ fetching: false, shouldFetchData: true }));
  }, [showMine, dispatch]);

  const handleTaskClick: TaskClick = ({
    section,
    isTask,
    isBackToTop,
    sectionType,
  }) => {
    /*
    this handler is used by both contract dashboard tasks - scroll into view of selected task and fetch data
    and nav buttons from contract data detailed view - change detailed view section
    */
    if (isTask && sectionType === SectionType.Contracts) {
      if (shouldFetchData) {
        dispatch(fetchContractsDetailedDataThunk(showMine));
      }
    } else if (isBackToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setSectionType(undefined);
      setContractActiveSection(undefined);
      setClaimActiveSection(undefined);

      document.body.style.overflow = 'auto';
    }
    setSectionType(sectionType);
    if (sectionType === SectionType.Contracts)
      setContractActiveSection(section as ContractsDashboardCategory);
    if (sectionType === SectionType.Claims)
      setClaimActiveSection(section as ClaimsDashboardCategory);
  };

  useEffect(() => {
    if (detailedViewRef?.current && showDetailedView) {
      detailedViewRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      document.body.style.overflow = 'hidden';
    }
  }, [showDetailedView]);

  return (
    <DashboardContextProvider
      handleTaskClick={handleTaskClick}
      contractActiveSection={contractActiveSection}
      contractDetailedData={contractDetailedData}
      claimsDetailedData={claimsDetailedData}
      claimsActiveSection={claimActiveSection}
    >
      <section className={`dashboard-ap ${showDetailedView ? 'scrolled' : ''}`}>
        {/* let's show loader only on initial load */}
        {status === FetchStatus.Fetching &&
        !claimsData?.length &&
        !contractsData.length ? (
          <ApLoaderDefault loaderSize="lg" />
        ) : (
          <>
            <div className="claims-tasks">
              <DashboardTasks
                tasks={claimsData?.length ? claimsData : allClaimsTasks}
                showMine={showMine}
              />
            </div>
            <div className="contracts-tasks">
              <DashboardTasks
                tasks={contractsData?.length ? contractsData : allContractsTasks}
                showMine={showMine}
              />
              <p className="percentage-warning">
                <ApIcon iconName="error" /> Percentages have been rounded to the
                nearest whole number"
              </p>
            </div>
          </>
        )}
      </section>
      {activeSectionType && showDetailedView ? (
        <section className="dashboard__detailed-view" ref={detailedViewRef}>
          <DashboardDetailedView sectionType={activeSectionType} />
        </section>
      ) : (
        <></>
      )}
    </DashboardContextProvider>
  );
};

import { ApolloProvider } from '@apollo/client';
import useGraphql from 'api/graphql/useGraphql';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './components/UIComponents/css-animations.css';
import './components/UIComponents/css-utils.css';

import { ErrorBoundary } from 'components/ErrorBoundary';
import {
  AppInitialDataProvider,
  useAppRoutesInitializer,
} from 'utils/AppInitializer';

import { URL_PATH } from 'appUrls';
import {
  AuthnErrorPage,
  InitialLoadingPage,
  PageNotFoundPage,
} from 'pages/AppDefaultPages';
import { BookmarksPage } from 'pages/BookmarksPage/BookmarksPage';
import { ClaimsContractsToolPage } from 'pages/ClaimsContractsToolPage';
import { ClaimsSettlementsPage } from 'pages/ClaimsSettlementsPage';
import { ContactUsPage } from 'pages/ContactUsPage';
import { CounterpartyPage } from 'pages/CounterpartyPage';
import { DataUploaderPage } from 'pages/DataUploaderPage';
import { ExhibitExporterContractPage } from 'pages/ExhibitExporterContractPage';
import { HelpPage } from 'pages/HelpPage/HelpPage';
import { ModulePicker } from 'pages/ModulePicker';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import { SetupWizardPage } from 'pages/SetupWizardPage';
import { SingleClaimPage } from 'pages/SingleClaimPage';
import { SingleContractPage } from 'pages/SingleContractPage';
import { SOFAsAndSchedulesPage } from 'pages/SOFAsAndSchedules';
import { FetchStatus } from 'types/fetch-status.types';
import { AppAuthenticationProvider } from 'utils/AppAuthenticationProvider';
import useStyleInjector from 'utils/hooks/useStyleInjector';
import { ExhibitExporterPage } from './pages/ExhibitExporterPage';
import { VendorsPage } from 'pages/VendorsPage/VendorsPage';

export const App = () => {
  const [client] = useGraphql();
  return (
    <ErrorBoundary>
      <AppAuthenticationProvider errorComponent={AuthnErrorPage}>
        <AppInitialDataProvider>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ApolloProvider client={client}>
                <AppContentRoutes />
              </ApolloProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </AppInitialDataProvider>
      </AppAuthenticationProvider>
    </ErrorBoundary>
  );
};

export const AppContentRoutes = () => {
  const { loadingStatus } = useAppRoutesInitializer();

  useStyleInjector();

  return (
    <Routes>
      <Route path={URL_PATH.HOME} element={<ModulePicker />} />

      <Route element={<Loader loadingStatus={loadingStatus} />}>
        <Route path={URL_PATH.SETUP_WIZARD} element={<SetupWizardPage />} />

        <Route path={URL_PATH.DATA_UPLOAD} element={<DataUploaderPage />} />

        <Route path={URL_PATH.CLAIMS} element={<ClaimsContractsToolPage />} />
        <Route path="/claim/:id" element={<SingleClaimPage />} />

        <Route path="/contract/:id" element={<SingleContractPage />} />
        <Route path="/counterparty/:id" element={<CounterpartyPage />} />

        <Route path={URL_PATH.EXHIBITS_CLAIMS} element={<ExhibitExporterPage />} />
        <Route
          path={URL_PATH.EXHIBITS_CONTRACTS}
          element={<ExhibitExporterContractPage />}
        />

        <Route path={URL_PATH.BOOKMARKS} element={<BookmarksPage />} />
        <Route path={URL_PATH.SETTINGS} element={<SettingsPage />} />
      </Route>

      <Route
        path={`${URL_PATH.SOFAS_SCHEDULES}/*`}
        element={<SOFAsAndSchedulesPage />}
      />
      <Route path={URL_PATH.HELP} element={<HelpPage />} />
      <Route path={URL_PATH.CONTACT_US} element={<ContactUsPage />} />
      <Route
        path={`${URL_PATH.CLAIMS_SETTLEMENT}/*`}
        element={<ClaimsSettlementsPage />}
      />
      <Route path={URL_PATH.VENDORS} element={<VendorsPage />} />

      <Route path="/" element={<Navigate to={URL_PATH.HOME} />} />
      <Route path="*" element={<PageNotFoundPage />} />
    </Routes>
  );
};

const Loader = (props: { loadingStatus: FetchStatus }) => {
  const { loadingStatus } = props;
  if (loadingStatus === FetchStatus.Idle || loadingStatus === FetchStatus.Fetching) {
    return <InitialLoadingPage />;
  }

  // Layout - Outlet pattern from React Router v6
  // https://stackoverflow.com/questions/70724269/react-router-v6-route-composition-is-it-possible-to-render-a-custom-route
  return <Outlet />;
};

import { ApButtonIcon, ApIcon } from '@alixpartners/ui-components';
import './HttpErrorInfo.css';
import { IHttpError } from './requestTypes';

type ErrorText = { title: string; message?: string };

const ERROR_MESSAGE: Record<number, ErrorText> = {
  400: { title: 'Invalid value' },
  401: { title: 'Unauthorized Please log in' },
  402: { title: 'Payment required for access' },
  403: { title: 'Access forbidden Contact support' },
  404: {
    title: 'Endpoint not found',
    message:
      'This may be due to a deployment that is underway. \n' +
      'Please try again shortly \n' +
      'If issue still persists contact the administrator',
  },
  405: { title: 'Method not allowed for this resource' },
  406: { title: 'Request not acceptable' },
  407: { title: 'Proxy authentication required' },
  408: { title: 'Request timed out. Try again' },
  409: { title: 'Conflict with the resource state' },
  410: { title: 'Resource no longer available' },
  411: { title: 'Invalid request header' },
  412: { title: 'Precondition for the request failed' },
  413: { title: 'Request body is too large' },
  414: { title: 'Requested URL too long' },
  415: { title: 'Unsupported media type' },
  416: { title: 'Requested range not satisfiable' },
  417: { title: 'Expectation in the request header not met' },
  418: { title: 'Request not allowed' },
  421: { title: 'Request directed to the wrong server' },
  422: { title: 'Unable to process the request' },
  423: { title: 'Resource is locked' },
  424: { title: 'Request failed due to another failed request' },
  425: { title: 'Request is too early to be processed' },
  426: { title: 'Switch to a different protocol required' },
  428: { title: 'Request requires conditional headers' },
  429: { title: 'Too many requests' },
  431: { title: 'Request header fields too large' },
  451: { title: 'Access denied for legal reasons' },
  500: {
    title: 'Internal server error',
    message: 'Please contact your IT administrator',
  },
  501: { title: 'Functionality not supported' },
  502: { title: 'Bad gateway. Try again later' },
  503: { title: 'Service temporarily unavailable' },
  504: { title: 'Gateway timed out Try again later' },
  505: { title: 'HTTP version not supported' },
  506: { title: 'Content negotiation circular reference' },
  507: { title: 'Insufficient storage to complete the request' },
  508: { title: 'Infinite loop detected' },
  510: { title: 'Further extensions required' },
  511: { title: 'Network authentication required' },
};

type HttpErrorInfoProps = {
  err: IHttpError;
  hide: () => void;
};

export const HttpErrorInfo = (props: HttpErrorInfoProps) => {
  const { err, hide } = props;

  const { title, message } = getInfoMessage(props.err);

  const messageText = sliceTextLimit(message);
  const messageList = err.messages?.map(sliceTextLimit);

  return (
    <div className="http-error-messege">
      <div className="http-error-messege__header">
        <div className="http-error-messege__title">
          <ApIcon iconName="outline_error" iconColor="#fff" />
          <span> {title} </span>
        </div>
        <ApButtonIcon
          className="http-error-messege__close"
          iconName="baseline_close"
          theme="dark"
          onClick={hide}
        />
      </div>

      <div className="http-error-messege__body">
        {messageText && (
          <div key={'main-message'}>
            <pre>{messageText}</pre>
          </div>
        )}
        {messageList &&
          messageList.map((m, i) => (
            // messages could be the same strings, using index as key
            <div key={i}>
              <pre>{m}</pre>
            </div>
          ))}
      </div>
    </div>
  );
};

const getInfoMessage = (error: IHttpError): ErrorText => {
  const { code, title, message } = error;
  if (!code) {
    return { title, message };
  }

  const result = ERROR_MESSAGE[code!];
  return result ?? { title: `HTTP Error ${code}`, message };
};

const sliceTextLimit = (message: string | undefined) => {
  if (!message) return message;

  // limit for max lines and max length for every line
  const maxLines = 6;
  const maxLineChars = 120;

  let lines = message.split('\n');

  if (lines.length > maxLines) {
    lines = lines.slice(0, maxLines - 1);
    lines.push('...');
  }

  const trimedLines = lines.map((el) => {
    if (el.length <= maxLineChars) return el;
    return el.slice(0, maxLineChars - 3) + '...';
  });

  const result = trimedLines.join('\n');
  return result;
};

import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback } from 'react';
import { useQueryParam, withDefault } from 'use-query-params';

const DEFAULT_SECTION = SectionType.Claims;
const DEFAULT_SUBSECTION = SubsectionType.AllClaims;

const SectionTypeParam = {
  encode: (v: SectionType) => v,
  decode: (v: any) =>
    Object.keys(SectionType).includes(v) ? (v as SectionType) : DEFAULT_SECTION,
};

const SubsectionTypeParam = {
  encode: (v: SubsectionType) => v,
  decode: (v: any) =>
    Object.keys(SubsectionType).includes(v)
      ? (v as SubsectionType)
      : DEFAULT_SUBSECTION,
};

export const useClaimToolQueryParams = () => {
  const [section, setSection] = useQueryParam<SectionType>(
    'section',
    withDefault(SectionTypeParam, DEFAULT_SECTION),
  );
  const [subsection, setSubsection] = useQueryParam<SubsectionType>(
    'subsection',
    withDefault(SubsectionTypeParam, DEFAULT_SUBSECTION),
  );

  const buildClaimPath = useCallback(
    (id: number) => {
      return buildSingleClaimPath(id, section, subsection);
    },
    [section, subsection],
  );

  const buildCounterPath = useCallback(
    (id: number) => {
      return buildCounterpartyPath(id, section, subsection);
    },
    [section, subsection],
  );

  return {
    section,
    setSection,
    subsection,
    setSubsection,
    buildSingleClaimPath: buildClaimPath,
    buildCounterpartyPath: buildCounterPath,
  };
};

export function buildSingleClaimPath(
  id: number,
  section: SectionType,
  subsection: SubsectionType,
): string {
  return `/claim/${id}?section=${section}&subsection=${subsection}`;
}

export function buildCounterpartyPath(
  id: number,
  section: SectionType,
  subsection: SubsectionType,
): string {
  return `/counterparty/${id}?section=${section}&subsection=${subsection}`;
}

import './CoverDocs.css';

const fileID = '3565f96d-836e-4b5e-a0d9-9d6cc9520213';

export const CoverDocs = () => {
  return (
    <section className="cover-docs-page">
      <div className="header-container">
        <h2 className="single-entity-header__title">Cover Documents</h2>
      </div>
      <div className="word-container">
        <iframe
          src={`https://alixpartnersgbr-my.sharepoint.com/personal/shinchy_alixpartners_com/_layouts/15/Doc.aspx?sourcedoc={${fileID}}&amp;action=embedview`}
          width="100%"
          height="99%"
          title="word"
          className="word-embed"
        >
          This is an embedded{' '}
          <a target="_blank" href="https://office.com" rel="noreferrer">
            Microsoft Office
          </a>{' '}
          document, powered by{' '}
          <a target="_blank" href="https://office.com/webapps" rel="noreferrer">
            Office
          </a>
          .
        </iframe>
      </div>
    </section>
  );
};

import { api } from 'api';
import { directorySettlementUser } from 'api/endpoints/settlementUsers';
import { SubmitData } from 'components/ModalWindows/UploadModal';
import _ from 'lodash';
import { useState } from 'react';
import { azureFileUploader } from 'utils/azureUploader/azureFileUploader';
import { useSettlementsUserBackgroundJob } from './useSettlementsUserBackgroundJob';

const TEMPLATE_FILE = 'SettlementUsersBatchUpload.xlsx';

export const useSettlementsUserBatchUpload = (props: {
  refreshList: () => void;
}) => {
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const backgroundjob = useSettlementsUserBackgroundJob();

  const downloadTemplate = () => api.fileInfo.downloadTemplate(TEMPLATE_FILE);

  const downloadError = async () => {
    const errorCode = backgroundjob.jobId;
    if (!errorCode) {
      console.error('unknown jobId');
      return;
    }

    await api.settlementUsers.getBatchErrorsFile(errorCode);
  };

  const uploadDocument = async (data: SubmitData): Promise<boolean> => {
    const file = data.entries[0].file;
    if (!file) return false;

    const uploadResult = await azureFileUploader({
      file,
      folder: directorySettlementUser,
      showErrorMessage: true,
      getCredentials: api.storage.getExcelContainerSasToken,
      onProgress: setUploadProgress,
    });

    if (uploadResult.type !== 'ok') return false;

    const result = await api.settlementUsers.batchUpload(
      uploadResult.fileName!,
      uploadResult.displayName,
    );

    backgroundjob.start(result?.jobId, { fileName: file.name });

    return !!result;
  };

  return {
    backgroundjob: _.pick(backgroundjob, ['running', 'result', 'status']),
    fileName: backgroundjob.fileName,
    uploadProgress,
    uploadErrorDialog: {
      show: showUploadDialog,
      close: () => setShowUploadDialog(false),
    },
    uploadDocument,
    downloadTemplate,
    downloadError,
  };
};

import { claimLogs } from 'api/endpoints/claimLogs';
import { useAppDispatch } from 'hooks/reducerHooks';
import { setExportState } from 'reducer/claimsContractsExportReducer';
import { getCurrentDate } from 'utils/formatDate';
import { SectionType } from '../types';
import { contractLogs } from 'api/endpoints/contractLogs';

export const useAuditLogGetter = (section: SectionType) => {
  const dispatch = useAppDispatch();
  const download = async () => {
    const fileName = `All${section}ChangeLog-${getCurrentDate().toISOString()}.xlsx`;

    dispatch(setExportState({ generating: true, fileName: 'Audit Log export' }));
    if (section === SectionType.Claims) {
      await claimLogs.excelExportAll(fileName);
    } else if (section === SectionType.Contracts) {
      await contractLogs.excelExportAll(fileName);
    }
    dispatch(setExportState({ generating: false }));
  };

  return { download };
};

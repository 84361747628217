import { post, get } from 'api/apiRequest';

export type ProjectDetails = {
  projectName: string | undefined;
  primaryCaseNumber: number | undefined;
  useMatching?: boolean;
};

export const projectDetailsApi = {
  getProjectDetails: () => get<ProjectDetails>(`/v1/Settings/project-info`),
  saveProjectDetails: (params: ProjectDetails): any => {
    return post(`/v1/Settings/project-info`, params);
  },
  setMatchingToggle: (toggle: boolean) => {
    return post(`/v1/Settings/toggle-matching/${toggle}`, null);
  },
};

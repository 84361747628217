import React from 'react';
import classNames from 'classnames';
import { ApButtonTab } from '@alixpartners/ui-components';
import './style.css';

export const NavLinkTab = (props: {
  label: string;
  active?: boolean;
  disabled?: boolean;
  onClick: () => void;
}) => {
  const { active, disabled, label, onClick } = props;
  return (
    <ApButtonTab
      className={classNames('navbar-tab', {
        'navbar-tab_selected': active,
        'navbar-tab_disabled': disabled,
      })}
      isSelected={active}
      disabled={disabled}
      theme="dark"
      onClick={onClick}
    >
      {label}
    </ApButtonTab>
  );
};

import { useCallback, useEffect, useMemo, useRef } from 'react';

// This hook does the same as URL.createObjectURL(...) function
// Only it automatically revoke the object URL to prevent Memory Leak.
export const useUrlObjectCreator = () => {
  const objectUrl = useRef<string>();

  const cleanUp = useCallback(() => {
    objectUrl.current && URL.revokeObjectURL(objectUrl.current);
    objectUrl.current = undefined;
  }, []);

  const create = useCallback(
    (buffer: ArrayBuffer | undefined) => {
      cleanUp();
      if (!buffer) return;

      objectUrl.current = URL.createObjectURL(new Blob([buffer]));
      return objectUrl.current;
    },
    [cleanUp],
  );

  useEffect(() => cleanUp, []); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => ({ url: objectUrl.current, create, cleanUp }),
    [objectUrl, create, cleanUp],
  );
};

import './DeleteModal.css';
import {
  ApButtonDanger,
  ApButtonGroup,
  ApButtonMain,
  ApModal,
} from '@alixpartners/ui-components';
import { ContractsUserGroupsResponse, GroupUsersItem } from 'api/endpoints';
import { DeleteModalContent } from './DeleteModalContent';
import { useEntityContext } from 'components/UIComponents/EntityTable/EntityContext';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

export const DeleteModal = ({
  deleteModalOpened,
  item,
  setDeleteModalOpened,
}: {
  deleteModalOpened: boolean;
  setDeleteModalOpened: (modalOpened: boolean) => void;
  item: GroupUsersItem | ContractsUserGroupsResponse;
}) => {
  const { onDelete, section } = useEntityContext();
  const handleDelete = () => {
    onDelete?.(item.id);
    setDeleteModalOpened(false);
  };
  const claimItem: GroupUsersItem | undefined =
    section === SectionType.Claims ? (item as GroupUsersItem) : undefined;
  const contractItem: ContractsUserGroupsResponse | undefined =
    section === SectionType.Contracts
      ? (item as ContractsUserGroupsResponse)
      : undefined;

  const renderFooter = () => (
    <ApButtonGroup>
      <ApButtonMain onClick={() => setDeleteModalOpened(false)}>Cancel</ApButtonMain>
      <ApButtonDanger onClick={handleDelete}>Yes, Delete</ApButtonDanger>
    </ApButtonGroup>
  );

  return (
    <ApModal
      header="Are you sure you want to delete this user group?"
      isOpen={deleteModalOpened}
      footer={renderFooter}
      isDismissible
      hasClosed={() => setDeleteModalOpened(false)}
      className="delete-modal"
    >
      <DeleteModalContent {...{ claimItem, contractItem, section }} />
    </ApModal>
  );
};

import { ActionStatus } from 'api/endpoints';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  ApDashboardTask,
  ClientDashboardTask,
} from 'reducer/userDashboardTasksReducer';
import {
  agGridFIlterModelActionStatusGenerator,
  agGridTextFilter,
  formatToLink,
} from 'utils/dashboardHelpers';
import {
  DashboardAgGridFilter,
  ClaimsDashboardCategory,
  ContractsDashboardCategory,
  NavButtons,
} from '../types';

const DEFAULT_AP_CLAIMS: ApDashboardTask[] = [
  {
    id: 'allClaims',
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Claims',
  },
  {
    id: ClaimsDashboardCategory.Resolved,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Resolved',
    filters: agGridTextFilter(
      DashboardAgGridFilter.DashboardCategory,
      ClaimsDashboardCategory.Resolved,
    ),
    hasSeparator: true,
    detailedViewValue: ClaimsDashboardCategory.Resolved,
  },
  {
    id: ClaimsDashboardCategory.ResolutionProccessInitiated,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Resolution process initiated',
    filters: agGridTextFilter(
      DashboardAgGridFilter.DashboardCategory,
      ClaimsDashboardCategory.ResolutionProccessInitiated,
    ),
    hasSeparator: true,
    detailedViewValue: ClaimsDashboardCategory.ResolutionProccessInitiated,
  },

  {
    id: ClaimsDashboardCategory.ResolutionProcessPending,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Resolution process pending',
    filters: agGridTextFilter(
      DashboardAgGridFilter.DashboardCategory,
      ClaimsDashboardCategory.ResolutionProcessPending,
    ),
    hasSeparator: true,
    detailedViewValue: ClaimsDashboardCategory.ResolutionProcessPending,
  },
  {
    id: ClaimsDashboardCategory.UnassignedClaims,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Unassigned claims',
    filters: agGridTextFilter(
      DashboardAgGridFilter.DashboardCategory,
      ClaimsDashboardCategory.UnassignedClaims,
    ),
    detailedViewValue: ClaimsDashboardCategory.UnassignedClaims,
  },
];

const DEFAULT_AP_CONTRACTS_TASKS: ApDashboardTask[] | ClientDashboardTask[] = [
  {
    id: 'allContracts',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Contracts',
    hasScroll: true,
    hasPercentage: true,
  },
  {
    id: 'reviewSubheader',
    subHeader: 'Awaiting / under review',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Awaiting - under review',
  },
  {
    id: 'contractsAwaitingReview',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Awaiting Review',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Awaiting Review',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.AwaitingUnderReview,
  },
  {
    id: 'contractsUnderReview',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Under Review',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Under Review',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.AwaitingUnderReview,
  },
  {
    id: 'assessedSubheader',
    subHeader: 'Resolution type',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Awaiting - under review',
  },
  {
    id: 'contractsAssume',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Assume',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Assume',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.Assume,
  },
  {
    id: 'contractsReject',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Reject',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Reject',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.Reject,
  },
  {
    id: 'contractsTerminate',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Terminate',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Terminate',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.Terminate,
  },
  {
    id: 'contractsNonExecutory',
    to: '/claims?section=Contracts',
    sectionType: SectionType.Contracts,
    description: 'Non-Executory',
    filters: agGridTextFilter(
      DashboardAgGridFilter.ContractResolutionTypeName,
      'Non-Executory',
    ),
    hasScroll: true,
    indentLeft: true,
    hasPercentage: true,
    detailedViewValue: ContractsDashboardCategory.NonExecutory,
  },
  {
    id: 'contractsTotalPercentage',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Contracts total percentage',
    hide: true,
  },
];

const ESTIMATES_TASKS: ApDashboardTask[] = [
  {
    id: 'separator',
    to: '',
    sectionType: SectionType.Contracts,
    description: '',
  },
  {
    id: 'estimates',
    subHeader: 'Estimates',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Estimates',
  },
  {
    id: 'estimatesCure',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Cure',
    indentLeft: true,
  },
  {
    id: 'rejectionDamage',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Rejection damage',
    indentLeft: true,
  },
  {
    id: 'contractsTotalPercentage',
    to: '',
    sectionType: SectionType.Contracts,
    description: 'Contracts total percentage',
    hide: true,
  },
];

export const allClaimsTasks: ApDashboardTask[] = DEFAULT_AP_CLAIMS.map((claim) => {
  return {
    ...claim,
    to: formatToLink(claim.to, `&predefinedFilter=${claim.id}&showMine=0`),
  };
});

export const allContractsTasks: ApDashboardTask[] = [
  ...DEFAULT_AP_CONTRACTS_TASKS,
  ...ESTIMATES_TASKS,
].map((contract) => {
  return {
    ...contract,
    to:
      contract.subHeader || !contract.to
        ? ''
        : formatToLink(contract.to, `&predefinedFilter=${contract.id}&showMine=0`),
  };
}) as ApDashboardTask[];

export const DEFAULT_CLIENT_CLAIMS_TASKS: ClientDashboardTask[] = [
  {
    id: 'allClaims',
    count: 0,
    to: '',
    sectionType: SectionType.Claims,
    description: 'Claims',
  },
  {
    id: 'assigned',
    count: 0,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Client Assigned Claims',
    filters: agGridFIlterModelActionStatusGenerator([ActionStatus.Assigned]),
    hasSeparator: true,
  },
  {
    id: 'assessed',
    count: 0,
    to: '/claims?section=Claims',
    sectionType: SectionType.Claims,
    description: 'Client Assessed Claims',
    filters: agGridFIlterModelActionStatusGenerator([
      ActionStatus.ClientReconciled,
      ActionStatus.AlixReconciled,
      ActionStatus.ObjectionPending,
      ActionStatus.ObjectionFiled,
      ActionStatus.ObjectionContinued,
      ActionStatus.ObjectionWithdrawn,
      ActionStatus.ObjectionOrderedPartial,
      ActionStatus.ObjectionOrderedFinal,
      ActionStatus.Withdrawn,
      ActionStatus.SettlementPending,
      ActionStatus.SettlementPartial,
      ActionStatus.SettlementResolved,
      ActionStatus.Voided,
      ActionStatus.Allowed,
    ]),
    footnote: 'Count based on all assigned reviewers and groups of reviewers',
  },
];

export const clientsContractsTasks: ClientDashboardTask[] =
  DEFAULT_AP_CONTRACTS_TASKS.map((contract) => {
    return {
      ...contract,
      to:
        contract.subHeader || !contract.to
          ? ''
          : formatToLink(contract.to, `&predefinedFilter=${contract.id}&showMine=1`),
    };
  }) as ClientDashboardTask[];

export const clientsClaimsTasks: ClientDashboardTask[] =
  DEFAULT_CLIENT_CLAIMS_TASKS.map((claim) => {
    return {
      ...claim,
      to: formatToLink(claim.to, `&predefinedFilter=${claim.id}&showMine=1`),
    };
  }) as ClientDashboardTask[];

export const CONTRACTS_DETAILED_VIEW_NAV_BTNS: NavButtons[] = [
  {
    label: 'Awaiting / Under Review',
    value: ContractsDashboardCategory.AwaitingUnderReview,
  },
  { label: 'Assume', value: ContractsDashboardCategory.Assume },
  { label: 'Reject', value: ContractsDashboardCategory.Reject },
  { label: 'Terminate', value: ContractsDashboardCategory.Terminate },
  { label: 'Non-Executory', value: ContractsDashboardCategory.NonExecutory },
];

export const CLAIMS_DETAILED_VIEW_NAV_BTNS: NavButtons[] = [
  { label: 'Resolved', value: ClaimsDashboardCategory.Resolved },
  {
    label: 'Resolution Process Initiated',
    value: ClaimsDashboardCategory.ResolutionProccessInitiated,
  },
  {
    label: 'Resolution Process Pending',
    value: ClaimsDashboardCategory.ResolutionProcessPending,
  },
  { label: 'Unassigned Claims', value: ClaimsDashboardCategory.UnassignedClaims },
];

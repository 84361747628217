import { del, get, getQuiet, post } from 'api/apiRequest';

export type ExportPdfStatus =
  | 'InQueue'
  | 'InProgress'
  | 'Completed'
  | 'InComplete'
  | 'Failed'
  | 'Canceled';

export const exportPdf_InProgress: ExportPdfStatus[] = ['InProgress', 'InQueue'];

export const exportPdf_canceled: ExportPdfStatus[] = ['Canceled', 'InComplete'];
export const exportPdf_notCompleted: ExportPdfStatus[] = [
  'Failed',
  'Canceled',
  'InComplete',
];

// failed at the Back-end
export const exportPdf_failed: ExportPdfStatus[] = ['Failed', 'InComplete'];

// pdf file might be downloaded
export const exportPdf_finished: ExportPdfStatus[] = [
  'Completed',
  'Canceled',
  'InComplete',
];

export interface ExportPdfResponse {
  id: number;
  exportStatus: ExportPdfStatus;
  fileName: string;
  filePath: string;
  fileSize: number;
  processedFiles: number;
  totalFiles: number;
  createdBy: string;
  createdDate: Date;
}

export const exportPdf = {
  get: async () => get<ExportPdfResponse[]>('v1/ExportPdf'),
  getQuiet: async () => getQuiet<ExportPdfResponse[]>('v1/ExportPdf'),
  post: async () => get<ExportPdfResponse>('v1/ExportPdf'),
  getById: async (id: number) => getQuiet<ExportPdfResponse>(`v1/ExportPdf/${id}`),
  retry: async (id: number) => post(`v1/ExportPdf/${id}/retry`),
  cancel: async (id: number) => post(`v1/ExportPdf/${id}/cancel`),
  delete: async (id: number) => del(`v1/ExportPdf/${id}`),
};

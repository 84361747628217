import { ApButtonDanger } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { forwardRef } from 'react';
import './ApButtonRed.css';

type ApButtonProps = React.ComponentProps<typeof ApButtonDanger>;

export const ApButtonRed = forwardRef<HTMLButtonElement, ApButtonProps>(
  (props, ref) => {
    const { className, ...buttonProps } = props;
    return (
      <ApButtonDanger
        className={classNames('ap-button-red', className)}
        ref={ref}
        {...buttonProps}
      />
    );
  },
);

import classNames from 'classnames';
import { Footer as CDOFooter } from 'components/Footer';
import { LabelLocked } from 'components/LabelLocked';
import { NavBarContainer } from 'components/NavBar';
import { Link } from 'react-router-dom';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { ClientInfoModuleTip } from './ClientInfoModuleTip';
import { ModuleCard, ToolCard } from './ModuleCard';
import { ADMIN_TOOLS, Module, MODULES } from './ModulePicker.const';
import './ModulePicker.css';

export function ModulePicker() {
  const { isClient } = useAppUserType();
  const tools = isClient
    ? ADMIN_TOOLS.filter((tool) => tool.name !== 'Settings')
    : ADMIN_TOOLS;

  return (
    <>
      <NavBarContainer />
      <div className="modulepicker-page">
        <main className="modulepicker">
          <div className="modulepicker__column modulepicker__parent-grid">
            <div role="menu" className="modulepicker__modules">
              <ClientInfoModuleTip />
              {MODULES.filter((m) => !m.hidden).map((m, index) => (
                <ModuleWrapper key={`module${index}`} module={m} />
              ))}
            </div>
            <div role="menu" className="modulepicker__tools">
              <h4>Platform wide admin tools</h4>
              <div className="modulepicker__tools-grid">
                {tools
                  .filter((m) => !m.hidden)
                  .map((m, index) => (
                    <ModuleWrapper key={`admin-module${index}`} asTool module={m} />
                  ))}
              </div>
            </div>
          </div>
        </main>
        <CDOFooter />
      </div>
    </>
  );
}

export type ModuleWrapperProps = {
  module: Module;
  asTool?: boolean;
};

function ModuleWrapper(props: ModuleWrapperProps) {
  const { module, asTool = false } = props;
  const { name, content, type, hidden } = module;

  if (hidden) return null;

  const cn = classNames('module-wrapper', { 'module-wrapper-tool': asTool });
  const Card = asTool ? ToolCard : ModuleCard;

  if (type === 'external') {
    const { link } = module;
    return (
      <a className={cn} role="menuitem" href={link} target="_blank" rel="noreferrer">
        <Card title={name}>{content}</Card>
      </a>
    );
  }

  if (type === 'coming-soon') {
    return (
      <LabelLocked text="coming soon" className={cn} role="note">
        <Card title={name}>{content}</Card>
      </LabelLocked>
    );
  }

  if (type === 'live') {
    const { link } = module;
    return (
      <Link className={cn} role="menuitem" to={link ?? ''}>
        <Card title={name}>{content}</Card>
      </Link>
    );
  }

  return null;
}

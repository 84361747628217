import { useState } from 'react';
import { iconImage } from '../SvgIcon/iconImageList';

export enum SORT_VALUES {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortState<T> = { field: keyof T; direction: SORT_VALUES };

const iconMap: { [key: string]: keyof typeof iconImage } = {
  [SORT_VALUES.DESC]: 'caret_inv',
  [SORT_VALUES.ASC]: 'caret',
};

export const useEntityTableSorting = <T,>(initialSortState: SortState<T>) => {
  const [sortState, setSortState] = useState(initialSortState);

  const handleSort = (field: keyof T) => {
    setSortState((prev) => ({
      field,
      direction:
        prev.direction === SORT_VALUES.ASC ? SORT_VALUES.DESC : SORT_VALUES.ASC,
    }));
  };

  return {
    sortState,
    handleSort,
    sortIcon: iconMap[sortState.direction],
  };
};

import { api } from 'api';
import { ExportPdfResponse } from 'api/endpoints/exportPdf';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { promiseTimer } from 'utils/debugUtils';
import { useIntervalTimer } from 'utils/hooks/useIntervalTimer';
import { apiSofaExport } from '../api/apiSofaExport';

interface ContextType {
  inProgress: ExportPdfResponse[] | undefined;
  queued: ExportPdfResponse[] | undefined;
  completed: ExportPdfResponse[] | undefined;
  forceUpdate: () => Promise<void>;
  downloadFile: (filePath: ExportPdfResponse['filePath']) => Promise<void>;
  cancelExport: (item: ExportPdfResponse) => Promise<void>;
  retryExport: (item: ExportPdfResponse) => Promise<void>;
}

const SofasExportContext = createContext<ContextType>({} as ContextType);

type Props = React.PropsWithChildren<{}>;

const PING_TIMER_INTERVAL = 20 * 1000; // 20 seconds

export const SofasExportContextProvider = ({ children }: Props) => {
  const [itemsInProgress, setItemsInProgress] = useState<ExportPdfResponse[]>();
  const [itemsQueued, setItemsQueued] = useState<ExportPdfResponse[]>();
  const [itemsCompleted, setItemsCompleted] = useState<ExportPdfResponse[]>();

  const monitoringTimer = useIntervalTimer();

  const fetchExportHistoryList = async () => {
    const historyItems = await apiSofaExport.getHistoryList();

    setItemsCompleted(historyItems.completed);
    setItemsQueued(historyItems.queued);
    setItemsInProgress(historyItems.progress);
  };

  useEffect(() => {
    monitoringTimer.start(() => fetchExportHistoryList(), PING_TIMER_INTERVAL);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadFile = useCallback(
    (filePath: ExportPdfResponse['filePath']) =>
      apiSofaExport.downloadExportedPdf(filePath),
    [],
  );

  const retryExport = async (item: ExportPdfResponse) => {
    await api.exportPdf.retry(item.id);
    await promiseTimer(500);
    await monitoringTimer.forceUpdate();

    // just for better user experience - refresh list again after 5 sec
    await promiseTimer(5000);
    await monitoringTimer.forceUpdate();
  };

  const cancelExport = async (item: ExportPdfResponse) => {
    await apiSofaExport.cancelExport(item.id);
    await monitoringTimer.forceUpdate();
  };

  const forceUpdate = () => monitoringTimer.forceUpdate();

  return (
    <SofasExportContext.Provider
      value={{
        inProgress: itemsInProgress,
        queued: itemsQueued,
        completed: itemsCompleted,
        downloadFile,
        cancelExport,
        retryExport,
        forceUpdate,
      }}
    >
      {children}
    </SofasExportContext.Provider>
  );
};

export const useSofasExportContext = () => useContext(SofasExportContext);

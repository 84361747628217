import { ICellRendererParams } from 'ag-grid-community';
import {
  ActionStatus,
  ClaimRow,
  Counterparty,
  CounterpartyMatchStatus,
} from 'api/endpoints';
import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { fromCamelCase } from 'utils/format';
import { SubsectionType } from '../types';
import './ActionStatusCell.css';

export interface ActionStatusCellParams
  extends ICellRendererParams<Counterparty | ClaimRow, any> {
  subsection: SubsectionType;
  onAccept: (row: Counterparty, cellProps: ActionStatusCellParams) => unknown;
  onDecline: (row: Counterparty, cellProps: ActionStatusCellParams) => unknown;
}

export const ActionStatusCell = (props: ActionStatusCellParams) => {
  let key = (props.data as ClaimRow)?.actionStatus;

  const label = key;

  const score = (props.data as Counterparty)?.score;
  if (typeof score === 'number') {
    const { onAccept, onDecline } = props;
    const { matchStatus } = props.data as Counterparty;
    if (matchStatus) {
      return (
        <div className={classNames('action-status-cell', 'matching', 'submatch')}>
          {matchStatus === CounterpartyMatchStatus.Matched && (
            <div className="match">
              <SvgIcon iconName="check_green" />
              <div>Match</div>
            </div>
          )}
          {matchStatus === CounterpartyMatchStatus.NotMatched && (
            <div className="not-match">
              <SvgIcon iconName="cancel_red" />
              <div>Not a match</div>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className={'action-status-cell submatch'}>
        {matchStatus}
        {typeof score === 'number' ? (
          <>
            <div
              className="action-status__icon-container"
              onClick={() =>
                props?.data ? onAccept(props.data as Counterparty, props) : undefined
              }
            >
              <SvgIcon iconName="check_white" />
            </div>
            <div
              className="action-status__icon-container"
              onClick={() =>
                props?.data
                  ? onDecline(props.data as Counterparty, props)
                  : undefined
              }
            >
              <SvgIcon iconName="cancel_white" />
            </div>
          </>
        ) : (
          <div className="label">- -</div>
        )}
      </div>
    );
  }

  return (
    <>
      {key && (
        <div
          className={classNames('action-status-cell', {
            'scheduled-claim': key === ActionStatus.ScheduledClaim,
            'filed-claim': key === ActionStatus.FiledClaim,
            'matches-found': key === ActionStatus.MatchesFound,
            'assigned-claim': key === ActionStatus.Assigned,
            matched: key === ActionStatus.Matched,
            'categorized-claim': key === ActionStatus.Categorized,
            'claim-withdrawn': key === ActionStatus.Withdrawn,
            'alix-reconciled-claim': key === ActionStatus.AlixReconciled,
            'client-reconciled-claim': key === ActionStatus.ClientReconciled,
            'matching-complete': key === ActionStatus.MatchingComplete,
            objection: [
              ActionStatus.ObjectionContinued,
              ActionStatus.ObjectionFiled,
              ActionStatus.ObjectionOrderedFinal,
              ActionStatus.ObjectionOrderedPartial,
              ActionStatus.ObjectionPending,
            ].includes(key),
            'order-vacated': key === ActionStatus.OrderVacated,
            'settlement-pending-claim': key === ActionStatus.SettlementPending,
            settlement: key === ActionStatus.SettlementFinal,
            'voided-claim': key === ActionStatus.Voided,
            'trumped-schedule': key === ActionStatus.TrumpedSchedule,
            'zero-dollar-value-scheduled-only':
              key === ActionStatus.ZeroDollarValueScheduledOnly,
            'allowable-allowed': [
              ActionStatus.Allowable,
              ActionStatus.Allowed,
            ].includes(key),
          })}
        >
          <span className="action-status-cell__value">{fromCamelCase(label)}</span>
        </div>
      )}
    </>
  );
};

import {
  ColDef,
  IServerSideDatasource,
  RowValueChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useGraphql from 'api/graphql/useGraphql';
import { ConfirmModal } from 'components/ModalWindows/ConfirmModal/ConfirmModal';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import _ from 'lodash';
import { useReportLegalEntities } from 'pages/SOFAsAndSchedules/Domain/useReportLegalEntities';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { createSofasGridDataSource } from './SofasPartsTables/createSofasGridDataSource';
import { useSofasColumnDef } from './SofasPartsTables/useSofasColumnDef';
import { useTableBody } from './SofasPartsTables/useTableBody';
import './TableBody.css';
import { ISofaTableProps } from './tables.types';
import { TableSubHeader } from './TableSubHeader';
// Checkbox specific imports
import { CheckboxHeader } from 'pages/ClaimsContractsToolPage/components/CheckboxHeader';
import { useSelectionManager } from 'pages/ClaimsContractsToolPage/hooks/useSelectionManager';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';

const colWidth = 200;
const defaultColDef: ColDef = {
  sortable: true,
  width: colWidth,
  editable: true,
  filter: true,
  floatingFilter: true,
};
const GRID_HEADER_HEIGHT = 30;
const CHECKBOX_COLUMN_DEFITION: ColDef = {
  checkboxSelection: true,
  // field is absolutely neccessary because
  // otherwise ag-grid will assign "0" string to the field
  // and sometimes "1" string to the field
  // this will cause infinite update loops in useGridState
  field: 'checkbox',
  filter: false,
  width: 60,
  maxWidth: 60,
  resizable: false,
  headerClass: 'checkbox',
  // https://stackoverflow.com/questions/57890959/header-checkbox-selection-doesnt-work-when-using-server-side-data-source
  // headerCheckboxSelection is not supported in server side model. It has to be
  // // implemented manually
  headerComponent: CheckboxHeader,
  suppressColumnsToolPanel: true,
  menuTabs: [],
};

interface ITableSofa {
  sofaTable: ISofaTableProps;
  onChangeTab: (part: string) => void;
  onChangeSchedule: (schedule: string) => void;
}

export const TableBody = ({
  sofaTable,
  onChangeTab,
  onChangeSchedule,
}: ITableSofa) => {
  const ref = useRef<AgGridReact>(null);

  const [client, authInitialized] = useGraphql();
  const legalEntity = useReportLegalEntities();

  const serverSideDatasource = useMemo(
    () => createSofasGridDataSource(client, sofaTable, legalEntity?.data ?? []),
    [client, sofaTable, legalEntity?.data],
  );

  const columnDefs = useSofasColumnDef(sofaTable);

  const [rowSelected, setRowSelected] = useState([]);

  const {
    processing,
    confirmDialog,
    onRowAdd,
    onRowDelete,
    onRowValueChanged,
    onRowEditingStopped,
  } = useTableBody(ref, client, sofaTable);

  const { selectAllCheckbox, handleCheckboxChange } = useSelectionManager<any>(
    ref,
    true,
  );

  useEffect(() => {
    setRowSelected([]);
  }, [processing]);

  const checkboxColumnDefs = {
    ...CHECKBOX_COLUMN_DEFITION,
    headerComponentParams: {
      section: SectionType.SOFAS,
      subsection: SubsectionType.SOFAS,
      checked: selectAllCheckbox,
      onChange: (x: boolean) => handleCheckboxChange(x),
    },
  };

  if (!authInitialized || !sofaTable.part) return null;

  return (
    <DivWithLoader className="table-section" loading={processing}>
      <TableSubHeader
        sofaTable={sofaTable}
        onChangeTab={onChangeTab}
        onChangeSchedule={onChangeSchedule}
        onResetFilters={() => {
          ref.current?.api.setFilterModel(null);
        }}
        onAdd={onRowAdd}
        onDelete={onRowDelete}
        deleteDisabled={rowSelected.length === 0}
      />
      <div className="table-section__content">
        <DataTableGrid
          ref={ref}
          sofaTable={sofaTable}
          columnDefs={[checkboxColumnDefs, ...columnDefs]}
          serverSideDatasource={serverSideDatasource}
          onRowChanged={onRowValueChanged}
          onRowEditingStopped={onRowEditingStopped}
          onSelectionChanged={(event: { api: { getSelectedRows: () => any } }) => {
            setRowSelected(event.api.getSelectedRows());
          }}
        />
      </div>

      {confirmDialog && <ConfirmModal {...confirmDialog} />}
    </DivWithLoader>
  );
};

interface DataTableGridProps {
  ref: React.RefObject<AgGridReact>;
  sofaTable: ISofaTableProps;
  columnDefs: any;
  serverSideDatasource: IServerSideDatasource;
  onRowChanged: (event: RowValueChangedEvent) => void;
  onRowEditingStopped: (event: any) => void;
  onSelectionChanged: (event: any) => void;
}

const DataTableGrid = forwardRef<AgGridReact, DataTableGridProps>((props, ref) => {
  const { columnDefs, serverSideDatasource, sofaTable } = props;
  const { onRowChanged, onRowEditingStopped, onSelectionChanged } = props;

  columnDefs[0].checkboxSelection = true;
  columnDefs[0].showDisabledCheckboxes = true;

  //Workaround to fix issue with grid refresh
  const dataRefreshKey = useMemo(() => _.uniqueId(), [sofaTable]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section
      className={`datatype-${sofaTable.type} table-section__body claims-tool-grid ag-theme-alpine `}
      style={{ maxWidth: columnDefs.length * colWidth + 5 }}
    >
      <AgGridReact
        ref={ref}
        key={dataRefreshKey}
        pagination
        serverSideInfiniteScroll
        serverSideSortOnServer
        suppressContextMenu
        serverSideDatasource={serverSideDatasource}
        columnDefs={columnDefs}
        maxConcurrentDatasourceRequests={1}
        defaultColDef={defaultColDef}
        rowModelType="serverSide"
        editType="fullRow"
        rowSelection="multiple"
        onRowValueChanged={onRowChanged}
        getRowId={getRowIdValue}
        onRowEditingStopped={onRowEditingStopped}
        suppressRowClickSelection
        onSelectionChanged={onSelectionChanged}
        headerHeight={GRID_HEADER_HEIGHT}
      />
    </section>
  );
});

const getRowIdValue = (param: any) => param?.data?.id;

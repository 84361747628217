import { useCallback, useEffect, useRef, useState } from 'react';
import { dateFromString } from 'utils/formatDate';
import { DEFAULT_FORMAT_LABEL, DEFAULT_PLACEHOLDER } from './Datepicker.const';
import { DatepickerProps, HtmlFormInputElement } from './DatePicker.types';
import { registerFormDataUpdate, registerFormReset } from './Datepicker.utils';

export function useDatepicker(props: DatepickerProps) {
  const {
    value: valueProp,
    defaultValue: defaultValueProp,
    onChange,
    formatLabel = DEFAULT_FORMAT_LABEL,
    placeholder = DEFAULT_PLACEHOLDER,
  } = props;

  const inputRef = useRef<HtmlFormInputElement | null>(null);

  const v = valueProp ?? defaultValueProp;
  const [label, setLabel] = useState<string>(v ? formatLabel(v) : placeholder);
  const [validity, setValidity] = useState<'idle' | 'invalid' | 'valid'>('idle');

  const value = dateFromString(valueProp);
  const defaultValue = dateFromString(defaultValueProp);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const target = e.target as HtmlFormInputElement;
      const validity = target.checkValidity();
      setValidity(validity ? 'valid' : 'invalid');

      if (!target.value) {
        // pressed reset
        setLabel(placeholder);
        onChange?.(undefined, e);
        return;
      }
      const isoValue = dateFromString(target.value);
      if (isoValue) {
        setLabel(formatLabel(isoValue));
        onChange?.(isoValue, e);
      }
    },
    [formatLabel, onChange, placeholder],
  );

  const handleClick: React.MouseEventHandler<HTMLElement> = useCallback(() => {
    const input = inputRef.current;
    if (input && !input.disabled) {
      input.showPicker();
    }
  }, [inputRef]);

  const handleSpacebar: React.KeyboardEventHandler<HTMLElement> = useCallback(
    (e) => {
      if (e.key !== ' ') return;
      const input = inputRef.current;
      if (input && !input.disabled) {
        input.showPicker();
      }
    },
    [inputRef],
  );

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const u1 = registerFormDataUpdate(input);
      const u2 = registerFormReset(input, (e) => {
        setLabel(placeholder);
        onChange?.(undefined, e);
      });
      return () => {
        u1?.();
        u2?.();
      };
    }
  }, [value, inputRef, placeholder, onChange]);

  return {
    inputRef,
    label,
    validity,
    value,
    defaultValue,
    handleChange,
    handleSpacebar,
    handleClick,
  };
}

import { FetchPolicy, gql } from '@apollo/client';
import {
  configuration,
  ContractActionIdFields,
  ContractEditChangeOptions,
  ContractEditValues,
  contracts,
} from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContractReviewersValues } from 'components/ReviewersEdit/ReviewersEdit';
import {
  ContractReviewersBatchItem,
  ReviewersBatchItem,
} from 'components/ReviewersEdit/ReviewersEdit.utils';
import { GridColumnsSectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  CANSSliceType,
  clearToSend,
  contractExhibitTypeIdSet,
  contractStatusIdSet,
} from 'reducer/contractActionNameReducer';
import { fetchUserDashboardTasksThunk } from 'reducer/thunks/dashboard-thunks';
import { selectableUpdated } from 'reducer/selectableDataReducer';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { allValuesUndefined } from 'utils/object';
import useContractEditActionStatus from './useContractEditActionStatus';
import { useEditableField } from './useEditableField';

export type EditFieldFunc = (
  field: string,
  value: string | number | boolean | ReviewersBatchItem,
) => unknown;
export type ChangeOptionsHook = [
  ContractEditChangeOptions,
  React.Dispatch<React.SetStateAction<ContractEditChangeOptions>>,
];

export interface EditContractHook {
  loading: boolean;
  isEdited: boolean;
  contract: ContractEditValues | undefined;
  changeOptionsHook: ChangeOptionsHook;
  isSaving: boolean;
  editField: EditFieldFunc;
  save: () => unknown;
  isEditable: (field: string, column?: string) => boolean;
  contractId: number;
}

const DEFAULT_CHANGE_OPTIONS: ContractEditChangeOptions = {
  comment: '',
};

function useEditContract(): EditContractHook {
  const dispatch = useAppDispatch();
  const user = useApplicationUser();
  const [isEdited, setEdited] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const changeOptionsHook = useState(DEFAULT_CHANGE_OPTIONS);
  const [changeOptions, setChangeOptions] = changeOptionsHook;
  const [contractEdits, setContractEdits] = useState<
    ContractEditValues | undefined | {}
  >({});
  const [originalContract, setOriginalContract] =
    useState<ContractEditValues | undefined>();
  const [client, authorized] = useGraphql();
  const { isEditable } = useEditableField();
  const { editActionField } = useContractEditActionStatus(originalContract);
  let { id } = useParams();
  const contractId = Number(id);

  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);

  const load = useCallback(
    async (policy?: FetchPolicy) => {
      const { data }: any = await client.query({
        query: await getEditContract(),
        fetchPolicy: policy,
        variables: {
          id: contractId,
        },
      });

      const item = extractContractItem(data);
      setOriginalContract(item);
      setLoading(false);
    },
    [contractId, client],
  );

  useEffect(() => {
    if (!authorized) return;
    load();
  }, [authorized, load]);

  const editField = useCallback(
    (
      field: string,
      value:
        | string
        | number
        | boolean
        | ReviewersBatchItem
        | ContractReviewersBatchItem,
    ) => {
      if (
        [
          'contractActionTypeId',
          'contractActionSubStatusId',
          'contractActionNameId',
        ].includes(field)
      ) {
        editActionField(field, value as string);
        return;
      }
      if (['contractReviewer'].includes(field)) return;

      if (['contractExhibitTypeId'].includes(field)) {
        dispatch(contractExhibitTypeIdSet(Number(value)));
      }

      if (['contractCategoryId'].includes(field)) {
        dispatch(selectableUpdated({ contractCategoryId: Number(value) }));
      }

      if (['contractStatusId'].includes(field)) {
        dispatch(contractStatusIdSet(Number(value)));
      }

      let existingField = (contractEdits as any)[field];
      if (!existingField) existingField = (originalContract as any)[field];
      if (!value && !existingField) return;
      if (value === existingField) return;
      setContractEdits({
        ...contractEdits,
        [field]: value,
      } as ContractEditValues);

      setEdited(true);
    },
    [contractEdits, originalContract, editActionField, dispatch],
  );

  const contractActionNameSlice = useAppSelector(
    (s) => s.contractActionNameSelection,
  );

  const { toSend: actionNameEditsToSend, type: actionNameEditsType } =
    contractActionNameSlice;

  const contractReviewer = useAppSelector((s) => s.contractReviewer);

  useEffect(() => {
    if (actionNameEditsType === CANSSliceType.Touched) {
      setEdited(true);
    }
  }, [actionNameEditsType]);

  const actionEdits: ContractActionIdFields = useMemo(
    () => ({
      contractActionTypeId: actionNameEditsToSend.contractActionTypeId,
      contractActionSubStatusId: actionNameEditsToSend.contractActionSubStatusId,
      contractActionNameId: actionNameEditsToSend.contractActionNameId,
    }),
    [actionNameEditsToSend],
  );

  const reviewers: ContractReviewersValues = useMemo(
    () => ({
      contractReviewerUserGroupIds: contractReviewer.data.userGroupReviewers,
      contractReviewerUserIds: contractReviewer.data.userReviewers,
    }),
    [contractReviewer],
  );

  const save = useCallback(async () => {
    if (!isEdited) return;
    if (isSaving) return;
    setSaving(true);

    async function saveData() {
      const edits = {
        ...contractEdits,
        ...actionEdits,
        ...reviewers,
      };
      const noEdits = allValuesUndefined(edits);
      if (noEdits) return;
      if (!originalContract) return;
      await contracts.edit({
        contractId,
        values: edits as ContractEditValues,
        changeOptions: changeOptions,
      });

      setChangeOptions(DEFAULT_CHANGE_OPTIONS);
      // we disable hiding the edit form on save because we want to always show it
      // setEdited(false);
      setSaving(false);
      if (user && user.data)
        dispatch(fetchUserDashboardTasksThunk({ user: user.data, showMine }));
    }

    await saveData();
    await load('network-only');

    dispatch(clearToSend({}));
    setContractEdits({});
  }, [
    user,
    isEdited,
    isSaving,
    contractEdits,
    originalContract,
    changeOptions,
    dispatch,
    actionEdits,
    load,
    setChangeOptions,
    contractId,
    reviewers,
    showMine,
  ]);

  const contract = useMemo(() => {
    return originalContract
      ? {
          ...originalContract,
          ...contractEdits,
        }
      : undefined;
  }, [contractEdits, originalContract]);

  return {
    loading,
    isEdited,
    isSaving,
    contract,
    changeOptionsHook,
    isEditable,
    editField,
    save,
    contractId,
  };
}

const getEditContract = async () => {
  const columns = await configuration.getGridColumns(
    GridColumnsSectionType.Contracts,
  );

  // adding id fields that are missing from columns data
  const additionalColumns = [
    'id',
    'contractCategoryId',
    'contractTypeId',
    'debtorId',
    'contractStatusId',
    'contractActionTypeId',
    'contractActionSubStatusId',
    'contractActionNameId',
    'paymentPositionId',
    'contractExhibitTypeId',
    'aPApprovalStatusId',
    'clientApprovalStatusId',
  ].join(' ');

  const joinedColumns = (columns || [])?.map((c) => c.propertyName).join(' ');
  const requestColumns = `${joinedColumns} ${additionalColumns}`;

  return gql`
      query GetContracts($id: Int!) {
        contracts(where: {
          id: { eq: $id }
        }) {
          totalCount
          items {
            ${requestColumns}
          }
        }
      }
    `;
};

export default useEditContract;

function extractContractItem(data: any): ContractEditValues | undefined {
  const item: ContractEditValues | undefined = data?.contracts?.items?.length
    ? { ...data.contracts.items[0] }
    : undefined;
  if (item) {
    item._fetchTimestamp = new Date().toISOString();
  }
  return item;
}

import { ApButtonIcon, ApLoaderDefault } from '@alixpartners/ui-components';
import { SettlementUserRole } from 'api/endpoints/settlementUsers';
import classNames from 'classnames';
import { InputComponentProps } from 'components/UIComponents/EntityTable';
import { SettlementUserInfo } from './api/apiSettlementUser';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userRolesOptions: SettlementUserRole[] = ['Counterparty', 'Client'];

export const SettlementsUserManagementInput =
  (initialProps: { onResetPassword: (item: SettlementUserInfo | null) => void }) =>
  ({ columnKey, item, fromTable }: InputComponentProps<SettlementUserInfo>) => {
    const { disabled, className, operation } = fromTable;
    const isValueEditing = operation === 'edit';

    if (columnKey === 'name') {
      return (
        <input
          type="text"
          name={columnKey}
          maxLength={50}
          required
          className={classNames(className, 'utils-clean-input')}
          disabled={disabled || isValueEditing}
          placeholder="Enter name"
          defaultValue={item?.name}
        />
      );
    }

    if (columnKey === 'email') {
      return (
        <input
          type="text"
          name={columnKey}
          maxLength={50}
          required
          className={classNames(className, 'utils-clean-input')}
          disabled={disabled || isValueEditing}
          placeholder="Enter email"
          defaultValue={item?.email}
        />
      );
    }
    if (columnKey === 'userRole') {
      return (
        <select
          name={columnKey}
          className={classNames(className, 'utils-clean-input')}
          required
          disabled={disabled}
          defaultValue={item?.userRole ?? ''}
        >
          <option disabled value="">
            Select resolution type
          </option>
          {userRolesOptions.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      );
    }

    if (isValueEditing && columnKey === 'resetPassword') {
      return (
        <div className="settlements-user-management__button-reset">
          {item?.resetPassword ? (
            <ApLoaderDefault loaderSize="sm" />
          ) : (
            <ApButtonIcon
              iconName="replay"
              type="button"
              onClick={() => initialProps.onResetPassword(item)}
            />
          )}
        </div>
      );
    }

    return null;
  };

import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { useCallback, useState } from 'react';
import { error } from 'utils/alert';
import { downloadExcelFile } from 'utils/file';
import {
  ClaimsByCategory,
  ClaimsWalkDownDetailedSummary,
  ClaimsWalkDownSummary,
} from '../sections/ClaimsReports/export/exporters';
import { exportContractTable } from 'pages/Dashboard/excelExport/exportContractTable';
import { contracts, scheduleGReport } from 'api/endpoints';
import { useAppUserType } from 'utils/hooks/useAppUserType';

interface ClaimsReportsState {
  claimsByCategory: IOption[];
  claimsWalkDownSummary: IOption[];
  claimsWalkDownDetailed: IOption[];
}

const ALL_ENTITIES_VALUE = {
  value: 'allEntities',
  label: 'All entities',
};

const DEFAULT_STATE: ClaimsReportsState = {
  claimsByCategory: [ALL_ENTITIES_VALUE],
  claimsWalkDownSummary: [ALL_ENTITIES_VALUE],
  claimsWalkDownDetailed: [ALL_ENTITIES_VALUE],
};

const TEMPLATE_MAP = {
  claimsByCategory: ClaimsByCategory,
  claimsWalkDownSummary: ClaimsWalkDownSummary,
  claimsWalkDownDetailed: ClaimsWalkDownDetailedSummary,
};

export const contractsResSubtypeReports = 'contractsResSubtypeReports';

export const useClaimsContractsReports = () => {
  const [reportLegalEntity, setReportLegalEntity] =
    useState<ClaimsReportsState>(DEFAULT_STATE);
  const [downloadingState, setDownloadingState] = useState<{
    downloading: boolean;
    selectedReport?: string | keyof ClaimsReportsState;
  }>({
    downloading: false,
    selectedReport: '',
  });
  const { isApUser } = useAppUserType();

  const legalEntityChange = (value: IOption[], type: keyof ClaimsReportsState) =>
    setReportLegalEntity((prevState) => ({
      ...prevState,
      [type]: value,
    }));

  const getWorkBookDetails = useCallback(
    async (template: keyof ClaimsReportsState) => {
      const { label: legalEntityName, value } = reportLegalEntity[template][0];
      const legalEntityId = value === 'allEntities' ? undefined : Number(value);

      const { getWorkbook, fileName } = TEMPLATE_MAP[template];
      const workbook = await getWorkbook({
        legalEntityName,
        legalEntityId,
      });

      return {
        workbook,
        filename: `${fileName}_${legalEntityName}.xlsx`,
      };
    },
    [reportLegalEntity],
  );

  const claimsReportDownload = useCallback(
    async (template: keyof ClaimsReportsState) => {
      setDownloadingState({ downloading: true, selectedReport: template });
      const { workbook, filename } = await getWorkBookDetails(template);

      if (!workbook) {
        console.error('Download error occured. No workbook to use');
        error('Download error occured. No workbook to use');
        setDownloadingState((prev) => ({ ...prev, downloading: false }));
        return;
      }
      await downloadExcelFile(workbook, filename);
      setDownloadingState((prev) => ({ ...prev, downloading: false }));
    },
    [getWorkBookDetails],
  );

  const downloadScheduleGReport = useCallback(async () => {
    setDownloadingState({ downloading: true, selectedReport: scheduleGReport });
    await contracts.downloadScheduleG();
    setDownloadingState((prev) => ({ ...prev, downloading: false }));
  }, [setDownloadingState]);

  const handleExportContractsTable = useCallback(async () => {
    setDownloadingState({
      downloading: true,
      selectedReport: contractsResSubtypeReports,
    });
    await exportContractTable();
    setDownloadingState((prev) => ({ ...prev, downloading: false }));
  }, []);

  return {
    reportLegalEntity,
    legalEntityChange,
    claimsReportDownload,
    downloadingState,
    handleExportContractsTable,
    downloadScheduleGReport,
    isApUser,
  };
};

import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import '../DisclosureLinkMenu/DiscloseLinkMenu.css';
import '../DisclosureLinkMenu/DiscloseLinkMenu.transitions.css';
import {
  MenuItem,
  MenuSubItem,
} from '../DisclosureLinkMenu/DisclosureLinkMenu.types';
import { DirectLink } from './DirectLink';
import { NestedLink } from './NestedLink';

export interface AccordionMenuSelectedItem {
  item?: MenuItem;
  subItem?: MenuSubItem;
}

export type Props = {
  items: MenuItem[];
  selected?: AccordionMenuSelectedItem;
  onChange?: (item: AccordionMenuSelectedItem) => void;
};

export const AccordionMenu = ({ items, selected, onChange }: Props) => {
  const [openedParent, setOpenedParent] = useState(selected?.item);

  const handleMenuClick = (item: MenuItem, subItem?: MenuSubItem) => {
    const isParentItem = item.type === 'nested' && isNil(subItem);
    if (isParentItem) {
      setOpenedParent((r) => (r !== item ? item : undefined));
      return;
    }

    onChange?.({ item, subItem });
  };

  useEffect(() => {
    setOpenedParent(selected?.item);
  }, [selected?.item]);

  return (
    <>
      {items
        .filter((item) => !item.hidden)
        .map((item) => {
          if (item.type === 'direct') {
            return (
              <DirectLink
                key={item.id}
                item={item}
                isSelected={item === selected?.item}
                onClick={handleMenuClick}
              />
            );
          }
          return (
            <NestedLink
              key={item.id}
              item={item}
              isOpened={item === openedParent}
              selectedSubItem={selected?.subItem}
              onSectionClick={handleMenuClick}
            />
          );
        })}
    </>
  );
};

import { CellClickedEvent } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { useCallback, useMemo, useState } from 'react';
import { MATCH_CODE_COLUMN } from '../const';
import { SectionType, SubsectionType, TSummaryMatchDialog } from '../types';

const initialSummaryDialog = {
  show: false,
  data: undefined as ClaimRow | undefined,
};

export const useMatchSummaryDialogWindow = (
  section: SectionType,
  subsection: SubsectionType,
) => {
  const [summaryMatching, setSummaryMatching] = useState(initialSummaryDialog);

  const onMatchCellClickedInjector = useCallback(
    async (params: CellClickedEvent<ClaimRow>) => {
      if (section !== SectionType.Claims) return;
      if (params.colDef.field === MATCH_CODE_COLUMN) {
        if (!params.data?.matchCode) return;
        setSummaryMatching({ show: true, data: params.data });
      }
    },
    [section],
  );

  const summaryMatchingDialog: TSummaryMatchDialog = useMemo(
    () => ({
      ...summaryMatching,
      close: () => setSummaryMatching(initialSummaryDialog),
    }),
    [summaryMatching],
  );

  return {
    summaryMatchingDialog,
    onMatchCellClickedInjector,
  };
};

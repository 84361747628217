import { useState } from 'react';
import './MatchingToggle.css';
import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { saveMatchingToggle } from 'reducer/thunks/projectDetailsThunks';
import { ApLoaderDefault } from '@alixpartners/ui-components';

export const MatchingToggle = () => {
  const dispatch = useAppDispatch();
  const { useMatching } =
    useAppSelector((s) => s.selectable.data.projectDetails) || {};
  const [matchingEnabled, setMatchingEnabled] = useState(
    useMatching ? ApThreeWayValue.Yes : ApThreeWayValue.No,
  );
  const [saving, setSaving] = useState(false);

  const handleToggleClick = async (toggle: ApThreeWayValue) => {
    setMatchingEnabled(toggle);
    setSaving(true);
    await dispatch(saveMatchingToggle(toggle === ApThreeWayValue.Yes));
    setSaving(false);
  };

  return (
    <div className="matching-toggle anim-appear flex-align-center">
      <div className="matching-toggle__header">
        <h4>
          Run matching automatically
          <p>
            Please enable this option if you want the matching process to run
            automatically over night.
          </p>
        </h4>
      </div>
      <ApThreeWaySwitch
        value={matchingEnabled}
        onChange={(toggle) => handleToggleClick(toggle)}
        label={
          saving ? (
            <ApLoaderDefault loaderSize="sm" />
          ) : matchingEnabled === ApThreeWayValue.Yes ? (
            'Enabled'
          ) : (
            'Disabled'
          )
        }
        labelFirst
      />
    </div>
  );
};

import { ApplyColumnStateParams, ColDef } from 'ag-grid-community';
import { ContractRow, GridColumn } from 'api/endpoints';
import { useMemo } from 'react';

import { SectionType, SubsectionType } from '../../types';

import compact from 'lodash/compact';

import { isUndefined } from 'lodash';
import { sortColumnsByState } from '../columnsSortingUtils';
import {
  ContractsColumnDefsCallbacks,
  contractsGridColumnMapper,
  contractsStaticColumnDef,
} from './contractsGridColumnMapper';

export const useContractsColumnDef = (
  gridColumns: GridColumn[] | undefined,
  section: SectionType,
  subsection: SubsectionType,
  columnState: ApplyColumnStateParams | undefined,
  selectAllCheckbox: boolean,
  callbacks: ContractsColumnDefsCallbacks,
) => {
  const columnDefs: ColDef<ContractRow>[] = useMemo(() => {
    if (isUndefined(gridColumns)) return [];

    const staticColDef = contractsStaticColumnDef(
      section,
      subsection,
      selectAllCheckbox,
      callbacks,
    );

    const columnMapperFn = contractsGridColumnMapper(section, subsection, callbacks);
    const columnDef = compact(gridColumns?.map(columnMapperFn));

    let result = [...staticColDef, ...columnDef];

    result = sortColumnsByState(result, columnState);

    return result;
  }, [gridColumns, subsection, section, callbacks, selectAllCheckbox, columnState]);

  return columnDefs;
};

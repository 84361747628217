import { ActionName } from 'api/endpoints/action-names';
import {
  AddFields,
  EditFieldsList,
  EntityTable,
  Panel,
} from 'components/UIComponents/EntityTable';
import { AwarePanel } from 'components/UIComponents/EntityTable/EntityTable.components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { dataOrSkeleton } from 'utils/skeleton';
import { ActionNameInput } from './ActionNameInput';
import './ActionNames.css';
import { useEditAndAddActionNames } from './useActionNames';
import { filterExistingActionNames } from './utils';

const HEADERS = [
  'Resolution name',
  'Resolution type',
  'Resolution subtype',
  'Hearing Date',
];
const COLUMN_KEYS: (keyof ActionName)[] = [
  'name',
  'typeId',
  'substatusId',
  'hearingDate',
];

export function ActionNames() {
  const {
    status,
    actionNames: originalActionNames,
    onAddSave,
    onEditSave,
  } = useEditAndAddActionNames(SectionType.Claims);
  const actionNames = dataOrSkeleton(originalActionNames, SKELETON, status) ?? [];

  return (
    <SettingsContentPanel>
      <section className="actionnames">
        <EntityTable
          items={filterExistingActionNames(actionNames)}
          status={status}
          addColumnKeys={COLUMN_KEYS}
          editColumnKeys={COLUMN_KEYS}
          inputComponent={ActionNameInput}
          onEditSave={onEditSave}
          onAddSave={onAddSave}
          section={SectionType.Claims}
        >
          <Panel title="Add a new resolution name">
            <AddFields headers={HEADERS} />
          </Panel>
          <AwarePanel title="Resolution names">
            <EditFieldsList headers={HEADERS} />
          </AwarePanel>
        </EntityTable>
      </section>
    </SettingsContentPanel>
  );
}

const SKELETON: ActionName[] = Array.from({ length: 3 }).map((_, i) => {
  return {
    id: -i - 1,
    name: '',
    substatusId: 0,
    typeId: 0,
    hearingDate: new Date().toISOString(),
  };
});

import './DeleteLoader.css';
import { ApLoaderDefault } from '@alixpartners/ui-components';

export const DeleteLoader = ({ label }: { label: string }) => {
  return (
    <div className="delete-loader">
      <ApLoaderDefault loaderSize="lg" />
      <h4>Deleting {label}, please wait</h4>
    </div>
  );
};

import { PageHelpSection } from 'components/PageHelpSection/PageHelpSection';
import './MatchingPage.css';
import { MatchingProcess } from './sections/MatchingProcess/MatchingProcess';
import { MatchingUpload } from './sections/MatchingUpload/MatchingUpload';
import { MatchingContextProvider } from './MatchingContextProvider';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

const HELP_STEPS = [
  {
    title: 'Press Start Matching',
  },
  {
    title: 'Download Matching file',
  },
  {
    title: 'Review matches',
  },
  {
    title: 'Upload Matching file',
  },
  {
    title: 'Press Start Matching',
  },
  {
    title:
      'See matches updated across the platform (and you can download the latest matching file)',
  },
];

export const MatchingPage = () => {
  return (
    <ApUserRole>
      <MatchingContextProvider>
        <div className="matching-page">
          <section className="matching-page__data">
            <MatchingProcess />
            <MatchingUpload />
          </section>
          <section className="matching-page__help">
            <PageHelpSection
              steps={HELP_STEPS}
              title="How to use matching?"
              subTitle="Once you have uploaded data (claims, contracts, vendor IDs) in the data upload page and you have navigated here:
  "
            />
          </section>
        </div>
      </MatchingContextProvider>
    </ApUserRole>
  );
};

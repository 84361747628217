import { ContractRow } from 'api/endpoints';
import useGraphql from 'api/graphql/useGraphql';
import { useAppDispatch } from 'hooks/reducerHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setRelatedContractsNumber } from 'reducer/contractsReducer';
import { graphqlGetContracts } from 'utils/agGridHotchocolate/graphqlGetContracts';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';

export const RELATED_CONTRACT_COLS: {
  label: string;
  propertyName: keyof ContractRow;
}[] = [
  { label: 'Contract number', propertyName: 'referenceNumber' },
  { label: 'Parent contract number', propertyName: 'parentContractNumber' },
  { label: 'Debtor', propertyName: 'primaryDebtor' },
  { label: 'Counterparty', propertyName: 'counterpartyName' },
  { label: 'Contract title', propertyName: 'description' },
  { label: 'Contract date', propertyName: 'contractDate' },
];

export const useRelatedContracts = (parentContractNumber: string) => {
  const [client] = useGraphql();
  const [relatedContracts, setRelatedContracts] = useState<ContractRow[]>([]);
  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();

  const filterModel: AgGridFilterModel = useMemo(
    () => ({
      parentContractNumber: {
        filterType: 'text',
        type: 'equals',
        filter: parentContractNumber,
      },
    }),
    [parentContractNumber],
  );

  const getRelatedContracts = useCallback(async () => {
    setFetching(true);
    const relatedContracts = await graphqlGetContracts(
      { filterModel },
      client,
      RELATED_CONTRACT_COLS.map((c) => c.propertyName),
    );
    setRelatedContracts(relatedContracts);
    setFetching(false);
    if (relatedContracts.length)
      // we're not counting the selected contract
      dispatch(setRelatedContractsNumber(relatedContracts.length - 1));
  }, [client, filterModel, dispatch]);

  useEffect(() => {
    getRelatedContracts();
  }, [getRelatedContracts]);

  return {
    fetching,
    relatedContracts,
  };
};

import { api } from 'api';
import {
  ExportPdfResponse,
  ExportPdfStatus,
  exportPdf_InProgress,
} from 'api/endpoints/exportPdf';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntervalTimer } from 'utils/hooks/useIntervalTimer';
import { iif } from 'utils/object';

interface Props {
  interval?: number;
  onStart?: (details: ExportPdfResponse) => void;
  onEnd?: (details: ExportPdfResponse | undefined) => void;
  onProgress?: (details: ExportPdfResponse | undefined) => void;
  onFailed?: (details: ExportPdfResponse | undefined) => void;
}

const MONITOR_TIME_INTERVAL = 10 * 1000; //milliseconds
const MAX_HTTP_ERRORS = 10;

export const useSofasExportJobMonitoring = ({
  interval = MONITOR_TIME_INTERVAL,
  onStart,
  onEnd,
  onProgress,
}: Props) => {
  const jobIdRef = useRef<number>();

  const errorsCount = useRef(0);
  const [result, setResult] = useState<ExportPdfResponse>();
  const timer = useIntervalTimer();

  const stop = useCallback(
    (result?: ExportPdfResponse) => {
      timer.stop();
      onEnd?.(result);
    },
    [timer, onEnd],
  );

  const checkJobStatus = useCallback(async () => {
    if (!jobIdRef.current) {
      stop();
      return;
    }

    const { data } = await api.exportPdf.getById(jobIdRef.current);

    if (!data) {
      console.error('ExportPdf request - returns an empty data');
      if (errorsCount.current < MAX_HTTP_ERRORS) {
        errorsCount.current = errorsCount.current + 1;
        return;
      }

      console.error('ExportPdf request Termintated - too many errors');
      stop();
    }

    errorsCount.current = 0;
    setResult(data);

    onProgress?.(data);

    const isInProgress = exportPdf_InProgress.includes(data?.exportStatus!);
    if (!isInProgress) {
      stop(data);
    }
  }, [onProgress, stop]);

  const start = useCallback(
    async (exportStatus: ExportPdfResponse | undefined) => {
      if (!exportStatus?.id) return;

      jobIdRef.current = exportStatus.id;
      setResult(undefined);

      timer.start(() => checkJobStatus(), interval);
      onStart?.(exportStatus);
    },
    [timer, interval, onStart, checkJobStatus],
  );

  const status: ExportPdfStatus | undefined = useMemo(
    () => result?.exportStatus ?? iif(timer.active, 'InProgress'),
    [result?.exportStatus, timer.active],
  );

  return {
    jobId: jobIdRef.current,
    running: timer.active,
    status,
    result,
    start,
    stop,
  };
};

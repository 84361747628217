import { ClaimActionFields, ClaimEditValues } from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEditActionField } from 'hooks/useEditActionField';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  useActionNames,
  useActionsSelectOptions,
} from 'pages/SettingsPage/sections/ActionNames/useActionNames';
import { useEffect } from 'react';
import { ClaimActionInfo, claimSet } from 'reducer/claimActionNameSelectionReducer';

export type ActionInfoKey = keyof Omit<ClaimActionInfo, 'id'>;
export type EditActionInfoKey = keyof Pick<
  ClaimActionFields,
  'actionNameId' | 'actionExhibitTypeId' | 'actionSubStatusId'
>;

function useEditActionStatus(claim?: ClaimEditValues) {
  const selectionState = useAppSelector((s) => s.claimActionNameSelection);
  const { claimId, _fetchTimestamp } = selectionState;

  // trigger data loading
  useActionNames({ section: SectionType.Claims });
  useActionsSelectOptions(SectionType.Claims);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      claim &&
      (claimId !== claim.id || claim._fetchTimestamp !== _fetchTimestamp)
    ) {
      dispatch(
        claimSet({
          claimActionInfo: {
            id: claim.id,
            _fetchTimestamp: claim._fetchTimestamp,
            actionNameId: claim.actionNameId,
            actionTypeId: claim.actionExhibitTypeId,
            actionSubstatusId: claim.actionSubStatusId,
            actionStatus: claim.actionStatus,
          },
        }),
      );
    }
  }, [_fetchTimestamp, claim, claimId, dispatch]);

  const { editActionField } = useEditActionField(SectionType.Claims);

  return {
    editActionField,
  };
}

export default useEditActionStatus;

import { SectionType, SubsectionType } from '../types';

export const selectAllCheckboxEnabled = (
  section: SectionType,
  subsection: SubsectionType,
) => {
  if (section === SectionType.Claims || section === SectionType.Contracts)
    return true;
  if (
    section === SectionType.Matching &&
    subsection === SubsectionType.UnmatchedClaims
  )
    return true;

  return false;
};

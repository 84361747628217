import { SlipSheet } from 'api/endpoints';
import classNames from 'classnames';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { useCallback, useState } from 'react';
import { ActionListItem, FileDisplay } from '../../Components/ActionList';
import { SlipSheetEntityInfo } from './components/SlipSheetEntityInfo';
import { SlipSheetsItemDetails } from './components/SlipSheetsItemDetails';
import './SlipSheetListItem.css';

interface Props {
  item: SlipSheet;
  onEditClick: (item: SlipSheet) => void;
  onDownloadClick: (item: SlipSheet) => void;
  onDeleteClick: (item: SlipSheet) => void;
}

export function SlipSheetListItem({
  item,
  onDeleteClick,
  onDownloadClick,
  onEditClick,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleEditClick = useCallback(() => onEditClick(item), [item, onEditClick]);
  const handleDownloadClick = useCallback(
    () => onDownloadClick(item),
    [item, onDownloadClick],
  );
  const handleDeleteClick = useCallback(
    () => onDeleteClick(item),
    [item, onDeleteClick],
  );

  return (
    <div data-expanded={expanded} className={classNames('slip-sheet-list-item')}>
      <ActionListItem
        key={item.id}
        id={item.id}
        displayName={item.displayName}
        newlyAdded={false}
        onEditClick={handleEditClick}
        onDownloadClick={handleDownloadClick}
        onDeleteClick={handleDeleteClick}
      >
        <span className="action-list-item__actions ">
          <ButtonIcon
            onClick={() => setExpanded((ep) => !ep)}
            className="slip-sheet-list-item__expand_btn"
            type="button"
            icon="chevron_left"
            iconColor="var(--ap-color-ui-grey-light)"
          />
          <span className="action-list-item__actions__divider" />
        </span>
        <FileDisplay displayName={item.displayName} />
        <SlipSheetEntityInfo item={item} />
      </ActionListItem>
      {expanded ? <SlipSheetsItemDetails item={item} /> : null}
    </div>
  );
}

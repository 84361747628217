import {
  ApButtonGroup,
  ApButtonMain,
  ApIcon,
  ApModal,
} from '@alixpartners/ui-components';

interface Props {
  title: string;
  isOpen?: boolean;
  message: string;
  disabled?: boolean;
  mainBtnText?: string;
  onCloseClick?: () => void;
}

export const InfoModal = ({
  title,
  message,
  isOpen = true,
  disabled = false,
  mainBtnText = 'Ok',
  onCloseClick,
}: Props) => {
  return (
    <ApModal
      isDismissible={false}
      isFluid={false}
      isOpen={isOpen}
      zIndex={998}
      hasClosed={onCloseClick}
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonMain onClick={onCloseClick} disabled={disabled}>
            {mainBtnText}
          </ApButtonMain>
        </ApButtonGroup>
      )}
    >
      <h3 style={customHeaderStyle}>
        <ApIcon iconName="outline_info" iconColor="orange" iconSize={24} />
        {title}
      </h3>
      <div>{message}</div>
    </ApModal>
  );
};

const customHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  textIndent: '8px',
};

import classNames from 'classnames';
import './Footer.css';

interface IProps {
  className?: string;
  sticky?: boolean;
}

export const Footer = ({ className, sticky = true }: IProps) => {
  return (
    <div className={classNames('c11-footer', {'c11-footer__sticky': sticky}, className)}>
      <div className="c11-footer-logo">
        <img className="c11-footer-logo__logo" src="/img/footer/alixpartners.png" alt="AlixPartners" />
        <div className="c11-footer-logo__separator" />
        <p>v{process.env.REACT_APP_VERSION}</p>
      </div>
      <div className="c11-footer-copyright__container">
        <p className="c11-footer-copyright__text">Privileged and Confidential</p>
        <p className="c11-footer-copyright__text">Information prepared at the advice of counsel</p>
      </div>
    </div>
  );
};

import { useCallback } from 'react';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { isEditableApUser, isEditableClient } from '../const/apUser';

/**
 * Depending on user role and current claim state, returns if a particular claim field is editable
 * by the user
 * @returns { isEditable: booean }
 */
export function useEditableField() {
  const { isApUser, isClient } = useAppUserType();

  const isEditable = useCallback(
    (field: string, column?: string): boolean => {
      if (column === 'total') return false;

      if (isApUser) {
        return isEditableApUser(field);
      }

      if (isClient) {
        return isEditableClient(field);
      }

      return true;
    },
    [isApUser, isClient],
  );

  return { isEditable };
}

import isNil from 'lodash/isNil';
import { FetchStatus } from 'types/fetch-status.types';

export const dataOrSkeleton = <T>(data: T, skeleton: T, status: FetchStatus): T => {
  if (status === FetchStatus.Fetching && isNil(data)) return skeleton;
  return data;
};

export const dataOrSkeletonIfData = <T>(
  data: T,
  skeleton: T,
): T => {
  if (isNil(data)) return skeleton;
  return data;
};

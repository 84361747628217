import classNames from 'classnames';
import { ApToggleSwitch } from '@alixpartners/ui-components';
import { InputComponent } from 'components/UIComponents/EntityTable';
import { DynamicField, labelByFieldType } from 'api/endpoints/dynamic-fields';
import './DynamicFields.css';
import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';

const DISPLAY_IN_OPTIONS = [
  { label: 'Categorization', value: PageSections.Categorization },
  { label: 'Additional information', value: PageSections.AdditionalInformation },
];

export const DynamicFieldInput: InputComponent<DynamicField> = ({
  columnKey,
  item,
  fromTable,
  handleToggleChange,
}) => {
  // we don't have an Add (item would passed as null) for dynamic fields, only Edit
  if (!item) return null;

  const { disabled, className, readonly, setValue } = fromTable;
  if (columnKey === 'name') {
    return (
      <input
        type="text"
        name="name"
        maxLength={50}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        readOnly={readonly}
        placeholder={item.placeholder ?? 'Enter name'}
        defaultValue={item?.name}
      />
    );
  }
  if (columnKey === 'type') {
    return (
      <span
        className={classNames(className, 'utils-capitalize')}
        defaultValue={item.type}
      >
        {item.type ? labelByFieldType[item.type] : ''}
      </span>
    );
  }

  if (columnKey === 'enabled') {
    return (
      <span
        className={classNames(className, 'edit-table__cell-switch', {
          'utils-obstruct-panel': disabled,
        })}
      >
        <ApToggleSwitch
          name="enabled"
          defaultChecked={item?.enabled ?? false}
          id={`switch-${item?.id}`}
          onChange={handleToggleChange}
        />
      </span>
    );
  }
  if (columnKey === 'displayIn') {
    return (
      <select
        disabled={disabled}
        onChange={(e) => setValue('displayIn', e.target.value)}
        className="display-in-select utils-clean-input"
        defaultValue={item.displayIn}
      >
        {DISPLAY_IN_OPTIONS.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    );
  }

  return null;
};

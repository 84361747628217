import _ from 'lodash';

/**
 * Queryfy. Transform javascript objects to graphql query.
 */
export const queryfy = (value: any): string => {
  if (_.isNil(value)) return 'null';

  if (_.isNumber(value)) return `${value}`;

  if (_.isDate(value)) return `"${value.toISOString()}"`;

  if (Array.isArray(value)) {
    const props = value.map((value) => `${queryfy(value)}`).join(',');
    return `[${props}]`;
  }

  if (typeof value === 'object') {
    const props = Object.keys(value)
      .filter((key) => !key.startsWith('__'))
      .map((key) => `${key}: ${queryfy(value[key])}`)
      .join(',\n');
    return `{${props}}\n`;
  }

  return `${JSON.stringify(value)}`;
};

// all properties in the query will be string values
export const queryfyAsStrings = (value: any, key?: string): string => {
  if (key === 'id') return `${value}`; // we should keep 'id' key as integer

  if (_.isNil(value)) return 'null';
  if (_.isNumber(value) || _.isBoolean(value)) return `"${value}"`;
  if (_.isDate(value)) return `"${value.toISOString()}"`;

  if (Array.isArray(value)) {
    const props = value.map((value) => `${queryfyAsStrings(value)}`).join(',');
    return `[${props}]`;
  }

  if (typeof value === 'object') {
    const props = Object.keys(value)
      .filter((key) => !key.startsWith('__'))
      .map((key) => `${key}: ${queryfyAsStrings(value[key], key)}`)
      .join(',\n');
    return `{${props}}\n`;
  }

  return `${JSON.stringify(value)}`;
};

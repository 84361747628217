import {
  DynamicFieldFilter as FilterType,
  DynamicFieldType,
  labelByFieldType,
} from 'api/endpoints/dynamic-fields';

export type FilterOption = {
  value: FilterType;
  label: string;
};

export const FILTER_OPTIONS: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  ...Object.values(DynamicFieldType).map((v) => ({
    value: v,
    label: labelByFieldType[v],
  })),
];

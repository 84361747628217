import { useEffect } from 'react';
import { useAppInitialDataContext } from 'utils/AppInitializer';

function useStyleInjector() {
  const { data } = useAppInitialDataContext();

  const { clientInfo } = data.basicInfo;

  // Bug fix for values that can occur if the environment has not been setup correctly
  if (clientInfo?.primaryBrandColor === 'string') {
    clientInfo.primaryBrandColor = '#5cb335';
  }
  if (clientInfo?.secondaryBrandColor === 'string') {
    clientInfo.secondaryBrandColor = '#000000';
  }

  useEffect(() => {
    const ID = 'dynamicTheme';
    let existingStyle = document.getElementById(ID);
    if (!existingStyle) {
      existingStyle = document.createElement('style');
      existingStyle.setAttribute('id', ID);
      document.head.appendChild(existingStyle);
    }
    // we use .ap-button-main to control colours and opacity of buttons
    // when we change primary and secondary colours

    // other parts of the website are controlled by
    // two css variables
    // --client-primary-brand-color and --client-secondary-brand-color
    existingStyle.innerHTML = `
        .ap-button-main:enabled {
          ${
            clientInfo?.primaryBrandColor
              ? `background: var(--client-primary-brand-color);`
              : ''
          }
        }
        .ap-button-main:hover:enabled {
          ${
            clientInfo?.primaryBrandColor
              ? `background: var(--client-primary-brand-color);
          opacity: 0.5;`
              : ''
          }
        }
        .ap-button-main:focus:enabled {
          ${
            clientInfo?.primaryBrandColor
              ? `background: var(--client-primary-brand-color);
          opacity: 0.8;`
              : ''
          }
        }
        .ap-button-main:active:enabled {
          ${
            clientInfo?.primaryBrandColor
              ? `background: ${clientInfo?.primaryBrandColor};
          opacity: 0.9;`
              : ''
          }
        }
  
        :root {
          ${
            clientInfo?.primaryBrandColor
              ? `--ap-color-ui-green-primary: ${clientInfo?.primaryBrandColor} !important;`
              : ''
          }
  
          ${
            clientInfo?.primaryBrandColor
              ? `--client-primary-brand-color: ${clientInfo?.primaryBrandColor};`
              : '--client-primary-brand-color: var(--ap-color-ui-green-primary);'
          }
          ${
            clientInfo?.secondaryBrandColor
              ? `--client-secondary-brand-color: ${clientInfo?.secondaryBrandColor};`
              : '--client-secondary-brand-color: var(--ap-color-ui-grey-darker)'
          }
        }
      `;
  }, [clientInfo?.primaryBrandColor, clientInfo?.secondaryBrandColor]);
}

export default useStyleInjector;

import { get } from '../apiRequest';

export interface EhxibitContractType {
  id: 0;
  exhibitTypeName: string;
}

export const contractExhibitTypes = {
  all: () => get<EhxibitContractType[]>('/v1/ContractExhibitTypes/all'),
};

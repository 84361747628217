import Select from 'components/UIComponents/Select';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { IBatchItem } from '../../contractsBatchEdit.type';
import { colProp } from '../../contractsBatchEdit.const';
import { contractExhibitTypeIdSet } from 'reducer/contractActionNameReducer';

export const ContractResolutionName = ({
  onChange,
  addCascadingItem,
}: {
  addCascadingItem: (item: IBatchItem) => void;
  onChange: (val: number) => void;
}) => {
  const { actionNameOptions } = useAppSelector((s) => s.contractActionNameSelection);
  const { contractExhibitTypes } = useAppSelector((s) => s.selectable.data);
  const dispatch = useAppDispatch();

  return (
    <div className="action-name-edit">
      <div className="action-name-edit__selector">
        <label>Exhibit type</label>
        <Select
          name={'Exhibit type'}
          options={[{ label: '', value: '' }, ...contractExhibitTypes]}
          onChange={(e) => {
            dispatch(contractExhibitTypeIdSet(+e.target.value));
            addCascadingItem({
              id: '',
              propName: colProp.contractExhibitTypeId,
              value: +e.target.value,
            });
          }}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution Name</label>
        <Select
          name={'Resolution Name'}
          options={actionNameOptions.map((c) => ({
            label: c.contractActionName,
            value: c.id,
          }))}
          onChange={(e) => onChange(+e.target.value)}
        />
      </div>
    </div>
  );
};

import { IMajorMinorValue, MajorCategories, MinorCategories } from 'api/endpoints';
import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { forEach } from 'lodash';

export const mapStringToMajMinorValue = (value: string): IMajorMinorValue => {
  const [value1, value2] = value.split(',');

  return {
    majorCategoryId: value1 ? +value1 : null,
    minorCategoryId: value2 ? +value2 : null,
  };
};

export const mapMajMinorToString = (value: IMajorMinorValue) =>
  `${value.majorCategoryId ?? ''},${value.minorCategoryId ?? ''}`;

export const mapMajorCategoriesToOption = (major: MajorCategories): IOption => ({
  label: major.majorCategory,
  value: `${major.id},`,
  data: major,
});
export const mapMinorCategoriesToOption = (
  major: MajorCategories,
  minor: MinorCategories,
): IOption => ({
  data: minor,
  label: `${major.majorCategory} - ${minor.minorCategory}`,
  labelNode: minor.minorCategory,
  value: `${major.id},${minor.id}`,
});

export const mapMajorCategories = (categories?: MajorCategories[]) => {
  const options: IOption[] = [];
  forEach(categories, (cat) => {
    options.push(mapMajorCategoriesToOption(cat));
    if (cat.minorCategories) {
      forEach(cat.minorCategories, (mCat) => {
        options.push(mapMinorCategoriesToOption(cat, mCat));
      });
    }
  });

  return options;
};

import { useMemo, useState } from 'react';
import {
  ApButtonDanger,
  ApButtonMain,
  ApIcon,
  ApModal,
} from '@alixpartners/ui-components';
import classNames from 'classnames';
import {
  DeleteSection,
  FileStatus,
  FileType,
  IUploadItem,
} from 'types/dataUploaderTypes';
import { DeleteDataItem } from './DeleteDataItem';
import { getWarningMessage } from 'pages/SettingsPage/utils/getDeletionWarningMessage';
import { DeleteLoader } from 'components/UIComponents/DeleteLoader/DeleteLoader';

type DeleteDataItemCardProps = {
  section: DeleteSection;
  sectionFiles: IUploadItem[];
  deleting?: boolean;
  typeToDelete?: FileType;
  handleDelete: (allFiles: boolean, section: DeleteSection) => void;
};

export const DeleteDataItemCard = ({
  section,
  sectionFiles,
  deleting,
  typeToDelete,
  handleDelete,
}: DeleteDataItemCardProps) => {
  const [openModal, setOpenModal] = useState(false);
  const allDataType = useMemo(() => section.type === FileType.All, [section]);

  const handleOnDelete = () => {
    handleDelete(allDataType, section);
    setOpenModal(false);
  };

  const renderFooter = () => (
    <>
      <ApButtonMain onClick={() => setOpenModal(false)}>Cancel</ApButtonMain>
      <ApButtonDanger onClick={handleOnDelete}>Yes, delete</ApButtonDanger>
    </>
  );

  const renderCardContent = ({
    className,
    showIcon,
  }: {
    className?: string;
    showIcon?: boolean;
  }) => {
    return sectionFiles.map((file, idx) => (
      <div
        key={file.id}
        className={classNames(className, {
          withBorder: sectionFiles.length > 1 && idx !== sectionFiles.length - 1,
          published: file.status === FileStatus.Published,
        })}
      >
        <DeleteDataItem file={file} showIcon={!!showIcon} />
      </div>
    ));
  };

  return (
    <div className="delete-data__item-card" key={section.type}>
      <div className="delete-data__item-title">
        <h3>{section.label}</h3>
        <ApButtonDanger onClick={() => setOpenModal(true)} disabled={deleting}>
          {allDataType && <ApIcon iconName="outline_warning" />}
          Delete {allDataType ? '' : 'all'} {section.label}
        </ApButtonDanger>
      </div>
      {renderCardContent({
        className: 'delete-data__item-card-content',
        showIcon: true,
      })}
      {typeToDelete === section.type && deleting && (
        <DeleteLoader label={section.label} />
      )}
      <ApModal
        isOpen={openModal}
        isDismissible
        footer={renderFooter}
        hasClosed={() => setOpenModal(false)}
        className="delete-data-modal"
        header={`Are you sure you want to delete ${section.label}?`}
      >
        <h3>
          <p>
            <ApIcon
              iconName="error"
              iconColor="var(--ap-color-red1)"
              iconSize="lg"
            />
            {getWarningMessage(section.type)}
          </p>
        </h3>
        {renderCardContent({ className: 'delete-modal-item' })}
      </ApModal>
    </div>
  );
};

import { actionNames, ServerContractActionName } from 'api/endpoints/action-names';
import {
  contractExhibitExports,
  ContractExhibitPost,
  ExhibitExportContract,
} from 'api/endpoints/contractExhibitExports';
import { contractExhibitTypes } from 'api/endpoints/contractExhibitTypes';
import { GraphQlApolloClient } from 'api/graphql';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { OptionItem } from 'components/ApNiceSelect';
import { graphqlGetContracts } from 'utils/agGridHotchocolate/graphqlGetContracts';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';
import { ContractExhibit } from './exhibityContractType';

// const actionNameId = 'contractActionNameId';

const NONE_ID = 0;

export interface ExhibitTypeActionNameOptions {
  [exhibitTypeId: string]: OptionItem[];
}

export const apiExhibitContract = {
  historyList: async () => {
    const result = await contractExhibitExports.all();
    return result ?? [];
  },

  deleteHistoryItem: contractExhibitExports.delete,

  exhibitTypes: async (): Promise<OptionItem[]> => {
    const types = await contractExhibitTypes.all();
    if (!types) return [];

    const result = types.map((r) => ({
      value: r.id,
      label: r.exhibitTypeName,
    }));

    return result;
  },

  actionNames: async (): Promise<ExhibitTypeActionNameOptions> => {
    const response = await actionNames.getContractActionNames();
    if (response.type !== ResponseType.OK) return {};

    const itemsWithoutNone = response.data.filter((r) => r.id !== NONE_ID);
    return getActionNameDictionary(itemsWithoutNone);
  },

  getContracts: async (client: GraphQlApolloClient, exhibit: ContractExhibit) => {
    if (!exhibit.actionNameId) return [];

    const filterModel: AgGridFilterModel = {
      contractActionNameId: {
        filterType: 'number',
        type: 'equals',
        filter: exhibit.actionNameId,
      },
    };

    return graphqlGetContracts({ filterModel, endRow: 1000 }, client);
  },

  getContractsByMembers: async (
    client: GraphQlApolloClient,
    contractMembers: ExhibitExportContract['contractExhibitMembers'],
  ) => {
    if (!contractMembers?.length) return [];

    const filterModel: AgGridFilterModel = {
      id: {
        filterType: 'set',
        values: contractMembers.map((r) => r.contractId),
      },
    };

    return graphqlGetContracts({ filterModel, endRow: 1000 }, client);
  },

  postExhibitExports: (value: ContractExhibit) => {
    if (!value.typeId || !value.actionNameId)
      return console.error("type and action can't be empty");

    const param: ContractExhibitPost = {
      contractExhibitTypeId: value.typeId,
      contractActionNameId: value.actionNameId,
      contractIds: value.contracts.map((r) => r.id),
    };

    contractExhibitExports.post(param);
  },
};

function getActionNameDictionary(values: ServerContractActionName[]) {
  const result = values.reduce((dictionary, item) => {
    const value: OptionItem = {
      value: item.id,
      label: item.contractActionName,
    };

    const id = item.contractExhibitTypeId;
    if (!dictionary[id]) {
      dictionary[id] = [value];
    } else {
      dictionary[id].push(value);
    }

    return dictionary;
  }, {} as ExhibitTypeActionNameOptions);

  return result;
}

import { configureStore } from '@reduxjs/toolkit';
import actionNamesReducer, { ActionNamesSlice } from './actionNamesReducer';
import actionsSelectOptionsReducer, {
  ActionsSelectOptionsState,
} from './actionsSelectOptions';
import allUsersReducer, { AllUsersSlice } from './allUsers';
import applicationUserReducer, {
  ApplicationUserOuterSlice,
} from './applicationUserReducer';
import {
  assignedGroupUsersListReducer,
  AssignedGroupUsersSlice,
  FetchAssignedUserGroupsAction,
} from './assigned-group-users';
import claimActionNameSelectionReducer, {
  ClaimActionNameSelectionSlice,
} from './claimActionNameSelectionReducer';
import claimLogDataReducer from './claimLogsDataReducer';
import claimReviewerReducer from './claimReviewerReducer';
import claimsDashboardDetailedDataReducer from './claimsDashboardDetailedDataReducer';
import claimsReducer from './claimsReducer';
import contractActionNameSelectionReducer, {
  ContractActionNameSelectionSlice,
} from './contractActionNameReducer';
import contractLogsDataReducer from './contractLogsDataReducer';
import contractPaymentPositionsReducer from './contractPaymentPositionsReducer';
import contractReviewerReducer from './contractReviewerReducer';
import contractsDashboardDetailedDataReducer from './contractsDashboardDetailedDataReducer';
import contractsReducer from './contractsReducer';
import contractUserGroupsReducer, {
  ContractsUserGroupsSlice,
} from './contractUserGroupsReducer';
import dynamicFieldsReducer, { DynamicFieldsSlice } from './dynamicFields';
import exhibitReducer from './exhibits/exhibitReducer';
import exhibitsReducer from './exhibits/exhibitsReducer';
import {
  GlobalNotesSlice,
  scheduleGlobalNotesReducer,
  sofaGlobalNotesReducer,
} from './globalNotesReducer';
import reportLegalEntitiesReducer, {
  ReportLegalEntitiesSlice,
} from './reportLegalEntitiesReducer';
import reportPartsReducer, { ReportPartsSlice } from './reportPartsReducer';
import selectableDataReducer from './selectableDataReducer';
import slipSheetsReducer, { SlipSheetsSlice } from './slipSheetsReducer';
import userGroupsReducer, { UserGroupsSlice } from './user-groups';
import userDashboardTasksReducer, {
  UserDashboardSOuterlice,
} from './userDashboardTasksReducer';
import userLogsReducer from './usersLogsReducer';
import claimsCategoriesReducer from './claimCategoriesReducer';
import contractCategoriesReducer from './contractCategoriesReducer';
import claimsContractsExportReducer from './claimsContractsExportReducer';
import batchEditReducer from './batchEditReducer';
import documentsReducer from './documentsReducer';

export type Action = FetchAssignedUserGroupsAction;

export type RootState = {
  selectable: ReturnType<typeof selectableDataReducer>;
  batchEdit: ReturnType<typeof batchEditReducer>;
  claims: ReturnType<typeof claimsReducer>;
  claimLogs: ReturnType<typeof claimLogDataReducer>;
  claimActionNameSelection: ClaimActionNameSelectionSlice;
  claimReviewer: ReturnType<typeof claimReviewerReducer>;
  claimsDashboardDetailedData: ReturnType<typeof claimsDashboardDetailedDataReducer>;
  claimsCategoriesSettings: ReturnType<typeof claimsCategoriesReducer>;
  contracts: ReturnType<typeof contractsReducer>;
  contractLogs: ReturnType<typeof contractLogsDataReducer>;
  contractActionNameSelection: ContractActionNameSelectionSlice;
  contractReviewer: ReturnType<typeof contractReviewerReducer>;
  contractPaymentPositions: ReturnType<typeof contractPaymentPositionsReducer>;
  contractsDashboardDetailedData: ReturnType<
    typeof contractsDashboardDetailedDataReducer
  >;
  contractsUserGroups: ContractsUserGroupsSlice;
  contractCategoriesSettings: ReturnType<typeof contractCategoriesReducer>;
  claimsContractsExport: ReturnType<typeof claimsContractsExportReducer>;
  documents: ReturnType<typeof documentsReducer>;
  assignedGroupUsers: AssignedGroupUsersSlice;
  actionsSelectOptions: ActionsSelectOptionsState;
  allUsers: AllUsersSlice;
  actionNames: ActionNamesSlice;
  dynamicFields: DynamicFieldsSlice;
  exhibits: ReturnType<typeof exhibitsReducer>;
  exhibit: ReturnType<typeof exhibitReducer>;
  applicationUser: ApplicationUserOuterSlice;
  userDashboardTasks: UserDashboardSOuterlice;
  userGroups: UserGroupsSlice;
  reportLegalEntities: ReportLegalEntitiesSlice;
  reportParts: ReportPartsSlice;
  sofaGlobalNotes: GlobalNotesSlice;
  scheduleGlobalNotes: GlobalNotesSlice;
  slipSheets: SlipSheetsSlice;
  userLogs: ReturnType<typeof userLogsReducer>;
};

const store = configureStore<RootState, Action>({
  reducer: {
    selectable: selectableDataReducer,
    batchEdit: batchEditReducer,
    claims: claimsReducer,
    claimLogs: claimLogDataReducer,
    claimActionNameSelection: claimActionNameSelectionReducer,
    claimReviewer: claimReviewerReducer,
    claimsDashboardDetailedData: claimsDashboardDetailedDataReducer,
    claimsCategoriesSettings: claimsCategoriesReducer,
    contracts: contractsReducer,
    contractLogs: contractLogsDataReducer,
    contractActionNameSelection: contractActionNameSelectionReducer,
    contractPaymentPositions: contractPaymentPositionsReducer,
    contractReviewer: contractReviewerReducer,
    contractsDashboardDetailedData: contractsDashboardDetailedDataReducer,
    contractCategoriesSettings: contractCategoriesReducer,
    contractsUserGroups: contractUserGroupsReducer,
    documents: documentsReducer,
    claimsContractsExport: claimsContractsExportReducer,
    assignedGroupUsers: assignedGroupUsersListReducer,
    actionsSelectOptions: actionsSelectOptionsReducer,
    actionNames: actionNamesReducer,
    allUsers: allUsersReducer,
    dynamicFields: dynamicFieldsReducer,
    exhibit: exhibitReducer,
    exhibits: exhibitsReducer,
    applicationUser: applicationUserReducer,
    userDashboardTasks: userDashboardTasksReducer,
    userGroups: userGroupsReducer,
    reportLegalEntities: reportLegalEntitiesReducer,
    reportParts: reportPartsReducer,
    sofaGlobalNotes: sofaGlobalNotesReducer,
    scheduleGlobalNotes: scheduleGlobalNotesReducer,
    slipSheets: slipSheetsReducer,
    userLogs: userLogsReducer,
  },
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type GetState = () => RootState;

export default store;

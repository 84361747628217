import Select from 'components/UIComponents/Select';
import { useAppSelector } from 'hooks/reducerHooks';
import { useEditActionField } from 'hooks/useEditActionField';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import {
  useActionNames,
  useActionsSelectOptions,
} from 'pages/SettingsPage/sections/ActionNames/useActionNames';
import { dateISOtoISO8601 } from 'utils/formatDate';
import './ActionNameEdit.css';

export type ActionNameEditProps = {
  onChange: (item: number) => void;
  label: string;
};

export const ActionNameEdit = ({ onChange, label }: ActionNameEditProps) => {
  useActionNames({ section: SectionType.Claims });
  useActionsSelectOptions(SectionType.Claims);
  const {
    actionTypesByStatus,
    substatusOptions,
    actionNameOptions,
    claimActionInfo,
    actionStatuses,
  } = useAppSelector((s) => s.claimActionNameSelection);

  const { actionTypeId, actionSubstatusId, actionNameId, actionStatus } =
    claimActionInfo;

  const { editActionField } = useEditActionField(SectionType.Claims);

  return (
    <>
      <div className="action-name-edit__selector">
        <label>Workflow status</label>
        <Select
          value={actionStatus}
          name={'Workflow status'}
          options={actionStatuses.map((status) => ({
            label: status.actionStatusDisplayName,
            value: status.actionStatus,
          }))}
          onChange={(e) => editActionField('actionStatus', e.target.value)}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution type</label>
        <Select
          value={actionTypeId}
          name={'Resolution type'}
          options={actionTypesByStatus.map((actType) => ({
            label: actType.actionExhibitTypeName,
            value: actType.id,
          }))}
          onChange={(e) => editActionField('actionTypeId', e.target.value)}
          disabled={!actionStatus}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution subtype</label>
        <Select
          value={actionSubstatusId}
          name={'Resolution subtype'}
          options={substatusOptions.map((sub) => ({
            label: sub.actionSubStatusName,
            value: sub.id,
          }))}
          onChange={(e) => editActionField('actionSubstatusId', e.target.value)}
          disabled={!actionStatus}
        />
      </div>
      <div className="action-name-edit__selector">
        <label>Resolution name</label>
        <Select
          value={actionNameId}
          name={'Resolution name'}
          options={actionNameOptions.map((o) => ({
            label: `${o.name} ${
              o.hearingDate ? `[${dateISOtoISO8601(o.hearingDate)}]` : ''
            }`,
            value: `${o.id}`,
          }))}
          onChange={(e) => {
            editActionField('actionNameId', e.target.value);
            onChange(Number(e.target.value));
          }}
          disabled={!actionStatus}
        />
      </div>
    </>
  );
};

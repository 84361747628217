import { ContractRow } from 'api/endpoints';
import Excel, { Column } from 'exceljs';
import _ from 'lodash';
import { titleStyle } from './templatesType';

export const createExcelWorkbook = (
  sheetName: string,
  columns: Partial<Column>[],
) => {
  const workbook = new Excel.Workbook();
  const firstSheet = workbook.addWorksheet(sheetName);

  firstSheet.columns = columns;
  const titleRow = firstSheet.lastRow;
  titleRow?.eachCell((r) => (r.style = titleStyle));

  return workbook;
};

export const getFullAddressName = (row: ContractRow) => {
  const { address1, address2, address3, address4 } = row;
  const { city, zip, state, country } = row;

  const address = _([address1, address2, address3, address4]).compact().join(', ');
  const fullAddress = _([address, city, state, zip, country]).compact().join(', ');

  return fullAddress;
};

export const getSortedSheetItems = (
  data: ContractRow[],
  sortBy: keyof ContractRow,
) => {
  const sheetItems = [...data].sort((a: ContractRow, b: ContractRow) =>
    String(a[sortBy])?.localeCompare(String(b[sortBy])),
  );
  return sheetItems;
};

import { ClaimRow } from 'api/endpoints';
import { exhibits as exhibitsApi } from 'api/endpoints/exhibits';
import { useEffect, useMemo, useState } from 'react';

import {
  ExhibitHistoryTable,
  loadExhibits,
} from './components/ExhibitHistory/ExhibitHistoryTable';
import { ExhibitDataSection } from './components/sections/ExhibitDataSection';
import { ExhibitStructureSection } from './components/sections/ExhibitStructureSection';
import { GlobalFootnoteSection } from './components/sections/GlobalFootnoteSection';

import useGraphql from 'api/graphql/useGraphql';
import { ExhibitExporterTopMenu } from 'components/ExhibitsExport/ExhibitExporterTopMenu';
import { ExhibitExportPanel } from 'components/ExhibitsExport/ExhibitExportPanel/ExhibitExportPanel';
import { Layout } from 'components/Layout';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { intersection, isNil } from 'lodash';
import { clearExhibit, exhibitLoading } from 'reducer/exhibits/exhibitReducer';
import {
  fetchActionNamesThunk,
  fetchActionsSelectOptions,
} from 'reducer/thunks/actions-thunks';
import './ExhibitExporterPage.css';
import useExhibitExporter from './hook/useExhibitExporter';
import { exportExhibitExcelFile } from './templates/excelExporter';
import { getClaimsByColumnSet } from './utils/apiExhibitClaim';
import { getClaimNumber } from './utils/field';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

export const ExhibitExporterPage = () => {
  const dispatch = useAppDispatch();
  const [client] = useGraphql();
  const exhibitExporterData = useExhibitExporter();
  const { projectDetails } = useAppSelector((s) => s.selectable.data);

  const [isShowExhibitHistory, setIsShowExhibitHistory] = useState(false);

  const toggleExhibitHistory = () => {
    setIsShowExhibitHistory(!isShowExhibitHistory);
  };

  const actionNames = useAppSelector((s) => s.actionNames);
  const { currentExhibit, loading } = useAppSelector((s) => s.exhibit);

  useEffect(() => {
    dispatch(fetchActionNamesThunk());
    dispatch(fetchActionsSelectOptions());
  }, [dispatch]);

  const saveExhibitRecord = async () => {
    await exhibitsApi.create({
      ...currentExhibit,
      referencingClaimFootnotes: [],
      claims: currentExhibit.claims.map((c: ClaimRow) => c.id),
      structureFooterOptions: currentExhibit.structureFooterOptions.map(
        (o) => o.value,
      ),
      structureHeaderOptions: currentExhibit.structureHeaderOptions.map(
        (o) => o.value,
      ),
    });
    await loadExhibits(dispatch);
  };

  const handleExportClick = async () => {
    if (!currentExhibit.claims.length || !currentExhibit.actionNameId) return;

    const action = actionNames.data?.find(
      (a) => a.id === currentExhibit.actionNameId,
    );
    let claimIds: number[] = currentExhibit.claims?.map((c: ClaimRow) => c.id);
    if (exhibitExporterData.selectedClaims.length) {
      const selectedClaimIds: number[] = exhibitExporterData.selectedClaims.map(
        (c) => c.id,
      );
      claimIds = intersection(claimIds, selectedClaimIds);
    }
    const claims = await getClaimsByColumnSet(client, claimIds, 'id');

    dispatch(exhibitLoading(true));
    await saveExhibitRecord();
    dispatch(exhibitLoading(false));
    await exportExhibitExcelFile(
      { ...currentExhibit, ...projectDetails, claims },
      action,
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearExhibit());
    };
  }, [dispatch]);

  const hasEmptyClaimNumbers = useMemo(() => {
    const claimNumberCells = currentExhibit.claims.map(getClaimNumber);
    return claimNumberCells.some(isNil);
  }, [currentExhibit.claims]);

  const isGenerateButtonActive =
    currentExhibit.exhibitNumber &&
    currentExhibit.objectionBasis &&
    currentExhibit.actionNameId &&
    currentExhibit.claims.length &&
    !hasEmptyClaimNumbers;

  return (
    <ApUserRole>
      <Layout>
        <ExhibitExporterTopMenu />
        <ExhibitExportPanel
          title="Claim Exhibit Exporter"
          showHistory={isShowExhibitHistory}
          historyElement={<ExhibitHistoryTable />}
          onShowHistoryClick={toggleExhibitHistory}
        >
          <ExhibitDataSection
            hasEmptyClaimNumbers={hasEmptyClaimNumbers}
            {...exhibitExporterData}
          />
          <ExhibitStructureSection />

          <GlobalFootnoteSection />

          <ButtonMainLoader
            loading={loading}
            autoFocus={false}
            disabled={!isGenerateButtonActive}
            iconName="download"
            onClick={handleExportClick}
          >
            {loading ? 'Preparing exhibit file' : 'Generate exhibit (.xlsx)'}
          </ButtonMainLoader>
        </ExhibitExportPanel>
      </Layout>
    </ApUserRole>
  );
};

import { get } from '../apiRequest';

export type AssignedGroupUser = {
  userGroupId: number;
  adUserId: string;
  firstName: string;
  lastName: string;
};

export type AssignedGroup = {
  id: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  userGroupName: string;
  majorCategoryId: string;
  majorCategoryName: string;
  minorCategoryId: string;
  minorCategoryName: string;
  applicationUsers: AssignedGroupUser[];
};

//!!! Deprecated !!! use "userGroup.ts => claimUserGroup" instead
export const assignedGroupUsers = {
  get: () => get<AssignedGroup[]>(`v1/UserGroup/getAssignedGroupUsers`),
  getById: (id: number) =>
    get<AssignedGroup[]>(`v1/UserGroup/getAssignedGroupUsers/${id}`),
};

import { jsonFetchGet, jsonFetchPost } from 'api/jsonFetch/authJsonFetch';
import { del, downloadFileGet, put } from '../apiRequest';

export enum ClaimAttachmentDocumentType {
  ClaimImage = 1,
  CourtExhibit = 2,
  VendorAgreement = 3,
  SettlementAgreement = 4,
  Communications = 5,
  Reconciliations = 6,
  ScheduleDetail = 7,
  WithdrawalForm = 8,
  Other = 9,
  Email = 10,
}

export interface CreateClaimAttachmentParams {
  claimId: number;
  claimUploadDocuments: CreateClaimAttachmentItem[];
}

export interface CreateClaimAttachmentItem {
  referenceNumber: string;
  documentName: string;
  documentType: ClaimAttachmentDocumentType;
  displayName: string;
  comment: string | null;
}

export interface CreateClaimAttachmentError {
  documentName: string;
  documentType: ClaimAttachmentDocumentType;
  displayName: string;
  error: string;
}

export interface ClaimAttachment {
  id: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedData: string;
  claimId: number;
  documentName: string;
  displayName: string;
  referenceNumber: string;
  documentType: ClaimAttachmentDocumentType;
  claimImageUrl: string;
  comment: string;
}

type NotFoundError = {
  status: 404;
  error: {
    statusCode: number;
  };
};

export type PostError =
  | NotFoundError
  | {
      status: 400;
      error: CreateClaimAttachmentError[];
    };

export const claimAttachments = {
  async get(claimId: number) {
    return jsonFetchGet<ClaimAttachment[], NotFoundError>(
      `v1/ClaimDocuments/${claimId}`,
    );
  },
  async post(params: CreateClaimAttachmentParams) {
    return jsonFetchPost<ClaimAttachment[], PostError>(
      `v1/ClaimDocuments/createClaimDocument`,
      params,
    );
  },
  async downloadDoc(docId: number) {
    return downloadFileGet(`v1/ClaimDocuments/download/${docId}`);
  },
  canDownloadClaimImage(claimId: number) {
    return jsonFetchGet<{ canDownload: boolean }, NotFoundError>(
      `v1/ClaimDocuments/canDownload/${claimId}`,
    );
  },
  deleteDocument: (id: number) => del(`v1/ClaimDocuments/${id}`),

  saveComment(documentId: number, comment: string) {
    return put(`v1/ClaimDocuments/${documentId}?comment=${comment}`, {});
  },
};

import {
  ApButtonGroup,
  ApButtonMain,
  ApButtonSecondary,
  ApIcon,
} from '@alixpartners/ui-components';

import { ErrorModal } from 'components/ModalWindows/UploadModal/ErrorModal';
import { BatchEditorUploadModal } from './BatchEditorUploadModal';
import './BatchEditUploadDialog.css';

interface Props {
  uploadLabel: string;
  onDownloadError: () => void;
  setShowErrorModal: (show: boolean) => void;
  setShowUploadModal: (show: boolean) => void;
  onSubmit: (file: File) => Promise<void>;
  showErrorModal?: boolean;
  showUploadModal?: boolean;
  isUploading?: boolean;
}

export const BatchEditUploadDialog = ({
  uploadLabel,
  onDownloadError,
  showErrorModal,
  setShowErrorModal,
  showUploadModal,
  setShowUploadModal,
  onSubmit,
  isUploading,
}: Props) => {
  if (showErrorModal) {
    return (
      <ErrorModal
        header={ErrorModalHeader}
        onClosed={() => setShowErrorModal(false)}
        renderFooter={() => (
          <ApButtonGroup>
            <ApButtonSecondary
              className="ap-button-casual"
              onClick={() => setShowErrorModal(false)}
            >
              Cancel
            </ApButtonSecondary>
            <ApButtonMain onClick={onDownloadError}>
              <ApIcon iconName="save_alt" />
              Download error log file
            </ApButtonMain>
          </ApButtonGroup>
        )}
        isOpen
        content={[
          "We've detected some errors in the file you are trying to upload.",
          'Please use the button below to download the errors file. Once you solved all the errors you can try upload the file again.',
        ]}
      />
    );
  }

  if (showUploadModal) {
    return (
      <BatchEditorUploadModal
        label={uploadLabel}
        setShowUploadModal={setShowUploadModal}
        onSubmit={onSubmit}
        showUploadModal={showUploadModal}
        isUploading={isUploading}
      />
    );
  }

  return null;
};

const ErrorModalHeader = (
  <span className="upload-error-modal__title">
    <ApIcon iconName="error" iconColor="#D97706" />
    Error: the file couldn't be uploaded
  </span>
);

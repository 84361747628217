import { ClaimContractLogPaginationSort } from 'api/endpoints/claimLogs';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useQueryParams, withDefault } from 'use-query-params';
import { SORT_OPTIONS } from '../types';
const DEFAULT_PAGE = 1;
const DEFAULT_SORT = SORT_OPTIONS[1].value;

const SortQPSettings = {
  urlName: 'cc-sort',
  encode: (v: string) => v,
  decode: (sortString: any) => {
    const sortOption = SORT_OPTIONS.find((o) => o.value === sortString);
    return sortOption?.value ?? DEFAULT_SORT;
  },
};
const PageQPSettings2 = {
  encode: (v: number) => `${v}`,
  decode: (pageString: any) => {
    let page = Number.parseInt(pageString ?? '', 10);
    page = Number.isNaN(page) ? DEFAULT_PAGE : page;
    if (page <= DEFAULT_PAGE) page = DEFAULT_PAGE;
    return page;
  },
  urlName: 'cc-page',
};

const SortParam = withDefault(SortQPSettings, DEFAULT_SORT);

export type ClaimLogQueryParams = {
  page: number;
  sort: ClaimContractLogPaginationSort;
};

export function useClaimContractLogPagination() {
  const { pathname, search } = useLocation();
  const [query, setQuery] = useQueryParams({
    page: PageQPSettings2,
    sort: SortParam,
  });

  const { page, sort } = query;

  const setSort = useCallback(
    (value: ClaimContractLogPaginationSort) => {
      setQuery({ sort: value, page: DEFAULT_PAGE });
    },
    [setQuery],
  );

  const setPage = useCallback(
    (value: number) => {
      setQuery({ sort, page: value });
    },
    [setQuery, sort],
  );

  const restOfQs = useMemo(() => {
    // Maintain the rest of the query string params for this page, like tabs, but not only.
    const usp = new URLSearchParams(search);
    usp.delete('cc-page');
    return usp.toString();
  }, [search]);

  /** Use with navigation links */
  const formatUrl = useCallback(
    (givenPage: number) => {
      return `${pathname}?${restOfQs}&cc-page=${givenPage}`;
    },
    [pathname, restOfQs],
  );

  return {
    page,
    sort,
    setPage,
    setSort,
    formatUrl,
  };
}

import { Cell, ClaimsDocumentsTable, Row } from 'components/ClaimsDocumentsTable';
import { UploadModal } from 'components/ModalWindows/UploadModal';
import { ApButtonMainEx, ButtonIcon } from 'components/UIComponents/Buttons';
import { IconLoading } from 'components/UIComponents/IconLoading';
import { useCallback, useState } from 'react';
import {
  SingleViewPanel,
  SingleViewPanelTitle,
} from '../../../../components/SingleViewPanel';
import { SettlementDocument } from '../../api/apiGetSettlementDocuments';
import { useSettlementSingleAttachmentsContext } from '../../dataProvider/SettlementSingleAttachmentsProvider';
import './SettlementSingleDocuments.css';

export const SettlementSingleDocuments = () => {
  const documents = useSettlementSingleAttachmentsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="settlement-single-documents">
      <SingleViewPanel>
        <div className="settlement-single-documents__upload">
          <ApButtonMainEx
            className=""
            iconName="add"
            onClick={() => setIsModalOpen(true)}
          >
            Upload new document
          </ApButtonMainEx>
        </div>
        <SingleViewPanelTitle title="List of documents" />
        <ClaimsDocumentsTable
          className="settlement-single-documents-grid"
          showLoader={!!documents.uploadProgress || documents.loading}
          emptyListMessage={<span>No attachments for this settlement</span>}
          headerItems={[
            'Document title',
            'Document type',
            'Docket / ref number',
            'Added by',
            'Date added',
            'Actions',
          ]}
        >
          {documents.items.map((item) => (
            <Row key={item.id}>
              <Cell style={{ width: '25%' }}>{item.displayName}</Cell>
              <Cell>{item.documentTypeName}</Cell>
              <Cell>{item.docketNumber}</Cell>
              <Cell>{item.createdBy}</Cell>
              <Cell>{item.createdDateFormatted}</Cell>
              <ActionsCell document={item} onClick={documents.downloadFile} />
            </Row>
          ))}
        </ClaimsDocumentsTable>
      </SingleViewPanel>
      {isModalOpen && (
        <UploadModal
          label="Upload filed"
          onClosed={() => setIsModalOpen(false)}
          onSubmit={documents.addAttachments}
          typeOptions={documents.statusOptions}
        />
      )}
    </div>
  );
};

const ActionsCell = (props: {
  document: SettlementDocument;
  onClick: (document: SettlementDocument) => Promise<void>;
}) => {
  const { document, onClick } = props;
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    setLoading(true);
    await onClick(document);
    setLoading(false);
  }, [document, onClick]);

  return (
    <Cell className="settlement-single-documents-grid__actions">
      {loading ? (
        <IconLoading className="settlement-single-documents-grid__item-loading" />
      ) : (
        <ButtonIcon
          type="button"
          icon="download_file"
          iconColor="var(--ap-color-ui-grey-darker)"
          onClick={handleClick}
        />
      )}
    </Cell>
  );
};

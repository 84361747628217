import { SlipSheet } from 'api/endpoints';
import { SofaDeleteConfirm } from 'pages/SOFAsAndSchedules/Components/SofaDeleteConfirm';
import { FileDisplay } from '../../Components';
import { SlipSheetEntityInfo } from './components/SlipSheetEntityInfo';
import './SlipSheetDeleteModal.css';

interface Props {
  item: SlipSheet;
  onClose: (confirm: boolean) => void;
}

export const SlipSheetDeleteModal = ({ item, onClose }: Props) => {
  return (
    <SofaDeleteConfirm
      title="Are you sure you want to delete this slipsheet?"
      yesButton="Yes, delete slipsheet"
      onClose={onClose}
    >
      <div className="slip-sheet-delete-info">
        <FileDisplay displayName={item.displayName} />
        <SlipSheetEntityInfo item={item} />
      </div>
    </SofaDeleteConfirm>
  );
};

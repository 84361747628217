import classNames from 'classnames';
import { InputComponent } from 'components/UIComponents/EntityTable';
import './UserGroups.css';
import { GroupUsersItem, ICategories } from 'api/endpoints';
import { useCallback, useEffect, useMemo } from 'react';
import { useAllUsers } from 'hooks/useAllUsers';
import { MultiSelect } from 'components/UIComponents/MultiSelect';
import { IOption } from 'components/UIComponents/MultiSelectExtendable';
import { mapUserInfoToOption } from 'pages/SettingsPage/api/apiGetAllUsersOptions';
import {
  getSelectedCategoryLabel,
  getSelectedUserLabel,
} from 'pages/SettingsPage/components/UserGroupList/utils/userGroupLabelUtils';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppSelector } from 'hooks/reducerHooks';
import { mapMajMinorToString } from 'utils/categoryMapper';
import { FormValidState } from 'utils/form/form-utils';

export const usersInputName = 'applicationUserId';

export const UsersInput: InputComponent<GroupUsersItem> = ({ item, fromTable }) => {
  const { disabled, className, getValue, setValue, setValidity, showHoverData } =
    fromTable;
  const selectedUserIds = useMemo(() => {
    return (
      (getValue(usersInputName) as number[] | undefined) ||
      (item ? item.applicationUsers.map((au) => au.appUserId) : [])
    );
  }, [getValue, item]);

  const { data: users, status } = useAllUsers();

  const options: IOption[] = useMemo(() => {
    return users?.map(mapUserInfoToOption) ?? [];
  }, [users]);

  const selectedOptions: IOption[] = useMemo(() => {
    const idsAsStrings = selectedUserIds?.map((id) => `${id}`) ?? [];
    return options.filter((o) => idsAsStrings.includes(o.value));
  }, [options, selectedUserIds]);

  const handleChange = useCallback(
    (options: IOption[]) => {
      const ids: number[] = options.map((o) => +o.value);
      setValue(usersInputName, ids);
    },
    [setValue],
  );

  useEffect(() => {
    if (item && item.applicationUsers) {
      const ids = item.applicationUsers.map((au) => au.appUserId);
      setValue(usersInputName, ids);
    }
  }, [item, setValue]);

  useEffect(() => {
    const validity: FormValidState = selectedOptions?.length ? 'valid' : 'invalid';
    setValidity(usersInputName, validity);
  }, [selectedOptions?.length, setValidity]);

  const renderHoverData = useCallback(
    () => (
      <div className="hover-data">
        {selectedOptions?.map(
          (item, idx) =>
            `${item?.label}${idx + 1 < selectedOptions.length ? ',' : ''} `,
        )}
      </div>
    ),
    [selectedOptions],
  );

  return (
    <MultiSelect
      labelCN={classNames('utils-clean-input', className)}
      label={item ? '' : undefined}
      disabled={disabled || status === FetchStatus.Fetching}
      options={options}
      selected={selectedOptions}
      search
      text={getSelectedUserLabel(selectedUserIds)}
      onChange={handleChange}
      hasHoverContainer={!!selectedOptions.length && showHoverData}
      renderHoverData={renderHoverData}
    />
  );
};

export const categoriesInputName = 'majorMinorCategoryOptions';

type IOptionWithChildren = IOption & { children: IOption[] };

export const CategoriesInput: InputComponent<GroupUsersItem> = ({
  item,
  fromTable,
}) => {
  const { disabled, className, getValue, setValue, setValidity } = fromTable;

  const selectable = useAppSelector((state) => state.selectable);
  const options: IOptionWithChildren[] = selectable.data
    .categoriesMajorCategoriesOptions as IOptionWithChildren[];

  const selectedOptions = useMemo(() => {
    return (
      (getValue(categoriesInputName) as IOption[] | undefined) ||
      (item ? computeInitialCategoryValue(item.categories, options) : [])
    );
  }, [getValue, item, options]);

  const handleChange = useCallback(
    (options: IOption[]) => {
      setValue(categoriesInputName, options);
    },
    [setValue],
  );

  useEffect(() => {
    const validity: FormValidState = selectedOptions?.length ? 'valid' : 'invalid';
    setValidity(categoriesInputName, validity);
  }, [selectedOptions?.length, setValidity]);

  useEffect(() => {
    if (item && item.categories) {
      const initalOptions: IOption[] = computeInitialCategoryValue(
        item.categories,
        options,
      );
      setValue(categoriesInputName, initalOptions);
    }
  }, [item, options, setValue]);

  return (
    <MultiSelect
      labelCN={classNames('utils-clean-input', className)}
      label={item ? '' : undefined}
      disabled={disabled}
      options={options}
      selected={selectedOptions}
      search
      text={getSelectedCategoryLabel(selectedOptions)}
      onChange={handleChange}
    />
  );
};

export const UserGroupInput: InputComponent<GroupUsersItem> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className } = fromTable;
  if (columnKey === 'userGroupName') {
    return (
      <input
        type="text"
        name={columnKey}
        maxLength={50}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        placeholder="Enter name"
        defaultValue={item?.userGroupName}
      />
    );
  }
  if (columnKey === 'applicationUsers') {
    return <UsersInput columnKey={columnKey} item={item} fromTable={fromTable} />;
  }

  if (columnKey === 'categories') {
    return (
      <CategoriesInput columnKey={columnKey} item={item} fromTable={fromTable} />
    );
  }

  return null;
};
function computeInitialCategoryValue(
  categories: ICategories[],
  options: IOptionWithChildren[],
): IOption[] {
  const idPairsAsString = categories.map(mapMajMinorToString);
  const firstLevel = options.filter((o) => idPairsAsString.includes(o.value));
  const secondLevel = options
    .flatMap((o) => {
      return o.children;
    })
    .filter((suboption) => idPairsAsString.includes(suboption.value));
  return [...firstLevel, ...secondLevel];
}

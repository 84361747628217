import _ from 'lodash';
import { isNotEmpty } from './lodashEx';

export const splitCommaSeparated = (commaSeparated: string | undefined = '') => {
  const items = commaSeparated?.split(',');
  const result = _(items)
    .filter(isNotEmpty)
    .map((r) => r.trim())
    .value();

  return result;
};
export const joinCommaSeparated = (items: string[]) => {
  const nonEmptyItems = items.filter(isNotEmpty);
  return nonEmptyItems.join(', ');
};

export const splitCommaSeparatedUniq = (
  textCommaSeparated: string | undefined = '',
) => {
  const items = splitCommaSeparated(textCommaSeparated);
  return getInvertedUniqueItems(items);
};

/**
 * Dublicates are removed from the begining of the array.
 */
export const getInvertedUniqueItems = (items: string[]) => {
  //we reverse items to remove duplicates from begining;
  const reversedItems = items.reverse();
  const result = _.uniq(reversedItems);
  return result.reverse();
};

export const commaSeparatedUniq = (textCommaSeparated: string | undefined = '') => {
  const items = splitCommaSeparatedUniq(textCommaSeparated);
  return items.join(', ');
};

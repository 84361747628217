import { GroupUsersItem, ICategories, claimUserGroup } from 'api/endpoints';

import { useCallback, useEffect } from 'react';
import {
  OnAddSave,
  OnEditSave,
} from 'components/UIComponents/EntityTable/EntityTable.types';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import {
  CreateUserGroupParams,
  createUserGroupThunk,
  editUserGroupThunk,
  fetchUserGroupListThunk,
} from 'reducer/thunks/user-groups-thunks';

import { FetchStatus } from 'types/fetch-status.types';
import {
  usersInputName,
  categoriesInputName,
} from '../sections/claims/UserGroupInput';
import { IOption } from '@alixpartners/ui-components';
import { mapStringToMajMinorValue } from 'utils/categoryMapper';
import { error } from 'utils/alert';

export type GroupUsersItemData = {
  data: GroupUsersItem[];
  status: FetchStatus;
};

export function useEditAndAddUserGroups() {
  const dispatch = useAppDispatch();
  const { data, status } = useUserGroups();

  const onEditSave: OnEditSave<GroupUsersItem> = useCallback(
    async (formValues, item: GroupUsersItem) => {
      return dispatch(
        editUserGroupThunk({ ...formValuesToParams(formValues), id: item.id }),
      );
    },
    [dispatch],
  );

  const onAddSave: OnAddSave<GroupUsersItem> = useCallback(
    async (formValues) => {
      return dispatch(createUserGroupThunk(formValuesToParams(formValues)));
    },
    [dispatch],
  );

  const onDelete = useCallback(
    async (userGroupId: number) => {
      const resp = await claimUserGroup.deleteAssignedGroupUsers(userGroupId);
      if (!resp.error) {
        dispatch(fetchUserGroupListThunk());
      } else {
        error(
          `${resp.error.title ? resp.error.title + '. ' : ''}${
            resp.error.body.errorMessage ??
            'Could not delete user group. Please try again'
          }`,
        );
      }
    },
    [dispatch],
  );

  return {
    onEditSave,
    onAddSave,
    status,
    data,
    onDelete,
  };
}

export function useUserGroups() {
  const { data, status } = useAppSelector((s) => s.userGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === FetchStatus.Idle) {
      dispatch(fetchUserGroupListThunk());
    }
  }, [dispatch, status]);

  return {
    status: status,
    data,
  };
}

function formValuesToParams(formValues: any): CreateUserGroupParams {
  return {
    applicationUserId: formValues[usersInputName] as unknown as number[],
    majorMinorCategoryId: (
      formValues[categoriesInputName] as unknown as IOption[]
    ).map((o) => mapStringToMajMinorValue(o.value)),
    name: formValues.userGroupName as string,
  };
}

export const mapClaimsCategoriesData = (categories: ICategories[]) => {
  if (!categories?.length) return;
  const uniqueCategories: string[] = [];
  const mappedCategories: { majorCategory: string; minorCategories: string[] }[] =
    [];

  categories.forEach((category) => {
    if (!uniqueCategories.includes(category.majorCategoryName)) {
      uniqueCategories.push(category.majorCategoryName);
      mappedCategories.push({
        majorCategory: category.majorCategoryName,
        minorCategories: [category.minorCategoryName],
      });
    } else if (mappedCategories && category.minorCategoryName) {
      mappedCategories
        .find((c) => c.majorCategory === category.majorCategoryName)
        ?.minorCategories.push(category.minorCategoryName);
    }
  });

  return mappedCategories;
};

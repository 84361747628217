export const SOFA_2_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['3', '4', '5', '6'],
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Creditor / Insider Name',
      propertyName: 'creditorInsiderName',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },
    {
      dataType: 'string',
      displayName: 'Relationship to Debtor',
      propertyName: 'relationshipToDebtor',
    },
    {
      dataType: 'string',
      displayName: 'Date',
      propertyName: 'datesOfPayment',
    },
    {
      dataType: 'decimal',
      displayName: 'Amount or Value',
      propertyName: 'amountPaid',
    },

    {
      dataType: 'string',
      displayName: 'Reasons for Payment',
      propertyName: 'reasonsForPayment',
    },

    {
      dataType: 'bool',
      displayName: 'Secured',
      propertyName: 'secured',
    },
    {
      dataType: 'bool',
      displayName: 'Unsecured',
      propertyName: 'unsecured',
    },
    {
      dataType: 'bool',
      displayName: 'Suppliers',
      propertyName: 'suppliers',
    },
    {
      dataType: 'bool',
      displayName: 'Services',
      propertyName: 'services',
    },
    {
      dataType: 'string',
      displayName: 'Other',
      propertyName: 'other',
    },
    {
      dataType: 'string',
      displayName: 'Description of the action creditor took / property',
      propertyName: 'description',
    },
    {
      dataType: 'string',
      displayName: 'Account Number (Last 4 Digits)',
      propertyName: 'accountNumber',
    },
    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};

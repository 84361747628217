import { IOption } from '@alixpartners/ui-components';
import { ColDef } from 'ag-grid-community';
import {
  Exhibit,
  exhibits as exhibitApi,
  exhibits as exhibitsApi,
} from 'api/endpoints/exhibits';
import useGraphql from 'api/graphql/useGraphql';
import {
  ExhibitHistoryGrid,
  HistoryGridRow,
} from 'components/ExhibitsExport/ExhibitHistoryGrid';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect, useMemo } from 'react';
import { exhibitLoaded } from 'reducer/exhibits/exhibitReducer';
import { exhibitsFetch, exhibitsLoaded } from 'reducer/exhibits/exhibitsReducer';
import { formatDateTime } from 'utils/formatDate';
import { exportExhibitExcelFile } from '../../templates/excelExporter';
import { getClaimsByColumnSet } from '../../utils/apiExhibitClaim';

export const COLUMNS: ColDef<any>[] = [
  {
    field: 'type',
    headerName: 'Exhibit Title',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Resolution name',
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'Date created',
    width: 200,
    sort: 'desc',
    sortable: true,
    sortingOrder: ['desc', 'asc'],
    valueFormatter: (params) => formatDateTime(params.value),
  },
];

export const loadExhibits = async (dispatch: any) => {
  dispatch(exhibitsFetch());
  const result = await exhibitsApi.getAll();
  if (!result) return;
  dispatch(exhibitsLoaded(result));
};

export const ExhibitHistoryTable = () => {
  const dispatch = useAppDispatch();
  const [client] = useGraphql();
  const exhibits = useAppSelector((s) => s.exhibits);
  const actionNames = useAppSelector((s) => s.actionNames);

  useEffect(() => {
    loadExhibits(dispatch);
  }, [dispatch]);

  const rows = useMemo(() => getRowData(exhibits.data), [exhibits.data]);

  const onCopy = async (id: number) => {
    const exhibit = await exhibitApi.get(id);
    if (!exhibit) return;

    dispatch(exhibitLoaded(exhibit));
  };

  const onDelete = async (id: number) => {
    await exhibitApi.delete(id);
    await loadExhibits(dispatch);
  };

  const onDownload = async (id: number) => {
    const exhibit = await exhibitApi.get(id);
    if (!exhibit) return;

    const claimsIds = exhibit.claims?.map((c) => c.claimId);
    const claims = await getClaimsByColumnSet(client, claimsIds, 'id');

    const action = actionNames.data?.find((a) => a.id === exhibit.actionNameId);
    await exportExhibitExcelFile(
      {
        ...exhibit,
        claims,
        // @todo: fix typings
        structureFooterOptions: exhibit.structureFooters as unknown as IOption[],
        structureHeaderOptions: exhibit.structureHeaders as unknown as IOption[],
      },
      action,
    );
  };

  return (
    <ExhibitHistoryGrid
      loading={exhibits.loading}
      rows={rows}
      columns={COLUMNS}
      onCopy={onCopy}
      onDownload={onDownload}
      onDelete={onDelete}
    />
  );
};

export const getRowData = (exhibits: Exhibit[]): HistoryGridRow[] => {
  return exhibits.map((r) => ({
    id: r.id,
    type: r.exhibitNumber,
    name: r.actionName.actionName,
    date: r.createdDate,
  }));
};

import { Row, Style, Worksheet } from 'exceljs';

export type CellStyle = Partial<Style> | null;
export type CellValue = number | string | null;

export const excelAddRow = (
  sheet: Worksheet,
  values: CellValue[],
  rowStyle?: CellStyle[],
) => {
  const row = sheet.addRow(values);
  applyRowStyle(row, rowStyle);

  return row;
};

export const excelMergeCellsVertical = (addedRows: Row[], colNumber: number) => {
  if (addedRows.length <= 1) return;

  const [first, ...other] = addedRows.map((r) => r.getCell(colNumber));
  other.forEach((r) => r.merge(first));
};

function applyRowStyle(row: Row, rowStyle?: CellStyle[]) {
  if (!rowStyle) return;

  const end = rowStyle.length;

  for (let id = 0; id < end; id++) {
    const style = rowStyle[id];
    if (style) {
      row.getCell(id + 1).style = style;
    }
  }
}

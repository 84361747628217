import { GroupUsersItem } from 'api/endpoints';
import {
  AddFields,
  EditFieldsList,
  EntityTable,
  Panel,
} from 'components/UIComponents/EntityTable';
import { AwarePanel } from 'components/UIComponents/EntityTable/EntityTable.components';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { useEditAndAddUserGroups } from '../../hooks/useClaimsUserGroups';
import { UserGroupInput } from './UserGroupInput';
import './UserGroups.css';

const HEADERS = ['Group name', 'Users', 'Categories'];
const COLUMN_KEYS: (keyof GroupUsersItem)[] = [
  'userGroupName',
  'applicationUsers',
  'categories',
];

export function UserGroups() {
  const { status, data, onAddSave, onEditSave, onDelete } =
    useEditAndAddUserGroups();
  const userGroups = data ?? [];

  return (
    <SettingsContentPanel>
      <section className="usergroups">
        <EntityTable
          items={userGroups}
          status={status}
          addColumnKeys={COLUMN_KEYS}
          editColumnKeys={COLUMN_KEYS}
          inputComponent={UserGroupInput}
          onEditSave={onEditSave}
          onAddSave={onAddSave}
          section={SectionType.Claims}
          onDelete={onDelete}
          hasDeleteRow
        >
          <Panel title="User group management">
            <AddFields headers={HEADERS} />
          </Panel>
          <AwarePanel title="Current user groups">
            <EditFieldsList headers={HEADERS} />
          </AwarePanel>
        </EntityTable>
      </section>
    </SettingsContentPanel>
  );
}

import classNames from 'classnames';
import { useCallback } from 'react';
import './ApThreeWaySwitch.css';

export enum ApThreeWayValue {
  Unknown = 'unknown',
  Yes = 'yes',
  No = 'no',
}

interface ApThreeWaySwitchProps {
  className?: string;
  /** switch value */
  value: ApThreeWayValue;
  /** HTML id attribute */
  id?: string;
  /** Label */
  label?: string | JSX.Element;

  /** Function that takes a value and returns unknown */
  onChange?: (value: ApThreeWayValue) => unknown;

  disabled?: boolean;

  labelFirst?: boolean;
}

export const ApThreeWaySwitch = ({
  className,
  label,
  value,
  id,
  onChange,
  disabled,
  labelFirst,
}: ApThreeWaySwitchProps) => {
  const handleClick = useCallback(() => {
    if (!onChange || disabled) return;
    switch (value) {
      case ApThreeWayValue.No:
        onChange(ApThreeWayValue.Yes);
        return;
      case ApThreeWayValue.Unknown:
        onChange(ApThreeWayValue.No);
        return;
      case ApThreeWayValue.Yes:
        onChange(ApThreeWayValue.No);
        return;
    }
  }, [value, onChange, disabled]);
  return (
    <div
      className={classNames('ap-three-way-switch', className, value, { disabled })}
      id={id}
      onClick={handleClick}
    >
      {labelFirst && label && (
        <div className="ap-three-way-switch__label">{label}</div>
      )}
      <div className="ap-three-way-switch__switch">
        <div className="ap-three-way-switch__circle" />
      </div>
      {!labelFirst && label && (
        <div className="ap-three-way-switch__label">{label}</div>
      )}
    </div>
  );
};

import { useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import get from 'lodash/get';
import { useQueryParam, StringParam } from 'use-query-params';
import { getSavedGridState, setSavedGridState } from 'utils/local-storage';
import { SectionType } from '../types';
import { useAppSelector } from 'hooks/reducerHooks';

/**
 * This hook changes filter model based on list of predefined filters
 * if we pass relevant query param. List of predefined filters is stored in
 * redux userDashboardTasks slice
 * @param gridRef
 * @param ready
 * @returns
 */
function usePredefinedFilters(
  gridRef: React.RefObject<AgGridReact<any>>,
  section: SectionType,
  hasPredefinedFilters: boolean,
) {
  const [predefinedFilter] = useQueryParam('predefinedFilter', StringParam);
  const { claimsData, contractsData, clientData } = useAppSelector(
    (s) => s.userDashboardTasks.slice,
  );
  const dashboardData = useMemo(
    () => [...claimsData, ...contractsData, ...clientData],
    [claimsData, contractsData, clientData],
  );

  const loadPredefinedFilters = useCallback(() => {
    if (!dashboardData?.length || !predefinedFilter || !hasPredefinedFilters)
      return false;
    const predefinedFilterData = dashboardData.find(
      (d) => d.id === predefinedFilter,
    );
    if (!predefinedFilterData) return false;

    const sectionState = get(getSavedGridState(), section);
    setSavedGridState({
      ...getSavedGridState(),
      [section]: {
        ...sectionState,
        filterState: predefinedFilterData.filters,
      },
    });
    gridRef?.current?.api.setFilterModel(predefinedFilterData.filters);

    return true;
  }, [predefinedFilter, gridRef, dashboardData, section, hasPredefinedFilters]);

  return {
    loadPredefinedFilters,
  };
}

export default usePredefinedFilters;

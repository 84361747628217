import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ButtonNav } from 'pages/SettingsPage/components/ButtonNav';
import { Link } from 'react-router-dom';
import { MenuItem } from '../DisclosureLinkMenu/DisclosureLinkMenu.types';

interface Props {
  item: MenuItem;
  isSelected: boolean;
  onClick: (item: MenuItem) => void;
}

export const DirectLink = ({ item, isSelected, onClick }: Props) => {
  const link = item.baseLink ?? '';
  if (item.isButton) {
    return (
      <ButtonNav
        text={item.label}
        onClick={() => onClick(item)}
        className={classNames('disclosurelinkmenu__subitem', {
          'disclosurelinkmenu__subitem-active': isSelected,
        })}
        disabled={item.disabled}
      />
    );
  }
  return (
    <div className="disclosurelinkmenu">
      <Link className="disclosurelinkmenu__item" to={link}>
        <span>{item.label}</span>
        <SvgIcon iconName="arrow_right" width={14} height={14} className="" />
      </Link>
    </div>
  );
};

import { ClientInfo, settings } from 'api/endpoints';
import { error } from 'utils/alert';
import { uploadLogo } from 'utils/uploader';

export const apiClientNameLogo = {
  uploadLogo: async (logoContent: ArrayBuffer | undefined) => {
    if (!logoContent) return '';

    const name = 'clientLogo.jpg';
    const logoFileName = await uploadLogo({ name, logoContent });
    if (!logoFileName) {
      error('Failed to upload the logo');
      return '';
    }

    return logoFileName;
  },

  saveBasicInfo: async (
    clientInfo: ClientInfo,
    clientCompanyName: string,
    logoFileName: string,
  ) => {
    const toSave = {
      ...clientInfo,
      clientCompanyName,
      logoFileName,

      //workaround: to avoid 'undefined' values for primary and secondary colors
      primaryBrandColor: clientInfo.primaryBrandColor ?? '',
      secondaryBrandColor: clientInfo.secondaryBrandColor ?? '',
    };

    await settings.postBasicInfo(toSave);
  },
};

import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { slipSheetsListThunk } from 'reducer/thunks/slipSheets-thunk';
import { SlipSheetsSlice } from 'reducer/slipSheetsReducer';
import { FetchStatus } from 'types/fetch-status.types';

export function useSlipSheetsList(): SlipSheetsSlice {
  const slice: SlipSheetsSlice = useAppSelector((s) => {
    return s.slipSheets;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (slice.type === FetchStatus.Idle) {
      dispatch(slipSheetsListThunk());
    }
  }, [dispatch, slice.type]);

  return slice;
}

import { ActionStatus, ContractActionStatus } from 'api/endpoints';

// @todo: rewise these statuses with BE
export const contractActionStatusStyleModifier = (key: ContractActionStatus) => ({
  'scheduled-claim': key === ContractActionStatus.ScheduledContract,
  'filed-claim': key === ContractActionStatus.FiledContract,
  'matches-found': key === ContractActionStatus.MatchesFound,
  'assigned-claim': key === ContractActionStatus.Assigned,
  matched: key === ContractActionStatus.Matched,
  'categorized-claim': key === ContractActionStatus.Categorized,
  'claim-withdrawn': key === ContractActionStatus.Withdrawn,
  'alix-reconciled-claim': key === ContractActionStatus.AlixReconciled,
  'client-reconciled-claim': key === ContractActionStatus.ClientReconciled,
  'matching-complete': key === ContractActionStatus.MatchingComplete,
  objection: [
    ContractActionStatus.ObjectionContinued,
    ContractActionStatus.ObjectionFiled,
    ContractActionStatus.ObjectionOrderedFinal,
    ContractActionStatus.ObjectionOrderedPartial,
    ContractActionStatus.ObjectionPending,
  ].includes(key),
  'order-vacated': key === ContractActionStatus.OrderVacated,
  settlement: [
    ContractActionStatus.SettlementFinal,
    ContractActionStatus.SettlementPartial,
    ContractActionStatus.SettlementPending,
  ].includes(key),
  'voided-claim': key === ContractActionStatus.Voided,
  'trumped-schedule': key === ContractActionStatus.TrumpedSchedule,
  'zero-dollar-value-scheduled-only':
    key === ContractActionStatus.ZeroDollarValueScheduledOnly,
  'allowable-allowed': [
    ContractActionStatus.Allowable,
    ContractActionStatus.Allowed,
  ].includes(key),
});

export const actionStatusStyleModifier = (key: ActionStatus) => ({
  'scheduled-claim': key === ActionStatus.ScheduledClaim,
  'filed-claim': key === ActionStatus.FiledClaim,
  'matches-found': key === ActionStatus.MatchesFound,
  'assigned-claim': key === ActionStatus.Assigned,
  matched: key === ActionStatus.Matched,
  'categorized-claim': key === ActionStatus.Categorized,
  'claim-withdrawn': key === ActionStatus.Withdrawn,
  'alix-reconciled-claim': key === ActionStatus.AlixReconciled,
  'client-reconciled-claim': key === ActionStatus.ClientReconciled,
  'matching-complete': key === ActionStatus.MatchingComplete,
  objection: [
    ActionStatus.ObjectionContinued,
    ActionStatus.ObjectionFiled,
    ActionStatus.ObjectionOrderedFinal,
    ActionStatus.ObjectionOrderedPartial,
    ActionStatus.ObjectionPending,
  ].includes(key),
  'order-vacated': key === ActionStatus.OrderVacated,
  settlement: [
    ActionStatus.SettlementFinal,
    ActionStatus.SettlementPartial,
    ActionStatus.SettlementPending,
  ].includes(key),
  'voided-claim': key === ActionStatus.Voided,
  'trumped-schedule': key === ActionStatus.TrumpedSchedule,
  'zero-dollar-value-scheduled-only':
    key === ActionStatus.ZeroDollarValueScheduledOnly,
  'allowable-allowed': [ActionStatus.Allowable, ActionStatus.Allowed].includes(key),
});

import { ApIcon } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './WarningMessage.css';

export const WarningMessage = ({
  warningMessage,
  className,
}: {
  warningMessage: string;
  className?: string;
}) => {
  return (
    <h6 className={classNames('warning', className)}>
      <ApIcon iconName="error" iconColor="#D97706" />
      <span>{warningMessage}</span>
    </h6>
  );
};

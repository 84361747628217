import { ApLoaderDefault } from '@alixpartners/ui-components';

export const GeneratingFileText = ({ fileName }: { fileName: string }) => (
  <div className="tabs-generating-info flex-align-center ">
    <ApLoaderDefault loaderSize="sm" />
    <p>
      Generating <b>{fileName}</b>
    </p>
  </div>
);

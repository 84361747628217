import { Fragment, useMemo, useState } from 'react';
import {
  Cell,
  ClaimsDocumentsTable,
  Row,
} from 'components/ClaimsDocumentsTable';
import { DocFileLink } from 'components/UIComponents/GridCell';
import { useContractAttachmentsContext } from 'pages/SingleContractPage/context/ContractAttachmentsProvider';
import { CONTRACT_ATTACHMENTS_OPTIONS } from 'pages/SingleContractPage/types';
import { findGridOptionsLabel } from 'utils/singleClaimFormatter';
import { downloadContractAttachment } from 'utils/uploader';
import { DocumentTableActions } from './DocumentTableActions';
import { DocComment } from './DocComment';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';

const GRID_HEADER_CAPTION = [
  'Document file',
  'Document type',
  'Docket/ ref number',
  'Actions',
];

export const SingleContractDocTable = () => {
  const [loadingAnimation, setLoadingAnimation] = useState<number[]>([]);

  const {
    deleteAttachment,
    setPrimaryDocument,
    attachments,
    deletingDoc,
    isDataLoading,
    uploadStatus,
    loadList,
  } = useContractAttachmentsContext();

  const filesUploadedByContractId = useMemo(() => {
    return attachments.map((f) => ({
      ...f,
      referenceNumberLabel: f.referenceNumber ?? '--',
      documentTypeLabel: findGridOptionsLabel(
        f.contractDocumentTypeId.toString(),
        CONTRACT_ATTACHMENTS_OPTIONS,
      ),
    }));
  }, [attachments]);

  const handleFileNameClick =
    (file: { id: number; displayName: string }) => async () => {
      const { id, displayName } = file;

      setLoadingAnimation((r) => [...r, id]);
      await downloadContractAttachment(id, displayName);
      setLoadingAnimation((r) => r.filter((r) => r !== id));
    };

  const handleSetPrimaryValueClick =
    (id: number) => async (isPrimaryDocument: boolean) => {
      setLoadingAnimation((r) => [...r, id]);
      await setPrimaryDocument({ id, isPrimaryDocument });
      setLoadingAnimation((r) => r.filter((r) => r !== id));
    };

  return (
    <>
      <ClaimsDocumentsTable
        className="single-contract-documents"
        showLoader={isDataLoading}
        progressValue={uploadStatus.progress}
        emptyListMessage={<span>No attachments for this Contract</span>}
        headerItems={GRID_HEADER_CAPTION}
      >
        {filesUploadedByContractId.map((f) => {
          const itemIsLoading = loadingAnimation.includes(f.id);
          return (
            <Fragment key={f.id}>
              <Row markAsNew={f.newlyAdded}>
                <Cell style={{ width: '30%' }}>
                  <DocFileLink
                    name={f.displayName}
                    loading={itemIsLoading}
                    onClick={handleFileNameClick(f)}
                  />
                </Cell>
                <Cell>{f.documentTypeLabel}</Cell>
                <Cell>{f.referenceNumberLabel}</Cell>
                <Cell style={{ width: '0' }}>
                  <DocumentTableActions
                    primary={f.isPrimaryDocument}
                    loading={itemIsLoading}
                    primaryChange={handleSetPrimaryValueClick(f.id)}
                    deleteAttachment={() => deleteAttachment(f.id)}
                    downloadClick={handleFileNameClick(f)}
                    deletingDoc={deletingDoc}
                  />
                </Cell>
              </Row>
              <DocComment
                loadList={loadList}
                document={f}
                section={SectionType.Contracts}
              />
            </Fragment>
          );
        })}
      </ClaimsDocumentsTable>
      <p />
    </>
  );
};

import { ApLoaderDefault } from '@alixpartners/ui-components';
import { ContractsTable } from './sections/ContractsTable/ContractsTable';
import { ContractsData, SubsectionType } from './types';

// depending on the whether Matching tab will have both contracts and contracts,
// we might want to have it renamed to ContractsContractsToolBody
export const ContractsToolBody = (props: {
  contractsCount?: number;
  contractsData: ContractsData;
  subsection: SubsectionType;
  setSubsection: (s: SubsectionType) => void;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
}): JSX.Element | null => {
  const { contractsCount, contractsData, disableResetFilters, onResetFilters } =
    props;

  if (!contractsData) return null;

  if (!contractsData.gridColumnsReady)
    return <ApLoaderDefault className="loader-container" />;

  return (
    <ContractsTable
      contractsCount={contractsCount}
      contractsData={contractsData}
      disableResetFilters={disableResetFilters}
      onResetFilters={onResetFilters}
    />
  );
};

import { Section } from 'components/SingleClaim/Section';
import './SingleContractCounterpartiesAndDebtors.css';
import { SingleContractCounterpartiesAndDebtorsTable } from './SingleContractCounterpartiesAndDebtorsTable';

interface Props {
  contractId: number;
  contractRef: string;
}

export const SingleContractCounterpartiesAndDebtors = ({
  contractId,
  contractRef,
}: Props) => {
  return (
    <div className="counterparties-and-debtors__wrapper">
      <div className="counterparties-and-debtors__container">
        <h4 className="counterparties-and-debtors__section-title">
          List of counterparties
        </h4>
        <Section className="counterparties-and-debtors__section">
          <SingleContractCounterpartiesAndDebtorsTable
            contractId={contractId}
            contractRef={contractRef}
            debtors="counterparty"
          />
        </Section>
      </div>
      <div className="counterparties-and-debtors__container">
        <h4 className="counterparties-and-debtors__section-title">
          List of debtors
        </h4>
        <Section className="counterparties-and-debtors__section">
          <SingleContractCounterpartiesAndDebtorsTable
            debtors="debtor"
            contractId={contractId}
            contractRef={contractRef}
          />
        </Section>
      </div>
    </div>
  );
};

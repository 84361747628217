import { api } from 'api';
import {
  SettlementGenerateAgreementResponse,
  SettlementId,
} from 'api/endpoints/settlements';
import { useState } from 'react';
import { downloadBlob } from 'utils/file';
import { apiSettlementSingle } from '../api/apiSettlementSingle';

interface Props {
  id: SettlementId;
  fileName: string | null | undefined;
  onGenerateNew?: (value: SettlementGenerateAgreementResponse) => Promise<void>;
}

export const useSettlementAgreemant = ({ id, fileName, onGenerateNew }: Props) => {
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blob, setBlob] = useState<Blob>();

  const generateNew = async () => {
    setGenerating(true);
    const result = await api.settlements.agreemantGenerate(id);

    setGenerating(false);
    if (!result) return false;

    onGenerateNew?.(result);
    return true;
  };

  const getBlob = async () => {
    if (blob) return blob;

    setLoading(true);
    const result = await apiSettlementSingle.agreemantDownload(id);
    setBlob(result);

    setLoading(false);

    return result;
  };

  const downloadFile = async () => {
    await getBlob();
    if (!blob) return;

    const agrreemantFileName = fileName ?? 'agreement.pdf';
    downloadBlob(blob, agrreemantFileName);
  };

  return {
    documentReady: !!fileName,
    fileName: fileName ?? undefined,
    blob,
    loading,
    generating,
    generateNew,
    getBlob,
    downloadFile,
  };
};

import { ApLeftSliderModal } from 'components/UIComponents/ApLeftSliderModal/ApLeftSliderModal';
import { ClaimsData, SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useMemo } from 'react';
import { BatchEditorForm } from '../../../../../components/BatchEditor/BatchEditorForm';
import './ClaimsBatchEdit.css';
import { BatchEditLine } from './components/BatchEditLine';
import { useClaimsToolBatchEdit } from './hooks/useClaimsToolBatchEdit';
import { BatchEditContextProvider } from './BatchEditContextProvider';

export interface IProps {
  claimsData: ClaimsData;
  onClose: () => void;
  cancelBatch: () => void;
}

export const ClaimsBatchEdit = ({ claimsData, onClose, cancelBatch }: IProps) => {
  const { fields, applyEnabled, optionItems, applyChanges } =
    useClaimsToolBatchEdit(claimsData);

  const handleApply = async () => {
    const result = await applyChanges();
    if (result) onClose();
  };

  const modalTopPosition = useMemo(
    () => document.getElementById('id-claim-grid')?.offsetTop ?? 0,
    [],
  );

  return (
    <BatchEditContextProvider data={claimsData} sectionType={SectionType.Claims}>
      <ApLeftSliderModal style={{ top: modalTopPosition }} className="batcheditor">
        <BatchEditorForm
          sectionType={SectionType.Claims}
          templateFileName="ClaimBatchEdit.xlsx"
          applyEnabled={applyEnabled}
          onAddClick={fields.add}
          onCancel={cancelBatch}
          onApply={handleApply}
        >
          {fields.items.map((item) => (
            <BatchEditLine
              key={item.id}
              item={item}
              optionItems={optionItems}
              onChange={fields.change}
              onRemove={fields.remove}
            />
          ))}
        </BatchEditorForm>
      </ApLeftSliderModal>
    </BatchEditContextProvider>
  );
};

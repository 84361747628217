import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import 'components/UIComponents/simple-table.css';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import './ClaimProgress.css';

export type ProgressProps = {
  progress: number;
};

//This component was exctracted from ClaimsAttachmentsTable components file

export const Progress = ({ progress }: ProgressProps) => {
  const indicatorRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const el = indicatorRef.current;
    if (!el) return;
    const p = progress === -1 ? 0 : progress;
    const percent = p * 100;
    el.style.setProperty('--claims-attachment-progress-percent', `${percent}`);
  }, [progress]);

  const progressLabel = Math.ceil(progress * 100);
  const done = progress === -1;
  return (
    <>
      <div
        className={classNames('claim-attachments__progress', {
          'claim-attachments__disappear': done,
        })}
      >
        <span className="claim-attachments__progress-label">
          <span
            ref={indicatorRef}
            className="claim-attachments__progress-indicator"
          />
          File(s) uploading{'  '}
          {progressLabel}%
          {/* placing the icon here to have consistent sizes accros rows */}
          <div className="claim-attachments__hidden-icon">
            <SvgIcon
              iconName={'drive_file_icon'}
              fill="var(--ap-color-ui-green-primary)"
            />
          </div>
        </span>
      </div>
    </>
  );
};

import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { UserItem } from './UserItem';
import { useUserLogsData } from '../hooks/useUserLogsData';
import { UserSectionId } from '../types';
import { UsersLogsData } from 'reducer/usersLogsReducer';

type Props = {
  title: string;
  count: number;
  id: UserSectionId;
};

const sortFields: { id: keyof UsersLogsData; value: string }[] = [
  { id: 'name', value: 'Name' },
  { id: 'date', value: 'Last logged on' },
];

export const UserLogsSection = ({ title, count, id }: Props) => {
  const { sortedUsers, handleSort, sortIcon, sortState } = useUserLogsData(id);

  return (
    <div className="users-content__item">
      <h4>
        {title} ({count})
      </h4>
      {!sortedUsers?.length ? (
        <h5>No {title} to display</h5>
      ) : (
        <>
          <div className="users-content__title">
            {sortFields.map(({ id, value }) => (
              <p onClick={() => handleSort(id)} key={id}>
                {sortState.field === id ? <b>{value}</b> : value}
                <SvgIcon iconName={sortIcon} />
              </p>
            ))}
          </div>
          <div className="users-content__details">
            {sortedUsers.map((user) => (
              <UserItem date={user.date} name={user.name} key={user.id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

import classNames from 'classnames';
import { ReactNode } from 'react';
import './HeaderItem.css';

export interface Props {
  className?: string;
  id: string;
  label: string;
  value?: ReactNode;
}

export const HeaderItem = ({ className, id, label, value }: Props) => {
  return (
    <div className={classNames('header-item', className)} id={id}>
      <div className="header-item__label">{label}</div>
      <div className="header-item__value">{value}</div>
    </div>
  );
};

import './CheckboxHeader.css';
import classNames from 'classnames';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SubsectionType } from '../types';
import { selectAllCheckboxEnabled } from '../util/selectAllCheckboxEnabled';

export interface CheckboxHeaderProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  section: SectionType;
  subsection: SubsectionType;
}

export const CheckboxHeader = ({
  checked,
  onChange,
  section,
  subsection,
}: CheckboxHeaderProps) => {
  if (
    !selectAllCheckboxEnabled(section, subsection) &&
    section !== SectionType.SOFAS
  )
    return null;

  return (
    <div
      className={classNames('checkbox-header', {
        checked,
      })}
    >
      <div
        className={classNames('ag-checkbox-input-wrapper', {
          'ag-checked': checked,
        })}
      >
        <input
          className="ag-input-field-input ag-checkbox-input"
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

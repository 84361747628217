import { MappingSummary } from 'api/endpoints/documents';

export const DocumentsPageUploadedFiles = ({
  summary,
}: {
  summary: MappingSummary;
}) => {
  return (
    <div className="files">
      <div className="files_headers">
        <h5>Uploaded files</h5>
        <h5>Mapped files</h5>
        <h5>Updated by</h5>
        <h5>Uploaded on</h5>
      </div>
      <div className="files_content">
        <p>{summary.totalFiles}</p>
        <p>{summary.mappedFiles}</p>
        <p>{summary.lastUploadedBy ?? '-'}</p>
        <p>{summary.lastUploadedDate ?? '-'}</p>
      </div>
    </div>
  );
};

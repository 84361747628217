import { SofaTitlePanel } from '../../Components/SofaTitlePanel';
import { SofaExportInProgress } from './components/SofaExportInProgress/SofaExportInProgress';
import { SofaExportList } from './components/SofaExportList/SofaExportList';
import './ExportHistoryPanel.css';

export const ExportHistoryPanel = () => {
  return (
    <div className="sofas-export-history">
      <SofaTitlePanel className="sofas-export-history__body" title="Export manager">
        <SofaExportInProgress />
        <SofaExportList />
      </SofaTitlePanel>

      <SofaTitlePanel
        className="sofas-export-history__help"
        title="Export reports best practices"
      >
        <SofaHelpArticle />
      </SofaTitlePanel>
    </div>
  );
};

const SofaHelpArticle = () => {
  return (
    <div className="sofas-export-history__help-article">
      <h4>User tip</h4>
      <p>
        It is faster to run one export at a time than trigger multiple exports to run
        concurrently
      </p>
    </div>
  );
};

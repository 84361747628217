import { forwardRef } from 'react';

import { ApIcon, IIconProps } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './MultiSelectLabel.css';

const DEFAULT_ICON_SIZE = '18px';

export interface IMultiSelectLabelProps {
  isOpen?: boolean;
  disabled?: boolean;
  iconName?: string;
  iconProps?: IIconProps;
  text: string;
}

export const MultiSelectLabel = forwardRef<
  HTMLButtonElement,
  IMultiSelectLabelProps
>((props, ref) => {
  const { isOpen, iconProps, iconName, disabled, text = '', ...rest } = props;
  const classMultiselectDisabled = disabled ? 'multiselect--disabled' : undefined;
  return (
    <button
      className={classNames('multiselect--button', classMultiselectDisabled)}
      {...rest}
      type="button"
      ref={ref}
      disabled={disabled}
    >
      {iconName && (
        <ApIcon
          className={classMultiselectDisabled}
          iconName={iconName}
          iconSize={DEFAULT_ICON_SIZE}
          {...iconProps}
        />
      )}
      <div className={classNames('multiselect--text', classMultiselectDisabled)}>
        {text}
      </div>
      <ApIcon
        className={classNames(classMultiselectDisabled, 'multiselect--icon', {
          'multiselect--icon-rotate': isOpen,
        })}
        iconName="caret"
      />
    </button>
  );
});

import {
  ApButtonIcon,
  ApIcon,
  ApLoaderDefault,
  ApTooltip,
} from '@alixpartners/ui-components';
import {
  ColDef,
  ColumnApi,
  GridReadyEvent,
  ICellRendererParams,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import './ExhibitHistoryGrid.css';

export const GRID_HEADER_HEIGHT = 30;

export interface HistoryGridRow {
  id: number;
  type: string;
  name: string;
  date: string;
}

interface Props {
  rows: HistoryGridRow[];
  loading?: boolean;
  columns: ColDef[];
  onCopy: (id: number) => void;
  onDownload: (id: number) => void;
  onDelete: (id: number) => void;
}

const colId_date = 'date';

export const ExhibitHistoryGrid = ({
  rows,
  loading,
  columns,
  onCopy,
  onDelete,
  onDownload,
}: Props) => {
  const [sortDesc, setSortDesc] = useState<boolean>(true);
  const [columnApi, setColumnApi] = useState<ColumnApi>();

  const columnDefs = useMemo(() => {
    const buttonColumn = {
      field: 'buttons',
      headerName: '',
      width: 150,
      suppressMenu: true,
      cellRenderer: ({ data }: ICellRendererParams<HistoryGridRow>) => (
        <Buttons
          id={data?.id!}
          onCopy={onCopy}
          onDelete={onDelete}
          onDownload={onDownload}
        />
      ),
    };

    return [...columns, buttonColumn];
  }, [columns, onCopy, onDelete, onDownload]);

  const onGridReady = (params: GridReadyEvent) => setColumnApi(params.columnApi);
  const toggleSortByDate = useCallback(() => {
    const sort = sortDesc ? 'asc' : 'desc';
    columnApi?.applyColumnState({ state: [{ colId: colId_date, sort }] });
  }, [columnApi, sortDesc]);

  const handleSortChanged = useCallback((event: SortChangedEvent) => {
    const dateColumn = event.columnApi
      .getColumnState()
      .find((c) => c.colId === colId_date);

    setSortDesc(dateColumn?.sort === 'desc');
  }, []);

  if (loading) return <ApLoaderDefault loaderSize="lg" />;

  return (
    <div className={classNames('exhibit-history-table', 'ag-theme-alpine')}>
      <div className="exhibit-history-table__header-top">
        <div className="exhibit-history-table__header-top__title">
          Previous exhibits
        </div>
        <div
          className="exhibit-history-table__header-top__sort-btn"
          onClick={() => toggleSortByDate()}
        >
          Date created
          <ApIcon
            iconName="baseline_arrow_drop_down"
            iconColor="#000000"
            className={classNames({
              'exhibit-history__triangle-chevron--active': !sortDesc,
            })}
          />
        </div>
      </div>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          suppressMenu: true,
          sortable: false,
        }}
        rowData={rows}
        suppressMovableColumns
        animateRows
        rowSelection="multiple"
        rowClass="exhibit-history__row"
        // suppressRowClickSelection
        // suppressContextMenu
        headerHeight={GRID_HEADER_HEIGHT}
        columnDefs={columnDefs}
        rowHeight={45}
        onGridReady={onGridReady}
        onSortChanged={handleSortChanged}
      />
    </div>
  );
};

interface ButtonsProps {
  id: number;
  onCopy: (id: number) => void;
  onDownload: (id: number) => void;
  onDelete: (id: number) => void;
}
const Buttons = ({ id, onCopy, onDelete, onDownload }: ButtonsProps) => {
  const handleCopyClick = useCallback(() => onCopy(id), [id, onCopy]);
  const handleDeleteClick = useCallback(() => onDelete(id), [id, onDelete]);
  const handleDownloadClick = useCallback(() => onDownload(id), [id, onDownload]);

  return (
    <div className="exhibit-history-table__buttons-cell">
      <ApTooltip message="Copy contents into new exhibit">
        <ApButtonIcon
          iconName="file_copy"
          iconProps={{ iconSize: 40 }}
          onClick={handleCopyClick}
          hoverColor="#CCCCCC"
          className="copy-button"
        />
      </ApTooltip>
      <ApTooltip message="Download xlsx">
        <ApButtonIcon
          iconName="download"
          iconProps={{ iconSize: 30 }}
          onClick={handleDownloadClick}
          className=""
        />
      </ApTooltip>
      <ApTooltip message="Delete">
        <ApButtonIcon
          iconName="delete"
          iconProps={{ iconSize: 30 }}
          onClick={handleDeleteClick}
        />
      </ApTooltip>
    </div>
  );
};

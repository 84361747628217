import { ICellRendererParams } from 'ag-grid-community';
import { ClaimRow } from 'api/endpoints';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import './ClaimReviewerCell.css';

export const ClaimReviewerCell = (props: ICellRendererParams<ClaimRow>) => {
  const formattedValues = useMemo(
    () => props.data?.claimReviewers?.trim().replaceAll(',', ', '),
    [props.data?.claimReviewers],
  );

  const hasValue = !isEmpty(formattedValues);

  return (
    <div className="claim-reviewer-cell">
      {hasValue && (
        <>
          <SvgIcon iconName="person" />
          <div className="claim-reviewer-cell__name">{formattedValues}</div>
        </>
      )}
    </div>
  );
};

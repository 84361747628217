import { ApSimpleSearch } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './HelpContentsBox.css';

type TopicProps = React.ComponentProps<'div'> & {
  title?: string;
};

export const TopicsBox = ({ title, className, children, ...props }: TopicProps) => {
  return (
    <div className={classNames('help-topics-box', className)} {...props}>
      {title && <header> {title}</header>}
      {children}
    </div>
  );
};

type ModuleProps = React.ComponentProps<'div'> & {
  title?: string;
  onSearch?: (value: string) => void;
};

export const ModuleBox = ({
  title,
  className,
  children,
  onSearch,
  ...props
}: ModuleProps) => {
  return (
    <div className={classNames('help-module-box', className)} {...props}>
      {title && (
        <header>
          <span>{title}</span>
          <ApSimpleSearch id={'Help search'} onSearch={onSearch} />
        </header>
      )}
      {children}
    </div>
  );
};

type SearchProps = React.ComponentProps<'div'> & {
  title: string;
  onSearch: (value: string) => void;
};

export const HelpSearchBox = ({
  title,
  className,
  children,
  onSearch,
  ...props
}: SearchProps) => {
  return (
    <div className={classNames('help-search-box', className)} {...props}>
      {title && (
        <header>
          <span>{title}</span>{' '}
          <ApSimpleSearch
            className="help-search-box_field"
            id={'HelpSearch'}
            onSearch={onSearch}
          />
        </header>
      )}
      {children}
    </div>
  );
};

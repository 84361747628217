import classNames from 'classnames';
import './SubMatchCodeEdit.css';
import { ApIcon } from '@alixpartners/ui-components';

type BaseInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const SubMatchCodeEdit = (props: Omit<BaseInputProps, 'type'>) => {
  const { className, ...rest } = props;

  return (
    <div className="claim-matches-grid--cell__submatch-cell flex-align-center">
      <input
        type={'text'}
        className={classNames('sub-match-code-edit', className)}
        {...rest}
      />
      <ApIcon iconName="edit" iconSize={16} />
    </div>
  );
};

import { useMemo } from 'react';
import { ClaimEditValues } from 'api/endpoints';
import classNames from 'classnames';
import { SCPInput, SCPTextarea } from 'components/SingleClaim/SCPInput';
import { Datepicker } from 'components/UIComponents/Datepicker';
import { SwitchButton } from 'components/UIComponents/SwitchButton';
import { InputWithLabel } from 'pages/SingleClaimPage/components/InputWithLabel';
import { formatDate } from 'utils/formatDate';
import { EditFieldFunc } from '../../hook/useEditClaim';
import { SingleClaimInputsType } from '../../types';
import inputValueFormatter from '../../util/inputValueFormatter';
import { CategorySelect } from '../CategorySelect';
import './SingleClaimInputs.css';
import { validateAndFormatURL } from 'utils/validateAndFormatURL';
import { SvgIcon } from 'components/UIComponents/SvgIcon';

export interface SingleClaimInputsProps {
  orientation: 'vertical' | 'horizontal';
  inputs: SingleClaimInputsType;
  claim?: ClaimEditValues;
  isEditable: (field: string, column?: string | undefined) => boolean;
  editField: EditFieldFunc;
}

export const SingleClaimInputs = ({
  orientation,
  inputs,
  claim,
  isEditable,
  editField,
}: SingleClaimInputsProps) => {
  const textFieldsInputs = inputs.filter(({ textField }) => textField);
  const otherInputs = inputs.filter(({ textField }) => !textField);

  const scheduledClaim = useMemo(
    () => claim?.sourceType === 'ScheduledClaim',
    [claim],
  );

  const textFields = textFieldsInputs.map(({ label, field }, index) => {
    const disabled = !isEditable(field);
    return (
      <InputWithLabel
        label={label}
        className="single-claim-overview__textfield-label"
        key={`textfieldWithLabel${index}`}
      >
        <SCPTextarea
          className={classNames({
            editable: !disabled,
          })}
          value={(claim as any)[field]}
          disabled={disabled}
          onBlur={(e) => editField(field, e.target.value)}
        />
      </InputWithLabel>
    );
  });

  const inputsElements = otherInputs.map(
    (
      {
        type,
        label,
        field,
        category,
        dateField,
        toggle,
        hyperlinkField,
        amountField,
      },
      index,
    ) => {
      if (type === 'empty')
        return <div key={index} className="single-claims-overview__input-label" />;

      const value = (claim as any)[field];
      const disabled = !isEditable(field);
      const url = hyperlinkField ? validateAndFormatURL(value) : undefined;

      if (field === 'barDateRef' && !scheduledClaim) return null;
      return (
        <InputWithLabel
          label={label}
          className="single-claim-overview__input-label"
          key={`inputWithLabel${index}`}
        >
          {category ? (
            <CategorySelect
              {...category}
              disabled={disabled}
              editField={editField}
              claim={claim}
            />
          ) : dateField ? (
            <Datepicker
              className={classNames('scp-input', {
                editable: !disabled,
              })}
              defaultValue={value}
              disabled={disabled}
              onChange={(e) => (e ? editField(field, e) : value)}
              formatLabel={formatDate}
            />
          ) : toggle ? (
            <SwitchButton
              className="contract-item__primary-switch pl-8"
              value={value}
              onChange={(toggle) => {
                editField(field, toggle);
              }}
              disabled={disabled}
            />
          ) : (
            <>
              <SCPInput
                className={classNames({
                  editable: !disabled,
                  withLink: hyperlinkField,
                })}
                type={type ?? 'text'}
                value={inputValueFormatter(value, field)}
                disabled={disabled}
                preventBrowserAddressDialog={type === 'address'}
                onBlur={(e) => editField(field, e.target.value)}
                amountField={amountField}
              />
              {!!value && hyperlinkField && (
                <a
                  className={classNames('scp-link-field scp-input', {
                    disabled: !url,
                  })}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  title={!url ? 'Not a valid URL' : ''}
                >
                  <SvgIcon iconName="open_link" fill="var(--ap-color-green1)" />
                </a>
              )}
            </>
          )}
        </InputWithLabel>
      );
    },
  );

  return (
    <div className={classNames('single-claim-inputs', orientation)}>
      {orientation === 'horizontal' && (
        <>
          {inputsElements} {textFields}
        </>
      )}
      {orientation === 'vertical' && (
        <>
          <div className="left-column">
            {inputsElements.slice(0, Math.ceil(inputsElements.length / 2))}
          </div>
          <div className="right-column">
            {inputsElements.slice(
              Math.ceil(inputsElements.length / 2),
              inputsElements.length,
            )}
          </div>
          <div className="textfields">{textFields}</div>
        </>
      )}
    </div>
  );
};

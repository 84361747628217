import React from 'react';
import { ApButtonIcon, ApTooltip } from '@alixpartners/ui-components';
import classNames from 'classnames';

import './style.css';

interface IHeaderButtonProps extends React.ComponentProps<typeof ApButtonIcon> {
  active?: boolean;
}

export const HeaderButton = React.forwardRef<HTMLButtonElement, IHeaderButtonProps>(
  (props, ref) => {
    const { active, ...buttonProps } = props;
    return (
      <div>
        <ApButtonIcon
          ref={ref}
          {...buttonProps}
          className={classNames('navbar-button', {
            'navbar-button_disabled ': buttonProps.disabled,
            'navbar-button_active': active,
          })}
          theme="dark"
          onMouseDown={preventDefaultHandler}
          iconProps={{ outlined: true }}
        />
      </div>
    );
  },
);

export const preventDefaultHandler = (e: React.MouseEvent<any>) =>
  e.preventDefault();

export const HeaderTooltip = (props: {
  message?: string;
  disabled?: boolean;
  children: JSX.Element;
}): JSX.Element => {
  if (!props.message || props.disabled) return props.children;

  return <ApTooltip message={props.message}>{props.children}</ApTooltip>;
};

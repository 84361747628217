import { AssignedGroup } from 'api/endpoints/assigned-group-users';
import { FetchStatus } from 'types/fetch-status.types';

export type AssignedGroupUsersSlice = {
  data: AssignedGroup[] | null;
  status: FetchStatus;
  error: { message: string } | null;
};

export type FetchAssignedUserGroupsAction =
  | {
      type: 'fetch-asssignedgroupusers-fetching';
    }
  | {
      type: 'fetch-asssignedgroupusers-done';
      data: AssignedGroup[];
    }
  | {
      type: 'fetch-asssignedgroupusers-error';
      error: { message: string };
    };

const initialState: AssignedGroupUsersSlice = {
  data: null,
  status: FetchStatus.Idle,
  error: null,
};

export const assignedGroupUsersListReducer = (
  state: AssignedGroupUsersSlice = initialState,
  action: FetchAssignedUserGroupsAction,
): AssignedGroupUsersSlice => {
  switch (action.type) {
    case 'fetch-asssignedgroupusers-fetching': {
      return {
        ...state,
        status: FetchStatus.Fetching,
      };
    }

    case 'fetch-asssignedgroupusers-done': {
      return {
        ...state,
        status: FetchStatus.Done,
        data: action.data,
      };
    }

    case 'fetch-asssignedgroupusers-error': {
      return {
        ...state,
        error: action.error,
        status: FetchStatus.Error,
      };
    }

    default:
      return state;
  }
};

import { Column } from 'exceljs';

export const titleStyle: Column['style'] = {
  font: {
    size: 8,
    color: { theme: 1 },
    name: 'Times New Roman',
    family: 1,
    bold: true,
  },
  alignment: {
    horizontal: 'left',
    vertical: 'middle',
    wrapText: true,
    indent: 1,
  },
  border: {
    top: { style: 'thin' },
    bottom: { style: 'thick' },
  },
};

export const titleGroupStyle: Column['style'] = {
  font: {
    size: 10,
    color: { theme: 1 },
    name: 'Times New Roman',
    family: 1,
    bold: true,
  },
  alignment: {
    horizontal: 'center',
    vertical: 'middle',
    indent: 1,
  },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD9D9D9' },
  },
};

import { ExportPdfResponse } from 'api/endpoints/exportPdf';
import pluralize from 'pluralize';
import './SofaExportQueuedDetails.css';

interface Props {
  items: ExportPdfResponse[] | undefined;
}

export const SofaExportQueuedDetails = ({ items }: Props) => {
  if (!items?.length) return null;

  return (
    <div className="sofa-export-details">
      <div className="sofa-export-details__title"> Queued Exports </div>
      <table className="sofa-export-details__body">
        <tbody>
          {items.map((r, id) => (
            <tr key={id}>
              <td>
                <span className="sofa-export-details--id">{id + 1}.</span>
                {r.fileName}{' '}
              </td>
              <td> by {r.createdBy} </td>
              <td>{`${r.totalFiles} ${pluralize('entity', r.totalFiles)}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

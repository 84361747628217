import {
  ContractsGroupUsersItem,
  contractUserGroup,
  GroupUsersItem,
  GroupUsersParams,
  claimUserGroup,
} from 'api/endpoints';
import { FetchStatus } from 'types/fetch-status.types';
import { userGroupsActions } from '../user-groups';

import { AppDispatch, GetState } from '../store';
import { AddOrEditStatus } from 'components/UIComponents/EntityTable/EntityTable.types';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';
import { contractUserGroupsActions } from '../contractUserGroupsReducer';
import { error } from 'utils/alert';

export const fetchUserGroupListThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(userGroupsActions.fetching());
    const userGroups = await claimUserGroup.getAssignedGroupUsers();
    if (userGroups) {
      dispatch(userGroupsActions.done(userGroups));
    } else {
      dispatch(
        userGroupsActions.error({
          message: 'Error whilst fetching the user groups.',
        }),
      );
    }
  };

type GeneralError = {
  type: FetchStatus.Error;
  error: { message: string };
};

export type UserGroupAddResponse =
  | {
      type: AddOrEditStatus.DoneButNoReturn;
    }
  | GeneralError;

export type CreateUserGroupParams = Omit<GroupUsersParams, 'id'>;
export type EditUserGroupParams = CreateUserGroupParams & { id: number };

const generalError: GeneralError = {
  type: FetchStatus.Error,
  error: {
    message: 'An unexpected error occured.',
  },
};

export const createUserGroupThunk =
  (newItem: CreateUserGroupParams) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<UserGroupAddResponse> => {
    try {
      // TODO BE to return the whole entity, now we need to fetch the whole list
      const result = await claimUserGroup.createClaimUserGroup(newItem);
      if (result.type === ResponseType.OK) {
        const userGroups = await claimUserGroup.getAssignedGroupUsers();
        if (!userGroups) {
          return generalError;
        }
        dispatch(userGroupsActions.added(userGroups));
        return {
          type: AddOrEditStatus.DoneButNoReturn,
        };
      } else if (result.type === ResponseType.BAD_REQ) {
        const message = result.data.error.errorMessage;
        return { type: FetchStatus.Error, error: { message } };
      }
      return generalError;
    } catch (e) {
      console.error(e);
      return generalError;
    }
  };

export const saveContractUserGroupThunk =
  (newItem: Omit<ContractsGroupUsersItem, 'id'>) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractUserGroup.createContractUserGroup(newItem);
    if (response.type === ResponseType.OK) {
      dispatch(getContractUserGroupThunk());
    } else {
      error('Could not save Contracts user groups. Please try again');
    }
  };

export const editContractUserGroupThunk =
  (editedItem: Omit<ContractsGroupUsersItem, 'id'>, id: number) =>
  async (dispatch: AppDispatch, _getState: GetState) => {
    const response = await contractUserGroup.editContractUserGroup(editedItem, id);
    if (!response.error) {
      dispatch(getContractUserGroupThunk());
    } else {
      error(
        response.error?.message ??
          'Could not save Contracts user group changes. Please try again',
      );
    }
  };

export const getContractUserGroupThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    const userGroupsResponse = await contractUserGroup.getContractUserGroup();
    if (userGroupsResponse?.data) {
      dispatch(contractUserGroupsActions.done(userGroupsResponse.data));
    } else {
      error(
        userGroupsResponse.error?.message ??
          'Could not fetch Contracts user groups. Please try again',
      );
    }
  };

export type UserGroupEditResponse =
  | {
      type: FetchStatus.Done;
      item: GroupUsersItem;
    }
  | GeneralError;

export const editUserGroupThunk =
  (editedItem: EditUserGroupParams) =>
  async (
    dispatch: AppDispatch,
    _getState: GetState,
  ): Promise<UserGroupEditResponse> => {
    try {
      // TODO BE to return the whole entity, now we need to send another request
      const result = await claimUserGroup.editClaimUserGroup(editedItem);
      if (result.type === ResponseType.OK) {
        const edited = await claimUserGroup.getAssignedGroupUsersId(editedItem.id);
        if (!edited) return generalError;
        dispatch(userGroupsActions.edited(edited));
        dispatch(fetchUserGroupListThunk());

        return {
          type: FetchStatus.Done,
          item: edited,
        };
      } else if (result.type === ResponseType.BAD_REQ) {
        const message = result.data.error.errorMessage;
        return { type: FetchStatus.Error, error: { message } };
      } else {
        return generalError;
      }
    } catch (e) {
      console.error(e);
      return generalError;
    }
  };

import { claimUserGroup } from 'api/endpoints';
import { allUsersActions } from '../allUsers';
import { AppDispatch, GetState } from '../store';

export const fetchAllUsersThunk =
  () => async (dispatch: AppDispatch, _getState: GetState) => {
    dispatch(allUsersActions.fetching());
    const response = await claimUserGroup.getAllUsers();
    if (response) {
      dispatch(allUsersActions.done(response));
    } else {
      dispatch(allUsersActions.error({ message: 'Could not fetch all users.' }));
    }
  };

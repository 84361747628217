import { showHttpError } from 'api/apiRequest/httpErrorAlert';
import { BlobStorageCredentials } from 'api/endpoints';
import AzureStorageClient from 'utils/azureStorageClient';
import { azureStorageUpload, AzureUploadResult } from './azureStorageUpload';

export type UploadResult = AzureUploadResult & {
  displayName: string;
  folder: string;
};

const MIN_UPLOAD_PROGRESS = 0.05;
const MAX_UPLOAD_PROGRESS = 0.9;

export const azureFileUploader = async (props: {
  file: File;
  folder?: string;
  showErrorMessage?: boolean;
  getCredentials: () => Promise<BlobStorageCredentials | any>;
  onProgress?: (loaded: number) => unknown;
}) => {
  const { file, folder = '', showErrorMessage, getCredentials, onProgress } = props;
  const displayName = file.name;

  onProgress?.(MIN_UPLOAD_PROGRESS);

  const azureClient = new AzureStorageClient({ getCredentials });
  const uploadResult = await azureStorageUpload(
    azureClient,
    file,
    folder,
    onProgress,
  );
  const result: UploadResult = { ...uploadResult, displayName, folder };

  onProgress?.(MAX_UPLOAD_PROGRESS);

  if (result.type === 'fail' && showErrorMessage) {
    const message = `File upload fail: ${displayName}`;
    showHttpError({ title: 'Upload failed', message });
  }

  return result;
};

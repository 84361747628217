import './DeleteData.css';
import { useEffect, useMemo, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useDataUploader } from 'pages/DataUploaderPage/hooks/useDataUploader';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { DeleteSection, FileType } from 'types/dataUploaderTypes';
import { ApLoaderDefault } from '@alixpartners/ui-components';
import { DELETE_DATA_SECTIONS } from 'pages/DataUploaderPage/utils/dataUploader.config';
import { DeleteDataItemCard } from './DeleteDataItemCard';
import { dataPipeline } from 'api/endpoints';
import { error } from 'utils/alert';
import { mapFileTypeForDeleteSection } from './helpers';

export const DeleteData = () => {
  const { files, initFileInfo, fetchingFiles, deleteAllByModule, deleteAll } =
    useDataUploader();
  const [deleting, setDeleting] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState<FileType>();

  const handleDelete = async (allFiles: boolean, section: DeleteSection) => {
    setDeleting(true);
    setTypeToDelete(section.type);
    const status = await dataPipeline.getStatusQuiet();
    if (status.data?.processingStatus === 'InProgress') {
      error('Another operation is in progress , please try after a few minutes');
      setDeleting(false);
      return;
    }
    if (allFiles) {
      deleteAll();
    } else {
      deleteAllByModule(section.moduleToDelete, section.label);
    }
  };

  useEffect(() => {
    initFileInfo();
  }, [initFileInfo]);

  const filesWithUpdatedType = files.map((file) => ({
    ...file,
    type: mapFileTypeForDeleteSection(file),
  }));

  const groupedFiles = useMemo(
    () => groupBy(filesWithUpdatedType, (f) => f.type),
    [filesWithUpdatedType],
  );

  useEffect(() => {
    if (!groupedFiles[typeToDelete!]) {
      setTypeToDelete(undefined);
      setDeleting(false);
    }
  }, [typeToDelete, groupedFiles, files]);

  if (fetchingFiles) {
    return <ApLoaderDefault loaderSize="lg" />;
  }

  if (!isEmpty(groupedFiles)) {
    return (
      <section className="delete-data">
        <div className="delete-data-items">
          {DELETE_DATA_SECTIONS.map((section) => {
            const sectionFiles =
              section.type === FileType.All
                ? filesWithUpdatedType
                : groupedFiles[section.type];
            if (sectionFiles?.length) {
              return (
                <DeleteDataItemCard
                  key={section.type}
                  section={section}
                  sectionFiles={sectionFiles}
                  deleting={deleting}
                  typeToDelete={typeToDelete}
                  handleDelete={handleDelete}
                />
              );
            }
            return null;
          })}
        </div>
      </section>
    );
  }
  return (
    <SettingsContentPanel>
      <h3>
        No uploaded data found.
        <p>
          To upload data navigate to the{' '}
          <Link
            to="/data-upload"
            style={{ color: 'var(--ap-color-ui-green-primary)' }}
          >
            Data uploader page
          </Link>
        </p>
      </h3>
    </SettingsContentPanel>
  );
};

import React, { useCallback, useEffect } from 'react';
import { ApCheckbox } from '@alixpartners/ui-components';
import {
  ClaimEditChangeOptionsMarkFlags,
  LabeledClaimEditChangeOptionsMarkFlag,
} from 'api/endpoints';
import { ChangeOptionsHook } from '../sections/SingleClaimOverview/hook/useEditClaim';

export type ChangeOptionCheckboxProps = {
  changeOptionsHook: ChangeOptionsHook;
  markFlags: LabeledClaimEditChangeOptionsMarkFlag[];
  disabled?: boolean;
};

export function ChangeOptionCheckbox(props: ChangeOptionCheckboxProps) {
  const { changeOptionsHook, markFlags, disabled } = props;
  const [changeOptions, setChangeOptions] = changeOptionsHook;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      const flag = target.name as keyof ClaimEditChangeOptionsMarkFlags;
      setChangeOptions({
        ...changeOptions,
        [flag]: target.checked,
      });
    },
    [changeOptions, setChangeOptions],
  );

  useEffect(() => {

// Workaround:  prevent to show invalid hint text
    const label = document.querySelectorAll('.save-popup__checkbox-container label');
    label?.forEach((el) => el.removeAttribute('title'));
  }, [markFlags]);

  if (!markFlags.length) return null;

  return (
    <div className="save-popup__checkbox-container">
      {markFlags.map((item) => {
        // explicilty coalesce to `false` so that the component remains controlled
        const checked = changeOptions[item.changeOptionsMarkFlag] ?? false;
        return (
          <ApCheckbox
            key={item.label}
            disabled={disabled}
            id={item.changeOptionsMarkFlag}
            name={item.changeOptionsMarkFlag}
            label=""
            checked={checked}
            onChange={handleChange}
          >
            {item.label}
          </ApCheckbox>
        );
      })}
    </div>
  );
}

import { URL_PATH } from 'appUrls';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FetchStatus } from 'types/fetch-status.types';
import { useAppInitialDataContext } from './AppInitalDataProvider';

export const useAppRoutesInitializer = () => {
  const navigate = useNavigate();
  const { data, loadingStatus } = useAppInitialDataContext();

  useEffect(() => {
    if (loadingStatus !== FetchStatus.Done) return;

    if (!data?.basicInfo.initialized) navigate(URL_PATH.SETUP_WIZARD);

    //do not include "navigate()" into deps list.
    //this useEffect must execute only once - on site first run.
    //whereas navigate() function is changed on each page switch
  }, [loadingStatus, data]); // eslint-disable-line react-hooks/exhaustive-deps

  return { loadingStatus };
};

import { ContractsGrid } from 'pages/ClaimsContractsToolPage/grid/ContractsGrid/ContractsGrid';
import { useEffect, useState } from 'react';
import { ContractsData } from '../../types';
import { ContractsAllSubheader } from './ContractsAllSubheader';
import { ContractsBatchEdit } from './ContractsBatchEdit';
import './ContractsTable.css';
import { getContractsCategoriesThunk } from 'reducer/thunks/contractsCategoriesThunks';
import { useAppDispatch } from 'hooks/reducerHooks';

export interface IContractsToolContentProps {
  contractsData: ContractsData;
  contractsCount: number | undefined;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
}

export const ContractsTable = ({
  contractsData,
  contractsCount,
  disableResetFilters,
  onResetFilters,
}: IContractsToolContentProps) => {
  const [showBatch, setShowBatch] = useState(false);
  const [isColumnSelectionOpen, setColumnSelection] = useState(false);
  const dispatch = useAppDispatch();

  const handleBatchComplete = () => {
    setShowBatch(false);
    contractsData.refresh();
  };

  const handleCancelClick = () => {
    setShowBatch(false);
    contractsData?.onResetFilters?.();
  };

  useEffect(() => {
    dispatch(getContractsCategoriesThunk());
  }, [dispatch]);

  return (
    <>
      <ContractsAllSubheader
        searchText={contractsData.searchText}
        contractsCount={contractsCount}
        showMineContracts={contractsData.showMineContracts}
        onShowMineContractsChange={contractsData.onShowMineContractsChange}
        onBatchClick={() => setShowBatch((r) => !r)}
        onExportContracts={contractsData.onExport}
        isColumnSelectionOpen={isColumnSelectionOpen}
        setColumnSelection={setColumnSelection}
        disableResetFilters={disableResetFilters}
        onResetFilters={onResetFilters}
        onSearchChanged={contractsData.onSearchChanged}
      />
      <div className="contracts-all--body">
        <ContractsGrid
          isColumnSelectionOpen={isColumnSelectionOpen}
          gridOptions={contractsData.grid}
          loading={contractsData.loading}
          dataSourceLoading={contractsData.dataSourceLoading}
        />

        {showBatch && (
          <ContractsBatchEdit
            contractsData={contractsData}
            onClose={handleBatchComplete}
            cancelBatch={handleCancelClick}
          />
        )}
      </div>
    </>
  );
};

import { ApButtonMain, ApIcon } from '@alixpartners/ui-components';
import { useMemo, useState } from 'react';

import { ClaimAttachmentDocumentType } from 'api/endpoints/claimAttachments';
import 'components/UIComponents/simple-table.css';
import { useClaimAttachmentsContext } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { UploadModal } from '../../../components/ModalWindows/UploadModal';
import { CLAIM_ATTACHMENTS_OPTIONS } from '../types';
import { ClaimAttachmentsTable } from './ClaimAttachmentsTable/ClaimAttachmentsTable';
import './SingleClaimDocuments.css';

export const SingleClaimDocuments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { addAttachments, sourceType } = useClaimAttachmentsContext();

  const handleUploadClick = () => setIsModalOpen(true);
  const handleModalClosed = () => setIsModalOpen(false);

  const typeOptions = useMemo(
    () =>
      sourceType !== 'FiledClaim'
        ? CLAIM_ATTACHMENTS_OPTIONS.filter(
            (option) =>
              option.value !== String(ClaimAttachmentDocumentType.ClaimImage),
          )
        : CLAIM_ATTACHMENTS_OPTIONS,
    [sourceType],
  );

  return (
    <div>
      <ApButtonMain
        className="single-claim-documents__upload"
        onClick={handleUploadClick}
      >
        <ApIcon iconName="add" iconSize="sm" />
        Upload new document
      </ApButtonMain>

      <ClaimAttachmentsTable />
      {isModalOpen ? (
        <UploadModal
          label="Upload filed claims data"
          typeOptions={typeOptions}
          onSubmit={addAttachments}
          onClosed={handleModalClosed}
          options={{ commentMaxLength: 500 }}
        />
      ) : undefined}
    </div>
  );
};

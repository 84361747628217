import { get } from 'api/apiRequest';
import { jsonFetchPost } from 'api/jsonFetch/authJsonFetch';

export type ProcessStatus = 'InProgess' | 'Completed' | 'Failed' | 'New';

type StatusResponse = {
  id: number;
  status: ProcessStatus;
  processedFiles: number;
  totalFiles: number;
};
export type MappingSummary = {
  mappedFiles: number;
  totalFiles: number;
  lastUploadedBy?: string;
  lastUploadedDate?: string;
};

export type SectionApi = {
  getSummary: () => Promise<MappingSummary | undefined>;
  getStatus: () => Promise<any>;
  triggerMapping: (fileName: string) => Promise<any>;
  triggerUnzip?: (fileName: string) => Promise<any>;
};

export const contractDocumentsApi: SectionApi = {
  triggerUnzip: (fileName: string) =>
    jsonFetchPost(`v1/ContractDocuments/unzip/trigger/${fileName}`),
  getStatus: () => get<StatusResponse>('v1/ContractDocuments/unzip/status'),
  getSummary: () => get<MappingSummary>('v1/ContractDocuments/mapping/summary'),
  triggerMapping: (fileName: string) =>
    jsonFetchPost(`v1/ContractDocuments/mapping/trigger/${fileName}`),
};

export const claimsDocumentsApi: SectionApi = {
  triggerUnzip: (fileName: string) =>
    jsonFetchPost(`v1/ClaimDocuments/unzip/trigger/${fileName}`),
  getStatus: () => get<StatusResponse>('v1/ClaimDocuments/mapping/status'),
  getSummary: () => get<MappingSummary>('v1/ClaimDocuments/mapping/summary'),
  triggerMapping: (fileName: string) =>
    jsonFetchPost(`v1/ClaimDocuments/mapping/trigger/${fileName}`),
};

import { ApButtonIcon } from '@alixpartners/ui-components';
import classnames from 'classnames';
import { CSSProperties } from 'react';

import './ApButtonRectIcon.css';

const colorDefault = '#1A1A1A';
const colorDisabled = '#737373';

type Props = React.ComponentProps<typeof ApButtonIcon> & {
  color?: string;
};

export const ApButtonRectIcon = ({
  className,
  color = colorDefault,

  ...props
}: Props) => {
  const iconColor = props.disabled ? colorDisabled : color;
  const buttonColor = { '--button-color': `${iconColor}` } as CSSProperties;

  return (
    <ApButtonIcon
      style={buttonColor}
      className={classnames('ap-button-rect-icon', className)}
      {...props}
    />
  );
};

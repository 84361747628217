import { ReportType } from 'api/endpoints/sofas-schedules';
import { InputCheckbox } from 'components/UIComponents/InputCheckbox/InputCheckbox';

interface Props {
  selected: ReportType;
  onChange: (value: ReportType) => void;
}

const PDF_REPORTS: ReportType[] = ['Sofa', 'Schedule'];
const EXCEL_REPORTS: ReportType[] = ['Excel'];

export const ReportTypeInputs = ({ selected, onChange }: Props) => {
  return (
    <div className="sofas-export-panel__input-group">
      <h5>Report Type</h5>
      <div className="sofas-export-panel__radio-group">
        {PDF_REPORTS.map((item) => (
          <InputCheckbox
            key={item}
            checked={selected === item}
            onChange={() => onChange(item)}
            type="radio"
          >
            {item}
          </InputCheckbox>
        ))}
      </div>
      <h5>Export Data</h5>
      <div className="sofas-export-panel__radio-group">
        {EXCEL_REPORTS.map((item) => (
          <InputCheckbox
            key={item}
            checked={selected === item}
            onChange={() => onChange(item)}
            type="radio"
          >
            {item}
          </InputCheckbox>
        ))}
      </div>
    </div>
  );
};

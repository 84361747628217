import classnames from 'classnames';
import { ComponentProps } from 'react';
import './PanelBoxShadowed.css';

interface Props extends ComponentProps<'div'> {
  title?: string;
}

export const PanelBoxShadowed = ({
  title,
  children,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={classnames('panel-box-shadowed--container', className)}
      {...props}
    >
      {title && <h3>{title}</h3>}
      <div className="panel-box-shadowed">{children}</div>
    </div>
  );
};

import classNames from 'classnames';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import './HelpAccordion.css';

export interface IAccordionProps {
  className?: string;
  header: string;
  expanded?: boolean;
  children: JSX.Element;
  onExpandedChange?: (expanded: boolean) => void;
}

export const HelpAccordion = ({
  className,
  header,
  children,
  expanded: expendedProps,
  onExpandedChange,
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState(expendedProps ?? false);

  useEffect(() => setExpanded(expendedProps ?? false), [expendedProps]);

  const triggerExpanded = () => {
    onExpandedChange?.(!expanded);

    if (_.isNil(expendedProps)) {
      setExpanded((r) => !r);
    }
  };

  return (
    <div className={classNames('help-accordion', className)}>
      <div className="help-accordion__header">
        <div className="help-accordion__header-text" onClick={triggerExpanded}>
          {header}
        </div>

        <div
          className={classNames('help-accordion__show', {
            'accordion-icon-rotate': expanded,
          })}
          onClick={triggerExpanded}
        >
          <SvgIcon iconName="caret_inv" width={10} />
        </div>
      </div>
      {expanded && <div className="help-accordion__content">{children}</div>}
    </div>
  );
};

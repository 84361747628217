import { Border, Borders, Style } from 'exceljs';

export type CellStyle = Partial<Style> | null;

const alignmentDefault: Style['alignment'] = {
  horizontal: 'right',
  indent: 1,
};

const borderMedium: Partial<Border> = {
  style: 'medium',
  color: { argb: 'FF000000' },
};

const borderThin: Partial<Border> = {
  style: 'thin',
  color: { argb: 'FF000000' },
};

const bordersValue: Partial<Borders> = {
  top: borderMedium,
  bottom: borderMedium,
  left: borderThin,
  right: borderThin,
};

const styleValue: CellStyle = {
  numFmt: '0',
  alignment: alignmentDefault,
  border: bordersValue,
};

const stylePercentage: CellStyle = {
  numFmt: '0.00%',
  alignment: alignmentDefault,
  border: bordersValue,
};

const styleMoney: CellStyle = {
  numFmt: '_("$"* #,##0_);_("$"* (#,##0);_(@_)',
  alignment: alignmentDefault,
  border: bordersValue,
};

const bordersMediumAll: Partial<Borders> = {
  top: borderMedium,
  bottom: borderMedium,
  left: borderMedium,
  right: borderMedium,
};

const styleHeaderBlack: CellStyle = {
  font: {
    bold: true,
    color: { argb: 'FFFFFFFF' },
  },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF000000' },
  },
  alignment: {
    horizontal: 'center',
    indent: 1,
  },
  border: bordersMediumAll,
};

const styleHeaderGrey: CellStyle = {
  font: {
    bold: true,
  },
  alignment: {
    horizontal: 'left',
    indent: 1,
  },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD9D9D9' },
  },
  border: bordersMediumAll,
};

const styleTitle: CellStyle = {
  font: {
    bold: true,
  },
  alignment: {
    horizontal: 'center',
    vertical: 'middle',
  },
  border: bordersMediumAll,
};

export const styleBoldText: CellStyle = {
  font: {
    bold: true,
  },
};

export const rowStyleWorkflowHeader: CellStyle[] = [
  null,
  styleHeaderBlack,
  styleHeaderBlack,
  styleHeaderGrey,
  styleHeaderGrey,
];

export const rowStyleWorflowValues: CellStyle[] = [
  null,
  styleTitle,
  styleTitle,
  styleValue,
  stylePercentage,
];

export const rowStyleResolutionHeader: CellStyle[] = [
  null,
  styleHeaderBlack,
  styleHeaderBlack,
  styleHeaderGrey,
  styleHeaderGrey,
  styleHeaderGrey,
  styleHeaderGrey,
  styleHeaderGrey,
];

export const rowStyleResolutionValues: CellStyle[] = [
  null,
  styleTitle,
  styleTitle,
  styleValue,
  stylePercentage,
  styleMoney,
  styleMoney,
  styleMoney,
];

export const rowStyleClaimsWorflowValues: CellStyle[] = [
  null,
  styleTitle,
  styleTitle,
  styleValue,
  {
    numFmt: '$#,##0.00',
    alignment: alignmentDefault,
    border: bordersValue,
  },
];

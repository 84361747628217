import Excel from 'exceljs';
import dayjs from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  reports,
  ClaimsWalkDownDetailedSummaryResponse,
  ClaimsWalkDownDetailedSummarySection,
  ClaimsWalkDownDetailedSummaryItem,
} from 'api/endpoints';
import {
  loadTemplate,
  insertNumberOrEmptyRows,
} from 'pages/ExhibitExporterPage/templates/utils/exporterUtils';
import { NEGATIVE_NUM_FORMAT } from 'pages/ClaimsContractsToolPage/const';

// needed to show 3rd, 1st August
dayjs.extend(advancedFormat);

export interface ClaimsWalkDownSummaryParams {
  legalEntityId?: number;
  legalEntityName?: string;
}
export interface ClaimsWalkDownSummaryExcelData {
  headerFooter: never[];
  reportData?: {
    data: ClaimsWalkDownDetailedSummaryResponse;
  };
}

export interface ClaimsWalkDownSummaryTemplateObject {
  templateStart: [number, number];
  dataStart: [number, number];
  subHeaderTemplateRow: number;
  categoryTemplateRow: number;
  totalTemplateRow: number;
  subHeaderTemplateCell: Excel.Cell;
  categoryTemplateCell: Excel.Cell;
  totalTemplateCell: Excel.Cell;
}

type SectionProps = {
  sheet: Excel.Worksheet;
  section: ClaimsWalkDownDetailedSummarySection;
  templateStart: [number, number];
  position: [number, number];
  templateObject: ClaimsWalkDownSummaryTemplateObject;
};

type SectionItemsProps = {
  sheet: Excel.Worksheet;
  items: ClaimsWalkDownDetailedSummaryItem[];
  templateStart?: [number, number];
  position: [number, number];
  templateObject: ClaimsWalkDownSummaryTemplateObject;
};

export const ClaimsWalkDownDetailedSummary = {
  fileName: 'ClaimsWalkDownDetailed',
  getWorkbook: async (params: ClaimsWalkDownSummaryParams) => {
    const excelData = await getDataToShow(params);
    return await createWorkbook(excelData as any, params);
  },
};

const GAP_BETWEEN_SECTIONS = 1;

async function createWorkbook(
  excelData: ClaimsWalkDownSummaryExcelData,
  params: ClaimsWalkDownSummaryParams,
): Promise<Excel.Workbook | undefined> {
  const templateData = await loadTemplate('ClaimsWalkDownDetail.xlsx', 'reports');

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(templateData);

  if (!excelData.reportData) {
    return;
  }
  const firstSheet = workbook.worksheets[0];

  const templateStart = [9, 2];
  const dataStart = [14, 2];
  const templateObject = extractTemplateData(
    firstSheet,
    [templateStart[0], templateStart[1]],
    [dataStart[0], dataStart[1]],
  );

  renderHeader(firstSheet, params);

  // render section by section
  renderSections(firstSheet, templateObject, excelData.reportData.data);

  // delete template row
  deleteTemplateRows(firstSheet, templateObject);

  return workbook;
}

function extractTemplateData(
  sheet: Excel.Worksheet,
  templateStart: [number, number],
  dataStart: [number, number],
): ClaimsWalkDownSummaryTemplateObject {
  const subHeaderTemplateRow = templateStart[0];
  const categoryTemplateRow = templateStart[0] + 1;
  const totalTemplateRow = templateStart[0] + 2;

  const subHeaderTemplateCell = sheet.getCell(
    subHeaderTemplateRow,
    templateStart[1],
  );
  const categoryTemplateCell = sheet.getCell(categoryTemplateRow, templateStart[1]);
  const totalTemplateCell = sheet.getCell(totalTemplateRow, templateStart[1]);

  return {
    templateStart,
    dataStart,
    subHeaderTemplateRow,
    categoryTemplateRow,
    totalTemplateRow,
    subHeaderTemplateCell,
    categoryTemplateCell,
    totalTemplateCell,
  };
}

function deleteTemplateRows(
  sheet: Excel.Worksheet,
  templateObject: ClaimsWalkDownSummaryTemplateObject,
) {
  sheet.spliceRows(templateObject.totalTemplateRow, 1);
  sheet.spliceRows(templateObject.categoryTemplateRow, 1);
  // let's delete extra 3 empty rows
  sheet.spliceRows(templateObject.subHeaderTemplateRow, 4);
}

async function renderHeader(
  sheet: Excel.Worksheet,
  params: ClaimsWalkDownSummaryParams,
) {
  const headerStart = [1, 1];

  sheet.getCell(
    headerStart[0],
    headerStart[1],
  ).value = `Debtor [${params.legalEntityName}]`;
  sheet.getCell(
    headerStart[0] + 2,
    headerStart[1],
  ).value = `Date [As of ${dayjs().format('MMMM Do, YYYY')}]`;
}
async function renderSections(
  sheet: Excel.Worksheet,
  templateObject: ClaimsWalkDownSummaryTemplateObject,
  data: ClaimsWalkDownDetailedSummaryResponse,
) {
  const { dataStart, templateStart } = templateObject;

  let renderedRowsCount = 0;
  data.sections.forEach((section) => {
    renderedRowsCount += renderSection({
      sheet,
      section,
      templateStart: [templateStart[0], templateStart[1]],
      position: [dataStart[0] + renderedRowsCount, dataStart[1]],
      templateObject,
    });
  });
}

/**
 *
 * @param sheet
 * @param section
 * @param templateStart
 * @param position
 * @param templateObject
 * @returns
 */
function renderSection({
  sheet,
  section,
  templateStart,
  position,
  templateObject,
}: SectionProps) {
  let rowsInserted = 0;
  const renderRow = position[0] + 1;

  if (section.subHeader) {
    insertNumberOrEmptyRows(sheet, renderRow - 1, 1);
    rowsInserted++;
    sheet.getCell(renderRow, position[1]).value = section.subHeader;
    sheet.getCell(renderRow, position[1]).style =
      templateObject.subHeaderTemplateCell.style;
  }

  if (section.items) {
    renderItems({
      sheet,
      items: section.items,
      position: [renderRow + rowsInserted, position[1]],
      templateObject,
    });
    rowsInserted += section.items.length;
  }

  // lets add gap between sections
  insertNumberOrEmptyRows(sheet, renderRow + rowsInserted, GAP_BETWEEN_SECTIONS);

  rowsInserted += GAP_BETWEEN_SECTIONS;

  return rowsInserted;
}

const subheaders = [
  {
    label: 'Count',
    key: 'claimCount',
  },
  {
    label: 'Secured',
    key: 'secured',
  },
  {
    label: 'Administrative',
    key: 'administrative',
  },
  {
    label: 'Priority',
    key: 'priority',
  },
  {
    label: 'Unsecured',
    key: 'unsecured',
  },
  {
    label: 'Total',
    key: 'total',
  },
];

function renderItems({ sheet, items, position, templateObject }: SectionItemsProps) {
  insertNumberOrEmptyRows(sheet, position[0], items.length);

  items.forEach((item, index) => {
    const rowIndex = position[0] + index;
    const columnIndex = position[1];

    // render description
    sheet.getCell(rowIndex, columnIndex).value = item.description;
    if (item.isTotalRow) {
      sheet.getCell(rowIndex, columnIndex).style =
        templateObject.totalTemplateCell.style;
    } else {
      sheet.getCell(rowIndex, columnIndex).style =
        templateObject.categoryTemplateCell.style;
    }

    subheaders.forEach((subheader, subheaderIndex) => {
      // render values
      const columnPosition = columnIndex + subheaderIndex + 1;

      const cell = sheet.getCell(rowIndex, columnPosition);
      cell.value = item[subheader.key as keyof ClaimsWalkDownDetailedSummaryItem];

      if (item.isTotalRow) {
        // copy style from item totalTemplateRow for template row
        cell.style = sheet.getCell(
          templateObject.totalTemplateRow,
          columnPosition,
        ).style;
      } else {
        // copy style from item categoryTemplateRow for normal row
        cell.style = sheet.getCell(
          templateObject.categoryTemplateRow,
          columnPosition,
        ).style;
      }
      if (subheader.key === 'claimCount') {
        // as for amounts, we show negative claim counts as '(1)' instead of '-1'
        cell.style.numFmt = NEGATIVE_NUM_FORMAT;
      }
    });
  });
}

async function getDataToShow(params: ClaimsWalkDownSummaryParams) {
  return {
    headerFooter: [],
    reportData: await getPreparedCategoryReportData(params.legalEntityId),
  };
}

const getPreparedCategoryReportData = async (legalEntityId?: number) => {
  const response = await reports.getClaimsWalkDownDetailedSummary(legalEntityId);
  if (!response) return undefined;

  return {
    data: response,
  };
};

import { fileInfo, IErrorsSummaryResponse, match, MatchStatus } from 'api/endpoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileType } from 'types/dataUploaderTypes';
import { useMonitoringTimer } from 'utils/hooks/useMonitoringTimer';
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect';

const POLL_INTERVAL = 5000;
const inProgressStatuses = [MatchStatus.Pending, MatchStatus.Running];

export const useMatchingExport = () => {
  const [downloading, setDowlnoading] = useState(false);
  const [matchingStatus, setMatchingStatus] = useState<MatchStatus | undefined>();
  const [fileErrors, setFileErrors] = useState<IErrorsSummaryResponse>();
  const { startTimer, stopTimer } = useMonitoringTimer(POLL_INTERVAL);

  const monitoringStatus = useMemo(
    () => matchingStatus && inProgressStatuses.includes(matchingStatus),
    [matchingStatus],
  );
  const getStatus = useCallback(async () => {
    const resp = await match.getStatus();
    setMatchingStatus(resp?.runtimeStatus);
    if (resp?.customStatus?.ProcessingStatus === MatchStatus.Failed) {
      const errorsResp = await fileInfo.getErrorsSummary(FileType.BaselineMatches);
      setFileErrors(errorsResp?.find((e) => e.hasError));
    }
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onDownload = useCallback(async () => {
    setDowlnoading(true);
    await match.getMatchingExport();
    setDowlnoading(false);
  }, []);

  useUpdateEffect(() => {
    if (!monitoringStatus) {
      stopTimer();
      return;
    }
    if (monitoringStatus) startTimer(getStatus);
  }, [matchingStatus, stopTimer, monitoringStatus, getStatus]);

  const onMatchingTrigger = useCallback(async () => {
    setMatchingStatus(MatchStatus.Running);
    await match.trigger();
    startTimer(getStatus);
  }, [startTimer, getStatus]);

  return {
    downloading,
    matchingStatus,
    onDownload,
    onMatchingTrigger,
    inProgressStatus: monitoringStatus,
    fileErrors,
    setFileErrors,
  };
};

import { ApTextInput } from '@alixpartners/ui-components';
import { ButtonColorPicker } from 'components/UIComponents/Buttons';

export interface WizardColorSelectorProps {
  color: string;
  onChange: (color: string) => unknown;
}

export const WizardColorSelector = ({
  color,
  onChange,
}: WizardColorSelectorProps) => {
  return (
    <div
      className="setup-wizard-field__colorselector setup-wizard-line"
      style={{ position: 'relative' }}
    >
      <div className="setup-wizard-field__coloredit">
        <ApTextInput
          id="setup-wizard-color"
          value={color}
          maxLength={7}
          onChange={(e: React.FormEvent<any>) => {
            onChange(e.currentTarget.value);
          }}
        />
      </div>

      <ButtonColorPicker color={color} onChange={onChange} />
    </div>
  );
};

import {
  CascadingContractBatchItemType,
  IBatchItem,
} from '../../contractsBatchEdit.type';
import { ContractCategoryType } from './ContractCategoryType';
import { ContractResolutionType } from './ContractResolutionType';
import { ContractResolutionName } from './ContractResolutionName';

export const ContractCascadingBatchItem = ({
  type,
  onChange,
  addCascadingItem,
  propName,
}: {
  type: CascadingContractBatchItemType;
  addCascadingItem: (item: IBatchItem) => void;
  onChange: (val: number) => void;
  propName: string;
}) => {
  if (type === 'contractCategoryType') {
    return (
      <ContractCategoryType
        onChange={onChange}
        addCascadingItem={addCascadingItem}
      />
    );
  }
  if (type === 'contractResolutionItems') {
    // this type will accommodate WorkflowStatus, Resolution Type and Resolution Subtype fields
    return (
      <ContractResolutionType
        onChange={onChange}
        addCascadingItem={addCascadingItem}
        propName={propName}
      />
    );
  }
  if (type === 'contractResolutionName') {
    return (
      <ContractResolutionName
        onChange={onChange}
        addCascadingItem={addCascadingItem}
      />
    );
  }
  return null;
};

import { PageSections } from 'pages/SingleContractPage/sections/SingleContractOverview/types';
import { CategorySelectType } from '../components/CategorySelect';
import { SCMatrix, SingleClaimInputEntity, SingleClaimSection } from '../types';

/**
 * in this file you can define  displayed
 * field for client user per resolution type status
 */

const EMPTY_ITEM: SingleClaimInputEntity = {
  type: 'empty',
  label: '',
  field: '',
};

const AMOUNTS_MATRIX: SCMatrix = {
  rows: [
    {
      label: 'Assessed',
      field: 'client',
    },
    {
      type: 'empty',
    },
    {
      label: 'Original',
      field: 'original',
    },
    {
      label: 'Current',
      field: 'current',
    },
    {
      label: 'Proposed',
      field: 'proposed',
    },
  ],
  columns: [
    {
      label: 'Secured',
      field: 'secured',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Admin',
      field: 'administrative',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Priority',
      field: 'priority',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Unsecured',
      field: 'unsecured',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Total',
      field: 'total',
      type: 'number',
      formatting: 'currency',
    },
    {
      label: 'Debtor',
      field: 'debtorId',
      type: 'category',
      category: {
        type: CategorySelectType.LegalEntities,
        name: 'debtorId',
      },
    },
    {
      type: 'empty',
    },
    {
      label: 'Contingent',
      field: 'contingent',
      type: 'bool',
    },
    {
      label: 'Unliquidated',
      field: 'unliquidated',
      type: 'bool',
    },
    {
      label: 'Disputed',
      field: 'disputed',
      type: 'bool',
    },
  ],
};

const DEFAULT_SECTIONS: SingleClaimSection[] = [
  {
    id: PageSections.Categorization,
    title: 'Categorization',
    inputs: [
      {
        label: 'Major/minor category',
        field: 'minorCategory',

        category: {
          type: CategorySelectType.Category,
          name: 'minorCategoryId',
        },
      },
    ],
  },
  {
    id: PageSections.Assignment,
    title: 'Assignment',
    inputs: [
      {
        label: 'Claim reviewer',
        field: 'claimReviewer',

        category: {
          type: CategorySelectType.ClaimReviewer,
          name: 'claimReviewer',
        },
      },
    ],
  },
  {
    id: PageSections.ClaimStatus,
    title: 'Claim status',
    orientation: 'vertical',
    inputs: [
      {
        label: 'Resolution type',
        field: 'actionTypeId',

        category: {
          type: CategorySelectType.ActionType,
          name: 'actionTypeId',
        },
      },
      {
        label: 'Resolution subtype',
        field: 'actionSubstatusId',

        category: {
          type: CategorySelectType.ActionSubstatus,
          name: 'actionSubstatusId',
        },
      },
      {
        label: 'Surviving claim no(s)',
        field: 'survivingClaimNumber',

        category: {
          type: CategorySelectType.SurvivingClaimNo,
          name: 'survivingClaimNumber',
        },
      },
      {
        label: 'Action review status',
        field: 'actionReview',
      },
      {
        label: 'Review level',
        field: 'reviewLevelId',
        category: {
          type: CategorySelectType.ReviewLevel,
          name: 'reviewLevelId',
        },
      },
    ],
  },
  {
    id: PageSections.AdditionalInformation,
    title: 'Additional information',
    inputs: [
      {
        field: 'notes',
        label: 'Notes',
        textField: true,
      },
      {
        label: 'Parent claim number',
        field: 'parentClaimNumber',
      },
      {
        label: 'Settlement number',
        field: 'settlementNumber',
      },
      {
        label: 'BarDateRef',
        field: 'barDateRef',
      },
    ],
  },
  {
    id: PageSections.Amounts,
    title: 'Amounts',
    matrix: AMOUNTS_MATRIX,
  },
  {
    id: PageSections.CounterpartyInfo,
    title: 'Counterparty Contact Information',
    inputs: [
      {
        label: 'Contact full name',
        field: 'contactFullName',
      },
      {
        label: 'Email',
        field: 'email',
        type: 'address',
      },
      {
        label: 'Address Line 1',
        field: 'address1',
        type: 'address',
      },
      {
        label: 'Address Line 2',
        field: 'address2',
        type: 'address',
      },
      {
        label: 'Address Line 3',
        field: 'address3',
        type: 'address',
      },
      {
        label: 'Address Line 4',
        field: 'address4',
        type: 'address',
      },
      {
        label: 'City',
        field: 'city',
        type: 'address',
      },
      {
        label: 'Phone number',
        field: 'phone',
        type: 'address',
      },
      {
        label: 'State',
        field: 'state',
        type: 'address',
      },
      {
        label: 'Country',
        field: 'country',
        type: 'address',
      },
      {
        label: 'Zip code',
        field: 'zip',
        type: 'address',
      },
    ],
  },
];

const OTHER_SECTIONS = convert(
  DEFAULT_SECTIONS,
  removeInputs(['survivingClaimNumber']),
);

export const ALLOW_SECTIONS = OTHER_SECTIONS;
export const SETTLEMENT_SECTIONS = OTHER_SECTIONS;
export const OBJECTION_SECTIONS = OTHER_SECTIONS;

export const EXPUNGE_OBJECTIONS_SECTIONS = convert(
  DEFAULT_SECTIONS,
  removeSections([
    'Assignment',
    'Counterparty Contact Information',
    'Additional information',
  ]),
  removeInputs(['actionReview', 'reviewLevelId']),
  addEmptyFields({ section: 'Claim status', count: 2 }),
);

export const EXPUNGE_OTHER_SECTIONS = convert(
  EXPUNGE_OBJECTIONS_SECTIONS,
  removeInputs(['survivingClaimNumber']),
);
export const EXPUNGE_SETTLEMENT_SECTIONS = EXPUNGE_OTHER_SECTIONS;

//-- internal -------------------------------------

type ConvertFn = (items: SingleClaimSection[]) => SingleClaimSection[];
function convert(sectionItems: SingleClaimSection[], ...convertFn: ConvertFn[]) {
  const result = convertFn.reduce((res, fn) => fn(res), sectionItems);
  return result;
}

function removeSections(sectionToSkip: string[]) {
  return (items: SingleClaimSection[]) =>
    items.filter((r) => !sectionToSkip.includes(r.title));
}

function removeInputs(inputsToSkip: string[]) {
  return (items: SingleClaimSection[]) =>
    items.map((r) => {
      if (!r.inputs) return r;
      return {
        ...r,
        inputs: r.inputs?.filter((inp) => !inputsToSkip.includes(inp.field)),
      };
    });
}

function addEmptyFields(props: { section: string; count: number }) {
  const { section, count } = props;

  return (items: SingleClaimSection[]) => {
    const emptyItems: SingleClaimInputEntity[] = Array(count).fill(EMPTY_ITEM);

    items.find((r) => r.title === section)?.inputs?.push(...emptyItems);
    return items;
  };
}

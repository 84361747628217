import { getQuiet } from 'api/apiRequest';

export type BackgroundjobStatus = 'InProgress' | 'Completed' | 'Failed' | 'Queued';

export const BACKGROUND_IN_PROGRESS: BackgroundjobStatus[] = [
  'InProgress',
  'Queued',
];

export interface BackgroundjobResponse<T> {
  id: number;
  status: BackgroundjobStatus;
  data: T;
}

export const backgroundjob = {
  get: <T>(jobId: number) =>
    getQuiet<BackgroundjobResponse<T>>(`v1/backgroundjob/${jobId}/status`),
};

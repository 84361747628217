import classNames from 'classnames';
import { ReactNode } from 'react';
import './Section.css';

export interface SectionProps {
  className?: string;
  title?: string;
  children: ReactNode;
}

export const Section = ({ children, title, className }: SectionProps) => {
  return (
    <div className={classNames('scp-section', className)}>
      {title && <div className="scp-section__title">{title.toUpperCase()}</div>}
      <div className="scp-section__content">{children}</div>
    </div>
  );
};

import { settings } from 'api/endpoints';
import { useEffect, useRef, useState } from 'react';
import { useAppInitialDataContext } from 'utils/AppInitializer/AppInitalDataProvider';
import { fetchUrlFileToBuffer } from 'utils/uploader';

import { ApTextInput } from '@alixpartners/ui-components';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { DivWithLoader } from 'components/UIComponents/DivWithLoader/DivWithLoader';
import { Panel } from 'components/UIComponents/EntityTable';
import { SettingsContentPanel } from 'pages/SettingsPage/components/SettingsContentPanel';
import { WizardFieldLabel } from 'pages/SetupWizardPage/component';
import { urlExtractFileName } from 'utils/file';
import { apiClientNameLogo } from './api/apiClientNameLogo';
import './ClientNameLogo.css';
import { LogoSelector, LogoSelectorRef } from './components/LogoSelector';
import { PreviewModal } from './PreviewModal';

export function ClientNameLogo() {
  const editor = useRef<LogoSelectorRef>(null);
  const { data } = useAppInitialDataContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [logoDataBuffer, setLogoDataBuffer] = useState<ArrayBuffer>();
  const [logoChanged, setLogoChanged] = useState<boolean>(false);

  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const fetchInit = async () => {
    setLoading(true);

    const { data } = await settings.getBasicInfo();
    setCompanyName(data?.clientCompanyName ?? '');

    const logoImage = await fetchUrlFileToBuffer(data?.logoFileName);
    setLogoDataBuffer(logoImage);

    setLoading(false);
  };

  useEffect(() => {
    fetchInit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveClientInfo = async () => {
    setLoading(true);
    const { clientInfo } = data.basicInfo;

    // Important!! clientInfo.logoFileName - contains full url path to image. !! We need only filename !!
    let logoFileName = urlExtractFileName(clientInfo.logoFileName) ?? '';
    if (logoChanged) {
      const logoContent = await editor.current?.getImage();
      logoFileName = await apiClientNameLogo.uploadLogo(logoContent);
    }

    await apiClientNameLogo.saveBasicInfo(clientInfo, companyName, logoFileName);

    await data.basicInfo.init();
    setLoading(false);

    await fetchInit();
  };

  const handlePreviewClick = async () => {
    setShowPreviewModal(true);

    const logo = await editor.current?.getImage();
    setLogoDataBuffer(logo);
  };

  const handleSetLogo = (content: ArrayBuffer) => {
    setLogoChanged(true);
    setLogoDataBuffer(content);
  };

  const onLogoRemoved = () => {
    setLogoChanged(true);
    setLogoDataBuffer(undefined);
  };

  return (
    <SettingsContentPanel>
      <DivWithLoader className="client-name-logo" loading={loading}>
        <Panel title="Client Name">
          <WizardFieldLabel text="Client company name" />
          <ApTextInput
            id=""
            value={companyName}
            onChange={(e: React.FormEvent<any>) =>
              setCompanyName(e.currentTarget.value)
            }
          ></ApTextInput>
        </Panel>
        <Panel title="Logo">
          <LogoSelector
            ref={editor}
            initialLogo={logoDataBuffer}
            allowModify={logoChanged}
            onLogoUploaded={handleSetLogo}
            onLogoRemoved={onLogoRemoved}
          />
        </Panel>
        <div className="client-name-logo__footer">
          <ApButtonCasual onClick={handlePreviewClick} disabled={loading}>
            Preview
          </ApButtonCasual>
          <ButtonMainLoader onClick={saveClientInfo} loading={loading}>
            Save Changes
          </ButtonMainLoader>
        </div>
      </DivWithLoader>
      {showPreviewModal && (
        <PreviewModal
          isOpen={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
          previewData={{
            newCompanyLogo: logoDataBuffer,
            companyName: companyName,
          }}
        />
      )}
    </SettingsContentPanel>
  );
}

import {
  ApButtonGroup,
  ApButtonTab,
  ApLoaderDefault,
  ApMenuItem,
} from '@alixpartners/ui-components';
import React, { useState } from 'react';
import { MatchesAcceptedPopup } from '../../components/MatchesAcceptedPopup';
import { MatchGroupCreationPopup } from '../../components/MatchGroupCreationPopup';
import { SECONDARY_HEADER_SECTIONS } from '../../const';
import { ExportExcelArgs, SubsectionType } from '../../types';

import { ClaimRow } from 'api/endpoints';
import classNames from 'classnames';
import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { DropdownMenuSecondary } from 'components/DropdownMenu/DropdownMenuSecondary';
import { ApButtonMainEx } from 'components/UIComponents/Buttons';
import { GridCountHeaderLabel } from 'pages/ClaimsContractsToolPage/components/GridCountHeaderLabel';

export interface IClaimsToolSubheaderProps {
  subsection: SubsectionType;
  claimsCount?: number;
  claimsExpandState: [
    ApThreeWayValue,
    React.Dispatch<React.SetStateAction<ApThreeWayValue>>,
  ];
  isColumnSelectionOpen: boolean;
  selectedRows: ClaimRow[];
  onSubsectionClick: (subsection: SubsectionType) => unknown;
  onMatchGroupSelectedClaims?: () => unknown;
  onAcceptMatches?: () => unknown;
  onExportClaims: (params: ExportExcelArgs) => void;
  isExporting: boolean;
}

export const ClaimsToolSubheader = ({
  selectedRows,
  subsection,
  claimsCount,
  claimsExpandState,
  onMatchGroupSelectedClaims,
  onAcceptMatches,
  onSubsectionClick,
  onExportClaims,
  isExporting,
}: IClaimsToolSubheaderProps) => {
  const [isMatchingModalOpen, setMatchingModalOpen] = useState(false);
  const [expandValue, setExpandValue] = claimsExpandState;
  const [
    isOneHundredPercentMatchingModalOpen,
    setOneHundredPercentMatchingModalOpen,
  ] = useState(false);
  return (
    <div className="claims-tool-subheader">
      {isMatchingModalOpen && (
        <MatchGroupCreationPopup
          isOpen={isMatchingModalOpen}
          onAccept={() => {
            if (!onMatchGroupSelectedClaims) return;
            onMatchGroupSelectedClaims();
          }}
          onClose={() => setMatchingModalOpen(false)}
          claims={selectedRows}
        />
      )}
      {isOneHundredPercentMatchingModalOpen && (
        <MatchesAcceptedPopup
          isOpen={isOneHundredPercentMatchingModalOpen}
          onAccept={() => {
            if (!onAcceptMatches) return;
            onAcceptMatches();
          }}
          onClose={() => setOneHundredPercentMatchingModalOpen(false)}
          claims={selectedRows}
        />
      )}
      <div className="claims-tool-subheader__tabs">
        <ApButtonGroup
          justifyContent="initial"
          spacingSize={0}
          className="subheader-tabs"
        >
          {SECONDARY_HEADER_SECTIONS.map((s) => (
            <ApButtonTab
              key={s.type}
              className={classNames('subheader-tab-button', {
                selected: subsection === s.type,
              })}
              autoFocus={false}
              isSelected={subsection === s.type}
              onClick={() => onSubsectionClick(s.type)}
              theme="light"
            >
              {s.label}
            </ApButtonTab>
          ))}
        </ApButtonGroup>
      </div>
      <GridCountHeaderLabel
        count={claimsCount}
        notMatched={subsection === SubsectionType.UnmatchedClaims}
        type="claims"
      />
      <div className="claims-tool-subheader__menu">
        {subsection === SubsectionType.UnmatchedClaims && (
          <>
            <div className="claims-tool-subheader__hide_expand">
              <ApThreeWaySwitch
                id="claims-expanded-switch"
                value={expandValue}
                label={
                  {
                    [ApThreeWayValue.No]: 'Expand all claims',
                    [ApThreeWayValue.Unknown]: 'Collapse all claims',
                    [ApThreeWayValue.Yes]: 'Collapse all claims',
                  }[expandValue]
                }
                onChange={(value) => setExpandValue(value)}
              />
            </div>
            <div className="claims-tool-subheader__accept_button">
              <ApButtonMainEx
                thinShape
                disabled={!onAcceptMatches}
                onClick={() => setOneHundredPercentMatchingModalOpen(true)}
              >
                Accept selected 100% matches
              </ApButtonMainEx>
            </div>
          </>
        )}
        {subsection === SubsectionType.AllClaims && (
          <>
            <div className="claims-tool-subheader__accept_button">
              <ApButtonMainEx
                thinShape
                disabled={!onMatchGroupSelectedClaims}
                onClick={() => setMatchingModalOpen(true)}
              >
                Match-group selected claims
              </ApButtonMainEx>
            </div>

            <DropdownMenuSecondary
              label="Export"
              iconName="save_alt"
              disableCloseOnSubmenuClick={isExporting}
              thinShape
              className={'claims-tool-subheader__export-menu'}
              buttonClassname={'subheader__export-button'}
            >
              {isExporting ? (
                <div className="subheader__export-loader_container">
                  <ApLoaderDefault
                    loaderSize="sm"
                    className="claims-tool-subheader__export-loader"
                  />
                  <div>Generating Excel file</div>
                </div>
              ) : (
                <ApMenuItem onClick={() => onExportClaims({})}>
                  Export Claim table
                </ApMenuItem>
              )}
              <ApMenuItem disabled={true}>Export All Audit Logs</ApMenuItem>
            </DropdownMenuSecondary>
          </>
        )}
      </div>
    </div>
  );
};

import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import {
  MenuItem,
  MenuSubItem,
} from 'components/DisclosureLinkMenu/DisclosureLinkMenu.types';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { ButtonNav } from 'pages/SettingsPage/components/ButtonNav';
import { NavLink } from 'react-router-dom';

type DLProps = {
  item: MenuItem;
  isOpened: boolean;
  selectedItem?: MenuItem;
  selectedSubItem?: MenuSubItem;
  onSectionClick: (item: MenuItem, subItem?: MenuSubItem) => void;
};

export const NestedLink = ({
  item,
  isOpened,
  selectedSubItem,
  onSectionClick,
}: DLProps) => {
  const itemCN = classNames('disclosurelinkmenu__item', {
    'disclosurelinkmenu__item-active': isOpened,
  });

  return (
    <Disclosure className="disclosurelinkmenu" as={'div'}>
      <Disclosure.Button className={itemCN} onClick={() => onSectionClick(item)}>
        <span>{item.label}</span>
        <SvgIcon
          iconName="chevron_left"
          className={
            isOpened
              ? 'disclosurelinkmenu__chevron-open'
              : 'disclosurelinkmenu__chevron'
          }
          width={16}
          height={16}
        />
      </Disclosure.Button>
      <Transition
        show={isOpened}
        enter="enter"
        enterFrom="enterFrom"
        enterTo="enterTo"
        leave="leave"
        leaveFrom="leaveFrom"
        leaveTo="leaveTo"
      >
        <Disclosure.Panel className="disclosurelinkmenu__panel">
          {item.subItems?.map((subitem) => {
            const { active, label, root, disabled } = subitem;
            const { defaultLink, link = '' } = subitem;

            if (item.isButton) {
              return (
                <ButtonNav
                  key={`disclose-${label}`}
                  text={label}
                  onClick={() => onSectionClick?.(item, subitem)}
                  className={classNames('disclosurelinkmenu__subitem', {
                    'disclosurelinkmenu__subitem-active':
                      selectedSubItem === subitem,
                  })}
                  disabled={disabled}
                />
              );
            }

            if (active === false)
              return (
                <div
                  className="disclosurelinkmenu__subitem disclosurelinkmenu__subitem-disabled"
                  key={`disclose-${label}`}
                >
                  <span>{label}</span>
                </div>
              );

            return (
              <NavLink
                className={createSubitemCN}
                key={link}
                to={root && defaultLink ? `/${defaultLink}` : link}
              >
                <span>{label}</span>
              </NavLink>
            );
          })}
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

function createSubitemCN({ isActive }: { isActive: boolean }) {
  return classNames('disclosurelinkmenu__subitem', {
    'disclosurelinkmenu__subitem-active': isActive,
  });
}

import { ApIcon, ApTooltip } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './ApIconTooltip.css';

type ToolTipProps = Omit<React.ComponentProps<typeof ApTooltip>, 'children'>;

interface Props extends ToolTipProps {
  iconName?: string;
  iconSize?: number;
  iconColor?: string;
}

export const ApIconInfoTooltip = ({
  className,
  iconName = 'outline_info',
  iconSize = 15,
  iconColor = '#737373',

  ...tooltipProps
}: Props) => {
  return (
    // placement value should be "bottom"
    // when placement="top" - the tooptip sometimes might not appear
    <ApTooltip enterDelay={0} placement="bottom" {...tooltipProps}>
      <span className={classNames('apicon-tooltip', className)}>
        <ApIcon iconName="outline_info" iconColor={iconColor} iconSize={iconSize} />
      </span>
    </ApTooltip>
  );
};

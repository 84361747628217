import { Location } from 'history';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import qs, { IParseOptions, IStringifyOptions } from 'qs';

interface LocationDescriptorObject {
  pathname?: string | undefined;
  search?: string | undefined;
  hash?: string | undefined;
  key?: string | undefined;
}

const urlSafeCharMap = '{}:,"\'/[]'
  .split('')
  .map((char) => [encodeURIComponent(char), char]);

const preserveUrlSafeCharacters = (str: string): string =>
  urlSafeCharMap.reduce(
    (acc, next) => acc.replace(new RegExp(next[0], 'g'), next[1]),
    str,
  );

export const getQueryParam = (
  location: Location | LocationDescriptorObject,
  param: string,
): string | undefined => {
  if (!location.search) {
    return undefined;
  }
  const params = new URLSearchParams(location.search);
  const value = params.has(param) ? params.get(param) : undefined;
  return isNil(value) ? undefined : value;
};

/**
 * Set a query param in a URL
 * ```
 * setQueryParam({ seach: ?foo=1 }, 'bar', 2)
 * //=> { search: ?foo=1&bar=2 }
 * ```
 */
export const setQueryParam = <S extends LocationDescriptorObject>(
  location: S,
  param: string,
  value: string,
): S => {
  const params = new URLSearchParams(location.search);
  params.set(param, value);
  const paramsString = preserveUrlSafeCharacters(params.toString());
  return { ...location, search: `?${paramsString}` };
};

export const deleteQueryParams = <S extends LocationDescriptorObject>(
  location: S,
  deleted: string[],
): S => {
  const params = new URLSearchParams(location.search);
  deleted.forEach((param) => {
    params.delete(param);
  });
  const paramsString = preserveUrlSafeCharacters(params.toString());
  return { ...location, search: `?${paramsString}` };
};

/**
 * Merge query params of two URLs
 * ```
 * mergeQueryParams({ search: ?foo=1 }, '?bar=2')
 * //=> { search: ?foo=1&bar=2 }
 * ```
 */
export const mergeQueryParams = <S extends LocationDescriptorObject>(
  location: S,
  searchString: string | undefined,
): S => {
  const params1 = new URLSearchParams(location.search);
  const params2 = new URLSearchParams(searchString);
  const params = new URLSearchParams({
    ...Object.fromEntries(params1.entries()),
    ...Object.fromEntries(params2.entries()),
  });
  const paramsString = preserveUrlSafeCharacters(params.toString());
  return { ...location, search: `?${paramsString}` };
};

/**
 * Stringify a query param value by as JSON.
 *
 * This is the simplest and safest way to save data in URL, ie. `value` will ALWAYS
 * equal `parse(stringify(value))`. This is does not hold when using 'qs'
 * or 'query-string' libraries. The drawback is that saving complex objects will
 * be serialized with curly braces in the URL.
 *
 * ```
 * const value = { query: value }
 * stringifyQueryParamAsJson(location, param, value)
 * //=> { search: "?param={key:\"value\"}",... }
 * ```
 */
export const stringifyQueryParamAsJson = <S, L extends LocationDescriptorObject>(
  location: L,
  param: string,
  value: S,
): L => {
  if (value === undefined) {
    return deleteQueryParams(location, [param]);
  }
  const valueAsString = JSON.stringify(value);
  return setQueryParam(location, param, valueAsString);
};

/**
 * Parse a query param as JSON.
 *
 * See comments for stringifyQueryParamAsJson().
 *
 * ```
 * const location = { search: "?param={key:\"value\"}" }
 * parseQueryParamAsJson(location)
 * //=> { key: 'value' }
 * ```
 */
export const parseQueryParamAsJson = <S extends unknown | undefined>(
  location: LocationDescriptorObject,
  param: string,
  validate?: (value: unknown) => boolean,
): S | undefined => {
  const paramString = getQueryParam(location, param);
  let value: S | undefined;
  try {
    value = JSON.parse(paramString!);
  } catch (e: any) {
    // Do nothing
  }
  const isValid = validate ? validate(value) : true;
  return isValid ? value : undefined;
};

/**
 * Serialize query param to URL using the 'qs' library.
 *
 * Dot notation us used for nested objects (?a.b=2).
 * "1" (string) and 1 (number) are not distinguished.
 * [1] (array) and 1 (number) are not distinguished.
 * undefined is omitted from the search params
 *
 * See https://github.com/ljharb/qs for more info.
 */
export const stringifyQueryParam = <S>(
  location: LocationDescriptorObject,
  param: string,
  value: S,
  options?: IStringifyOptions,
): LocationDescriptorObject => {
  // We cannot distinguish between null and undefined when parsing
  // the query param so we just omit it from the search string.
  const queryString = qs.stringify(
    { [param]: value },
    { allowDots: true, addQueryPrefix: true, ...options },
  );
  return mergeQueryParams(location, queryString);
};

/**
 * Parse query param from URL using the 'qs' library
 *
 * See https://github.com/ljharb/qs for more info.
 */
export const parseQueryParam = <S>(
  location: LocationDescriptorObject,
  param: string,
  validate?: (value: unknown) => boolean,
  options?: IParseOptions,
): S | undefined => {
  if (!location.search) {
    return undefined;
  }
  const query = qs.parse(location.search, {
    allowDots: true,
    ignoreQueryPrefix: true,
    ...options,
  });
  const value = query[param];
  const isValid = validate ? validate(value) : true;
  return isValid ? (query[param] as S) : undefined;
};

export const isEqualSearchString = (a: string, b: string): boolean => {
  const [cleanA, cleanB] = [a, b].map((x) =>
    qs.parse(x, { ignoreQueryPrefix: true }),
  );
  return isEqual(cleanA, cleanB);
};
/**
 * Checks if two urls are equal.
 * Unlike strict equality comparison this ignores the order of query params.
 */
export const isEqualUrl = (a: string, b: string): boolean => {
  let loc1;
  let loc2;
  try {
    const prefix = 'http://example.com';
    loc1 = new URL(`${prefix}/${a}`);
    loc2 = new URL(`${prefix}/${b}`);
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error, a, b);
    return false;
  }
  return (
    loc1.pathname === loc2.pathname &&
    loc1.hash === loc2.hash &&
    isEqualSearchString(loc1.search, loc2.search)
  );
};

import classNames from 'classnames';
import { useCallback } from 'react';
import './LinkText.css';

interface Props {
  className?: string;
  href?: string;
  children: string;
  onClick?: () => void;
}

export const LinkText = ({ className, href, children, onClick }: Props) => {
  const handleClick = useCallback(() => {
    if (href) {
      window.location.href = href;
      return;
    }

    onClick?.();
  }, [href, onClick]);

  return (
    <span className={classNames('ap-link-text', className)} onClick={handleClick}>
      {children}
    </span>
  );
};

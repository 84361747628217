import { ApplyColumnStateParams, ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { GridColumn } from 'api/endpoints';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { getSavedGridState, setSavedGridState } from 'utils/local-storage';
import { DEFAULT_DISPLAY_MAP, SECTIONS_WITH_PREDEFINED_FILTERS } from '../const';
import { SectionType } from '../types';
import usePredefinedFilters from './usePredefinedFilters';

import partition from 'lodash/partition';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import { getGridState } from '../util/getGridState';

const BooleanFalseParam = withDefault(BooleanParam, false);

export type SectionFilter = {
  colState: ColumnState[];
  groupState: {
    groupId: string;
    open: boolean;
  }[];
  filterState?: {
    [key: string]: any;
  };
  firstDisplayedRow: number;
};
export interface GridState {
  [section: string]: SectionFilter;
}

/**
 * We use this hook to save and restore column state, sort model
 * from local storage
 * @param gridRef
 * @param section
 * @param gridColumns
 * @param ready
 * @returns
 */
function useGridState({
  gridRef,
  section,
  gridColumns,
  ready,
  refreshData,
}: {
  gridRef: React.RefObject<AgGridReact<any>>;
  section: SectionType;
  gridColumns: GridColumn[] | undefined;
  ready: boolean;
  fromContracts?: boolean;
  refreshData?: () => Promise<void>;
}) {
  const gridOptions = gridRef.current;

  // when showMineClaims/showMineContracts we use claimsForReviewer/contractsForReviewer graphql query
  // and apply current applicationUserId as a filter
  const [showMine, setShowMine] = useQueryParam('showMine', BooleanFalseParam);
  const [columnState, setColumnState] =
    useState<ApplyColumnStateParams | undefined>();

  const handleColumnStateChange = useCallback(
    (cs: ApplyColumnStateParams | undefined) => {
      if (isEqual(cs, columnState)) return;
      setColumnState(cs);
    },
    [columnState],
  );

  /**
   * There is a bug in ag-grid. applyColumnState does not respect
   * the order of columns. We need to use this workaround to
   * pass columnState to useColumnDef hook and
   * apply sort order of columns in column definitions eghh :(
   */

  const applyColumnState = useCallback(
    (cs: ApplyColumnStateParams) => {
      if (!gridOptions || !gridOptions.columnApi) return;
      const newCS = {
        applyOrder: true,
        state: cs?.state?.sort(
          (a: any, b: any) =>
            // pushing dynamic fields at end of table
            a.colId?.includes('custom') - b.colId?.includes('custom'),
        ),
      };
      handleColumnStateChange(newCS);
      gridOptions.columnApi.applyColumnState(newCS);
    },
    [gridOptions, handleColumnStateChange],
  );

  const hasPredefinedFilters = SECTIONS_WITH_PREDEFINED_FILTERS.includes(section);
  const { loadPredefinedFilters } = usePredefinedFilters(
    gridRef,
    section,
    hasPredefinedFilters,
  );

  useEffect(() => {
    if (!ready || !gridOptions || !gridOptions.columnApi) return;
    const columnState = getSavedGridState();
    const sectionColumnState = get(columnState, section);
    if (!sectionColumnState) {
      if (gridColumns) {
        applyColumnState({
          state: gridColumns.map((c) => ({
            colId: c.propertyName,
            hide: !DEFAULT_DISPLAY_MAP[section]?.includes(c.propertyName),
          })),
        });
      }
      if (hasPredefinedFilters) loadPredefinedFilters();
      return;
    }

    const gridState = getGridState(gridOptions);
    if (isEqual(gridState, sectionColumnState)) return;

    applyColumnState({
      state: sectionColumnState.colState,
    });

    if (!sectionColumnState.groupState) return;
    gridOptions.columnApi.setColumnGroupState(sectionColumnState.groupState);

    if (!loadPredefinedFilters())
      gridOptions.api.setFilterModel(sectionColumnState.filterState);

    // we don't need to run this hook on columnState updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, section]);

  const saveDisplayedState = useCallback(() => {
    const gridOptions = gridRef.current;
    if (!gridOptions || !ready) return;

    const currentSectionState = getGridState(gridOptions);
    const savedGridColumnState = getSavedGridState();

    const newColumnState = {
      ...savedGridColumnState,
      [section]: currentSectionState,
    };

    if (!isEqual(savedGridColumnState, newColumnState)) {
      if (
        section === SectionType.Claims &&
        savedGridColumnState?.[section]?.colState
      ) {
        const savedDisplayedCols = savedGridColumnState[section].colState.filter(
          (col) => !col.hide,
        ).length;
        const currentDisplayedCols =
          gridOptions.columnApi?.getAllDisplayedColumns().length;
        // there's a bug where ClaimsGrid doesn't detect changes
        // so we force GetClaims graphQL query when new column is added
        if (currentDisplayedCols > savedDisplayedCols) {
          refreshData?.();
        }
      }

      const [visibleColumns, hiddenColumns] = partition(
        newColumnState?.[section]?.colState,
        (col) => !col.hide,
      );
      const colState = [
        // we display the visible cols first and then the hidden ones in alphabetical order
        ...visibleColumns,
        ...hiddenColumns.sort((a: any, b: any) => a.colId.localeCompare(b.colId)),
      ];
      setSavedGridState(newColumnState);
      handleColumnStateChange({
        state: colState,
      });
    }

    // we should not include columnState here as we only need it for
    // first load comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, section, gridRef, handleColumnStateChange]);

  useEffect(() => {
    saveDisplayedState();
  }, [columnState, saveDisplayedState]);

  const onFirstDataRendered = useCallback(() => {
    // here we scroll to the saved position
    const gridOptions = gridRef.current;
    if (!gridOptions || !ready) return;
    const columnState = getSavedGridState();
    if (columnState) {
      const sectionState = get(columnState, section);
      if (sectionState && typeof sectionState.firstDisplayedRow !== 'undefined')
        gridOptions.api.ensureIndexVisible(sectionState.firstDisplayedRow, 'top');
    }
    // setFirstDataHasRendered(true);
  }, [gridRef, ready, section]);

  return {
    columnState,
    showMine,
    setShowMine,
    onDisplayedColumnsChanged: saveDisplayedState,
    onBodyScrollEnd: saveDisplayedState,
    onFirstDataRendered,
    onFilterChanged: saveDisplayedState,
    onResetColumnState: () => handleColumnStateChange(undefined),
  };
}

export default useGridState;

import { get } from '../apiRequest';

export interface ClaimResolutionCategory {
  majorCategoryName: string;
  claimCount: number;
  assertedTotal: number;
  currentTotal: number;
  proposedTotal: number;
  isTotalRow: boolean;
}
export interface ClaimResolutionSection {
  sectionName: string;
  order: number;
  defaultVisible: boolean;
  values: ClaimResolutionCategory[];
}
export type ClaimsResolutionByCategories = ClaimResolutionSection[];

export interface ClaimsWalkDownSummaryItem {
  description: string;
  order: number;
  claimCount: number;
  secured: null | number;
  administrative: null | number;
  priority: null | number;
  unsecured: null | number;
  total: null | number;
  isTotalRow: boolean;
}

export interface ClaimsWalkDownDetailedSummaryItem {
  description: string;
  order: number;
  claimCount: number;
  secured: null | number;
  administrative: null | number;
  priority: null | number;
  unsecured: null | number;
  total: null | number;
  isTotalRow: boolean;
}

export interface ClaimsWalkDownSummarySection {
  subHeader: null | string;
  items: ClaimsWalkDownSummaryItem[];
}
export type ClaimsWalkDownSummaryResponse = {
  [key: string]: ClaimsWalkDownSummarySection;
};

export interface ClaimsWalkDownDetailedSummarySection {
  subHeader: null | string;
  items: ClaimsWalkDownDetailedSummaryItem[];
  name: string;
}
export type ClaimsWalkDownDetailedSummaryResponse = {
  sections: ClaimsWalkDownDetailedSummarySection[];
};

export enum BackgroundJobStatus {
  None = 'None',
  Queued = 'Queued',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type ExcelBackgroundJobStatus = {
  id: number;
  name: string;
  status: BackgroundJobStatus;
  data: {
    fileDownloadUrl: string;
  };
};

export const reports = {
  getClaimsResolutionByCategory: (legalEntityId?: number | string) =>
    get<ClaimsResolutionByCategories>(
      legalEntityId
        ? `v1/Reports/ClaimsResolutionByCategory?legalEntityId=${legalEntityId}`
        : 'v1/Reports/ClaimsResolutionByCategory',
    ),
  getClaimsWalkDownSummary: (legalEntityId?: number | string) =>
    get<ClaimsWalkDownSummaryResponse>(
      legalEntityId
        ? `v1/Reports/ClaimsWalkDownSummary?legalEntityId=${legalEntityId}`
        : 'v1/Reports/ClaimsWalkDownSummary',
    ),
  getClaimsWalkDownDetailedSummary: (legalEntityId?: number | string) =>
    get<ClaimsWalkDownDetailedSummaryResponse>(
      legalEntityId
        ? `v1/Reports/ClaimsWalkDownDetail?legalEntityId=${legalEntityId}`
        : 'v1/Reports/ClaimsWalkDownDetail',
    ),
};
